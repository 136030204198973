import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Typography
} from '@mui/material';
import {
  errorNotificationMessages,
  modalInformation, notificationIcons, successNotificationMessages, validation,
} from '../../../../utils/constants';
import { DIPLOMA_COLOR } from '../../../../../theme';
import BackdropLoading from '../../../molecules/backdrop-loading';
import ConfirmationModal from '../../../organisms/confirmation-modal';
import SelectAutocomplete from '../../../molecules/select-autocomplete';
import { AlertCircle } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { showNotification, showSuccessNotification } from '../../notification/actions';
import { selectDiploma, toggleInfoDiploma, toggleNextPage } from '../../diploma-page/store/actions';
import { setDiplomas, setLoadingDiplomas } from '../../diploma-list/store/actions';
import { RootState } from '../../../../../setup/reducer/reducers';
import InstitutionProfile from '../../../../../domain/enum/InstitutionProfile';
import RestartDiplomaFlowManager from '../RestartDiplomaFlowManager';
import ChangeDiplomaRequest from '../../../../../domain/interface/request/ChangeDiplomaRequest';
import DiplomaService from '../../../../../services/DiplomaService';
import ChangeType from '../../../../../domain/enum/diploma/ChangeType';

const PREFIX = 'RestartDiplomaFlow';

const classes = {
  spaced: `${PREFIX}-spaced`,
  helperIcon: `${PREFIX}-helperIcon`,
  descriptionLoading: `${PREFIX}-descriptionLoading`,
  circularProgress: `${PREFIX}-circularProgress`,
  grid: `${PREFIX}-grid`,
  textField: `${PREFIX}-textField`,
  errorIcon: `${PREFIX}-errorIcon`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.spaced}`]: {
    marginBottom: theme.spacing(4)
  },

  [`& .${classes.helperIcon}`]: {
    color: "rgba(244, 67, 54, 1)",
    width: '14.33px',
    height: '14.33px'
  },

  [`& .${classes.errorIcon}`]: {
    color: theme.palette.error.main
  },

  [`& .${classes.descriptionLoading}`]: {
    width: '19.938rem',
    color: DIPLOMA_COLOR
  },

  [`& .${classes.circularProgress}`]: {
    color: DIPLOMA_COLOR
  },

  [`& .${classes.grid}`]: {
    width: '416px'
  },

  [`& .${classes.textField}`]: {
    width: '400px'
  }
}));

type Props = {
  setOpenModal: (flag: boolean) => void;
  openModal: boolean;
  id?: string;
};

const listReason = RestartDiplomaFlowManager.RestartDiplomaFlow;

const RestartDiplomaFlow = (props: Props) => {
  const { openModal, setOpenModal, id } = props;

  const [isLoading, setLoading] = React.useState(false);

  const [validationError, setvalidationError] = React.useState(false);

  const [reason, setReason] = React.useState();

  const dispatch = useDispatch();

  const {
    selectedInstitutionProfile,
    selectedDiplomaStatus,
    selectedIssuerInstitution
  } = useSelector((state: RootState) => state.diplomaFilter);

  const selectedInstitution = useSelector(
    (state: RootState) => state.header.institution
  );

  const { info, nextPage } = useSelector(
    (state: RootState) => state.diplomaPage
  );

  async function reloadDiplomas(): Promise<any> {
    dispatch(setLoadingDiplomas(true));
    if (selectedInstitutionProfile === InstitutionProfile.Register) {
      return dispatch(
        setDiplomas(
          setLoadingDiplomas,
          selectedDiplomaStatus,
          selectedInstitution,
          selectedIssuerInstitution,
          selectedInstitutionProfile
        )
      );
    }
    return dispatch(
      setDiplomas(
        setLoadingDiplomas,
        selectedDiplomaStatus,
        undefined,
        selectedInstitution,
        selectedInstitutionProfile
      )
    );
  }

  async function handleSubmit(): Promise<void> {
    if (reason && !isLoading) {
      setvalidationError(false)
      setLoading(true);
      const changeRequest: ChangeDiplomaRequest = {
        type: ChangeType.IssuerInstitutionRestartsDiploma,
        reasonType: reason
      }
      try {
        await DiplomaService.addChangeDiploma(changeRequest, id)
        dispatch(
          showSuccessNotification(
            successNotificationMessages.defaultSuccess,
            notificationIcons.success
          )
        );
      } catch (e) {
        dispatch(
          showNotification(
            errorNotificationMessages.defaultError,
            notificationIcons.error
          )
        );
      }
      setLoading(false);
      setOpenModal(false);
      dispatch(selectDiploma());
      await reloadDiplomas();
      dispatch(setLoadingDiplomas(false));
      if (info) {
        dispatch(toggleInfoDiploma());
      }
      if (nextPage) {
        dispatch(toggleNextPage());
      }
    } else {
      setvalidationError(true)
    }
  }

  function handleClickCancelButton(): void {
    setvalidationError(false)
    setOpenModal(false);
  }

  function handleChangeReason(event: any): void {
    setReason(event.name)
  }

  const gridStyleWarning = {
    backgroundColor: 'rgba(244, 67, 54, 0.1)',
    padding: '0.9rem',
    color: '#621B16',
    fontSize: '14px',
    borderRadius: '4px',
    marginBottom: '1rem',
    gap: '1rem'
  }

  return (
    <>
      <ConfirmationModal
        openModal={openModal}
        modalTitle={modalInformation.modalRestartDiplomaFlow.title}
        textContinueButton={modalInformation.modalRestartDiplomaFlow.textContinue}
        handleContinueButton={handleSubmit}
        textCancelButton={modalInformation.modalRestartDiplomaFlow.textCancel}
        handleCancelButton={handleClickCancelButton}
      >
        <StyledGrid
          container
          justifyContent="center"
          alignItems="stretch"
          className={classes.grid}
        >
          {isLoading && (
            <BackdropLoading
              size={50}
              description="Cancelando Diploma..."
              open={isLoading}
              classes={classes}
            />
          )}

          <Grid item>
            {validationError && (
              <Grid container style={gridStyleWarning}>
                <AlertCircle
                  size={20}
                  className={classes.errorIcon}
                />
                {modalInformation.modalRestartDiplomaFlow.validationError}
              </Grid>
            )}
            {!validationError && (<Typography className={classes.spaced}>
              {modalInformation.modalRestartDiplomaFlow.description}
            </Typography>)}
            <Typography variant="caption">Motivo de Cancelamento</Typography>
            <SelectAutocomplete
              compareName
              defaultValue={reason}
              listItems={listReason}
              placeholder="Nenhuma situação selecionada"
              setItem={handleChangeReason}
              data-testid="select-status"
              helperText={validationError ? validation.errorMessages.requiredField : ''}
              error={validationError}
            />
          </Grid>
        </StyledGrid>
      </ConfirmationModal>
    </>
  );

};

RestartDiplomaFlow.defaultProps = {
  id: undefined,
  status: undefined
};

export default RestartDiplomaFlow;
