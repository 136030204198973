import {
  Collapse, Divider, Grid, IconButton, Paper, Typography,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { useCardCollapseStyles } from './styles';
import { CardCollapseProps } from './types';

const ShowMoreButton = ({ expand, toggleExpand, getLabel, styles }: any) => (
  <Typography
    variant="h5"
    onClick={toggleExpand}
    data-testid="card-collapse-show-more-button"
    className={styles.infoColorText}
  >
    {getLabel()}
    <IconButton
      disableRipple
      disableFocusRipple
      onClick={toggleExpand}
      aria-label={getLabel()}
      className={styles.infoColorText}
      size="large"
    >
      {expand ? <ExpandLess /> : <ExpandMore />}
    </IconButton>
  </Typography>
);

const CardCollapse = ({
  header, showMoreLabel, showLessLabel, children,
}: CardCollapseProps) => {
  const styles = useCardCollapseStyles();
  const [expand, setExpand] = useState(false);

  const toggleExpand = () => setExpand(!expand);

  const getLabel = () => (expand ? showLessLabel : showMoreLabel);

  const getHeaderWidth = () => (children ? 9 : 12);

  return (
    <Grid container data-testid="card-collapse" className={styles.root}>
      <Grid item xs={12}>
        <Paper elevation={0}>
          <Grid
            container
            justifyContent="space-around"
            alignItems="center"
            className={styles.headerContainer}
          >
            <Grid
              item
              xs={getHeaderWidth()}
              data-testid="card-collapse-header"
            >
              {header}
            </Grid>
            {
              children && (
                <Grid item xs={3} className={styles.showContentLabelContainer}>
                  <ShowMoreButton
                    expand={expand}
                    toggleExpand={toggleExpand}
                    getLabel={getLabel}
                    styles={styles}
                  />
                </Grid>
              )
            }
          </Grid>

          <Collapse in={expand} timeout="auto">
            {expand && <Divider />}
            <Grid
              item
              container
              alignItems="center"
              className={styles.collapseContainer}
            >
              {children}
            </Grid>
          </Collapse>
        </Paper>
      </Grid>
    </Grid>
  );
};

CardCollapse.defaultProps = {
  showMoreLabel: 'Visualizar',
  showLessLabel: 'Esconder',
  children: null,
};

export default CardCollapse;
