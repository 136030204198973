import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import {
  Divider,
  Table,
  TableBody, TableCell, TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import React, { useEffect } from "react";
import { AlertTriangle } from 'react-feather';
import { useSelector } from 'react-redux';
import Tables from '../../../../../domain/enum/Tables';
import Teacher from '../../../../../domain/model/diploma/Teacher';
import { RootState } from '../../../../../setup/reducer/reducers';
import { DARK_CYAN_COLOR, DARK_GRAY_COLOR, FONT_FAMILY_BOLD, FONT_FAMILY_EXTRABOLD, GRAY_COLOR } from "../../../../../theme";
import { getHeadCells } from '../../../../utils/functions/table';
import CustomizedTooltip from '../../../atoms/customized-tooltip';
import PaperTooltip from '../../../atoms/paper-tooltip';
import { getRows } from '../CurriculumUnitTableManager';
import { CurriculumUnitData, CurriculumUnitProps, HeadCell, RenderCellParams } from '../types';


const PREFIX = 'InternshipTable';

const classes = {
  root: `${PREFIX}-root`,
  cellabel: `${PREFIX}-cellabel`,
  iconValidation: `${PREFIX}-iconValidation`,
  tooltipContainer: `${PREFIX}-tooltipContainer`,
  teachersTooltipIcon: `${PREFIX}-teachersTooltipIcon`,
  teachersTypography: `${PREFIX}-teachersTypography`,
  tooltipTypography: `${PREFIX}-tooltipTypography`,
  teachersIcon: `${PREFIX}-teachersIcon`,
  iconTaskAlt: `${PREFIX}-iconTaskAlt`,
  errorIcon: `${PREFIX}-errorIcon`,
  validationError: `${PREFIX}-validationError`,
};

const Root: any = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingBottom: '50px'
  },

  [`& .${classes.cellabel}`]: {
    color: DARK_GRAY_COLOR,
    fontFamily: theme.typography.body2.fontFamily,
  },

  [`& .${classes.iconValidation}`]: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    justifyContent: 'center',
    color: DARK_CYAN_COLOR,
    fontWeight: 'bold',
    fontSize: '0.8rem',
  },

  [`& .${classes.tooltipContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 16,
    gap: '4px'
  },

  [`& .${classes.teachersTooltipIcon}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${classes.teachersTypography}`]: {
    fontFamily: FONT_FAMILY_BOLD,
    color: theme.palette.primary.main,
    marginLeft: 8,
    marginRight: 24,
  },

  [`& .${classes.tooltipTypography}`]: {
    margin: 8,
    marginLeft: 16,
  },

  [`& .${classes.teachersIcon}`]: {
    color: GRAY_COLOR
  },

  [`& .${classes.iconTaskAlt}`]: {
    fontWeight: '900',
    height: '1.2rem'
  },
  [`& .${classes.errorIcon}`]: {
    color: theme.palette.error.main
  },
  [`& .${classes.validationError}`]: {
    color: theme.palette.error.main,
    fontFamily: FONT_FAMILY_EXTRABOLD,
    fontSize: 13,
    display: 'inline-block'
  },
}));

const TableLoading = () => (
  <TableBody>
    {
      [...Array(7)].map((_e, i) => (
        <TableRow key={i++}>
          {
            [...Array(7)].map((_f, j) => (
              <TableCell key={i++ + j++} colSpan={1}>
                <Skeleton style={{ height: 32 }} animation="wave" />
              </TableCell>
            ))
          }
        </TableRow>
      ))
    }
  </TableBody>
);

const EnhancedTableHead = ({ headCells }: { headCells: HeadCell[] }) => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{
              width: headCell.size,
              whiteSpace: 'nowrap',
            }}
            key={headCell.id}
            align={headCell.align}
          >
            <Typography variant="caption" style={headCell.styles}>
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const CurriculumUnitTable = (props: CurriculumUnitProps) => {
  const {
    data, loading, validation, type
  } = props;

  const [rows, setRows] = React.useState<CurriculumUnitData[]>([]);
  const isInfoMode = useSelector((state: RootState) => state.diplomaPage.info);

  useEffect(() => {
    setRows(getRows(type, data, validation));

  }, [data, validation, isInfoMode, type]);

  const headCells: HeadCell[] = getHeadCells(type, isInfoMode);

  const renderCell: any = ({
    label, splitPoint = 30, cellSize = 25, textAlign = 'left',
  }: RenderCellParams) => {
    const customStyle = { width: cellSize };

    if (label) {
      if (label.length > 45) {
        return (
          <TableCell align={textAlign} style={customStyle}>
            <CustomizedTooltip title={label} placement="top">
              <Typography className={classes.cellabel} variant="body1">{`${(label).slice(0, 45)}...`}</Typography>
            </CustomizedTooltip>
          </TableCell>
        );
      }
      return (<TableCell align={textAlign} style={customStyle}><Typography className={classes.cellabel} variant="body1">{label}</Typography></TableCell>);
    }

    return <TableCell align={textAlign} style={customStyle}><Typography className={classes.cellabel} variant="body1">-</Typography></TableCell>;
  };

  const renderValidationCell = (row: CurriculumUnitData) => {
    if (row?.error && row.error.length > 0) {
      return (
        <TableCell align="center">
          <div className={classes.iconValidation}>
            <PaperTooltip
              key={row.id}
              placement="bottom-start"
              title={
                <>
                  <Root>{getMessageError(row.error)}</Root>
                </>
              }
            >
              <div className={classes.tooltipContainer}>
                <AlertTriangle size={15} className={classes.errorIcon} />
                <Typography className={classes.validationError} variant="body1">
                  {row.error.length === 1 ? '1 INCONSISTÊNCIA' : `${row.error.length} INCONSISTÊNCIAS`}
                </Typography>
              </div>
            </PaperTooltip>
          </div>
        </TableCell>
      );
    }

    return (
      <TableCell align="center">
        <div className={classes.iconValidation}>
          <>
            <TaskAltOutlinedIcon className={classes.iconTaskAlt} />
            <Typography variant="inherit">OK</Typography>
          </>
        </div>
      </TableCell>
    );
  };

  const translateError = (error: string): string => {
    switch (error) {
      case 'code':
        return 'Código'
      case 'internshipLoadWithTag':
        return 'Carga Horária'
      case 'complementaryActivityType':
        return 'Tipo'
      case 'activityLoadWithTag':
        return 'Carga Horária'
      case 'startDate':
        return 'Data de início'
      case 'endDate':
        return 'Data de término'
      case 'teachers':
        return 'Docentes'
      default:
        return ''
    }
  }

  const getMessageError = (errorValidation: string[]) => {
    const errorRow: React.ReactNode[] = [];
    errorValidation.forEach((error: string) => {
      const nameError = translateError(error)
      errorRow.push(
        <Typography
          key={error}
          className={classes.tooltipTypography}
          variant="h6"
        >{`${nameError} está vazio(a) ou incorreto(a).`}</Typography>
      );
    });
    return errorRow;
  };

  const getTeacherCell = () => (
    <TableCell align="center">
      <SupervisorAccountIcon
        className={classes.teachersIcon}
      />
    </TableCell>
  )

  const getTeachers = (teachers: Array<Teacher>) => {
    const teachersRow: React.ReactNode[] = [];
    teachers.forEach((teacher: Teacher) => {
      teachersRow.push(
        <Typography
          key={teacher.name}
          className={classes.tooltipTypography}
          variant="h6"
        >{`${teacher.name || 'N/A'} (${teacher.title || 'N/A'})`}</Typography>
      );
    });
    return teachersRow;
  };

  const buildCells = (row: CurriculumUnitData): React.ReactNode => (
    <TableRow
      hover
      key={row.id}
    >
      {renderCell({
        label: row.code,
        cellSize: 10,
        splitPoint: 25,
      })}

      {type === Tables.ComplementaryActivity && (renderCell({
        label: row.complementaryActivityType,
        cellSize: 10,
        splitPoint: 25,
      }))}

      {type === Tables.InternshipsTable ? (renderCell({
        label: row.internshipLoadWithTag,
        cellSize: 10,
        splitPoint: 25,
      })) : (renderCell({
        label: row.activityLoadWithTag,
        cellSize: 10,
        splitPoint: 25,
      }))}
      {renderCell({
        label: row.startDate,
        cellSize: 10,
        splitPoint: 25,
      })}
      {renderCell({
        label: row.endDate,
        cellSize: 10,
        splitPoint: 25,
      })}
      {row.teachers.length > 0 ? (
        <PaperTooltip
          key={row.id}
          placement="bottom-start"
          title={
            <>
              <Root>
                <div className={classes.tooltipContainer}>
                  <SupervisorAccountIcon
                    className={classes.teachersTooltipIcon}
                  />
                  <Typography
                    className={classes.teachersTypography}
                    variant="body2"
                  >
                    Docentes
                  </Typography>
                </div>
              </Root>
              <Divider light style={{ marginBottom: 8 }} />
              <Root>{getTeachers(row.teachers)}</Root>
            </>
          }
        >
          {getTeacherCell()}
        </PaperTooltip>
      ) : (
        getTeacherCell()
      )}
      {!isInfoMode && renderValidationCell(row)}


    </TableRow>
  );

  return (
    <Root className={classes.root}>
      <TableContainer>
        <Table>
          <EnhancedTableHead headCells={headCells} />
          {
            loading ? <TableLoading /> : (
              <TableBody>
                {rows.map((row: CurriculumUnitData) => (buildCells(row)
                ))}
              </TableBody>
            )
          }
        </Table>
      </TableContainer>
    </Root>
  );
}

export default CurriculumUnitTable;
