import { AxiosResponse } from 'axios';
import getAxiosInstance from '../apis/axiosInstance';
import Permission from '../domain/model/permission/Permission';

let axiosInstance = getAxiosInstance(
  process.env.REACT_APP_AUTHORIZATION_API || ''
);

class AuthenticationService {
  async userPermission(): Promise<AxiosResponse<Permission>> {
    return axiosInstance.get('permissions/self');
  }
}

export default new AuthenticationService();
