import { Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import GavelIcon from '@mui/icons-material/Gavel';
import { useState } from 'react';
import { ConnectedProps, connect, useDispatch } from 'react-redux';
import SendDiplomaToSignatureModal from '../../diploma-modal-signers/views/SendDiplomaToSignatureModal';
import { RootState } from '../../../../../setup/reducer/reducers';
import { addChangeDiploma, updateComponentValidateActive, updateDiploma } from '../../diploma-page/store/actions';
import DefaultTemplate from '../../../templates/default-template';
import DiplomaValidationButton from '../../../molecules/diploma-validation-button';
import CardTitle from '../../../molecules/card-title';
import BackCardHeader from '../../../molecules/back-card-header';
import CustomTextField from '../../../atoms/form/CustomTextField';
import IssuerInstitutionValidatesDiplomaRequest from '../../../../../domain/interface/request/IssuerInstitutionValidatesDiplomaRequest';
import InstitutionProfile from '../../../../../domain/enum/InstitutionProfile';
import Employee from '../../../../../domain/model/employee/Employee';
import SignerData from '../../../../../domain/model/institution/SignerData';
import ChangeType from '../../../../../domain/enum/diploma/ChangeType';
import Signer from '../../../../../domain/model/diploma/Signer';
import BackdropLoading from '../../../molecules/backdrop-loading';

const PREFIX = 'CourtOrder';

const classes = {
  root: `${PREFIX}-root`,
  fabGridRight: `${PREFIX}-fabGridRight`,
  fabGridLeft: `${PREFIX}-fabGridLeft`,
  icon: `${PREFIX}-icon`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    paddingBottom: theme.spacing(7)
  },

  [`& .${classes.fabGridRight}`]: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(4),
    maxWidth: '45%'
  },

  [`& .${classes.fabGridLeft}`]: {
    position: 'fixed',
    bottom: theme.spacing(3),
    left: theme.spacing(4),
    maxWidth: '45%'
  }
}));

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main,
  }
}));

const mapState = (state: RootState) => ({
  diploma: state.diplomaPage.diploma,
  validation: state.diplomaPage.validation,
  selectedInstitutionProfile: state.diplomaFilter.selectedInstitutionProfile,
  employees: state.selectDiplomaSigners.employees
});

const mapDispatch = {
  sendDiplomaToSignature: (
    request: IssuerInstitutionValidatesDiplomaRequest,
    diplomaId?: string,
    setLoading?: (isLoading: boolean) => void
  ) => addChangeDiploma(request, diplomaId, setLoading)
};

const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>

const CourtOrder = (props: Props) => {
  const {
    diploma,
    validation,
    selectedInstitutionProfile,
    employees,
    sendDiplomaToSignature
  } = props;

  const dispatch = useDispatch();

  const [openSendDiplomaToSignatureModal, setOpenSendDiplomaToSignatureModal] = useState(false);

  const [isLoadingSignersAction, setLoadingSignersAction] = useState(false);

  let signers: Signer[];

  function returnRequest() {
    let issuerInstitutionValidatesDiplomaRequest = {
      issuerInstitution: {
        ...diploma?.issuerInstitution,
        signers
      },
      type: ChangeType.IssuerInstitutionValidatedDiploma
    } as IssuerInstitutionValidatesDiplomaRequest

    if (diploma?.isCourtOrder && diploma?.courtOrder) {
      issuerInstitutionValidatesDiplomaRequest.courtOrder = diploma.courtOrder
    }

    return issuerInstitutionValidatesDiplomaRequest;
  }

  const handleSendDiplomaToSignature = () => {
    if (selectedInstitutionProfile === InstitutionProfile.Issuer) {
      if (diploma) {
        diploma.courtOrder = {
          judgeName: judgeName.trim(),
          courtCaseNumber: courtCaseNumber,
          decision: decision.trim()
        }
        dispatch(updateDiploma(diploma))
      }

      let selectedFirstEmployee: Employee | undefined;
      let selectedSecondEmployee: Employee | undefined;
      let firstSigner: SignerData | undefined;
      let secondSigner: SignerData | undefined;

      firstSigner = hasDefaultSigners?.find((signer) => signer.signFor === 'issuer' && signer.signerPosition === 1);
      secondSigner = hasDefaultSigners?.find((signer) => signer.signFor === 'issuer' && signer.signerPosition === 2);

      selectedFirstEmployee = employees?.find((employee) => employee.login === firstSigner?.login);
      selectedSecondEmployee = employees?.find((employee) => employee.login === secondSigner?.login);

      signers = [
        {
          ...selectedFirstEmployee,
          order: 1
        } as Signer,
        {
          ...selectedSecondEmployee,
          order: 2
        } as Signer
      ];

      const request: IssuerInstitutionValidatesDiplomaRequest = returnRequest()
      sendDiplomaToSignature(request, diploma?.id, setLoadingSignersAction);
    } else {
      setOpenSendDiplomaToSignatureModal(true);
    }
  };

  function isCompletedForm() {
    let copyValidation = Object.assign({}, validation);

    if (judgeName?.length === 0 || courtCaseNumber?.length < 25) {
      copyValidation.courtOrderForm = "Formulário de decisão judicial incompleto"
    }
    return copyValidation;
  }

  const [judgeName, setJudgeName] = useState('');
  const [courtCaseNumber, setCourtCaseNumber] = useState('');
  const [decision, setDecision] = useState('');

  const handleJudgeName = (event: any) => {
    let value = event.target.value;
    if (value.length > 255) {
      value = value.slice(0, 255);
    }
    setJudgeName(value)
  }

  const handleCourtCaseNumber = (event: any) => {
    let value = event.target.value.replace(/[^\d]/g, '');
    if (value.length > 20) {
      value = value.slice(0, 20);
    }
    let formatCourtCaseNumber = value.replace(/(\d{7})(\d{2})(\d{4})(\d)(\d{2})(\d{4})/, '$1-$2.$3.$4.$5.$6');
    setCourtCaseNumber(formatCourtCaseNumber);
  }

  const handleDecision = (event: any) => {
    let value = event.target.value;
    if (value.length > 500) {
      value = value.slice(0, 500);
    }
    setDecision(value);
  }

  const returnPage = () => {
    dispatch(updateComponentValidateActive("AcademicDocumentation"));
  };

  const hasDefaultSigners = diploma?.defaultSigners

  const getPageAction = (): JSX.Element => {
    if ((!hasDefaultSigners || hasDefaultSigners?.length < 4) && selectedInstitutionProfile === InstitutionProfile.Issuer) {
      return (
        <DiplomaValidationButton
          validation={"invalidDefaultSigners"}
          text="Enviar para assinatura"
          buttonType="success"
        />
      );
    }

    return (
      <>
        <DiplomaValidationButton
          handleClick={handleSendDiplomaToSignature}
          validation={isCompletedForm()}
          text="Enviar para assinatura"
          buttonType="success"
          data-testid="diploma-send-for-signature-button"
        />
        <SendDiplomaToSignatureModal
          openModal={openSendDiplomaToSignatureModal}
          setOpenModal={setOpenSendDiplomaToSignatureModal}
        />
      </>
    );
  };

  return (
    <Root>
      <DefaultTemplate message="Validação de" highlightedMessage="Dados Judiciais">
        {isLoadingSignersAction && (
          <BackdropLoading
            size={50}
            description='Enviando Para Assinatura...'
            open={isLoadingSignersAction}
            classes={classes}
          />
        )}
        <StyledPaper>
          {<BackCardHeader onClick={returnPage} />}
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <CardTitle icon={<GavelIcon className={classes.icon} />}>Decisão Judicial</CardTitle>
            </Grid>
            <Grid container px={4} pb={5} justifyContent="space-between">
              <Grid item xs={5} >
                <Typography noWrap variant="body1">Nome do Juiz *</Typography>
                <CustomTextField
                  placeholder="Digite o nome do juiz..."
                  type="text"
                  fullWidth
                  value={judgeName}
                  onChange={handleJudgeName}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography noWrap variant="body1">Número do Processo *</Typography>
                <CustomTextField
                  placeholder="Digite o número do Processo..."
                  type="text"
                  fullWidth
                  value={courtCaseNumber}
                  onChange={handleCourtCaseNumber}
                />
              </Grid>
              <Grid item xs={12} mt={5} >
                <Typography noWrap variant="body1">Decisão</Typography>
                <CustomTextField
                  placeholder="Digite a decisão do Juiz..."
                  type="text"
                  fullWidth
                  value={decision}
                  onChange={handleDecision}
                />
              </Grid>
            </Grid>
          </Grid>
        </StyledPaper>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={3}
          className={classes.fabGridRight}
        >
          {getPageAction()}
        </Grid>
        <SendDiplomaToSignatureModal
          openModal={openSendDiplomaToSignatureModal}
          setOpenModal={setOpenSendDiplomaToSignatureModal}
        />
      </DefaultTemplate>
    </Root>
  );
};

export default connector(CourtOrder);
