import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import CardTitle from '../../molecules/card-title';
import LabeledTooltip from '../../molecules/labeled-tooltip';
import { CurriculumLabelPaperProps } from './types';

const PREFIX = 'index';

const classes = {
    icon: `${PREFIX}-icon`
};

const StyledPaper = styled(Paper)((
    {
        theme
    }
) => ({
    [`& .${classes.icon}`]: {
        color: theme.palette.primary.main,
    }
}));

export default function CurriculumLabelPaper({ curriculum, isLoading, validation }: CurriculumLabelPaperProps) {
    return (
        <StyledPaper>
            <Grid container item xs={12}>
                <Grid item xs={12}>
                    <CardTitle icon={<LocalOfferOutlinedIcon className={classes.icon} />}>Etiqueta</CardTitle>
                </Grid>
                <Grid item xs={4}>
                    <LabeledTooltip
                        label="Código"
                        isLoading={isLoading}
                        error={validation?.curriculum?.labes[0]?.code}
                        errorMessage={validation?.curriculum?.labes[0]?.code}
                        data-testid="curriculum-labels-code">
                        {curriculum?.labels[0].code || 'N/A'}
                    </LabeledTooltip>
                </Grid>
                <Grid item xs={4}>
                    <LabeledTooltip
                        label="Nome"
                        isLoading={isLoading}
                        error={validation?.curriculum?.labes[0]?.name}
                        errorMessage={validation?.curriculum?.labes[0]?.name}
                        data-testid="curriculum-labels-name">
                        {curriculum?.labels[0].name || 'N/A'}
                    </LabeledTooltip>
                </Grid>
            </Grid>
        </StyledPaper>
    );
}
