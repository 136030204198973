import DashboardDataDto from '../../../../../domain/interface/dto/DashboardDataDto';

export const SELECT_DASHBOARD_START_DATE =
  'app/diploma-dashboard/SELECT_DASHBOARD_START_DATE';
export const SELECT_DASHBOARD_END_DATE =
  'app/diploma-dashboard/SELECT_DASHBOARD_END_DATE';
export const SET_DASHBOARD_DATA = 'app/diploma-dashboard/SET_DASHBOARD_DATA';
export const SET_DASHBOARD_LOADING_DATA =
  'app/diploma-dashboard/SET_DASHBOARD_LOADING_DATA';

export interface DiplomaDashboardState {
  startDate?: string;
  endDate?: string;
  data?: DashboardDataDto;
  loadingData: boolean;
}

export const INITIAL_STATE: DiplomaDashboardState = {
  startDate: undefined,
  endDate: undefined,
  data: undefined,
  loadingData: true
};

export interface SelectStartDateAction {
  type: typeof SELECT_DASHBOARD_START_DATE;
  payload: {
    startDate?: string;
  };
}

export interface SelectEndDateAction {
  type: typeof SELECT_DASHBOARD_END_DATE;
  payload: {
    endDate?: string;
  };
}

export interface SetDashboardDataAction {
  type: typeof SET_DASHBOARD_DATA;
  payload: {
    data?: DashboardDataDto;
  };
}

export interface SetDashboardLoadingDataAction {
  type: typeof SET_DASHBOARD_LOADING_DATA;
  payload: {
    loadingData: boolean;
  };
}

export type DiplomaDashboardActions =
  | SelectStartDateAction
  | SelectEndDateAction
  | SetDashboardDataAction
  | SetDashboardLoadingDataAction;
