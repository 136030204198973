import { Grid, GridSize, Typography, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { EERIE_BLACK_COLOR, MEDIUM_GRAY_COLOR } from '../../../../theme';
import { InlineLabeledTextProps } from './types';

const PREFIX = 'InlineLabeledText';

const classes = {
  text: `${PREFIX}-text`,
  label: `${PREFIX}-label`
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.text}`]: {
    color: EERIE_BLACK_COLOR,
    fontWeight: 400,
  },

  [`& .${classes.label}`]: {
    color: MEDIUM_GRAY_COLOR,
    fontWeight: 400,
    whiteSpace: 'pre-wrap',
  }
}));

function getContentBreakpoint(isLoading: boolean): GridSize | undefined {
  return isLoading ? 4 : undefined;
}

function InlineLabeledText({ label, text, isLoading }: InlineLabeledTextProps): JSX.Element {


  const getContent = (): JSX.Element => {
    if (isLoading) {
      return <Skeleton animation="wave" />;
    }

    return (
      <Typography variant="body2" className={classes.text}>
        {text}
      </Typography>
    );
  };

  return (
    <StyledGrid container item alignItems="baseline" xs={12} spacing={0}>
      <Grid item>
        <Typography variant="body2" className={classes.label}>
          {label}
          {': '}
        </Typography>
      </Grid>
      <Grid item xs={getContentBreakpoint(isLoading)}>
        {getContent()}
      </Grid>
    </StyledGrid>
  );
}

InlineLabeledText.defaultProps = {
  isLoading: false,
  text: 'N/A',
};

export default InlineLabeledText;
