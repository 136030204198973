import InstitutionType from '../../enum/InstitutionType';
import Address from '../Address';
import Sponsor from '../Sponsor';
import Settings from './Settings';

export default class Institution {
  mecCode: number;

  type: InstitutionType;

  name: string;

  cnpj: string;

  address?: Address;

  sponsor?: Sponsor;

  validationUrl?: string;

  brand?: string;

  settings?: Settings;
}
