import Tables from '../../../domain/enum/Tables';
import { CurricularUnityAndValidations } from '../../components/organisms/curriculum-curricular-unities-paper/types';
import { Order } from '../../components/organisms/enhanced-table-head/types';

export const getHeadCells = (type: Tables, modeInfo?: boolean) => {
  const commonHeadCells = [
    {
      id: 'startDate',
      label: 'Início',
      size: '10%',
      align: 'left'
    },
    {
      id: 'endDate',
      label: 'Fim',
      size: '10%',
      align: 'left'
    },
    {
      id: 'teachers',
      label: 'Docentes',
      size: '10%',
      align: 'center'
    },
    {
      id: 'error',
      label: 'Validação',
      size: '15%',
      align: 'center'
    }
  ];

  let headCells: any = [];

  if (type === Tables.ComplementaryActivity) {
    headCells = [
      {
        id: 'code',
        label: 'Código',
        size: '10%',
        align: 'left'
      },
      {
        id: 'complementaryActivityType',
        label: 'Tipo',
        size: '25%',
        align: 'left'
      },
      {
        id: 'activityLoadWithTag',
        label: 'Carga Horária',
        size: '10%',
        align: 'left'
      },
      ...commonHeadCells
    ];
  } else if (type === Tables.InternshipsTable) {
    headCells = [
      {
        id: 'code',
        label: 'Código',
        size: '35%',
        align: 'left'
      },
      {
        id: 'internshipLoadWithTag',
        label: 'Carga Horária',
        size: '10%',
        align: 'left'
      },
      ...commonHeadCells
    ];
  }

  if (modeInfo) {
    headCells = headCells.filter((cell: any) => cell.label !== 'Validação');
  }

  return headCells;
};

export function orderRows<T>(order: Order, orderBy: string, rows: Array<T>) {
  const orderByKey = orderBy as keyof T;
  if (!orderByKey) {
    return rows;
  }
  return rows.sort((a, b) => {
    if (!a[orderByKey] || !b[orderByKey]) {
      return 0;
    }
    if (order === 'asc') {
      return a[orderByKey] < b[orderByKey] ? -1 : 1;
    }
    return a[orderByKey] < b[orderByKey] ? 1 : -1;
  });
}

export function orderLoad(order: Order, rows: CurricularUnityAndValidations[]) {
  if (order === 'asc') {
    return rows.sort(
      (a, b) =>
        a.curricularUnitClockTime?.load - b.curricularUnitClockTime?.load
    );
  }
  return rows.sort(
    (a, b) => b.curricularUnitClockTime?.load - a.curricularUnitClockTime?.load
  );
}
