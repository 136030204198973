import { ThunkAction } from 'redux-thunk';
import { Action, Dispatch } from 'redux';
import { DouPageActions, SELECT_BATCH, TOGGLE_INFO_BATCH } from './types';
import { RootState } from '../../../../../setup/reducer/reducers';
import Dou from '../../../../../domain/model/dou/Dou';

const internalSelectBatch = (batch?: Dou): DouPageActions => ({
  type: SELECT_BATCH,
  payload: {
    batch
  }
});

export const toggleInfoBatch = (): DouPageActions => ({
  type: TOGGLE_INFO_BATCH
});

export const selectBatch =
  (batch?: Dou): ThunkAction<void, RootState, unknown, Action<Dou>> =>
  async (dispatch: Dispatch) => {
    if (batch) {
      dispatch(internalSelectBatch(batch));
    }
  };
