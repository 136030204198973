import makeStyles from '@mui/styles/makeStyles';
import { DARK_GRAY_COLOR } from '../../../../theme';

const useDiplomaEducationalStagesSelectStyles = makeStyles({
  container: {
    cursor: 'pointer',
    float: 'right',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    color: DARK_GRAY_COLOR
  },
  containerIcon: {
    float: 'right'
  },
  containerDescription: {
    marginLeft: '1px',
    float: 'right',
    textAlignLast: 'right',
    marginRight: '8px'
  },
  iconUp: {
    height: '18px',
    width: '18px'
  },
  iconDown: {
    height: '18px',
    width: '18px'
  },
  skeleton: {
    height: '40px',
    width: '240px',
    display: 'inline-block',
    marginBottom: '3.5px'
  },
  subContainer: {
    right: '25rem',
    flexWrap: 'nowrap'
  }
});

export default useDiplomaEducationalStagesSelectStyles;
