import Institution from '../../../../../domain/model/institution/Institution';
import DiplomaInstitutionService from '../../../../../services/DiplomaInstitutionService';
import { AppThunk } from '../../../../../setup/reducer';
import {
  errorNotificationMessages,
  notificationIcons
} from '../../../../utils/constants';
import { showNotification } from '../../../pages/notification/actions';
import { HeaderActions, SET_INSTITUTION, SET_INSTITUTIONS } from './types';

const internalSetInstitution = (institution?: Institution): HeaderActions => ({
  type: SET_INSTITUTION,
  payload: {
    institution
  }
});

export const setInstitution =
  (institution?: Institution): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetInstitution(institution));
  };

const internalSetInstitutions = (
  institutions: Institution[]
): HeaderActions => ({
  type: SET_INSTITUTIONS,
  payload: {
    institutions
  }
});

export const setInstitutions =
  (setLoadingInstitution?: any): AppThunk =>
  async (dispatch) => {
    let institutions: Institution[] = [];
    try {
      institutions = (await DiplomaInstitutionService.listInstitutions()).data;
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
    }
    dispatch(internalSetInstitutions(institutions));

    if (setLoadingInstitution) {
      setLoadingInstitution(false);
    }
  };
