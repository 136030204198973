import Dou from '../../../../../domain/model/dou/Dou';
import Institution from '../../../../../domain/model/institution/Institution';
import { AppThunk } from '../../../../../setup/reducer';
import {
  errorNotificationMessages,
  notificationIcons
} from '../../../../utils/constants';
import { showNotification } from '../../notification/actions';
import { listNewBatches } from '../DouFilterManager';
import { DouFilterBatchStatus, DouFilterBatchType } from '../types';
import {
  DouListActions,
  SET_SEARCH,
  SET_STATUS,
  SET_BATCHES,
  SET_LOADING_BATCHES,
  SET_TYPE
} from './types';

const internalSetSearch = (search?: number): DouListActions => ({
  type: SET_SEARCH,
  payload: {
    search
  }
});

export const setSearch =
  (search?: number): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetSearch(search));
  };

const internalSetStatus = (status?: DouFilterBatchStatus): DouListActions => ({
  type: SET_STATUS,
  payload: {
    status
  }
});

export const setStatus =
  (status?: DouFilterBatchStatus): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetStatus(status));
  };

const internalSetBatches = (batches: Dou[]): DouListActions => ({
  type: SET_BATCHES,
  payload: {
    batches
  }
});

const internalSetLoadingBatches = (
  loadingBatches: boolean
): DouListActions => ({
  type: SET_LOADING_BATCHES,
  payload: {
    loadingBatches
  }
});

export const setLoadingBatches =
  (loadingData: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetLoadingBatches(loadingData));
  };

export const setBatches =
  (institution: Institution): AppThunk =>
  async (dispatch) => {
    let batches: Dou[] = [];

    try {
      batches = await listNewBatches(institution.mecCode);
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
    }

    dispatch(internalSetBatches(batches));
    dispatch(setLoadingBatches(false));
  };

const internalSetType = (type?: DouFilterBatchType): DouListActions => ({
  type: SET_TYPE,
  payload: {
    type
  }
});

export const setType =
  (type?: DouFilterBatchType): AppThunk =>
  (dispatch) => {
    dispatch(internalSetType(type));
  };
