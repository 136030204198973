import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import BookOutlined from '@mui/icons-material/BookOutlined';
import CardTitle from '../../molecules/card-title';
import LabeledTooltip from '../../molecules/labeled-tooltip';
import { CurriculumDataPaperProps } from './types';
import moment from 'moment';

const PREFIX = 'index';

const classes = {
  icon: `${PREFIX}-icon`
};

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main,
  }
}));

export default function CurriculumDataPaper({ root, isLoading, validation }: CurriculumDataPaperProps) {
  return (
    <StyledPaper>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <CardTitle icon={<BookOutlined className={classes.icon} />}>Currículo</CardTitle>
        </Grid>
        <Grid item xs={2}>
          <LabeledTooltip
            label="Código do Currículo"
            isLoading={isLoading}
            error={validation?.root?.curriculumCode}
            errorMessage={validation?.root?.curriculumCode}
            data-testid="curriculum-root-curriculumCode">
            {root?.curriculumCode || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip
            label="Descrição da Grade Curricular"
            isLoading={isLoading}
            error={validation?.root?.curriculumDescription}
            errorMessage={validation?.root?.curriculumDescription}
            data-testid="curriculum-root-curriculumDescription">
            {root?.curriculumDescription || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={3}>
          <LabeledTooltip
            label="Data de Cadastro"
            isLoading={isLoading}
            error={validation?.root?.curriculumDate}
            errorMessage={validation?.root?.curriculumDate}
            data-testid="curriculum-root-curriculumDate"
          >
            {root?.curriculumDate ? moment(root.curriculumDate).format('DD/MM/YYYY') : 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={3}>
          <LabeledTooltip
            label="Hora/Aula"
            isLoading={isLoading}
            error={validation?.root?.minutesTimeClockClass}
            errorMessage={validation?.root?.minutesTimeClockClass}
            data-testid="curriculum-root-minutesTimeClockClass">
            {root?.minutesTimeClockClass || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip
            label="Versão"
            isLoading={isLoading}
            error={validation?.root?.xsdVersion}
            errorMessage={validation?.root?.xsdVersion}
            data-testid="curriculum-root-version">
            {root?.xsdVersion || 'N/A'}
          </LabeledTooltip>
        </Grid>
      </Grid>
    </StyledPaper>
  );
}
