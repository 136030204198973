import makeStyles from '@mui/styles/makeStyles';

const useDiplomaEducationalStateLabelStyles = makeStyles({
  root: {
    top: '3.47rem',
    right: '9rem',
    position: 'absolute',
    width: '15rem',
    padding: '1rem',
    '& .MuiRadio-root': {
      padding: 0,
      marginRight: '0.625rem',
      marginLeft: '0.5rem'
    },
    '& .Mui-disabled': {
      cursor: 'not-allowed'
    },
    '& .MuiFormControlLabel-root': {
      marginTop: '0.5rem'
    },
    '& .MuiDivider-root': {
      margin: '1rem 0 1rem 0'
    }
  }
});

export default useDiplomaEducationalStateLabelStyles;
