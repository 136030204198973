import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { PdfViewerProps } from './types';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PREFIX = 'index';

const classes = {
  ResumeContainer: `${PREFIX}-ResumeContainer`,
  PDFDocument: `${PREFIX}-PDFDocument`,
  PDFPageOne: `${PREFIX}-PDFPageOne`,
};

const Root = styled('div')(() => ({
  [`& .${classes.ResumeContainer}`]: {
    margin: 'auto',
    width: '65%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  [`& .${classes.PDFDocument}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& canvas': {
      maxWidth: '100%',
      height: 'auto !important',
    },
  },

  [`& .${classes.PDFPageOne}`]: {
    marginBottom: '25px'
  }
}));

export default function PdfViewer({ file }: PdfViewerProps): JSX.Element {
  const [documentNumPages, setDocumentNumPages] = useState<number | null>(null);

  function onDocumentLoadSuccess(numPages: number) {
    setDocumentNumPages(numPages);
  }

  return (
    <Root>
      <Document
        file={file}
        onLoadSuccess={(pdf) => onDocumentLoadSuccess(pdf.numPages)}
      >
        {Array.from(
          new Array(documentNumPages),
          (_, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              className={classes.PDFDocument}
            />
          ),
        )}
      </Document>
    </Root>

  );
}
