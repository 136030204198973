import {
  ClickAwayListener,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InstitutionProfile from '../../../../domain/enum/InstitutionProfile';
import InstitutionType from '../../../../domain/enum/InstitutionType';
import { RootState } from '../../../../setup/reducer/reducers';
import useDiplomaEducationalStateLabelStyles from './styles';

interface DropdownEducationalStageProps {
  showDropdown: boolean;
  setShowDropdown(showDropdown: boolean): void;
}

let handleSetDefaultFilters: () => void;

export default function EducationalStageSelect({
  showDropdown,
  setShowDropdown
}: DropdownEducationalStageProps) {
  const styles = useDiplomaEducationalStateLabelStyles();

  const verifyIntitutionProfile = (): InstitutionProfile => {
    let institutionProfileStorage = JSON.parse(
      localStorage.getItem('institution_profile') || '{}'
    );

    if (
      JSON.stringify(institutionProfileStorage) === null ||
      JSON.stringify(institutionProfileStorage) === '{}'
    ) {
      localStorage.setItem(
        'institution_profile',
        JSON.stringify(InstitutionProfile.Issuer)
      );
      return InstitutionProfile.Issuer;
    }

    return institutionProfileStorage;
  };

  const [institutionProfile, setInstitutionProfile] = useState(
    verifyIntitutionProfile()
  );

  const [disableRegisterRadio, setDisableRegisterRadio] = useState(true);

  const selectedInstitution = useSelector(
    (state: RootState) => state.header.institution
  );

  const isRegister =
    selectedInstitution?.type === InstitutionType.University ||
    selectedInstitution?.type === InstitutionType.UniversityCenter;

  handleSetDefaultFilters = () => {
    if (isRegister) {
      setDisableRegisterRadio(false);
    } else {
      setDisableRegisterRadio(true);
      setInstitutionProfile(InstitutionProfile.Issuer);
    }
  };

  useEffect(() => {
    handleSetDefaultFilters();
  }, [selectedInstitution]);

  const handleChangeInstitutionProfile = (event: any) => {
    localStorage.setItem(
      'institution_profile',
      JSON.stringify(event.target.value)
    );
    setInstitutionProfile(event.target.value);
    document.location.reload();
  };

  return (
    <>
      {showDropdown && (
        <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
          <Grid container data-testid="diploma-educational-stage-select">
            <Grid item>
              <Paper className={styles.root}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="caption">Selecionar visão</Typography>

                    <RadioGroup
                      name="institutionProfile"
                      value={institutionProfile}
                      onChange={handleChangeInstitutionProfile}
                    >
                      <FormControlLabel
                        value={InstitutionProfile.Issuer}
                        control={<Radio size="small" />}
                        label={InstitutionProfile.Issuer}
                        data-testid="diploma-educational-stage-select-issuer-radio"
                      />
                      <FormControlLabel
                        value={InstitutionProfile.Register}
                        control={<Radio size="small" />}
                        label={InstitutionProfile.Register}
                        disabled={disableRegisterRadio}
                        data-testid="diploma-educational-stage-select-register-radio"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </ClickAwayListener>
      )}
    </>
  );
}
