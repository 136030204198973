import {
  DouListActions,
  DouListState,
  INITIAL_STATE,
  SET_SEARCH,
  SET_STATUS,
  SET_BATCHES,
  SET_LOADING_BATCHES,
  SET_TYPE
} from './types';

export default function reducer(
  state: DouListState = INITIAL_STATE,
  action: DouListActions
): DouListState {
  switch (action.type) {
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload.search
      };
    case SET_STATUS:
      return {
        ...state,
        status: action.payload.status
      };
    case SET_BATCHES:
      return {
        ...state,
        batches: action.payload.batches
      };
    case SET_LOADING_BATCHES:
      return {
        ...state,
        loadingBatches: action.payload.loadingBatches
      };
    case SET_TYPE:
      return {
        ...state,
        type: action.payload.type
      };
    default:
      return state;
  }
}
