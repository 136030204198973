import DefaultTemplate from "../../../templates/default-template";
import InstitutionManagementList from "./InstitutionManagementList";
import DefaultSignersModal from "../../../organisms/default-signers-modal";
import { useSelector } from 'react-redux';
import { RootState } from "../../../../../setup/reducer/reducers";

const InstitutionManagementPage = () => {

    const openDefaultSignersModal = useSelector(
        (state: RootState) => state.institutionPage.openDefaultSignersModal
    );

    return (
        <DefaultTemplate>
            <InstitutionManagementList />
            <DefaultSignersModal
                openModal={openDefaultSignersModal}
            />
        </DefaultTemplate>
    );
}

export default InstitutionManagementPage;