import { TableBody, TableRow, TableCell, Skeleton, Table } from '@mui/material';
import { TableLoadingProps } from './types';

const TableLoading = ({ rows, columns }: TableLoadingProps) => {
    return (
        <Table>
            <TableBody>
                {
                    [...Array(rows)].map((_e, i) => (
                        <TableRow key={i++}>
                            {
                                [...Array(columns)].map((_f, j) => (
                                    <TableCell key={i++ + j++} colSpan={1}>
                                        <Skeleton style={{ height: 32 }} animation="wave" />
                                    </TableCell>
                                ))}
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
    );
};

export default TableLoading;
