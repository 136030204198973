import EditModelCertificate from '../../../../domain/model/certificate/EditModelCertificate';
import { searchIgnoringCaseAndAccents } from '../../../utils/functions';
import { EditModelCertificateData, HeadCell, Order } from './types';

export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

export const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key
): ((
  a: { [key in Key]: number | string | string[] },
  b: { [key in Key]: number | string | string[] }
) => number) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

export const stableSort = <T>(
  array: T[],
  comparator: (a: T, b: T) => number
) => {
  if (array.length === 0) {
    return array;
  }
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

export const headCells: HeadCell[] = [
  {
    id: 'type',
    label: 'Tipo',
    size: '20%',
    align: 'left',
    sortable: true
  },
  {
    id: 'brands',
    label: 'Marcas',
    size: '15%',
    align: 'left',
    sortable: false
  },
  {
    id: 'user',
    label: 'Nome do Usuário',
    size: '20%',
    align: 'left',
    sortable: true
  },

  {
    id: 'action',
    label: 'Ações',
    size: '5%',
    align: 'center',
    sortable: false
  }
];

export const getRows = (
  modelCertificate: EditModelCertificate[],
  search: string
): EditModelCertificateData[] => {
  let rows: EditModelCertificateData[] = [];

  if (!modelCertificate) {
    return rows;
  }

  modelCertificate.forEach((value) => {
    rows.push({
      id: value.pk,
      brands: value.brands,
      user: value.userName,
      type: value.pk,
      action: ''
    });
  });

  if (search.length >= 2) {
    search = search.toLowerCase();
    rows = rows.filter(
      (value) =>
        searchIgnoringCaseAndAccents(value?.type, search) ||
        value.type?.toLowerCase().match(`.*${search}.*`)
    );
  }

  return rows;
};
