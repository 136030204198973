import makeStyles from '@mui/styles/makeStyles';
import { GRAY_COLOR, WHITE_COLOR } from '../../../../theme';
import DotStepIconProps from './types';

const useDotStepIconStyles = makeStyles({
  dot: {
    width: '0.625rem',
    height: '0.625rem',
    border: `${GRAY_COLOR} 0.0625rem solid`,
    borderRadius: '50%',
    backgroundColor: WHITE_COLOR,
  },
  activeDot: (props: DotStepIconProps) => ({
    backgroundColor: props?.activeColor || GRAY_COLOR,
    border: `${props?.activeColor || GRAY_COLOR} 0.0625rem solid`,
  }),
});

export default useDotStepIconStyles;
