import { Grid } from '@mui/material';
import MenuBar from '../../menu-bar/views/MenuBar';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../setup/reducer/reducers';
import Router from '../../../../../Router';
import Notification from '../../notification';
import { useHistory } from 'react-router-dom';
import { pageRoutes } from '../../../../utils/constants';

const PREFIX = 'App';

const classes = {
  content: `${PREFIX}-content`,
  global: `${PREFIX}-global`
};

export default function DiplomaBase(): JSX.Element {
  const history = useHistory();
  const user = useSelector((state: RootState) => state.login.user);
  const showSignDocuments = useSelector(
    (state: RootState) => state.authorization.showSignDocuments
  );

  return (
    <>
      {user?.token &&
        showSignDocuments !== undefined &&
        history?.location?.pathname !== pageRoutes.PublicPortal && <MenuBar />}
      <Grid container direction="column" className={classes.content}>
        <Router />
      </Grid>
      <Notification data-testid="notification-message" />
    </>
  );
}
