import Institution from '../../../../../domain/model/institution/Institution';
import { SelectedDiplomaStatus } from '../types';
import Diploma from '../../../../../domain/model/diploma/Diploma';
import InstitutionProfile from '../../../../../domain/enum/InstitutionProfile';

export const SELECT_ISSUER_INSTITUTION =
  'app/diploma-list/GET_ISSUER_INSTITUTIONS';
export const SET_ISSUER_INSTITUTIONS =
  'app/diploma-list/SET_ISSUER_INSTITUTIONS';
export const SELECT_DIPLOMA_STATUS = 'app/diploma-list/SELECT_DIPLOMA_STATUS';
export const SET_SEARCH = 'app/diploma-list/SET_SEARCH';
export const SET_DIPLOMAS = 'app/diploma-list/SET_DIPLOMAS';
export const SET_LOADING_DIPLOMAS = 'app/diploma-list/SET_LOADING_DIPLOMAS';
export const SET_SELECTED_INSTITUTION_PROFILE =
  'app/diploma-list/SET_SELECTED_INSTITUTION_PROFILE';
export const SET_SELECTED_SYNC_DIPLOMAS =
  'app/diploma-list/SET_SELECTED_SYNC_DIPLOMAS';

export interface DiplomaListState {
  selectedIssuerInstitution?: Institution;
  issuerInstitutions?: Institution[];
  selectedDiplomaStatus?: SelectedDiplomaStatus;
  search: string;
  diplomas: Diploma[];
  loadingDiplomas: boolean;
  selectedInstitutionProfile: InstitutionProfile;
  syncDiplomas: Diploma[];
}

export const INITIAL_STATE: DiplomaListState = {
  selectedIssuerInstitution: undefined,
  issuerInstitutions: [],
  selectedDiplomaStatus: undefined,
  search: '',
  diplomas: [],
  loadingDiplomas: false,
  selectedInstitutionProfile: InstitutionProfile.Issuer,
  syncDiplomas: []
};

export interface SelectIssuerInstitutionAction {
  type: typeof SELECT_ISSUER_INSTITUTION;
  payload: {
    selectedIssuerInstitution?: Institution;
  };
}

export interface SetIssuerInstitutionsAction {
  type: typeof SET_ISSUER_INSTITUTIONS;
  payload: {
    issuerInstitutions: Institution[];
  };
}
export interface SelectDiplomaStatusAction {
  type: typeof SELECT_DIPLOMA_STATUS;
  payload: {
    selectedDiplomaStatus?: SelectedDiplomaStatus;
  };
}

export interface SetSearchAction {
  type: typeof SET_SEARCH;
  payload: {
    search: string;
  };
}

export interface SetDiplomasAction {
  type: typeof SET_DIPLOMAS;
  payload: {
    diplomas: Diploma[];
  };
}

export interface SetLoadingDiplomasAction {
  type: typeof SET_LOADING_DIPLOMAS;
  payload: {
    loadingDiplomas: boolean;
  };
}

export interface SetSelectedSyncDiplomasAction {
  type: typeof SET_SELECTED_SYNC_DIPLOMAS;
  payload: {
    syncDiplomas: Diploma[];
  };
}

interface SetSelectedInstitutionProfileAction {
  type: typeof SET_SELECTED_INSTITUTION_PROFILE;
  payload: {
    selectedProfile: InstitutionProfile;
  };
}

export type DiplomaListActions =
  | SelectIssuerInstitutionAction
  | SetIssuerInstitutionsAction
  | SelectDiplomaStatusAction
  | SetSearchAction
  | SetDiplomasAction
  | SetLoadingDiplomasAction
  | SetSelectedInstitutionProfileAction
  | SetSelectedSyncDiplomasAction;
