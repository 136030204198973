import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { Search } from 'react-feather';
import { ConnectedProps, connect, useDispatch } from 'react-redux';
import { RootState } from '../../../../../setup/reducer/reducers';
import { DIPLOMA_COLOR, GRAY_COLOR } from '../../../../../theme';
import CustomTextField from '../../../atoms/form/CustomTextField';
import * as Actions from '../store/actions';
import { setInstitutionsSigners, setLoadingInstitutionsSigners } from '../store/actions';

const PREFIX = 'InstitutionManagementFilter';

const classes = {
    root: `${PREFIX}-root`
};

const StyledGrid = styled(Grid)(() => ({
    [`&.${classes.root}`]: {
        padding: '30px',
    }
}));

const mapState = (state: RootState) => ({
    search: state.institutionManagementFilter.search
});

const mapDispatch = {
    setSearch: (search: string) => Actions.setSearch(search),
};

const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const InstitutionManagementFilter = (props: Props) => {
    const {
        search,
        setSearch
    } = props

    const dispatch = useDispatch();

    useEffect(() => {
        setLoadingInstitutionsSigners(true)
        dispatch(setInstitutionsSigners())
    }, [dispatch]);

    return (
        <StyledGrid className={classes.root} container spacing={3}>
            <Grid item xs={12} mt={2}>
                <CustomTextField
                    placeholder="Pesquise por nome da Instituição..."
                    fullWidth
                    value={search}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSearch(e.target.value)
                    }
                    InputProps={{
                        endAdornment: <Search color={search ? DIPLOMA_COLOR : GRAY_COLOR} />,
                    }}
                />
            </Grid>
        </StyledGrid>
    );
}

export default connector(InstitutionManagementFilter);