import {
  CertificateActions,
  CertificateState,
  INITIAL_STATE,
  SET_CERTIFICATE_BATCHES,
  SET_LOADING_BATCHES,
  SET_SEARCH
} from './types';

export default function reducer(
  state: CertificateState = INITIAL_STATE,
  action: CertificateActions
): CertificateState {
  switch (action.type) {
    case SET_CERTIFICATE_BATCHES:
      return {
        ...state,
        batches: action.payload.batches
      };
    case SET_LOADING_BATCHES:
      return {
        ...state,
        loadingBatches: action.payload.loadingBatches
      };
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload.search
      };
    default:
      return state;
  }
}
