import { styled } from "@mui/material/styles";
import { CurriculumSecurityInfoPaperProps } from "./types";
import { Grid, Paper } from "@mui/material";
import CardTitle from "../../molecules/card-title";
import { Lock as LockIcon } from 'react-feather';
import LabeledTooltip from "../../molecules/labeled-tooltip";

const PREFIX = 'CurriculumSecurityInfoPaper';

const classes = {
  icon: `${PREFIX}-icon`
};

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main,
  }
}));

const CurriculumSecurityInfoPaper = ({ curriculum, isLoading, validation }: CurriculumSecurityInfoPaperProps) => {
  return (
    <StyledPaper>
      <Grid container>
        <Grid item xs={12}>
          <CardTitle icon={<LockIcon className={classes.icon} />}>Informações de Segurança</CardTitle>
        </Grid>
        <Grid item xs={12}>
          <LabeledTooltip
            label="Código de validação do currículo"
            isLoading={isLoading}
            error={validation?.validationCode}
            errorMessage={validation?.validationCode}
          >
            {curriculum?.root?.validationCode || 'N/A'}
          </LabeledTooltip>
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

export default CurriculumSecurityInfoPaper
