import { styled } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import { Paper, Divider } from '@mui/material';
import CardTitle from '../../../molecules/card-title';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';


import { theme } from '../../../../../theme';
import InstitutionManagementFilter from './InstitutionManagementFilter';
import InstitutionManagementTable from './InstitutionManagementTable';
const PREFIX = 'InstitutionManagementList';

const classes = {
    root: `${PREFIX}-root`,
    icon: `${PREFIX}-icon`
};

const Root = styled('div')(() => ({
    [`&.${classes.root}`]: {
        marginBottom: '50px',
        maxHeight: '1000px',
    },
    [`& .${classes.icon}`]: {
        color: theme.palette.primary.main
    },
}));

export default function InstitutionManagementList(): JSX.Element {
    return (
        <Root>
            <Paper className={classes.root}>
                <Grid container >
                    <CardTitle icon={<AccountBalanceIcon className={classes.icon} />}>
                        Gestão da Instituição
                    </CardTitle>
                </Grid>
                <Divider />
                <>
                    <InstitutionManagementFilter />
                    <InstitutionManagementTable />
                </>
            </Paper>
        </Root>
    );
}