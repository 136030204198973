import ChangeHistory from '../../../../domain/model/ChangeHistory';
import DiplomaChangeType from '../../../../domain/enum/diploma/ChangeType';
import DiplomaStatus from '../../../../domain/enum/diploma/DiplomaStatus';
import {
  CRITICAL_COLOR,
  DARK_CYAN_COLOR,
  DIPLOMA_VALIDATION_ACTIVE_ACTION
} from '../../../../theme';
import { sortListByMostRecentDateProperty } from '../../../utils/functions';

const validCancelChangeTypes = [
  DiplomaChangeType.IssuerInstitutionCancelsDiploma,
  DiplomaChangeType.RegisterInstitutionCancelsDiploma,
  DiplomaChangeType.IssuerInstitutionTemporarilyCancelsDiploma,
  DiplomaChangeType.RegisterInstitutionTemporarilyCancelsDiploma
];

const validActivateDiplomaChangeTypes = [
  DiplomaChangeType.IssuerInstitutionActivatesDiploma,
  DiplomaChangeType.RegisterInstitutionActivatesDiploma
];

const issuerChangesRequests = [
  DiplomaChangeType.IssuerInstitutionCancelsDiploma,
  DiplomaChangeType.IssuerInstitutionActivatesDiploma,
  DiplomaChangeType.IssuerInstitutionTemporarilyCancelsDiploma
];

const registerDiplomaChangesRequests = [
  DiplomaChangeType.RegisterInstitutionCancelsDiploma,
  DiplomaChangeType.RegisterInstitutionActivatesDiploma,
  DiplomaChangeType.RegisterInstitutionTemporarilyCancelsDiploma
];

export default class ChangeHistoryManager {
  static isCompleted = (change: ChangeHistory) =>
    change.type === DiplomaChangeType.StatusUpdated &&
    change.status === DiplomaStatus.Completed;

  static isAnyCancelChangeType = (change: ChangeHistory) =>
    change.type && validCancelChangeTypes.includes(change.type);

  static isAnyActivateDiplomaChangeType = (change: ChangeHistory) =>
    change.type && validActivateDiplomaChangeTypes.includes(change.type);

  static isDiplomaIssuerRequester = (change: ChangeHistory) =>
    change.type && issuerChangesRequests.includes(change.type);

  static isDiplomaRegisterRequester = (change: ChangeHistory) =>
    change.type && registerDiplomaChangesRequests.includes(change.type);

  static isDiplomaTemporaryCancel = (change: ChangeHistory) =>
    change.type &&
    (change.type ===
      DiplomaChangeType.IssuerInstitutionTemporarilyCancelsDiploma ||
      change.type ===
        DiplomaChangeType.RegisterInstitutionTemporarilyCancelsDiploma);

  static getChangeTypeColor = (change: ChangeHistory) => {
    if (ChangeHistoryManager.isDiplomaTemporaryCancel(change)) {
      return DIPLOMA_VALIDATION_ACTIVE_ACTION;
    }

    if (ChangeHistoryManager.isAnyCancelChangeType(change)) {
      return CRITICAL_COLOR;
    }

    return DARK_CYAN_COLOR;
  };

  static getOnlyChangesAfterDiplomaConclusionSortted = (
    history: Array<ChangeHistory>
  ) =>
    history
      .filter(
        (change: ChangeHistory) =>
          ChangeHistoryManager.isCompleted(change) ||
          ChangeHistoryManager.isAnyCancelChangeType(change) ||
          ChangeHistoryManager.isAnyActivateDiplomaChangeType(change)
      )
      .sort(sortListByMostRecentDateProperty('date'));
}
