import EditModelCertificate from '../../../../../domain/model/certificate/EditModelCertificate';
import DocumentPdfService from '../../../../../services/DocumentPdfService';
import { AppThunk } from '../../../../../setup/reducer';
import {
  errorNotificationMessages,
  notificationIcons
} from '../../../../utils/constants';
import { showNotification } from '../../notification/actions';
import {
  EditModelsCertificateActions,
  SET_EDIT_MODELS_CERTIFICATE,
  SET_LOADING_EDIT_MODELS,
  SET_SEARCH
} from './types';

const internalSetLoadingEditModelsCertificate = (
  loadingEditModels: boolean
): EditModelsCertificateActions => ({
  type: SET_LOADING_EDIT_MODELS,
  payload: {
    loadingEditModels
  }
});

const internalSetSearch = (search: string): EditModelsCertificateActions => ({
  type: SET_SEARCH,
  payload: {
    search
  }
});

const internalSetEditModelsCertificate = (
  editModelsCertificate: EditModelCertificate[]
): EditModelsCertificateActions => ({
  type: SET_EDIT_MODELS_CERTIFICATE,
  payload: {
    editModelsCertificate
  }
});

export const setSearch =
  (search: string): AppThunk =>
  async (dispatch) => {
    search = search.replace(/[*|[|+|(|)]/g, '');
    dispatch(internalSetSearch(search));
  };

export const setLoadingEditModelsCertificate =
  (loadingData: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetLoadingEditModelsCertificate(loadingData));
  };

export const setEditModels = (): AppThunk => async (dispatch) => {
  let listEditModelsCertificate: EditModelCertificate[] = [];
  try {
    const response = await DocumentPdfService.getListSettingsCertificate();
    listEditModelsCertificate = response.data;
  } catch (e) {
    dispatch(
      showNotification(
        errorNotificationMessages.defaultError,
        notificationIcons.error
      )
    );
  } finally {
    dispatch(setLoadingEditModelsCertificate(false));
  }

  dispatch(internalSetEditModelsCertificate(listEditModelsCertificate));
};
