import React from 'react';

const NotFound = () => (
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M120 60C120 93.135 93.135 120 60 120C26.865 120 0 93.135 0 60C0 26.865 26.865 0 60 0C93.135 0 120 26.865 120 60Z" fill="#107887" fillOpacity="0.25" />
    <path d="M33.5851 105.33C32.1076 101.603 34.7626 91.5904 38.0626 84.9379L20.3626 76.0879C19.1251 78.5479 14.7076 87.9529 13.6426 98.0854C22.1026 108.368 33.9151 115.785 47.4151 118.665L45.1201 107.693C35.3251 109.74 34.6351 107.985 33.5851 105.33Z" fill="#806B60" />
    <path d="M30.4125 94.7479C30.4125 95.5654 29.745 96.2329 28.9275 96.2329H26.64C25.8225 96.2329 25.155 95.5654 25.155 94.7479V67.9279C25.155 67.1104 25.8225 66.4429 26.64 66.4429H28.9275C29.745 66.4429 30.4125 67.1104 30.4125 67.9279V94.7479Z" fill="#F4952C" />
    <path d="M75.9975 76.8003H50.9175V114.368H75.9975V76.8003Z" fill="#3D3531" />
    <path d="M51.9377 65.0026C51.9377 65.0026 50.1077 67.1776 50.1077 68.5876C50.1077 69.9976 50.3552 76.7926 51.1577 78.9076C51.9602 81.0151 57.9002 82.9201 66.4352 81.6001C74.9702 80.2801 80.3477 74.0851 83.9402 70.2526C87.5252 66.4201 87.4052 63.3301 87.4052 62.7151C87.4052 62.1001 86.6927 59.4976 86.6927 59.4976C86.6927 59.4976 85.9202 54.1201 84.9902 53.4376C84.0602 52.7551 61.1852 57.4576 51.9377 65.0026Z" fill="#516C85" />
    <path d="M91.7852 39.4199C91.7852 39.4199 96.7727 45.2774 97.0202 47.7074C97.2677 50.1374 90.9602 50.0174 87.7427 48.4874C84.5327 46.9649 89.4377 40.0799 91.7852 39.4199Z" fill="#5C5A58" />
    <path d="M73.9351 11.4302C73.9351 11.4302 71.0101 2.03266 66.7576 0.420161C62.5126 -1.18484 58.5151 2.06266 59.7076 6.19516C60.7801 9.90766 65.1076 10.7702 68.1976 11.1827C71.2951 11.5877 73.9351 11.4302 73.9351 11.4302Z" fill="#403E3D" />
    <path d="M73.1851 11.1225C73.1851 11.1225 78.8926 3.09754 83.4301 2.89504C87.9676 2.68504 90.7501 7.02004 88.3351 10.575C86.1601 13.77 81.7801 13.245 78.7126 12.675C75.6376 12.105 73.1851 11.1225 73.1851 11.1225Z" fill="#403E3D" />
    <path d="M47.3027 31.0953C47.3027 31.0953 45.4877 39.3378 46.1477 47.4228C46.8077 55.5003 49.4477 64.5753 51.5102 66.9678C53.5727 69.3603 56.4602 71.2578 63.3002 69.6903C70.1402 68.1228 81.4427 62.7678 85.8902 52.8678C90.3452 42.9753 89.1077 26.5653 78.3902 20.6253C69.2552 15.5628 50.2727 16.5828 47.3027 31.0953Z" fill="#FFDAC6" />
    <path d="M75.3376 31.4627C75.3376 31.4627 75.1351 39.3377 75.9976 40.6577C76.8601 41.9777 78.6751 42.6752 78.9676 44.9027C79.2526 47.1302 79.0501 55.9502 79.5451 55.8677C80.0401 55.7852 82.5151 54.3827 82.8451 53.3927C83.1751 52.4027 85.6876 44.9402 85.6876 44.9402L91.5826 47.2877C91.5826 47.2877 92.4901 41.2277 91.5826 35.7002C90.6751 30.1877 78.0151 28.9052 75.3376 31.4627Z" fill="#806B60" />
    <path d="M46.0052 29.1978C46.0052 29.1978 44.8277 27.9603 45.4502 26.2728C46.0652 24.5853 49.9052 16.1703 56.7902 12.8778C63.6752 9.57782 72.4127 10.2378 75.6752 11.4753C78.9302 12.7128 84.0002 16.7103 84.0002 23.8203C84.0002 30.9303 77.7977 34.4178 76.5002 34.1853C75.1952 33.9528 60.8252 26.5353 46.0052 29.1978Z" fill="#5C5A58" />
    <path d="M45.1576 28.125C43.2001 28.8975 38.5576 35.5425 39.4651 38.0175C40.3726 40.4925 48.0376 42.9675 59.0851 40.3275C70.1326 37.6875 75.3301 34.725 75.3301 33.735C75.3376 31.6725 58.5151 22.845 45.1576 28.125Z" fill="#696664" />
    <path d="M74.6326 11.145C74.6326 11.145 76.0426 12.1875 77.0401 17.265C79.0501 27.5325 75.3376 33.27 75.3376 33.5025C75.3376 33.735 75.1501 34.2075 75.9526 34.6425C76.7551 35.0775 85.1326 38.6925 87.7651 39.7725C90.3901 40.8525 91.9351 41.3175 92.3101 40.9125C92.6776 40.5075 93.3751 33.75 91.5751 26.4675C89.7901 19.1775 82.7176 12.3375 74.6326 11.145Z" fill="#696664" />
    <path d="M85.6877 44.9477C86.0177 43.4627 87.9752 41.9477 89.9327 43.0352C91.8902 44.1302 93.2477 46.9277 91.9877 50.7152C90.4277 55.3727 84.3677 56.2802 84.3302 53.9327C84.2852 51.5852 85.6877 44.9477 85.6877 44.9477Z" fill="#FFDAC6" />
    <path d="M106.875 97.4327C106.875 88.5527 106.875 82.1777 106.875 81.6002C106.875 79.5377 106.755 79.4927 105.023 77.2652C103.29 75.0377 92.9027 65.8877 92.9027 65.8877L54.1502 106.95L45.2702 80.5277C45.2702 80.5277 40.4627 87.3302 40.2152 88.6877C40.1102 89.2652 40.0952 102.038 40.1102 116.61C46.3352 118.8 53.0252 120 60.0002 120C78.9752 120 95.8802 111.18 106.875 97.4327Z" fill="#967E71" />
    <path d="M52.0276 108.818L47.9401 88.4853L45.2626 80.5278C45.2626 80.5278 40.4551 87.3303 40.2076 88.6878C40.1026 89.2653 40.0876 102.038 40.1026 116.61C42.3826 117.413 44.7301 118.08 47.1301 118.605V115.77L52.0276 108.818Z" fill="#856F64" />
    <path d="M54.8101 111.075L50.1076 69.645C50.1076 69.645 42.4426 82.5075 43.0576 83.0025C43.6726 83.4975 48.3751 90.915 48.3751 90.915L46.0051 98.3325L53.3251 111.075H54.8101Z" fill="#6B5A50" />
    <path d="M86.6852 59.4976L47.1377 115.77C47.1377 115.77 74.5952 107.235 75.3377 105.255C76.0802 103.275 75.3377 87.6301 75.3377 87.6301C75.3377 87.6301 86.0402 86.3326 86.6852 85.9576C87.3302 85.5826 93.7652 65.7976 93.7652 65.7976C93.7652 65.7976 88.5527 61.2901 86.6852 59.4976Z" fill="#806B60" />
    <path d="M38.7301 83.6925C38.7301 84.8775 37.7701 85.8375 36.5851 85.8375H36.2851C35.1001 85.8375 34.1401 84.8775 34.1401 83.6925V77.1675C34.1401 75.9825 35.1001 75.0225 36.2851 75.0225H36.5851C37.7701 75.0225 38.7301 75.9825 38.7301 77.1675V83.6925Z" fill="#D9B4A1" />
    <path d="M34.9952 85.9275C34.9952 89.235 32.3102 91.92 29.0027 91.92H25.6727C22.3652 91.92 19.6802 89.235 19.6802 85.9275V75.5325C19.6802 72.225 22.3652 69.54 25.6727 69.54H29.0027C32.3102 69.54 34.9952 72.225 34.9952 75.5325V85.9275Z" fill="#FFDAC6" />
    <path d="M22.8225 55.1775C22.8225 48.45 24.645 42.8025 27.09 41.28C26.6625 41.0175 26.22 40.875 25.7625 40.875C22.6725 40.875 20.1675 47.28 20.1675 55.1775C20.1675 63.075 22.6725 69.48 25.7625 69.48C26.22 69.48 26.6625 69.3375 27.09 69.075C24.645 67.5525 22.8225 61.9125 22.8225 55.1775Z" fill="#A7B7D4" />
    <path d="M31.3651 55.1778C31.3651 48.4503 29.5426 42.8028 27.0976 41.2803C24.6451 42.8028 22.8301 48.4428 22.8301 55.1778C22.8301 61.9128 24.6526 67.5528 27.0976 69.0753C29.5426 67.5528 31.3651 61.9128 31.3651 55.1778Z" fill="#DDE4F0" />
    <path d="M23.6325 55.1778C23.6325 48.8253 25.2525 43.4478 27.495 41.5803C27.36 41.4678 27.225 41.3703 27.09 41.2803C24.6375 42.8028 22.8225 48.4428 22.8225 55.1778C22.8225 61.9128 24.645 67.5528 27.09 69.0753C27.225 68.9928 27.36 68.8878 27.495 68.7753C25.26 66.9153 23.6325 61.5303 23.6325 55.1778Z" fill="#C1D3F5" />
    <path d="M30.075 40.8828H25.77C25.77 40.8828 26.4675 40.8978 27.0975 41.2878C29.55 42.8103 31.365 48.4503 31.365 55.1853C31.365 61.9203 29.5425 67.5603 27.0975 69.0828C26.5425 69.4278 26.0625 69.4803 25.8675 69.4878H30.075C33.165 69.4878 35.67 63.0828 35.67 55.1853C35.67 47.2803 33.165 40.8828 30.075 40.8828Z" fill="#84878F" />
    <path d="M51.9225 56.1229C51.9225 56.1229 53.82 53.9329 57.18 54.7129C60.5325 55.4929 63.315 60.0679 65.8875 59.9329C63.93 61.9954 61.185 63.0529 58.245 63.0529C55.305 63.0529 52.1025 61.1329 51.8325 60.5104C51.375 61.5829 49.5 62.5954 48.075 62.7229C46.65 62.8504 44.8275 61.3879 44.6025 60.1429C45.285 60.1279 45.9225 58.8154 46.9875 57.0529C48.09 55.2379 50.4675 54.8179 51.9225 56.1229Z" fill="#806B60" />
    <path d="M52.4551 46.2599C51.3301 46.2224 47.8426 52.4024 48.5401 54.4199C49.2376 56.4374 51.7501 56.4374 53.0701 55.8599C54.3901 55.2824 54.8026 54.4574 54.8026 52.9724C54.8101 51.5024 53.8201 46.3049 52.4551 46.2599Z" fill="#FF9985" />
    <path d="M63.7952 44.7525C63.7952 45.9075 63.1277 46.845 62.3102 46.845C61.4927 46.845 60.8252 45.9075 60.8252 44.7525C60.8252 43.5975 61.4927 42.6675 62.3102 42.6675C63.1277 42.66 63.7952 43.5975 63.7952 44.7525Z" fill="#806B60" />
    <path d="M49.5301 44.4602C49.5301 45.4577 48.9601 46.2602 48.2476 46.2602C47.5426 46.2602 46.9651 45.4577 46.9651 44.4602C46.9651 43.4627 47.5351 42.6602 48.2476 42.6602C48.9601 42.6602 49.5301 43.4702 49.5301 44.4602Z" fill="#806B60" />
    <path d="M113.633 86.8876C111.113 83.1526 108.045 79.6951 104.46 76.6201C104.46 76.6201 101.595 84.1876 98.2427 99.6451C99.1277 101.138 99.8552 102.698 100.44 104.31C105.84 99.3901 110.318 93.4801 113.633 86.8876Z" fill="#806B60" />
  </svg>

);

export default NotFound;
