import Employee from '../../../../domain/model/employee/Employee';
import EmployeeService from '../../../../services/EmployeeService';
import { searchIgnoringCaseAndAccents } from '../../../utils/functions';
import { HeadCell, Order, UsersManagementData } from './types';

export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

export const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key
): ((
  a: { [key in Key]: number | string | string[] },
  b: { [key in Key]: number | string | string[] }
) => number) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

export const stableSort = <T>(
  array: T[],
  comparator: (a: T, b: T) => number
) => {
  if (array.length === 0) {
    return array;
  }
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

export const headCells: HeadCell[] = [
  {
    id: 'position',
    label: 'Perfil de acesso',
    size: '3%',
    align: 'left',
    sortable: true
  },
  {
    id: 'name',
    label: 'Nome',
    size: '10%',
    align: 'left',
    sortable: true
  },
  {
    id: 'cpf',
    label: 'CPF',
    size: '10%',
    align: 'left',
    sortable: false
  },
  {
    id: 'email',
    label: 'E-mail',
    size: '5%',
    align: 'left',
    sortable: false
  },
  {
    id: 'action',
    label: 'Ações',
    size: '5%',
    align: 'center',
    sortable: false
  }
];

export const listUsersManagement = async (): Promise<Employee[]> => {
  const response = await EmployeeService.listEmployees();
  return response.data;
};

export const getRows = (
  employees: Employee[],
  search: string
): UsersManagementData[] => {
  let rows: UsersManagementData[] = [];

  if (!employees) {
    return rows;
  }

  employees.forEach((value) => {
    rows.push({
      id: value.id,
      position: value.canSignDocuments ? 'ASSINANTE' : 'OPERADOR',
      name: value.name,
      cpf: value.cpf,
      email: value.email,
      action: ''
    });
  });

  if (search.length >= 2) {
    search = search.toLowerCase();
    rows = rows.filter(
      (value) =>
        searchIgnoringCaseAndAccents(value?.name, search) ||
        value.name?.toLowerCase().match(`.*${search}.*`)
    );
  }

  return rows;
};
