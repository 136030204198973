import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { RootState } from '../../../../../setup/reducer/reducers';
import { getDefaultGreetingMessage } from '../../../../utils/functions';
import PageTitle from '../../../molecules/page-title';
import ManagementCard from './ManagementCard';

const urlSigner = process.env.REACT_APP_DIPLOMA_SIGNER_URL;

const ManagementPage = () => {
  const user = useSelector((state: RootState) => state.login.user);
  const pageTitle = getDefaultGreetingMessage(user?.name);

  return (
    <>
      <PageTitle
        message={pageTitle.message}
        highlightedMessage={pageTitle.highlightedMessage}
      />
      <Box display="flex" justifyContent="center">
        <ManagementCard link={urlSigner} />
      </Box>
    </>
  );
};

export default ManagementPage;
