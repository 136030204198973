import Employee from '../../../../../domain/model/employee/Employee';
import { AppThunk } from '../../../../../setup/reducer';
import {
  errorNotificationMessages,
  notificationIcons
} from '../../../../utils/constants';
import { showNotification } from '../../notification/actions';
import { listUsersManagement } from '../UsersManagementTableManager';
import {
  SET_LOADING_USERS,
  SET_MODAL_USERS,
  SET_SEARCH,
  SET_USERS_MANAGEMENT,
  UsersManagementActions
} from './types';

const internalSetLoadingUsers = (
  loadingEmployees: boolean
): UsersManagementActions => ({
  type: SET_LOADING_USERS,
  payload: {
    loadingEmployees
  }
});

const internalSetSearch = (search: string): UsersManagementActions => ({
  type: SET_SEARCH,
  payload: {
    search
  }
});

export const setSearch =
  (search: string): AppThunk =>
  async (dispatch) => {
    search = search.replace(/[*|[|+|(|)]/g, '');
    dispatch(internalSetSearch(search));
  };

export const setLoadingEmployees =
  (loadingData: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetLoadingUsers(loadingData));
  };

const internalSetUsers = (employees: Employee[]): UsersManagementActions => ({
  type: SET_USERS_MANAGEMENT,
  payload: {
    employees
  }
});

const internalSetUsersModalAction = (
  openUsersModal: boolean,
  typeSubmit: string
): UsersManagementActions => ({
  type: SET_MODAL_USERS,
  payload: {
    openUsersModal,
    typeSubmit
  }
});

export const setUsersModalAction =
  (openUsersModal: boolean, typeSubmit: string): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetUsersModalAction(openUsersModal, typeSubmit));
  };

export const setUsers = (): AppThunk => async (dispatch) => {
  let employees: Employee[] = [];

  try {
    employees = await listUsersManagement();
  } catch (e) {
    dispatch(
      showNotification(
        errorNotificationMessages.defaultError,
        notificationIcons.error
      )
    );
  }

  dispatch(internalSetUsers(employees));
  dispatch(setLoadingEmployees(false));
};
