import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { FONT_FAMILY_SEMIBOLD, INFO_COLOR } from '../../../../theme';

export const useCardCollapseStyles = makeStyles(() => createStyles({
  showContentLabelContainer: {
    cursor: 'pointer',
    textAlign: 'right',
    fontFamily: FONT_FAMILY_SEMIBOLD,
  },
  infoColorText: {
    color: INFO_COLOR,
  },
  headerContainer: {
    padding: '0 1.5rem',
    minHeight: '3.125rem',
  },
  collapseContainer: {
    padding: '1.25rem 1.5rem',
  },
  root: {
    marginBottom: '40px',
  },
}));
