import Employee from '../../../../../domain/model/employee/Employee';

export const SET_USERS_MANAGEMENT = 'app/users-management/SET_USERS_MANAGEMENT';
export const SET_LOADING_USERS = 'app/users-management/SET_LOADING_USERS';
export const SET_SEARCH = 'app/users-management/SET_SEARCH';
export const SET_MODAL_USERS = 'app/users-management/SET_MODAL_USERS';
export interface UsersManagementState {
  search: string;
  employees: Employee[];
  loadingEmployees: boolean;
  openUsersModal: boolean;
  typeSubmit: string;
}

export const INITIAL_STATE: UsersManagementState = {
  search: '',
  employees: [],
  loadingEmployees: false,
  openUsersModal: false,
  typeSubmit: ''
};

interface SetUsersActions {
  type: typeof SET_USERS_MANAGEMENT;
  payload: {
    employees: Array<Employee>;
  };
}

interface SetLoadingUsersAction {
  type: typeof SET_LOADING_USERS;
  payload: {
    loadingEmployees: boolean;
  };
}

interface SetSearchAction {
  type: typeof SET_SEARCH;
  payload: {
    search: string;
  };
}

interface SetUsersModal {
  type: typeof SET_MODAL_USERS;
  payload: {
    openUsersModal: boolean;
    typeSubmit: string;
  };
}

export type UsersManagementActions =
  | SetUsersActions
  | SetLoadingUsersAction
  | SetUsersModal
  | SetSearchAction;
