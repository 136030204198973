import Root from '../../../../../domain/model/curriculum/Root';
import Institution from '../../../../../domain/model/institution/Institution';
import { AppThunk } from '../../../../../setup/reducer';
import {
  errorNotificationMessages,
  notificationIcons
} from '../../../../utils/constants';
import { listCurriculums } from '../../digital-curriculum-page/CurriculumTableManager';
import { showNotification } from '../../notification/actions';
import {
  CurriculumActions,
  SET_CURRICULUMS,
  SET_LOADING_CURRICULUMS,
  SET_SELECTED_SYNC_CURRICULUMS,
  SET_CURRICULUMS_FILTER,
  CurriculumsFilter
} from './types';

const internalSetCurriculums = (curriculums: Root[]): CurriculumActions => ({
  type: SET_CURRICULUMS,
  payload: {
    curriculums
  }
});

const internalSetLoadingCurriculums = (
  loadingCurriculums: boolean
): CurriculumActions => ({
  type: SET_LOADING_CURRICULUMS,
  payload: {
    loadingCurriculums
  }
});

export const setLoadingCurriculums =
  (loadingData: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetLoadingCurriculums(loadingData));
  };

export const setSyncCurriculums =
  (syncCurriculums: Root[]): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetSyncCurriculums(syncCurriculums));
  };

const internalSetSyncCurriculums = (
  syncCurriculums: Root[]
): CurriculumActions => ({
  type: SET_SELECTED_SYNC_CURRICULUMS,
  payload: {
    syncCurriculums
  }
});

export const setCurriculums =
  (selectedIssuerIes?: Institution): AppThunk =>
  async (dispatch) => {
    let curriculums: Root[] = [];

    try {
      if (!selectedIssuerIes) {
        return [];
      }
      curriculums = await listCurriculums(selectedIssuerIes.mecCode);
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
    }
    dispatch(internalSetCurriculums(curriculums));
    dispatch(internalSetLoadingCurriculums(false));
  };

const internalSetCurriculumsFilter = (
  filter: CurriculumsFilter
): CurriculumActions => ({
  type: SET_CURRICULUMS_FILTER,
  payload: {
    filter
  }
});

export const setCurriculumsFilter =
  (filter: CurriculumsFilter): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetCurriculumsFilter(filter));
  };
