import { Grid, Paper, TableContainer } from '@mui/material';
import { styled } from '@mui/material/styles';
import CardTitle from '../../molecules/card-title';
import { Grid as GridIcon } from 'react-feather';
import { CurricularUnityAndValidations, CurriculumCurricularUnitiesPaperProps } from './types';
import TableLoading from '../../molecules/table-loading';
import Curriculum from '../../../../domain/model/curriculum/Curriculum';
import CurriculumType from '../../../../domain/enum/curriculum/CurriculumType';
import React from 'react';
import E2sCurriculumTable from '../../molecules/curricular-unities-tables/CurricularUnitiesE2STable';
import LegacyCurriculumTable from '../../molecules/curricular-unities-tables/CurricularUnitiesLegacyTable';
import TablePaginationFooter from '../../molecules/table-pagination-footer';

const PREFIX = 'CurriculumCurricularUnitiesPaper';

const classes = {
  icon: `${PREFIX}-icon`,
  table: `${PREFIX}-table`,
};
const pageSize = 10;

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${classes.table}`]: {
    padding: '0px 32px 24px 32px',
  }
}));

const renderContent = (
  isLoading: boolean,
  curricularUnitysAndValidations: CurricularUnityAndValidations[],
  pageStartIndex: number,
  curriculum?: Curriculum
) => {
  const isIntegratedCurriculum = curriculum?.root?.curriculumType === CurriculumType.Integrated;

  if (isLoading) {
    return <TableLoading columns={5} rows={2} />
  }
  return isIntegratedCurriculum
    ? <E2sCurriculumTable
      curriculum={curriculum}
      curricularUnityAndValidation={curricularUnitysAndValidations}
      pageStartIndex={pageStartIndex}
    />
    : <LegacyCurriculumTable
      curricularUnityAndValidation={curricularUnitysAndValidations}
      pageStartIndex={pageStartIndex}
    />;
}

const CurriculumCurricularUnitiesPaper = ({ curriculum, isLoading, validation }: CurriculumCurricularUnitiesPaperProps) => {
  const [page, setPage] = React.useState(0);

  const curricularUnityAndValidations = curriculum?.curricularUnities.map((row, index) => {
    return {
      ...row,
      validation: validation?.[index]
    }
  });

  return (
    <StyledPaper>
      <Grid container>
        <Grid item xs={12}>
          <CardTitle icon={<GridIcon className={classes.icon} />}>Matriz Curricular</CardTitle>
        </Grid>
        <Grid item xs={12}>
          <TableContainer className={classes.table}>
            {renderContent(isLoading, curricularUnityAndValidations ?? [], page, curriculum)}
            <TablePaginationFooter
              numberOfItems={curriculum?.curricularUnities.length ?? 0}
              pageStartIndex={page}
              pageSize={pageSize}
              setPage={setPage}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

export default CurriculumCurricularUnitiesPaper;
