import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import SchoolIcon from '@mui/icons-material/SchoolOutlined';
import CardTitle from '../../molecules/card-title';
import LabeledTooltip from '../../molecules/labeled-tooltip';
import { CurriculumCoursePaperProps } from './types';
import RegulatoryAct from '../../../../domain/model/RegulatoryAct';

const PREFIX = 'index';

const classes = {
  icon: `${PREFIX}-icon`
};

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main,
  }
}));

export default function CurriculumCoursePaper({ course, isLoading, validation }: CurriculumCoursePaperProps) {

  const getErrorMessage = (errorObject: { [key: string]: string } | undefined, type: string | undefined): string | undefined => {
    if (!isLoading && errorObject) {
      switch (type) {
        case 'authorization':
          return 'Autorização inválida';
        case 'recognition':
          return 'Reconhecimento inválido';
        case 'recognitionRenewal':
          return 'Renovação de Reconhecimento inválido';
        default:
          return 'Erro desconhecido';
      }
    }
    return undefined;
  }

  return (
    <StyledPaper>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <CardTitle icon={<SchoolIcon className={classes.icon} />}>Curso</CardTitle>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip
            label="Nome"
            isLoading={isLoading}
            error={validation?.name}
            errorMessage={validation?.name}
            data-testid="curriculum-course-name">
            {course?.name || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={4}>

        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip
            label="Código do Curso EMEC"
            isLoading={isLoading}
            error={validation?.emecCode}
            errorMessage={validation?.emecCode}
            data-testid="curriculum-course-emec">
            {course?.emecCode?.toString() || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip
            label="Nome da Habilitação"
            isLoading={isLoading}
            error={validation?.qualificationName}
            errorMessage={validation?.qualificationName}
            data-testid="curriculum-course-qualification-name">
            {(course && course.qualificationName) ? course.qualificationName : 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={4}>

        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip label="Data da Habilitação" isLoading={isLoading} data-testid="curriculum-course-qualification-date">
            {'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={12}>
          <LabeledTooltip
            label="Autorização"
            isLoading={isLoading}
            error={!isLoading ? validation?.authorization : null}
            errorMessage={getErrorMessage(validation?.authorization, 'authorization')}
            data-testid="curriculum-course-authorization">
            {!isLoading ? RegulatoryAct.stringify(course?.authorization) || 'N/A' : null}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={12}>
          <LabeledTooltip
            label="Reconhecimento"
            error={!isLoading ? validation?.recognition : null}
            errorMessage={getErrorMessage(validation?.recognition, 'recognition')}
            data-testid="curriculum-course-recognition">
            {!isLoading ? RegulatoryAct.stringify(course?.recognition) || 'N/A' : null}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={12}>
          <LabeledTooltip
            label="Renovação de Reconhecimento"
            error={!isLoading ? validation?.recognitionRenewal : null}
            errorMessage={getErrorMessage(validation?.recognitionRenewal, 'recognitionRenewal')}
            data-testid="curriculum-course-recognition-renewal">
            {!isLoading ? RegulatoryAct.stringify(course?.recognitionRenewal) || 'N/A' : null}
          </LabeledTooltip>
        </Grid>
      </Grid>
    </StyledPaper>
  );
}
