import { Check, AlertTriangle } from 'react-feather';
import InspectionDocumentResponse from '../../../../domain/interface/response/InspectionDocumentResponse';
import { styled, Typography } from '@mui/material';
import InspectionDocumentStatus from '../../../../domain/enum/inspectionDocument/InspectionDocumentStatus';
import { DARK_GRAY_COLOR } from '../../../../theme';

const PREFIX = 'GenerateInspectionDocumentFeedback';

const classes = {
    feedbackContainer: `${PREFIX}-feedbackContainer`,
    feedbackIcon: `${PREFIX}-feedbackIcon`,
    feedbackText: `${PREFIX}-feedbackText`
};

const Root = styled('div')(() => ({
    [`& .${classes.feedbackContainer}`]: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '16px',
    },
    [`& .${classes.feedbackIcon}`]: {
        flex: 1,
        color: DARK_GRAY_COLOR
    },
    [`& .${classes.feedbackText}`]: {
        flex: 11,
    }
}));

type GenerateInspectionDocumentFeedbackProps = {
    document: InspectionDocumentResponse;
};

const GenerateInspectionDocumentFeedback = ({ document }: GenerateInspectionDocumentFeedbackProps) => {
    const generateFeedbackText = () => {
        if (document.status === InspectionDocumentStatus.Generated) {
            return `${document.documentCode} - Geração de Arquivo de Fiscalização (${document.context}) iniciada em ${document.processStartDate}.`;
        }

        return `Geração de Arquivo de Fiscalização (${document.context}) não foi iniciada com sucesso. Por favor tente novamente.`;
    }

    return (
        <Root>
            <div className={classes.feedbackContainer}>
                {
                    document.status === InspectionDocumentStatus.Generated ?
                        <Check size={20} className={classes.feedbackIcon} /> :
                        <AlertTriangle size={20} className={classes.feedbackIcon} />
                }
                <Typography className={classes.feedbackText}>
                    {generateFeedbackText()}
                </Typography>
            </div>
        </Root>
    );
};

export default GenerateInspectionDocumentFeedback;
