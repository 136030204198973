import {
  AuthorizationActions,
  AuthorizationState,
  INITIAL_STATE,
  SET_SHOW_SIGN_DOCUMENTS,
  SET_EMPLOYEE
} from './types';

export default function reducer(
  state: AuthorizationState = INITIAL_STATE,
  action: AuthorizationActions
): AuthorizationState {
  switch (action.type) {
    case SET_SHOW_SIGN_DOCUMENTS:
      return {
        ...state,
        showSignDocuments: action.payload.showSignDocuments
      };
    case SET_EMPLOYEE:
      return {
        ...state,
        employee: action.payload.employee
      };
    default:
      return state;
  }
}
