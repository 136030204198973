import { Grid } from '@mui/material';
import { School } from '@mui/icons-material';
import React from 'react';
import AddressDto from '../../../../domain/interface/dto/AddressDto';
import RegulatoryActDto from '../../../../domain/interface/dto/RegulatoryActDto';
import { DIPLOMA_COLOR } from '../../../../theme';
import CardTitle from '../card-title';
import BasePublicDataWidget from '../base-public-data-widget';
import InlineLabeledText from '../inline-labeled-text';
import { PublicCourseDataWidgetProps } from './types';

function PublicCourseDataWidget({ course, isLoading }: PublicCourseDataWidgetProps): JSX.Element {
  return (
    <BasePublicDataWidget title={<CardTitle variant="h3" icon={<School htmlColor={DIPLOMA_COLOR} />}>Dados do Curso</CardTitle>}>
      <>
        <Grid item container>
          <InlineLabeledText label="Nome" text={course?.name} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Código EMEC" text={course?.emecCode.toString()} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Habilitação" text={course?.qualificationName} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Modalidade" text={course?.modality} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Título Conferido" text={course?.conferredTitle} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Grau Conferido" text={course?.conferredDegree} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Endereço" text={AddressDto.stringify(course?.address)} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Autorização" text={RegulatoryActDto.stringify(course?.authorization)} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Reconhecimento" text={RegulatoryActDto.stringify(course?.recognition)} isLoading={isLoading} />
        </Grid>
        {course?.recognitionRenewal ? (
          <Grid item container>
            <InlineLabeledText label="Renovação de Reconhecimento" text={RegulatoryActDto.stringify(course?.recognitionRenewal)} isLoading={isLoading} />
          </Grid>
        ) : null}
      </>
    </BasePublicDataWidget>
  );
}

PublicCourseDataWidget.defaultProps = {
  isLoading: false,
};

export default PublicCourseDataWidget;
