
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ChangeType from '../../../../domain/enum/curriculum/ChangeType';
import CurriculumService from '../../../../services/CurriculumService';
import ConfirmationModal from '../confirmation-modal';
import { useState } from 'react';
import { DIPLOMA_COLOR } from '../../../../theme';
import SelectAutocomplete from '../../molecules/select-autocomplete';
import CurriculumCanceledModalManager from '../../pages/digital-curriculum-page/CurriculumCanceledModalManager';
import { validation, modalInformation, errorNotificationMessages, notificationIcons } from '../../../utils/constants';
import BackdropLoading from '../../molecules/backdrop-loading';
import { ConnectedProps, connect, useDispatch } from 'react-redux';
import { showNotification } from '../../pages/notification/actions';
import Institution from '../../../../domain/model/institution/Institution';
import { RootState } from '../../../../setup/reducer/reducers';
import * as Actions from '../../pages/digital-curriculum-page/store/actions';

export interface CurriculumCanceledModalProps {
  isOpen: boolean;
  handleClose: (success: boolean) => void;
  curriculumId: string;
}

const PREFIX = 'RestartCurriculumFlow';

const classes = {
  spaced: `${PREFIX}-spaced`,
  helperIcon: `${PREFIX}-helperIcon`,
  descriptionLoading: `${PREFIX}-descriptionLoading`,
  circularProgress: `${PREFIX}-circularProgress`,
  grid: `${PREFIX}-grid`,
  select: `${PREFIX}-textField`,
  errorIcon: `${PREFIX}-errorIcon`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.spaced}`]: {
    marginBottom: theme.spacing(4)
  },

  [`& .${classes.helperIcon}`]: {
    color: 'rgba(244, 67, 54, 1)',
    width: '14.33px',
    height: '14.33px'
  },

  [`& .${classes.errorIcon}`]: {
    color: theme.palette.error.main
  },

  [`& .${classes.descriptionLoading}`]: {
    width: '19.938rem',
    color: DIPLOMA_COLOR
  },

  [`& .${classes.circularProgress}`]: {
    color: DIPLOMA_COLOR
  },

  [`& .${classes.grid}`]: {
    width: '416px'
  },

  [`& .${classes.select}`]: {
    width: '400px'
  }
}));

const mapState = (state: RootState) => ({
  selectedInstitution: state.header.institution as Institution
});

const connector = connect(mapState);

type Props = ConnectedProps<typeof connector>;

const DigitalCurriculumCanceledModal = (props: CurriculumCanceledModalProps & Props) => {
  const [selectedReason, setSelectedReason] = useState('');
  const [reasonValidationError, setReasonValidationError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const handleConfirm = async () => {
    if (!selectedReason) {
      setReasonValidationError(validation.errorMessages.requiredField);
      return;
    }

    const { curriculumId } = props;
    const idWithoutPrefix = curriculumId.includes('#') ? curriculumId.split('#')[1] : curriculumId;
    const request = {
      type: ChangeType.IssuerInstitutionCanceledCurriculum,
      reasonType: selectedReason
    };

    setIsLoading(true);

    try {
      await CurriculumService.addChangeCurriculum(request, idWithoutPrefix);
      dispatch(Actions.setLoadingCurriculums(true));
      dispatch(Actions.setCurriculums(props.selectedInstitution));
      props.handleClose(true);
    } catch (err: any) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
      setIsLoading(false);
    }
  }

  return (
    <ConfirmationModal
      modalTitle={modalInformation.modalCancelCurriculum.title}
      textCancelButton={modalInformation.modalCancelCurriculum.textAbort}
      textContinueButton={modalInformation.modalCancelCurriculum.textConfirm}
      handleCancelButton={() => props.handleClose(false)}
      handleContinueButton={handleConfirm}
      openModal={props.isOpen}>
      <StyledGrid
        container
        justifyContent='center'
        alignItems='stretch'
        className={classes.grid}
      >
        <BackdropLoading
          size={50}
          description={modalInformation.modalCancelCurriculum.textLoading}
          open={isLoading}
          classes={classes} />
        <Grid item>
          <Typography className={classes.spaced}>
            {modalInformation.modalCancelCurriculum.textDescription}
          </Typography>
          <Grid item>
            <Typography variant='caption'>
              {modalInformation.modalCancelCurriculum.textReasaonSelectionLabel}
            </Typography>
          </Grid>
          <SelectAutocomplete
            compareName
            defaultValue={selectedReason}
            listItems={CurriculumCanceledModalManager.CurriculumCanceledReasonOptions}
            placeholder={modalInformation.modalCancelCurriculum.textReasonSelectionPlaceholder}
            setItem={setSelectedReason}
            helperText={reasonValidationError}
            error={!!reasonValidationError}
          />
        </Grid>
      </StyledGrid>
    </ConfirmationModal>
  );
}

export default connector(DigitalCurriculumCanceledModal);
