import getAxiosInstance from '../../../apis/axiosInstance';

interface DownloadParams {
  fileExtension: 'application/pdf' | 'application/xml',
  fileName: string,
}

type DownloadBase64FileParams = DownloadParams & {
  base64: string,
};

export const downloadBase64File = async ({
  base64, fileName, fileExtension,
}: DownloadBase64FileParams) => {
  const a = document.createElement('a');
  a.href = `data:${fileExtension};base64,${base64}`;
  a.download = fileName;
  a.click();
};

type DownloadBase64UrlFileParams = DownloadParams & {
  base64Url: string,
};

export const downloadBase64UrlFile = async ({
  base64Url, fileName, fileExtension,
}: DownloadBase64UrlFileParams) => {
  const res = await getAxiosInstance(base64Url).get('');
  const base64 = res.data;

  downloadBase64File({
    base64,
    fileName,
    fileExtension,
  });
};
