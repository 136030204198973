import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Download, File, Upload } from 'react-feather';
import { DropzoneArea } from 'react-mui-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import CreatePresignedPostRequest from '../../../../../domain/interface/request/CreatePresignedPostRequest';
import InsertCertificateBatchRequest from '../../../../../domain/interface/request/InsertCertificateBatchRequest';
import CreatePresignedPostResponse from '../../../../../domain/interface/response/CreatePresignedPostResponse';
import CreateSignedUrlResponse from '../../../../../domain/interface/response/CreateSignedUrlResponse';
import CertificateService from '../../../../../services/CertificateService';
import DocumentService from '../../../../../services/DocumentService';
import StorageService from '../../../../../services/StorageService';
import { RootState } from '../../../../../setup/reducer/reducers';
import { SUCCESS_UPLOAD_COLOR } from '../../../../../theme';
import { errorNotificationMessages, modalInformation, notificationIcons, pageRoutes, successNotificationMessages } from '../../../../utils/constants';
import { formatBytes } from '../../../../utils/functions';
import CustomTextField from '../../../atoms/form/CustomTextField';
import BackCardHeader from '../../../molecules/back-card-header';
import BackdropLoading from '../../../molecules/backdrop-loading';
import CardTitle from '../../../molecules/card-title';
import ConfirmationModal from '../../../organisms/confirmation-modal';
import DefaultTemplate from '../../../templates/default-template';
import * as Actions from '../../certificate-page/store/actions';
import { showNotification, showSuccessNotification } from '../../notification/actions';

const PREFIX = 'IssuanceCertificate';

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  buttonUpload: `${PREFIX}-buttonUpload`,
  gridDropzone: `${PREFIX}-gridDropzone`,
  gridDropzoneDisabled: `${PREFIX}-gridDropzoneDisabled`,
  dropZoneBox: `${PREFIX}-dropZoneBox`,
  dropZoneBoxP: `${PREFIX}-dropZoneBoxP`,
  dropZoneBoxAdd: `${PREFIX}-dropZoneBoxAdd`,
  gridIcon: `${PREFIX}-gridIcon`,
  gridIconFile: `${PREFIX}-gridIconFile`,
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    paddingBottom: theme.spacing(7)
  },
  [`& .${classes.buttonUpload}`]: {
    marginTop: '0.5rem',
    color: '#107887',

  },
  [`& .${classes.gridDropzone}`]: {
    justifyContent: 'center',
    paddingBottom: '3%',
    marginTop: '3%'
  },
  [`& .${classes.gridDropzoneDisabled}`]: {
    justifyContent: 'center',
    paddingBottom: '3%',
    pointerEvents: 'none',
    marginTop: '3%'
  },
  [`& .${classes.dropZoneBox}`]: {
    background: '#F0F0F0',
    borderColor: '#c8c8c8',
    borderSize: '10px',
    borderRadius: '10px',
    borderStyle: 'dashed',
    borderWidth: '2px',
    height: '180px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  [`& .${classes.dropZoneBoxP}`]: {
    textAlign: 'center',
  },
  [`& .${classes.dropZoneBoxAdd}`]: {
    textAlign: 'center',
    color: SUCCESS_UPLOAD_COLOR
  },
  [`& .${classes.gridIcon}`]: {
    color: '#737677',
  },
  [`& .${classes.gridIconFile}`]: {
    color: SUCCESS_UPLOAD_COLOR
  }
}));

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main,
  }
}));

const IssuanceCertificatePage = () => {
  const [description, setDescription] = useState('');
  const [fileAdd, setFileAdd] = useState(false);
  const [file, setFile] = useState<Blob>();
  const [openConfirmationModal, setConfirmationModal] =
    useState(false);
  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();

  let iconComponent: React.ReactElement;
  if (fileAdd) {
    iconComponent = <File className={classes.gridIconFile} size={'50'} />;
  } else {
    iconComponent = <Upload className={classes.gridIcon} size={'50'} />;
  }

  const renderIcon = () => iconComponent;

  const handleDescription = (event: any) => {
    let value = event.target.value;

    if (value.length <= 100) {
      setDescription(value)
    }

  }

  const history = useHistory();

  const navigateBackToMicrocertificatesOnClick = () => {
    history.push(pageRoutes.Certificate)
  }

  const dropRejectMessage = (rejectedFile: File, acceptedFiles: string[], maxFileSize: number) => {
    let message: string = "Arquivo inválido.";

    if (rejectedFile.type !== acceptedFiles[0]) {
      message += 'Apenas o formato "' + acceptedFiles[0] + '" é permitido.';
    }
    if (rejectedFile.size > maxFileSize) {
      message += 'O tamanho do arquivo é maior que o tamanho máximo permitido de "' + formatBytes(maxFileSize) + '".';
    }

    return message;
  }

  const openDialog = (files: File[]) => {

    const document = files[0];
    const blob = document.slice(0, document.size);

    setFile(blob);
    setConfirmationModal(true);
  }

  const user = useSelector((state: RootState) => state.login.user);

  async function handleSubmit() {
    const fileId = uuidv4();
    try {
      setLoading(true);
      const preSignedUrl: CreatePresignedPostResponse = (await DocumentService.createPresignedPost({ key: `${fileId}.xlsx`, acessKey: 'upload-certificate-sheet' } as CreatePresignedPostRequest)).data;

      if (file && fileId) {
        const formData = new FormData();
        Object.entries(preSignedUrl.fields).forEach(([key, value]) => {
          formData.append(key, value)
        });
        formData.append(`file`, file);

        const request = {
          id: fileId,
          description: description,
          user: `${user?.login.split('@')[0]}`
        } as InsertCertificateBatchRequest

        StorageService.uploadFileToBucket(preSignedUrl.url, `${fileId}.xlsx`, formData);

        let response = await CertificateService.insertCertificateBatch(request);

        if (response.status === 201) {
          dispatch(showSuccessNotification(successNotificationMessages.sucessInsertingCertificateBatch));
          setFileAdd(true);
          setConfirmationModal(false);
          setLoading(false);
          history.push(pageRoutes.Certificate);
          dispatch(Actions.setLoadingBatchesCertificates(true));
          dispatch(Actions.setBatches());
        } else {
          setLoading(false);
          dispatch(showNotification(errorNotificationMessages.faileldInsertingCertificateBatch, notificationIcons.error));
        }
      }
    } catch (error) {
      setLoading(false);
      setConfirmationModal(false);
      dispatch(
        showNotification(
          errorNotificationMessages.faileldInsertingCertificateBatch,
          notificationIcons.error
        )
      );
    }
  }

  function handleCancel() {
    setFile(undefined)
    setConfirmationModal(false)
  }

  const handleDownloadFile = async () => {
    const response: CreateSignedUrlResponse = (await DocumentService.CreateSignedUrl({ key: `${process.env.REACT_APP_DEFAULT_SHEET_NAME}` })).data;

    const link = document.createElement('a');
    link.href = response.url;
    link.download = `${process.env.REACT_APP_DEFAULT_SHEET_NAME}`;
    link.click();
  }

  return (
    <Root>
      <DefaultTemplate message="Emissão de" highlightedMessage="Certificados">
        <StyledPaper>
          <BackCardHeader onClick={() => navigateBackToMicrocertificatesOnClick()} />

          <Grid container item xs={12}>
            <Grid item xs={12}>
              <CardTitle icon={<DescriptionOutlinedIcon className={classes.icon} />}>Descrição</CardTitle>
            </Grid>

            <Grid container item xs={12} sx={{ alignItems: 'center', margin: '0 2rem' }}>

              <CustomTextField
                placeholder="Digite a descrição (máximo de 100 caracteres)..."
                type="text"
                style={{ width: '100%' }}
                value={description}
                onChange={handleDescription}
              />
              <Button variant="text" size="medium" startIcon={<Download />} onClick={handleDownloadFile} className={classes.buttonUpload} >Modelo de Arquivo XLSX</Button>


              <Grid item className={description.length === 0 ? classes.gridDropzoneDisabled : classes.gridDropzone} xs={12}>
                <Typography variant="body2" color={'primary'}>Planilha de Certificados</Typography>

                <DropzoneArea
                  Icon={renderIcon}
                  showPreviewsInDropzone={false}
                  showPreviews={false}
                  showFileNamesInPreview={false}
                  showAlerts={['error', 'info']}
                  getDropRejectMessage={dropRejectMessage}
                  filesLimit={1}
                  maxFileSize={100000000}
                  dropzoneText={fileAdd ? 'Arquivo Anexado' : 'Arraste e Solte ou Clique aqui para inserir o arquivo'}
                  dropzoneClass={classes.dropZoneBox}
                  dropzoneParagraphClass={fileAdd ? classes.dropZoneBoxAdd : classes.dropZoneBoxP}
                  onDrop={(files) => openDialog(files)}
                  acceptedFiles={['.xlsx']}
                />
                {description.length === 0 && <Typography variant="body2" style={{ fontSize: '0.8rem' }} color={'red'}>Preencha o campo "Descrição" para habilitar o carregamento.</Typography>}
              </Grid>
            </Grid>
          </Grid>
        </StyledPaper>
      </DefaultTemplate>
      <ConfirmationModal
        openModal={openConfirmationModal}
        modalTitle={modalInformation.modalCertificateFile.title}
        textContinueButton={modalInformation.modalCertificateFile.textContinue}
        textCancelButton={modalInformation.modalCertificateFile.textCancel}
        loading={undefined}
        handleContinueButton={handleSubmit}
        handleCancelButton={handleCancel}
      >
        {isLoading && (
          <BackdropLoading
            size={50}
            description='Realizando upload do arquivo...'
            open={isLoading}
            classes={classes}
          />
        )}
        <Typography variant="body2" color={'black'}>Ao confirmar este upload, será dado início ao processamento </Typography>
        <Typography variant="body2" color={'black'}>do arquivo para a emissão dos certificados.</Typography>
        <Typography variant="body2" color={'black'}>Deseja realmente confirmar?</Typography>

      </ConfirmationModal>
    </Root>

  );
}
export default IssuanceCertificatePage;
