import {
  CurriculumInfoActions,
  SET_CURRICULUM,
  SET_LOADING_CURRICULUM,
  CurriculumInfoPage,
  INITIAL_STATE,
  TOGGLE_IS_LOADING_CURRICULUM
} from './type';

export default function reducer(
  state: CurriculumInfoPage = INITIAL_STATE,
  action: CurriculumInfoActions
): CurriculumInfoPage {
  switch (action.type) {
    case SET_CURRICULUM:
      return {
        ...state,
        curriculum: action.payload.curriculum
      };
    case SET_LOADING_CURRICULUM:
      return {
        ...state,
        loadingCurriculum: action.payload.loadingCurriculum
      };
    case TOGGLE_IS_LOADING_CURRICULUM:
      return {
        ...state,
        isLoadingCurriculum: !state.isLoadingCurriculum
      };
    default:
      return state;
  }
}
