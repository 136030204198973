import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ReactNode } from 'react';

const PREFIX = 'CardTitle';

const defaultClasses = {
  root: `${PREFIX}-root`,
  iconBox: `${PREFIX}-iconBox`
};

const Root = styled('span')((
  {
    theme
  }
) => ({
  [`& .${defaultClasses.root}`]: {
    margin: '32px 0 32px 32px',
  },

  [`& .${defaultClasses.iconBox}`]: {
    background: `${theme.palette.primary.light}`,
    borderRadius: '4px',
    width: '40px',
    height: '40px',
    alignContent: 'center',
  }
}));

export type CardTitleProps = {
  icon: ReactNode,
  children: ReactNode,
  variant?: 'body1' | 'h3',
  classes?: any,
  root?: any
};

const CardTitle = (props: CardTitleProps) => {

  const {
    icon, children, variant, classes, root
  } = props;

  return (
    <Root>
      <Grid
        item
        container
        xs={12}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        wrap="nowrap"
        className={root ? root : defaultClasses.root}
      >
        <Box mr={2} p={1} className={defaultClasses.iconBox}>
          {icon}
        </Box>
        <Typography noWrap className={classes?.typography} variant={variant}>
          {' '}
          {children}
          {' '}
        </Typography>
      </Grid>
    </Root>
  );
};

CardTitle.defaultProps = {
  variant: 'h3',
};

export default CardTitle;
