import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Paper, Divider, Collapse, IconButton, Grid, Typography } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CardTitle from '../../molecules/card-title';
import StepStatusTimeline from '../../molecules/step-status-timeline';
import { DARK_GRAY_COLOR, FONT_FAMILY_REGULAR } from '../../../../theme';
import { StatusTimelineProps } from './types';

const PREFIX = 'StatusTimeline';

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  legend: `${PREFIX}-legend`,
  expand: `${PREFIX}-expand`,
  icon: `${PREFIX}-icon`,
  label: `${PREFIX}-label`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    marginBottom: '40px',
  },

  [`& .${classes.button}`]: {
    textAlign: 'right',
    justifyContent: 'right',
  },

  [`& .${classes.legend}`]: {
    fontFamily: FONT_FAMILY_REGULAR,
    marginLeft: '30px',
    marginBottom: '20px',
  },

  [`& .${classes.expand}`]: {
    paddingTop: '29px',
    marginRight: '32px',
  },

  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main,
    display: 'inline-block',
  },

  [`& .${classes.label}`]: {
    display: 'inline-block',
    cursor: 'pointer',
    color: DARK_GRAY_COLOR,
  }
}));

const StatusTimeline = ({
  stepsData, title, description, note,
}: StatusTimelineProps) => {

  const [expand, setExpand] = useState(false);
  const [label, setLabel] = useState('Visualizar');

  const handleTimeline = () => {
    setLabel(expand ? 'Visualizar' : 'Esconder');
    setExpand(!expand);
  };

  return (
    <Root>
      <Paper className={classes.root}>
        <Grid container>
          <Grid item xs={6}>
            <CardTitle icon={<MoreHorizIcon className={classes.icon} />}>
              {title}
            </CardTitle>
          </Grid>
          <Grid className={classes.button} item xs={6}>
            <div className={classes.expand}>
              <Typography onClick={() => handleTimeline()} className={classes.label} variant="h5">
                {label}
              </Typography>
              <IconButton
                disableFocusRipple
                disableRipple
                style={{ backgroundColor: 'transparent', color: DARK_GRAY_COLOR }}
                aria-label={label}
                onClick={() => handleTimeline()}
                data-testid="status-timeline-toggle"
                size="large">
                {expand ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </IconButton>
            </div>
          </Grid>
        </Grid>
        {expand && <Divider />}
        <Collapse
          style={{ marginTop: '8px' }}
          in={expand}
          timeout="auto"
          unmountOnExit
          key="timeline"
        >
          <StepStatusTimeline steps={stepsData} />
          {description && (
            <Typography className={classes.legend} variant="subtitle2">
              {description}
              <br />
              {note || ''}
            </Typography>
          )}
        </Collapse>
      </Paper>
    </Root>
  );
};

export default StatusTimeline;
