/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, TextFieldProps } from '@mui/material';
import { OPAQUE_DIPLOMA_COLOR, VERY_LIGHT_DIPLOMA_COLOR } from '../../../../theme';
const PREFIX = 'CustomTextField';

const classes = {
  activeInput: `${PREFIX}-activeInput`
};

const StyledTextField
  = styled(TextField
  )((
    {
      theme
    }
  ) => ({
    "& .MuiFilledInput-root": {
      background: OPAQUE_DIPLOMA_COLOR,
    },
    "& .MuiFilledInput-root:hover": {
      background: VERY_LIGHT_DIPLOMA_COLOR,
    },
    "& .MuiFilledInput-root.Mui-focused": {
      background: VERY_LIGHT_DIPLOMA_COLOR,
    },
    "& .MuiFilledInput-underline:before": {
      borderBottom: `1px solid ${theme.palette.primary.main}`
    },
    "&& .MuiFilledInput-underline:hover:before": {
      borderBottom: `1px solid ${theme.palette.primary.main}`
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: `1px solid ${theme.palette.primary.main}`
    }
  }));

export default function CustomTextField(props: TextFieldProps) {

  const { className, value } = props;
  const classNames = `${className} ${value ? classes.activeInput : ''}`;

  return (
    <StyledTextField
      variant='filled'
      size='small'
      {...props}
      className={classNames}
    />
  );
}
