import React from 'react';
import { styled } from '@mui/material/styles';
import { Paper, Grid, Link, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import {
  DARK_GRAY_COLOR, DIPLOMA_COLOR, INFO_COLOR, FONT_FAMILY_REGULAR,
} from '../../../../theme';
import ExternalLink from '../../../icons/ExternalLink';
import { CardNotificationProps } from './types';

const PREFIX = 'CardNotification';

const classes = {
  root: `${PREFIX}-root`,
  description: `${PREFIX}-description`,
  text: `${PREFIX}-text`,
  quantity: `${PREFIX}-quantity`,
  button: `${PREFIX}-button`,
  link: `${PREFIX}-link`,
  icon: `${PREFIX}-icon`
};

const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {
    marginTop: '44px',
    height: '50px',
    borderColor: '#E0E0E0',
  },

  [`& .${classes.description}`]: {
    marginLeft: '24px',
  },

  [`& .${classes.text}`]: {
    marginTop: '5px',
    color: DARK_GRAY_COLOR,
    fontFamily: FONT_FAMILY_REGULAR,
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
  },

  [`& .${classes.quantity}`]: {
    marginTop: '5px',
    color: DIPLOMA_COLOR,
  },

  [`& .${classes.button}`]: {
    marginRight: '24px',
    marginTop: '5px',
    color: INFO_COLOR,
  },

  [`& .${classes.link}`]: {
    color: INFO_COLOR,
    textDecoration: 'underline',
    marginRight: '8px',
  },

  [`& .${classes.icon}`]: {
    marginTop: '1.5px',
  }
}));

const CardNotification = (props: CardNotificationProps) => {

  const { quantity, link } = props;

  return (
    (<Root>
      {quantity !== 0 && (
        <Paper variant="outlined" className={classes.root}>
          <Grid
            container
            spacing={3}
          >
            <Grid item xs={6}>
              <Box display="flex" p={1} className={classes.description}>
                <Typography variant="body1" className={classes.text}>
                  {quantity > 1 ? 'Existem' : 'Existe'}
                </Typography>
                &nbsp;
                <Typography variant="subtitle2" className={classes.quantity}>
                  {quantity}
                </Typography>
                &nbsp;
                <Typography variant="body1" className={classes.text}>
                  {quantity > 1 ? 'diplomas com assinaturas pendentes.' : 'diploma com assinatura pendente.'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end" p={1} className={classes.button}>
                <a href={link} target="_blank" rel="noopener noreferrer" className={classes.link}>Visualizar</a>
                <Link href={link} target="_blank" className={classes.icon}>
                  <ExternalLink color={INFO_COLOR} />
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Root>)
  );
};

export default CardNotification;
