import { combineReducers } from 'redux';
import dropdownIesCampusReducer from '../../shared/components/molecules/dropdown-ies-campus/store/reducer';
import institutionPageReducer from '../../shared/components/organisms/default-signers-modal/store/reducer';
import diplomaEducationalStageLabelReducer from '../../shared/components/organisms/diploma-educational-stage-label/store/reducer';
import institutionPageInfoReducer from '../../shared/components/organisms/institution-management-info/store/reducer';
import usersPageReducer from '../../shared/components/organisms/users-modal/store/reducer';
import authorizationReducer from '../../shared/components/pages/authorization/store/reducer';
import certificateBatchInfoReducer from '../../shared/components/pages/certificate-batch-info/store/reducer';
import EditModelCertificateReducer from '../../shared/components/pages/certificate-edit-model-page/store/reducer';
import {
  default as certificatePageReducer,
  default as certificateReducer
} from '../../shared/components/pages/certificate-page/store/reducer';
import { default as curriculumPageReducer } from '../../shared/components/pages/digital-curriculum-page/store/reducer';
import { default as curriculumValidatePageReducer } from '../../shared/components/pages/curriculum-validate/store/reducer';
import { default as curriculumInfoPageReducer } from '../../shared/components/pages/curriculum-info/store/reducer';
import EditCertificateSettingsModelReducer from '../../shared/components/pages/certificate-settings-model-page/store/reducer';
import dashboardFilterReducer from '../../shared/components/pages/diploma-dashboard/store/reducer';
import diplomaFilterReducer from '../../shared/components/pages/diploma-list/store/reducer';
import selectDiplomaSignersReducer from '../../shared/components/pages/diploma-modal-signers/store/reducer';
import diplomaPageReducer from '../../shared/components/pages/diploma-page/store/store';
import douFilterReducer from '../../shared/components/pages/dou-list/store/reducer';
import douPageReducer from '../../shared/components/pages/dou-page/store/store';
import institutionManagementReducer from '../../shared/components/pages/institution-management/store/reducer';
import loginReducer from '../../shared/components/pages/login-microsoft/store';
import notificationReducer from '../../shared/components/pages/notification/store';
import usersManagementReducer from '../../shared/components/pages/users-management/store/reducer';
import usersSecurityPageReducer from '../../shared/components/pages/users-security-page/store/reducer';

const rootReducer = combineReducers({
  login: loginReducer,
  notification: notificationReducer,
  diplomaPage: diplomaPageReducer,
  header: dropdownIesCampusReducer,
  selectDiplomaSigners: selectDiplomaSignersReducer,
  diplomaFilter: diplomaFilterReducer,
  dashboardFilter: dashboardFilterReducer,
  authorization: authorizationReducer,
  douFilter: douFilterReducer,
  douPage: douPageReducer,
  institutionPage: institutionPageReducer,
  institutionManagementFilter: institutionManagementReducer,
  usersManagementFilter: usersManagementReducer,
  usersPage: usersPageReducer,
  certificatePage: certificatePageReducer,
  certificateFilter: certificateReducer,
  editModelCertificateFilter: EditModelCertificateReducer,
  editModelCertificatePage: EditModelCertificateReducer,
  editCertificateSettingsModel: EditCertificateSettingsModelReducer,
  certificateBatchInfo: certificateBatchInfoReducer,
  diplomaEducational: diplomaEducationalStageLabelReducer,
  institutionInfoPage: institutionPageInfoReducer,
  usersSecurity: usersSecurityPageReducer,
  curriculumPage: curriculumPageReducer,
  curriculumValidatePage: curriculumValidatePageReducer,
  curriculumInfoPage: curriculumInfoPageReducer
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
