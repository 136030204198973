import {
  DiplomaListActions,
  DiplomaListState,
  INITIAL_STATE,
  SELECT_ISSUER_INSTITUTION,
  SET_ISSUER_INSTITUTIONS,
  SELECT_DIPLOMA_STATUS,
  SET_SEARCH,
  SET_LOADING_DIPLOMAS,
  SET_SELECTED_INSTITUTION_PROFILE,
  SET_DIPLOMAS,
  SET_SELECTED_SYNC_DIPLOMAS
} from './types';
import InstitutionProfile from '../../../../../domain/enum/InstitutionProfile';

const verifyIntitutionProfile = (): InstitutionProfile => {
  let institutionProfileStorage = JSON.parse(
    localStorage.getItem('institution_profile') || '{}'
  );

  if (
    JSON.stringify(institutionProfileStorage) === null ||
    JSON.stringify(institutionProfileStorage) === '{}'
  ) {
    localStorage.setItem(
      'institution_profile',
      JSON.stringify(InstitutionProfile.Issuer)
    );
    return InstitutionProfile.Issuer;
  }

  return institutionProfileStorage;
};

export default function reducer(
  state: DiplomaListState = INITIAL_STATE,
  action: DiplomaListActions
): DiplomaListState {
  switch (action.type) {
    case SELECT_ISSUER_INSTITUTION:
      return {
        ...state,
        selectedInstitutionProfile: verifyIntitutionProfile(),
        selectedIssuerInstitution: action.payload.selectedIssuerInstitution
      };
    case SET_ISSUER_INSTITUTIONS:
      return {
        ...state,
        selectedInstitutionProfile: verifyIntitutionProfile(),
        issuerInstitutions: action.payload.issuerInstitutions
      };
    case SELECT_DIPLOMA_STATUS:
      return {
        ...state,
        selectedInstitutionProfile: verifyIntitutionProfile(),
        selectedDiplomaStatus: action.payload.selectedDiplomaStatus
      };
    case SET_SEARCH:
      return {
        ...state,
        selectedInstitutionProfile: verifyIntitutionProfile(),
        search: action.payload.search
      };
    case SET_DIPLOMAS:
      return {
        ...state,
        selectedInstitutionProfile: verifyIntitutionProfile(),
        diplomas: action.payload.diplomas
      };
    case SET_LOADING_DIPLOMAS:
      return {
        ...state,
        selectedInstitutionProfile: verifyIntitutionProfile(),
        loadingDiplomas: action.payload.loadingDiplomas
      };
    case SET_SELECTED_INSTITUTION_PROFILE:
      return {
        ...state,
        selectedInstitutionProfile: verifyIntitutionProfile()
      };
    case SET_SELECTED_SYNC_DIPLOMAS:
      return {
        ...state,
        syncDiplomas: action.payload.syncDiplomas
      };
    default:
      return state;
  }
}
