import {
  DefaultUserModalActions,
  SET_DEFAULT_USER_MODAL,
  INITIAL_STATE,
  UsersState
} from './types';

export default function reducer(
  state: UsersState = INITIAL_STATE,
  action: DefaultUserModalActions
) {
  if (action.type === SET_DEFAULT_USER_MODAL) {
    return {
      ...state,
      employee: action.payload
    };
  }

  return state;
}
