import { AppThunk } from '../../../../../setup/reducer';
import { DiplomaEducationalActions, SET_SHOW_VISION_DROPDOWN } from './types';

const internalSetShowVisionDropdowAction = (
  showVisionDropdow: boolean
): DiplomaEducationalActions => ({
  type: SET_SHOW_VISION_DROPDOWN,
  payload: {
    showVisionDropdow
  }
});

export const setShowVisionDropdowAction =
  (showVisionDropdow: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetShowVisionDropdowAction(showVisionDropdow));
  };
