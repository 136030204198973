import { AlertTriangle, Check, Clock, Download, Link2 } from 'react-feather';
import { Button, styled, Typography } from '@mui/material';
import InspectionDocumentStatus from '../../../../domain/enum/inspectionDocument/InspectionDocumentStatus';
import { DARK_GRAY_COLOR, DIPLOMA_COLOR } from '../../../../theme';
import GetListFiscalizationFileResponse from '../../../../domain/interface/response/GetListFiscalizationFileResponse';
import { useDispatch } from 'react-redux';
import { showSuccessNotification } from '../../pages/notification/actions';
import { notificationIcons } from '../../../utils/constants';
import axios from 'axios';

const PREFIX = 'InspectionFileFeedback';

const classes = {
  feedbackContainer: `${PREFIX}-feedbackContainer`,
  feedbackIcon: `${PREFIX}-feedbackIcon`,
  feedbackText: `${PREFIX}-feedbackText`,
  feedbackSubText: `${PREFIX}-feedbackSubText`,
  subTextIcon: `${PREFIX}-subTextIcon`,
  downloadIcon: `${PREFIX}-downwloadIcon`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  disabledIcon: `${PREFIX}-disabledIcon`,
  feedBackTextContainer: `${PREFIX}-feedBackTextContainer`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.feedbackContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '5px'
  },
  [`& .${classes.feedbackIcon}`]: {
    flex: 1,
    color: DARK_GRAY_COLOR,
  },
  [`& .${classes.feedbackText}`]: {
    flex: 11,
    fontWeight: 'bold',
    fontSize: '14px',
    color: DARK_GRAY_COLOR
  },
  [`& .${classes.feedbackSubText}`]: {
    padding: '1px 0px 0px 0px',
    flex: 11,
    color: '#737677',
    fontSize: '12px',
  },
  [`& .${classes.subTextIcon}`]: {
    color: DIPLOMA_COLOR,
    margin: '5px 0px 0px 0px'
  },
  [`& .${classes.downloadIcon}`]: {
    color: DIPLOMA_COLOR,
    padding: '0px 25px 0px 0px'
  },
  [`& .${classes.buttonContainer}`]: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  [`& .${classes.disabledIcon}`]: {
    color: theme.palette.grey[500]
  },
  [`& .${classes.feedBackTextContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '5px'
  },
}));

type InspectionFileFeedbackProps = {
  fiscalizationDocument: GetListFiscalizationFileResponse;
};


const InspectionFileFeedback = ({ fiscalizationDocument }: InspectionFileFeedbackProps) => {
  const dispatch = useDispatch();
  const generateFeedbackText = () => {
    if (fiscalizationDocument.status === InspectionDocumentStatus.Processing) {
      return `${fiscalizationDocument.documentCode} - ${InspectionDocumentStatus.Processing}`;
    }
    else if (fiscalizationDocument.status === InspectionDocumentStatus.Generated) {
      return `${fiscalizationDocument.documentCode} - ${InspectionDocumentStatus.Generated} em ${fiscalizationDocument.endDate}`;
    }
    else {
      return `Erro | ${fiscalizationDocument.documentCode} - ${InspectionDocumentStatus.Error}`;
    }
  }
  const generateFeedbackSubtext = () => {
    if (fiscalizationDocument.status !== InspectionDocumentStatus.Error)
      return `${fiscalizationDocument.context} - período de ${fiscalizationDocument.startDate} a ${fiscalizationDocument.endDate} por ${fiscalizationDocument.user}`;
    else {
      return `${fiscalizationDocument.context} - período de ${fiscalizationDocument.startDate} a ${fiscalizationDocument.endDate} solicitado em ${fiscalizationDocument.processStartDate} por ${fiscalizationDocument.user}`;
    }
  }

  const handleButtonClick = () => {
    navigator.clipboard.writeText(fiscalizationDocument.linkDownloadDocument!)
    dispatch(
      showSuccessNotification("URL copiada com sucesso.", notificationIcons.success)
    );
  }

  const shouldDisabledButtons = fiscalizationDocument.status !== InspectionDocumentStatus.Generated;

  const getIconByStatus = () => {
    switch (fiscalizationDocument.status) {
      case InspectionDocumentStatus.Processing:
        return <Clock size={14} className={classes.feedbackIcon} />
      case InspectionDocumentStatus.Error:
        return <AlertTriangle size={14} className={classes.feedbackIcon} />
      default:
        return <Check size={14} className={classes.feedbackIcon} />
    }
  }

  const handleDownloadButtonClick = async () => {
    const linkDownloadDocument = 'https://dev.api.diplomas.a2s.technology/document/api/document/c84e7180-bd6e-46bf-9f38-fa66f924a79d/public/download/';
    const header = { Pragma: 'no-cache' };
    const axiosInstance = axios.create({
      headers: header,
      baseURL: ''
    });
    const response = await axiosInstance.get(linkDownloadDocument);
    const linkSource = `data:application/pdf;base64,${response.data}`;
    const downloadLink = document.createElement('a');
    const fileName = `imagemDoBatman.pdf`
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (
    <Root>
      <div className={classes.feedbackContainer}>
        <div>
          <div className={classes.feedBackTextContainer}>
            {getIconByStatus()}
            <Typography className={classes.feedbackText}>
              {generateFeedbackText()}
            </Typography>
          </div>
          <Typography className={classes.feedbackSubText}>
            {generateFeedbackSubtext()}
          </Typography>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            disabled={shouldDisabledButtons}
            onClick={handleDownloadButtonClick}>
            <Download size={20}
              className={`${classes.feedbackIcon}
               ${classes.subTextIcon}
               ${shouldDisabledButtons && classes.disabledIcon}`} />
          </Button>
          <Button
            onClick={handleButtonClick}
            disabled={shouldDisabledButtons}>
            <Link2 size={20} className={`${classes.feedbackIcon}
              ${classes.downloadIcon}
              ${classes.subTextIcon} 
              ${shouldDisabledButtons && classes.disabledIcon}`} />
          </Button>
        </div>
      </div>
    </Root>
  );
};

export default InspectionFileFeedback;
