import InstitutionRegulatoryActType from '../../../../../domain/enum/InstitutionRegulatoryActType';
import Curriculum from '../../../../../domain/model/curriculum/Curriculum';
import Institution from '../../../../../domain/model/institution/Institution';
import InstitutionRegulatoryAct from '../../../../../domain/model/institution/InstitutionRegulatoryAct';
import CurriculumService from '../../../../../services/CurriculumService';
import DiplomaInstitutionService from '../../../../../services/DiplomaInstitutionService';
import { AppThunk } from '../../../../../setup/reducer';
import { RootState } from '../../../../../setup/reducer/reducers';
import {
  errorNotificationMessages,
  notificationIcons
} from '../../../../utils/constants';
import { showNotification } from '../../notification/actions';
import {
  CurriculumInfoActions,
  SET_CURRICULUM,
  TOGGLE_IS_LOADING_CURRICULUM
} from './type';

const internalSetCurriculum = (
  curriculum: Curriculum
): CurriculumInfoActions => ({
  type: SET_CURRICULUM,
  payload: {
    curriculum
  }
});

const sortInstitutionRegulatoryActByDateDescending = (
  a: InstitutionRegulatoryAct,
  b: InstitutionRegulatoryAct
): number => {
  if ((!a || !a.date) && (!b || !b.date)) {
    return 0;
  }
  if (!a || !a.date) {
    return 1;
  }
  if (!b || !b.date) {
    return -1;
  }
  if (a.date > b.date) {
    return -1;
  }
  if (a.date < b.date) {
    return 1;
  }
  return 0;
};

const selectNewestIesRegulatoryActByType = (
  iesRegulatoryActs: InstitutionRegulatoryAct[],
  type: InstitutionRegulatoryActType
): InstitutionRegulatoryAct | undefined =>
  iesRegulatoryActs
    .filter((r) => r.type === type)
    .sort(sortInstitutionRegulatoryActByDateDescending)
    .shift();

export const toggleIsLoadingCurriculum = (): CurriculumInfoActions => ({
  type: TOGGLE_IS_LOADING_CURRICULUM
});

export const setCurriculumInfo =
  (idCurriculum: string): AppThunk =>
  async (dispatch, getState: () => RootState) => {
    dispatch(toggleIsLoadingCurriculum());

    let curriculum: Curriculum = new Curriculum();

    const selectedInstitution: Institution | undefined =
      getState().header.institution;

    if (!selectedInstitution) {
      dispatch(
        showNotification(
          errorNotificationMessages.thereMustBeSelectedIesToProceed,
          notificationIcons.error
        )
      );
      return;
    }

    try {
      curriculum = (await CurriculumService.getCurriculum({ id: idCurriculum }))
        .data;
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
      dispatch(toggleIsLoadingCurriculum());
    }
    if (!curriculum.issuerInstitution) {
      let regulatoryActs: InstitutionRegulatoryAct[] | undefined;

      if (selectedInstitution.mecCode) {
        try {
          regulatoryActs = (
            await DiplomaInstitutionService.listRegulatoryAct(
              selectedInstitution.mecCode
            )
          ).data;
        } catch {
          dispatch(
            showNotification(
              errorNotificationMessages.failedToRetrieveIesRegulatoryActs,
              notificationIcons.error
            )
          );
        }
      }

      curriculum.issuerInstitution = JSON.parse(
        JSON.stringify(selectedInstitution)
      );

      if (regulatoryActs && curriculum.issuerInstitution) {
        curriculum.issuerInstitution.licensing =
          selectNewestIesRegulatoryActByType(
            regulatoryActs,
            InstitutionRegulatoryActType.Licensing
          );
        curriculum.issuerInstitution.relicensing =
          selectNewestIesRegulatoryActByType(
            regulatoryActs,
            InstitutionRegulatoryActType.Relicensing
          );
        curriculum.issuerInstitution.relicensingRenewal =
          selectNewestIesRegulatoryActByType(
            regulatoryActs,
            InstitutionRegulatoryActType.RelicensingRenewal
          );
      }
    }

    dispatch(toggleIsLoadingCurriculum());
    dispatch(internalSetCurriculum(curriculum));
  };
