import UserDto from '../../../../domain/interface/dto/UserDto';

export interface LoginState {
  user?: UserDto;
  loading: boolean;
  userManager: any;
}

export const INSERT_USER = 'app/login/INSERT_USER';
export const INSERT_USER_MANAGER = 'app/login/INSERT_USER_MANAGER';
export const SET_LOGIN_LOADING = 'app/login/SET_LOGIN_LOADING';

interface InsertUserAction {
  type: typeof INSERT_USER;
  payload: UserDto | undefined;
}

interface InsertUserManagerAction {
  type: typeof INSERT_USER_MANAGER;
  payload: any | undefined;
}

interface SetLoginLoadingAction {
  type: typeof SET_LOGIN_LOADING;
}

export type LoginActionsTypes =
  | InsertUserAction
  | InsertUserManagerAction
  | SetLoginLoadingAction;
