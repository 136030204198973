import { AxiosResponse } from 'axios';
import getAxiosInstance from '../apis/axiosInstance';
import ChangeInstitutionsDefaultSignersRequest from '../domain/interface/request/ChangeInstitutionsDefaultSignersRequest';
import EnableAutomaticDiplomaIssuanceRequest from '../domain/interface/request/EnableAutomaticDiplomaIssuanceRequest';
import Institution from '../domain/model/institution/Institution';
import InstitutionRegulatoryAct from '../domain/model/institution/InstitutionRegulatoryAct';
import InstitutionSigners from '../domain/model/institution/InstitutionSigners';
import InstitutionsSigners from '../domain/model/institution/InstitutionsSigners';

const axiosInstance = getAxiosInstance(
  process.env.REACT_APP_DIPLOMA_INSTITUTION_API || ''
);

class DiplomaInstitutionService {
  async listInstitutions(): Promise<AxiosResponse<Institution[]>> {
    return axiosInstance.get('education-institution');
  }

  async listRegulatoryAct(
    mecCode: number
  ): Promise<AxiosResponse<InstitutionRegulatoryAct[]>> {
    return axiosInstance.get(`education-institution/${mecCode}/regulatory-act`);
  }

  async listDefaultSigners(
    mecCode: number
  ): Promise<AxiosResponse<InstitutionSigners>> {
    return axiosInstance.get(`education-institution/${mecCode}/defaultsigners`);
  }

  async listInstitutionsSigners(): Promise<
    AxiosResponse<InstitutionsSigners[]>
  > {
    return axiosInstance.get('education-institution/listInstitutionSigners');
  }

  async changeInstitutionDefaultSigners(
    request: ChangeInstitutionsDefaultSignersRequest
  ): Promise<AxiosResponse<any>> {
    return axiosInstance.put('education-institution/newInstitution', request);
  }

  async getInstitution(mecCode: number): Promise<AxiosResponse<Institution>> {
    return axiosInstance.get(`education-institution/${mecCode}`);
  }

  async updateInstitutionSettings(
    enableSettings: EnableAutomaticDiplomaIssuanceRequest
  ): Promise<AxiosResponse<any>> {
    return axiosInstance.put(
      'education-institution/updateInstitutionSettings',
      enableSettings
    );
  }
}

export default new DiplomaInstitutionService();
