import EnableAutomaticDiplomaIssuanceRequest from '../../../../../domain/interface/request/EnableAutomaticDiplomaIssuanceRequest';
import Institution from '../../../../../domain/model/institution/Institution';
import DiplomaInstitutionService from '../../../../../services/DiplomaInstitutionService';
import { AppThunk } from '../../../../../setup/reducer';
import {
  errorNotificationMessages,
  notificationIcons
} from '../../../../utils/constants';
import { showNotification } from '../../../pages/notification/actions';
import {
  DiplomaModalInstitutionInfoActions,
  SET_INSTITUTION,
  SET_LOADING_INSTITUTION,
  SET_LOADING_SAVE_SETTINGS
} from './types';

const internalSetLoadingInstitution = (
  loadingInstitution: boolean
): DiplomaModalInstitutionInfoActions => ({
  type: SET_LOADING_INSTITUTION,
  payload: {
    loadingInstitution
  }
});

const internalGetInstitution = (
  institution?: Institution
): DiplomaModalInstitutionInfoActions => ({
  type: SET_INSTITUTION,
  payload: {
    institution
  }
});

const internalSetLoadingSaveSettings = (
  loadingSaveSettings: boolean
): DiplomaModalInstitutionInfoActions => ({
  type: SET_LOADING_SAVE_SETTINGS,
  payload: {
    loadingSaveSettings
  }
});

export const getInstitution =
  (mecCode: number): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetLoadingInstitution(true));

    let institution = new Institution();
    try {
      institution = (await DiplomaInstitutionService.getInstitution(mecCode))
        .data;
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
    }

    dispatch(internalGetInstitution(institution));
    dispatch(internalSetLoadingInstitution(false));
  };

export const enableAutomaticDiplomaIssuance =
  (enableSettings: EnableAutomaticDiplomaIssuanceRequest): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(internalSetLoadingSaveSettings(true));
      await DiplomaInstitutionService.updateInstitutionSettings(enableSettings);
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
    } finally {
      dispatch(internalSetLoadingSaveSettings(false));
    }
  };
