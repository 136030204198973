import Batch from '../../../../../domain/model/certificate/Batch';

export const SET_LOADING_BATCHES = 'app/certificate-page/SET_LOADING_BATCHES';
export const SET_SEARCH = 'app/certificate-page/SET_SEARCH';
export const SET_CERTIFICATE_BATCHES =
  'app/certificate-page/SET_CERTIFICATE_BATCHES';

export interface CertificateState {
  search: string;
  loadingBatches: boolean;
  batches: Batch[];
}

export const INITIAL_STATE: CertificateState = {
  search: '',
  loadingBatches: false,
  batches: []
};

interface SetBatchesAction {
  type: typeof SET_CERTIFICATE_BATCHES;
  payload: {
    batches: Array<Batch>;
  };
}

interface SetLoadingBatchesAction {
  type: typeof SET_LOADING_BATCHES;
  payload: {
    loadingBatches: boolean;
  };
}

interface SetSearchAction {
  type: typeof SET_SEARCH;
  payload: {
    search: string;
  };
}

export type CertificateActions =
  | SetSearchAction
  | SetLoadingBatchesAction
  | SetBatchesAction;
