import {
  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Skeleton from '@mui/material/Skeleton';
import moment from 'moment';
import React from 'react';
import {
  connect, ConnectedProps,
} from 'react-redux';
import DouRegisterBatchStatus from '../../../../../domain/enum/dou/DouRegisterBatchStatus';
import { RootState } from '../../../../../setup/reducer/reducers';
import CustomizedTooltip from '../../../atoms/customized-tooltip';
import EmptyTable from '../../../molecules/empty-table';
import StagesTimeline from '../../../molecules/stages-timeline';
import {
  DARK_GRAY_COLOR, DIPLOMA_COLOR, EERIE_BLACK_COLOR, GRAY_COLOR, INFO_COLOR, TOMATO_COLOR,
} from '../../../../../theme';
import { getRows } from '../DouFilterManager';
import {
  getComparator, headCells, stableSort,
} from '../DouTableManager';
import {
  BatchData, EnhancedTableProps, Order, RenderCellParams,
} from '../types';
import DouActions from './DouActions';

const PREFIX = 'DouTable';

const classes = {
  root: `${PREFIX}-root`,
  dropdown: `${PREFIX}-dropdown`,
  pagination: `${PREFIX}-pagination`,
  cellabel: `${PREFIX}-cellabel`,
  activeSortIcon: `${PREFIX}-activeSortIcon`,
  inactiveSortIcon: `${PREFIX}-inactiveSortIcon`,
  icon: `${PREFIX}-icon`,
  disabled: `${PREFIX}-disabled`,
  activeIcon: `${PREFIX}-activeIcon`,
  textDouModal: `${PREFIX}-textDouModal`,
  containerContentStyleDouModal: `${PREFIX}-containerContentStyleDouModal`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    paddingLeft: '30px',
    paddingRight: '30px',
  },

  [`& .${classes.dropdown}`]: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.pagination}`]: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
  },

  [`& .${classes.cellabel}`]: {
    color: DARK_GRAY_COLOR,
    fontFamily: theme.typography.body2.fontFamily,
  },

  [`& .${classes.activeSortIcon}`]: {
    fill: theme.palette.primary.main,
    width: 24,
    height: 24,
  },

  [`& .${classes.inactiveSortIcon}`]: {
    width: 24,
    height: 24,
  },

  [`& .${classes.icon}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.25rem',
    height: '1.25rem',
    color: GRAY_COLOR,
    cursor: 'not-allowed',
  },

  [`& .${classes.disabled}`]: {
    cursor: 'not-allowed',
  },

  [`& .${classes.activeIcon}`]: {
    cursor: 'pointer',
    color: DIPLOMA_COLOR,
  },

  [`& .${classes.textDouModal}`]: {
    color: EERIE_BLACK_COLOR,
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
  },

  [`& .${classes.containerContentStyleDouModal}`]: {
    padding: '20px 15px 20px 10px',
  }
}));

const mapState = (state: RootState) => ({
  search: state.douFilter.search,
  status: state.douFilter.status,
  batches: state.douFilter.batches,
  type: state.douFilter.type,
  loadingBatches: state.douFilter.loadingBatches,
});

const connector = connect(mapState);

const TableLoading = () => (
  <TableBody>
    {
      [...Array(10)].map((_e, i) => (
        <TableRow key={i++}>
          {
            [...Array(headCells.length)].map((_f, j) => (
              <TableCell key={i++ + j++} colSpan={1}>
                <Skeleton style={{ height: 32 }} animation="wave" />
              </TableCell>
            ))
          }
        </TableRow>
      ))
    }
  </TableBody>
);

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = (property: keyof BatchData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{
              width: headCell.size,
              whiteSpace: 'nowrap',
            }}
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id && order}
          >
            {
              headCell.sortable
                ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                    classes={{
                      icon: ((orderBy === headCell.id) ? classes.activeSortIcon : classes.inactiveSortIcon),
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <Typography variant="caption" style={headCell.styles}>
                      {headCell.label}
                    </Typography>
                  </TableSortLabel>
                )
                : (
                  <Typography variant="caption" style={headCell.styles}>
                    {headCell.label}
                  </Typography>
                )
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

type Props = ConnectedProps<typeof connector>;

const DouTable = (props: Props) => {

  const {
    batches, search, status, loadingBatches, type,
  } = props;
  const [rows, setRows] = React.useState<BatchData[]>([]);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof BatchData>('status');
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 8;

  React.useEffect(() => {
    setRows(getRows(batches, search, status, type));
  }, [search, status, batches, type]);

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof BatchData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const renderStatusCell = (value: string) => {
    const steps = [{
      value: DouRegisterBatchStatus.Opened,
      color: INFO_COLOR,
    }, {
      value: DouRegisterBatchStatus.Closed,
      color: TOMATO_COLOR,
    }];

    return (
      <TableCell>
        <StagesTimeline
          steps={steps}
          currentValue={value}
        />
      </TableCell>
    );
  };

  const renderDouActionsCell = (row: BatchData) => (
    <TableCell align="left">
      <DouActions status={row.status} sk={row.sk} />
    </TableCell>
  );

  const getPublicationDate = (row: any) => {
    if (row.status === 'Fechado') {
      return row.publicationDate === 'N/A' ? row.publicationDate : moment(row.publicationDate).format('DD/MM/YYYY');
    }
    return '';
  }

  const getDouNumber = (row: any) => {
    if (row.status === 'Fechado') {
      return row.douNumber;
    }
    return '';
  }

  const renderCell = ({
    label, splitPoint = 30, cellSize = 25, textAlign = 'left',
  }: RenderCellParams) => {
    const customStyle = splitPoint >= 15 ? { width: cellSize } : { width: cellSize, paddingLeft: '40px' };

    if (label) {
      if (label.length > splitPoint) {
        return (
          <TableCell align={textAlign} style={customStyle}>
            <CustomizedTooltip title={label} placement="top">
              <Typography className={classes.cellabel} variant="body1">{`${(label).slice(0, splitPoint)}...`}</Typography>
            </CustomizedTooltip>
          </TableCell>
        );
      }
      return (<TableCell align={textAlign} style={customStyle}><Typography className={classes.cellabel} variant="body1">{label}</Typography></TableCell>);
    }
    return <TableCell align={textAlign} style={customStyle}><Typography className={classes.cellabel} variant="body1">-</Typography></TableCell>;
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const getNumPages = () => Math.ceil((rows && rows.length) / rowsPerPage) - 1;

  const buildCells = (row: BatchData): React.ReactNode => (
    <TableRow
      hover
      key={row.sk}
    >
      {renderStatusCell(row.status)}

      {renderCell({
        label: row.batchNumber.toString(),
        cellSize: 10,
        splitPoint: 15,
        textAlign: 'center',
      })}

      {renderCell({
        label: row.type.toString(),
        cellSize: 10,
        splitPoint: 15,
        textAlign: 'center',
      })}

      {renderCell({
        label: row.createdAt ? moment(row.createdAt).format('DD/MM/YYYY') : 'N/A',
        textAlign: 'right',
      })}

      {renderCell({
        label: getPublicationDate(row),
        textAlign: 'right',
      })}
      {renderCell({
        label: getDouNumber(row),
        cellSize: 10,
        splitPoint: 15,
        textAlign: 'center',
      })}

      {renderDouActionsCell(row)}

    </TableRow>
  );

  let tableContent;

  if (loadingBatches) {
    tableContent = <TableLoading />;
  } else if (rows.length > 0) {
    tableContent = (
      <TableBody>
        {stableSort(rows, getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row: BatchData) => (buildCells(row)
          ))}
        {emptyRows > 0 && (
          <TableRow style={{ height: 65 * emptyRows }}>
            <TableCell colSpan={headCells.length} />
          </TableRow>
        )}
      </TableBody>
    );
  } else {
    tableContent = (
      <TableBody>
        <TableRow style={{ height: 650 }}>
          <TableCell colSpan={headCells.length}>
            <EmptyTable emptyStateMessage="Nenhum lote encontrado" orientationMessage="Tente selecionar filtros diferentes para sua busca!" />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <Root className={classes.root}>
      <TableContainer>
        <Table>
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          {tableContent}
        </Table>
      </TableContainer>
      {batches.length > 0 ? (
        <TablePagination
          component="div"
          className={classes.pagination}
          count={rows.length ? rows.length : 0}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={(obj: any) => (
            `${obj.from}-${obj.to} de ${obj.count}`
          )}
          nextIconButtonProps={{
            style: {
              color: page === getNumPages() ? GRAY_COLOR : DIPLOMA_COLOR,
            },
          }}
          backIconButtonProps={{
            style: {
              color: page === 0 ? GRAY_COLOR : DIPLOMA_COLOR,
            },
          }}
          page={page}
          onPageChange={handleChangePage}
        />
      ) : null}

    </Root>
  );
};

export default connector(DouTable);
