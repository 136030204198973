import InstitutionsSigners from '../../../../domain/model/institution/InstitutionsSigners';
import SignerData from '../../../../domain/model/institution/SignerData';
import DiplomaInstitutionService from '../../../../services/DiplomaInstitutionService';
import { searchIgnoringCaseAndAccents } from '../../../utils/functions';
import { HeadCell, InstitutionManagementData, Order } from './types';

export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

export const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key
): ((
  a: { [key in Key]: number | string | string[] },
  b: { [key in Key]: number | string | string[] }
) => number) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

export const stableSort = <T>(
  array: T[],
  comparator: (a: T, b: T) => number
) => {
  if (array.length === 0) {
    return array;
  }
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

export const headCells: HeadCell[] = [
  {
    id: 'name',
    label: 'Nome da Instituição',
    size: '20%',
    align: 'left',
    sortable: true
  },
  {
    id: 'issuerSigners',
    label: 'Assinantes do fluxo de emissão',
    size: '25%',
    align: 'left',
    sortable: false
  },
  {
    id: 'registerSigners',
    label: 'Assinantes do fluxo de registro',
    size: '30%',
    align: 'left',
    sortable: false
  },
  {
    id: 'actions',
    label: 'Ações',
    size: '20%',
    align: 'center',
    sortable: false
  }
];

export const listInstitutionsSigners = async (): Promise<
  InstitutionsSigners[]
> => {
  const response = await DiplomaInstitutionService.listInstitutionsSigners();
  return response.data;
};

function getDefaultSignersName(
  signFor: string,
  institutionsSigners: SignerData[] | any
): string[] {
  let institutionsSignersList: string[] = [];

  let firstSigner: string | undefined;
  let secondSigner: string | undefined;

  institutionsSigners?.forEach((signer: SignerData) => {
    if (
      (signFor === 'issuer' && signer.signFor === 'issuer') ||
      (signFor === 'register' && signer.signFor === 'register')
    ) {
      if (signer.signerPosition === 1) {
        firstSigner = signer.name;
      } else {
        secondSigner = signer.name;
      }
    }
  });

  institutionsSignersList[0] = firstSigner ?? '';
  institutionsSignersList[1] = secondSigner ?? '';

  return institutionsSignersList;
}

export const getRows = (
  institutionsSigners: InstitutionsSigners[],
  search: string
): InstitutionManagementData[] => {
  let rows: InstitutionManagementData[] = [];

  if (!institutionsSigners) {
    return rows;
  }

  institutionsSigners.forEach((value) => {
    rows.push({
      mecCode: value.mecCode,
      name: value.name,
      issuerSigners: getDefaultSignersName('issuer', value.signers),
      registerSigners: getDefaultSignersName('register', value.signers),
      actions: ''
    });
  });

  if (search.length >= 2) {
    search = search.toLowerCase();
    rows = rows.filter(
      (value) =>
        searchIgnoringCaseAndAccents(value?.name, search) ||
        value.name?.toLowerCase().match(`.*${search}.*`)
    );
  }

  return rows;
};
