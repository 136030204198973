import React from 'react';
import { DIPLOMA_COLOR } from '../../theme';

type DiplomaProps = {
  color?: string,
  size?: number,
};

const Diploma = ({ color, size }: DiplomaProps) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill={color} xmlns="http://www.w3.org/2000/svg">
    <path d="M14.01 16.0541L5.78889 22.4433C2.11341 24.8119 -2.04214 19.0461 1.12328 15.9606C1.01827 15.9606 16.5903 3.80555 18.0755 2.66797C21.541 -0.105874 26.2066 6.48589 22.8311 9.16623L17.5804 13.2802L14.01 16.0541ZM4.39371 15.1347C5.31182 15.3818 6.12384 15.9411 6.70027 16.7235C7.2767 17.5058 7.58434 18.4661 7.57413 19.4513L13.4849 14.7762C13.5613 13.7782 13.2805 12.7859 12.6965 11.9895C12.1124 11.193 11.2661 10.6483 10.3195 10.4597L4.39371 15.1347ZM18.8706 3.80555L13.6049 7.93514C14.454 8.27971 15.189 8.87202 15.7214 9.64068C16.2538 10.4093 16.5607 11.3215 16.6053 12.2673L21.976 8.02864C24.0013 6.06514 20.8509 2.2628 18.8706 3.80555ZM2.21842 16.7865C-0.256901 18.7344 2.90851 23.0198 5.38384 21.1031C7.85916 19.1863 4.69375 14.823 2.21842 16.7865Z M16.499 14C16.5058 14.416 16.6101 14.8266 16.8055 15.2065C17.4185 16.1268 20 17.0777 20 17.0777V17.1493L17.2888 17.2515L18.5737 19C17.7603 18.7239 14.7073 17.5583 14.2004 15.9325C14.1033 15.5967 14.0363 15.2548 14 14.91" />
  </svg>
);

Diploma.defaultProps = {
  color: DIPLOMA_COLOR,
  size: 24,
};

export default Diploma;
