import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { Search } from 'react-feather';
import { ConnectedProps, connect, useDispatch } from 'react-redux';
import { RootState } from '../../../../../setup/reducer/reducers';
import { DIPLOMA_COLOR, GRAY_COLOR } from '../../../../../theme';
import CustomTextField from '../../../atoms/form/CustomTextField';
import * as Actions from '../store/actions';

const PREFIX = 'UsersManagementFilter';

const classes = {
  root: `${PREFIX}-root`
};

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {
    padding: '30px',
  }
}));

const mapState = (state: RootState) => ({
  search: state.usersManagementFilter.search
});

const mapDispatch = {
  setSearch: (search: string) => Actions.setSearch(search),
};

const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const UsersManagementFilter = (props: Props) => {
  const {
    search,
    setSearch
  } = props

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Actions.setLoadingEmployees(true))
    dispatch(Actions.setUsers())
  }, [dispatch]);

  return (
    <StyledGrid className={classes.root} container spacing={3}>
      <Grid item xs={12} mt={2}>
        <CustomTextField
          placeholder="Pesquise por nome do(a) usuário(a)..."
          fullWidth
          value={search}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearch(e.target.value)
          }
          InputProps={{
            endAdornment: <Search color={search ? DIPLOMA_COLOR : GRAY_COLOR} />,
          }}
        />
      </Grid>
    </StyledGrid>
  );
}

export default connector(UsersManagementFilter);