import { AxiosResponse } from 'axios';
import getAxiosInstance from '../apis/axiosInstance';
import GetCertificatesRequest from '../domain/interface/request/GetCertiticatesRequest';
import InsertCertificateBatchRequest from '../domain/interface/request/InsertCertificateBatchRequest';
import Batch from '../domain/model/certificate/Batch';
import Certificate from '../domain/model/certificate/Certificate';

let axiosInstance = getAxiosInstance(
  process.env.REACT_APP_CERTIFICATE_API || ''
);

class CertificateService {
  async insertCertificateBatch(
    request: InsertCertificateBatchRequest
  ): Promise<AxiosResponse<string>> {
    return axiosInstance.post('certificate/batch', request);
  }
  async listBatches(): Promise<AxiosResponse<Batch[]>> {
    return axiosInstance.get('certificate/batches');
  }

  async getCertificates(
    request: GetCertificatesRequest
  ): Promise<AxiosResponse<Certificate[]>> {
    return axiosInstance.get(`certificate/batch/${request.id}`);
  }
}

export default new CertificateService();
