import * as Eta from 'eta';
import { EtaConfig } from 'eta/dist/types/config';
import GetTemplateRequest from '../../../../domain/interface/request/GetTemplateRequest';
import GetTemplateResponse from '../../../../domain/interface/response/GetTemplateResponse';
import DocumentPdfService from '../../../../services/DocumentPdfService';

const DouTemplateManager = () => {
  const getRenderConfiguration = (): EtaConfig => {
    const config = Eta.defaultConfig;
    config.async = true;

    return config;
  };

  const getTemplateEta = async (request: any): Promise<GetTemplateResponse> => {
    try {
      const config = Eta.defaultConfig;
      config.async = true;
      const template: string | void = await Eta.render(
        request.template,
        request.data,
        getRenderConfiguration()
      );

      return {
        template
      } as GetTemplateResponse;
    } catch (err) {
      const template = '';
      return {
        template
      } as GetTemplateResponse;
    }
  };

  const getDouTemplate = async (
    templateDou: GetTemplateRequest
  ): Promise<GetTemplateResponse> => {
    const response = await DocumentPdfService.getTemplate(templateDou);
    return response.data;
  };

  return { getTemplateEta, getDouTemplate };
};

export default DouTemplateManager();
