import React, {
  useState, useEffect, MouseEvent, ReactNode,
} from 'react';
import { styled } from '@mui/material/styles';
import {
  Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography,
} from '@mui/material';
import { useDispatch, connect, ConnectedProps, useSelector } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import {
  DIPLOMA_COLOR, DARK_GRAY_COLOR, GRAY_COLOR, TOMATO_COLOR, DIPLOMA_VALIDATION_PASSIVE_ACTION, DIPLOMA_VALIDATION_ACTIVE_ACTION, DARK_CYAN_COLOR, MEDIUM_GRAY_COLOR,
} from '../../../../../theme';
import DiplomaStatus from '../../../../../domain/enum/diploma/DiplomaStatus';
import { RootState } from '../../../../../setup/reducer/reducers';
import {
  Data, Order, RenderCellParams,
} from '../types';
import {
  getComparator, headCells, stableSort,
} from '../DiplomaTableManager';
import { getRows } from '../DiplomaFilterManager';
import CustomizedTooltip from '../../../atoms/customized-tooltip';
import DiplomaActions from '../../../molecules/diploma-actions';
import EmptyTable from '../../../molecules/empty-table';
import StagesTimeline from '../../../molecules/stages-timeline';
import { getMediumDateFormat } from '../../../../utils/functions';
import SignerData from '../../../../../domain/model/institution/SignerData';
import Checkbox from '@mui/material/Checkbox';
import OriginSystem from '../../../../../domain/enum/diploma/OriginSystem';
import { setSyncDiplomas } from '../store/actions';
import EnhancedTableHead from '../../../organisms/enhanced-table-head';

const PREFIX = 'DiplomaTable';

const classes = {
  root: `${PREFIX}-root`,
  dropdown: `${PREFIX}-dropdown`,
  pagination: `${PREFIX}-pagination`,
  cellLabel: `${PREFIX}-cellLabel`,
  activeSortIcon: `${PREFIX}-activeSortIcon`,
  inactiveSortIcon: `${PREFIX}-inactiveSortIcon`,
  validateDiplomaIcon: `${PREFIX}-validateDiplomaIcon`,
  checkbox: `${PREFIX}-checkbox`,
  checkboxDisabled: `${PREFIX}-checkboxDisabled`,
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    paddingLeft: '30px',
    paddingRight: '30px',
  },

  [`& .${classes.dropdown}`]: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.pagination}`]: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
  },

  [`& .${classes.cellLabel}`]: {
    color: DARK_GRAY_COLOR,
    fontFamily: theme.typography.body2.fontFamily,
  },

  [`& .${classes.activeSortIcon}`]: {
    fill: theme.palette.primary.main,
    width: 24,
    height: 24,
  },

  [`& .${classes.inactiveSortIcon}`]: {
    width: 24,
    height: 24,
  },
  [`& .${classes.checkbox}`]: {
    display: 'flex',
    alignItemns: 'center',
    width: '10px',
    color: MEDIUM_GRAY_COLOR,
  },
  [`& .${classes.checkboxDisabled}`]: {
    color: GRAY_COLOR
  },
  [`& .${classes.validateDiplomaIcon}`]: {
    color: DIPLOMA_COLOR,
  }
}));

const TableLoading = () => (
  <TableBody>
    {
      [...Array(10)].map((_e, i) => (
        <TableRow key={i++}>
          {
            [...Array(headCells.length)].map((_f, j) => (
              <TableCell key={i++ + j++} colSpan={1}>
                <Skeleton style={{ height: 32 }} animation="wave" />
              </TableCell>
            ))
          }
        </TableRow>
      ))
    }
  </TableBody>
);

const mapState = (state: RootState) => ({
  diplomas: state.diplomaFilter.diplomas,
  loading: state.diplomaFilter.loadingDiplomas,
  search: state.diplomaFilter.search,
  institutionType: state.diplomaFilter.selectedInstitutionProfile,
  defaultSigners: state.institutionPage.defaultSigners,
  otherSigners: state.institutionPage.otherSigners
});

const connector = connect(mapState);

type Props = ConnectedProps<typeof connector>;

const DiplomaTable = (props: Props) => {

  const {
    diplomas, search, loading, institutionType,
  } = props;
  const [rows, setRows] = useState<Data[]>([]);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('status');
  const [page, setPage] = useState(0);
  const rowsPerPage = 8;
  const initialPage = 0;
  const syncDiplomaList = useSelector((state: RootState) => state.diplomaFilter.syncDiplomas);
  const dispatch = useDispatch();

  useEffect(() => {
    setRows(getRows(diplomas, search));
    setPage(initialPage);
  }, [diplomas, search]);

  const handleRequestSort = (_event: MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const defaultSignersIes = useSelector((state: RootState) => state.institutionPage.defaultSigners);


  function getDefaultSignersName(defaultSigners: SignerData[]): string[] {

    if (defaultSigners.length > 0) {
      return orderDefaultSigners(defaultSigners);
    }
    else {
      return orderDefaultSigners(defaultSignersIes);
    }
  }

  function orderDefaultSigners(listSigner?: SignerData[]): string[] {
    let defaultSignersList: string[] = [];

    listSigner?.forEach((signer: SignerData) => {
      if (signer.signerPosition === 1 && signer.signFor === 'issuer') {
        defaultSignersList[0] = signer.name;
      }
      if (signer.signerPosition === 2 && signer.signFor === 'issuer') {
        defaultSignersList[1] = signer.name;
      }
      if (signer.signerPosition === 1 && signer.signFor === 'register') {
        defaultSignersList[2] = signer.name;
      }
      if (signer.signerPosition === 2 && signer.signFor === 'register') {
        defaultSignersList[3] = signer.name;
      }
    });

    return defaultSignersList
  }
  ;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const getNumPages = () => Math.ceil((rows && rows.length) / rowsPerPage) - 1;

  const getLastStep = (row: Data) => {
    switch (row.status) {
      case DiplomaStatus.Completed:
        return {
          value: row.status,
          color: DARK_CYAN_COLOR,
          label: 'CONCLUÍDO',
        };
      case DiplomaStatus.Sent:
        return {
          value: row.status,
          color: DARK_CYAN_COLOR,
          label: 'ENVIADO',
        };
      case DiplomaStatus.Cancelled:
        return {
          value: row.status,
          color: TOMATO_COLOR,
          label: 'ANULADO',
        };
      case DiplomaStatus.TemporarilyCancelled:
        return {
          value: row.status,
          color: DIPLOMA_VALIDATION_ACTIVE_ACTION,
          label: 'ANULADO TEMP.',
        };
      case DiplomaStatus.RestartDiplomaFlow:
        return {
          value: row.status,
          color: MEDIUM_GRAY_COLOR,
          label: 'REINICIANDO FLUXO',
          transitoryStatus: true
        }
      case DiplomaStatus.InSync:
        return {
          value: row.status,
          color: MEDIUM_GRAY_COLOR,
          label: 'EM SINCRONIZAÇÃO',
          transitoryStatus: true
        }
      default:
        return {
          value: '',
          color: TOMATO_COLOR,
          label: 'INDEFINIDO',
        };
    }
  };

  function buildCells(row: Data): ReactNode {
    const steps = [{
      value: DiplomaStatus.IssuerConfirmationPending,
      label: 'PENDENTE CONF. EMISSÃO',
      color: DIPLOMA_VALIDATION_ACTIVE_ACTION,
    }, {
      value: DiplomaStatus.GeneratingIssuerDocuments,
      label: 'GERANDO DOC. EMISSÃO',
      color: DIPLOMA_VALIDATION_PASSIVE_ACTION,
    },
    {
      value: DiplomaStatus.GeneratingAcademicDocumentation,
      label: 'GERANDO XML DOC. ACADÊMICA',
      color: DIPLOMA_VALIDATION_PASSIVE_ACTION,
    }, {
      value: DiplomaStatus.AwaitingIssuerSignature,
      label: 'AGUARD. ASSINATURA EMISSÃO',
      color: DIPLOMA_VALIDATION_ACTIVE_ACTION,
    }, {
      value: DiplomaStatus.RegisterConfirmationPending,
      label: 'PENDENTE CONF. REGISTRO',
      color: DIPLOMA_VALIDATION_ACTIVE_ACTION,
    }, {
      value: DiplomaStatus.AwaitingRegister,
      label: 'AGUARDANDO REG. LIVRO',
      color: DIPLOMA_VALIDATION_PASSIVE_ACTION,
    }, {
      value: DiplomaStatus.GeneratingRegisterDocuments,
      label: 'GERANDO DOC. REGISTRO',
      color: DIPLOMA_VALIDATION_PASSIVE_ACTION,
    }, {
      value: DiplomaStatus.AwaitingRegisterSignature,
      label: 'AGUARD. ASSINATURA REGISTRO',
      color: DIPLOMA_VALIDATION_ACTIVE_ACTION,
    },
    {
      value: DiplomaStatus.GeneratingVisualRepresentation,
      label: 'GERANDO RVDD',
      color: DIPLOMA_VALIDATION_PASSIVE_ACTION,
    },
    {
      ...getLastStep(row),
    },
    ];
    const isInputChecked = (id: string) => syncDiplomaList.some((diploma) => diploma.id === id);

    function handleChange(event: any) {
      const { id, checked } = event.target
      if (checked) {
        const finderDiploma = diplomas.find((diploma) => diploma.id === id);
        if (finderDiploma !== undefined) {
          dispatch(setSyncDiplomas([...syncDiplomaList, finderDiploma]));
        }
      } else {
        const removeDiploma = syncDiplomaList.filter((diploma) => diploma.id !== id)
        dispatch(setSyncDiplomas(removeDiploma));
      }
    }

    const renderCell = ({
      label, splitPoint = 30, cellSize = 25, textAlign = 'left', labelArray = []
    }: RenderCellParams) => {
      const customStyle = splitPoint >= 15 ? { width: cellSize } : { width: cellSize, paddingLeft: '40px' };
      if (label) {
        if (label.length > splitPoint) {
          return (
            <TableCell align={textAlign} style={customStyle}>
              <CustomizedTooltip title={label} placement="top">
                <Typography className={classes.cellLabel} variant="body1">{`${(label).slice(0, splitPoint)}...`}</Typography>
              </CustomizedTooltip>
            </TableCell>
          );
        }
        return (<TableCell align={textAlign} style={customStyle}><Typography className={classes.cellLabel} variant="body1">{label}</Typography></TableCell>);
      }

      if (labelArray.length > 0) {
        const rows: Array<JSX.Element> = [];

        labelArray.forEach((data) => {
          rows.push(
            <CustomizedTooltip key={data} title={data} placement="top">
              <Typography className={classes.cellLabel} variant="body1">{`${(data)?.slice(0, 15)}...`}</Typography>
            </CustomizedTooltip>)
        });
        return (
          <TableCell align={textAlign} style={customStyle}>
            {rows}
          </TableCell>
        );
      }
      return <TableCell align={textAlign} style={customStyle}><Typography className={classes.cellLabel} variant="body1">-</Typography></TableCell>;
    };
    const isDisabledcheckBox = row.originSystem !== OriginSystem.SIAF;
    return (
      <TableRow
        hover
        key={row.id}
      >
        <TableCell >
          {row.status === DiplomaStatus.IssuerConfirmationPending && (
            <Checkbox className={`${classes.checkbox} ${isDisabledcheckBox ? classes.checkboxDisabled : ''}`} disabled={isDisabledcheckBox} onChange={handleChange} id={row.id} checked={isInputChecked(row.id)}
            />)}

        </TableCell>
        <TableCell>
          <StagesTimeline
            steps={steps}
            currentValue={row.status}
          />
        </TableCell>
        {renderCell({ label: row.updatedAt ? getMediumDateFormat(row.updatedAt) : '-', textAlign: 'right' })}
        {renderCell({ label: row.graduateRegistrationNumber, textAlign: 'right' })}
        {renderCell({ label: row.graduateName, splitPoint: 15 })}
        {renderCell({ label: row.courseName, splitPoint: 15 })}
        {renderCell({ label: '', labelArray: getDefaultSignersName(row.defaultSigners), splitPoint: 15 })}

        <DiplomaActions defaultSigners={defaultSignersIes} status={row.status} id={row.id} diplomas={diplomas} institutionProfile={institutionType} />
      </TableRow>
    );
  }

  return (
    <Root className={classes.root}>
      <TableContainer>
        <Table>
          <EnhancedTableHead
            headCells={headCells}
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          {
            !loading ? (
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (buildCells(row)
                  ))}
                {rows.length > 0 && emptyRows > 0 && (
                  <TableRow style={{ height: 65 * emptyRows }}>
                    <TableCell colSpan={headCells.length} />
                  </TableRow>
                )}
                {rows.length === 0 && (
                  <TableRow style={{ height: 650 }}>
                    <TableCell colSpan={headCells.length}>
                      <EmptyTable emptyStateMessage="Nenhum Diploma Encontrado" orientationMessage="Tente selecionar filtros diferentes para sua busca!" />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableLoading />
            )
          }
        </Table>
      </TableContainer>
      {
        diplomas.length > 0 && (
          <TablePagination
            component="div"
            className={classes.pagination}
            count={rows.length ? rows.length : 0}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
            labelDisplayedRows={(obj: any) => (
              `${obj.from}-${obj.to} de ${obj.count}`
            )}
            nextIconButtonProps={{
              style: {
                color: page === getNumPages() ? GRAY_COLOR : DIPLOMA_COLOR,
              },
            }}
            backIconButtonProps={{
              style: {
                color: page === 0 ? GRAY_COLOR : DIPLOMA_COLOR,
              },
            }}
            page={page}
            onPageChange={handleChangePage}
          />
        )
      }

    </Root>
  );
};

export default connector(DiplomaTable) 
