import { Paper } from '@mui/material';
import React from 'react';
import BackCardHeader from '../back-card-header';
import { GoBackPaperProps } from './types';

export default function GoBackPaper({ children, onClickBack }: GoBackPaperProps) {
  return (
    <Paper>
      { onClickBack && <BackCardHeader onClick={onClickBack} />}
      { children }
    </Paper>
  );
}
