import axios, { AxiosResponse } from 'axios';

class StorageService {
  async uploadFileToBucket(
    url: string,
    fileName: string,
    formData: FormData
  ): Promise<AxiosResponse<string>> {
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=something',
        key: `${fileName}`
      }
    };
    const response = await axios.post(url, formData, options);

    return response.data;
  }
}

export default new StorageService();
