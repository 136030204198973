import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { User } from 'react-feather';
import { stringify as stringifySex } from '../../../../domain/enum/Sex';
import CityDto from '../../../../domain/interface/dto/CityDto';
import StateIdDto from '../../../../domain/interface/dto/StateIdDto';
import { formatCpf, formatDate } from '../../../utils/functions';
import CardTitle from '../card-title';
import BasePublicDataWidget from '../base-public-data-widget';
import InlineLabeledText from '../inline-labeled-text';
import { PublicGraduateDataWidgetProps } from './types';

const PREFIX = 'PublicGraduateDataWidget';

const classes = {
  icon: `${PREFIX}-icon`
};

const StyledBasePublicDataWidget = styled(BasePublicDataWidget)((
  {
    theme
  }
) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main,
  }
}));

function PublicGraduateDataWidget({ graduate, isLoading }: PublicGraduateDataWidgetProps): JSX.Element {


  return (
    <StyledBasePublicDataWidget title={<CardTitle variant="h3" icon={<User className={classes.icon} />}>Dados do Diplomado</CardTitle>}>
      <>
        <Grid item container>
          <InlineLabeledText label="Nome" text={graduate?.name} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Data de Nascimento" text={formatDate(graduate?.dateOfBirth)} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="CPF" text={graduate ? formatCpf(graduate.cpf) : undefined} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="RG" text={graduate ? StateIdDto.stringify(graduate?.stateId) : undefined} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Sexo" text={graduate ? stringifySex(graduate.sex) : undefined} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Nacionalidade" text={graduate?.nationality} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Naturalidade" text={graduate ? CityDto.stringify(graduate.placeOfBirth) : undefined} isLoading={isLoading} />
        </Grid>
      </>
    </StyledBasePublicDataWidget>
  );
}

PublicGraduateDataWidget.defaultProps = {
  isLoading: false,
};

export default PublicGraduateDataWidget;
