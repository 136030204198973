import { AxiosResponse } from 'axios';
import getAxiosInstance from '../apis/axiosInstance';
import ListDiplomasRequest from '../domain/interface/request/ListDiplomasRequest';
import ChangeDiplomaRequest from '../domain/interface/request/ChangeDiplomaRequest';
import Diploma from '../domain/model/diploma/Diploma';
import GetDashboardDataRequest from '../domain/interface/request/GetDashboardDataRequest';
import DashboardDataDto from '../domain/interface/dto/DashboardDataDto';
import AwaitingSignatureCountDto from '../domain/interface/dto/AwaitingSignatureCountDto';
import GraduateDocumentFile from '../domain/model/diploma/GraduateDocumentFile';
import GetGraduateDocumentRequest from '../domain/interface/request/GetGraduateDocumentRequest';
import PublicDiplomaDto from '../domain/interface/dto/PublicDiplomaDto';
import AddGraduateDocumentRequest from '../domain/interface/request/AddGraduateDocumentRequest';
import GetDiplomaRequest from '../domain/interface/request/GetDiplomaRequest';

const axiosInstance = getAxiosInstance(process.env.REACT_APP_DIPLOMA_API || '');

class DiplomaService {
  async listIssuerInstitutions(
    registerMecCode: number
  ): Promise<AxiosResponse<number[]>> {
    return axiosInstance.get(`issuer-institution/${registerMecCode}`);
  }

  async listDiplomas(
    request: ListDiplomasRequest
  ): Promise<Diploma[]> {
    let lastEvaluatedKey: string | undefined = undefined;
    let diplomas: Diploma[] = [];

    do {
      const response: AxiosResponse<any> = await axiosInstance.get('diploma-summary', {
        params: {
          ...request,
          status: JSON.stringify(request.status),
          lastEvaluatedKey
        },
      });
      diplomas = diplomas.concat(response.data.diplomas);
      lastEvaluatedKey = response.data.lastEvaluatedKey ?? undefined;
    } while (lastEvaluatedKey);

    return diplomas;
  }

  async getDiploma(
    request: GetDiplomaRequest
  ): Promise<AxiosResponse<Diploma>> {
    return axiosInstance.get(`diploma/${request.id}`);
  }

  async validateDiploma(request: Diploma): Promise<AxiosResponse<any>> {
    return axiosInstance.post('diploma/validate', request);
  }

  async addChangeDiploma(
    request: ChangeDiplomaRequest,
    diplomaId: string | undefined
  ): Promise<AxiosResponse<any>> {
    return axiosInstance.post(`diploma/${diplomaId}/change`, request);
  }

  async getDashboardData(
    request: GetDashboardDataRequest
  ): Promise<AxiosResponse<DashboardDataDto>> {
    return axiosInstance.get('diploma/dashboard', {
      params: {
        ...request,
      },
    });
  }

  async getGraduateDocument(
    request: GetGraduateDocumentRequest
  ): Promise<AxiosResponse<GraduateDocumentFile>> {
    return axiosInstance.get(
      `diploma/${request.diplomaId}/graduate-document?documentId=${request.documentId}`
    );
  }

  async getAwaitingSignatureCount(
    institutionMecCode: number
  ): Promise<AxiosResponse<AwaitingSignatureCountDto>> {
    return axiosInstance.get('diploma/awaiting-signature', {
      params: {
        institutionMecCode,
      },
    });
  }

  async getPublicDiploma(
    validationCode: string
  ): Promise<AxiosResponse<PublicDiplomaDto>> {
    return axiosInstance.get(`diploma/${validationCode}/public`);
  }

  async addGraduateDocument(
    request?: AddGraduateDocumentRequest,
    diplomaId?: string | undefined
  ): Promise<AxiosResponse<any>> {
    return axiosInstance.post(`diploma/${diplomaId}/graduate-document`, [
      request,
    ]);
  }

  async deleteGraduateDocument(
    diplomaId?: string,
    documentId?: string
  ): Promise<AxiosResponse<any>> {
    return axiosInstance.delete(
      `diploma/${diplomaId}/graduate-document?documentId=${documentId}`
    );
  }
}

export default new DiplomaService();
