import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import { Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Grid as GridIcon, User } from 'react-feather';
import Tables from '../../../../domain/enum/Tables';
import { DARK_GRAY_COLOR, FONT_FAMILY_EXTRABOLD } from '../../../../theme';
import { formatDate } from '../../../utils/functions';
import CardTitle from '../../molecules/card-title';
import CurriculumTable from '../../molecules/curriculum-table';
import CurriculumUnitTable from '../../molecules/curriculum-unit-table/views/CurriculumUnitTable';
import LabeledTooltip from '../../molecules/labeled-tooltip';
import { CurriculumPaperProps } from './types';


const PREFIX = 'index';

const classes = {
  icon: `${PREFIX}-icon`,
  typography: `${PREFIX}-typography`,
  errorIcon: `${PREFIX}-errorIcon`,
  message: `${PREFIX}-message`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main
  },

  [`& .${classes.typography}`]: {
    fontFamily: FONT_FAMILY_EXTRABOLD,
    color: theme.palette.primary.main
  },

  [`& .${classes.errorIcon}`]: {
    color: theme.palette.error.main,
    marginRight: '8px',
    marginBottom: '3px'
  },
  [`& .${classes.message}`]: {
    color: DARK_GRAY_COLOR,
    marginLeft: '5.5rem',

  }
}));

export default function CurriculumPaper({
  transcript,
  validation,
  originSystem,
  processNumber
}: CurriculumPaperProps): JSX.Element {

  return (
    <StyledPaper>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <CardTitle icon={<User className={classes.icon} />}>
            Histórico Escolar
          </CardTitle>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip
            label="Data de Emissão"
            error={validation?.issueDate}
            errorMessage={validation?.issueDate}
            originSystem={originSystem}
            processNumber={processNumber}
          >
            {formatDate(transcript?.issueDate) || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip
            label="Código do Currículo"
            error={validation?.curriculumCode}
            errorMessage={validation?.curriculumCode}
            originSystem={originSystem}
            processNumber={processNumber}
          >
            {transcript?.curriculumCode || 'N/A'}
          </LabeledTooltip>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <CardTitle
            variant="body1"
            classes={classes}
            icon={<GridIcon className={classes.icon} />}
          >
            Matriz Curricular
          </CardTitle>
        </Grid>
        {validation?.curriculum === 'Formato inválido' && (
          <Grid item xs={12}>
            <LabeledTooltip
              label="A Matriz Curricular não pode estar vazia"
              error={validation?.curriculum === 'Formato inválido'}
              originSystem={originSystem}
              processNumber={processNumber}
            ></LabeledTooltip>
          </Grid>
        )}
        {validation?.curriculum !== 'Formato inválido' && (
          <CurriculumTable
            curriculum={transcript?.curriculum}
            validation={validation?.curriculum}
          />
        )}
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <CardTitle
            variant="body1"
            classes={classes}
            icon={<DashboardCustomizeOutlinedIcon className={classes.icon} />}
          >
            Atividade Complementar
          </CardTitle>
        </Grid>
        {transcript?.complementaryActivities && transcript?.complementaryActivities.length > 0 ? (
          <CurriculumUnitTable
            data={transcript.complementaryActivities}
            validation={validation?.complementaryActivities}
            type={Tables.ComplementaryActivity}
          />
        ) : (<Typography className={classes.message} variant="body1">
          Nenhuma Atividade Complementar foi encontrada
        </Typography>)}
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={12}>
          <CardTitle
            variant="body1"
            classes={classes}
            icon={<AssignmentOutlinedIcon className={classes.icon} />}
          >
            Estágio
          </CardTitle>
        </Grid>
        {transcript?.internships && transcript?.internships.length > 0 ? (
          <CurriculumUnitTable
            data={transcript.internships}
            validation={validation?.internships}
            type={Tables.InternshipsTable}
          />
        ) : (<Typography className={classes.message} style={{ paddingBottom: '30px' }} variant="body1">
          Nenhum Estágio foi encontrado
        </Typography>)}
      </Grid>
    </StyledPaper>
  );
}
