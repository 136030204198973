import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import InstitutionProfile from '../../../../../domain/enum/InstitutionProfile';
import { RootState } from '../../../../../setup/reducer/reducers';
import DiplomaValidationButton from '../../../molecules/diploma-validation-button';
import CoursePaper from '../../../organisms/course-paper';
import GraduatePaper from '../../../organisms/graduate-paper';
import InstitutionPaper from '../../../organisms/institution-paper';
import DefaultTemplate from '../../../templates/default-template';
import { MEDIUM_GRAY_COLOR } from '../../../../../theme';
import {
  selectDiploma,
  updateComponentValidateActive
} from '../../diploma-page/store/actions';
import AcademicDocumentation from './AcademicDocumentation';
import CourtOrder from './CourtOrder';

const PREFIX = 'DiplomaValidate';

const classes = {
  root: `${PREFIX}-root`,
  fabIcon: `${PREFIX}-fabIcon`,
  errorColorFab: `${PREFIX}-errorColorFab`,
  successColorFab: `${PREFIX}-successColorFab`,
  primaryColorFab: `${PREFIX}-primaryColorFab`,
  fabGridRight: `${PREFIX}-fabGridRight`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    paddingBottom: theme.spacing(7)
  },

  [`& .${classes.fabIcon}`]: {
    marginRight: theme.spacing(1)
  },

  [`& .${classes.errorColorFab}`]: {
    backgroundColor: theme.palette.error.main,
    height: '44px',
    '&:hover': {
      opacity: 0.9,
      backgroundColor: theme.palette.error.main
    }
  },

  [`& .${classes.successColorFab}`]: {
    backgroundColor: theme.palette.success.main,
    height: '44px',
    '&:hover': {
      opacity: 0.9,
      backgroundColor: theme.palette.success.main
    },
    '&:disabled': {
      backgroundColor: MEDIUM_GRAY_COLOR
    }
  },

  [`& .${classes.primaryColorFab}`]: {
    backgroundColor: theme.palette.primary.main,
    height: '44px',
    '&:hover': {
      opacity: 0.9,
      backgroundColor: theme.palette.primary.main
    }
  },

  [`& .${classes.fabGridRight}`]: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(4),
    maxWidth: '45%'
  }
}));

export default function DiplomaValidate(): JSX.Element {
  const dispatch = useDispatch();
  const diploma = useSelector((state: RootState) => state.diplomaPage.diploma);
  const validation = useSelector(
    (state: RootState) => state.diplomaPage.validation
  );
  const selectedInstitutionProfile = useSelector(
    (state: RootState) => state.diplomaFilter.selectedInstitutionProfile
  );
  const isLoadingDiploma = useSelector(
    (state: RootState) => state.diplomaPage.isLoadingDiploma
  );
  const componentValidateActive = useSelector(
    (state: RootState) => state.diplomaPage.componentValidateActive
  );

  const clearDiploma = () => {
    dispatch(selectDiploma());
  };

  const nextPage = () => {
    dispatch(updateComponentValidateActive("AcademicDocumentation"));
  };

  const getPageAction = (): JSX.Element => {
    return (
      <DiplomaValidationButton
        handleClick={nextPage}
        text="Próximo"
        buttonType="primary"
        data-testid="diploma-next-page-button"
      />
    );
  };

  let activeComponent;

  if (componentValidateActive === "AcademicDocumentation") {
    activeComponent = <AcademicDocumentation />;
  } else {
    activeComponent = <CourtOrder />;
  }

  return (
    <Root >
      {
        componentValidateActive === "DiplomaValidate"
          ? (
            <DefaultTemplate message="Validação de" highlightedMessage="Diplomas">
              <Grid container spacing={2} className={classes.root}>
                <Grid item md={5}>
                  <GraduatePaper graduate={diploma?.graduate} onClickBack={clearDiploma} validation={validation?.graduate} isLoading={isLoadingDiploma} processNumber={diploma?.processNumber} originSystem={diploma?.originSystem} />
                </Grid>
                <Grid item md={7}>
                  <CoursePaper course={diploma?.course} validation={validation?.course} isLoading={isLoadingDiploma} processNumber={diploma?.processNumber} originSystem={diploma?.originSystem} />
                </Grid>
                <Grid item md={12}>
                  <InstitutionPaper institution={diploma?.issuerInstitution} profile={InstitutionProfile.Issuer} validation={validation?.issuerInstitution} isLoading={isLoadingDiploma} />
                </Grid>
                {selectedInstitutionProfile === InstitutionProfile.Register && (
                  <Grid item md={12}>
                    <InstitutionPaper institution={diploma?.registerInstitution} profile={InstitutionProfile.Register} validation={validation?.registerInstitution} isLoading={isLoadingDiploma} />
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={3}
                className={classes.fabGridRight}
              >
                {getPageAction()}
              </Grid>
            </DefaultTemplate >
          ) :
          activeComponent
      }
    </Root>
  );
}
