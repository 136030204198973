import { Theme, Tooltip } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const PaperTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.default,
    minWidth: 200,
    maxWidth: 400,
    borderRadius: '0px 0px 8px 8px',
    boxShadow: '0px 9px 15px rgba(0, 0, 0, 0.05), 0px 2px 38px rgba(0, 0, 0, 0.07)',
  },
  tooltipPlacementBottom: {
    margin: '1px 0px',
  },
}))(Tooltip);

export default PaperTooltip;
