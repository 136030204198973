import React from 'react';
import { styled } from '@mui/material/styles';
import {
  FormControlLabel,
  Grid,
  RadioGroup,
  Radio,
  Typography
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { HelpCircle } from 'react-feather';
import {
  errorNotificationMessages,
  modalInformation,
  notificationIcons,
  successNotificationMessages
} from '../../../../utils/constants';
import ReasonType from '../../../../../domain/enum/ReasonType';
import CancellationType from '../../../../../domain/enum/diploma/CancellationType';
import { DIPLOMA_COLOR, INFO_COLOR } from '../../../../../theme';
import CustomizedTooltip from '../../../atoms/customized-tooltip';
import ChangeDiplomaRequest from '../../../../../domain/interface/request/ChangeDiplomaRequest';
import { RootState } from '../../../../../setup/reducer/reducers';
import ChangeType from '../../../../../domain/enum/diploma/ChangeType';
import InstitutionProfile from '../../../../../domain/enum/InstitutionProfile';
import DiplomaService from '../../../../../services/DiplomaService';
import BackdropLoading from '../../../molecules/backdrop-loading';
import {
  selectDiploma,
  toggleInfoDiploma,
  toggleNextPage
} from '../../diploma-page/store/actions';
import {
  setDiplomas,
  setLoadingDiplomas
} from '../../diploma-list/store/actions';
import ConfirmationModal from '../../../organisms/confirmation-modal';
import {
  showNotification,
  showSuccessNotification
} from '../../notification/actions';
import DiplomaStatus from '../../../../../domain/enum/diploma/DiplomaStatus';
import CustomTextField from '../../../atoms/form/CustomTextField';

const PREFIX = 'CancelDiploma';

const classes = {
  spaced: `${PREFIX}-spaced`,
  helperIcon: `${PREFIX}-helperIcon`,
  descriptionLoading: `${PREFIX}-descriptionLoading`,
  circularProgress: `${PREFIX}-circularProgress`,
  grid: `${PREFIX}-grid`,
  texField: `${PREFIX}-texField`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.spaced}`]: {
    marginBottom: theme.spacing(4)
  },

  [`& .${classes.helperIcon}`]: {
    color: INFO_COLOR,
    width: '14.33px',
    height: '14.33px'
  },

  [`& .${classes.descriptionLoading}`]: {
    width: '19.938rem',
    color: DIPLOMA_COLOR
  },

  [`& .${classes.circularProgress}`]: {
    color: DIPLOMA_COLOR
  },

  [`& .${classes.grid}`]: {
    width: '416px'
  },

  [`& .${classes.texField}`]: {
    width: '400px'
  }
}));

type Props = {
  setOpenModal: (flag: boolean) => void;
  openModal: boolean;
  id?: string;
  status?: DiplomaStatus;
};

const CancelDiploma = (props: Props) => {
  const { openModal, id, status, setOpenModal } = props;

  const dispatch = useDispatch();

  const [reasonType, setReasonType] = React.useState(ReasonType.CraftError);
  const [typeValue, setTypeValue] = React.useState(CancellationType.Definitely);
  const [cancelledDescription, setCancelledDescription] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);
  const [isValidDescription, setIsValidDescription] = React.useState(true);

  const {
    selectedInstitutionProfile,
    selectedDiplomaStatus,
    selectedIssuerInstitution
  } = useSelector((state: RootState) => state.diplomaFilter);
  const selectedInstitution = useSelector(
    (state: RootState) => state.header.institution
  );
  const { info, nextPage } = useSelector(
    (state: RootState) => state.diplomaPage
  );

  function getChangeType(): ChangeType {
    if (selectedInstitutionProfile === InstitutionProfile.Issuer) {
      if (typeValue === CancellationType.Temporarily) {
        return ChangeType.IssuerInstitutionTemporarilyCancelsDiploma;
      }
      return ChangeType.IssuerInstitutionCancelsDiploma;
    }

    if (typeValue === CancellationType.Temporarily) {
      return ChangeType.RegisterInstitutionTemporarilyCancelsDiploma;
    }
    return ChangeType.RegisterInstitutionCancelsDiploma;
  }

  async function reloadDiplomas(): Promise<any> {
    dispatch(setLoadingDiplomas(true));
    if (selectedInstitutionProfile === InstitutionProfile.Register) {
      return dispatch(
        setDiplomas(
          setLoadingDiplomas,
          selectedDiplomaStatus,
          selectedInstitution,
          selectedIssuerInstitution,
          selectedInstitutionProfile
        )
      );
    }
    return dispatch(
      setDiplomas(
        setLoadingDiplomas,
        selectedDiplomaStatus,
        undefined,
        selectedInstitution,
        selectedInstitutionProfile
      )
    );
  }

  async function handleSubmit(): Promise<void> {
    setIsValidDescription(!!cancelledDescription.trim());
    if (!isLoading && !!cancelledDescription.trim()) {
      setLoading(true);
      const changeRequest: ChangeDiplomaRequest = {
        type: getChangeType(),
        reason: cancelledDescription,
        reasonType
      };
      try {
        await DiplomaService.addChangeDiploma(changeRequest, id);
        dispatch(
          showSuccessNotification(
            successNotificationMessages.defaultSuccess,
            notificationIcons.success
          )
        );
      } catch (e) {
        dispatch(
          showNotification(
            errorNotificationMessages.defaultError,
            notificationIcons.error
          )
        );
      }
      setLoading(false);
      setOpenModal(false);
      dispatch(selectDiploma());
      await reloadDiplomas();
      dispatch(setLoadingDiplomas(false));
      if (info) {
        dispatch(toggleInfoDiploma());
      }
      if (nextPage) {
        dispatch(toggleNextPage());
      }
    }
  }

  function handleClickCancelButton(): void {
    setOpenModal(false);
  }

  function handleChangeReason(event: any): void {
    setReasonType(event.target.value);
    setTypeValue(CancellationType.Definitely);
  }

  function handleChangeType(event: any): void {
    setTypeValue(event.target.value);
  }

  function handleChangeCancelledDescription(event: any): void {
    setIsValidDescription(!!event.target.value.trim());
    setCancelledDescription(event.target.value);
  }

  return (
    <>
      <ConfirmationModal
        openModal={openModal}
        modalTitle={modalInformation.modalCancel.title}
        textContinueButton={modalInformation.modalCancel.textContinue}
        handleContinueButton={handleSubmit}
        textCancelButton={modalInformation.modalCancel.textCancel}
        handleCancelButton={handleClickCancelButton}
      >
        <StyledGrid
          container
          justifyContent="center"
          alignItems="stretch"
          className={classes.grid}
        >
          {isLoading && (
            <BackdropLoading
              size={50}
              description="Anulando Diploma..."
              open={isLoading}
              classes={classes}
            />
          )}

          <Grid item>
            <Typography className={classes.spaced}>
              {modalInformation.modalCancel.description}
            </Typography>
            <Typography variant="caption">Motivo</Typography>
            <RadioGroup
              name="reasonType"
              value={reasonType}
              onChange={handleChangeReason}
            >
              <Grid item container direction="column">
                <Grid item>
                  <FormControlLabel
                    value={ReasonType.CraftError}
                    control={<Radio size="small" />}
                    label={ReasonType.CraftError}
                  />
                  <CustomizedTooltip
                    title="O erro de ofício ocorre por identificação de erros de dados, averbação, apostilamento ou decisão judicial."
                    placement="top-start"
                  >
                    <HelpCircle className={classes.helperIcon} />
                  </CustomizedTooltip>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    value={ReasonType.FactualError}
                    control={<Radio size="small" />}
                    label={ReasonType.FactualError}
                  />
                  <CustomizedTooltip
                    title="O erro de fato ocorre em casos de reanálise da documentação de matrícula do aluno."
                    placement="top-start"
                  >
                    <HelpCircle className={classes.helperIcon} />
                  </CustomizedTooltip>
                </Grid>
              </Grid>
            </RadioGroup>
            <Typography variant="caption">Tipo da Anulação</Typography>
            <RadioGroup
              name="cancellationType"
              value={typeValue}
              onChange={handleChangeType}
            >
              <FormControlLabel
                value={CancellationType.Definitely}
                control={<Radio size="small" />}
                label={CancellationType.Definitely}
              />
              <FormControlLabel
                value={CancellationType.Temporarily}
                disabled={
                  reasonType === ReasonType.CraftError ||
                  status === DiplomaStatus.TemporarilyCancelled
                }
                control={<Radio size="small" />}
                label={CancellationType.Temporarily}
              />
            </RadioGroup>
            <Typography variant="caption">Descrição do motivo</Typography>
            <br />
            <CustomTextField
              name="cancelledDescription"
              value={cancelledDescription}
              fullWidth
              className={classes.texField}
              rows={5}
              onChange={handleChangeCancelledDescription}
              placeholder="Digite aqui os motivos..."
              helperText={
                isValidDescription
                  ? 'Esta descrição será disponibilizada no Portal Público da IES.'
                  : 'Campo obrigatório'
              }
              multiline
              error={!isValidDescription}
            />
          </Grid>
        </StyledGrid>
      </ConfirmationModal>
    </>
  );
};

CancelDiploma.defaultProps = {
  id: undefined,
  status: undefined
};

export default CancelDiploma;
