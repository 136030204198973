import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { Search } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../setup/reducer/reducers';
import CustomTextField from '../../../atoms/form/CustomTextField';
import SelectAutocomplete from '../../../molecules/select-autocomplete';
import { DIPLOMA_COLOR, GRAY_COLOR } from '../../../../../theme';
import DouFilterManager from '../DouFilterManager';
import {
  setSearch, setStatus, setBatches, setLoadingBatches, setType,
} from '../store/actions';
import { DouFilterBatchStatus, DouFilterBatchType } from '../types';

const PREFIX = 'DouFilter';

const classes = {
  root: `${PREFIX}-root`
};

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {
    padding: '30px',
  }
}));

export default function DouFilter(): JSX.Element {

  const douNumber = useSelector((state: RootState) => state.douFilter.search);
  const selectedStatus = useSelector((state: RootState) => state.douFilter.status);
  const selectedDouBatchType = useSelector((state: RootState) => state.douFilter.type);
  const selectedInstitution = useSelector((state: RootState) => state.header.institution);
  const statuses = DouFilterManager.DouBatchStatuses;
  const types = DouFilterManager.DouBatchTypes;
  const dispatch = useDispatch();

  const setBatchNumber = (number?: number) => dispatch(setSearch(number));
  const setBatchStatus = (status?: DouFilterBatchStatus) => dispatch(setStatus(status));
  const setBatchType = (type?: DouFilterBatchType) => dispatch(setType(type));

  useEffect(() => {
    if (selectedInstitution?.mecCode) {
      dispatch(setLoadingBatches(true));
      dispatch(setBatches(selectedInstitution));
    }
  }, [dispatch, selectedInstitution]);

  return (
    <StyledGrid className={classes.root} container spacing={3}>
      <Grid item xs={4} mt={2}>
        <CustomTextField
          placeholder="Pesquise por número do lote..."
          fullWidth
          type="text"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBatchNumber(Number(e.target.value))}
          InputProps={{
            endAdornment: <Search color={douNumber ? DIPLOMA_COLOR : GRAY_COLOR} />,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <SelectAutocomplete
          defaultValue={selectedDouBatchType}
          compareName
          listItems={types}
          label="Tipo do Lote"
          placeholder="Nenhum tipo selecionado"
          setItem={setBatchType}
        />
      </Grid>
      <Grid item xs={4}>
        <SelectAutocomplete
          defaultValue={selectedStatus}
          compareName
          listItems={statuses}
          label="Status do Lote"
          placeholder="Nenhum status selecionado"
          setItem={setBatchStatus}
        />
      </Grid>
    </StyledGrid>
  );
}
