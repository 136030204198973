import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Typography
} from '@mui/material';

import {
  errorNotificationMessages,
  modalInformation,
  notificationIcons,
  pageRoutes,
  successNotificationMessages
} from '../../../../utils/constants';
import { DIPLOMA_COLOR, INFO_COLOR } from '../../../../../theme';
import BackdropLoading from '../../../molecules/backdrop-loading';
import ConfirmationModal from '../../../organisms/confirmation-modal';
import {
  showNotification,
  showSuccessNotification
} from '../../notification/actions';
import CustomTextField from '../../../atoms/form/CustomTextField';
import ChangeCurriculumRequest from '../../../../../domain/interface/request/ChangeCurriculumRequest';
import ChangeType from '../../../../../domain/enum/curriculum/ChangeType';
import CurriculumService from '../../../../../services/CurriculumService';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const PREFIX = 'rejectedCurriculum';

const classes = {
  spaced: `${PREFIX}-spaced`,
  helperIcon: `${PREFIX}-helperIcon`,
  descriptionLoading: `${PREFIX}-descriptionLoading`,
  circularProgress: `${PREFIX}-circularProgress`,
  grid: `${PREFIX}-grid`,
  texField: `${PREFIX}-texField`,
  counterTexField: `${PREFIX}-counterTexField`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.spaced}`]: {
    marginBottom: theme.spacing(4)
  },

  [`& .${classes.helperIcon}`]: {
    color: INFO_COLOR,
    width: '14.33px',
    height: '14.33px'
  },

  [`& .${classes.descriptionLoading}`]: {
    width: '19.938rem',
    color: DIPLOMA_COLOR
  },

  [`& .${classes.circularProgress}`]: {
    color: DIPLOMA_COLOR
  },

  [`& .${classes.grid}`]: {
    width: '416px'
  },

  [`& .${classes.texField}`]: {
    width: '512px'
  },
  [`& .${classes.counterTexField}`]: {
    float: 'right'
  }
}));

type Props = {
  setOpenModal: (flag: boolean) => void;
  openModal: boolean;
  id: string;
};

const RejectedCurriculum = (props: Props) => {
  const { openModal, id, setOpenModal } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const [rejectedDescription, setRejectedDescription] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);
  const [isValidDescription, setIsValidDescription] = React.useState(true);


  async function handleSubmit(): Promise<void> {
    setIsValidDescription(!!rejectedDescription.trim());
    if (!isLoading && !!rejectedDescription.trim()) {
      setLoading(true);
      const changeRequest: ChangeCurriculumRequest = {
        type: ChangeType.IssuerInstitutionRejectedCurriculum,
        reasonType: rejectedDescription
      };
      try {
        await CurriculumService.addChangeCurriculum(changeRequest, id);
        dispatch(
          showSuccessNotification(
            successNotificationMessages.defaultSuccess,
            notificationIcons.success
          )
        );
      } catch (e) {
        dispatch(
          showNotification(
            errorNotificationMessages.defaultError,
            notificationIcons.error
          )
        );
      }
      setLoading(false);
      setOpenModal(false);
      history.push(pageRoutes.DigitalCurriculum)
    }
  }

  function handleClickrejectedButton(): void {
    setRejectedDescription('')
    setOpenModal(false);
  }

  function handleChangerejectedDescription(event: any): void {
    setIsValidDescription(!!event.target.value.trim());
    setRejectedDescription(event.target.value);
  }

  return (
    <ConfirmationModal
      openModal={openModal}
      modalTitle={modalInformation.modalRejectCurriculum.title}
      textContinueButton={modalInformation.modalRejectCurriculum.textContinue}
      handleContinueButton={handleSubmit}
      textCancelButton={modalInformation.modalRejectCurriculum.textCancel}
      handleCancelButton={handleClickrejectedButton}
    >
      <StyledGrid
        container
        justifyContent="center"
        alignItems="stretch"
        className={classes.grid}
      >
        {isLoading && (
          <BackdropLoading
            size={50}
            description="Reprovando currículo..."
            open={isLoading}
            classes={classes}
          />
        )}

        <Grid item>
          <Typography className={classes.spaced}>
            {modalInformation.modalRejectCurriculum.description}
          </Typography>
          <Typography variant="caption">Descrição do motivo</Typography>
          <Typography className={classes.counterTexField} variant="caption">{`${rejectedDescription.length}/240`}</Typography>
          <br />
          <CustomTextField
            name="rejectedDescription"
            value={rejectedDescription}
            fullWidth
            className={classes.texField}
            rows={5}
            onChange={handleChangerejectedDescription}
            placeholder="Digite aqui os motivos..."
            helperText={
              isValidDescription
                ? ''
                : 'Campo obrigatório'
            }
            inputProps={{ maxLength: 240 }}
            multiline
            error={!isValidDescription}
          />
        </Grid>
      </StyledGrid>
    </ConfirmationModal>
  );
};


export default RejectedCurriculum;
