import { useSelector } from 'react-redux';
import UsersModal from '../../../organisms/users-modal';
import DefaultTemplate from '../../../templates/default-template';
import UsersManagementList from './UsersManagementList';
import { RootState } from '../../../../../setup/reducer/reducers';

const UsersManagementPage = () => {

  const openUsersModal = useSelector((state: RootState) => state.usersManagementFilter.openUsersModal);
  const typeSubmitUsersModal = useSelector((state: RootState) => state.usersManagementFilter.typeSubmit);

  return (
    <DefaultTemplate>
      <UsersManagementList />
      <UsersModal openModal={openUsersModal} typeSubmit={typeSubmitUsersModal} />
    </DefaultTemplate>
  );
}

export default UsersManagementPage;