import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Paper, Divider, Button } from '@mui/material';
import CardTitle from '../../../molecules/card-title';
import { theme } from '../../../../../theme';
import { User } from 'react-feather';
import UsersManagementFilter from './UsersManagementFilter';
import UsersManagementTable from './UsersManagementTable';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch } from 'react-redux';
import { setUsersModalAction } from '../store/actions';
import TypeSubmit from '../../../../../domain/enum/TypeSubmit';
const PREFIX = 'InstitutionManagementList';

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  buttonAdd: `${PREFIX}-buttonAdd`,
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    marginBottom: '50px',
    maxHeight: '1000px',
  },
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main
  },
  [`& .${classes.buttonAdd}`]: {
    height: '2.5rem',
    marginRight: '2rem',
  },
}));

export default function UsersManagementList(): JSX.Element {
  const dispatch = useDispatch();
  function handleSubmit() {
    dispatch(setUsersModalAction(true, TypeSubmit.NewUserModal))
  }
  return (
    <Root>
      <Paper className={classes.root}>
        <Grid container item xs={12} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <CardTitle icon={<User className={classes.icon} />}>
            Cadastro de usuários
          </CardTitle>
          <Button variant="contained" size="large" startIcon={<AddIcon />} onClick={handleSubmit} className={classes.buttonAdd} >Adicionar Novo</Button>
        </Grid>
        <Divider />
        <>
          <UsersManagementFilter />
          <UsersManagementTable />
        </>
      </Paper>
    </Root>
  );
}