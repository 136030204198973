enum Brands {
  AGES = 'AGES',
  EBRADI = 'EBRADI',
  FADERGS = 'FADERGS',
  FASEH = 'FASEH',
  FPB = 'FPB',
  HSMU = 'HSMU',
  IBCMED = 'IBCMED',
  IBMR = 'IBMR',
  INSPIRALI = 'INSPIRALI',
  INSTITUTO = 'INSTITUTO',
  MCAMPOS = 'MCAMPOS',
  USJT = 'USJT',
  UAM = 'UAM',
  UNA = 'UNA',
  UNIBH = 'UNIBH',
  UNICURITIBA = 'UNICURITIBA',
  UNIFACS = 'UNIFACS',
  UNIFGUA = 'UNIFGUA',
  UNIFG = 'UNIFG',
  UNIRITTER = 'UNIRITTER',
  SOCIESC = 'SOCIESC',
  UNISUL = 'UNISUL',
  UNP = 'UNP'
}

export default Brands;
