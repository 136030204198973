import Root from '../../../../../domain/model/curriculum/Root';
import { SelectedCourse, SelectedOriginSystem, SelectedStatus } from '../types';

export const SET_LOADING_CURRICULUMS =
  'app/digital-curriculum-list/SET_LOADING_CURRICULUMS';

export const SET_CURRICULUMS = 'app/digital-curriculum-list/SET_CURRICULUMS';

export const SET_SELECTED_SYNC_CURRICULUMS =
  'app/diploma-list/SET_SELECTED_SYNC_CURRICULUMS';

export const SET_CURRICULUMS_FILTER =
  'app/digital-curriculum-list/SET_FILTER_CURRICULUMS';

export interface CurriculumsFilter {
  originSystem: SelectedOriginSystem;
  courses: SelectedCourse[];
  statuses: SelectedStatus[];
}

export interface CurriculumState {
  loadingCurriculums: boolean;
  curriculums: Root[];
  syncCurriculums: Root[];
  filter: CurriculumsFilter;
}

export const INITIAL_STATE: CurriculumState = {
  loadingCurriculums: false,
  curriculums: [],
  syncCurriculums: [],
  filter: {
    originSystem: {
      id: '',
      name: 'Todos'
    },
    courses: [],
    statuses: []
  }
};

interface SetCurriculumsAction {
  type: typeof SET_CURRICULUMS;
  payload: {
    curriculums: Array<Root>;
  };
}

interface SetLoadingCurriculumsAction {
  type: typeof SET_LOADING_CURRICULUMS;
  payload: {
    loadingCurriculums: boolean;
  };
}

export interface SetSelectedSyncCurriculumsAction {
  type: typeof SET_SELECTED_SYNC_CURRICULUMS;
  payload: {
    syncCurriculums: Root[];
  };
}

export interface SetCurriculumsFilterActions {
  type: typeof SET_CURRICULUMS_FILTER;
  payload: {
    filter: CurriculumsFilter;
  };
}

export type CurriculumActions =
  | SetCurriculumsAction
  | SetLoadingCurriculumsAction
  | SetSelectedSyncCurriculumsAction
  | SetCurriculumsFilterActions;
