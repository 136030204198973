import React from 'react';
import { Grid, Typography } from '@mui/material';
import usePageTitleStyles from './styles';
import { PageTitleProps } from './types';

function PageTitle({ highlightedMessage, message }: PageTitleProps): JSX.Element {
  const classes = usePageTitleStyles();

  return (
    <Grid
      container
      alignItems="center"
      className={classes.root}
      alignContent="flex-start"
      spacing={10}
    >
      <Grid
        item
        xs={12}
        container
        className={classes.containerTitle}
      >
        {
          message && (
            <Typography variant="h1" noWrap className={classes.grayText}>
              {message}
            </Typography>
          )
        }
        {
          highlightedMessage && (
            <Typography variant="h1" noWrap>
              {highlightedMessage}
            </Typography>
          )
        }
      </Grid>
    </Grid>
  );
}

PageTitle.defaultProps = {
  highlightedMessage: undefined,
  message: undefined,
};

export default PageTitle;
