enum Sex {
  Male = 'M',
  Female = 'F',
  NA = ''
}

export const stringify = (sex: Sex): string =>
  sex === Sex.Male ? 'Masculino' : 'Feminino';

export default Sex;
