import React from 'react';
import {
  Button, Grid,
} from '@mui/material';
import BaseModal from '../../molecules/base-modal';
import useConfirmationModalStyles from './styles';
import { ConfirmationModalProps } from './types';

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const classes = useConfirmationModalStyles();

  const {
    openModal,
    modalTitle,
    modalSubTitle,
    textContinueButton,
    handleContinueButton,
    children,
    textCancelButton,
    handleCancelButton,
    loading,
    loadingMessage,
  } = props;

  return (
    <BaseModal
      openModal={openModal}
      onClose={handleCancelButton}
      modalTitle={modalTitle}
      modalSubTitle={modalSubTitle}
      loading={loading}
      loadingMessage={loadingMessage}
      actions={(
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Button
            className={classes.backButton}
            onClick={handleCancelButton}
          >
            {textCancelButton}
          </Button>
          <Button
            className={classes.continueButton}
            onClick={() => {
              handleContinueButton();
            }}
          >
            {textContinueButton}
          </Button>
        </Grid>
      )}
    >
      {children}
    </BaseModal>
  );
};

ConfirmationModal.defaultProps = {
  children: null,
  loading: false,
  loadingMessage: '',
};

export default ConfirmationModal;
