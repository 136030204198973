import {
  INITIAL_STATE,
  InstitutionManagementActions,
  InstitutionManagementState,
  SET_INSTITUTIONS_SIGNERS,
  SET_LOADING_INSTITUTIONS_SIGNERS,
  SET_PAGE_INSTITUTION,
  SET_SEARCH,
  SET_SHOW_NAME_INSTITUTION_DROPDOWN
} from './types';

export default function reducer(
  state: InstitutionManagementState = INITIAL_STATE,
  action: InstitutionManagementActions
): InstitutionManagementState {
  switch (action.type) {
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload.search
      };
    case SET_INSTITUTIONS_SIGNERS:
      return {
        ...state,
        institutionsSigners: action.payload.institutionsSigners
      };
    case SET_LOADING_INSTITUTIONS_SIGNERS:
      return {
        ...state,
        loadingInstitutionsSigners: action.payload.loadingInstitutionsSigners
      };
    case SET_SHOW_NAME_INSTITUTION_DROPDOWN:
      return {
        ...state,
        showNameInstitutionDropdow: action.payload.showNameInstitutionDropdow
      };
    case SET_PAGE_INSTITUTION:
      return {
        ...state,
        page: action.payload.page
      };
    default:
      return state;
  }
}
