import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Paper, Grid, Typography, Box, Link, Button, Skeleton
} from '@mui/material';
import { useSelector } from 'react-redux';
import Terms from '../../../../icons/Terms';
import ExternalLink from '../../../../icons/ExternalLink';
import {
  GRAY_COLOR,
  DIPLOMA_COLOR,
  WHITE_COLOR,
  MEDIUM_GRAY_COLOR,
  HOVER_BUTTON_DIPLOMA_COLOR,
  BUTTON_SHADOW,
  FONT_FAMILY_REGULAR,
  FONT_FAMILY_BOLD,
} from '../../../../../theme';
import { ManagementCardProps } from '../types';
import { RootState } from '../../../../../setup/reducer/reducers';
import { getAwaitingSignatureCount } from '../DiplomaManagementManager';

const PREFIX = 'ManagementCard';

const classes = {
  root: `${PREFIX}-root`,
  root2: `${PREFIX}-root2`,
  grid: `${PREFIX}-grid`,
  h4: `${PREFIX}-h4`,
  body1: `${PREFIX}-body1`,
  gridIcon: `${PREFIX}-gridIcon`,
  visualization: `${PREFIX}-visualization`,
  skeleton: `${PREFIX}-skeleton`,
  secondSkeleton: `${PREFIX}-secondSkeleton`,
  thirdSkeleton: `${PREFIX}-thirdSkeleton`
};

const StyledPaper = styled(Paper)(() => ({
  [`& .${classes.root}`]: {
    width: '194px',
    height: '44px',
    boxShadow: BUTTON_SHADOW,
    marginTop: '24px',
    borderRadius: '4px',
    backgroundColor: DIPLOMA_COLOR,
    '&:hover': {
      backgroundColor: HOVER_BUTTON_DIPLOMA_COLOR,
      boxShadow: BUTTON_SHADOW,
      border: 'none',
    },
    '&:active': {
      boxShadow: BUTTON_SHADOW,
      backgroundColor: DIPLOMA_COLOR,
      border: 'none',
    }
  },
  [`& .${classes.root2}`]: {
    width: '100%',
    marginBottom: '50px',
    maxHeight: '471px',
    maxWidth: '886px',
  },

  [`& .${classes.grid}`]: {
    height: '471px',
  },


  [`& .${classes.h4}`]: {
    color: MEDIUM_GRAY_COLOR,
  },

  [`& .${classes.body1}`]: {
    fontFamily: FONT_FAMILY_REGULAR,
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: '24px',
    color: GRAY_COLOR,
    width: '266px',
    textAlign: 'center',
  },

  [`& .${classes.gridIcon}`]: {
    maxHeight: '52%',
  },

  [`& .${classes.visualization}`]: {
    fontFamily: FONT_FAMILY_BOLD,
  },

  [`& .${classes.skeleton}`]: {
    paddingTop: '24px',
    width: '250px',
    height: '40px',
  },

  [`& .${classes.secondSkeleton}`]: {
    width: '110px',
    height: '60px',
  },

  [`& .${classes.thirdSkeleton}`]: {
    width: '150px',
    height: '70px',
  }
}));

const BootstrapButton = Button;

const ManagementCard = (props: ManagementCardProps) => {

  const { link } = props;

  const institution = useSelector((state: RootState) => state.header.institution);
  const [quantity, setQuantity] = useState<number | undefined>(undefined);

  useEffect(() => {
    setQuantity(undefined);
    async function fetchAwaitingSignature() {
      if (institution?.mecCode) {
        const awaitingSignatureCount = await getAwaitingSignatureCount(institution.mecCode);
        setQuantity(awaitingSignatureCount.awaitingIssuerSignature);
      }
    }
    fetchAwaitingSignature();
  }, [institution]);

  return (
    <StyledPaper className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.gridIcon}
          container
          item
          xs={12}
          alignContent="flex-end"
          justifyContent="center"
        >
          <Terms />
        </Grid>
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          alignContent="center"
          direction="column"
        >
          <Box display="flex" p={1}>
            {quantity === undefined && <Skeleton className={classes.skeleton} animation="wave" />}
            {quantity !== undefined
              && (
                <Typography className={classes.h4} variant="h4">
                  {quantity === 0 && 'Você não possui diplomas pendentes para assinar'}
                  {quantity === 1 && 'Você possui 1 diploma pendente para assinar'}
                  {quantity > 1 && `Você possui ${quantity} diplomas pendentes para assinar`}
                </Typography>
              )}
          </Box>
          {quantity === undefined && (
            <>
              <Skeleton className={classes.secondSkeleton} animation="wave" />
              <Skeleton className={classes.thirdSkeleton} animation="wave" />
            </>
          )}
          {quantity !== undefined && quantity >= 1
            && (
              <>
                <Typography className={classes.body1} variant="body1">
                  Clique em visualizar para acessar o portal da assinatura
                </Typography>
                <BootstrapButton
                  variant="contained"
                  disableRipple
                  classes={{
                    root: classes.root
                  }}>
                  <Box display="flex" p={1}>
                    <Link underline="none" href={link} target="_blank" style={{ marginTop: '4px', marginRight: '8px' }}>
                      <Typography variant="button" className={classes.visualization}>
                        Visualizar
                      </Typography>
                    </Link>
                    <Link underline="none" href={link} target="_blank" style={{ marginTop: '2.5px' }}>
                      <ExternalLink color={WHITE_COLOR} />
                    </Link>
                  </Box>
                </BootstrapButton>
              </>
            )}
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default ManagementCard;
