import React from 'react';
import { useStagesTimelineStyles } from './styles';
import { StepDotProps } from './types';

export function StepDot({ active, currentStepColor }: StepDotProps) {
  const styles = useStagesTimelineStyles({ currentStepColor });
  return (
    <div className={`${styles.dot} ${active && styles.activeDot}`} />
  );
}
