import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import CardTitle from '../../molecules/card-title';
import { CurriculumComplementaryActivitiesPaperProps, CurriculumComplementaryActivitiesTableProps } from './types';
import TableLoading from '../../molecules/table-loading';
import TypographyWithError from '../../molecules/typography-with-error';

const PREFIX = 'CurriculumComplementaryActivityPaper';

const classes = {
  icon: `${PREFIX}-icon`,
  table: `${PREFIX}-table`,
  tableHeaderLabel: `${PREFIX}-table-header-label`,
  e2sTableTopCell: `${PREFIX}-e2sTableTopCell`,
  e2sTableBottomCell: `${PREFIX}-e2sTableBottomCell`
};

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${classes.table}`]: {
    padding: '0px 32px 24px 32px',
  },
  [`& .${classes.tableHeaderLabel}`]: {
    ...theme.typography.body1,
  },
  [`& .${classes.e2sTableTopCell}`]: {
    borderBottom: 'none',
    paddingBottom: 0
  },
  [`& .${classes.e2sTableBottomCell}`]: {
    paddingTop: 0
  }
}));

const TableRows = ({ complementaryActivityCategories, validation }: { complementaryActivityCategories: any, validation: any }) => {

  if (complementaryActivityCategories.length > 0) {
    return complementaryActivityCategories.map((complementaryActivity: any, indexComplementaryActivity: number) => {
      const complementaryActivityValidation = validation ? validation[indexComplementaryActivity] : null;

      return complementaryActivity.activities.map((activity: any, indexActivity: string | number) => {
        const activityValidation = complementaryActivityValidation?.activities ?
          complementaryActivityValidation.activities[indexActivity] : null;

        return (
          <>
            <TableRow>
              <TableCell component="th" scope="row" className={classes.e2sTableTopCell}>
                <TypographyWithError
                  value={complementaryActivity?.code || 'N/A'}
                  errors={complementaryActivityValidation?.code} />
              </TableCell>
              <TableCell className={classes.e2sTableTopCell}>
                <TypographyWithError
                  value={complementaryActivity?.name || 'N/A'}
                  errors={complementaryActivityValidation?.name} />
              </TableCell>
              <TableCell className={classes.e2sTableTopCell}>
                <TypographyWithError
                  value={complementaryActivity?.limitLoadClockTime ? complementaryActivity?.limitLoadClockTime.toString() : 'N/A'}
                  errors={complementaryActivityValidation?.limitLoadClockTime} />
              </TableCell>
              <TableCell className={classes.e2sTableTopCell}>
                <TypographyWithError
                  value={activity?.description || 'N/A'}
                  errors={activityValidation?.description} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3} className={classes.e2sTableBottomCell} component="th" scope="row" />
              <TableCell>
                <Grid container direction="row">
                  <Grid item xs={3}>
                    <Typography variant="body1">Código</Typography>
                    <TypographyWithError
                      value={activity?.code || 'N/A'}
                      errors={activityValidation?.code} />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">Nome</Typography>
                    <TypographyWithError
                      value={activity?.name || 'N/A'}
                      errors={activityValidation?.name} />
                  </Grid>
                  <Grid item xs={3} container direction='column' alignItems='flex-end'>
                    <Typography variant="body1">Lim. de CH</Typography>
                    <TypographyWithError
                      value={activity?.limitLoadClockTime ? `${activity.limitLoadClockTime}h` : 'N/A'}
                      errors={activityValidation?.limitLoadClockTime} />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </>
        );
      });
    })
  } else {
    return (
      <>
        <TableRow>
          <TableCell component="th" scope="row" className={classes.e2sTableTopCell}>
            <TypographyWithError
              value={'N/A'} />
          </TableCell>
          <TableCell className={classes.e2sTableTopCell}>
            <TypographyWithError
              value={'N/A'} />
          </TableCell>
          <TableCell className={classes.e2sTableTopCell}>
            <TypographyWithError
              value={'N/A'} />
          </TableCell>
          <TableCell className={classes.e2sTableTopCell}>
            <TypographyWithError
              value={'N/A'} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={3} className={classes.e2sTableBottomCell} component="th" scope="row" />
          <TableCell>
            <Grid container direction="row">
              <Grid item xs={3}>
                <Typography variant="body1">Código</Typography>
                <TypographyWithError
                  value={'N/A'} />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">Nome</Typography>
                <TypographyWithError
                  value={'N/A'} />
              </Grid>
              <Grid item xs={3} container direction='column' alignItems='flex-end'>
                <Typography variant="body1">Lim. de CH</Typography>
                <TypographyWithError
                  value={'N/A'} />
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      </>
    );
  }
}

const ComplementaryActivityTable = ({ complementaryActivityCategories, validation }: CurriculumComplementaryActivitiesTableProps) => {
  return (
    <Table>
      <colgroup>
        <col style={{ width: '15%' }} />
        <col style={{ width: '15%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '50%' }} />
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHeaderLabel}>Código</TableCell>
          <TableCell className={classes.tableHeaderLabel}>Nome</TableCell>
          <TableCell className={classes.tableHeaderLabel}>Carga Horária</TableCell>
          <TableCell className={classes.tableHeaderLabel}>Tipo</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          TableRows({ complementaryActivityCategories, validation })
        }
      </TableBody>
    </Table>
  );
}

export default function CurriculumComplementaryActivityPaper({ complementaryActivityCategories, isLoading, validation }: CurriculumComplementaryActivitiesPaperProps) {
  return (
    <StyledPaper>
      <Grid container>
        <Grid item xs={12}>
          <CardTitle icon={<DashboardCustomizeOutlinedIcon className={classes.icon} />}>Atividades Complementares</CardTitle>
        </Grid>
        <Grid item xs={12}>
          <TableContainer className={classes.table}>
            {isLoading || !complementaryActivityCategories ?
              <TableLoading columns={4} rows={2} /> :
              <ComplementaryActivityTable complementaryActivityCategories={complementaryActivityCategories} validation={validation} />}
          </TableContainer>
        </Grid>
      </Grid>
    </StyledPaper>
  );
}
