import { Divider, Typography, TypographyProps, styled } from "@mui/material";
import { AlertTriangle } from "react-feather";
import PaperTooltip from "../../atoms/paper-tooltip";
import { FONT_FAMILY_BOLD } from "../../../../theme";

const PREFIX = 'TypographyWithError';

const classes = {
  tooltipContainer: `${PREFIX}-tooltipContainer`,
  errorIcon: `${PREFIX}-errorIcon`,
  tooltipValidationError: `${PREFIX}-tooltipValidationError`,
  tooltipContainerBottom: `${PREFIX}-tooltipContainerBottom`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.errorIcon}`]: {
    color: theme.palette.error.main
  },

  [`& .${classes.tooltipValidationError}`]: {
    color: theme.palette.error.main,
    fontFamily: FONT_FAMILY_BOLD,
    fontSize: 16,
    marginLeft: 8,
    marginRight: 24,
    display: 'inline-block'
  },

  [`&.${classes.tooltipContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 16
  },

  [`&.${classes.tooltipContainerBottom}`]: {
    marginBottom: 8,
  },
}));

type TypographyWithErrorProps = TypographyProps & {
  value: string,
  errors?: string | string[]
}

const renderErrors = (errors: string | string[]) => {
  if (Array.isArray(errors)) {
    return errors.map((error, index) => (
      <Typography key={index}>
        {error}
      </Typography>
    ));
  }

  return (
    <Typography>
      {errors}
    </Typography>
  );
}

const TypographyWithError = ({ value, errors, className, style }: TypographyWithErrorProps) => {
  if (!!errors && errors.length > 0) {
    return (
      <PaperTooltip
        placement="bottom-start"
        title={
          <>
            <Root className={classes.tooltipContainer}>
              <AlertTriangle size={15} className={classes.errorIcon} />
              <Typography
                className={classes.tooltipValidationError}
                variant="body1"
              >
                {Array.isArray(errors) && errors.length > 1 ? `${errors.length} inconsistências encontradas` : 'Inconsistência encontrada'}
              </Typography>
            </Root>
            <Divider light style={{ marginBottom: 16 }} />
            <Root
              className={`${classes.tooltipContainer} ${classes.tooltipContainerBottom}`}
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start'
              }} >
              {renderErrors(errors)}
            </Root>
          </>
        }
      >
        <Typography variant="body2" className={className} color='error'>
          {value}
        </Typography>
      </PaperTooltip >
    );
  }

  return (
    <Typography className={className} style={style} variant="body2">
      {value}
    </Typography>
  );
}

export default TypographyWithError;
