import { Button, Grid } from '@mui/material';
import { StyledEngineProvider, Theme, ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { Form, Formik } from 'formik';
import React from 'react';
import { LogIn } from 'react-feather';
import { ConnectedProps, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  A2S_COLOR, FONT_FAMILY_EXTRABOLD,
  OPAQUE_A2S_COLOR,
  WHITE_COLOR,
  theme
} from '../../../../../theme';
import A2SLogo from '../../../../img/a2s_logo.svg';
import { setLoginLoading } from '../store';

const PREFIX = 'Login';

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  loginContainer: `${PREFIX}-loginContainer`,
  loginBox: `${PREFIX}-loginBox`,
  loginImage: `${PREFIX}-loginImage `,
  loginGroup: `${PREFIX}-loginGroup `,
  userInput: `${PREFIX}-userInput `,
  passInput: `${PREFIX}-passInput `,
  MuiInputLabelShrink: `${PREFIX}-MuiInputLabelShrink `,
  MuiInputLabel: `${PREFIX}-MuiInputLabel `,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.root}`]: {
    padding: '30px',
  },

  [`& .${classes.button}`]: {
    width: '20rem',
    height: '2.75rem',
    fontFamily: FONT_FAMILY_EXTRABOLD,
    '&:hover': {
      opacity: 0.9,
    },
  },
  [`& .${classes.loginContainer}`]: {
    position: 'fixed',
    top: '28%',
    left: '42%',
    marginTop: '-50px',
    marginLeft: '-100px',
    background: '#ffffff',
    width: '26rem',
    height: '28.5rem',
    MozBoxShadow: '4px 4px 25px rgba(158, 171, 181, 0.15)',
    WebkitBoxShadow: '4px 4px 25px rgba(158, 171, 181, 0.15)',
    boxShadow: '4px 4px 25px rgba(158, 171, 181, 0.15)',
    borderRadius: '8px'
  },
  [`& .${classes.loginBox}`]: {
    width: '26rem',
    height: '28.625rem',
    display: 'grid',
    gridTemplateRows: 'auto auto auto',
    justifyItems: 'center',
  },
  [`& .${classes.loginImage}`]: {
    width: '10.938rem',
    height: '6rem',
    marginTop: '3rem',
  },
  [`& .${classes.loginGroup}`]: {
    margin: '2px',
    width: '80%',
  },
  [`& .${classes.userInput}`]: {
    marginBottom: '3rem',
    width: '20rem',
    height: '3rem',
  },
  [`& .${classes.passInput}`]: {
    marginBottom: '3rem',
    width: '20rem',
    height: '3rem',
  },
  [`& .${classes.MuiInputLabelShrink}`]: {
    transform: 'translate(2px, -16px) scale(0.85) !important'
  },
  [`& .${classes.MuiInputLabel}`]: {
    transform: ' translate(8px, 10px) scale(1) !important'
  },
}));

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

const mapDispatch = {
  toggleLoading: () => setLoginLoading(),
};

const connector = connect(null, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const Login = (props: Props) => {

  const { toggleLoading } = props;

  const newTheme = JSON.parse(JSON.stringify(theme));
  newTheme.palette.primary.main = A2S_COLOR;
  newTheme.overrides.MuiInputBase.root['&.Mui-focused'].backgroundColor = OPAQUE_A2S_COLOR;

  const loginTheme = createTheme(newTheme);

  const history = useHistory();
  function userAuthentication() {
    history.push('/');
    history.go(0);
  }
  function handleSubmit(event: any) {
    toggleLoading();
    event.preventDefault();
    userAuthentication();
  }

  return (
    <StyledGrid >
      <Grid className={classes.loginContainer}>
        <Grid className={classes.loginBox}>
          <img src={A2SLogo} className={classes.loginImage} alt="Logo" />
          <Formik
            initialValues={{}}
            onSubmit={() => { }}
          >
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={loginTheme}>
                <Form className={classes.loginGroup} onSubmit={handleSubmit}>
                  <Button
                    data-testid="login-submit"
                    className={classes.button}
                    variant="contained"
                    startIcon={<LogIn size={16.67} />}
                    type="submit"
                    fullWidth
                    disableElevation
                    style={{
                      backgroundColor: A2S_COLOR, color: WHITE_COLOR, fontSize: '14px', fontWeight: 'bold',
                    } as React.CSSProperties}
                  >
                    Entrar
                  </Button>
                </Form>
              </ThemeProvider>
            </StyledEngineProvider>
          </Formik>
        </Grid>
      </Grid>
    </StyledGrid>
  );
};

export default connector(Login);
