import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import store from '../setup/reducer';

const header = { Pragma: 'no-cache' };

const getAxiosInstance = (url: string): AxiosInstance => {
  const axiosInstance = axios.create({
    headers: header,
    baseURL: url
  });

  axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const user = store.getState().login.user;
    if (user?.token) {
      config.headers.Authorization = `Bearer ${user.token}`;
    }
    return config;
  });
  return axiosInstance;
};

export default getAxiosInstance;
