import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Icon, Typography, Skeleton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useSelector } from 'react-redux';
import { DARK_GRAY_COLOR, FONT_FAMILY_REGULAR } from '../../../../theme';
import DropdownIesCampus from '../dropdown-ies-campus/index';
import { RootState } from '../../../../setup/reducer/reducers';

const PREFIX = 'SelectIesCampus';

const classes = {
  container: `${PREFIX}-container`,
  containerIcon: `${PREFIX}-containerIcon`,
  containerDescription: `${PREFIX}-containerDescription`,
  iconUp: `${PREFIX}-iconUp`,
  iconDown: `${PREFIX}-iconDown`,
  skeleton: `${PREFIX}-skeleton`,
  subContainer: `${PREFIX}-subContainer`
};

const Root = styled('div')({
  [`& .${classes.container}`]: {
    cursor: 'pointer',
    float: 'right',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    color: DARK_GRAY_COLOR
  },
  [`& .${classes.containerIcon}`]: {
    float: 'right'
  },
  [`& .${classes.containerDescription}`]: {
    marginLeft: '1px',
    float: 'right',
    textAlignLast: 'left',
    marginRight: '8px',
    '& .MuiTypography-body1': {
      fontFamily: FONT_FAMILY_REGULAR
    }
  },
  [`& .${classes.iconUp}`]: {
    height: '18px',
    width: '18px'
  },
  [`& .${classes.iconDown}`]: {
    height: '18px',
    width: '18px'
  },
  [`& .${classes.skeleton}`]: {
    height: '40px',
    display: 'inline-block',
    marginBottom: '3.5px'
  },
  [`& .${classes.subContainer}`]: {
    flexWrap: 'nowrap'
  }
});

const SelectIesCampus = () => {
  const selectedInstitution = useSelector(
    (state: RootState) => state.header.institution
  );

  const showInstitutionName = useSelector((state: RootState) => state.institutionManagementFilter.showNameInstitutionDropdow)
  const [showDropdown, setDropdown] = useState(false);

  function getIesCampusLabel() {
    return (
      <>
        {
          (showInstitutionName) ? (
            <Root>
              {selectedInstitution && (
                <Typography variant="h6">{selectedInstitution?.name}</Typography>
              )}
            </Root >
          ) : (
            <Root>
              <Typography variant="h6">Todas</Typography>
            </Root>
          )
        }
      </>

    );
  }
  return (
    <>
      {!selectedInstitution ? (
        <Root>
          <Skeleton className={classes.skeleton} animation="wave" />{' '}
        </Root>
      ) : (
        <Root>
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            className={classes.container}
            onClick={() => showInstitutionName && setDropdown(!showDropdown)}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.subContainer}
            >
              <Grid item className={classes.containerDescription}>
                {getIesCampusLabel()}
              </Grid>
              <Grid item className={classes.containerIcon}>
                {showDropdown ? (
                  <Icon>
                    <KeyboardArrowUpIcon className={classes.iconUp} />
                  </Icon>
                ) : (
                  <Icon>
                    <KeyboardArrowDownIcon className={classes.iconDown} />
                  </Icon>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Root>
      )}
      <DropdownIesCampus
        showDropdown={showDropdown}
        setShowDropdown={setDropdown}
      />
    </>
  );
};

export default SelectIesCampus;
