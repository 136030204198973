import State, { stringify as stringifyState } from '../../enum/State';

export default class CityDto {
  code?: string;

  name: string;

  state?: State;

  static stringify(city: CityDto) {
    if (city.state) {
      return `${city.name}, ${stringifyState(city.state)}`;
    }

    return city.name;
  }

  static stringifyForAddress(city: CityDto | undefined): string | undefined {
    if (!city) {
      return undefined;
    }

    let cityDescription = city.name || '';

    if (city.state) {
      cityDescription += `/${city.state}`;
    }

    return cityDescription;
  }
}
