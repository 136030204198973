import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Snackbar, Typography, Grid } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import {
  FONT_FAMILY_EXTRABOLD,
  TOMATO_COLOR,
  LIGHT_TOMATO_COLOR,
} from '../../../../../theme';

const PREFIX = 'ErrorNotification';

const classes = {
  snackbarTextBold: `${PREFIX}-snackbarTextBold`,
  erroSnackBar: `${PREFIX}-erroSnackBar`,
  erroSnackBarText: `${PREFIX}-erroSnackBarText`
};

const StyledSnackbar = styled(Snackbar)(() => ({
  [`& .${classes.snackbarTextBold}`]: {
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: FONT_FAMILY_EXTRABOLD,
    cursor: 'pointer',
  },

  [`& .${classes.erroSnackBar}`]: {
    backgroundColor: LIGHT_TOMATO_COLOR,
    color: `${TOMATO_COLOR} !important`,
  },

  [`& .${classes.erroSnackBarText}`]: {
    color: TOMATO_COLOR,
  }
}));

type Props = {
  errorMessage: string | null,
  hideErrorMessage: () => void,
  snackBarClass: string,
  snackBarTextclass: string,
  errorIcon: JSX.Element,
};

const ErrorNotification = (props: Props) => {
  const {
    errorMessage, hideErrorMessage, snackBarClass, snackBarTextclass, errorIcon,
  } = props;



  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        hideErrorMessage();
      }, 5000);
    }
  }, [errorMessage, hideErrorMessage]);

  const openMessage: boolean = !!errorMessage && errorMessage.trim().length > 0;

  return (
    <StyledSnackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={openMessage}
      onClose={hideErrorMessage}
      ClickAwayListenerProps={{
        mouseEvent: false,
      }}
    >
      <MuiAlert
        elevation={0}
        icon={errorIcon}
        severity="error"
        className={`${snackBarClass} ${classes.erroSnackBar}`}
      >
        <Grid container direction="row" spacing={2} alignItems="center" wrap="nowrap">
          <Grid item>
            <Typography className={`${snackBarTextclass} ${classes.erroSnackBarText}`}>
              {errorMessage}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={`${classes.snackbarTextBold} ${classes.erroSnackBarText}`} onClick={hideErrorMessage}>
              FECHAR
            </Typography>
          </Grid>
        </Grid>
      </MuiAlert>
    </StyledSnackbar>
  );
};

export default ErrorNotification;
