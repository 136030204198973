import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { SUCCESS_UPLOAD_COLOR } from '../../../../../theme';
import { pageRoutes } from '../../../../utils/constants';
import BackCardHeader from '../../../molecules/back-card-header';
import DefaultTemplate from '../../../templates/default-template';
import EditModelCertificateList from './EditModelCertificateList';

const PREFIX = 'EditModelCertificatePage';

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  buttonUpload: `${PREFIX}-buttonUpload`,
  gridDropzone: `${PREFIX}-gridDropzone`,
  gridDropzoneDisabled: `${PREFIX}-gridDropzoneDisabled`,
  dropZoneBox: `${PREFIX}-dropZoneBox`,
  dropZoneBoxP: `${PREFIX}-dropZoneBoxP`,
  dropZoneBoxAdd: `${PREFIX}-dropZoneBoxAdd`,
  gridIcon: `${PREFIX}-gridIcon`,
  gridIconFile: `${PREFIX}-gridIconFile`,
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    paddingBottom: theme.spacing(7)
  },
  [`& .${classes.buttonUpload}`]: {
    marginTop: '0.5rem',
    color: '#107887',

  },
  [`& .${classes.gridDropzone}`]: {
    justifyContent: 'center',
    paddingBottom: '3%',
    marginTop: '3%'
  },
  [`& .${classes.gridDropzoneDisabled}`]: {
    justifyContent: 'center',
    paddingBottom: '3%',
    pointerEvents: 'none',
    marginTop: '3%'
  },
  [`& .${classes.dropZoneBox}`]: {
    background: '#F0F0F0',
    borderColor: '#c8c8c8',
    borderSize: '10px',
    borderRadius: '10px',
    borderStyle: 'dashed',
    borderWidth: '2px',
    height: '180px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  [`& .${classes.dropZoneBoxP}`]: {
    textAlign: 'center',
  },
  [`& .${classes.dropZoneBoxAdd}`]: {
    textAlign: 'center',
    color: SUCCESS_UPLOAD_COLOR
  },
  [`& .${classes.gridIcon}`]: {
    color: '#737677',
  },
  [`& .${classes.gridIconFile}`]: {
    color: SUCCESS_UPLOAD_COLOR
  }
}));

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main,
  }
}));

const EditModelCertificatePage = () => {

  const history = useHistory();

  const navigateBackToMicrocertificatesOnClick = () => {
    history.push(pageRoutes.Certificate)
  }

  return (
    <Root>
      <DefaultTemplate message="Edição de" highlightedMessage="Modelos">
        <StyledPaper>
          <BackCardHeader onClick={() => navigateBackToMicrocertificatesOnClick()} />
          <EditModelCertificateList />
        </StyledPaper>
      </DefaultTemplate>

    </Root>

  );
}
export default EditModelCertificatePage;
