import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Autocomplete, Button, Checkbox, FormHelperText, Grid, Icon, ListItem, Paper, TextField, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import { Info, Search } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import CompletedActivity from '../../../../../domain/enum/certificate/CompletedActivity';
import PositionComplementaryLogo from '../../../../../domain/enum/certificate/PositionComplementaryLogo';
import CreatePresignedPostRequest from '../../../../../domain/interface/request/CreatePresignedPostRequest';
import CertificateSettingsRequest from '../../../../../domain/interface/request/CertificateSettingsRequest';
import CreatePresignedPostResponse from '../../../../../domain/interface/response/CreatePresignedPostResponse';
import DocumentService from '../../../../../services/DocumentService';
import StorageService from '../../../../../services/StorageService';
import { RootState } from '../../../../../setup/reducer/reducers';
import { DIPLOMA_COLOR, TOMATO_COLOR } from '../../../../../theme';
import { notificationIcons, pageRoutes } from '../../../../utils/constants';
import { resizeImage } from '../../../../utils/functions';
import LoadingIcon from '../../../atoms/loading-icon';
import BackdropLoading from '../../../molecules/backdrop-loading';
import SelectAutocomplete from '../../../molecules/select-autocomplete';
import DefaultTemplate from '../../../templates/default-template';
import { showNotification } from '../../notification/actions';
import { setDeleteLogoFromBucket, setInsertCertificateSettings, setUpdateCertificateSettings, setCertificateTypes } from '../store/actions';
import ConfirmationModal from '../../../organisms/confirmation-modal';

const PREFIX = 'CertificateSettingsModelPage';

const classes = {
  root: `${PREFIX}-root`,
  gridContainer: `${PREFIX}-gridContainer`,
  subTitle: `${PREFIX}-subTitle`,
  gridSelecteds: `${PREFIX}-gridSelecteds`,
  gridBrands: `${PREFIX}-gridBrands`,
  labelAutoComplete: `${PREFIX}-labelAutoComplete`,
  icon: `${PREFIX}-icon`,
  buttonUpload: `${PREFIX}-buttonUpload`,
  textInfoUpload: `${PREFIX}-textInfoUpload`,
  iconDelete: `${PREFIX}-iconDelete`,
  buttonDeleteFile: `${PREFIX}-buttonDeleteFile`,
  buttonCancel: `${PREFIX}-buttonCancel`,
  buttonSave: `${PREFIX}-buttonSave`,
  textError: `${PREFIX}-textError`,
  previewModel: `${PREFIX}-previewModel`,

};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    padding: '2rem',
  }
}));

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.gridContainer}`]: {

  },
  [`& .${classes.subTitle}`]: {
    fontSize: '1.5rem',
    marginBottom: '2rem'
  },
  [`& .${classes.gridSelecteds}`]: {
    display: 'flex',
    gap: '1rem',
    marginBottom: '1.5rem'
  },
  [`& .${classes.gridBrands}`]: {
    display: 'flex',
    flexDirection: 'column'
  },
  [`& .${classes.labelAutoComplete}`]: {
    marginBottom: '0.2rem',
    marginLeft: '2px',
    display: 'flex',
    alignItems: 'start',
    marginTop: '6px',
  },
  [`& .${classes.icon}`]: {
    height: '0.9rem',
    cursor: 'pointer'
  },
  [`& .${classes.buttonUpload}`]: {
    height: '2.8rem',
    backgroundColor: '#f0f0f0',
    color: '#818181',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0 0.5px 0 0 #000',
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    borderTopLeftRadius: '2px',
    borderTopRightRadius: '2px',
    '&:hover': {
      backgroundColor: '#f0f0f0',
      color: '#818181',
    },
  },
  [`& .${classes.textInfoUpload}`]: {
    marginLeft: '4px',
    marginTop: '4px',
  },
  [`& .${classes.iconDelete}`]: {
    height: '1.2rem',
  },
  [`& .${classes.buttonDeleteFile}`]: {
    padding: '8px',
    minWidth: '0',
    fontWeight: 'bold',
    color: '#066BFF'
  },
  [`& .${classes.buttonCancel}`]: {
    fontWeight: 'bold',
    width: '100px'
  },
  [`& .${classes.buttonSave}`]: {
    marginRight: '2rem',
    width: '100px'
  },
  [`& .${classes.textError}`]: {
    color: TOMATO_COLOR
  },
  [`& .${classes.previewModel}`]: {
    height: '200px',
    width: '100%',
    border: '2px solid #adadad',
    color: '#adadad',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const CertificateSettingsModelPage = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const history = useHistory();
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const certificateTypesAvailable = useSelector((rootState: RootState) => rootState.editCertificateSettingsModel.certificateTypes);
  const brands = useSelector((rootState: RootState) => rootState.editCertificateSettingsModel.brands);
  const certificateSetting = useSelector((rootState: RootState) => rootState.editCertificateSettingsModel.certificateSetting);
  const loadingCertificateType = useSelector((rootState: RootState) => rootState.editCertificateSettingsModel.loadingCertificateType);
  const loadingBrands = useSelector((rootState: RootState) => rootState.editCertificateSettingsModel.loadingBrands);

  const [subTitle, setSubTitle] = useState('Novo Modelo')
  const [selectType, setSelectType] = useState<string | undefined>(undefined);
  const [disableCertificateTypeSelect, setdisableCertificateTypeSelect] = useState(false);
  const [selectActivity, setSelectActivity] = useState<string | undefined>(undefined);
  const [selectPositionLogo, setSelectPositionLogo] = useState<string | undefined>(PositionComplementaryLogo.PositionRight);
  const [selectBrands, setSelectBrands] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSelectedFileDeleted, setIsSelectedFileDeleted] = useState(false);
  const [openConfirmationModal, setConfirmationModal] =
    useState<boolean>(false);
  const [titleConfirmationModal, setTitleConfirmationModal] =
    useState<string>('');

  const positonsLogoAvailable = Object.values(PositionComplementaryLogo);
  const activitysCompleted = Object.values(CompletedActivity);
  const sortedActivitysCompleted = [...activitysCompleted].sort();

  const sortedBrands = [...brands].sort();
  const allOptionsBrands = ['Todas', ...sortedBrands];

  const titlePage = currentPath === pageRoutes.CreateCertificateSettings ? 'Criação de' : 'Edição de';
  const validationInputsMandatory = selectType !== undefined && selectActivity !== undefined && selectBrands.length > 0;


  useEffect(() => {

    if (currentPath !== pageRoutes.CreateCertificateSettings && certificateSetting) {
      setSubTitle(certificateSetting.pk);
      setSelectType(certificateSetting.pk);
      setdisableCertificateTypeSelect(true);
      if (!selectActivity) {
        setSelectActivity(certificateSetting.completedActivity);
      }
      if (!isEditing)
        setSelectBrands(certificateSetting.brands);
      const positionLogo = PositionComplementaryLogo.PositionRight
      setSelectPositionLogo(positionLogo);
      setIsEditing(true);
      if (!isSelectedFileDeleted && certificateSetting && certificateSetting.additionalLogotipoFile) {
        setSelectedFile({ name: `${certificateSetting.additionalLogotipoFile.split('-').pop()}` });
      }
    }
  }, [currentPath, certificateSetting, selectPositionLogo, selectBrands, isSelectedFileDeleted, selectActivity, isEditing])

  const handleSelectedBrands = (_: any, value: any) => {
    if (value.includes('Todas')) {
      if (selectBrands.length === brands.length) {
        setSelectBrands([]);
      } else {
        setSelectBrands(brands);
      }
    } else {
      setSelectBrands(value);
    }
  };

  const handleFileChange = async (event: any) => {
    if (selectedFile) {
      setIsSelectedFileDeleted(true);
    }
    const file = event.target.files[0];

    const allowedExtensions = ['png', 'jpg', 'jpeg', 'gif'];
    const extension = file?.name.split('.').pop()?.toLowerCase();

    if (!allowedExtensions.includes(extension)) {
      dispatch(
        showNotification(
          'Arquivo no formato inválido. Apenas JPG, JPEG, PNG e GIF são aceitos.',
          notificationIcons.error
        )
      );
      return
    }
    const resizedFile = await resizeImage(file, 280, 88);
    setSelectedFile(resizedFile);
  };

  const handleClearFile = () => {
    setTitleConfirmationModal('Confirma a exclusão deste documento?');
    setConfirmationModal(true);
  };

  const successDialog = async (): Promise<void> => {
    setSelectedFile(undefined);
    setIsSelectedFileDeleted(true);
    setConfirmationModal(false);
  }

  const closeDialog = (): void => {
    setConfirmationModal(false);
  };

  const handleCancel = async () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setSelectType(undefined);
    setSelectActivity(undefined);
    setSelectBrands([]);
    setSelectPositionLogo(undefined);
    setSelectedFile(undefined);
    history.push(pageRoutes.SettingsCertificate);
  }

  const handleSubmit = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    try {
      setIsLoading(true)

      const request = {
        certificateType: selectType,
        brands: selectBrands,
        completedActivity: selectActivity,
        additionalLogotipoPosition: 'Direita'
      } as CertificateSettingsRequest


      if (isEditing) {
        if (selectedFile && certificateSetting?.additionalLogotipoFile !== selectedFile.name) {
          const fileName = await handleUploadFile(selectedFile);
          request.additionalLogotipoFile = fileName;
        }
        if (certificateSetting && isSelectedFileDeleted) {
          await dispatch(setDeleteLogoFromBucket(certificateSetting.additionalLogotipoFile));
          request.additionalLogotipoPosition = 'Direita';
        }

        await dispatch(setUpdateCertificateSettings(request));
      } else {
        if (selectedFile) {
          const fileName = await handleUploadFile(selectedFile);
          request.additionalLogotipoFile = fileName;
        }

        await dispatch(setInsertCertificateSettings(request));
      }
    } finally {
      setIsLoading(false);
      setSelectType(undefined);
      setSelectActivity(undefined);
      setSelectBrands([]);
      setSelectPositionLogo(undefined);
      setSelectedFile(undefined);
      if (!isLoading) {
        history.push(pageRoutes.SettingsCertificate)
        await dispatch(setCertificateTypes());
      };
    }
  }

  async function handleUploadFile(selectedFile: File): Promise<string> {
    const fileId = uuidv4();
    const fileName = `${fileId}.${selectedFile.name.split('.').pop()}`;
    const preSignedUrl: CreatePresignedPostResponse = (await DocumentService.createPresignedPost({ key: `${fileName}`, acessKey: 'certificate-settings' } as CreatePresignedPostRequest)).data;

    const formData = new FormData();
    Object.entries(preSignedUrl.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('file', selectedFile);

    await StorageService.uploadFileToBucket(preSignedUrl.url, fileName, formData);

    return fileName;
  }

  return (
    <Root>
      <DefaultTemplate message={titlePage} highlightedMessage="Modelos" >
        <StyledPaper className={classes.root}>
          <Typography className={classes.subTitle} variant="h3">{subTitle}</Typography>
          <Grid container item xs={12} gap={2}>
            <Grid container item direction='column' style={{ width: '70%' }}>
              <Grid className={classes.gridSelecteds} >
                <Grid item style={{ width: '50%' }}>
                  <SelectAutocomplete
                    compareName
                    defaultValue={selectType}
                    loading={loadingCertificateType || disableCertificateTypeSelect}
                    loadingActiveIcon={loadingCertificateType}
                    label="Tipo de Microcertificado"
                    placeholder="Selecionar"
                    listItems={certificateTypesAvailable.slice().sort()}
                    setItem={setSelectType}
                    data-testid="select-typeCertificate"
                  />
                </Grid>
                <Grid item style={{ width: '50%' }}>
                  <SelectAutocomplete
                    compareName
                    defaultValue={selectActivity}
                    label="Atividade concluída"
                    placeholder="Selecionar"
                    listItems={sortedActivitysCompleted}
                    setItem={setSelectActivity}
                    data-testid="select-activity"
                  />
                </Grid>
              </Grid>
              <Grid className={classes.gridSelecteds}>
                <Grid item className={classes.gridBrands} style={{ width: '37%' }}>
                  <FormHelperText className={classes.labelAutoComplete}>
                    Marcas
                  </FormHelperText>
                  <Autocomplete
                    disabled={loadingBrands}
                    multiple
                    id="multiple-checkboxes"
                    options={allOptionsBrands}
                    disableCloseOnSelect
                    getOptionLabel={(brand) => brand}
                    onChange={handleSelectedBrands}
                    value={selectBrands}
                    renderOption={(props, brand, { selected }) => (
                      <ListItem {...props}>
                        <Checkbox checked={brand === 'Todas'
                          ? selectBrands.length === brands.length
                          : selected} value={brand} />
                        {brand}
                      </ListItem>
                    )}
                    renderTags={() => `${selectBrands.length} marca(s) selecionada(s)`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={selectBrands.length > 0 ? "" : "Selecionar"}
                        size='small'
                        variant="filled"
                      />
                    )}
                    popupIcon={(
                      <Icon>
                        {loadingBrands ? <LoadingIcon /> : <KeyboardArrowDownIcon className={undefined} />}
                      </Icon>
                    )}
                  />
                </Grid>
                <Grid item style={{ width: '35%' }}>
                  <FormHelperText className={classes.labelAutoComplete}>
                    Logotipo Complementar
                    <Tooltip title="Logotipo, brasão ou símbolo da instituição que ministrou o curso em parceria com a IES.">
                      <Info
                        className={classes.icon}
                        color={DIPLOMA_COLOR}
                      />
                    </Tooltip>
                  </FormHelperText>
                  <Button component="label" variant="contained" endIcon={<FileUploadOutlinedIcon />} className={classes.buttonUpload}>
                    {selectedFile ? selectedFile.name : 'Inserir arquivo'}
                    <VisuallyHiddenInput type="file" accept=".png, .jpg, .jpeg, .gif" onChange={handleFileChange}
                      ref={fileInputRef} />
                  </Button >
                  {
                    selectedFile ? (
                      <FormHelperText className={classes.textInfoUpload} >
                        <Button onClick={handleClearFile} className={classes.buttonDeleteFile}>
                          {'excluir arquivo'}
                        </Button>
                      </FormHelperText>
                    ) : (<FormHelperText className={classes.textInfoUpload}>arquivos aceitos: jpg, png ou gif</FormHelperText>)}
                </Grid >
                <Grid item style={{ width: '28%' }}>
                  <FormHelperText className={classes.labelAutoComplete}>
                    Posição do logotipo complementar
                  </FormHelperText>
                  <Autocomplete
                    value={selectPositionLogo}
                    disabled={selectedFile === undefined}
                    onChange={(_, position: any) => {
                      if (certificateSetting) {
                        certificateSetting.additionalLogotipoPosition = 'Direita';
                      }
                      setSelectPositionLogo(position)
                    }}
                    options={positonsLogoAvailable}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        size='small'
                        variant="filled"
                      />
                    )}
                  />

                </Grid>
              </Grid >
            </Grid >
            <Grid item style={{ width: '28%' }} container justifyContent="flex-end">
              <div className={classes.previewModel}>
                <BrokenImageOutlinedIcon style={{ height: '3rem', width: '3rem' }} />
              </div>
              <Search color="#adadad" style={{ marginTop: '0.3rem' }} />
            </Grid>
          </Grid >
          <Grid container item xs={12} alignItems='center' justifyContent='flex-end' gap={2} style={{ marginTop: '2rem' }}>
            <Button size="large" onClick={handleCancel} className={classes.buttonCancel} >Cancelar</Button>
            <Button variant="contained" size="large" onClick={handleSubmit} className={classes.buttonSave} disabled={!validationInputsMandatory}>  {isEditing ? 'Atualizar' : 'Salvar'}</Button>
          </Grid>
        </StyledPaper >
      </DefaultTemplate >
      <ConfirmationModal
        openModal={openConfirmationModal}
        modalTitle={titleConfirmationModal}
        textContinueButton="Sim"
        textCancelButton="Cancelar"
        loading={isLoading}
        children=" "
        handleContinueButton={() => {
          successDialog();
        }}
        handleCancelButton={() => {
          closeDialog();
        }}
      />
      {
        isLoading && (
          <BackdropLoading
            size={50}
            description={'Salvando Configurações...'}
            open={isLoading}
            classes={classes}
          />
        )
      }
    </Root >
  );
}

export default CertificateSettingsModelPage;
