import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BusinessIcon from '@mui/icons-material/Business';
import { Button, Checkbox, Grid, Paper, Skeleton, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EnableAutomaticDiplomaIssuanceRequest from '../../../../domain/interface/request/EnableAutomaticDiplomaIssuanceRequest';
import Address from '../../../../domain/model/Address';
import { RootState } from '../../../../setup/reducer/reducers';
import { FONT_FAMILY_EXTRABOLD } from '../../../../theme';
import { pageRoutes } from '../../../utils/constants';
import { CnpjMask } from '../../../utils/stringMasks';
import BackdropLoading from '../../molecules/backdrop-loading';
import CardTitle from '../../molecules/card-title';
import LabeledText from '../../molecules/labeled-text';
import { enableAutomaticDiplomaIssuance } from './store/actions';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.primary.main
    },
    typography: {
      fontFamily: FONT_FAMILY_EXTRABOLD,
      color: theme.palette.primary.main
    },
    enableIssuerContainer: {
      display: 'flex',
      marginRight: '2rem'
    },
    button: {
      height: '2.5rem',
      marginRight: '2rem',
      marginBottom: '2rem',

    },
  })
);

const CheckboxLoading = () => (
  <Skeleton style={{ height: 20, width: '200px' }} animation="wave" />
);

export function InstitutionManagementInfo(): JSX.Element {
  const classesStyle = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const institution = useSelector((state: RootState) => state.institutionInfoPage.institution);
  const isLoading = useSelector((state: RootState) => state.institutionInfoPage.loadingSaveSettings);
  const loadingInstitution = useSelector((state: RootState) => state.institutionInfoPage.loadingInstitution);
  const [isEnable, setIsEnable] = useState<boolean>(false);

  const enableAutomaticDiplomaToggle = process.env.REACT_APP_ENABLE_AUTOMATIC_DIPLOMA_ISSUANCE === 'true';

  useEffect(() => {
    institution?.settings?.enableAutomaticDiplomaIssuance === true && setIsEnable(true)
  }, [institution])

  function handleChange(event: any) {
    const isChecked = event.target.value;
    setIsEnable(isChecked === 'true' ? true : false);
  }

  async function handleSubmit() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const request: EnableAutomaticDiplomaIssuanceRequest = {
      enableAutomaticDiplomaIssuance: isEnable,
      mecCode: institution?.mecCode
    }

    await dispatch(enableAutomaticDiplomaIssuance(request))

    if (!isLoading) {
      history.push(pageRoutes.InstitutionManagement)
    }
  }

  return (
    <Paper>
      <Grid container item xs={12}>
        <Grid item xs={12} container justifyContent='space-between'>
          <CardTitle
            icon={<BusinessIcon className={classesStyle.icon} />}
          >{`IES Emissora`}</CardTitle>
          {enableAutomaticDiplomaToggle && (<Grid className={classesStyle.enableIssuerContainer} justifyContent='flex-end' alignItems='center'>
            {loadingInstitution ? <CheckboxLoading /> : (
              <><Typography variant="body1">Habilitar emissão automática </Typography>
                <Checkbox onChange={handleChange} value={!isEnable} checked={isEnable} /></>

            )}
          </Grid>)}
        </Grid>

        <Grid item xs={4}>
          <LabeledText
            label="Nome"
            isLoading={loadingInstitution}
          >
            {institution?.name?.toString() || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={4}>
          <LabeledText
            label="Marca"
            isLoading={loadingInstitution}
          >
            {institution?.brand?.toString() || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={4}>
          <LabeledText
            label="Código MEC"
            isLoading={loadingInstitution}
          >
            {institution?.mecCode?.toString() || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={12}>
          <LabeledText
            label="Endereço"
            isLoading={loadingInstitution}
          >
            {Address.stringify(institution?.address) || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={4}>
          <LabeledText
            label="CNPJ"
            isLoading={loadingInstitution}
          >
            {CnpjMask.apply(institution?.cnpj) || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={4}>
          <LabeledText
            label="URL de validação base"
            isLoading={loadingInstitution}
          >
            {institution?.validationUrl?.toString() || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={12}>
          <CardTitle
            variant="body1"
            classes={classesStyle}
            icon={<AccountBalanceIcon className={classesStyle.icon} />}
          >
            Mantenedora
          </CardTitle>
        </Grid>

        <Grid item xs={6}>
          <LabeledText
            label="CNPJ"
            isLoading={loadingInstitution}
          >
            {CnpjMask.apply(institution?.sponsor?.cnpj) || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={6}>
          <LabeledText
            label="Razão Social"
            isLoading={loadingInstitution}
          >
            {institution?.sponsor?.corporateName?.toString() || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid container
          justifyContent="space-between"
          alignItems="center">
          <LabeledText
            label="Endereço"
            isLoading={loadingInstitution}
          >
            {Address.stringify(institution?.sponsor?.address) || 'N/A'}
          </LabeledText>
          {enableAutomaticDiplomaToggle && (<Button variant="contained" size="large" onClick={handleSubmit} className={classesStyle.button} >Salvar Configurações</Button>)}
        </Grid>
      </Grid>
      {isLoading && (
        <BackdropLoading
          size={50}
          description={'Salvando configurações'}
          open={isLoading}
          classes={classesStyle}
        />
      )}
    </Paper>

  );
};

