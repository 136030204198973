import UserDto from '../../../../domain/interface/dto/UserDto';
import {
  LoginState,
  INSERT_USER,
  LoginActionsTypes,
  SET_LOGIN_LOADING,
  INSERT_USER_MANAGER
} from './types';

export const INITIAL_STATE: LoginState = {
  user: undefined,
  loading: false,
  userManager: undefined
};

export default function reducer(
  state: LoginState = INITIAL_STATE,
  action: LoginActionsTypes
): LoginState {
  switch (action.type) {
    case INSERT_USER:
      return {
        ...state,
        user: action.payload
      };
    case INSERT_USER_MANAGER:
      return {
        ...state,
        userManager: action.payload
      };
    case SET_LOGIN_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    default:
      return state;
  }
}

export const updateUser = (user?: UserDto): LoginActionsTypes => ({
  type: INSERT_USER,
  payload: user
});

export const updateUserManager = (userManager?: any): LoginActionsTypes => ({
  type: INSERT_USER_MANAGER,
  payload: userManager
});

export const setLoginLoading = (): LoginActionsTypes => ({
  type: SET_LOGIN_LOADING
});
