import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { DARK_GRAY_COLOR } from '../../../../theme';

const useAdvancedFilterModalStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '0 0.5rem 2.25rem 0',
      width: '24.75rem'
    },
    button: {
      width: '100%',
      height: '2.75rem'
    },
    iconContainer: {
      marginRight: '0.5rem'
    },
    filtersContainer: {
      marginBottom: '0.5rem'
    },
    datePickersDescription: {
      fontSize: '0.875rem',
      marginBottom: '0.75rem',
      color: DARK_GRAY_COLOR
    },
    datePicker: {
      width: '11.5rem'
    }
  })
);

export default useAdvancedFilterModalStyles;
