import HorizontalMenuBar from '../../../organisms/horizontal-menu-bar';
import VerticalMenuBar from '../../../organisms/vertical-menu-bar';

const MenuBar = () => (
  <>
    <HorizontalMenuBar />
    <VerticalMenuBar />
  </>
);

export default MenuBar;
