import { Grid, IconButton } from "@mui/material";
import { styled } from "@mui/styles";
import { Info, Download, XCircle } from 'react-feather';
import { FindInPageOutlined } from '@mui/icons-material';
import { GRAY_COLOR, CURRICULUM_COLOR, CRITICAL_COLOR } from "../../../../../theme";
import CustomizedTooltip from "../../../atoms/customized-tooltip";
import { CurriculumActionProps } from '../types';
import CurriculumStatus from "../../../../../domain/enum/curriculum/CurriculumStatus";
import { useHistory } from "react-router-dom";
import { pageRoutes } from '../../../../utils/constants';
import { setCurriculum } from "../../curriculum-validate/store/actions";
import { useDispatch } from "react-redux";
import { setCurriculumInfo } from "../../curriculum-info/store/actions";
import axios from 'axios';
import CurriculumService from "../../../../../services/CurriculumService";
import DigitalCurriculumCanceledModal from "../../../organisms/digital-curriculum-cancel-modal/DigitalCurriculumCanceledModal";
import { useState } from "react";

const PREFIX = 'CurriculumAction';

const classes = {
  icon: `${PREFIX}-icon`,
  activeIcon: `${PREFIX}-activeIcon`,
  activeIconCancel: `${PREFIX}-activeIconCancel`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.icon}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.25rem',
    height: '1.25rem',
    color: GRAY_COLOR,
    cursor: 'not-allowed',
  },
  [`& .${classes.activeIcon}`]: {
    cursor: 'pointer',
    color: CURRICULUM_COLOR,
  },
  [`& .${classes.activeIconCancel}`]: {
    cursor: 'pointer',
    color: CRITICAL_COLOR,
  },
}));

export default function CurriculumAction(props: Readonly<CurriculumActionProps>) {
  const { id, status, validationCode, issuerMecCode, curriculumCode } = props

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const enableValidateCurriculum = status === CurriculumStatus.IssuerConfirmationPending;
  const enableCancel = status === CurriculumStatus.AwaitingXmlSignature;
  const enableSavePdf = status === CurriculumStatus.Completed
  const history = useHistory();
  const dispatch = useDispatch();
  const navigateToCurriculumValidateOnClick = () => {
    const formatId = id.split('#')
    dispatch(setCurriculum(formatId[1]))
    history.push(pageRoutes.DigitalCurriculumValidate)
  }

  const navigateToCurriculumInfoOnClick = () => {
    const formatId = id.split('#')
    dispatch(setCurriculumInfo(formatId[1]))
    history.push(pageRoutes.DigitalCurriculumInfo)
  }

  const downloadPdfCurriculumOnClick = async () => {
    const request = { validationCode };
    const { linkDownloadRvce } = await CurriculumService.getPublicCurriculum(request);
    const header = { Pragma: 'no-cache' };
    const axiosInstance = axios.create({
      headers: header,
      baseURL: ''
    });
    const response = await axiosInstance.get(linkDownloadRvce);
    const linkSource = `data:application/pdf;base64,${response.data}`;
    const downloadLink = document.createElement("a");
    const fileName = `curriculo_escolar_digital_${issuerMecCode}_${curriculumCode}.pdf`
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();

  }

  return (
    <StyledGrid
      container
      justifyContent="center"
      alignItems="center"
      spacing={3}
    >
      <Grid item>
        <CustomizedTooltip title="Validar currículo" placement="top">
          <span>
            <IconButton size="large" onClick={() => navigateToCurriculumValidateOnClick()} disabled={!enableValidateCurriculum}>
              <FindInPageOutlined className={`${classes.icon} ${!enableValidateCurriculum ? '' : classes.activeIcon}`} />
            </IconButton>
          </span>
        </CustomizedTooltip>
        <CustomizedTooltip title="Informações" placement="top">
          <span>
            <IconButton onClick={() => navigateToCurriculumInfoOnClick()} size="large" >
              <Info className={`${classes.icon} ${classes.activeIcon} `} />
            </IconButton>
          </span>
        </CustomizedTooltip>
        <CustomizedTooltip title="Cancelar" placement="top">
          <span>
            <IconButton size="large" disabled={!enableCancel} onClick={() => setIsCancelModalOpen(true)}>
              <XCircle className={`${classes.icon} ${!enableCancel ? '' : classes.activeIconCancel}`} />
            </IconButton>
          </span>
        </CustomizedTooltip>
        <CustomizedTooltip title="Salvar PDF" placement="top">
          <span>
            <IconButton size="large" disabled={!enableSavePdf} onClick={() => downloadPdfCurriculumOnClick()}>
              <Download className={`${classes.icon} ${!enableSavePdf ? '' : classes.activeIcon}`} />
            </IconButton>
          </span>
        </CustomizedTooltip>
      </Grid>
      <DigitalCurriculumCanceledModal
        handleClose={(_) => setIsCancelModalOpen(false)}
        isOpen={isCancelModalOpen}
        curriculumId={id} />
    </StyledGrid>
  )
}
