import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { DARK_GRAY_COLOR, FONT_FAMILY_LIGHT } from '../../../../theme';

const usePageTitleStyles = makeStyles(() => createStyles({
  root: {
    width: '100%',
    margin: '0px',
  },
  grayText: {
    color: DARK_GRAY_COLOR,
    fontFamily: FONT_FAMILY_LIGHT,
    marginRight: '12px',
  },
  containerCards: {
    width: '95%',
    margin: '0px',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    '@media (max-width:1330px)': {
      display: 'grid',
      gridTemplateRows: 'auto auto',
    },
  },
  containerTitle: {
    width: '95%',
    margin: '40px 0',
    padding: '0 !important',
  },
}));

export default usePageTitleStyles;
