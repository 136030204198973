import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { AppBar, IconButton, Typography, Grid } from '@mui/material';
import { LogOut } from 'react-feather';
import Divider from '@mui/material/Divider';
import {
  WHITE_COLOR,
  MEDIUM_GRAY_COLOR,
  LIGHT_GRAY_COLOR
} from '../../../../theme';
import { modalInformation } from '../../../utils/constants';
import ConfirmationModal from '../confirmation-modal';
import SelectIesCampus from '../../molecules/select-ies-campus/index';
import { RootState } from '../../../../setup/reducer/reducers';
import EducationalStageLabel from '../diploma-educational-stage-label';
import { LogoutComponent } from '../../pages/login-microsoft/LoginManager';

const PREFIX = 'HorizontalMenuBar';

const classes = {
  appBar: `${PREFIX}-appBar`,
  container: `${PREFIX}-container`
};

const StyledAppBar = styled(AppBar)(() => ({
  [`&.${classes.appBar}`]: {
    position: 'fixed',
    width: '100%',
    boxShadow:
      '0px 1px 1px -2px rgba(0,0,0,0.2), 0px -1px 1px 1px rgba(0,0,0,0.05), 0px 3px 1px 3px rgba(0,0,0,0.01)',
    height: '56px',
    backgroundColor: WHITE_COLOR,
    zIndex: 2,
    borderBottom: `1px solid ${LIGHT_GRAY_COLOR}`
  },

  [`& .${classes.container}`]: {
    width: '100%',
    height: '56px'
  }
}));

const HorizontalMenuBar = () => {
  const user = useSelector((state: RootState) => state.login.user);
  const userManager = useSelector(
    (state: RootState) => state.login.userManager
  );

  let userName = user?.name;
  if (userName) {
    const names = userName.split(' ');
    if (names.length >= 2) {
      userName = `${names[0]}`;
    }
  }

  const [modalLogout, setModalLogout] = useState(false);

  const exit = () => {
    localStorage.clear();
    sessionStorage.clear();
    LogoutComponent(userManager);
  };

  const logout = () => {
    setModalLogout(true);
  };

  return (
    <StyledAppBar className={classes.appBar} position="fixed">
      <Grid
        container
        className={classes.container}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        wrap="nowrap"
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          xs={6}
          spacing={3}
        >
          <Grid item />
          <Grid item>
            <SelectIesCampus data-testid="select-ies-campus" />
          </Grid>
          <Grid item>
            <Divider
              light
              orientation="vertical"
              variant="middle"
              style={{ height: '56px' }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={6}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={3}
        >
          <Grid item>
            <Divider
              light
              orientation="vertical"
              variant="middle"
              style={{ height: '56px' }}
            />
          </Grid>
          <Grid item>
            <EducationalStageLabel data-testid="select-educational-level" />
          </Grid>
          <Grid item>
            <Divider
              light
              orientation="vertical"
              variant="middle"
              style={{ height: '56px' }}
            />
          </Grid>
          <Grid item>
            <Typography variant="h6">{userName}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={logout}
              style={{ marginLeft: '8px', marginRight: '-16px' }}
              size="large"
            >
              <LogOut color={MEDIUM_GRAY_COLOR} size={18} />
            </IconButton>
            <ConfirmationModal
              openModal={modalLogout}
              modalTitle={modalInformation.modalExit.title}
              textContinueButton={modalInformation.modalExit.textContinueButton}
              handleContinueButton={() => {
                exit();
              }}
              textCancelButton={modalInformation.textCancelButton}
              handleCancelButton={() => {
                setModalLogout(false);
              }}
            />
          </Grid>
          <Grid item />
        </Grid>
      </Grid>
    </StyledAppBar>
  );
};

export default HorizontalMenuBar;
