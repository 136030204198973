import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { pageRoutes } from '../../../../utils/constants';
import BackCardHeader from '../../../molecules/back-card-header';
import CardTitle from '../../../molecules/card-title';
import DefaultTemplate from '../../../templates/default-template';
import CertificateInfoFilter from './CertificateInfoFilter';
import CertificateInfoTable from './CertificateInfoTable';


const PREFIX = 'CertificateInfo';

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  buttonUpload: `${PREFIX}-buttonUpload`,
  gridDropzone: `${PREFIX}-gridDropzone`,
  gridDropzoneDisabled: `${PREFIX}-gridDropzoneDisabled`,
  dropZoneBox: `${PREFIX}-dropZoneBox`,
  dropZoneBoxP: `${PREFIX}-dropZoneBoxP`,
  dropZoneBoxAdd: `${PREFIX}-dropZoneBoxAdd`,
  gridIcon: `${PREFIX}-gridIcon`,
  gridIconFile: `${PREFIX}-gridIconFile`,
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    paddingBottom: theme.spacing(7),
  }
}));

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main,
  }
}));

const CertificateInfoPage = () => {
  const history = useHistory();
  const navigateBackToMicrocertificatesOnClick = () => {
    history.push(pageRoutes.Certificate)
  }

  return (
    <Root>
      <DefaultTemplate message="Informações do " highlightedMessage="Lote" >
        <StyledPaper>
          <BackCardHeader onClick={() => navigateBackToMicrocertificatesOnClick()} />

          <Grid container item xs={12} className={classes.root}>
            <CardTitle icon={<DescriptionOutlinedIcon className={classes.icon} />}>Certificados</CardTitle>
            <CertificateInfoFilter />
            <CertificateInfoTable />
          </Grid>

        </StyledPaper>
      </DefaultTemplate>
    </Root>

  );
}


export default CertificateInfoPage; 
