import React from 'react';
import { Button, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Copy } from 'react-feather';
import DouBatchType from '../../../../domain/enum/dou/DouBatchType';
import CustomizedTooltip from '../../atoms/customized-tooltip';
import BaseModal from '../../molecules/base-modal';
import usePublicationDouModalStyles from './styles';
import { PublicationDouModalProps } from './types';
import { DIPLOMA_VALIDATION_PASSIVE_ACTION } from '../../../../theme';

const PublicationDouModal = (props: PublicationDouModalProps) => {
  const classes = usePublicationDouModalStyles();

  const {
    openModal,
    modalTitle,
    textCopyButton,
    handleCopyButton,
    loading,
    loadingMessage,
    onClose,
    children,
    batchType,
  } = props;

  function getBatchTypeStyle(): string {
    return batchType === DouBatchType.Cancellation ? classes.cancellationGridModal : classes.registerGridModal;
  }

  return (
    <BaseModal
      openModal={openModal}
      onClose={onClose}
      modalTitle={modalTitle}
      loading={loading}
      loadingMessage={loadingMessage}
      containerContentStyle={getBatchTypeStyle()}
      actions={(
        <Grid
          item
          container
          justifyContent="space-around"
          alignItems="center"
        >
          <Button
            className={classes.actButton}
            onClick={handleCopyButton}
          >
            <>
              <CustomizedTooltip title={textCopyButton} placement="top-start" className={classes.iconTooltip}>
                <Copy className={classes.icon} color={DIPLOMA_VALIDATION_PASSIVE_ACTION} />
              </CustomizedTooltip>
              {textCopyButton}
            </>
          </Button>
        </Grid>
)}
    >
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
        size="large">
        <CloseIcon />
      </IconButton>
      <Grid
        container
        direction="column"
        spacing={3}
        className={classes.gridChildModal}
      >
        <Grid item>
          {children}
        </Grid>
      </Grid>
    </BaseModal>
  );
};

PublicationDouModal.defaultProps = {
  children: null,
  loading: false,
  loadingMessage: '',
};

export default PublicationDouModal;
