import { useState } from 'react';
import { Grid, Typography, styled } from '@mui/material';
import ConfirmationModal from '../confirmation-modal';
import BackdropLoading from '../../molecules/backdrop-loading';
import { errorNotificationMessages, modalInformation, successNotificationMessages } from '../../../utils/constants';
import CustomTextField from '../../atoms/form/CustomTextField';
import CustomDatePicker from '../../atoms/form/CustomDatePicker';
import { showNotification, showSuccessNotification } from '../../pages/notification/actions';
import { useDispatch } from 'react-redux';
import DouService from '../../../../services/DouService';

const PREFIX = 'EditDouModal';

const classes = {
  spaced: `${PREFIX}-spaced`,
  helperIcon: `${PREFIX}-helperIcon`,
  descriptionLoading: `${PREFIX}-descriptionLoading`,
  circularProgress: `${PREFIX}-circularProgress`,
  grid: `${PREFIX}-grid`,
  texField: `${PREFIX}-texField`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.spaced}`]: {
    marginBottom: theme.spacing(2)
  },
}));

type Props = {
  openEditModal: boolean;
  setOpenEditModal: (flag: boolean) => void;
  institutionMecCode?: number;
  batchNumber?: number;
  type?: string;
  callbackAfterUpdateBatch?: () => void;
};

const EditDouModal = ({ openEditModal, setOpenEditModal, institutionMecCode, batchNumber, type, callbackAfterUpdateBatch }: Props) => {
  const [isLoading, setLoading] = useState(false);
  const [dateSelect, setDateSelect] = useState('');
  const [douNumber, setDouNumber] = useState('');
  const dispatch = useDispatch();

  const updateBatchService = async (institutionMecCode: number, batchNumber: number, type: string, request: any) => {
    try {
      const res: any = await DouService.updateBatch(institutionMecCode, batchNumber, type, request);
      if (res.data.success && res.status === 200) {
        dispatch(showSuccessNotification(successNotificationMessages.successUpdateBatch));
      } else {
        dispatch(showNotification(errorNotificationMessages.failedUpdateBatch));
      }
    } catch (e) {
      dispatch(showNotification(errorNotificationMessages.failedUpdateBatch));
    }
  };

  const handleDouNumberChange = (event: any) => {
    setDouNumber(event.target.value);
  };

  const getRequest = () => {
    const date = dateSelect ? new Date(dateSelect).toISOString() : '';
    return {
      publicationDate: date,
      douNumber
    };
  };

  async function handleSubmit() {
    setLoading(true);
    if (institutionMecCode && batchNumber && type) {
      await updateBatchService(institutionMecCode, batchNumber, type, getRequest());
    };
    setDouNumber('');
    setLoading(false);
    setOpenEditModal(false);
    if (callbackAfterUpdateBatch) {
      callbackAfterUpdateBatch();
    };

  };

  function handleClickCancelButton() {
    setDouNumber('');
    setDateSelect('');
    setOpenEditModal(false);
  };

  return (
    <>
      <ConfirmationModal
        openModal={openEditModal}
        modalTitle={modalInformation.modalEditDou.title}
        modalSubTitle={modalInformation.modalEditDou.subTitle}
        textContinueButton={modalInformation.modalEditDou.textContinue}
        handleContinueButton={handleSubmit}
        textCancelButton={modalInformation.modalEditDou.textCancel}
        handleCancelButton={handleClickCancelButton}
      >
        <StyledGrid
        >
          {isLoading && (
            <BackdropLoading
              size={50}
              description='Editando Informações...'
              open={isLoading}
              classes={classes}
            />
          )}

          <Grid item xs={12}>
            <Typography variant="body1">Data de Publicação</Typography>
            <CustomDatePicker
              value={''}
              onChange={(newDate: string) => setDateSelect(newDate)}
            />
            <Typography className={classes.spaced} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Número do DOU</Typography>
            <CustomTextField
              placeholder="Número do DOU"
              type="text"
              fullWidth
              value={douNumber}
              onChange={handleDouNumberChange}
            />
          </Grid>
        </StyledGrid>
      </ConfirmationModal>
    </>
  );
};

EditDouModal.defaultProps = {
  callbackAfterUpdateBatch: () => { },
};

export default EditDouModal;

