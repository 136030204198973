import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import moment from 'moment';
import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import BatchStatus from '../../../../../domain/enum/certificate/BatchStatus';
import { RootState } from '../../../../../setup/reducer/reducers';
import { DARK_CYAN_COLOR, DARK_GRAY_COLOR, DIPLOMA_COLOR, GRAY_COLOR, INFO_COLOR, TOMATO_COLOR } from "../../../../../theme";
import CustomizedTooltip from '../../../atoms/customized-tooltip';
import EmptyTable from '../../../molecules/empty-table';
import { getComparator, getRows, headCells, stableSort } from "../CertificateTableManager";
import { CertificateData, EnhancedTableProps, Order, RenderCellParams } from "../types";
import CertificateAction from './CertificateAction';

const PREFIX = 'CertificateTable';

const classes = {
  root: `${PREFIX}-root`,
  pagination: `${PREFIX}-pagination`,
  cellabel: `${PREFIX}-cellabel`,
  activeSortIcon: `${PREFIX}-activeSortIcon`,
  inactiveSortIcon: `${PREFIX}-inactiveSortIcon`,
  status: `${PREFIX}-status`,
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    paddingLeft: '30px',
    paddingRight: '30px',
  },

  [`& .${classes.pagination}`]: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
  },

  [`& .${classes.cellabel}`]: {
    color: DARK_GRAY_COLOR,
    fontFamily: theme.typography.body2.fontFamily,
  },
  [`& .${classes.status}`]: {
    fontFamily: theme.typography.body2.fontFamily,
    fontWeight: 800
  },

  [`& .${classes.activeSortIcon}`]: {
    fill: theme.palette.primary.main,
    width: 24,
    height: 24,
  },

  [`& .${classes.inactiveSortIcon}`]: {
    width: 24,
    height: 24,
  },

}));

const mapState = (state: RootState) => ({
  search: state.certificateFilter.search,
  batches: state.certificateFilter.batches,
  loadingBatches: state.certificateFilter.loadingBatches
})

const connector = connect(mapState);

const TableLoading = () => (
  <TableBody>
    {
      [...Array(10)].map((_e, i) => (
        <TableRow key={i++}>
          {
            [...Array(headCells.length)].map((_f, j) => (
              <TableCell key={i++ + j++} colSpan={1}>
                <Skeleton style={{ height: 32 }} animation="wave" />
              </TableCell>
            ))
          }
        </TableRow>
      ))
    }
  </TableBody>
);

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const {
    classes, onRequestSort, order, orderBy,
  } = props;

  const createSortHandler = (property: keyof CertificateData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{
              width: headCell.size,
              whiteSpace: 'nowrap',
            }}
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id && order}
          >
            {
              headCell.sortable
                ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                    classes={{
                      icon: ((orderBy === headCell.id) ? classes.activeSortIcon : classes.inactiveSortIcon),
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <Typography variant="caption" style={headCell.styles}>
                      {headCell.label}
                    </Typography>
                  </TableSortLabel>
                )
                : (
                  <Typography variant="caption" style={headCell.styles}>
                    {headCell.label}
                  </Typography>
                )
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

type Props = ConnectedProps<typeof connector>;

const CertificateTable = (props: Props) => {
  const {
    search, batches, loadingBatches
  } = props;

  const [orderBy, setOrderBy] = React.useState<keyof CertificateData>('createdAt');
  const [order, setOrder] = React.useState<Order>('desc');
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState<CertificateData[]>([]);
  const rowsPerPage = 8;

  useEffect(() => {
    setRows(getRows(batches, search));
  }, [batches, search]);

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof CertificateData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const validStatusBatch = (status: string): boolean => {
    switch (status) {
      case BatchStatus.Error:
        return true
      case BatchStatus.Processing:
        return true
      default:
        return false
    }
  }

  const renderActionsCell = (id: string, status: string) => (
    <TableCell align="center">
      <CertificateAction id={id} disabledButton={validStatusBatch(status)} />
    </TableCell>
  );

  const renderCell = ({
    label, splitPoint = 30, cellSize = 25, textAlign = 'left',
  }: RenderCellParams) => {
    const customStyle = splitPoint >= 15 ? { width: cellSize } : { width: cellSize, paddingLeft: '40px' };

    if (label) {
      if (label.length > 100) {
        return (
          <TableCell align={textAlign} style={customStyle}>
            <CustomizedTooltip title={label} placement="top">
              <Typography className={classes.cellabel} variant="body1">{`${(label).slice(0, 100)}...`}</Typography>
            </CustomizedTooltip>
          </TableCell>
        );
      }
      return (<TableCell align={textAlign} style={customStyle}><Typography className={classes.cellabel} variant="body1">{label}</Typography></TableCell>);
    }

    return <TableCell align={textAlign} style={customStyle}><Typography className={classes.cellabel} variant="body1">-</Typography></TableCell>;
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const getNumPages = () => Math.ceil((rows && rows.length) / rowsPerPage) - 1;

  const renderStatusCell = (value: string) => {
    let colorStatus = ''
    switch (value) {
      case BatchStatus.Imported:
        colorStatus = DARK_CYAN_COLOR
        break
      case BatchStatus.Error:
        colorStatus = TOMATO_COLOR
        break
      default:
        colorStatus = INFO_COLOR
    }
    return (
      <TableCell align={'left'}><Typography style={{ color: `${colorStatus}` }} className={classes.status} variant="body1">{value.toUpperCase()}</Typography></TableCell>
    );
  };

  const buildCells = (row: CertificateData): React.ReactNode => (
    <TableRow
      hover
      key={row.id}
    >
      {renderStatusCell(row.status)}

      {renderCell({
        label: row.description,
        cellSize: 10,
        splitPoint: 25,
      })}

      {renderCell({
        label: row.user,
        cellSize: 10,
        splitPoint: 25,
      })}

      {renderCell({
        label: row.createdAt ? moment(row.createdAt).format('DD/MM/YYYY') : '',
        cellSize: 10,
        splitPoint: 25,
        textAlign: 'center',
      })}

      {renderActionsCell(row.id, row.status)}

    </TableRow>
  );

  let tableContent;

  if (loadingBatches) {
    tableContent = <TableLoading />;
  } else {
    if (rows.length > 0) {
      tableContent = (
        <TableBody>
          {stableSort(rows, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row: CertificateData) => buildCells(row))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 65 * emptyRows }}>
              <TableCell colSpan={headCells.length} />
            </TableRow>
          )}
        </TableBody>
      );
    } else {
      tableContent = (
        <TableBody>
          <TableRow style={{ height: 650 }}>
            <TableCell colSpan={headCells.length}>
              <EmptyTable emptyStateMessage="Nenhum resultado encontrado" orientationMessage="Tente informar dados diferentes para a busca!" />
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
  }

  return (
    <Root className={classes.root}>
      <TableContainer>
        <Table>
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          {tableContent}
        </Table>
      </TableContainer>

      {batches.length > 0 ? (
        <TablePagination
          component="div"
          className={classes.pagination}
          count={rows.length ? rows.length : 0}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={(obj: any) => (
            `${obj.from}-${obj.to} de ${obj.count}`
          )}
          nextIconButtonProps={{
            style: {
              color: page === getNumPages() ? GRAY_COLOR : DIPLOMA_COLOR,
            },
          }}
          backIconButtonProps={{
            style: {
              color: page === 0 ? GRAY_COLOR : DIPLOMA_COLOR,
            },
          }}
          page={page}
          onPageChange={handleChangePage}
        />
      ) : null}
    </Root>
  )
}

export default connector(CertificateTable); 
