enum ChangeType {
  RegisterInstitutionValidatedDiploma = 'Registro validado pela IES registradora',
  IssuerInstitutionValidatedDiploma = 'Registro validado pela IES emissora',
  StatusUpdated = 'Status alterado',
  RegisterInstitutionCancelsDiploma = 'Registro anulado pela IES registradora',
  RegisterInstitutionTemporarilyCancelsDiploma = 'Registro anulado temporariamente pela IES registradora',
  IssuerInstitutionCancelsDiploma = 'Registro anulado pela IES emissora',
  IssuerInstitutionTemporarilyCancelsDiploma = 'Registro anulado temporariamente pela IES emissora',
  RegisterInstitutionActivatesDiploma = 'Registro ativado pela IES registradora',
  IssuerInstitutionActivatesDiploma = 'Registro ativado pela IES emissora',
  IssuerInstitutionRestartsDiploma = 'Registro cancelado pela IES emissora',
  DefaultSignersUpdate = 'Assinantes padrão atualizados',
  IssuerInstitutionSynchronizesDiploma = 'Registro sincronizado pela IES emissora'
}

export default ChangeType;
