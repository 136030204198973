import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import React from 'react';
import { User } from 'react-feather';
import CardTitle from '../../molecules/card-title';
import { FONT_FAMILY_EXTRABOLD } from '../../../../theme';
import { formatDate } from '../../../utils/functions';
import { AcademicDocGraduatePaperProps } from './types';
import LabeledTooltip from '../../molecules/labeled-tooltip';

const PREFIX = 'index';

const classes = {
  icon: `${PREFIX}-icon`,
  typography: `${PREFIX}-typography`
};

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${classes.typography}`]: {
    fontFamily: FONT_FAMILY_EXTRABOLD,
    color: theme.palette.primary.main,
  }
}));

export default function AcademicDocGraduatePaper({ academicDocumentation, validation, originSystem, processNumber }: AcademicDocGraduatePaperProps): JSX.Element {

  return (
    <StyledPaper>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <CardTitle icon={<User className={classes.icon} />}>Diplomado</CardTitle>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip label="Situação" error={validation?.transcript?.studentSituation} errorMessage={validation?.transcript?.studentSituation}
            originSystem={originSystem} processNumber={processNumber}>
            {academicDocumentation?.transcript?.studentSituation || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip label="Situação ENADE" error={validation?.transcript?.enadeSituation} errorMessage={validation?.transcript?.enadeSituation}
            originSystem={originSystem} processNumber={processNumber}>
            {academicDocumentation?.transcript?.enadeSituation || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip label="Data da Prova ENADE" error={validation?.transcript?.enadeTestDate} errorMessage={validation?.transcript?.enadeTestDate}
            originSystem={originSystem} processNumber={processNumber}>
            {formatDate(academicDocumentation?.transcript?.enadeTestDate) || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={12}>
          <CardTitle variant="body1" classes={classes} icon={<SchoolOutlinedIcon className={classes.icon} />}>Curso</CardTitle>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip label="Data de Ingresso" error={validation?.enrollment?.date} errorMessage={validation?.enrollment?.date}
            originSystem={originSystem} processNumber={processNumber}>
            {formatDate(academicDocumentation?.enrollment?.date) || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip label="Forma de Ingresso" error={validation?.enrollment?.admissionProcess} errorMessage={validation?.enrollment?.admissionProcess}
            originSystem={originSystem} processNumber={processNumber}>
            {academicDocumentation?.enrollment?.admissionProcess || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip label="Data de Conclusão do Ensino Médio" error={validation?.conclusionDate} errorMessage={validation?.conclusionDate}
            originSystem={originSystem} processNumber={processNumber}>
            {formatDate(academicDocumentation?.conclusionDate) || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip label="Data de Colação de Grau" error={validation?.graduationCeremonyDate} errorMessage={validation?.graduationCeremonyDate}
            originSystem={originSystem} processNumber={processNumber}>
            {formatDate(academicDocumentation?.graduationCeremonyDate) || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip label="Carga Horária" error={validation?.courseWorkload} errorMessage={validation?.courseWorkload}
            originSystem={originSystem} processNumber={processNumber}>
            {academicDocumentation?.courseWorkload?.toString() || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip label="Carga Horária Integralizada" error={validation?.transcript?.courseLoadPaidIn} errorMessage={validation?.transcript?.courseLoadPaidIn}
            originSystem={originSystem} processNumber={processNumber}>
            {academicDocumentation?.transcript?.courseLoadPaidIn?.toString() || 'N/A'}
          </LabeledTooltip>
        </Grid>
      </Grid>
    </StyledPaper>
  );
}
