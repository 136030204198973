import {
  CertificateInfoActions,
  CertificateInfoState,
  INITIAL_STATE,
  SET_BATCH_CERTIFICATES,
  SET_CERTIFICATES,
  SET_LOADING_CERTIFICATES,
  SET_SEARCH
} from './types';

export default function reducer(
  state: CertificateInfoState = INITIAL_STATE,
  action: CertificateInfoActions
): CertificateInfoState {
  switch (action.type) {
    case SET_CERTIFICATES:
      return {
        ...state,
        certificates: action.payload.certificates
      };
    case SET_LOADING_CERTIFICATES:
      return {
        ...state,
        loadingCertificates: action.payload.loadingCertificates
      };
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload.search
      };
    case SET_BATCH_CERTIFICATES:
      return {
        ...state,
        id: action.payload.id
      };
    default:
      return state;
  }
}
