import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, styled, Grid, } from "@mui/material";
import { EERIE_BLACK_COLOR, DIPLOMA_COLOR } from "../../../../theme";

const PREFIX = 'RejectedDialogInfo';

const classes = {
  title: `${PREFIX}-title`,
  titleAction: `${PREFIX}-titleAction`,
  action: `${PREFIX}-action`,
  contentdReason: `${PREFIX}-contentdReason`
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.title}`]: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '28px',
    color: EERIE_BLACK_COLOR
  },
  [`& .${classes.titleAction}`]: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '14px',
    color: DIPLOMA_COLOR
  },
  [`& .${classes.action}`]: {
    justifyContent: 'flex-end'
  },
  [`& .${classes.contentdReason}`]: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
    wordBreak: 'break-all'
  }
}));

export interface RejectedDialogInfoProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

const RejectedDialogInfo = (props: RejectedDialogInfoProps) => {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <StyledDialog onClose={handleClose} open={open} PaperProps={{ style: { width: 560, borderRadius: 4 } }}>
      <DialogTitle className={classes.title}>Currículo não aprovado </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.contentdReason}>
              Motivo: {selectedValue}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.action} >
        <Button onClick={handleClose}>
          <Typography className={classes.titleAction} >
            FECHAR
          </Typography>
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default RejectedDialogInfo;
