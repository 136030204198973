import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup/reducer/reducers';
import { getDefaultGreetingMessage } from '../../../utils/functions';
import PageTitle from '../../molecules/page-title';
import { DefaultTemplateProps } from './types';

function DefaultTemplate({ children, highlightedMessage, message }: DefaultTemplateProps): JSX.Element {
  const userName = useSelector((state: RootState) => state.login.user?.name);

  if (!highlightedMessage || !message) {
    const pageTitle = getDefaultGreetingMessage(userName);
    highlightedMessage = pageTitle.highlightedMessage;
    message = pageTitle.message;
  }

  return (
    <>
      <PageTitle highlightedMessage={highlightedMessage} message={message} />
      {children}
    </>
  );
}

DefaultTemplate.defaultProps = {
  children: null,
  message: undefined,
  highlightedMessage: undefined,
};

export default DefaultTemplate;
