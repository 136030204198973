import { styled } from '@mui/material/styles';
import { ClassNameMap } from '@mui/styles';
import { Grid, Typography, Divider, Skeleton } from '@mui/material';
import { AlertTriangle } from 'react-feather';
import { LabeledTooltipProps } from './types';
import { FONT_FAMILY_REGULAR, FONT_FAMILY_BOLD } from '../../../../theme';
import PaperTooltip from '../../atoms/paper-tooltip';
import OriginSystem from '../../../../domain/enum/diploma/OriginSystem';

const PREFIX = 'index';

const defaultClasses = {
  root: `${PREFIX}-root`,
  errorMessage: `${PREFIX}-errorMessage`,
  errorIcon: `${PREFIX}-errorIcon`,
  tooltipContainer: `${PREFIX}-tooltipContainer`,
  teachersTooltipIcon: `${PREFIX}-teachersTooltipIcon`,
  teachersTypography: `${PREFIX}-teachersTypography`,
  tooltipTypography: `${PREFIX}-tooltipTypography`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${defaultClasses.root}`]: {
    padding: '0 32px 40px 32px'
  },

  [`& .${defaultClasses.errorMessage}`]: {
    fontFamily: FONT_FAMILY_REGULAR
  },

  [`& .${defaultClasses.errorIcon}`]: {
    color: theme.palette.error.main
  },

  [`& .${defaultClasses.tooltipContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 16
  },

  [`& .${defaultClasses.teachersTooltipIcon}`]: {
    color: theme.palette.primary.main
  },

  [`& .${defaultClasses.teachersTypography}`]: {
    fontFamily: FONT_FAMILY_BOLD,
    color: theme.palette.error.main,
    marginLeft: 8,
    marginRight: 24
  },

  [`& .${defaultClasses.tooltipTypography}`]: {
    margin: 8,
    marginLeft: 16
  }
}));

export default function LabeledTooltip({
  label,
  children,
  error,
  errorMessage,
  classes,
  placeholder,
  isLoading,
  'data-testid': dataTestid,
  originSystem,
  processNumber
}: LabeledTooltipProps): JSX.Element {
  const mergedClasses: ClassNameMap<'root' | 'errorMessage'> = {
    root: classes ? classes.root : defaultClasses.root,
    errorMessage: classes ? classes.errorMessage : defaultClasses.errorMessage
  };

  const getTooltipOriginSystem = (
    processNumber?: string,
    originSystem?: string
  ): JSX.Element | null => {
    switch (originSystem) {
      case OriginSystem.SIAF:
        return getTooltipSiaf();
      case OriginSystem.SIGADL:
        return getTooltipSiga(originSystem);
      case OriginSystem.SIGAF2F:
        return getTooltipSiga(originSystem);
      default:
        return processNumber !== '0'
          ? getTooltipSiaf()
          : getTooltipSiga(OriginSystem.SIGA);
    }
  };

  const getTooltipSiaf = (): JSX.Element | null => {
    return (
      <Typography className={defaultClasses.tooltipTypography} variant="h6">
        A edição dos dados só podem ser
        <br />
        realizadas no SIAF
        <br />
        <br />
        Para isso ACESSE o SIAF
        <br />
        e realize as devidas alterações.
        <br />
        Depois realize a SINCRONIZAÇÃO através do produto.
      </Typography>
    );
  };

  const getTooltipSiga = (originSystem?: string): JSX.Element | null => {
    return (
      <Typography className={defaultClasses.tooltipTypography} variant="h6">
        A edição dos dados só podem ser
        <br />
        realizadas no {originSystem}
        <br />
        <br />
        Para isso ACESSE o {originSystem}
        <br />
        após realizar as devidas alterações.
      </Typography>
    );
  };

  const getTooltip = (element: JSX.Element): JSX.Element | null => {
    if (!error) {
      return element;
    }

    return (
      <>
        <PaperTooltip
          placement="bottom-start"
          title={
            <>
              <Root>
                <Grid
                  container
                  item
                  xs={12}
                  className={defaultClasses.tooltipContainer}
                >
                  <Grid item>
                    <AlertTriangle
                      size={15}
                      className={defaultClasses.errorIcon}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      className={defaultClasses.teachersTypography}
                      variant="body2"
                    >
                      Inconsistências Encontradas
                    </Typography>
                  </Grid>
                </Grid>
              </Root>
              <Divider light style={{ marginBottom: 16 }} />
              <Root>{getTooltipOriginSystem(processNumber, originSystem)}</Root>
              <div style={{ paddingBottom: 8 }} />
            </>
          }
        >
          {element}
        </PaperTooltip>
      </>
    );
  };

  const getErrorIcon = (): JSX.Element | null => {
    if (!error) {
      return null;
    }

    return (
      <>
        <Grid item>
          {getTooltip(
            <AlertTriangle size="16px" className={defaultClasses.errorIcon} />
          )}
        </Grid>
      </>
    );
  };

  const getErrorMessage = (): JSX.Element | null => {
    if (!errorMessage) {
      return null;
    }

    return (
      <>
        <Grid item xs={12}>
          {getTooltip(
            <Root>
              <Typography
                noWrap
                variant="body1"
                color="error"
                className={mergedClasses.errorMessage}
              >
                {errorMessage}
              </Typography>
            </Root>
          )}
        </Grid>
      </>
    );
  };

  const getContent = (): JSX.Element => {
    if (isLoading) {
      return <>{getTooltip(<Skeleton animation="wave" />)}</>;
    }

    return (
      <>
        {getTooltip(
          <Typography variant="body2" component="span" data-testid={dataTestid}>
            {children || placeholder}
          </Typography>
        )}
      </>
    );
  };

  return (
    <Root>
      <Grid item container xs={12} className={mergedClasses.root}>
        <Grid item container xs={12} spacing={1}>
          {getErrorIcon()}
          <Grid item>
            {getTooltip(
              <Typography noWrap variant="body1" color={error ? 'error' : ''}>
                {label}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {getContent()}
        </Grid>
        {getErrorMessage()}
      </Grid>
    </Root>
  );
}
