import Batch from '../../../../domain/model/certificate/Batch';
import CertificateService from '../../../../services/CertificateService';
import { searchIgnoringCaseAndAccents } from '../../../utils/functions';
import { CertificateData, HeadCell, Order } from './types';

export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

export const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key
): ((
  a: { [key in Key]: number | string | string[] },
  b: { [key in Key]: number | string | string[] }
) => number) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

export const stableSort = <T>(
  array: T[],
  comparator: (a: T, b: T) => number
) => {
  if (array.length === 0) {
    return array;
  }
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

export const headCells: HeadCell[] = [
  {
    id: 'status',
    label: 'Status',
    size: '10%',
    align: 'left',
    sortable: true
  },
  {
    id: 'description',
    label: 'Descrição',
    size: '30%',
    align: 'left',
    sortable: true
  },
  {
    id: 'user',
    label: 'Nome do Usuário(a)',
    size: '10%',
    align: 'left',
    sortable: true
  },
  {
    id: 'createdAt',
    label: 'Data de Carregamento do Arquivo',
    size: '20%',
    align: 'center',
    sortable: true
  },
  {
    id: 'action',
    label: 'Ações',
    size: '10%',
    align: 'center',
    sortable: false
  }
];

export const listCertificateBatch = async (): Promise<Batch[]> => {
  let certificatesBatchs: Batch[] = [];
  try {
    return (await CertificateService.listBatches()).data;
  } catch (error) {
    return certificatesBatchs;
  }
};

export const getRows = (
  certificateBatchs: Batch[],
  search: string
): CertificateData[] => {
  let rows: CertificateData[] = [];

  if (!certificateBatchs || certificateBatchs.length === 0) {
    return rows;
  }

  certificateBatchs.forEach((value) => {
    rows.push({
      id: value.pk,
      status: value.status,
      description: value.description,
      user: value.user,
      createdAt: value.createdAt,
      action: ''
    });
  });

  if (search.length >= 2) {
    search = search.toLowerCase();
    rows = rows.filter(
      (value) =>
        searchIgnoringCaseAndAccents(value?.user, search) ||
        value.user?.toLowerCase().match(`.*${search}.*`)
    );
  }

  return rows;
};
