import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Skeleton from '@mui/material/Skeleton';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Check from '@mui/icons-material/Check';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';
import { Typography, Chip } from '@mui/material';
import { ClassNameMap } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { ColorLibStepProps, StepProp, StepPropIcon } from './types';
import {
  FONT_FAMILY_EXTRABOLD, FONT_FAMILY_SEMIBOLD, MEDIUM_GRAY_COLOR, CRITICAL_COLOR, OPAQUE_CRITICAL_COLOR
} from '../../../../theme';

const useColorLibStepIconStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    backgroundColor: theme.palette.background.default,
    border: `2px solid ${MEDIUM_GRAY_COLOR}`,
    boxSizing: 'border-box',
    zIndex: 1,
    width: '32px',
    height: '32px',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tag: {
    marginTop: '0px',
    marginBottom: '100px',
  },
  icon: {
    position: 'absolute',
    width: '11px',
    height: '24px',
    top: '4px',
    fontFamily: FONT_FAMILY_SEMIBOLD,
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: MEDIUM_GRAY_COLOR,
  },
  actionsContainer: {
    marginBottom: '2px',
  },
  manualChipLabel: {
    paddingRight: '0px',
    paddingLeft: '0px',
  },
  manualChipRoot: {
    height: '24px',
    paddingRight: '5px',
    paddingLeft: '5px',
    fontFamily: FONT_FAMILY_EXTRABOLD,
    color: CRITICAL_COLOR,
    backgroundColor: OPAQUE_CRITICAL_COLOR,
  }
}));

function customIcon(step: StepProp, stepPropIcon: StepPropIcon, classes: ClassNameMap) {
  if (stepPropIcon === 'E' || stepPropIcon === 'R') {
    return (
      <div
        className={classes.icon}
        style={{ color: step.color }}
      >
        {stepPropIcon}
      </div>
    );
  } else {
    return <Check style={{ color: step.color ?? MEDIUM_GRAY_COLOR }} />;
  }
}

function ColorLibStepIcon({ icon, steps }: ColorLibStepProps & { steps: StepProp[] }) {
  const classes = useColorLibStepIconStyles();
  const step = steps[icon - 1];

  return (
    <div
      className={classes.root}
      style={{ border: `2px solid ${step.color}` }}
    >
      {step.helperText && (
        <div className={classes.tag}>
          <Chip
            classes={{ root: classes.manualChipRoot, label: classes.manualChipLabel }}
            label={step.helperText}
          />
        </div>
      )}
      {step.icon ? customIcon(step, step.icon, classes) : null}
    </div>
  );
}

const LibConnector = withStyles({
  alternativeLabel: {
    top: '15.5px',
    left: 'calc(-50% + 0px)',
    right: 'calc(50% + 0px)',
  },
})(StepConnector);

interface TimelineProps {
  steps: StepProp[],
  isReport?: boolean,
  loader?: any,
}

function StepStatusTimeline(props: TimelineProps) {
  const activeStep = -1;
  const { steps, isReport, loader } = props;
  const ColorLibStepIconComponent = (props: any) => <ColorLibStepIcon {...props} steps={steps} />;


  const useStyles = makeStyles(() => ({
    root: {
      paddingTop: '40px',
      display: 'flex',
    },
    stepper: {
      padding: '24px 0px 24px 0px',
    },
    value: {
      margin: '10px 0px',
    },
    description: {
      color: MEDIUM_GRAY_COLOR,
      fontFamily: FONT_FAMILY_EXTRABOLD,
      fontSize: '12px',
      lineHeight: '20px',
      align: 'center',
    },
    skeleton: {
      height: '60px',
      width: '50%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '-14px',
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<LibConnector />}
            className={classes.stepper}
          >
            {steps.map((item: any) => (
              <Step key={item.label}>
                <StepLabel StepIconComponent={ColorLibStepIconComponent}>
                  {isReport && loader ? (
                    <Skeleton className={classes.skeleton} animation="wave" />
                  ) : (isReport && (<Typography className={classes.value} style={{ color: item.color }} variant="h2">{item.value}</Typography>))}
                  <Typography className={classes.description}>{item.label}</Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
    </div>
  );
}


export default StepStatusTimeline;
