enum CurriculumStatus {
  AwaitingXmlSignature = 'Aguardando assinatura do XML',
  Completed = 'Concluído',
  GeneratingCurriculumXml = 'Gerando XML do currículo digital',
  GeneratingVisualRepresentation = 'Gerando representação visual do currículo digital',
  IssuerConfirmationPending = 'Pendente de confirmação de emissão',
  Rejected = 'Reprovado',
  Synchronizing = 'Em sincronização',
  RestartingFlow = 'Reiniciando Fluxo'
}

export default CurriculumStatus;
