import { Grid, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Edit } from 'react-feather';
import { GRAY_COLOR, DIPLOMA_COLOR } from "../../../../../theme";
import CustomizedTooltip from "../../../atoms/customized-tooltip";
import { pageRoutes } from '../../../../utils/constants';
import { setEditModelCertificate, setListBrands } from '../../certificate-settings-model-page/store/actions';
import { ConnectedProps, connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { EditModelCertificateData } from '../types';
import { RootState } from '../../../../../setup/reducer/reducers';


const PREFIX = 'EditModelCertificateActions';

const classes = {
  icon: `${PREFIX}-icon`,
  activeIcon: `${PREFIX}-activeIcon`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.icon}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.25rem',
    height: '1.25rem',
    color: GRAY_COLOR,
    cursor: 'not-allowed',
  },

  [`& .${classes.activeIcon}`]: {
    cursor: 'pointer',
    color: DIPLOMA_COLOR,
  },
}));


const mapState = (state: RootState) => ({
  editModelsCertificate: state.editModelCertificatePage.editModelsCertificate,
})

const connector = connect(mapState);

type Props = ConnectedProps<typeof connector>;

function EditModelCertificateActions(props: EditModelCertificateData & Props) {
  const { type, editModelsCertificate } = props

  const history = useHistory();
  const dispatch = useDispatch()

  const navigateToUpdateModelCertificateOnClick = async () => {
    dispatch(await setListBrands());
    dispatch(await setEditModelCertificate(type, editModelsCertificate));
    history.push(pageRoutes.UpdateCertificateSettings);

  }

  return (
    <>
      <StyledGrid
        container
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item>
          <CustomizedTooltip title="Editar" placement="top">
            <IconButton size="large" onClick={navigateToUpdateModelCertificateOnClick}>
              <Edit className={`${classes.icon} ${classes.activeIcon}`} color={DIPLOMA_COLOR} />
            </IconButton>
          </CustomizedTooltip>

        </Grid>
      </StyledGrid>
    </>
  )
}

export default connector(EditModelCertificateActions);
