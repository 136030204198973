import React from 'react';
import { styled } from '@mui/material/styles';
import {
    Grid,
    Typography
} from '@mui/material';
import { DIPLOMA_COLOR } from '../../../../theme';
import ConfirmationModal from '../confirmation-modal';
import BackdropLoading from '../../molecules/backdrop-loading';
import SelectAutocomplete from '../../molecules/select-autocomplete';
import { RootState } from '../../../../setup/reducer/reducers';
import { ConnectedProps, connect, useDispatch, useSelector } from 'react-redux';
import { modalInformation, validation } from '../../../utils/constants';
import SignerData from '../../../../domain/model/institution/SignerData';
import { setDiplomas, setLoadingDiplomas } from '../../pages/diploma-list/store/actions';
import ChangeDiplomaDefaultSignerRequest from '../../../../domain/interface/request/ChangeDiplomaDefaultSignerRequest';
import ChangeType from '../../../../domain/enum/diploma/ChangeType';
import { changeDiplomaDefaultSigners, changeInstitutionDefaultSigners, setDefaultSignerModalSettingsAction, setModalSettingsAction } from './store/actions';
import ChangeInstitutionsDefaultSignersRequest from '../../../../domain/interface/request/ChangeInstitutionsDefaultSignersRequest';
import { setInstitutionsSigners, setLoadingInstitutionsSigners } from '../../pages/institution-management/store/actions';
import TypeSubmit from '../../../../domain/enum/TypeSubmit';

const PREFIX = 'DefaultSignersModal';

const classes = {
    spaced: `${PREFIX}-spaced`,
    helperIcon: `${PREFIX}-helperIcon`,
    descriptionLoading: `${PREFIX}-descriptionLoading`,
    circularProgress: `${PREFIX}-circularProgress`,
    grid: `${PREFIX}-grid`,
    texField: `${PREFIX}-texField`,
    subtitle: `${PREFIX}-subtitle`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.spaced}`]: {
        marginBottom: theme.spacing(4)
    },

    [`& .${classes.grid}`]: {
        width: '416px'
    },

    [`& .${classes.subtitle}`]: {
        color: DIPLOMA_COLOR,
        fontWeight: 'bold',
        fontSize: '14px'
    }
}));

const mapState = (state: RootState) => ({
    defaultSigners: state.institutionPage.defaultSigners,
    otherSigners: state.institutionPage.otherSigners
});

const connector = connect(mapState);

type Props = ConnectedProps<typeof connector> & {
    openModal: boolean;
};

const DefaultSignersModal = (props: Props) => {
    const {
        openModal
    } = props;
    const [isLoading, setLoading] = React.useState(false);
    const dispatch = useDispatch();

    const selectedInstitution = useSelector(
        (state: RootState) => state.header.institution
    );
    const {
        selectedInstitutionProfile,
        selectedDiplomaStatus
    } = useSelector((state: RootState) => state.diplomaFilter);

    const [validationError, setvalidationError] = React.useState(false);

    const defaultSignersIes = useSelector((state: RootState) => state.institutionPage.defaultSigners) || [];
    const otherSigners = useSelector((state: RootState) => state.institutionPage.otherSigners) || [];

    const typeSubmit = useSelector((state: RootState) => state.institutionPage.typeSubmit);
    const mecCode = useSelector((state: RootState) => state.institutionPage.mecCode);
    const diplomaId = useSelector((state: RootState) => state.institutionPage.diplomaId);

    const firstIssuerSigner = useSelector((state: RootState) => state.institutionPage.firstIssuerSigner);
    const secondIssuerSigner = useSelector((state: RootState) => state.institutionPage.secondIssuerSigner);
    const firstRegisterSigner = useSelector((state: RootState) => state.institutionPage.firstRegisterSigner);
    const secondRegisterSigner = useSelector((state: RootState) => state.institutionPage.secondRegisterSigner);

    const allSigners = [...otherSigners, ...defaultSignersIes]

    function buildChangeDefaultSignersRequest(signFor: string, signerPosition: number, signer?: SignerData): ChangeInstitutionsDefaultSignersRequest {
        const signerRequest: ChangeInstitutionsDefaultSignersRequest = {
            ...signer,
            signFor,
            signerPosition,
            mecCode: mecCode
        } as ChangeInstitutionsDefaultSignersRequest

        return signerRequest;
    }

    function buildChangeInstitutionDefaultSignersRequest(): ChangeInstitutionsDefaultSignersRequest[] {
        let changeInstitutionDefaultSignersRequest: ChangeInstitutionsDefaultSignersRequest[] = [];

        if (firstIssuerSigner) {
            const firstIssuerSignerRequest = buildChangeDefaultSignersRequest('issuer', 1, firstIssuerSigner)
            changeInstitutionDefaultSignersRequest.push(firstIssuerSignerRequest);
        }

        if (secondIssuerSigner) {
            const secondIssuerSignerRequest = buildChangeDefaultSignersRequest('issuer', 2, secondIssuerSigner)
            changeInstitutionDefaultSignersRequest.push(secondIssuerSignerRequest);
        }

        if (firstRegisterSigner) {
            const firstRegisterSignerRequest = buildChangeDefaultSignersRequest('register', 1, firstRegisterSigner)
            changeInstitutionDefaultSignersRequest.push(firstRegisterSignerRequest);
        }

        if (secondRegisterSigner) {
            const secondRegisterSignerRequest = buildChangeDefaultSignersRequest('register', 2, secondRegisterSigner)
            changeInstitutionDefaultSignersRequest.push(secondRegisterSignerRequest);
        }

        return changeInstitutionDefaultSignersRequest;
    }

    async function handleSubmit(): Promise<void> {
        setLoading(true)
        if (typeSubmit === TypeSubmit.DiplomaPage && !isLoading) {
            if (!firstIssuerSigner || !secondIssuerSigner || !firstRegisterSigner || !secondRegisterSigner) {
                setvalidationError(true);
            } else {
                setvalidationError(false)
                const changeRequest: ChangeDiplomaDefaultSignerRequest = {
                    defaultSigners: [
                        {
                            ...firstIssuerSigner,
                            signFor: 'issuer',
                            signerPosition: 1,
                        } as SignerData,
                        {
                            ...secondIssuerSigner,
                            signFor: 'issuer',
                            signerPosition: 2,
                        } as SignerData,
                        {
                            ...firstRegisterSigner,
                            signFor: 'register',
                            signerPosition: 1,
                        } as SignerData,
                        {
                            ...secondRegisterSigner,
                            signFor: 'register',
                            signerPosition: 2,
                        } as SignerData
                    ],
                    type: ChangeType.DefaultSignersUpdate
                }

                await dispatch(changeDiplomaDefaultSigners(changeRequest, diplomaId));

                await reloadDiplomas();
                dispatch(setLoadingDiplomas(false));
                setDefaultSignerUndefined();
            }
        }
        else if (typeSubmit === TypeSubmit.InstitutionManagementPage) {
            await dispatch(changeInstitutionDefaultSigners(buildChangeInstitutionDefaultSignersRequest()));
            await reloadInstitutionsSigners();
            dispatch(setLoadingInstitutionsSigners(false));
            setDefaultSignerUndefined();
        }
        setLoading(false)
    }

    async function reloadDiplomas(): Promise<any> {
        dispatch(setLoadingDiplomas(true));
        return dispatch(
            setDiplomas(
                setLoadingDiplomas,
                selectedDiplomaStatus,
                undefined,
                selectedInstitution,
                selectedInstitutionProfile
            )
        );
    };

    async function reloadInstitutionsSigners(): Promise<any> {
        dispatch(setLoadingInstitutionsSigners(true));
        return dispatch(setInstitutionsSigners());
    }

    function handleClickCancelButton(): void {
        setDefaultSignerUndefined();
        setvalidationError(false);
    }

    function setDefaultSignerUndefined(): void {
        dispatch(setDefaultSignerModalSettingsAction());
        dispatch(setModalSettingsAction(false, '', 0, ''));
    }

    function handleChangeFirstIssuerSigner(event: any): void {
        dispatch(setDefaultSignerModalSettingsAction(event, secondIssuerSigner, firstRegisterSigner, secondRegisterSigner));
    }
    function handleChangeSecondIssuerSigner(event: any): void {
        dispatch(setDefaultSignerModalSettingsAction(firstIssuerSigner, event, firstRegisterSigner, secondRegisterSigner));
    }
    function handleChangeFirstRegisterSigner(event: any): void {
        dispatch(setDefaultSignerModalSettingsAction(firstIssuerSigner, secondIssuerSigner, event, secondRegisterSigner));
    }
    function handleChangeSecondRegisterSigner(event: any): void {
        dispatch(setDefaultSignerModalSettingsAction(firstIssuerSigner, secondIssuerSigner, firstRegisterSigner, event));
    }

    return (
        <>
            <ConfirmationModal
                openModal={openModal}
                modalTitle={modalInformation.modalEditDefaultSigners.title}
                textContinueButton={modalInformation.modalEditDefaultSigners.textContinue}
                handleContinueButton={handleSubmit}
                textCancelButton={modalInformation.modalEditDefaultSigners.textCancel}
                handleCancelButton={handleClickCancelButton}
            >
                <StyledGrid
                    container
                    justifyContent="center"
                    alignItems="stretch"
                    className={classes.grid}
                >
                    {isLoading && (
                        <BackdropLoading
                            size={50}
                            description='Atualizando Assinantes...'
                            open={isLoading}
                            classes={classes}
                        />
                    )}
                    <Grid item>
                        <Typography className={classes.spaced}>
                            {modalInformation.modalEditDefaultSigners.description}
                        </Typography>
                        <Typography className={classes.subtitle} variant="caption">IES Emissora</Typography>
                        <SelectAutocomplete
                            listItems={allSigners}
                            defaultValue={firstIssuerSigner?.name}
                            label="Responsável pela 1º Assinatura"
                            placeholder="Nenhum responsável selecionado"
                            setItem={handleChangeFirstIssuerSigner}
                            data-testid="default-signer"
                            helperText={validationError ? validation.errorMessages.requiredField : ''}
                            error={validationError}
                        />
                        <Typography className={classes.spaced} />
                        <SelectAutocomplete
                            listItems={allSigners}
                            defaultValue={secondIssuerSigner?.name}
                            label="Responsável pela 2º Assinatura"
                            placeholder="Nenhum responsável selecionado"
                            setItem={handleChangeSecondIssuerSigner}
                            data-testid="default-signer"
                            helperText={validationError ? validation.errorMessages.requiredField : ''}
                            error={validationError}
                        />
                        <Typography className={classes.spaced} />
                        <Typography className={classes.subtitle} variant="caption">IES Registradora</Typography>
                        <SelectAutocomplete
                            listItems={allSigners}
                            defaultValue={firstRegisterSigner?.name}
                            label="Responsável pela 1º Assinatura"
                            placeholder="Nenhum responsável selecionado"
                            setItem={handleChangeFirstRegisterSigner}
                            data-testid="default-signer"
                            helperText={validationError ? validation.errorMessages.requiredField : ''}
                            error={validationError}
                        />
                        <Typography className={classes.spaced} />
                        <SelectAutocomplete
                            listItems={allSigners}
                            defaultValue={secondRegisterSigner?.name}
                            label="Responsável pela 2º Assinatura"
                            placeholder="Nenhum responsável selecionado"
                            setItem={handleChangeSecondRegisterSigner}
                            data-testid="default-signer"
                            helperText={validationError ? validation.errorMessages.requiredField : ''}
                            error={validationError}
                        />
                    </Grid>

                </StyledGrid>

            </ConfirmationModal>
        </>
    );
};

export default connector(DefaultSignersModal);
