import EditModelCertificate from '../../../../../domain/model/certificate/EditModelCertificate';

export const SET_LOADING_EDIT_MODELS =
  'app/certificate-page/SET_LOADING_EDIT_MODELS';
export const SET_SEARCH = 'app/certificate-page/SET_SEARCH';
export const SET_EDIT_MODELS_CERTIFICATE =
  'app/certificate-page/SET_EDIT_MODELS_CERTIFICATE';

export interface EditModelState {
  search: string;
  loadingEditModels: boolean;
  editModelsCertificate: EditModelCertificate[];
}

export const INITIAL_STATE: EditModelState = {
  search: '',
  loadingEditModels: false,
  editModelsCertificate: []
};

interface SetEditModelAction {
  type: typeof SET_EDIT_MODELS_CERTIFICATE;
  payload: {
    editModelsCertificate: Array<EditModelCertificate>;
  };
}

interface SetLoadingEditModelsAction {
  type: typeof SET_LOADING_EDIT_MODELS;
  payload: {
    loadingEditModels: boolean;
  };
}

interface SetSearchAction {
  type: typeof SET_SEARCH;
  payload: {
    search: string;
  };
}

export type EditModelsCertificateActions =
  | SetSearchAction
  | SetLoadingEditModelsAction
  | SetEditModelAction;
