import PermissionEmployeeDto from '../../../../../domain/interface/dto/PermissionEmployeeDto';
import { UsersParamsDataProps } from '../../users-management/types';

export const SET_LOADING_USERS = 'app/users-security/SET_INSTITUTION';
export const SET_USERS_SECURITY = 'app/users-security/SET_USERS_SECURITY';
export const SET_PERMISSIONS_USER = 'app/users-security/SET_PERMISSIONS_USER';
export const SET_LOADING_UPSERT = 'app/users-security/SET_LOADING_UPSERT';
export interface UsersSecurityState {
  users: UsersParamsDataProps;
  loadingUsers: boolean;
  loadingUpsert: boolean;
  permissions: PermissionEmployeeDto[];
}

export const INITIAL_STATE: UsersSecurityState = {
  users: {} as UsersParamsDataProps,
  loadingUsers: false,
  loadingUpsert: false,
  permissions: []
};

interface SetUsersSecurity {
  type: typeof SET_USERS_SECURITY;
  payload: {
    users: UsersParamsDataProps;
  };
}
interface SetPermissionsUser {
  type: typeof SET_PERMISSIONS_USER;
  payload: {
    permissions: PermissionEmployeeDto[];
  };
}

interface SetLoadingUsersSecurity {
  type: typeof SET_LOADING_USERS;
  payload: {
    loadingUsers: boolean;
  };
}
interface SetLoadingUpsert {
  type: typeof SET_LOADING_UPSERT;
  payload: {
    loadingUpsert: boolean;
  };
}

export type UsersSecurityActions =
  | SetUsersSecurity
  | SetLoadingUsersSecurity
  | SetPermissionsUser
  | SetLoadingUpsert;
