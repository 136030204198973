import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import React from 'react';
import { PublicDataWidgetProps } from './types';

const PREFIX = 'BasePublicDataWidget';

const classes = {
  spaced: `${PREFIX}-spaced`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.spaced}`]: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  }
}));

function BasePublicDataWidget({ title, children }: PublicDataWidgetProps): JSX.Element {


  return (
    <StyledGrid container direction="column">
      <Grid item>
        {title}
      </Grid>
      {children ? (
        <Grid container item className={classes.spaced} spacing={1} direction="column">
          {children}
        </Grid>
      ) : null}
    </StyledGrid>
  );
}

BasePublicDataWidget.defaultProps = {
  children: null,
};

export default BasePublicDataWidget;
