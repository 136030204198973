import CurricularUnity from '../../../domain/model/curriculum/CurricularUnity';
import { CurricularUnityAndValidations } from '../../components/organisms/curriculum-curricular-unities-paper/types';
import { Order } from '../../components/organisms/enhanced-table-head/types';
import { orderLoad, orderRows } from './table';

export function formatPrerequisites(curriculum: CurricularUnity): string {
  if (!curriculum.prerequisites) {
    return 'N/A';
  }
  return curriculum.prerequisites?.join(' - ');
}

export function handleRequestSort(
  property: string,
  orderBy: string,
  order: Order,
  setOrder: (order: Order) => void,
  setOrderBy: (property: string) => void
) {
  let newOrder: Order;

  if (orderBy === property) {
    newOrder = order === 'asc' ? 'desc' : 'asc';
  } else {
    newOrder = 'asc';
  }

  setOrder(newOrder);
  setOrderBy(property);
}

export function orderList(
  rows: CurricularUnityAndValidations[],
  order: Order,
  orderBy: string
) {
  let orderedRows;
  if (!rows) {
    return [];
  }
  if (orderBy === 'load') {
    orderedRows = orderLoad(order, rows);
  } else {
    orderedRows = orderRows(order, orderBy, rows);
  }

  return orderedRows;
}
