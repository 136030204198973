import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../setup/reducer/reducers';
import CoursePaper from '../../../organisms/course-paper';
import GraduatePaper from '../../../organisms/graduate-paper';
import InstitutionPaper from '../../../organisms/institution-paper';
import {
  selectDiploma,
  toggleInfoDiploma,
  toggleNextPage
} from '../../diploma-page/store/actions';
import { MEDIUM_GRAY_COLOR } from '../../../../../theme';
import InstitutionProfile from '../../../../../domain/enum/InstitutionProfile';
import DefaultTemplate from '../../../templates/default-template';
import DiplomaValidationButton from '../../../molecules/diploma-validation-button';
import AcademicDocumentationInformation from './AcademicDocumentationInformation';
import ChangesPaper from '../../../organisms/changes-paper';

const PREFIX = 'DiplomaInformation';

const classes = {
  root: `${PREFIX}-root`,
  fabIcon: `${PREFIX}-fabIcon`,
  errorColorFab: `${PREFIX}-errorColorFab`,
  successColorFab: `${PREFIX}-successColorFab`,
  primaryColorFab: `${PREFIX}-primaryColorFab`,
  fabGridRight: `${PREFIX}-fabGridRight`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    paddingBottom: theme.spacing(7)
  },

  [`& .${classes.fabIcon}`]: {
    marginRight: theme.spacing(1)
  },

  [`& .${classes.errorColorFab}`]: {
    backgroundColor: theme.palette.error.main,
    height: '44px',
    '&:hover': {
      opacity: 0.9,
      backgroundColor: theme.palette.error.main
    }
  },

  [`& .${classes.successColorFab}`]: {
    backgroundColor: theme.palette.success.main,
    height: '44px',
    '&:hover': {
      opacity: 0.9,
      backgroundColor: theme.palette.success.main
    },
    '&:disabled': {
      backgroundColor: MEDIUM_GRAY_COLOR
    }
  },

  [`& .${classes.primaryColorFab}`]: {
    backgroundColor: theme.palette.primary.main,
    height: '44px',
    '&:hover': {
      opacity: 0.9,
      backgroundColor: theme.palette.primary.main
    }
  },

  [`& .${classes.fabGridRight}`]: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(4),
    maxWidth: '45%'
  }
}));

export default function DiplomaInformation(): JSX.Element {
  const dispatch = useDispatch();
  const diploma = useSelector((state: RootState) => state.diplomaPage.diploma);
  const selectedInstitutionProfile = useSelector(
    (state: RootState) => state.diplomaFilter.selectedInstitutionProfile
  );
  const isNextPage = useSelector(
    (state: RootState) => state.diplomaPage.nextPage
  );
  const isLoadingDiploma = useSelector(
    (state: RootState) => state.diplomaPage.isLoadingDiploma
  );

  const clearDiploma = () => {
    dispatch(toggleInfoDiploma());
    dispatch(selectDiploma());
  };

  const nextPage = () => {
    dispatch(toggleNextPage());
  };

  const getPageAction = (): JSX.Element => {
    return (
      <DiplomaValidationButton
        handleClick={nextPage}
        text="Próximo"
        buttonType="primary"
      />
    );
  };

  return !isNextPage ? (
    <Root>
      <DefaultTemplate message="Informações do" highlightedMessage="Diploma">
        <ChangesPaper history={diploma?.history} />
        <Grid
          container
          spacing={2}
          className={classes.root}
          alignItems="stretch"
          direction="row"
        >
          <Grid item md={5}>
            <GraduatePaper
              graduate={diploma?.graduate}
              onClickBack={clearDiploma}
              isLoading={isLoadingDiploma}
              processNumber={diploma?.processNumber} originSystem={diploma?.originSystem}
            />
          </Grid>
          <Grid item md={7}>
            <CoursePaper
              course={diploma?.course}
              isLoading={isLoadingDiploma}
            />
          </Grid>
          <Grid item md={12}>
            <InstitutionPaper
              institution={diploma?.issuerInstitution}
              profile={InstitutionProfile.Issuer}
              isLoading={isLoadingDiploma}
            />
          </Grid>
          {selectedInstitutionProfile === InstitutionProfile.Register && (
            <Grid item md={12}>
              <InstitutionPaper
                institution={diploma?.registerInstitution}
                profile={InstitutionProfile.Register}
                isLoading={isLoadingDiploma}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={3}
          className={classes.fabGridRight}
        >
          {getPageAction()}
        </Grid>
      </DefaultTemplate>
    </Root>
  ) : (
    <AcademicDocumentationInformation />
  );
}
