import {
  CertificateSettingsModelActions,
  CertificateSettingsModelState,
  INITIAL_STATE,
  SET_CERTIFICATE_SETTINGS,
  SET_CERTIFICATE_TYPES,
  SET_LIST_BRANDS,
  SET_LOADING_BRANDS,
  SET_LOADING_CERTIFICATE_TYPE
} from './types';

export default function reducer(
  state: CertificateSettingsModelState = INITIAL_STATE,
  action: CertificateSettingsModelActions
): CertificateSettingsModelState {
  switch (action.type) {
    case SET_CERTIFICATE_TYPES:
      return {
        ...state,
        certificateTypes: action.payload.certificateTypes
      };
    case SET_LIST_BRANDS:
      return {
        ...state,
        brands: action.payload.brands
      };
    case SET_CERTIFICATE_SETTINGS:
      return {
        ...state,
        certificateSetting: action.payload.certificateSetting
      };
    case SET_LOADING_CERTIFICATE_TYPE:
      return {
        ...state,
        loadingCertificateType: action.payload.loadingCertificateType
      };
    case SET_LOADING_BRANDS:
      return {
        ...state,
        loadingBrands: action.payload.loadingBrands
      };
    default:
      return state;
  }
}
