import DefaultTemplate from "../../../templates/default-template";
import CertificateList from "./CertificateList";

const CertificatePage = () => {
  return (
    <DefaultTemplate>
      <CertificateList />
    </DefaultTemplate>
  );
}

export default CertificatePage;