import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

import { Timeline } from '@mui/icons-material';
import { DIPLOMA_COLOR } from '../../../../theme';
import CardTitle from '../card-title';
import ChangesTimeline from '../../organisms/changes-timeline';
import BasePublicDataWidget from '../base-public-data-widget';
import { PublicDiplomaHistoryWidgetProps } from './types';

const PREFIX = 'index';

const classes = {
  disclaimer: `${PREFIX}-disclaimer`
};

const StyledBasePublicDataWidget = styled(BasePublicDataWidget)((
  {
    theme
  }
) => ({
  [`& .${classes.disclaimer}`]: {
    marginBottom: theme.spacing(2),
  }
}));

export default function PublicDiplomaHistoryWidget({ diploma, hasDisclaimer }: PublicDiplomaHistoryWidgetProps): JSX.Element {


  return (
    <StyledBasePublicDataWidget title={(
      <CardTitle variant="h3" icon={<Timeline htmlColor={DIPLOMA_COLOR} />}>
        Histórico de Anulações
        {hasDisclaimer ? '*' : ''}
      </CardTitle>
    )}
    >
      <>
        <Grid item>
          <Typography className={classes.disclaimer} variant="body2">
            Este diploma se encontra
            {' '}
            {diploma?.status.toLowerCase()}
            . O histórico de anulações está descrito abaixo:
          </Typography>
        </Grid>
        <Grid container item>
          <ChangesTimeline history={diploma?.history ?? []} onPublicPortal />
        </Grid>
      </>
    </StyledBasePublicDataWidget>
  );
}
