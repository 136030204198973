import {
  CurriculumValidateActions,
  SET_CURRICULUM,
  SET_LOADING_CURRICULUM,
  CurriculumValidatePage,
  INITIAL_STATE,
  TOGGLE_IS_LOADING_CURRICULUM
} from './type';

export default function reducer(
  state: CurriculumValidatePage = INITIAL_STATE,
  action: CurriculumValidateActions
): CurriculumValidatePage {
  switch (action.type) {
    case SET_CURRICULUM:
      return {
        ...state,
        curriculum: action.payload.curriculum,
        validation: action.payload.validation
      };
    case SET_LOADING_CURRICULUM:
      return {
        ...state,
        loadingCurriculum: action.payload.loadingCurriculum
      };
    case TOGGLE_IS_LOADING_CURRICULUM:
      return {
        ...state,
        isLoadingCurriculum: !state.isLoadingCurriculum
      };
    default:
      return state;
  }
}
