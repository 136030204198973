import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DouRegisterBatchStatus from '../../../../domain/enum/dou/DouRegisterBatchStatus';
import CloseBatchRequest from '../../../../domain/interface/request/CloseBatchRequest';
import Dou from '../../../../domain/model/dou/Dou';
import { errorNotificationMessages, modalInformation, successNotificationMessages } from '../../../utils/constants';
import { closeBatch } from '../../pages/dou-list/DouFilterManager';
import { showNotification, showSuccessNotification } from '../../pages/notification/actions';
import ConfirmationModal from '../confirmation-modal';

interface CloseBatchModalProps {
  openModal: boolean,
  batch: Dou,
  onCloseModal: () => void,
  callbackAfterCloseBatch?: () => void,
}

export default function CloseBatchModal({
  openModal, batch, onCloseModal, callbackAfterCloseBatch,
}: Readonly<CloseBatchModalProps>) {
  const dispatch = useDispatch();
  const [internalOpenModal, setInternalOpenModal] = useState(false);
  const [loadingCloseBatchModal, setLoadingCloseBatchModal] = useState(false);

  useEffect(() => {
    setInternalOpenModal(openModal);
  }, [openModal]);

  const closeBatchService = async (institutionMecCode: number, batchNumber: number, type: string) => {
    try {
      const request: CloseBatchRequest = {
        number: batchNumber,
        batchType: type
      }
      const res: any = await closeBatch(institutionMecCode, request);
      if (res.success) {
        dispatch(showSuccessNotification(successNotificationMessages.successOnCloseBatch));
      } else {
        dispatch(showNotification(errorNotificationMessages.failedOnCloseBatch));
      }
    } catch (e) {
      dispatch(showNotification(errorNotificationMessages.failedOnCloseBatch));
    }
  };

  const handleToggleModal = () => {
    setInternalOpenModal(batch.status === DouRegisterBatchStatus.Opened && !(internalOpenModal));

    if (internalOpenModal) {
      onCloseModal();
    }
  };

  const handleCloseBatch = async (institutionMecCode: number, batchNumber: number, type: string) => {
    if (batch.status === DouRegisterBatchStatus.Opened) {
      setLoadingCloseBatchModal(true);
      await closeBatchService(institutionMecCode, batchNumber, type);
      setLoadingCloseBatchModal(false);
      handleToggleModal();
      if (callbackAfterCloseBatch) {
        callbackAfterCloseBatch();
      }
    }
  };
  return (
    <Grid item xs={4}>
      <ConfirmationModal
        openModal={internalOpenModal}
        modalTitle={modalInformation.modalCloseBatch.description}
        textContinueButton={modalInformation.modalCloseBatch.textContinue}
        handleContinueButton={() => handleCloseBatch(batch.institutionMecCode, batch.batchNumber, batch.type)}
        textCancelButton={modalInformation.modalCloseBatch.textCancel}
        handleCancelButton={handleToggleModal}
        loading={loadingCloseBatchModal}
        loadingMessage={loadingCloseBatchModal ? modalInformation.modalCloseBatch.textOnLoading : ''}
      />
    </Grid>
  );
}

CloseBatchModal.defaultProps = { callbackAfterCloseBatch: () => { }, };

