export const userStorageKey = 'diplomas-user-app';

export const authorizations = {
  login: 'diplomas',
  showSignDocuments:
    'diplomas.ui.{institutionMecCode}.{campusEmecCode}.showSignDocuments'
};

export const notificationIcons = {
  error: 'DefaultError',
  tryAgain: 'TryAgain',
  success: 'CheckCircle'
};

export const successNotificationMessages = {
  defaultSuccess: 'Ação executada com sucesso!',
  successToRefuseDiploma: 'Diploma recusado e retornado para a IES Emissora.',
  successToSendDiplomaToSignature:
    'Diploma encaminhado para assinatura com sucesso!',
  successToSendCertificateToSignature:
    'Certificado encaminhado para assinatura com sucesso!',
  successToSendCurriculumToSignature:
    'Currículo encaminhado para assinatura com sucesso!',
  successOnCloseBatch: 'Lote fechado com sucesso!',
  successCopyToClipboard: 'Texto copiado com sucesso!',
  successUpdateBatch: 'Atualização feita com sucesso!',
  successSync: 'Sincronização feita com sucesso!',
  successUpdateEmployee: 'Usuário atualizado com sucesso!',
  successInsertEmployee: 'Usuário cadastrado com sucesso!',
  sucessInsertingCertificateBatch: 'Arquivo carregado com sucesso!'
};

export const errorNotificationMessages = {
  defaultError: 'Ocorreu um erro. Por favor, tente novamente.',
  failedToLogin:
    'Ocorreu um erro ao tentar realizar o login no sistema, verifique seus dados e tente novamente.',
  failedToRetrieveDiploma:
    'Ocorreu um erro ao tentar obter os dados do diploma.',
  failedToValidateDiploma: 'Ocorreu um erro ao tentar validar o diploma.',
  failedToRefuseDiploma: 'Ocorreu um erro ao tentar recusar o diploma.',
  thereMustBeSelectedIesToProceed:
    'Ocorreu um erro pois não há IES selecionada.',
  failedToRetrieveIesRegulatoryActs:
    'Ocorreu um erro ao tentar recuperar atos regulatórios da IES.',
  failedToSendDiplomaToSignature: 'Falha ao enviar diploma para assinatura.',
  failedToSendCertificateToSignature:
    'Falha ao enviar o certificado para assinatura.',
  failedToSendCurriculumToSignature:
    'Falha ao enviar o currículo para assinatura.',
  failedOnCloseBatch:
    'Ocorreu um erro ao fechar o lote. Por favor, tente novamente.',
  failedUpdateBatch: 'Ocorreu um erro ao atualizar o lote',
  failedOnUpdateDouPage:
    'Ocorreu um erro ao atualizar os dados da página. Por favor, atualize a página.',
  failedOnRetrieveTextPublicationDouTemplate:
    'Modelo do texto de publicação não encontrado. Por favor, tente novamente.',
  failedToValidateCertificate:
    'Ocorreu um erro ao tentar validar o certificado.',
  failedToSyncDiploma: 'Ocorreu um erro para sincronizar',
  faileldInsertEmployeeCpf: 'Erro ao cadastrar: CPF informado já existe',
  faileldInsertEmployeeEmail: 'Erro ao cadastrar: E-mail informado já existe',
  faileldUpdateEmployeeEmail:
    'Erro ao editar: Múltiplos usuários com este e-mail',
  failedUpdatingEmployeeCpf: 'Erro ao editar: Múltiplos usuários com este CPF',
  faileldInsertingCertificateBatch: 'Erro ao carregar o arquivo.',
  failedToSyncCurriculum: 'Ocorreu um erro para sincronizar o(s) currículo(s).'
};

export const warningNotificationMessages = {
  batchDoesNotHaveDiplomas: 'Esse lote não possui diplomas registrados'
};

export const welcomeMessage = {
  goodMorning: 'Bom dia,',
  goodEvening: 'Boa tarde,',
  goodNight: 'Boa noite,'
};

export const modalInformation = {
  textCancelButton: 'Cancelar',
  modalExit: {
    title: 'Deseja Sair?',
    textContinueButton: 'Sair'
  },
  modalRefuse: {
    title: 'Recusar Diploma?',
    textContinueButton: 'Confirmar'
  },
  modalSendToSignature: {
    title: 'Envio Para Assinatura',
    description: 'Por favor, preencha os dados do responsável pelo registro.',
    textContinueButton: 'Enviar',
    signerPropertiesPlaceholder: 'Selecione um responsável',
    form: {
      name: {
        label: 'Nome',
        placeholder: 'Digite o nome...'
      },
      sex: {
        label: 'Sexo',
        placeholder: 'Selecione o sexo...',
        values: {
          female: 'Feminino',
          male: 'Masculino',
          empty: 'Selecione o sexo...'
        }
      },
      cpf: {
        label: 'CPF',
        placeholder: 'Digite o CPF...'
      },
      position: {
        label: 'Cargo ou função',
        placeholder: 'Digite o cargo ou função...'
      },
      registerNumber: {
        label: 'ID ou Matrícula',
        placeholder: 'ID ou Matrícula...'
      }
    }
  },
  modalEditCertificate: {
    title: 'Você tem certeza que deseja salvar as alterações?',
    loadingMessage: 'Salvando Alterações do Certificado',
    textContinueButton: 'Salvar',
    validationTextContinueButton: 'Salvar mesmo assim',
    validationError: 'Existem campos inválidos, deseja continuar mesmo assim?'
  },
  modalClearEditCertificate: {
    title: 'Deseja sair sem salvar as alterações?',
    textContinueButton: 'Sim'
  },
  modalSendCertificateToSignature: {
    title: 'Envio do Certificado Para Assinatura',
    description:
      'Por favor, selecione o template do certificado e o responsável pela assinatura do registro.',
    loadingMessage: 'Enviando Certificado para Assinatura',
    textContinueButton: 'Enviar'
  },
  modalSendDiplomaToSignature: {
    title: 'Envio do Diploma Para Assinatura',
    loadingMessage: 'Enviando Diploma para Assinatura'
  },
  modalCloseBatch: {
    title: 'Confirmar ação',
    description: 'Você realmente deseja fechar esse lote?',
    textContinue: 'Sim, fechar lote',
    textCancel: 'Cancelar',
    textOnLoading: 'Realizando o fechamento do lote...'
  },
  modalCancel: {
    title: 'Anulação do Diploma',
    description: 'Por favor, preencha as informações abaixo.',
    textContinue: 'Anular Diploma',
    textCancel: 'Fechar'
  },
  modalRejectCurriculum: {
    title: 'Reprovar currículo',
    description:
      'Descreva brevemente o motivo pelo qual esse currículo não deve ser aprovado.',
    textContinue: 'Confirmar',
    textCancel: 'Cancelar'
  },
  modalRestartDiplomaFlow: {
    title: 'Cancelamento do Diploma',
    description: 'Por favor, preencha as informações abaixo.',
    textContinue: 'Cancelar Diploma',
    textCancel: 'Fechar',
    validationError: 'É necessário incluir um motivo de cancelamento'
  },
  modalCancelCertificate: {
    title: 'Anulação do Certificado',
    textContinue: 'Anular Certificado',
    textCancel: 'Fechar'
  },
  modalActivate: {
    title: 'Deseja ativar o Diploma?',
    textContinue: 'Ativar Diploma',
    textCancel: 'Fechar'
  },
  modalDeleteTeacher: {
    textContinue: 'Remover',
    textCancel: 'Cancelar',
    title: 'Remover Docente?'
  },
  modalEditTeacher: {
    title: 'Editar Docente',
    textContinue: 'Aplicar',
    textCancel: 'Cancelar'
  },
  modalEditDefaultSigners: {
    title: 'Alterar Assinantes Padrão',
    description:
      'Por favor, selecione os responsáveis pelas assinaturas da IES.',
    textContinue: 'Salvar',
    textCancel: 'Cancelar'
  },
  modalEditDou: {
    title: 'Adicionar Data de Publicação e Número',
    subTitle: 'do DOU',
    textContinue: 'Salvar',
    textCancel: 'Cancelar'
  },
  modalEditUsers: {
    title: 'Edição de Usuário',
    newTitle: 'Novo Usuário',
    description: 'Por favor, preencha as informações do usuário abaixo.',
    textContinue: 'Salvar Alterações',
    textCancel: 'Cancelar',
    animaEmail: 'E-mail inválido e/ou não é do domínio "animaeducacao"'
  },
  modalCertificateFile: {
    title: 'Confirmação de Upload',
    textContinue: 'Confirmar',
    textCancel: 'Cancelar'
  },
  modalCancelCurriculum: {
    title: 'Cancelamento do Currículo',
    textAbort: 'Fechar',
    textConfirm: 'Cancelar Currículo',
    textLoading: 'Cancelando Currículo...',
    textDescription: 'Por favor, preencha as informações abaixo.',
    textReasaonSelectionLabel: 'Motivo do cancelamento',
    textReasonSelectionPlaceholder:'Nenhuma situação selecionada',
  }
};

export const validation = {
  errorMessages: {
    requiredField: 'O campo é obrigatório',
    invalidCpf: 'O CPF é inválido',
    invalidCnpj: 'O CNPJ é inválido',
    invalidDate: 'A data é inválida',
    default: 'O campo é inválido',
    mustBeInteger: 'Deve ser um número inteiro',
    mustHaveSevenNumbers: 'O campo deve possuir sete dígitos numéricos',
    mustHaveEightNumbers: 'O campo deve possuir oito dígitos numéricos',
    invalidDiplomaValidationCode: 'Insira um código válido'
  },
  infoMessages: {
    changesHasNotBeenSaved: 'Alterações não salvas'
  }
};

export const TIMEZONE = 'America/Sao_Paulo';

export const pageRoutes = {
  Base: '/',
  Login: '/login',
  LoginMicrosoft: '/login-microsoft',
  Logout: '/logout',
  DiplomaPage: '/diplomas',
  DiplomaDashboard: '/dashboard',
  DiplomaManagement: '/management',
  DouPage: '/dou',
  PublicPortal: '/public-portal',
  InstitutionManagement: '/institution/management',
  UsersManagement: '/users/management',
  Certificate: '/certificate',
  IssuanceCertificate: '/certificate/issuance',
  SettingsCertificate: '/certificate/settings',
  CreateCertificateSettings: '/certificate/settings/create',
  UpdateCertificateSettings: '/certificate/settings/update',
  CertificateInfo: '/certificate/info',
  InstitutionInfo: '/institution/info',
  UsersSecurity: '/users/security',
  DigitalCurriculum: '/curriculum',
  DigitalCurriculumValidate: '/curriculum/validate',
  DigitalCurriculumInfo: '/curriculum/info'
};
