import moment, { Moment } from 'moment';
import InspectionDocumentContext from '../domain/enum/inspectionDocument/InspectionDocumentContext';
import InspectionDocumentResponse from '../domain/interface/response/InspectionDocumentResponse';
import InspectionDocumentStatus from '../domain/enum/inspectionDocument/InspectionDocumentStatus';
import GetListFiscalizationFileResponse from '../domain/interface/response/GetListFiscalizationFileResponse';

export default class InspectionDocumentService {
  async createInspectionDocument(
    institutionId: number,
    startDate: Moment,
    endDate: Moment,
    context: InspectionDocumentContext
  ): Promise<InspectionDocumentResponse> {
    console.log({
      institutionId,
      startDate: startDate.format('DD/MM/YYYY'),
      endDate: endDate.format('DD/MM/YYYY'),
      context
    });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return {
      documentCode: 'AF001',
      processStartDate: moment().format('DD/MM/YYYY'),
      context,
      status: InspectionDocumentStatus.Generated
    };
  }
  async fetchInspectionDocuments(
    institutionId: number,
    startDate?: Moment,
    endDate?: Moment
  ): Promise<GetListFiscalizationFileResponse[]> {
    const mockData: GetListFiscalizationFileResponse[] = [
      {
        documentCode: 'doc1',
        processStartDate: '2022/01/01',
        status: InspectionDocumentStatus.Generated,
        startDate: '2022/01/01',
        endDate: '2022/01/31',
        user: 'user1',
        context: InspectionDocumentContext.Issuer,
        linkDownloadDocument: 'www.asdfasfsdfa.com.br',
        base64Document: 'asdfasf3014i1234'
      },
      {
        documentCode: 'doc2',
        processStartDate: '2022/02/01',
        status: InspectionDocumentStatus.Error,
        startDate: '2022/02/01',
        endDate: '2022/02/28',
        user: 'Gabriel Arthur',
        context: InspectionDocumentContext.Register
      },
      {
        documentCode: 'doc3',
        processStartDate: '2022/03/01',
        status: InspectionDocumentStatus.Processing,
        startDate: '2022/03/01',
        endDate: '2022/03/31',
        user: 'Rodrigo Rodriguez',
        context: InspectionDocumentContext.Issuer
      },
      {
        documentCode: 'doc4',
        processStartDate: '2022/04/01',
        status: InspectionDocumentStatus.Processing,
        startDate: '2022/04/01',
        endDate: '2022/04/30',
        user: 'user4',
        context: InspectionDocumentContext.Issuer
      },
      {
        documentCode: 'doc5',
        processStartDate: '2022/05/01',
        status: InspectionDocumentStatus.Error,
        startDate: '2022/05/01',
        endDate: '2022/05/31',
        user: 'user5',
        context: InspectionDocumentContext.Register
      },
      {
        documentCode: 'doc6',
        processStartDate: '2022/01/01',
        status: InspectionDocumentStatus.Generated,
        startDate: '2022/01/01',
        endDate: '2022/01/31',
        user: 'user1',
        context: InspectionDocumentContext.Issuer,
        linkDownloadDocument: 'www.asdfasfsdfa.com.br',
        base64Document: 'asdfasf3014i1234'
      },
      {
        documentCode: 'doc7',
        processStartDate: '2022/01/01',
        status: InspectionDocumentStatus.Generated,
        startDate: '2022/01/01',
        endDate: '2022/01/31',
        user: 'user1',
        context: InspectionDocumentContext.Issuer,
        linkDownloadDocument: 'www.asdfasfsdfa.com.br',
        base64Document: 'asdfasf3014i1234'
      },
      {
        documentCode: 'doc8',
        processStartDate: '2022/01/01',
        status: InspectionDocumentStatus.Generated,
        startDate: '2022/01/01',
        endDate: '2022/01/31',
        user: 'user1',
        context: InspectionDocumentContext.Issuer,
        linkDownloadDocument: 'www.asdfasfsdfa.com.br',
        base64Document: 'asdfasf3014i1234'
      },
      {
        documentCode: 'doc9',
        processStartDate: '2022/01/01',
        status: InspectionDocumentStatus.Generated,
        startDate: '2022/01/01',
        endDate: '2022/01/31',
        user: 'user1',
        context: InspectionDocumentContext.Issuer,
        linkDownloadDocument: 'www.asdfasfsdfa.com.br',
        base64Document: 'asdfasf3014i1234'
      }
    ];
    return mockData;
  }
}
