import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  errorNotificationMessages,
  modalInformation,
  notificationIcons,
  successNotificationMessages
} from '../../../../utils/constants';
import { DIPLOMA_COLOR } from '../../../../../theme';
import ChangeDiplomaRequest from '../../../../../domain/interface/request/ChangeDiplomaRequest';
import { RootState } from '../../../../../setup/reducer/reducers';
import InstitutionProfile from '../../../../../domain/enum/InstitutionProfile';
import DiplomaService from '../../../../../services/DiplomaService';
import BackdropLoading from '../../../molecules/backdrop-loading';
import {
  selectDiploma,
  toggleInfoDiploma,
  toggleNextPage
} from '../../diploma-page/store/actions';
import {
  setDiplomas,
  setLoadingDiplomas
} from '../../diploma-list/store/actions';
import ConfirmationModal from '../../../organisms/confirmation-modal';
import {
  showNotification,
  showSuccessNotification
} from '../../notification/actions';
import ChangeType from '../../../../../domain/enum/diploma/ChangeType';

const PREFIX = 'ActivateDiploma';

const classes = {
  spaced: `${PREFIX}-spaced`,
  descriptionLoading: `${PREFIX}-descriptionLoading`,
  circularProgress: `${PREFIX}-circularProgress`,
  grid: `${PREFIX}-grid`,
  texField: `${PREFIX}-texField`
};

const StyledConfirmationModal = styled(ConfirmationModal)(({ theme }) => ({
  [`& .${classes.spaced}`]: {
    marginBottom: theme.spacing(4)
  },

  [`& .${classes.descriptionLoading}`]: {
    width: '19.938rem',
    color: DIPLOMA_COLOR
  },

  [`& .${classes.circularProgress}`]: {
    color: DIPLOMA_COLOR
  },

  [`& .${classes.grid}`]: {
    width: '416px'
  },

  [`& .${classes.texField}`]: {
    width: '400px'
  }
}));

type Props = {
  setOpenModal: (flag: boolean) => void;
  openModal: boolean;
  id?: string;
};

const ActivateDiploma = (props: Props) => {
  const { openModal, id, setOpenModal } = props;

  const dispatch = useDispatch();

  const [isLoading, setLoading] = React.useState(false);

  const {
    selectedInstitutionProfile,
    selectedDiplomaStatus,
    selectedIssuerInstitution
  } = useSelector((state: RootState) => state.diplomaFilter);
  const selectedInstitution = useSelector(
    (state: RootState) => state.header.institution
  );
  const { info, nextPage } = useSelector(
    (state: RootState) => state.diplomaPage
  );

  async function reloadDiplomas(): Promise<any> {
    dispatch(setLoadingDiplomas(true));
    if (selectedInstitutionProfile === InstitutionProfile.Register) {
      return dispatch(
        setDiplomas(
          setLoadingDiplomas,
          selectedDiplomaStatus,
          selectedInstitution,
          selectedIssuerInstitution,
          selectedInstitutionProfile
        )
      );
    }
    return dispatch(
      setDiplomas(
        setLoadingDiplomas,
        selectedDiplomaStatus,
        undefined,
        selectedInstitution,
        selectedInstitutionProfile
      )
    );
  }

  function getChangeType(): ChangeType {
    if (selectedInstitutionProfile === InstitutionProfile.Issuer) {
      return ChangeType.IssuerInstitutionActivatesDiploma;
    }
    return ChangeType.RegisterInstitutionActivatesDiploma;
  }

  async function handleSubmit(): Promise<void> {
    setLoading(true);
    const changeRequest: ChangeDiplomaRequest = {
      type: getChangeType()
    };
    try {
      await DiplomaService.addChangeDiploma(changeRequest, id);
      dispatch(
        showSuccessNotification(
          successNotificationMessages.defaultSuccess,
          notificationIcons.success
        )
      );
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
    }
    setLoading(false);
    setOpenModal(false);
    await reloadDiplomas();
    dispatch(setLoadingDiplomas(false));
    dispatch(selectDiploma());
    if (info) {
      dispatch(toggleInfoDiploma());
    }
    if (nextPage) {
      dispatch(toggleNextPage());
    }
  }

  function handleClickCancelButton(): void {
    setOpenModal(false);
  }

  return (
    <StyledConfirmationModal
      openModal={openModal}
      modalTitle={modalInformation.modalActivate.title}
      textContinueButton={modalInformation.modalActivate.textContinue}
      handleContinueButton={handleSubmit}
      textCancelButton={modalInformation.modalActivate.textCancel}
      handleCancelButton={handleClickCancelButton}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="stretch"
        className={classes.grid}
      >
        {isLoading && (
          <BackdropLoading
            size={30}
            description="Ativando Diploma..."
            open={isLoading}
            classes={classes}
          />
        )}
      </Grid>
    </StyledConfirmationModal>
  );
};

ActivateDiploma.defaultProps = {
  id: undefined
};

export default ActivateDiploma;
