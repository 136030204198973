enum CompletedActivity {
  CourseExtensive = 'Curso de Extensão',
  Discipline = 'Disciplina',
  Nanodegree = 'Nanodegree',
  Lecture = 'Palestra',
  MonitoringProgram = 'Programa de Monitoria Acadêmica na Unidade Curricular',
  CurricularUnit = 'Unidade Curricular',
  LeadingProjectInCurricularUnit = 'Projeto Liderando na Unidade Curricular',
  Module = 'Módulo',
  ExtensionEvent = 'Evento de Extensão',
  ExtensionProject = 'Projeto de Extensão'
}

export default CompletedActivity;
