import Diploma from '../../../../../domain/model/diploma/Diploma';

export const SELECT_DIPLOMA = 'app/diploma-page/SELECT_DIPLOMA';
export const TOGGLE_INFO_DIPLOMA = 'app/diploma-page/TOGGLE_INFO_DIPLOMA';
export const TOGGLE_NEXT_PAGE = 'app/diploma-page/TOGGLE_NEXT_PAGE';
export const TOGGLE_IS_LOADING_DIPLOMA = 'app/diploma-page/IS_LOADING_DIPLOMA';
export const UPDATE_COMPONENT_VALIDATE_ACTIVE =
  'app/diploma-page/UPDATE_COMPONENT_VALIDATE_ACTIVE';

export interface DiplomaPageValidateState {
  diploma?: Diploma;
  validation?: any;
  info: boolean;
  nextPage: boolean;
  isLoadingDiploma: boolean;
  componentValidateActive: string;
}

export const INITIAL_STATE: DiplomaPageValidateState = {
  diploma: undefined,
  validation: undefined,
  info: false,
  nextPage: false,
  isLoadingDiploma: false,
  componentValidateActive: ''
};

interface SelectDiplomaAction {
  type: typeof SELECT_DIPLOMA;
  payload: {
    diploma?: Diploma;
    validation?: any;
  };
}

interface ToggleInfoDiplomaAction {
  type: typeof TOGGLE_INFO_DIPLOMA;
}

interface ToggleNextPageDiplomaAction {
  type: typeof TOGGLE_NEXT_PAGE;
}

interface UpdateComponentValidateActiveAction {
  type: typeof UPDATE_COMPONENT_VALIDATE_ACTIVE;
  payload: {
    componentValidateActive: string;
  };
}

interface ToggleIsLoadingDiplomaAction {
  type: typeof TOGGLE_IS_LOADING_DIPLOMA;
}

export type DiplomaPageValidateActions =
  | SelectDiplomaAction
  | ToggleNextPageDiplomaAction
  | ToggleIsLoadingDiplomaAction
  | ToggleInfoDiplomaAction
  | UpdateComponentValidateActiveAction;
