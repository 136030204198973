import SignerData from '../../../../../domain/model/institution/SignerData';

export const SET_SIGNERS = 'app/institution-signers/SET_SIGNERS';
export const SET_DEFAULT_SIGNER_MODAL_SETTINGS =
  'app/institution-signers/SET_DEFAULT_SIGNER_MODAL_SETTINGS';
export const SET_MODAL_SETTINGS = 'app/institution-signers/SET_MODAL_SETTINGS';

export interface SignersState {
  defaultSigners?: SignerData[];
  otherSigners?: SignerData[];
  diplomaId?: string;
  openDefaultSignersModal: boolean;
  typeSubmit: string;
  mecCode: number;
  firstIssuerSigner?: SignerData;
  secondIssuerSigner?: SignerData;
  firstRegisterSigner?: SignerData;
  secondRegisterSigner?: SignerData;
}

export interface ModalSettingsState {}

export const INITIAL_STATE: SignersState = {
  defaultSigners: [],
  otherSigners: [],
  diplomaId: '',
  openDefaultSignersModal: false,
  typeSubmit: '',
  mecCode: 0,
  firstIssuerSigner: undefined,
  secondIssuerSigner: undefined,
  firstRegisterSigner: undefined,
  secondRegisterSigner: undefined
};

interface SetSignersAction {
  type: typeof SET_SIGNERS;
  payload: {
    defaultSigners?: SignerData[];
    otherSigners?: SignerData[];
  };
}

interface SetModalSetting {
  type: typeof SET_MODAL_SETTINGS;
  payload: {
    diplomaId: string | undefined;
    openDefaultSignersModal: boolean;
    typeSubmit: string;
    mecCode: number;
  };
}

interface SetDefaultSignerModalSettings {
  type: typeof SET_DEFAULT_SIGNER_MODAL_SETTINGS;
  payload: {
    firstIssuerSigner?: SignerData;
    secondIssuerSigner?: SignerData;
    firstRegisterSigner?: SignerData;
    secondRegisterSigner?: SignerData;
  };
}

export type DiplomaModalDefaultSignersActions =
  | SetSignersAction
  | SetModalSetting
  | SetDefaultSignerModalSettings;
