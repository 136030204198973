import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Fab, Typography, Zoom,
} from '@mui/material';
import { ChevronUp } from 'react-feather';
import { BackToTopButtonProps } from './types';

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`
};

const StyledZoom = styled(Zoom)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }
}));

export default function BackToTopButton({ showBelow }: BackToTopButtonProps): JSX.Element {


  const [show, setShow] = useState(false);

  function scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > showBelow && !show) {
        setShow(true);
        return;
      }

      if (window.scrollY < showBelow && show) {
        setShow(false);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [show, showBelow]);

  return (
    <StyledZoom in={show}>
      <Fab
        color="primary"
        className={classes.root}
        variant="extended"
        size="medium"
        component="button"
        onClick={scrollToTop}
      >
        <ChevronUp />
        <Typography variant="button">Voltar ao Topo</Typography>
      </Fab>
    </StyledZoom>
  );
}
