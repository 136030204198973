import {
  BatchPageState,
  SELECT_BATCH,
  DouPageActions,
  TOGGLE_INFO_BATCH,
  UPDATE_BATCH
} from './types';

export const INITIAL_STATE: BatchPageState = {
  batch: undefined,
  info: false
};

export default function reducer(
  state: BatchPageState = INITIAL_STATE,
  action: DouPageActions
): BatchPageState {
  switch (action.type) {
    case SELECT_BATCH:
    case UPDATE_BATCH:
      return {
        ...state,
        batch: action.payload.batch
      };
    case TOGGLE_INFO_BATCH:
      return {
        ...state,
        info: !state.info
      };
    default:
      return state;
  }
}
