import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { DIPLOMA_VALIDATION_PASSIVE_ACTION } from '../../../../theme';

const usePublicationDouModalStyles = makeStyles((theme: Theme) => ({
  actButton: {
    color: DIPLOMA_VALIDATION_PASSIVE_ACTION,
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '16px 26px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500]
  },
  iconTooltip: {
    marginRight: '.5rem'
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.146rem',
    height: '1.146rem'
  },
  gridChildModal: {
    width: '622px',
    marginLeft: theme.spacing(1)
  },
  registerGridModal: {
    maxHeight: '29rem'
  },
  cancellationGridModal: {
    maxHeight: '36.563rem'
  }
}));

export default usePublicationDouModalStyles;
