import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DiplomaStatus from '../../../../../domain/enum/diploma/DiplomaStatus';
import InstitutionProfile from '../../../../../domain/enum/InstitutionProfile';
import { RootState } from '../../../../../setup/reducer/reducers';
import CardNotification from '../../../organisms/card-notification';
import PageTitle from '../../../molecules/page-title';
import { StepProp } from '../../../molecules/step-status-timeline/types';
import { getDefaultGreetingMessage } from '../../../../utils/functions';
import { DIPLOMA_SUCCESS_MAIN_COLOR } from '../../../../../theme';
import DiplomaInformation from '../../diploma-info/views/DiplomaInformation';
import DiplomaList from '../../diploma-list/views/DiplomaList';
import { getAwaitingSignatureCount } from '../../diploma-management/DiplomaManagementManager';
import DiplomaValidate from '../../diploma-validate/views/DiplomaValidate';
import StatusTimeline from '../../../organisms/status-timeline';
import DefaultSignersModal from '../../../organisms/default-signers-modal';

const urlSigner = process.env.REACT_APP_DIPLOMA_SIGNER_URL;

const stepsData: StepProp[] = [
  {
    label: DiplomaStatus.IssuerConfirmationPending.toUpperCase(),
    helperText: 'MANUAL',
    icon: 'E'
  },
  {
    label: DiplomaStatus.GeneratingIssuerDocuments.toUpperCase(),
    icon: 'E'
  },
  {
    label: DiplomaStatus.GeneratingAcademicDocumentation.toUpperCase(),
    icon: 'E'
  },
  {
    label: DiplomaStatus.AwaitingIssuerSignature.toUpperCase(),
    helperText: 'MANUAL*',
    icon: 'E'
  },
  {
    label: DiplomaStatus.RegisterConfirmationPending.toUpperCase(),
    helperText: 'MANUAL',
    icon: 'R'
  },
  {
    label: DiplomaStatus.AwaitingRegister.toUpperCase(),
    icon: 'R'
  },
  {
    label: DiplomaStatus.GeneratingRegisterDocuments.toUpperCase(),
    icon: 'R'
  },
  {
    label: DiplomaStatus.AwaitingRegisterSignature.toUpperCase(),
    helperText: 'MANUAL*',
    icon: 'R'
  },
  {
    label: DiplomaStatus.GeneratingVisualRepresentation.toUpperCase(),
    icon: 'E'
  },
  {
    label: DiplomaStatus.Completed.toUpperCase(),
    color: DIPLOMA_SUCCESS_MAIN_COLOR,
    icon: 'Check'
  }
];

const DiplomaPage = () => {
  const hasSelectedDiploma = useSelector(
    (state: RootState) => state.diplomaPage.diploma !== undefined
  );
  const isLoadingDiploma = useSelector(
    (state: RootState) => state.diplomaPage.isLoadingDiploma
  );
  const selectedInstitution = useSelector(
    (state: RootState) => state.header.institution
  );
  const selectedInstitutionProfile = useSelector(
    (state: RootState) => state.diplomaFilter.selectedInstitutionProfile
  );
  const user = useSelector((state: RootState) => state.login.user);
  const employee = useSelector(
    (state: RootState) => state.authorization.employee
  );
  const isInfoMode = useSelector((state: RootState) => state.diplomaPage.info);

  const pageTitle = getDefaultGreetingMessage(user?.name);

  const [quantity, setQuantity] = useState<number>(0);

  useEffect(() => {
    async function fetchAwaitingSignature() {
      if (selectedInstitution?.mecCode) {
        const awaitingSignatureCount = await getAwaitingSignatureCount(
          selectedInstitution.mecCode
        );
        setQuantity(
          selectedInstitutionProfile === InstitutionProfile.Issuer
            ? awaitingSignatureCount.awaitingIssuerSignature
            : awaitingSignatureCount.awaitingRegisterSignature
        );
      }
    }
    if (employee?.canSignDocuments) {
      setQuantity(0);
      fetchAwaitingSignature();
    }
  }, [employee, selectedInstitution, selectedInstitutionProfile]);

  const openDefaultSignersModal = useSelector(
    (state: RootState) => state.institutionPage.openDefaultSignersModal
  );

  if (!hasSelectedDiploma && !isLoadingDiploma) {
    return (
      <>
        <CardNotification quantity={quantity} link={urlSigner} />
        <DefaultSignersModal
          openModal={openDefaultSignersModal}
        />
        <PageTitle
          message={pageTitle.message}
          highlightedMessage={pageTitle.highlightedMessage}
        />
        {selectedInstitutionProfile === InstitutionProfile.Issuer && (
          <StatusTimeline
            title="Etapas do Fluxo de Validação de Diplomas"
            stepsData={stepsData}
            description="E: Ação da IES Emissora - R: Ação da IES Registradora."
            note="*Apenas os responsáveis pela assinatura dos documentos."
          />
        )}
        <DiplomaList />
      </>
    );
  }

  if (isInfoMode) {
    return <DiplomaInformation />;
  }
  return <DiplomaValidate />;
};

export default DiplomaPage;
