import Certificate from '../../../../../domain/model/certificate/Certificate';

export const SET_LOADING_CERTIFICATES =
  'app/certificate-page/SET_LOADING_CERTIFICATES';
export const SET_SEARCH = 'app/certificate-page/SET_SEARCH';
export const SET_CERTIFICATES = 'app/certificate-page/SET_CERTIFICATES';
export const SET_BATCH_CERTIFICATES =
  'app/certificate-page/SET_BATCH_CERTIFICATES';

export interface CertificateInfoState {
  search: string;
  loadingCertificates: boolean;
  certificates: Certificate[];
  id: string;
}

export const INITIAL_STATE: CertificateInfoState = {
  search: '',
  loadingCertificates: false,
  certificates: [],
  id: ''
};

interface SetCertificatesInfoAction {
  type: typeof SET_CERTIFICATES;
  payload: {
    certificates: Array<Certificate>;
  };
}

interface SetLoadingCertificatesAction {
  type: typeof SET_LOADING_CERTIFICATES;
  payload: {
    loadingCertificates: boolean;
  };
}

interface SetSearchAction {
  type: typeof SET_SEARCH;
  payload: {
    search: string;
  };
}

interface SetBatchCertifiicatesAction {
  type: typeof SET_BATCH_CERTIFICATES;
  payload: {
    id: string;
  };
}

export type CertificateInfoActions =
  | SetSearchAction
  | SetBatchCertifiicatesAction
  | SetLoadingCertificatesAction
  | SetCertificatesInfoAction;
