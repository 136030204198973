export interface NotificationState {
  errorMessage: string | null;
  successMessage: string | null;
  successIcon: string | null;
  errorIcon: string | null;
}

export const NOTIFY_SUCCESS = 'notification/NOTIFY_SUCCESS';
export const NOTIFY_ERROR = 'notification/NOTIFY_ERROR';

interface NotifyErrorAction {
  type: typeof NOTIFY_ERROR,
  payload: {
    message: string | null,
    icon: string | null,
  }
}

interface NotifySuccessAction {
  type: typeof NOTIFY_SUCCESS,
  payload: {
    message: string | null,
    icon: string | null,
  }
}

export type NotificationActionTypes = NotifyErrorAction | NotifySuccessAction;
