import Tables from '../../../../domain/enum/Tables';
import ComplementaryActivity from '../../../../domain/model/diploma/ComplementaryActivity';
import Internship from '../../../../domain/model/diploma/Internship';
import LoadTag from '../../../../domain/model/diploma/LoadTag';
import { formatDate } from '../../../utils/functions';
import { CurriculumUnitData } from './types';

function getErrorValidation(validation: any, row: any) {
  let errors: Array<string> = [];
  const keysValidation = Object.keys(validation[0]);

  keysValidation.forEach((key: string) => {
    if (key in row) {
      if (!row[key]) {
        errors.push(key);
      }
      if (key === 'teachers') {
        if (row[key].length > 0) {
          row[key].forEach((teacher: any) => {
            if (!teacher.name || !teacher.title) {
              errors.push(key);
            }
          });
        } else {
          errors.push(key);
        }
      }
    }
  });

  return errors;
}

function getPropLoad(workload: LoadTag) {
  if ('load' in workload) {
    return workload.load?.toString();
  }

  return '';
}

function createdRowInternship(row: Internship, validation?: any) {
  const createdRow: CurriculumUnitData = {
    id: row.code || '',
    code: row.code || '',
    internshipLoadWithTag:
      row.internshipLoadWithTag && getPropLoad(row.internshipLoadWithTag[0]),
    startDate: formatDate(row.startDate) || '',
    endDate: formatDate(row.endDate) || '',
    teachers: row.teachers || []
  };

  if (validation) {
    createdRow.error = getErrorValidation(validation, createdRow);
  }
  return createdRow;
}

function createdRowComplementaryActivity(
  row: ComplementaryActivity,
  validation?: any
) {
  const createdRow: CurriculumUnitData = {
    id: row.code || '',
    code: row.code || '',
    complementaryActivityType: row.complementaryActivityType || '',
    activityLoadWithTag:
      row.activityLoadWithTag && getPropLoad(row.activityLoadWithTag[0]),
    startDate: formatDate(row.startDate) || '',
    endDate: formatDate(row.endDate) || '',
    teachers: row.teachers || []
  };

  if (validation) {
    createdRow.error = getErrorValidation(validation, createdRow);
  }

  return createdRow;
}

export const getRows = (
  type: string,
  datas?: Internship[] | ComplementaryActivity[],
  validation?: any
): CurriculumUnitData[] => {
  let rows: CurriculumUnitData[] = [];

  if (!datas) {
    return rows;
  }

  datas.forEach((data) => {
    let row;
    if (type === Tables.InternshipsTable) {
      const internship: Internship = data;
      row = createdRowInternship(internship, validation);
    } else {
      const complementaryActivity: ComplementaryActivity = data;
      row = createdRowComplementaryActivity(complementaryActivity, validation);
    }
    rows.push(row);
  });

  return rows;
};
