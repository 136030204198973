enum State {
  AC = 'AC',
  AL = 'AL',
  AM = 'AM',
  AP = 'AP',
  BA = 'BA',
  CE = 'CE',
  DF = 'DF',
  ES = 'ES',
  GO = 'GO',
  MA = 'MA',
  MG = 'MG',
  MS = 'MS',
  MT = 'MT',
  PA = 'PA',
  PB = 'PB',
  PE = 'PE',
  PI = 'PI',
  PR = 'PR',
  RJ = 'RJ',
  RN = 'RN',
  RO = 'RO',
  RR = 'RR',
  RS = 'RS',
  SC = 'SC',
  SE = 'SE',
  SP = 'SP',
  TO = 'TO',
}

export const stringify = (state: State): string => {
  switch (state) {
    case State.AC:
      return 'Acre';
    case State.AL:
      return 'Alagoas';
    case State.AM:
      return 'Amazonas';
    case State.AP:
      return 'Amapá';
    case State.BA:
      return 'Bahia';
    case State.CE:
      return 'Ceará';
    case State.DF:
      return 'Distrito Federal';
    case State.ES:
      return 'Espírito Santos';
    case State.GO:
      return 'Goiás';
    case State.MA:
      return 'Maranhão';
    case State.MG:
      return 'Minas Gerais';
    case State.MS:
      return 'Mato Grosso do Sul';
    case State.MT:
      return 'Mato Grosso';
    case State.PA:
      return 'Pará';
    case State.PB:
      return 'Paraíba';
    case State.PE:
      return 'Pernambuco';
    case State.PI:
      return 'Piauí';
    case State.PR:
      return 'Paraná';
    case State.RJ:
      return 'Rio de Janeiro';
    case State.RN:
      return 'Rio Grande do Norte';
    case State.RO:
      return 'Rondônia';
    case State.RR:
      return 'Roraima';
    case State.RS:
      return 'Rio Grande do Sul';
    case State.SC:
      return 'Santa Catarina';
    case State.SE:
      return 'Sergipe';
    case State.SP:
      return 'São Paulo';
    case State.TO:
      return 'Tocantins';
    default:
      return 'Estado inválido';
  }
};

export default State;
