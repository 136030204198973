import { MEDIUM_GRAY_COLOR } from '../../theme';

type CertificateProps = {
  color?: string,
  size?: number,
};

const Certificate = ({ color, size }: CertificateProps) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1" fill="white">
      <path fillRule="evenodd" clipRule="evenodd" d="M2 4.25C2 3.44566 2.55519 3 3 3H21C21.4448 3 22 3.44566 22 4.25V17.75C22 18.5543 21.4448 19 21 19H18.5V21H21C22.7643 21 24 19.4309 24 17.75V4.25C24 2.56906 22.7643 1 21 1H3C1.23567 1 0 2.56906 0 4.25V17.75C0 19.4309 1.23567 21 3 21H15.5V19H3C2.55519 19 2 18.5543 2 17.75V4.25Z" />
    </mask>
    <path fillRule="evenodd" clipRule="evenodd" d="M2 4.25C2 3.44566 2.55519 3 3 3H21C21.4448 3 22 3.44566 22 4.25V17.75C22 18.5543 21.4448 19 21 19H18.5V21H21C22.7643 21 24 19.4309 24 17.75V4.25C24 2.56906 22.7643 1 21 1H3C1.23567 1 0 2.56906 0 4.25V17.75C0 19.4309 1.23567 21 3 21H15.5V19H3C2.55519 19 2 18.5543 2 17.75V4.25Z" fill={color} stroke={color} strokeWidth="2" mask="url(#path-1-inside-1)" />
    <path d="M17 18C18.6569 18 20 16.6569 20 15C20 13.3431 18.6569 12 17 12C15.3431 12 14 13.3431 14 15C14 16.6569 15.3431 18 17 18Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.5 18V23L17 22L18.5 23V18" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 11H5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16 7H5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 15H5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

Certificate.defaultProps = {
  color: MEDIUM_GRAY_COLOR,
  size: 24,
};

export default Certificate;
