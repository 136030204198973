import DefaultTemplate from "../../../templates/default-template";
import DigitalCurriculumList from "./DigitalCurriculumList";

const CurriculumPage = () => {
  return (
    <DefaultTemplate>
      <DigitalCurriculumList />
    </DefaultTemplate>
  );
}

export default CurriculumPage;
