import { AxiosResponse } from 'axios';
import getAxiosInstance from '../apis/axiosInstance';
import CreatePresignedPostRequest from '../domain/interface/request/CreatePresignedPostRequest';
import CreatePresignedPostResponse from '../domain/interface/response/CreatePresignedPostResponse';

let axiosInstance = getAxiosInstance(
  process.env.REACT_APP_DIPLOMA_DOCUMENT_API || ''
);

class DocumentService {
  async createPresignedPost(
    request: CreatePresignedPostRequest
  ): Promise<AxiosResponse<CreatePresignedPostResponse>> {
    return axiosInstance.get(
      `document/createpresignedpost/${request.key}/${request.acessKey}`
    );
  }

  async CreateSignedUrl(
    request: CreatePresignedPostRequest
  ): Promise<AxiosResponse<CreatePresignedPostResponse>> {
    return axiosInstance.get(`document/createsignedurl/${request.key}`);
  }

  async deleteLogoFromBucket(fileName: string): Promise<AxiosResponse> {
    return axiosInstance.delete(`document/deleteLogoFromBucket/${fileName}`);
  }
}

export default new DocumentService();
