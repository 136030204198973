import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Grid, Icon, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InstitutionProfile from '../../../../domain/enum/InstitutionProfile';
import { RootState } from '../../../../setup/reducer/reducers';
import EducationalStageSelect from '../diploma-educational-stage-select';
import useDiplomaEducationalStagesSelectStyles from './styles';

let getEducationalStageLabel: () => string;

export default function EducationalStageLabel() {
  const classes = useDiplomaEducationalStagesSelectStyles();
  const selectedInstitution = useSelector(
    (state: RootState) => state.header.institution
  );
  const disabledDropdownVision = useSelector((state: RootState) => state.diplomaEducational.showVisionDropdow)
  const selectedInstitutionProfile = useSelector(
    (state: RootState) => state.diplomaFilter.selectedInstitutionProfile
  );
  const [showDropdown, setDropdown] = useState(false);
  const [educationalStageLabel, setEducationalStageLabel] = useState('');

  const isRegister = () =>
    selectedInstitutionProfile === InstitutionProfile.Register;

  getEducationalStageLabel = () => {
    if (isRegister()) {
      return 'Visão da IES Registradora • Graduação';
    }

    return 'Visão da IES Emissora • Graduação';
  };

  useEffect(() => {
    setEducationalStageLabel(getEducationalStageLabel());
  }, [selectedInstitutionProfile]);

  return (
    <>
      {!(selectedInstitution && selectedInstitutionProfile) ? (
        <Skeleton className={classes.skeleton} animation="wave" />
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          className={classes.container}
          onClick={() => disabledDropdownVision ? setDropdown(false) : setDropdown(!showDropdown)}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            className={classes.subContainer}
          >
            <Grid item className={classes.containerDescription}>
              <Typography variant="h6">{educationalStageLabel}</Typography>
            </Grid>
            <Grid item className={classes.containerIcon}>
              {showDropdown ? (
                <Icon>
                  <KeyboardArrowUpIcon className={classes.iconUp} />
                </Icon>
              ) : (
                <Icon>
                  <KeyboardArrowDownIcon className={classes.iconDown} />
                </Icon>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      <EducationalStageSelect
        showDropdown={showDropdown}
        setShowDropdown={setDropdown}
        data-testid="dropdown-educational-level"
      />
    </>
  );
}
