import Dou from '../../../../../domain/model/dou/Dou';

export interface BatchPageState {
  batch?: Dou;
  info: boolean;
}

export const SELECT_BATCH = 'app/diploma-page/SELECT_BATCH';
export const TOGGLE_INFO_BATCH = 'app/diploma-page/TOGGLE_INFO_BATCH';
export const UPDATE_BATCH = 'app/diploma-page/UPDATE_BATCH';

interface SelectBatchAction {
  type: typeof SELECT_BATCH;
  payload: {
    batch?: Dou;
  };
}

interface ToggleInfoBatchAction {
  type: typeof TOGGLE_INFO_BATCH;
}

interface UpdateBatchAction {
  type: typeof UPDATE_BATCH;
  payload: {
    batch?: Dou;
  };
}

export type DouPageActions =
  | SelectBatchAction
  | ToggleInfoBatchAction
  | UpdateBatchAction;
