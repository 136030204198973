import { TableCell, TableHead, TableRow, TableSortLabel, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { EnhancedTableProps } from "./types";

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const {
    classes, order, orderBy, onRequestSort, headCells
  } = props;

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{
              width: headCell.size,
              whiteSpace: 'nowrap',
            }}
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id && order}
          >
            {
              headCell.sortable
                ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                    classes={{
                      icon: ((orderBy === headCell.id) ? classes.activeSortIcon : classes.inactiveSortIcon),
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <Typography variant="caption" style={headCell.styles}>
                      {headCell.label}
                    </Typography>
                  </TableSortLabel>
                )
                : (
                  <Typography variant="caption" style={headCell.styles}>
                    {headCell.label}
                  </Typography>
                )
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
