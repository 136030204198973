import { Grid, IconButton, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Info, CheckCircle, Slash, XCircle, RefreshCcw } from 'react-feather';
import { FindInPageOutlined } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import {
  CRITICAL_COLOR,
  DARK_CYAN_COLOR,
  EERIE_BLACK_COLOR,
  GRAY_COLOR,
  DIPLOMA_COLOR
} from '../../../../theme';
import { ActionProps } from './types';
import DiplomaStatus from '../../../../domain/enum/diploma/DiplomaStatus';
import { selectDiploma, toggleInfoDiploma, updateComponentValidateActive } from '../../pages/diploma-page/store/actions';
import CustomizedTooltip from '../../atoms/customized-tooltip';
import CancelDiploma from '../../pages/diploma-modal-cancel/views/CancelDiploma';
import ActivateDiploma from '../../pages/diploma-modal-activate/views/ActivateDiploma';
import RestartDiplomaFlow from '../../pages/diploma-modal-restartDiplomaFlow/views/RestartDiplomaFlow';
import { enableDefaultSignersButton, enableRestartDiploma, enableRestartDiplomaFlow, validateDiploma } from '../../../utils/functions';
import { changeDiplomaDefaultSigners, setDefaultSignerModalSettingsAction, setModalSettingsAction } from '../../organisms/default-signers-modal/store/actions';
import TypeSubmit from '../../../../domain/enum/TypeSubmit';
import ChangeDiplomaDefaultSignerRequest from '../../../../domain/interface/request/ChangeDiplomaDefaultSignerRequest';
import SignerData from '../../../../domain/model/institution/SignerData';
import ChangeType from '../../../../domain/enum/diploma/ChangeType';

const PREFIX = 'index';

const classes = {
  icon: `${PREFIX}-icon`,
  disabled: `${PREFIX}-disabled`,
  activeIcon: `${PREFIX}-activeIcon`,
  activeBlockIcon: `${PREFIX}-activeBlockIcon`,
  activeConcludeIcon: `${PREFIX}-activeConcludeIcon`,
  textDouModal: `${PREFIX}-textDouModal`,
  containerContentStyleDouModal: `${PREFIX}-containerContentStyleDouModal`
};

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`& .${classes.icon}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.25rem',
    height: '1.25rem',
    color: GRAY_COLOR,
    cursor: 'not-allowed'
  },

  [`& .${classes.disabled}`]: {
    cursor: 'not-allowed'
  },

  [`& .${classes.activeIcon}`]: {
    cursor: 'pointer',
    color: DIPLOMA_COLOR
  },

  [`& .${classes.activeBlockIcon}`]: {
    cursor: 'pointer',
    color: CRITICAL_COLOR
  },

  [`& .${classes.activeConcludeIcon}`]: {
    cursor: 'pointer',
    color: DARK_CYAN_COLOR
  },

  [`& .${classes.textDouModal}`]: {
    color: EERIE_BLACK_COLOR,
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight
  },

  [`& .${classes.containerContentStyleDouModal}`]: {
    padding: '20px 15px 20px 10px'
  }
}));

export default function DiplomaActions(props: ActionProps) {
  const { status, id, diplomas, institutionProfile, defaultSigners } = props;
  const dispatch = useDispatch();

  const [openDiplomaCancellationModal, setOpenDiplomaCancellationModal] =
    useState(false);
  const [openDiplomaActivationModal, setOpenDiplomaActivationModal] =
    useState(false);
  const [openRestartDiplomaFlowModal, setOpenRestartDiplomaFlowModal] =
    useState(false);

  const diploma = diplomas.find(f => f.id === id);

  const selectCurrentDiploma = () => {
    if (diploma) {
      dispatch(selectDiploma(diploma));
    }
  };

  const validateDiplomaOnClick = async () => {
    if (!diploma?.defaultSigners) {
      const firstIssuerSigner = defaultSigners?.find((signer) => signer.signFor === 'issuer' && signer.signerPosition === 1);
      const secondIssuerSigner = defaultSigners?.find((signer) => signer.signFor === 'issuer' && signer.signerPosition === 2);
      const firstRegisterSigner = defaultSigners?.find((signer) => signer.signFor === 'register' && signer.signerPosition === 1);
      const secondRegisterSigner = defaultSigners?.find((signer) => signer.signFor === 'register' && signer.signerPosition === 2);

      if (firstIssuerSigner && secondIssuerSigner && firstRegisterSigner && secondRegisterSigner) {
        const changeRequest: ChangeDiplomaDefaultSignerRequest = {
          defaultSigners: [
            {
              ...firstIssuerSigner,
              signFor: 'issuer',
              signerPosition: 1,
            } as SignerData,
            {
              ...secondIssuerSigner,
              signFor: 'issuer',
              signerPosition: 2,
            } as SignerData,
            {
              ...firstRegisterSigner,
              signFor: 'register',
              signerPosition: 1,
            } as SignerData,
            {
              ...secondRegisterSigner,
              signFor: 'register',
              signerPosition: 2,
            } as SignerData
          ],
          type: ChangeType.DefaultSignersUpdate
        };
        await dispatch(changeDiplomaDefaultSigners(changeRequest, diploma?.id));
      }
    }

    dispatch(updateComponentValidateActive("DiplomaValidate"));
    selectCurrentDiploma();
  };

  const informationDiplomaOnClick = () => {
    dispatch(toggleInfoDiploma());
    selectCurrentDiploma();
  };

  const cancelDiplomaOnClick = () => {
    setOpenDiplomaCancellationModal(true);
  };

  const activateDiplomaOnClick = () => {
    setOpenDiplomaActivationModal(true);
  };

  const restartDiplomaFlowOnClick = () => {
    setOpenRestartDiplomaFlowModal(true);
  }

  const defaultSignersModalOnClick = () => {
    if (diploma) {
      let firstIssuerSigner;
      let secondIssuerSigner;
      let firstRegisterSigner;
      let secondRegisterSigner;

      if (diploma?.defaultSigners) {
        firstIssuerSigner = diploma?.defaultSigners?.find((signer) => signer.signFor === 'issuer' && signer.signerPosition === 1);
        secondIssuerSigner = diploma?.defaultSigners?.find((signer) => signer.signFor === 'issuer' && signer.signerPosition === 2);
        firstRegisterSigner = diploma?.defaultSigners?.find((signer) => signer.signFor === 'register' && signer.signerPosition === 1);
        secondRegisterSigner = diploma?.defaultSigners?.find((signer) => signer.signFor === 'register' && signer.signerPosition === 2);
      } else {
        firstIssuerSigner = defaultSigners?.find((signer) => signer.signFor === 'issuer' && signer.signerPosition === 1);
        secondIssuerSigner = defaultSigners?.find((signer) => signer.signFor === 'issuer' && signer.signerPosition === 2);
        firstRegisterSigner = defaultSigners?.find((signer) => signer.signFor === 'register' && signer.signerPosition === 1);
        secondRegisterSigner = defaultSigners?.find((signer) => signer.signFor === 'register' && signer.signerPosition === 2);
      }

      dispatch(setDefaultSignerModalSettingsAction(firstIssuerSigner, secondIssuerSigner, firstRegisterSigner, secondRegisterSigner));
      dispatch(setModalSettingsAction(true, TypeSubmit.DiplomaPage, diploma.issuerMecCode, diploma?.id));
    }
  }

  const enableCancelDiploma =
    (status === DiplomaStatus.Completed || status === DiplomaStatus.Sent || status === DiplomaStatus.TemporarilyCancelled)

  const enableClassNameCancelDiploma =
    (!enableCancelDiploma ? classes.disabled : '')

  const enableButtonSignerModel = status === DiplomaStatus.TemporarilyCancelled

  const enableClassButtonSigner = (!enableButtonSignerModel ? classes.disabled : '')

  const enableButtonChangeDefaultSigner = process.env.REACT_APP_ENABLE_CHANGE_DEFAULT_SIGNER === "true";

  return (
    <>
      <TableCellStyled>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <CustomizedTooltip title="Validar Diploma" placement="top">
              <span className={!validateDiploma(status, institutionProfile) ? classes.disabled : ''}>
                <IconButton
                  onClick={
                    validateDiploma(status, institutionProfile) ? () => validateDiplomaOnClick() : undefined
                  }
                  disabled={!validateDiploma(status, institutionProfile)}
                  data-testid="diploma-validate-button"
                  size="large"
                >
                  <FindInPageOutlined
                    className={`${classes.icon} ${validateDiploma(status, institutionProfile) && classes.activeIcon
                      }`}
                  />
                </IconButton>
              </span>
            </CustomizedTooltip>
          </Grid>

          <Grid item>
            <CustomizedTooltip title="Informações" placement="top">
              <span>
                <IconButton
                  onClick={() => informationDiplomaOnClick()}
                  data-testid="diploma-information-button"
                  size="large"
                >
                  <Info
                    className={`${classes.icon} ${classes.activeIcon}`}
                    color={DIPLOMA_COLOR}
                  />
                </IconButton>
              </span>
            </CustomizedTooltip>
          </Grid>

          {!(enableCancelDiploma) && enableRestartDiploma(process.env.REACT_APP_ENABLE_RESTART_DIPLOMA_FLOW) ? (<Grid item>
            <CustomizedTooltip title="Cancelar" placement="top">
              <span
                className={
                  enableClassNameCancelDiploma
                }
              >
                <IconButton
                  onClick={restartDiplomaFlowOnClick}
                  disabled={
                    !enableRestartDiplomaFlow(status, institutionProfile)
                  }
                  data-testid="diploma-RestartFlow-button"
                  size="large"
                >
                  <XCircle
                    className={`${classes.icon} ${enableRestartDiplomaFlow(status, institutionProfile) &&
                      classes.activeBlockIcon
                      }`}
                  />
                </IconButton>
              </span>
            </CustomizedTooltip>
          </Grid>) : null}

          {enableCancelDiploma || !enableRestartDiploma(process.env.REACT_APP_ENABLE_RESTART_DIPLOMA_FLOW) ? (
            <Grid item>
              <CustomizedTooltip title="Anular Diploma" placement="top">
                <span
                  className={
                    enableClassNameCancelDiploma
                  }
                >
                  <IconButton
                    onClick={cancelDiplomaOnClick}
                    disabled={
                      !enableCancelDiploma
                    }
                    data-testid="diploma-annul-button"
                    size="large"
                  >
                    <Slash
                      className={`${classes.icon} ${enableCancelDiploma &&
                        classes.activeBlockIcon
                        }`}
                    />
                  </IconButton>
                </span>
              </CustomizedTooltip>
            </Grid>) : null}

          {!(enableButtonSignerModel) && enableButtonChangeDefaultSigner ? (<Grid item>
            <CustomizedTooltip title="Trocar Assinante" placement="top">
              <span
                className={
                  enableClassButtonSigner
                }
              >
                <IconButton
                  onClick={defaultSignersModalOnClick}
                  disabled={
                    !enableDefaultSignersButton(status, institutionProfile)
                  }
                  data-testid="diploma-signer_model-button"
                  size="large"
                >
                  <RefreshCcw
                    className={`${classes.icon} ${enableDefaultSignersButton(status, institutionProfile) &&
                      classes.activeIcon
                      }`}
                  />
                </IconButton>
              </span>
            </CustomizedTooltip>
          </Grid>) : null}

          {enableButtonSignerModel ? (<Grid item>
            <CustomizedTooltip title="Concluir diploma" placement="top">
              <span
                className={
                  enableClassButtonSigner
                }
              >
                <IconButton
                  onClick={activateDiplomaOnClick}
                  disabled={!enableButtonSignerModel}
                  data-testid="diploma-complete-button"
                  size="large"
                >
                  <CheckCircle
                    className={`${classes.icon} ${enableButtonSignerModel &&
                      classes.activeConcludeIcon
                      }`}
                  />
                </IconButton>
              </span>
            </CustomizedTooltip>
          </Grid>) : null}
        </Grid>
      </TableCellStyled>
      <CancelDiploma
        openModal={openDiplomaCancellationModal}
        setOpenModal={setOpenDiplomaCancellationModal}
        id={id}
        status={status}
      />
      <ActivateDiploma
        openModal={openDiplomaActivationModal}
        setOpenModal={setOpenDiplomaActivationModal}
        id={id}
      />
      <RestartDiplomaFlow
        openModal={openRestartDiplomaFlowModal}
        setOpenModal={setOpenRestartDiplomaFlowModal}
        id={id}
      />
    </>
  );
}
