import AddIcon from '@mui/icons-material/Add';
import { Button, Paper, Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../../../setup/reducer/reducers';
import { theme } from '../../../../../theme';
import { pageRoutes } from '../../../../utils/constants';
import { setCertificateTypes, setListBrands } from '../../certificate-settings-model-page/store/actions';
import EditModelCertificateFilter from './EditModelCertificateFilter';
import EditModelCertificateTable from './EditModelCertificateTable';

const PREFIX = 'EditModelCertificateList';

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  buttonAdd: `${PREFIX}-buttonAdd`,
  titleTypes: `${PREFIX}-titleTypes`,
  cardTitle: `${PREFIX}-cardTitle`,
  helperIcon: `${PREFIX}-helperIcon`,
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    marginBottom: '50px',
    maxHeight: '1000px',
  },
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main
  },
  [`& .${classes.buttonAdd}`]: {
    height: '2.5rem',
    marginRight: '1rem'

  },
  [`& .${classes.titleTypes}`]: {
    marginLeft: '2rem',
  },
  [`& .${classes.cardTitle}`]: {
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px'
  },
}));

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '8px',
    lineHeight: 1.5,
  },
});

export default function UsersManagementList(): JSX.Element {
  const dispatch = useDispatch()
  const history = useHistory();
  const certificateTypesAvailable = useSelector((rootState: RootState) => rootState.editCertificateSettingsModel.certificateTypes);

  const isButtonDisabled = !certificateTypesAvailable || certificateTypesAvailable.length === 0;

  const navigateToCreateModelCertificateOnClick = async () => {
    dispatch(await setListBrands());
    dispatch(await setCertificateTypes());
    history.push(pageRoutes.CreateCertificateSettings)
  }

  return (
    <Root>
      <Paper className={classes.root}>
        <Grid container item xs={12} className={classes.cardTitle}>
          <Typography className={classes.titleTypes} variant="h3">Tipos de Microcertificados</Typography>
          <CustomTooltip className={tooltipClasses.tooltip} title="Já existem modelos criados para todos os tipos de microcertificados disponíveis atualmente. Você pode editá-los, caso necessário.
" disableHoverListener={!isButtonDisabled}>
            <span>
              <Button
                variant="contained"
                size="large"
                startIcon={<AddIcon />}
                onClick={navigateToCreateModelCertificateOnClick}
                className={classes.buttonAdd}
                disabled={isButtonDisabled}
              >
                Criar Novo Modelo
              </Button>
            </span>
          </CustomTooltip>
        </Grid>
        <>
          <EditModelCertificateFilter />
          <EditModelCertificateTable />
        </>
      </Paper>
    </Root>
  );
}
