import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Fab, Grid, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Send, Edit3, CheckCircle, Slash,
} from 'react-feather';
import { useSelector } from 'react-redux';
import {
  FONT_FAMILY_REGULAR, MEDIUM_GRAY_COLOR, WHITE_COLOR, DIPLOMA_VALIDATION_PASSIVE_ACTION, FONT_FAMILY_BOLD,
} from '../../../../theme';
import { RootState } from '../../../../setup/reducer/reducers';
import { DiplomaValidationButtonProps } from './types';

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
  primaryColorFab: `${PREFIX}-primaryColorFab`,
  errorMessage: `${PREFIX}-errorMessage`,
  errorIcon: `${PREFIX}-errorIcon`,
  fabIcon: `${PREFIX}-fabIcon`,
  successColorFab: `${PREFIX}-successColorFab`,
  nextPageColorFab: `${PREFIX}-nextPageColorFab`,
  errorColorFab: `${PREFIX}-errorColorFab`,
  fontFamilyBold: `${PREFIX}-fontFamilyBold`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    padding: '0 32px 40px 32px',
  },

  [`& .${classes.primaryColorFab}`]: {
    backgroundColor: theme.palette.primary.main,
    height: '44px',
    '&:hover': {
      opacity: 0.9,
      backgroundColor: theme.palette.primary.main,
    },
  },

  [`& .${classes.errorMessage}`]: {
    fontFamily: FONT_FAMILY_REGULAR,
  },

  [`& .${classes.errorIcon}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.fabIcon}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.successColorFab}`]: {
    backgroundColor: theme.palette.success.main,
    height: '44px',
    '&:hover': {
      opacity: 0.9,
      backgroundColor: theme.palette.success.main,
    },
    '&:disabled': {
      backgroundColor: MEDIUM_GRAY_COLOR,
    },
  },

  [`& .${classes.nextPageColorFab}`]: {
    width: '162px',
    color: WHITE_COLOR,
    backgroundColor: DIPLOMA_VALIDATION_PASSIVE_ACTION,
    height: '44px',
    '&:hover': {
      opacity: 0.9,
      backgroundColor: DIPLOMA_VALIDATION_PASSIVE_ACTION,
    },
  },

  [`& .${classes.errorColorFab}`]: {
    backgroundColor: theme.palette.error.main,
    height: '44px',
    '&:hover': {
      opacity: 0.9,
      backgroundColor: theme.palette.error.main,
    },
  },

  [`& .${classes.fontFamilyBold}`]: {
    fontFamily: FONT_FAMILY_BOLD,
  }
}));

export default function DiplomaValidationButton({
  handleClick, text, validation, buttonType, 'data-testid': dataTestid,
}: DiplomaValidationButtonProps): JSX.Element {
  const isLoadingDiploma = useSelector((rootState: RootState) => rootState.diplomaPage.isLoadingDiploma);


  const shouldDisableButton = (): boolean => {
    if (isLoadingDiploma) {
      return true;
    }
    if (validation === "invalidDefaultSigners") {
      return true;
    }
    return text === 'Enviar para assinatura' && validation && Object.keys(validation).length > 0;
  };

  const getClassName = (): string => {
    switch (buttonType) {
      case 'warn':
        return classes.errorColorFab;
      case 'success':
        return classes.successColorFab;
      case 'primary':
        return classes.nextPageColorFab;
      default:
        return classes.primaryColorFab;
    }
  };

  const getIcon = (): JSX.Element | null => {
    switch (text) {
      case 'Recusar Diploma':
        return <CloseIcon className={classes.fabIcon} htmlColor={WHITE_COLOR} />;
      case 'Enviar para assinatura':
        return <Send className={classes.fabIcon} color={WHITE_COLOR} />;
      case 'Editar informações':
        return <Edit3 className={classes.fabIcon} color={WHITE_COLOR} />;
      case 'Ativar Diploma':
        return <CheckCircle className={classes.fabIcon} color={WHITE_COLOR} />;
      case 'Anular Diploma':
        return <Slash className={classes.fabIcon} color={WHITE_COLOR} />;
      default:
        return null;
    }
  };

  return (
    <StyledGrid item>
      <Fab
        className={getClassName()}
        variant="extended"
        size="large"
        disabled={shouldDisableButton()}
        component="button"
        onClick={handleClick}
      >
        {getIcon()}
        <Typography className={classes.fontFamilyBold} variant="button" data-testid={dataTestid}>{text}</Typography>
      </Fab>
    </StyledGrid>
  );
}
