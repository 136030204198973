import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FormHelperText, Icon } from '@mui/material';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { OPAQUE_DIPLOMA_COLOR } from '../../../../theme';
import CustomTextField from '../../atoms/form/CustomTextField';
import LoadingIcon from '../../atoms/loading-icon';
import { SelectAutocompleteProps } from './types';

const PREFIX = 'SelectAutocomplete';

const internalClasses = {
  root: `${PREFIX}-root`,
  activeIcon: `${PREFIX}-activeIcon`,
  popupIndicator: `${PREFIX}-popupIndicator`,
  popupIndicatorOpen: `${PREFIX}-root`,
  activeAutocomplete: `${PREFIX}-activeAutocomplete`,
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${internalClasses.activeIcon}`]: {
    fill: theme.palette.primary.main,
    width: 24,
    height: 24
  },
  [`& .${internalClasses.popupIndicator}`]: {
    marginRight: '5px'
  },
  [`& .${internalClasses.popupIndicatorOpen}`]: {
    color: theme.palette.primary.main
  },
  [`& .${internalClasses.activeAutocomplete}`]: {
    backgroundColor: OPAQUE_DIPLOMA_COLOR,
  },
}
));

const SelectAutocomplete = (props: SelectAutocompleteProps) => {
  const {
    listItems, placeholder, label, setItem, classes, defaultValue, loading,
    error, helperText, compareName, enableClearable, 'data-testid': dataTestid, loadingActiveIcon
  } = props;


  return <Root>
    {
      label && (
        <FormHelperText className={classes?.labelAutoComplete}>
          {label}
        </FormHelperText>
      )
    }
    <Autocomplete
      style={{ width: '100%' }}
      data-testid={dataTestid}
      autoComplete
      autoHighlight
      disabled={loading}
      value={defaultValue || null}
      noOptionsText="Nenhum resultado encontrado"
      openOnFocus
      disableClearable={!enableClearable}
      options={listItems}
      getOptionLabel={(option: any) => option?.name || option.value || option}
      isOptionEqualToValue={(option: any, value: any) => (compareName ? (option?.name === value?.name) : (option.value === value.value))}
      onChange={(_, value: any) => setItem(value)}
      popupIcon={(
        <Icon>
          {loadingActiveIcon ? <LoadingIcon /> : <KeyboardArrowDownIcon className={defaultValue && internalClasses.activeIcon} />}
        </Icon>
      )}
      classes={{
        popupIndicator: internalClasses.popupIndicator,
        popupIndicatorOpen: internalClasses.popupIndicatorOpen,
        inputRoot: defaultValue && internalClasses.activeAutocomplete,
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <CustomTextField
          {...params}
          error={error}
          helperText={helperText}
          placeholder={placeholder}
          hiddenLabel
        />
      )}
    />
  </Root>;
};

SelectAutocomplete.defaultProps = {
  loading: false,
  classes: undefined,
  defaultValue: undefined,
  error: false,
  helperText: '',
  InputProps: {},
  compareName: false,
  enableClearable: false,
  loadingActiveIcon: false,
  'data-testid': undefined,
  label: '',
};

export default SelectAutocomplete;
