import { AxiosResponse } from 'axios';
import ChangeDiplomaDefaultSignerRequest from '../../../../../domain/interface/request/ChangeDiplomaDefaultSignerRequest';
import ChangeInstitutionsDefaultSignersRequest from '../../../../../domain/interface/request/ChangeInstitutionsDefaultSignersRequest';
import InstitutionSigners from '../../../../../domain/model/institution/InstitutionSigners';
import SignerData from '../../../../../domain/model/institution/SignerData';
import DiplomaInstitutionService from '../../../../../services/DiplomaInstitutionService';
import DiplomaService from '../../../../../services/DiplomaService';
import { AppThunk } from '../../../../../setup/reducer';
import {
  errorNotificationMessages,
  notificationIcons,
  successNotificationMessages
} from '../../../../utils/constants';
import {
  showNotification,
  showSuccessNotification
} from '../../../pages/notification/actions';
import {
  DiplomaModalDefaultSignersActions,
  SET_DEFAULT_SIGNER_MODAL_SETTINGS,
  SET_MODAL_SETTINGS,
  SET_SIGNERS
} from './types';

const internalListInstitutionSigners = (
  defaultSigners?: SignerData[],
  otherSigners?: SignerData[]
): DiplomaModalDefaultSignersActions => ({
  type: SET_SIGNERS,
  payload: {
    defaultSigners,
    otherSigners
  }
});

export const listSigners =
  (mecCode: number): AppThunk =>
  async (dispatch) => {
    let institutionSigners: InstitutionSigners = {};
    try {
      institutionSigners = (
        await DiplomaInstitutionService.listDefaultSigners(mecCode)
      ).data;
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
    }
    dispatch(
      internalListInstitutionSigners(
        institutionSigners.defaultSigners,
        institutionSigners.otherSigners
      )
    );
  };

export const changeDiplomaDefaultSigners =
  (
    changeRequest: ChangeDiplomaDefaultSignerRequest,
    id: string | undefined
  ): AppThunk =>
  async (dispatch) => {
    try {
      await DiplomaService.addChangeDiploma(changeRequest, id);
      dispatch(
        showSuccessNotification(
          successNotificationMessages.defaultSuccess,
          notificationIcons.success
        )
      );
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
    }
  };

export const changeInstitutionDefaultSigners =
  (
    changeInstitutionDefaultSignersRequest: ChangeInstitutionsDefaultSignersRequest[]
  ): AppThunk =>
  async (dispatch) => {
    try {
      let promises: Array<Promise<AxiosResponse<any>>> = [];

      changeInstitutionDefaultSignersRequest.forEach(async (signer) => {
        promises.push(
          DiplomaInstitutionService.changeInstitutionDefaultSigners(signer)
        );
      });

      await Promise.all(promises);

      dispatch(
        showSuccessNotification(
          successNotificationMessages.defaultSuccess,
          notificationIcons.success
        )
      );
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
    }
  };

const internalSetModalSettingsAction = (
  diplomaId: string | undefined,
  openDefaultSignersModal: boolean,
  typeSubmit: string,
  mecCode: number
): DiplomaModalDefaultSignersActions => ({
  type: SET_MODAL_SETTINGS,
  payload: {
    diplomaId,
    openDefaultSignersModal,
    typeSubmit,
    mecCode
  }
});

export const setModalSettingsAction =
  (
    openDefaultSignersModal: boolean,
    typeSubmit: string,
    mecCode: number,
    diplomaId?: string
  ): AppThunk =>
  async (dispatch) => {
    dispatch(
      internalSetModalSettingsAction(
        diplomaId,
        openDefaultSignersModal,
        typeSubmit,
        mecCode
      )
    );
  };

const internalSetDefaultSignerModalSettingsAction = (
  firstIssuerSigner?: SignerData,
  secondIssuerSigner?: SignerData,
  firstRegisterSigner?: SignerData,
  secondRegisterSigner?: SignerData
): DiplomaModalDefaultSignersActions => ({
  type: SET_DEFAULT_SIGNER_MODAL_SETTINGS,
  payload: {
    firstIssuerSigner,
    secondIssuerSigner,
    firstRegisterSigner,
    secondRegisterSigner
  }
});

export const setDefaultSignerModalSettingsAction =
  (
    firstIssuerSigner?: SignerData,
    secondIssuerSigner?: SignerData,
    firstRegisterSigner?: SignerData,
    secondRegisterSigner?: SignerData
  ): AppThunk =>
  async (dispatch) => {
    dispatch(
      internalSetDefaultSignerModalSettingsAction(
        firstIssuerSigner,
        secondIssuerSigner,
        firstRegisterSigner,
        secondRegisterSigner
      )
    );
  };
