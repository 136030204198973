import InsertEmployeeRequest from '../../../../../domain/interface/request/InsertEmployeeRequest';
import UpdateEmployeeRequest from '../../../../../domain/interface/request/UpdateEmployeeRequest';
import Employee from '../../../../../domain/model/employee/Employee';
import EmployeeService from '../../../../../services/EmployeeService';
import { AppThunk } from '../../../../../setup/reducer';
import {
  errorNotificationMessages,
  notificationIcons,
  successNotificationMessages
} from '../../../../utils/constants';
import {
  showNotification,
  showSuccessNotification
} from '../../../pages/notification/actions';
import { DefaultUserModalActions, SET_DEFAULT_USER_MODAL } from './types';

export const changeUpdateEmployee =
  (changeRequest: UpdateEmployeeRequest): AppThunk =>
  async (dispatch) => {
    try {
      await EmployeeService.updateEmployee(changeRequest);
      dispatch(
        showSuccessNotification(
          successNotificationMessages.successUpdateEmployee,
          notificationIcons.success
        )
      );

      return true;
    } catch (error: any) {
      if (typeof error.response.data.details === 'string') {
        if (error.response.data.details.includes('CPF')) {
          dispatch(
            showNotification(
              errorNotificationMessages.failedUpdatingEmployeeCpf,
              notificationIcons.error
            )
          );
          return false;
        } else if (error.response.data.details.includes('e-mail')) {
          dispatch(
            showNotification(
              errorNotificationMessages.faileldUpdateEmployeeEmail,
              notificationIcons.error
            )
          );
          return false;
        }
      } else {
        dispatch(
          showNotification(
            errorNotificationMessages.defaultError,
            notificationIcons.error
          )
        );
        return false;
      }
    }
  };

export const changeInsertNewEmployee =
  (changeRequest: InsertEmployeeRequest): AppThunk =>
  async (dispatch) => {
    try {
      await EmployeeService.insertEmployee(changeRequest);
      dispatch(
        showSuccessNotification(
          successNotificationMessages.successInsertEmployee,
          notificationIcons.success
        )
      );

      return true;
    } catch (error: any) {
      if (
        typeof error.response.data.details === 'object' &&
        'employee' in error.response.data.details
      ) {
        return error.response.data.details.employee;
      }
      if (typeof error.response.data.details === 'string') {
        if (error.response.data.details.includes('CPF')) {
          dispatch(
            showNotification(
              errorNotificationMessages.faileldInsertEmployeeCpf,
              notificationIcons.error
            )
          );
          return false;
        } else if (error.response.data.details.includes('e-mail')) {
          dispatch(
            showNotification(
              errorNotificationMessages.faileldInsertEmployeeEmail,
              notificationIcons.error
            )
          );
          return false;
        }
      } else {
        dispatch(
          showNotification(
            errorNotificationMessages.defaultError,
            notificationIcons.error
          )
        );
        return false;
      }
    }
  };

const internalSetDefaultUserModalAction = (
  employee?: Employee
): DefaultUserModalActions => ({
  type: SET_DEFAULT_USER_MODAL,
  payload: employee
});

export const setDefaultUserModalAction =
  (employee?: Employee): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetDefaultUserModalAction(employee));
  };
