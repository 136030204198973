import Employee from '../../../../../domain/model/employee/Employee';

export const SET_EMPLOYEE = 'app/authorization/SET_EMPLOYEE';
export const SET_SHOW_SIGN_DOCUMENTS =
  'app/authorization/SET_SHOW_SIGN_DOCUMENTS';

export interface AuthorizationState {
  employee?: Employee;
  showSignDocuments?: boolean;
}

export const INITIAL_STATE: AuthorizationState = {
  employee: undefined,
  showSignDocuments: undefined
};

interface SetEmployeeAction {
  type: typeof SET_EMPLOYEE;
  payload: {
    employee?: Employee;
  };
}

interface SetShowSignDocumentsAction {
  type: typeof SET_SHOW_SIGN_DOCUMENTS;
  payload: {
    showSignDocuments?: boolean;
  };
}

export type AuthorizationActions =
  | SetEmployeeAction
  | SetShowSignDocumentsAction;
