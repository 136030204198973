import EditModelCertificate from '../../../../../domain/model/certificate/EditModelCertificate';

export const SET_CERTIFICATE_TYPES =
  'app/certificate-settings-model/SET_CERTIFICATE_TYPES';
export const SET_LIST_BRANDS = 'app/certificate-settings-model/SET_LIST_BRANDS';
export const SET_CERTIFICATE_SETTINGS =
  'app/certificate-settings-model/SET_CERTIFICATE_SETTINGS';
export const SET_LOADING_CERTIFICATE_TYPE =
  'app/certificate-settings-model/SET_LOADING_CERTIFICATE_TYPE';
export const SET_LOADING_BRANDS =
  'app/certificate-settings-model/SET_LOADING_BRANDS';

export interface CertificateSettingsModelState {
  certificateTypes: string[];
  brands: string[];
  loadingCertificateType: boolean;
  loadingBrands: boolean;
  certificateSetting?: EditModelCertificate;
}

export const INITIAL_STATE: CertificateSettingsModelState = {
  certificateTypes: [],
  brands: [],
  loadingCertificateType: false,
  loadingBrands: false,
  certificateSetting: undefined
};

interface SetListBrands {
  type: typeof SET_LIST_BRANDS;
  payload: {
    brands: string[];
  };
}

interface SetCertificateSetting {
  type: typeof SET_CERTIFICATE_SETTINGS;
  payload: {
    certificateSetting: EditModelCertificate;
  };
}
interface SetLoadingCertificateType {
  type: typeof SET_LOADING_CERTIFICATE_TYPE;
  payload: {
    loadingCertificateType: boolean;
  };
}
interface SetLoadingBrands {
  type: typeof SET_LOADING_BRANDS;
  payload: {
    loadingBrands: boolean;
  };
}

interface SetCertificateTypesAction {
  type: typeof SET_CERTIFICATE_TYPES;
  payload: {
    certificateTypes: string[];
  };
}

export type CertificateSettingsModelActions =
  | SetCertificateTypesAction
  | SetListBrands
  | SetLoadingCertificateType
  | SetLoadingBrands
  | SetCertificateSetting;
