import Area from './Area';
import { ComplementaryActivityCategory } from './ComplementaryActivityCategory';
import Course from './Course';
import { CriteriaIntegralization } from './CriteriaIntegralization';
import CurricularUnity from './CurricularUnity';
import IssuerInstitution from './IssuerInstitution';
import Label from './Label';
import Root from './Root';

export default class Curriculum {
  curriculumId: string;

  root?: Root;

  course: Course;

  issuerInstitution: IssuerInstitution;

  labels: Array<Label>;

  areas: Array<Area>;

  curricularUnities: Array<CurricularUnity>;

  complementaryActivityCategories: Array<ComplementaryActivityCategory>;

  criteriaIntegralization: CriteriaIntegralization;
}
