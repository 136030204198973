import {
  DiplomaEducationalActions,
  DiplomaEducationalState,
  INITIAL_STATE,
  SET_SHOW_VISION_DROPDOWN
} from './types';

export default function reducer(
  state: DiplomaEducationalState = INITIAL_STATE,
  action: DiplomaEducationalActions
): DiplomaEducationalState {
  if (action.type === SET_SHOW_VISION_DROPDOWN) {
    return {
      ...state,
      showVisionDropdow: action.payload.showVisionDropdow
    };
  }

  return state;
}
