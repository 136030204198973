import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import moment from 'moment';
import InstitutionProfile from '../../../domain/enum/InstitutionProfile';
import DiplomaStatus from '../../../domain/enum/diploma/DiplomaStatus';
import Diploma from '../../../domain/model/diploma/Diploma';
import { PageTitleProps } from '../../components/molecules/page-title/types';
import { welcomeMessage } from '../constants';

const getWelcomeMessageGreeting = (): string => {
  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  let result;

  if (currentHour < 12) {
    result = welcomeMessage.goodMorning;
  } else if (currentHour < 18) {
    result = welcomeMessage.goodEvening;
  } else {
    result = welcomeMessage.goodNight;
  }
  return result;
};

export const getDefaultGreetingMessage = (
  name: string | undefined
): PageTitleProps => {
  if (name) {
    const separateNames = name.split(' ');
    [name] = separateNames;
  }
  return {
    highlightedMessage: name || '',
    message: getWelcomeMessageGreeting()
  };
};

export const formatDate = (date: string | undefined): string | undefined =>
  date ? moment(date, 'YYYY-MM-DD').format('YYYY') : undefined;

export const isAuthorized = (permission: string, roles: string[]) => {
  const groupPermissions = permission.split('.');
  for (const role of roles) {
    const roleSegments = role.split('.');
    if (
      groupPermissions.length === 1 &&
      roleSegments[0] === groupPermissions[0]
    ) {
      return true;
    }
    if (
      groupPermissions.length === 5 &&
      roleSegments[0] === groupPermissions[0] &&
      roleSegments[1] === groupPermissions[1] &&
      roleSegments[4] === groupPermissions[4] &&
      (roleSegments[2] === '*' || roleSegments[2] === groupPermissions[2]) &&
      (roleSegments[3] === '*' || roleSegments[3] === groupPermissions[3])
    ) {
      return true;
    }
  }
  return false;
};

export const getIsCourtOrder = (diploma?: Diploma) => diploma?.isCourtOrder;

export const reduceTextIfNecessary = (
  text: string,
  maxCharactersToShow = 30
) => {
  const textLengthIsInsideLimit = text.length <= maxCharactersToShow;

  if (textLengthIsInsideLimit) {
    return text;
  }

  const prefixText = text.slice(0, Math.floor(maxCharactersToShow / 2));
  const suffixText = text.slice(
    text.length - Math.round(maxCharactersToShow / 2) - 3,
    text.length
  );
  return `${prefixText}...${suffixText}`;
};

export const sortListByName = (
  current: { name?: string },
  next: { name?: string }
) => {
  current.name = current.name || '';
  next.name = next.name || '';

  if (current.name > next.name) {
    return 1;
  }
  if (current.name < next.name) {
    return -1;
  }

  return 0;
};

export const sortListByMostRecentDateProperty =
  (datePropertyName: string) => (current: any, next: any) => {
    const currentDate = moment(current[datePropertyName]);
    const nextDate = moment(next[datePropertyName]);

    if (currentDate.isSame(nextDate)) {
      return 0;
    }

    if (currentDate.isBefore(nextDate)) {
      return 1;
    }

    return -1;
  };

export const filterListByDateProperty =
  ({
    datePropertyName,
    startDate,
    endDate
  }: {
    datePropertyName: string;
    startDate?: string;
    endDate?: string;
  }) =>
  (current: any) => {
    let isValid = true;

    const currentDate = moment(current[datePropertyName]);
    const isNotDateTimeFormat = (date: string) =>
      /^\d{4}-\d{2}-\d{2}$/.test(date);

    if (startDate) {
      const dateTime = isNotDateTimeFormat(startDate)
        ? `${startDate}T00:00:00`
        : startDate;
      const dateToCompare = moment(dateTime);
      isValid =
        isValid &&
        (currentDate.isAfter(dateToCompare) ||
          currentDate.isSame(dateToCompare));
    }

    if (endDate) {
      const dateTime = isNotDateTimeFormat(endDate)
        ? `${endDate}T23:59:59`
        : endDate;
      const dateToCompare = moment(dateTime);
      isValid =
        isValid &&
        (currentDate.isBefore(dateToCompare) ||
          currentDate.isSame(dateToCompare));
    }

    return isValid;
  };

export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (bytes === 0) return '0 Bytes';

  const kb = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const suffixId = Math.floor(Math.log(bytes) / Math.log(kb));
  const suffix = sizes[suffixId];

  const formattedNumber = parseFloat(
    (bytes / kb ** suffixId).toFixed(decimals)
  );

  return `${formattedNumber} ${suffix}`;
};

export const formatCpf = (cpf: string): string => cpfValidator.format(cpf);

export const getMediumDateFormat = (date: string) =>
  moment(date).format('DD/MM/YY [às] HH[h]mm[min]');

export const getLongDateFormat = (date: string) =>
  moment(date).format('HH[h]mm[min] [do dia] DD/MM/YY');

export const searchIgnoringCaseAndAccents = (
  input: string,
  search: string
): boolean => {
  if (!input || !search) {
    return false;
  }

  const getCartesianBySearchLength = (
    inputC: string,
    repetition: number
  ): Array<string> => {
    const cartesianProduct: Array<string> = [];

    Array.from(inputC).forEach((_, index, array) => {
      if (Number(index) !== array.length - 1) {
        const combination = inputC.substring(index, index + repetition);

        if (combination.length === repetition) {
          cartesianProduct.push(combination);
        }
      }
    });

    return cartesianProduct;
  };

  const cartesianArray = getCartesianBySearchLength(input, search.length);

  const collator = new Intl.Collator('pt-BR', {
    usage: 'search',
    sensitivity: 'base'
  });

  return cartesianArray.some((pairs) => collator.compare(pairs, search) === 0);
};

export const validateDiploma = (
  status: DiplomaStatus,
  institutionProfile: InstitutionProfile
) => {
  if (
    (status === DiplomaStatus.RegisterConfirmationPending &&
      institutionProfile === InstitutionProfile.Register) ||
    (status === DiplomaStatus.IssuerConfirmationPending &&
      institutionProfile === InstitutionProfile.Issuer)
  ) {
    return true;
  }
  return false;
};
export const enableRestartDiplomaFlow = (
  status: DiplomaStatus,
  institutionProfile: InstitutionProfile
) => {
  if (
    (status === DiplomaStatus.AwaitingIssuerSignature ||
      status === DiplomaStatus.RegisterConfirmationPending) &&
    institutionProfile === InstitutionProfile.Issuer &&
    enableRestartDiploma(process.env.REACT_APP_ENABLE_RESTART_DIPLOMA_FLOW)
  ) {
    return true;
  }
  return false;
};

export const enableDefaultSignersButton = (
  status: DiplomaStatus,
  institutionProfile: InstitutionProfile
) => {
  if (
    status === DiplomaStatus.IssuerConfirmationPending &&
    institutionProfile === InstitutionProfile.Issuer
  ) {
    return true;
  }
  return false;
};

export const enableRestartDiploma = (flag: any) => {
  if (flag === 'false') return false;

  return true;
};

export const resizeImage = (
  file: any,
  maxWidth: number,
  maxHeight: number
): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (e: any) {
      const image = new Image();
      image.src = e.target.result;

      image.onload = function () {
        let newWidth = image.width;

        let newHeight = image.height;

        const aspectRatio = newWidth / newHeight;
        if (newWidth > maxWidth) {
          newWidth = maxWidth;
          newHeight = newWidth / aspectRatio;
        }
        if (newHeight > maxHeight) {
          newHeight = maxHeight;
          newWidth = newHeight * aspectRatio;
        }

        const canvas: any = document.createElement('canvas');
        const context: any = canvas.getContext('2d');

        const verticalCenterOffset = (maxHeight - newHeight) / 2;

        canvas.width = maxWidth;
        canvas.height = maxHeight;
        context.fillStyle = '#fff';
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.drawImage(image, 0, verticalCenterOffset, newWidth, newHeight);

        canvas.toBlob((blob: any) => {
          const resizedFile = new File([blob], file.name, { type: file.type });
          resolve(resizedFile);
        }, file.type);
      };
    };

    reader.readAsDataURL(file);

    reader.onerror = () => {
      reject(new Error('Error reading file.'));
    };
  });
};
