import React from 'react';
import { styled } from '@mui/material/styles';
import { IconButton, Grid, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BaseModal from '../../molecules/base-modal';
import SelectAutocomplete from '../../molecules/select-autocomplete';
import PdfViewer from '../../molecules/pdf-viewer';
import { AcademicDocFilesModalProps } from './types';
import { BACKGROUND_COLOR, MEDIUM_GRAY_COLOR } from '../../../../theme';

const PREFIX = 'index';

const classes = {
  closeButton: `${PREFIX}-closeButton`,
  internalGrid: `${PREFIX}-internalGrid`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(3),
    color: MEDIUM_GRAY_COLOR,
  },

  [`& .${classes.internalGrid}`]: {
    marginTop: theme.spacing(4),
    height: '400px',
    width: '80vw',
    overflowY: 'scroll',
    backgroundColor: BACKGROUND_COLOR,
    border: '1px solid #E0E0E0',
  }
}));

export default function AcademicDocFilesModal(props: AcademicDocFilesModalProps): JSX.Element {
  const {
    listItems, currentItem, currentFileSrc, openModal, setOpenModal, setItem, loading,
  } = props;


  return (
    <>
      {openModal
        && (
          <BaseModal
            modalTitle="Visualização de Documentação Acadêmica"
            openModal
            onClose={() => setOpenModal(false)}
          >
            <StyledGrid container justifyContent="center" direction="row">
              <Grid item xs={12}>
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={() => setOpenModal(false)}
                  size="large">
                  <CloseIcon />
                </IconButton>
                <SelectAutocomplete
                  listItems={listItems}
                  defaultValue={currentItem}
                  label="Documento"
                  placeholder="Nenhum documento selecionado"
                  setItem={setItem}
                />
              </Grid>
              <Grid container item xs={12} className={classes.internalGrid} justifyContent="center" alignItems="center" direction="row">
                {loading ? <CircularProgress data-testid="loading" thickness={5} size={110} /> : <PdfViewer data-testid="pdf" file={currentFileSrc} />}
              </Grid>
            </StyledGrid>
          </BaseModal>
        )}
    </>
  );
}
