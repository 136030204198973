import React, { useRef } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RootState } from '../../../../../setup/reducer/reducers';
import { modalInformation } from '../../../../utils/constants';
import ConfirmationModal from '../../../organisms/confirmation-modal';
import * as Actions from '../store/actions';
import RegisterInstitutionValidatesDiplomaRequest from '../../../../../domain/interface/request/RegisterInstitutionValidatesDiplomaRequest';
import { addChangeDiploma } from '../../diploma-page/store/actions';
import ChangeType from '../../../../../domain/enum/diploma/ChangeType';
import Institution from '../../../../../domain/model/institution/Institution';
import Signer from '../../../../../domain/model/diploma/Signer';
import { GRAY_COLOR } from '../../../../../theme';
import EmployeePaper from '../../../organisms/employee-paper';
import Employee from '../../../../../domain/model/employee/Employee';
import InstitutionProfile from '../../../../../domain/enum/InstitutionProfile';
import IssuerInstitutionValidatesDiplomaRequest from '../../../../../domain/interface/request/IssuerInstitutionValidatesDiplomaRequest';
import SignerData from '../../../../../domain/model/institution/SignerData';
import ChangeDiplomaDefaultSignerRequest from '../../../../../domain/interface/request/ChangeDiplomaDefaultSignerRequest';
import { changeDiplomaDefaultSigners } from '../../../organisms/default-signers-modal/store/actions';

const mapState = (state: RootState) => ({
  diploma: state.diplomaPage.diploma,
  employees: state.selectDiplomaSigners.employees,
  selectedInstitutionProfile: state.diplomaFilter.selectedInstitutionProfile,
  selectedInstitution: state.header.institution as Institution
});

const mapDispatch = {
  sendDiplomaToSignature: (
    request:
      | RegisterInstitutionValidatesDiplomaRequest
      | IssuerInstitutionValidatesDiplomaRequest,
    diplomaId?: string,
    setLoading?: (isLoading: boolean) => void
  ) => addChangeDiploma(request, diplomaId, setLoading),
  setEmployees: (registerIes: Institution, setLoadingEmployee: any) =>
    Actions.setEmployees(setLoadingEmployee, registerIes.mecCode, true)
};

const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector> & {
  setOpenModal: any;
  openModal: any;
};

const SendDiplomaToSignatureModal = (props: Props) => {
  const {
    openModal,
    diploma,
    selectedInstitution,
    employees,
    selectedInstitutionProfile,
    setOpenModal,
    sendDiplomaToSignature,
    setEmployees
  } = props;

  const dispatch = useDispatch();

  const [loadingEmployee, setLoadingEmployee] = React.useState<boolean>(false);
  const [firstSignerLocal, setFirstSignerLocal] = React.useState<Employee | undefined>(undefined);
  const [secondSignerLocal, setSecondSignerLocal] = React.useState<Employee | undefined>(undefined);
  const [firstSignerError, setFirstSignerError] =
    React.useState<boolean>(false);
  const [secondSignerError, setSecondSignerError] =
    React.useState<boolean>(false);
  const [loadingModal, setLoadingModal] = React.useState<boolean>(false);

  let signers: Signer[];

  React.useEffect(() => {
    setLoadingEmployee(true);
    setEmployees(selectedInstitution, setLoadingEmployee);
  }, [setLoadingEmployee, setEmployees, selectedInstitution]);

  React.useEffect(() => {
    setFirstSignerLocal(firstSignerLocal);
    setFirstSignerError(false);
  }, [firstSignerLocal]);

  React.useEffect(() => {
    setSecondSignerLocal(secondSignerLocal);
    setSecondSignerError(false);
  }, [secondSignerLocal]);

  const diplomaDefaultSigners = diploma?.defaultSigners;

  const firstIssuerSigner = diplomaDefaultSigners?.find((signer) => signer.signFor === 'issuer' && signer.signerPosition === 1);
  const secondIssuerSigner = diplomaDefaultSigners?.find((signer) => signer.signFor === 'issuer' && signer.signerPosition === 2);
  const firstRegisterSigner = diplomaDefaultSigners?.find((signer) => signer.signFor === 'register' && signer.signerPosition === 1);
  const secondRegisterSigner = diplomaDefaultSigners?.find((signer) => signer.signFor === 'register' && signer.signerPosition === 2);

  React.useEffect(() => {
    let selectedFirstEmployee: Employee | undefined;
    let selectedSecondEmployee: Employee | undefined;

    if (selectedInstitutionProfile === InstitutionProfile.Register) {
      selectedFirstEmployee = employees?.find((employee) => employee.login === firstRegisterSigner?.login);
      selectedSecondEmployee = employees?.find((employee) => employee.login === secondRegisterSigner?.login);

      setFirstSignerLocal(selectedFirstEmployee);
      setSecondSignerLocal(selectedSecondEmployee);

    } else {
      selectedFirstEmployee = employees?.find((employee) => employee.login === firstIssuerSigner?.login);
      selectedSecondEmployee = employees?.find((employee) => employee.login === secondIssuerSigner?.login);

      setFirstSignerLocal(selectedFirstEmployee);
      setSecondSignerLocal(selectedSecondEmployee);
    }
  }, [employees, selectedInstitutionProfile, firstIssuerSigner, secondIssuerSigner, firstRegisterSigner, secondRegisterSigner])

  const loading = useRef(false);
  React.useEffect(() => {
    loading.current = false;
  }, [firstSignerLocal, secondSignerLocal]);

  function returnRequest() {
    if (selectedInstitutionProfile === InstitutionProfile.Register) {
      return {
        registerInstitution: {
          ...diploma?.registerInstitution,
          signers
        },
        type: ChangeType.RegisterInstitutionValidatedDiploma
      } as RegisterInstitutionValidatesDiplomaRequest;
    }

    let issuerInstitutionValidatesDiplomaRequest = {
      issuerInstitution: {
        ...diploma?.issuerInstitution,
        signers
      },
      type: ChangeType.IssuerInstitutionValidatedDiploma
    } as IssuerInstitutionValidatesDiplomaRequest

    if (diploma?.isCourtOrder && diploma?.courtOrder) {
      issuerInstitutionValidatesDiplomaRequest.courtOrder = diploma.courtOrder
    }

    return issuerInstitutionValidatesDiplomaRequest;
  }

  async function handleSubmit(): Promise<void> {
    setFirstSignerError(!firstSignerLocal);
    setSecondSignerError(!secondSignerLocal);

    if (firstSignerLocal && secondSignerLocal && !loading.current) {
      loading.current = true;

      let firstIssuerSigner = diplomaDefaultSigners?.find((signer) => signer.signFor === 'issuer' && signer.signerPosition === 1);
      let secondIssuerSigner = diplomaDefaultSigners?.find((signer) => signer.signFor === 'issuer' && signer.signerPosition === 2);
      let firstRegisterSigner = diplomaDefaultSigners?.find((signer) => signer.signFor === 'register' && signer.signerPosition === 1);
      let secondRegisterSigner = diplomaDefaultSigners?.find((signer) => signer.signFor === 'register' && signer.signerPosition === 2);

      let changeRequest: ChangeDiplomaDefaultSignerRequest;

      if (selectedInstitutionProfile === InstitutionProfile.Issuer) {
        changeRequest = {
          defaultSigners: [
            {
              login: firstSignerLocal.login,
              name: firstSignerLocal.name,
              signFor: 'issuer',
              signerPosition: 1,
            } as SignerData,
            {
              login: secondSignerLocal.login,
              name: secondSignerLocal.name,
              signFor: 'issuer',
              signerPosition: 2,
            } as SignerData,
            {
              login: firstRegisterSigner?.login,
              name: firstRegisterSigner?.name,
              signFor: 'register',
              signerPosition: 1,
            } as SignerData,
            {
              login: secondRegisterSigner?.login,
              name: secondRegisterSigner?.name,
              signFor: 'register',
              signerPosition: 2,
            } as SignerData
          ],
          type: ChangeType.DefaultSignersUpdate
        };
      } else {
        changeRequest = {
          defaultSigners: [
            {
              login: firstIssuerSigner?.login,
              name: firstIssuerSigner?.name,
              signFor: 'issuer',
              signerPosition: 1,
            } as SignerData,
            {
              login: secondIssuerSigner?.login,
              name: secondIssuerSigner?.name,
              signFor: 'issuer',
              signerPosition: 2,
            } as SignerData,
            {
              login: firstSignerLocal?.login,
              name: firstSignerLocal?.name,
              signFor: 'register',
              signerPosition: 1,
            } as SignerData,
            {
              login: secondSignerLocal?.login,
              name: secondSignerLocal?.name,
              signFor: 'register',
              signerPosition: 2,
            } as SignerData
          ],
          type: ChangeType.DefaultSignersUpdate
        };
      }

      await dispatch(changeDiplomaDefaultSigners(changeRequest, diploma?.id));

      signers = [
        {
          ...firstSignerLocal,
          order: 1
        } as Signer,
        {
          ...secondSignerLocal,
          order: 2
        } as Signer
      ];
      const request:
        | RegisterInstitutionValidatesDiplomaRequest
        | IssuerInstitutionValidatesDiplomaRequest = returnRequest();
      sendDiplomaToSignature(request, diploma?.id, setLoadingModal);
    }
  }

  function handleClickCancelButton() {
    setFirstSignerError(false);
    setSecondSignerError(false);
    setFirstSignerLocal(undefined);
    setSecondSignerLocal(undefined);
    setOpenModal(false);
  }

  let firstSigner: SignerData | undefined;
  let secondSigner: SignerData | undefined;

  if (diplomaDefaultSigners) {
    if (selectedInstitutionProfile === InstitutionProfile.Register) {
      firstSigner = diplomaDefaultSigners?.find((signer) => signer.signFor === 'register' && signer.signerPosition === 1);
      secondSigner = diplomaDefaultSigners?.find((signer) => signer.signFor === 'register' && signer.signerPosition === 2);
    } else {
      firstSigner = diplomaDefaultSigners?.find((signer) => signer.signFor === 'issuer' && signer.signerPosition === 1);
      secondSigner = diplomaDefaultSigners?.find((signer) => signer.signFor === 'issuer' && signer.signerPosition === 2);
    }
  }

  return (
    <>
      {openModal && (
        <ConfirmationModal
          openModal
          handleCancelButton={handleClickCancelButton}
          modalTitle={modalInformation.modalSendDiplomaToSignature.title}
          textContinueButton={
            modalInformation.modalSendToSignature.textContinueButton
          }
          handleContinueButton={handleSubmit}
          textCancelButton={modalInformation.textCancelButton}
          loading={loadingModal}
          loadingMessage={
            modalInformation.modalSendDiplomaToSignature.loadingMessage
          }
        >
          <Typography style={{ marginBottom: '32px' }}>
            {modalInformation.modalSendToSignature.description}
          </Typography>

          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="stretch"
          >
            <Grid item xs={5}>
              <EmployeePaper
                employees={employees?.filter(
                  (employee) => employee.id !== secondSignerLocal?.id
                )}
                setEmployees={setFirstSignerLocal}
                selectedEmployee={firstSignerLocal}
                loadingEmployee={loadingEmployee}
                error={firstSignerError}
                order="1"
                defaultSigner={firstSigner}
              />
            </Grid>

            <Grid item>
              <Divider
                orientation="vertical"
                flexItem
                style={{
                  height: '60%',
                  position: 'absolute',
                  color: GRAY_COLOR
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <EmployeePaper
                employees={employees?.filter(
                  (employee) => employee.id !== firstSignerLocal?.id
                )}
                setEmployees={setSecondSignerLocal}
                selectedEmployee={secondSignerLocal}
                loadingEmployee={loadingEmployee}
                error={secondSignerError}
                order="2"
                defaultSigner={secondSigner}
              />
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
            />
          </Grid>
        </ConfirmationModal>
      )}
    </>
  );
};

export default connector(SendDiplomaToSignatureModal);
