import { Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { pageRoutes } from '../../../../utils/constants';
import BackCardHeader from '../../../molecules/back-card-header';
import DefaultTemplate from '../../../templates/default-template';
import CurriculumCoursePaper from '../../../organisms/curriculum-course-paper';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../setup/reducer/reducers';
import CurriculumIssuerInstitutionPaper from '../../../organisms/curriculum-institution-paper';
import InstitutionProfile from '../../../../../domain/enum/InstitutionProfile';
import { useState } from 'react';
import CurriculumValidateActions from './CurriculumValidateActions';
import Curriculum from '../../../../../domain/model/curriculum/Curriculum';
import CurriculumCurricularUnitiesPaper from '../../../organisms/curriculum-curricular-unities-paper';
import CurriculumDataPaper from '../../../organisms/curriculum-data-paper';
import CurriculumComplementaryActivityPaper from '../../../organisms/curriculum-complementary-activity-paper';
import CurriculumCriteriaIntegralizationPaper from '../../../organisms/curriculum-criteria-integralization-paper';
import CurriculumSecurityInfoPaper from '../../../organisms/curriculum-security-info-paper';
import TabName from '../../../../../domain/enum/curriculum/TabName';
import CurriculumLabelPaper from '../../../organisms/curriculum-label-paper';

const PREFIX = 'CurriculumValidatePage';

const classes = {
  root: `${PREFIX}-root`,
  tabButtonActive: `${PREFIX}-tabButtonActive`,
  tabButtonInactive: `${PREFIX}-tabButtonInactive`,
  tabButtonContainer: `${PREFIX}-tabButtonContainer`,
  divTabButton: `${PREFIX}-divTabButton`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    paddingBottom: theme.spacing(2),
  },
  [`& .${classes.tabButtonActive}`]: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '18px',
    marginLeft: '18px'
  },
  [`& .${classes.tabButtonInactive}`]: {
    color: theme.typography.body1.color,
    fontWeight: 600,
    fontSize: '18px',
    marginLeft: '18px'
  },
  [`& .${classes.tabButtonContainer}`]: {
    display: 'flex'
  },
  [`& .${classes.divTabButton}`]: {
    height: '2px',
    width: '20px',
    backgroundColor: theme.palette.primary.main,
    margin: 'auto'
  }
}));

const CurriculumValidatePage = (): JSX.Element => {
  const curriculum = useSelector((state: RootState) => state.curriculumValidatePage.curriculum);
  const loadingCurriculum = useSelector(
    (state: RootState) => state.curriculumValidatePage.isLoadingCurriculum
  );
  const validation = useSelector(
    (state: RootState) => state.curriculumValidatePage.validation
  );

  const [tabName, setTabName] = useState(TabName.iesData);

  const history = useHistory();
  const navigateBackToMicrocertificatesOnClick = () => {
    history.push(pageRoutes.DigitalCurriculum)
  }

  return (
    <Root>
      <DefaultTemplate message="Validação do" highlightedMessage="Currículo" >
        <Grid container item xs={12} className={classes.root} direction={'row'} justifyContent={'space-between'}>
          <Grid item xs={6}>
            <BackCardHeader showDivider={false} onClick={() => navigateBackToMicrocertificatesOnClick()} />
          </Grid>
          <Grid container item xs={6} direction='row' className={classes.root} justifyContent={'flex-end'}>
            <Grid item container xs={12} direction='row' alignItems="center" justifyContent={'flex-end'} className={classes.tabButtonContainer} >
              <div>
                <Button className={tabName === TabName.iesData ? classes.tabButtonActive : classes.tabButtonInactive} onClick={() => setTabName(TabName.iesData)}>Dados da IES e do Curso</Button>
                {tabName === TabName.iesData && <div className={classes.divTabButton} />}
              </div>
              <div>
                <Button className={tabName === TabName.curriculumData ? classes.tabButtonActive : classes.tabButtonInactive} onClick={() => setTabName(TabName.curriculumData)}>Dados do Currículo</Button>
                {tabName === TabName.curriculumData && <div className={classes.divTabButton} />}
              </div>
            </Grid>
          </Grid>
        </Grid>
        {tabName === TabName.iesData ? (
          <Grid container spacing={3}>
            <Grid item>
              <CurriculumCoursePaper course={curriculum?.course} isLoading={loadingCurriculum} validation={validation?.course} />
            </Grid>
            <Grid item>
              <CurriculumIssuerInstitutionPaper institution={curriculum?.issuerInstitution} validation={validation?.issuerInstitution} profile={InstitutionProfile.Issuer} isLoading={loadingCurriculum} />
            </Grid>
          </Grid>
        ) : (
          <Grid container direction='column' spacing={3}>
            <Grid item>
              <CurriculumDataPaper root={curriculum?.root} isLoading={loadingCurriculum} validation={validation} />
            </Grid>
            <Grid item>
              <CurriculumLabelPaper curriculum={curriculum} isLoading={loadingCurriculum} validation={validation} />
            </Grid>
            <Grid item>
              <CurriculumCurricularUnitiesPaper curriculum={curriculum} isLoading={loadingCurriculum} validation={validation?.curricularUnities} />
            </Grid>
            <Grid item>
              <CurriculumComplementaryActivityPaper complementaryActivityCategories={curriculum?.complementaryActivityCategories} isLoading={loadingCurriculum} validation={validation?.complementaryActivityCategories} />
            </Grid>
            <Grid item>
              <CurriculumCriteriaIntegralizationPaper criteriaIntegralization={curriculum?.criteriaIntegralization} isLoading={loadingCurriculum} validation={validation} />
            </Grid>
            <Grid item>
              <CurriculumSecurityInfoPaper curriculum={curriculum} isLoading={loadingCurriculum} validation={validation} />
            </Grid>
          </Grid>
        )}
        {!loadingCurriculum &&
          <CurriculumValidateActions
            curriculum={curriculum as Curriculum}
            error={validation ? true : false}
            currentTab={tabName}
            onPressContinue={() => {
              if (tabName === TabName.iesData) {
                setTabName(TabName.curriculumData);
                return true
              } else {
                return false
              }
            }} />
        }
      </DefaultTemplate>
    </Root>

  );
}

export default CurriculumValidatePage; 
