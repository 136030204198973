import Institution from '../../../../../domain/model/institution/Institution';

export const SET_INSTITUTION = 'app/header/SET_INSTITUTION';
export const SET_INSTITUTIONS = 'app/header/SET_INSTITUTIONS';
export const SET_SELECTED_EDUCATIONAL_STAGE =
  'app/header/SET_SELECTED_EDUCATIONAL_STAGE';

export interface HeaderState {
  institution?: Institution;
  institutions: Institution[];
}

export const INITIAL_STATE: HeaderState = {
  institution: undefined,
  institutions: []
};

interface SetInstitutionAction {
  type: typeof SET_INSTITUTION;
  payload: {
    institution?: Institution;
  };
}

interface SetInstitutionsAction {
  type: typeof SET_INSTITUTIONS;
  payload: {
    institutions: Institution[];
  };
}

export type HeaderActions = SetInstitutionAction | SetInstitutionsAction;
