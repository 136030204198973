import { Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { DARK_GRAY_COLOR } from '../../../../theme';

const CustomizedTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 16,
    background: DARK_GRAY_COLOR,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05), 0px 2px 10px rgba(0, 0, 0, 0.1)',
    boxRadius: '8px',
  },
}))(Tooltip);

export default CustomizedTooltip;
