import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import {
  DARK_GRAY_COLOR,
  FONT_FAMILY_BOLD,
  FONT_FAMILY_SEMIBOLD
} from '../../../../theme';

export const useTypographyEmphasisStyles = makeStyles(() =>
  createStyles({
    emphasizedText: (props: any) => ({
      color: props.baseColor || DARK_GRAY_COLOR,
      '& strong': {
        fontFamily: props.isBold ? FONT_FAMILY_BOLD : FONT_FAMILY_SEMIBOLD,
        color: props.emphasisColor
      }
    })
  })
);
