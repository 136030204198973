enum DiplomaStatus {
  AwaitingRegister = 'Aguardando registro no livro',
  AwaitingRegisterSignature = 'Aguardando assinatura de documentos de registro',
  AwaitingIssuerSignature = 'Aguardando assinatura de documentos de emissão',
  GeneratingIssuerDocuments = 'Gerando documentos de emissão',
  GeneratingAcademicDocumentation = 'Gerando xml de documentação acadêmica',
  GeneratingRegisterDocuments = 'Gerando documentos de registro',
  GeneratingVisualRepresentation = 'Gerando representação visual do diploma digital',
  IssuerConfirmationPending = 'Pendente de confirmação de emissão',
  RegisterConfirmationPending = 'Pendente de confirmação de registro',
  Completed = 'Concluído',
  Sent = 'Enviado',
  Cancelled = 'Anulado',
  TemporarilyCancelled = 'Anulado temporariamente',
  RestartDiplomaFlow = 'Reiniciando Fluxo',
  InSync = 'Em sincronização'
}

export default DiplomaStatus;
