import Sex from '../../../../../domain/enum/Sex';
import Employee from '../../../../../domain/model/employee/Employee';

export const SET_DEFAULT_USER_MODAL = 'app/users-page/SET_DEFAULT_USER_MODAL';

export interface UsersState {
  employee?: Employee;
}

export const INITIAL_STATE: UsersState = {
  employee: {
    name: '',
    email: '',
    cpf: '',
    sex: Sex.NA,
    position: '',
    canSignDocuments: false,
    login: '',
    registerNumber: '',
    institutions: {},
    id: ''
  }
};

interface SetDefaultUserModal {
  type: typeof SET_DEFAULT_USER_MODAL;
  payload?: Employee;
}

export type DefaultUserModalActions = SetDefaultUserModal;
