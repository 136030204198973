import {
  EditModelsCertificateActions,
  EditModelState,
  INITIAL_STATE,
  SET_EDIT_MODELS_CERTIFICATE,
  SET_LOADING_EDIT_MODELS,
  SET_SEARCH
} from './types';

export default function reducer(
  state: EditModelState = INITIAL_STATE,
  action: EditModelsCertificateActions
): EditModelState {
  switch (action.type) {
    case SET_EDIT_MODELS_CERTIFICATE:
      return {
        ...state,
        editModelsCertificate: action.payload.editModelsCertificate
      };
    case SET_LOADING_EDIT_MODELS:
      return {
        ...state,
        loadingEditModels: action.payload.loadingEditModels
      };
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload.search
      };
    default:
      return state;
  }
}
