import {
  Divider, Button, Typography, Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import React from 'react';
import { ChevronLeft } from 'react-feather';
import { DARK_GRAY_COLOR, FONT_FAMILY_REGULAR } from '../../../../theme';

const PREFIX = 'BackCardHeader';

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  label: `${PREFIX}-label`,
  icon: `${PREFIX}-icon`,
  divider: `${PREFIX}-divider`
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    height: '60px',
  },

  [`& .${classes.button}`]: {
    height: '59px',
    left: '0px',
    top: '0px',
    alignItems: 'center',
    padding: '17px 18px',
  },

  [`& .${classes.label}`]: {
    lineHeight: '24px',
    left: '28px',
    fontSize: '18px',
    bottom: '0%',
    fontFamily: FONT_FAMILY_REGULAR,
    color: DARK_GRAY_COLOR,
  },

  [`& .${classes.icon}`]: {
    color: DARK_GRAY_COLOR,
    top: '2px',
  },

  [`& .${classes.divider}`]: {
    height: '1px',
    top: '59px',
  }
}));

type Props = {
  onClick: () => void,
  showDivider?: boolean
};

const BackCardHeader = (props: Props) => {
  const { onClick, showDivider = true } = props;


  return (
    <Root>
      <Grid className={classes.root}>
        <Button
          onClick={onClick}
          className={classes.button}
          startIcon={(
            <ChevronLeft
              className={classes.icon}
              size="20px"
            />
          )}
        >
          <Typography className={classes.label}>Voltar</Typography>
        </Button>
        {showDivider && <Divider className={classes.divider} />}
      </Grid>
    </Root>
  );
};

export default BackCardHeader;
