import {
  DiplomaPageValidateState,
  SELECT_DIPLOMA,
  DiplomaPageValidateActions,
  INITIAL_STATE,
  TOGGLE_NEXT_PAGE,
  TOGGLE_IS_LOADING_DIPLOMA,
  TOGGLE_INFO_DIPLOMA,
  UPDATE_COMPONENT_VALIDATE_ACTIVE
} from './types';

export default function reducer(
  state: DiplomaPageValidateState = INITIAL_STATE,
  action: DiplomaPageValidateActions
): DiplomaPageValidateState {
  switch (action.type) {
    case SELECT_DIPLOMA:
      return {
        ...state,
        diploma: action.payload.diploma,
        validation: action.payload.validation
      };
    case TOGGLE_INFO_DIPLOMA:
      return {
        ...state,
        info: !state.info
      };
    case TOGGLE_NEXT_PAGE:
      return {
        ...state,
        nextPage: !state.nextPage
      };
    case TOGGLE_IS_LOADING_DIPLOMA:
      return {
        ...state,
        isLoadingDiploma: !state.isLoadingDiploma
      };
    case UPDATE_COMPONENT_VALIDATE_ACTIVE:
      return {
        ...state,
        componentValidateActive: action.payload.componentValidateActive
      };
    default:
      return state;
  }
}
