import Address from './Address';
import { CnpjMask } from '../../shared/utils/stringMasks';

export default class Sponsor {
  corporateName?: string;

  cnpj?: string;

  address?: Address;

  static stringify(sponsor: Sponsor | undefined): string | undefined {
    if (!sponsor) {
      return undefined;
    }
    let description: string = sponsor.corporateName || '';
    if (sponsor.cnpj) {
      if (description) {
        description += '\n';
      }
      description += `CNPJ ${CnpjMask.apply(sponsor.cnpj)}`;
    }
    return description;
  }
}
