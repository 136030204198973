import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import React from 'react';
import { User } from 'react-feather';
import moment from 'moment';
import { cpf } from 'cpf-cnpj-validator';
import CardTitle from '../../molecules/card-title';
import { stringify as stringifySex } from '../../../../domain/enum/Sex';
import GoBackPaper from '../../molecules/go-back-paper';
import StateId from '../../../../domain/model/diploma/StateId';
import { GraduatePaperProps } from './types';
import LabeledTooltip from '../../molecules/labeled-tooltip';

const PREFIX = 'index';

const classes = {
  icon: `${PREFIX}-icon`,
  graduateContainer: `${PREFIX}-graduateContainer`
};

const StyledGoBackPaper = styled(GoBackPaper)(({ theme }) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main
  },

  [`& .${classes.graduateContainer}`]: {
    wordWrap: 'break-word'
  }
}));

export default function GraduatePaper({
  graduate,
  onClickBack,
  validation,
  isLoading,
  originSystem,
  processNumber
}: GraduatePaperProps): JSX.Element {
  const isNationalityBrasilian: boolean = !!(
    graduate?.nationality && graduate?.nationality?.includes('brasileir')
  );

  const hasStateId: boolean = !!(
    graduate?.stateId?.number || graduate?.stateId?.state
  );

  return (
    <StyledGoBackPaper onClickBack={onClickBack}>
      <Grid className={classes.graduateContainer} container item xs={12}>
        <Grid item xs={12}>
          <CardTitle icon={<User className={classes.icon} />}>
            Diplomado
          </CardTitle>
        </Grid>
        <Grid container item xs={12}>
          <LabeledTooltip
            label="Nome"
            error={validation?.name}
            errorMessage={validation?.name}
            isLoading={isLoading}
            data-testid="diploma-graduate-name"
            originSystem={originSystem}
            processNumber={processNumber}
          >
            {graduate?.name || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={12}>
          <LabeledTooltip
            label="Email"
            error={validation?.email}
            errorMessage={validation?.email}
            isLoading={isLoading}
            data-testid="diploma-graduate-email"
            originSystem={originSystem}
            processNumber={processNumber}
          >
            {graduate?.email || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={6}>
          <LabeledTooltip
            label="Nome Social"
            error={validation?.socialName}
            errorMessage={validation?.socialName}
            isLoading={isLoading}
            data-testid="diploma-graduate-social-name"
            originSystem={originSystem}
            processNumber={processNumber}
          >
            {graduate?.socialName || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={6}>
          <LabeledTooltip
            label="Sexo"
            error={validation?.sex}
            errorMessage={validation?.sex}
            isLoading={isLoading}
            data-testid="diploma-graduate-sex"
            originSystem={originSystem}
            processNumber={processNumber}
          >
            {(graduate?.sex && stringifySex(graduate.sex)) || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip
            label="Matrícula"
            error={validation?.registrationNumber}
            errorMessage={validation?.registrationNumber}
            isLoading={isLoading}
            data-testid="diploma-graduate-registration-number"
            originSystem={originSystem}
            processNumber={processNumber}
          >
            {graduate?.registrationNumber || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip
            label="Protocolo"
            isLoading={isLoading}
            data-testid="diploma-graduate-process-number"
            originSystem={originSystem}
            processNumber={processNumber}
          >
            {processNumber || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip
            label="CPF"
            error={validation?.cpf}
            errorMessage={validation?.cpf}
            isLoading={isLoading}
            data-testid="diploma-graduate-cpf"
            originSystem={originSystem}
            processNumber={processNumber}
          >
            {(graduate && cpf.format(graduate.cpf)) || 'N/A'}
          </LabeledTooltip>
        </Grid>
        {hasStateId || isNationalityBrasilian ? (
          <>
            <Grid item xs={4}>
              <LabeledTooltip
                isLoading={isLoading}
                label="RG"
                error={
                  validation?.stateId?.number ||
                  validation?.stateId?.state ||
                  (typeof validation?.stateId === 'string' &&
                    validation?.stateId)
                }
                errorMessage={
                  validation?.stateId?.number ||
                  validation?.stateId?.state ||
                  (typeof validation?.stateId === 'string' &&
                    validation?.stateId)
                }
                data-testid="diploma-graduate-rg"
                originSystem={originSystem}
                processNumber={processNumber}
              >
                {StateId.stringify(graduate?.stateId) || 'N/A'}
              </LabeledTooltip>
            </Grid>
            <Grid item xs={4}>
              <LabeledTooltip
                label="Órgão emissor"
                error={validation?.stateId?.issuingAuthority}
                errorMessage={validation?.stateId?.issuingAuthority}
                isLoading={isLoading}
                data-testid="diploma-graduate-issuing-authority"
                originSystem={originSystem}
                processNumber={processNumber}
              >
                {graduate?.stateId?.issuingAuthority || 'N/A'}
              </LabeledTooltip>
            </Grid>
            <Grid item xs={4}>
              <LabeledTooltip
                label="Data de expedição"
                error={validation?.stateId?.issuedAt}
                errorMessage={validation?.stateId?.issuedAt}
                isLoading={isLoading}
                data-testid="diploma-graduate-issued-date"
                originSystem={originSystem}
                processNumber={processNumber}
              >
                {(graduate?.stateId?.issuedAt &&
                  moment(graduate?.stateId?.issuedAt, 'YYYY-MM-DD').format(
                    'DD/MM/YYYY'
                  )) ||
                  'N/A'}
              </LabeledTooltip>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={6}>
              <LabeledTooltip
                label="Tipo de documento"
                error={validation?.documentType}
                errorMessage={validation?.documentType}
                isLoading={isLoading}
                data-testid="diploma-graduate-document-type"
                originSystem={originSystem}
                processNumber={processNumber}
              >
                {graduate?.documentType || 'N/A'}
              </LabeledTooltip>
            </Grid>
            <Grid item xs={6}>
              <LabeledTooltip
                label="Identificador"
                error={validation?.identifier}
                errorMessage={validation?.identifier}
                isLoading={isLoading}
                data-testid="diploma-graduate-identifier"
                originSystem={originSystem}
                processNumber={processNumber}
              >
                {graduate?.identifier || 'N/A'}
              </LabeledTooltip>
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <LabeledTooltip
            label="Data de Nascimento"
            error={validation?.dateOfBirth}
            errorMessage={validation?.dateOfBirth}
            isLoading={isLoading}
            data-testid="diploma-graduate-birthday"
            originSystem={originSystem}
            processNumber={processNumber}
          >
            {(graduate?.dateOfBirth &&
              moment(graduate.dateOfBirth, 'YYYY-MM-DD').format(
                'DD/MM/YYYY'
              )) ||
              'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={6}>
          <LabeledTooltip
            label="Nacionalidade"
            error={validation?.nationality}
            errorMessage={validation?.nationality}
            isLoading={isLoading}
            data-testid="diploma-graduate-nationality"
            originSystem={originSystem}
            processNumber={processNumber}
          >
            {graduate?.nationality || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={12}>
          <LabeledTooltip
            label="Naturalidade"
            error={validation?.placeOfBirth}
            errorMessage={
              validation?.placeOfBirth?.name ||
              validation?.placeOfBirth?.state ||
              validation?.placeOfBirth?.code
            }
            isLoading={isLoading}
            data-testid="diploma-graduate-naturalness"
            originSystem={originSystem}
            processNumber={processNumber}
          >
            {(graduate?.placeOfBirth?.name &&
              graduate?.placeOfBirth?.state &&
              `${graduate?.placeOfBirth?.name} - ${graduate?.placeOfBirth?.state}`) ||
              graduate?.placeOfBirth?.name ||
              graduate?.placeOfBirth?.state ||
              'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={6}>
          <LabeledTooltip
            label="Data de Conclusão de Curso"
            error={validation?.conclusionDate}
            errorMessage={validation?.conclusionDate}
            isLoading={isLoading}
            data-testid="diploma-graduate-conclusion-date"
            originSystem={originSystem}
            processNumber={processNumber}
          >
            {(graduate?.conclusionDate &&
              moment(graduate.conclusionDate, 'YYYY-MM-DD').format(
                'DD/MM/YYYY'
              )) ||
              'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={6}>
          <LabeledTooltip
            label="Data de Colação de Grau"
            error={validation?.graduationCeremonyDate}
            errorMessage={validation?.graduationCeremonyDate}
            isLoading={isLoading}
            data-testid="diploma-graduate-ceremony-date"
            originSystem={originSystem}
            processNumber={processNumber}
          >
            {(graduate?.graduationCeremonyDate &&
              moment(graduate.graduationCeremonyDate, 'YYYY-MM-DD').format(
                'DD/MM/YYYY'
              )) ||
              'N/A'}
          </LabeledTooltip>
        </Grid>
      </Grid>
    </StyledGoBackPaper>
  );
}
