import AddIcon from '@mui/icons-material/Add';
import { Button, Divider, Grid, Paper } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Edit } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { theme } from "../../../../../theme";
import Certificate from '../../../../icons/Certificate';
import { pageRoutes } from '../../../../utils/constants';
import CardTitle from "../../../molecules/card-title";
import CertificateFilter from './CertificateFilter';
import CertificateTable from './CertificateTable';
import { setCertificateTypes } from '../../certificate-settings-model-page/store/actions';
import { useDispatch } from 'react-redux';

const PREFIX = 'CertificateList';

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  buttonAdd: `${PREFIX}-buttonAdd`,
  buttonCreateModel: `${PREFIX}-buttonCreateModel`,
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    marginBottom: '50px',
    maxHeight: '1000px',
  },
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main
  },
  [`& .${classes.buttonAdd}`]: {
    height: '2.5rem',
    marginRight: '2rem',
  },
  [`& .${classes.buttonCreateModel}`]: {
    height: '2.5rem',
    fontWeight: 'bold',
    marginRight: '2rem',


  },
}));

export default function CertificateList(): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch()

  const navigateToIssuanceOfCertificatesOnClick = () => {
    history.push(pageRoutes.IssuanceCertificate)
  }
  const navigateToEditModalCertificateOnClick = async () => {
    dispatch(await setCertificateTypes());
    history.push(pageRoutes.SettingsCertificate)
  }
  const enableEditModelsButton = process.env.REACT_APP_ENABLE_BUTTON_CREATE_MODEL === 'true'

  return (
    <Root>
      <Paper className={classes.root}>
        <Grid container item xs={12} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <CardTitle icon={<Certificate color={theme.palette.primary.main} />}>
            Microcertificados
          </CardTitle>
          <Grid>
            {enableEditModelsButton && (<Button variant="text" size="large" startIcon={<Edit />} onClick={() => navigateToEditModalCertificateOnClick()} className={classes.buttonCreateModel} >Editar Modelos</Button>)}
            <Button variant="contained" size="large" startIcon={<AddIcon />} onClick={() => navigateToIssuanceOfCertificatesOnClick()} className={classes.buttonAdd} >Gerar Certificado</Button>
          </Grid>
        </Grid>

        <Divider />
        <>
          <CertificateFilter />
          <CertificateTable />
        </>
      </Paper>
    </Root>
  );
}
