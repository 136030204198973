import Dou from '../../../../../domain/model/dou/Dou';
import { DouFilterBatchStatus, DouFilterBatchType } from '../types';

export interface DouListState {
  search?: number;
  status?: DouFilterBatchStatus;
  type?: DouFilterBatchType;
  batches: Array<Dou>;
  loadingBatches: boolean;
}

export const INITIAL_STATE: DouListState = {
  search: undefined,
  status: { name: 'Todos' },
  type: { name: 'Todos' },
  batches: [],
  loadingBatches: false
};

export const SET_SEARCH = 'app/dou-list/SET_SEARCH';
export const SET_STATUS = 'app/dou-list/SET_STATUS';
export const SET_BATCHES = 'app/dou-list/SET_BATCHES';
export const SET_LOADING_BATCHES = 'app/dou-list/SET_LOADING_BATCHES';
export const SET_TYPE = 'app/dou-list/SET_TYPE';

interface SetSearchAction {
  type: typeof SET_SEARCH;
  payload: {
    search?: number;
  };
}

interface SetStatusAction {
  type: typeof SET_STATUS;
  payload: {
    status?: DouFilterBatchStatus;
  };
}

interface SetBatchesAction {
  type: typeof SET_BATCHES;
  payload: {
    batches: Array<Dou>;
  };
}

interface SetLoadingBatchesAction {
  type: typeof SET_LOADING_BATCHES;
  payload: {
    loadingBatches: boolean;
  };
}

interface SetTypeAction {
  type: typeof SET_TYPE;
  payload: {
    type?: DouFilterBatchType;
  };
}

export type DouListActions =
  | SetSearchAction
  | SetStatusAction
  | SetBatchesAction
  | SetLoadingBatchesAction
  | SetTypeAction;
