enum GraduateDocumentType {
  ResponsabilityTerm = 'Termo de responsabilidade',
  StateId = 'Documento de identidade do aluno',
  HighSchoolCompletion = 'Prova conclusão ensino medio',
  GraduationCerimonyDocument = 'Prova colação',
  ObligatoryInternshipReceipt = 'Comprovação estágio curricular',
  Transcript = 'Histórico escolar',
  BirthCertificate = 'Certidão nascimento',
  MarriageCertificate = 'Certidão casamento',
  EvidenceOfVoters = 'Título eleitor',
  NationalityAct = 'Ato naturalização',
  Others = 'Outros'
}

export default GraduateDocumentType;
