import { AppBar, Skeleton, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChangeEvent, useEffect, useState } from 'react';
import { BookOpen, FileText, Settings, Users } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import InstitutionProfile from '../../../../domain/enum/InstitutionProfile';
import TypePermission from '../../../../domain/enum/TypePermission';
import { RootState } from '../../../../setup/reducer/reducers';
import {
  DIPLOMA_COLOR,
  FONT_FAMILY_BOLD,
  FONT_FAMILY_SEMIBOLD,
  LIGHT_GRAY_COLOR,
  MEDIUM_GRAY_COLOR,
  OPAQUE_DIPLOMA_COLOR,
  WHITE_COLOR
} from '../../../../theme';
import Certificate from '../../../icons/Certificate';
import Diploma from '../../../icons/Diploma';
import { pageRoutes } from '../../../utils/constants';
import CustomizedTooltip from '../../atoms/customized-tooltip';
import { setShowNameInstitutionDropdowAction } from '../../pages/institution-management/store/actions';
import { listSigners } from '../default-signers-modal/store/actions';
import { setShowVisionDropdowAction } from '../diploma-educational-stage-label/store/actions';

const PREFIX = 'VerticalMenuBar';

const classes = {
  appBar: `${PREFIX}-appBar`,
  root: `${PREFIX}-root`,
  rootTab: `${PREFIX}-rootTab`,
  tabSelected: `${PREFIX}-tabSelected`,
  tabs: `${PREFIX}-tabs`,
  indicator: `${PREFIX}-indicator`,
  skeleton: `${PREFIX}-skeleton`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.appBar}`]: {
    position: 'fixed',
    width: '3.75rem',
    left: '0rem',
    top: '3.5rem',
    boxShadow:
      '0px 1px 1px -2px rgba(0,0,0,0.2), 0px -1px 1px 1px rgba(0,0,0,0.05), 0px 3px 1px 3px rgba(0,0,0,0.01)',
    height: '100%',
    backgroundColor: WHITE_COLOR,
    zIndex: 1,
    display: 'block',
    borderBottom: `1px solid ${LIGHT_GRAY_COLOR}`,
    justifyContent: 'center'
  },

  [`& .${classes.root}`]: {
    fill: MEDIUM_GRAY_COLOR,
    stroke: MEDIUM_GRAY_COLOR
  },

  [`& .${classes.rootTab}`]: {
    color: MEDIUM_GRAY_COLOR,
    fontFamily: FONT_FAMILY_SEMIBOLD,
    fontSize: '16px',
    minWidth: '3.75rem',
    maxWidth: '3.75rem',
    minHeight: '3.75rem',
    maxHeight: '3.75rem',
    opacity: '1'
  },

  [`& .${classes.tabSelected}`]: {
    fontFamily: FONT_FAMILY_BOLD,
    color: DIPLOMA_COLOR,
    backgroundColor: OPAQUE_DIPLOMA_COLOR
  },

  [`& .${classes.tabs}`]: {
    display: 'inline-block'
  },

  [`& .${classes.indicator}`]: {
    marginTop: '0.75rem',
    left: '0rem',
    width: '0.188rem',
    height: '2.25rem !important',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '0px 64px 64px 0px;'
  },

  [`& .${classes.skeleton}`]: {
    left: '0.625rem',
    height: '4.1671rem',
    width: '2.5rem',
    background:
      'linear-gradient(90deg, #D2D2D2 0%, rgba(152, 151, 151, 0) 113.84%)',
    borderRadius: '6px'
  }
}));

const VerticalMenuBar = () => {
  const showSignDocuments = useSelector(
    (state: RootState) => state.authorization.showSignDocuments
  );
  const selectedInstitutionProfile = useSelector(
    (state: RootState) => state.diplomaFilter.selectedInstitutionProfile
  );
  const selectedInstitution = useSelector(
    (state: RootState) => state.header.institution
  );

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const roles = useSelector((state: RootState) => state.login.user?.roles);
  const permissionRoles = roles ? roles.split(' ') : []

  const isRegister = (val?: string) => val === InstitutionProfile.Register;

  const isEnableMenu = (flag?: string) => flag === 'false' ? false : true;

  const getColor = (buttonRoute: string) =>
    buttonRoute === history.location.pathname
      ? DIPLOMA_COLOR
      : MEDIUM_GRAY_COLOR;

  const certificateIconActive = [pageRoutes.Certificate, pageRoutes.CertificateInfo, pageRoutes.IssuanceCertificate, pageRoutes.SettingsCertificate, pageRoutes.CreateCertificateSettings, pageRoutes.UpdateCertificateSettings]
  const getColorCertificate = () => certificateIconActive.includes(location.pathname) ? DIPLOMA_COLOR : MEDIUM_GRAY_COLOR

  const initialRoute = () => {
    if (showSignDocuments) {
      return pageRoutes.DiplomaManagement;
    }
    return location.pathname;
  };

  const [selectedTab, setSelectedTab] = useState<string>(initialRoute);

  const changeRouteAndTab = (pageRoute: string) => {
    history.push(pageRoute);
    setSelectedTab(pageRoute);
  };

  function verifyPermission(permission: string) {
    return permissionRoles.includes(permission)
  }

  const handleChange = (_event: ChangeEvent<{}>, value: string) => {
    if (
      value === pageRoutes.InstitutionManagement ||
      value === pageRoutes.UsersManagement ||
      value === pageRoutes.Certificate
    ) {
      dispatch(setShowNameInstitutionDropdowAction(false));
      dispatch(setShowVisionDropdowAction(true));
    } else if (value === pageRoutes.DigitalCurriculum || value === pageRoutes.DigitalCurriculumInfo || value === pageRoutes.DigitalCurriculumValidate) {
      dispatch(setShowNameInstitutionDropdowAction(true));
      dispatch(setShowVisionDropdowAction(true));
    } else {
      if (selectedInstitution?.mecCode) {
        dispatch(listSigners(selectedInstitution?.mecCode));
      }
      dispatch(setShowNameInstitutionDropdowAction(true));
      dispatch(setShowVisionDropdowAction(false));
    }

    changeRouteAndTab(value);
  };

  useEffect(() => {
    if (
      Object.values(pageRoutes).includes(history.location.pathname) &&
      history.location.pathname !== pageRoutes.Base &&
      history.location.pathname !== pageRoutes.LoginMicrosoft &&
      history.location.pathname !== pageRoutes.Login &&
      history.location.pathname !== pageRoutes.PublicPortal
    ) {

      if (selectedTab === pageRoutes.DiplomaManagement) {
        changeRouteAndTab(pageRoutes.DiplomaManagement);
      } else {
        changeRouteAndTab(history.location.pathname);
      }
    }
    // eslint-disable-next-line
  }, [history, selectedInstitution, selectedInstitutionProfile]);

  return (
    <Root>
      {!selectedInstitution || showSignDocuments === undefined ? (
        <AppBar className={classes.appBar} position="fixed">
          .   <Skeleton className={classes.skeleton} animation="wave" />
          <Skeleton className={classes.skeleton} animation="wave" />
          <Skeleton className={classes.skeleton} animation="wave" />
        </AppBar>
      ) : (
        <AppBar className={classes.appBar} position="fixed">
          {!showSignDocuments && (
            <Tabs
              orientation="vertical"
              className={classes.tabs}
              classes={{ indicator: classes.indicator }}
              value={selectedTab}
              onChange={handleChange}
            >
              {verifyPermission(TypePermission.DiplomaTrackingRead) && <Tab
                icon={
                  <CustomizedTooltip title="Diplomas" placement="right">
                    <span style={{ textAlign: 'center' }}>
                      <Diploma
                        color={getColor(pageRoutes.DiplomaPage)}
                        size={24}
                      />
                    </span>
                  </CustomizedTooltip>
                }
                value={pageRoutes.DiplomaPage}
                disableRipple
                classes={{
                  root: classes.rootTab,
                  selected: classes.tabSelected
                }}
              />}

              {(!isRegister(selectedInstitutionProfile) && verifyPermission(TypePermission.BatchControlDouRead)) && (
                <Tab
                  icon={
                    <CustomizedTooltip title="DOU" placement="right">
                      <span style={{ textAlign: 'center' }}>
                        <BookOpen size={24} />
                      </span>
                    </CustomizedTooltip>
                  }
                  value={pageRoutes.DouPage}
                  disableRipple
                  classes={{
                    root: classes.rootTab,
                    selected: classes.tabSelected
                  }}
                />
              )}

              {verifyPermission(TypePermission.InstitutionManagementRead) && <Tab
                icon={
                  <CustomizedTooltip title="Gestão da Instituição" placement="right">
                    <span style={{ textAlign: 'center' }}>
                      <Settings size={24} />
                    </span>
                  </CustomizedTooltip>
                }
                value={pageRoutes.InstitutionManagement}
                disableRipple
                classes={{
                  root: classes.rootTab,
                  selected: classes.tabSelected
                }}
              />}

              {verifyPermission(TypePermission.UsersRegistrationRead) && <Tab
                icon={
                  <CustomizedTooltip title="Usuários" placement="right">
                    <span style={{ textAlign: 'center' }}>
                      <Users size={24} />
                    </span>
                  </CustomizedTooltip>
                }
                value={pageRoutes.UsersManagement}
                disableRipple
                classes={{
                  root: classes.rootTab,
                  selected: classes.tabSelected
                }}
              />}

              {verifyPermission(TypePermission.MicrocertificatesRead) && (
                <Tab
                  icon={
                    <CustomizedTooltip title="Microcertificados" placement="right">
                      <span style={{ textAlign: 'center' }}>
                        <Certificate size={24} color={getColorCertificate()} />
                      </span>
                    </CustomizedTooltip>
                  }
                  value={pageRoutes.Certificate}
                  disableRipple
                  classes={{
                    root: classes.rootTab,
                    selected: classes.tabSelected
                  }}
                />)}
              {(isEnableMenu(process.env.REACT_APP_ENABLE_MENU_CURRICULUM) && verifyPermission(TypePermission.DigitalCurriculumRead)) && (
                <Tab
                  icon={
                    <CustomizedTooltip title="Currículo Digital" placement="right">
                      <span style={{ textAlign: 'center' }}>
                        <FileText size={24} />
                      </span>
                    </CustomizedTooltip>
                  }
                  value={pageRoutes.DigitalCurriculum}
                  disableRipple
                  classes={{
                    root: classes.rootTab,
                    selected: classes.tabSelected
                  }}
                />)}
            </Tabs>
          )}
          {showSignDocuments && (
            <Tabs
              orientation="vertical"
              className={classes.tabs}
              classes={{ indicator: classes.indicator }}
              value={selectedTab}
              onChange={handleChange}
            >
              <Tab
                icon={
                  <CustomizedTooltip title="Gestão" placement="right">
                    <span style={{ textAlign: 'center' }}>
                      <Settings size={24} />
                    </span>
                  </CustomizedTooltip>
                }
                value={pageRoutes.DiplomaManagement}
                disableRipple
                classes={{
                  root: classes.rootTab,
                  selected: classes.tabSelected
                }}
              />
            </Tabs>
          )}
        </AppBar>
      )}
    </Root>
  );
};

export default VerticalMenuBar;


