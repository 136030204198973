import State from '../enum/State';

export default class City {
  code?: string;

  name?: string;

  state?: State;

  static stringify(city: City | undefined): string | undefined {
    if (!city) {
      return undefined;
    }
    let cityDescription = city.name || '';
    if (city.state) {
      cityDescription += `/${city.state}`;
    }
    return cityDescription;
  }
}
