import City from './City';
import { CepMask } from '../../shared/utils/stringMasks';

export default class Address {
  streetName?: string;

  number?: string;

  complement?: string;

  neighborhood?: string;

  postalCode?: string;

  city?: City;

  static stringify(address: Address | undefined): string | undefined {
    if (!address) {
      return undefined;
    }

    const description: string[] = [];
    const streetAddress: string[] = [];
    if (address.streetName) {
      streetAddress.push(address.streetName);
    }
    if (address.number) {
      streetAddress.push(address.number);
    }
    if (address.complement) {
      streetAddress.push(address.complement);
    }
    if (streetAddress.length) {
      description.push(streetAddress.join(', '));
    }
    if (address.neighborhood) {
      description.push(address.neighborhood);
    }
    if (address.city && address.city.name) {
      description.push(City.stringify(address.city) || '');
    }
    if (address.postalCode) {
      description.push(CepMask.apply(address.postalCode));
    }
    return description.join(' - ');
  }
}
