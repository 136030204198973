import React, { useEffect, useState, Fragment } from 'react';
import { Grid, Typography } from '@mui/material';
import { StepStatusTimelineProps, Step } from './types';
import { GRAY_COLOR } from '../../../../theme';
import { useStagesTimelineStyles } from './styles';
import StepConnector from './StepConnector';
import { StepDot } from './StepDot';

const defaultStep: Step = {
  value: 'N/A',
  color: GRAY_COLOR,
};

export default function StagesTimeline({ steps, currentValue }: StepStatusTimelineProps) {
  const [currentStep, setCurrentStep] = useState<Step>(defaultStep);
  const styles = useStagesTimelineStyles({ currentStepColor: currentStep.color });

  const getCurrentStep = () => {
    return steps.find((step) => step.value.toUpperCase() === currentValue.toUpperCase());
  };

  useEffect(() => {
    setCurrentStep(getCurrentStep() || defaultStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isActiveStep = (value: string) =>
    (value.toUpperCase() === currentStep?.value.toUpperCase())
    && !currentStep?.transitoryStatus;

  const isLastStep = (index: number) => index + 1 === steps.length;

  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      container
    >
      <Grid>
        <Typography className={styles.label}>
          {(currentStep.label || currentStep.value).toUpperCase()}
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        {
          steps.map((step, i) => (
            <Fragment key={step.value}>
              <Grid item>
                <StepDot
                  currentStepColor={currentStep.color}
                  active={isActiveStep(step.value)}
                />
              </Grid>
              {!isLastStep(i) && <Grid item><StepConnector /></Grid>}
            </Fragment>
          ))
        }
      </Grid>
    </Grid>
  );
}
