import {
  DiplomaModalInstitutionInfoActions,
  INITIAL_STATE,
  InstitutionState,
  SET_INSTITUTION,
  SET_LOADING_INSTITUTION,
  SET_LOADING_SAVE_SETTINGS
} from './types';

export default function reducer(
  state: InstitutionState = INITIAL_STATE,
  action: DiplomaModalInstitutionInfoActions
): InstitutionState {
  switch (action.type) {
    case SET_INSTITUTION:
      return {
        ...state,
        institution: action.payload.institution
      };
    case SET_LOADING_INSTITUTION:
      return {
        ...state,
        loadingInstitution: action.payload.loadingInstitution
      };
    case SET_LOADING_SAVE_SETTINGS:
      return {
        ...state,
        loadingSaveSettings: action.payload.loadingSaveSettings
      };

    default:
      return state;
  }
}
