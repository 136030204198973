import React from 'react';
import { InputAdornment } from '@mui/material';
import { XCircle } from 'react-feather';
import { CRITICAL_COLOR } from '../../../../theme';

interface ErrorInputAdornmentProps {
  show: boolean;
}

const ErrorInputAdornment = (props: ErrorInputAdornmentProps) => {
  const { show } = props;
  return (
    <>
      {show && <InputAdornment position="end"><XCircle color={CRITICAL_COLOR} /></InputAdornment>}
    </>
  );
};

export default ErrorInputAdornment;
