import { Grid, Paper, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CardTitle from '../../molecules/card-title';
import { CnpjMask } from '../../../utils/stringMasks';
import RegulatoryAct from '../../../../domain/model/RegulatoryAct';
import Address from '../../../../domain/model/Address';
import Sponsor from '../../../../domain/model/Sponsor';
import InstitutionProfile from '../../../../domain/enum/InstitutionProfile';
import { FONT_FAMILY_EXTRABOLD } from '../../../../theme';
import { InstitutionPaperProps } from './types';
import LabeledText from '../../molecules/labeled-text';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.primary.main
    },
    typography: {
      fontFamily: FONT_FAMILY_EXTRABOLD,
      color: theme.palette.primary.main
    }
  })
);

export default function InstitutionPaper({
  institution,
  profile,
  validation,
  isLoading
}: InstitutionPaperProps): JSX.Element {
  const classes = useStyles();

  return (
    <Paper>
      <Grid data-testid="institution-paper" container item xs={12}>
        <Grid item xs={12}>
          <CardTitle
            icon={<BusinessIcon className={classes.icon} />}
          >{`IES ${profile}`}</CardTitle>
        </Grid>
        <Grid item xs={6}>
          <LabeledText
            label="Nome"
            error={validation?.name}
            errorMessage={validation?.name}
            isLoading={isLoading}
            data-testid="diploma-institution-name"
          >
            {institution?.name || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={4}>
          <LabeledText
            label="CNPJ"
            error={validation?.cnpj}
            errorMessage={validation?.cnpj}
            isLoading={isLoading}
            data-testid="diploma-institution-cnpj"
          >
            {institution?.cnpj ? CnpjMask.apply(institution.cnpj) : 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={2}>
          <LabeledText
            label="Código MEC"
            error={validation?.mecCode}
            errorMessage={validation?.mecCode}
            isLoading={isLoading}
            data-testid="diploma-institution-mec-code"
          >
            {institution?.mecCode?.toString() || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={6}>
          <LabeledText
            label="Endereço"
            error={validation?.address}
            errorMessage={validation?.address ? 'Endereço inválido' : undefined}
            isLoading={isLoading}
            data-testid="diploma-institution-address"
          >
            {Address.stringify(institution?.address) || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={6}>
          <LabeledText
            label="Marca"
            error={validation?.brand}
            errorMessage={validation?.brand ? 'Marca inválida' : undefined}
            isLoading={isLoading}
            data-testid="diploma-institution-brand"
          >
            {institution?.brand || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={4}>
          <LabeledText
            label="Credenciamento"
            error={validation?.licensing}
            errorMessage={
              validation?.licensing ? 'Credenciamento inválido' : undefined
            }
            isLoading={isLoading}
            data-testid="diploma-institution-licensing"
          >
            {RegulatoryAct.stringify(institution?.licensing) || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={4}>
          <LabeledText
            label="Recredenciamento"
            error={validation?.relicensing}
            errorMessage={
              validation?.relicensing ? 'Recredenciamento inválido' : undefined
            }
            isLoading={isLoading}
            data-testid="diploma-institution-relicensing"
          >
            {RegulatoryAct.stringify(institution?.relicensing) || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={4}>
          <LabeledText
            label="Renovação de Recredenciamento"
            error={validation?.relicensingRenewal}
            errorMessage={
              validation?.relicensingRenewal
                ? 'Renovação de Recredenciamento inválida'
                : undefined
            }
            isLoading={isLoading}
            data-testid="diploma-institution-relicensing-renewal"
          >
            {RegulatoryAct.stringify(institution?.relicensingRenewal) || 'N/A'}
          </LabeledText>
        </Grid>
        {profile === InstitutionProfile.Register ? (
          <Grid item>
            <LabeledText
              label="Mantenedora"
              error={validation?.sponsor}
              errorMessage={
                validation?.sponsor ? 'Mantenedora inválida' : undefined
              }
              isLoading={isLoading}
              data-testid="diploma-institution-sponsor"
            >
              {Sponsor.stringify(institution?.sponsor) || 'N/A'}
            </LabeledText>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <CardTitle
                variant="body1"
                classes={classes}
                icon={<AccountBalanceIcon className={classes.icon} />}
              >
                Mantenedora
              </CardTitle>
            </Grid>
            <Grid item xs={4}>
              <LabeledText
                label="CNPJ"
                error={validation?.sponsor?.cnpj}
                errorMessage={validation?.sponsor?.cnpj}
                isLoading={isLoading}
                data-testid="diploma-institution-sponsor-cnpj"
              >
                {institution?.sponsor?.cnpj
                  ? CnpjMask.apply(institution?.sponsor?.cnpj)
                  : 'N/A'}
              </LabeledText>
            </Grid>
            <Grid item xs={8}>
              <LabeledText
                label="Razão Social"
                error={validation?.sponsor?.corporateName}
                errorMessage={validation?.sponsor?.corporateName}
                isLoading={isLoading}
                data-testid="diploma-institution-sponsor-corporate-name"
              >
                {institution?.sponsor?.corporateName || 'N/A'}
              </LabeledText>
            </Grid>
            <Grid item xs={12}>
              <LabeledText
                label="Endereço"
                error={validation?.sponsor?.address}
                errorMessage={
                  validation?.sponsor?.address ? 'Endereço inválido' : undefined
                }
                isLoading={isLoading}
                data-testid="diploma-institution-sponsor-address"
              >
                {Address.stringify(institution?.sponsor?.address) || 'N/A'}
              </LabeledText>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
}
