import React from 'react';

const ExternalLink = () => (
  <svg width="223" height="205" viewBox="0 0 223 205" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M102.396 112.789C102.356 112.789 102.331 112.788 102.322 112.787L97.5542 112.788V112.086H102.34C102.45 112.09 104.711 112.154 106.794 109.631C109.897 105.872 112.446 96.5671 106.963 73.5829C99.5897 42.6774 105.739 15.3741 108.725 5.06989C108.873 4.5601 108.899 4.02315 108.802 3.50146C108.706 2.97977 108.489 2.48764 108.169 2.06395C107.849 1.64026 107.435 1.29662 106.959 1.06019C106.483 0.823756 105.959 0.701006 105.427 0.701636H34.2177C33.6763 0.702361 33.1427 0.830471 32.6602 1.07557C32.1777 1.32067 31.76 1.67585 31.4408 2.11228C30.1758 3.84889 28.4398 7.18509 27.3535 13.2175L26.6616 13.0935C27.7714 6.93002 29.5639 3.49567 30.8721 1.69979C31.2566 1.17391 31.7599 0.74593 32.3412 0.450589C32.9225 0.155249 33.5654 0.000873819 34.2177 1.90916e-06H105.427C106.068 -0.000613261 106.7 0.147445 107.273 0.43248C107.846 0.717514 108.345 1.13172 108.731 1.64236C109.116 2.153 109.377 2.7461 109.494 3.37479C109.61 4.00348 109.578 4.65053 109.401 5.26484C106.43 15.5156 100.312 42.6772 107.647 73.4205C113.207 96.7279 110.534 106.235 107.313 110.106C105.188 112.659 102.847 112.789 102.396 112.789Z" fill="#E6E6E6" />
      <path d="M89.5655 125.593C89.5256 125.593 89.5006 125.592 89.4918 125.592L84.7236 125.592V124.891H89.509C89.6204 124.894 91.8803 124.958 93.9631 122.436C97.0669 118.677 99.6158 109.372 94.1324 86.3877C86.7592 55.4822 92.9087 28.1789 95.8949 17.8747C96.042 17.3649 96.0683 16.828 95.9717 16.3063C95.8752 15.7846 95.6584 15.2924 95.3385 14.8688C95.0185 14.4451 94.6043 14.1014 94.1284 13.865C93.6525 13.6286 93.1281 13.5058 92.5965 13.5064H21.3872C20.8458 13.5072 20.3122 13.6353 19.8297 13.8804C19.3472 14.1255 18.9294 14.4807 18.6103 14.9171C17.3453 16.6537 15.6093 19.9899 14.523 26.0223L13.8311 25.8983C14.9409 19.7348 16.7334 16.3005 18.0416 14.5046C18.4261 13.9787 18.9294 13.5507 19.5107 13.2554C20.092 12.9601 20.7349 12.8057 21.3872 12.8048H92.5965C93.2371 12.8042 93.8691 12.9523 94.4425 13.2373C95.0159 13.5223 95.515 13.9365 95.9004 14.4472C96.2858 14.9578 96.547 15.5509 96.6632 16.1796C96.7795 16.8083 96.7477 17.4553 96.5703 18.0696C93.5995 28.3204 87.4818 55.482 94.8164 86.2253C100.377 109.533 97.7037 119.04 94.4822 122.911C92.3573 125.464 90.016 125.593 89.5655 125.593Z" fill="#CCCCCC" />
      <path d="M76.7348 138.398C76.695 138.398 76.6699 138.397 76.6612 138.397L6.54936 138.397C5.87435 138.398 5.20942 138.234 4.61277 137.919C4.01613 137.604 3.50594 137.147 3.12687 136.59C2.7478 136.033 2.5114 135.391 2.43834 134.721C2.36529 134.051 2.45782 133.374 2.70783 132.748C5.25172 126.405 8.67694 111.187 3.03528 80.5329C-3.62653 44.3355 2.33756 31.2537 5.21095 27.3094C5.59544 26.7835 6.09876 26.3555 6.68007 26.0602C7.26137 25.7649 7.90428 25.6105 8.55657 25.6096H79.7659C80.4065 25.609 81.0384 25.7571 81.6118 26.0421C82.1852 26.3271 82.6843 26.7413 83.0697 27.252C83.4552 27.7626 83.7163 28.3557 83.8326 28.9844C83.9488 29.6131 83.917 30.2602 83.7396 30.8745C80.7689 41.1252 74.6512 68.2868 81.9857 99.0301C87.5462 122.338 84.8732 131.845 81.6516 135.716C79.5266 138.269 77.1854 138.398 76.7348 138.398ZM8.55657 26.3113C8.01517 26.312 7.48157 26.4401 6.99907 26.6852C6.51658 26.9303 6.09878 27.2855 5.7796 27.7219C2.9595 31.593 -2.8859 44.476 3.72672 80.4062C9.1162 109.69 6.50233 125.175 3.36048 133.009C3.14874 133.526 3.06926 134.087 3.12922 134.642C3.18918 135.198 3.38669 135.729 3.70394 136.189C4.0185 136.653 4.44243 137.033 4.93854 137.296C5.43464 137.559 5.98776 137.696 6.54935 137.696H76.6784C76.7891 137.701 79.0497 137.763 81.1325 135.241C84.2363 131.482 86.7851 122.177 81.3017 99.1925C73.9286 68.287 80.0781 40.9837 83.0642 30.6795C83.2113 30.1697 83.2377 29.6328 83.1411 29.1111C83.0445 28.5894 82.8277 28.0973 82.5078 27.6736C82.1879 27.2499 81.7736 26.9062 81.2978 26.6698C80.8219 26.4334 80.2975 26.3106 79.7659 26.3113H8.55657Z" fill="#3F3D56" />
      <path d="M67.6541 57.1832H11.7624C11.0165 57.1832 10.3012 56.8875 9.77386 56.3611C9.24647 55.8348 8.9502 55.121 8.9502 54.3766C8.9502 53.6323 9.24647 52.9184 9.77386 52.3921C10.3012 51.8658 11.0165 51.5701 11.7624 51.5701H67.6541C68.3999 51.5701 69.1152 51.8658 69.6426 52.3921C70.1699 52.9184 70.4662 53.6323 70.4662 54.3766C70.4662 55.121 70.1699 55.8348 69.6426 56.3611C69.1152 56.8875 68.3999 57.1832 67.6541 57.1832Z" fill="#107887" />
      <path d="M67.6541 69.111H11.7624C11.0165 69.111 10.3012 68.8153 9.77386 68.289C9.24647 67.7627 8.9502 67.0488 8.9502 66.3045C8.9502 65.5601 9.24647 64.8463 9.77386 64.3199C10.3012 63.7936 11.0165 63.4979 11.7624 63.4979H67.6541C68.3999 63.4979 69.1152 63.7936 69.6426 64.3199C70.1699 64.8463 70.4662 65.5601 70.4662 66.3045C70.4662 67.0488 70.1699 67.7627 69.6426 68.289C69.1152 68.8153 68.3999 69.111 67.6541 69.111Z" fill="#CCCCCC" />
      <path d="M67.6541 81.0389H11.7624C11.0165 81.0389 10.3012 80.7432 9.77386 80.2168C9.24647 79.6905 8.9502 78.9767 8.9502 78.2323C8.9502 77.488 9.24647 76.7741 9.77386 76.2478C10.3012 75.7215 11.0165 75.4258 11.7624 75.4258H67.6541C68.3999 75.4258 69.1152 75.7215 69.6426 76.2478C70.1699 76.7741 70.4662 77.488 70.4662 78.2323C70.4662 78.9767 70.1699 79.6905 69.6426 80.2168C69.1152 80.7432 68.3999 81.0389 67.6541 81.0389Z" fill="#CCCCCC" />
      <path d="M71.8723 92.9666H15.9806C15.2348 92.9666 14.5195 92.6709 13.9921 92.1446C13.4647 91.6182 13.1685 90.9044 13.1685 90.16C13.1685 89.4157 13.4647 88.7019 13.9921 88.1755C14.5195 87.6492 15.2348 87.3535 15.9806 87.3535H71.8723C72.6182 87.3535 73.3335 87.6492 73.8608 88.1755C74.3882 88.7019 74.6845 89.4157 74.6845 90.16C74.6845 90.9044 74.3882 91.6182 73.8608 92.1446C73.3335 92.6709 72.6182 92.9666 71.8723 92.9666Z" fill="#CCCCCC" />
      <path d="M71.8723 104.894H15.9806C15.2348 104.894 14.5195 104.599 13.9921 104.072C13.4647 103.546 13.1685 102.832 13.1685 102.088C13.1685 101.343 13.4647 100.63 13.9921 100.103C14.5195 99.5769 15.2348 99.2812 15.9806 99.2812H71.8723C72.6182 99.2812 73.3335 99.5769 73.8608 100.103C74.3882 100.63 74.6845 101.343 74.6845 102.088C74.6845 102.832 74.3882 103.546 73.8608 104.072C73.3335 104.599 72.6182 104.894 71.8723 104.894Z" fill="#CCCCCC" />
      <path d="M38.1264 45.2554H11.7624C11.0165 45.2554 10.3012 44.9597 9.77386 44.4334C9.24647 43.9071 8.9502 43.1932 8.9502 42.4489C8.9502 41.7045 9.24647 40.9907 9.77386 40.4643C10.3012 39.938 11.0165 39.6423 11.7624 39.6423H38.1264C38.8722 39.6423 39.5875 39.938 40.1149 40.4643C40.6423 40.9907 40.9385 41.7045 40.9385 42.4489C40.9385 43.1932 40.6423 43.9071 40.1149 44.4334C39.5875 44.9597 38.8722 45.2554 38.1264 45.2554Z" fill="#107887" />
      <path d="M34.722 124.127C35.2894 121.729 37.9553 120.943 40.1349 121.031C41.5119 121.124 42.8779 121.339 44.2163 121.675C45.6168 121.988 47.0037 122.358 48.3836 122.751C50.914 123.471 53.4183 124.342 55.9904 124.905C58.1317 125.374 60.4586 125.649 62.594 125.002C64.6467 124.38 66.3881 122.853 66.9466 120.741C67.4729 118.752 66.7503 116.576 64.8184 115.667C63.0567 114.838 60.8313 115.259 59.9581 117.135C58.9736 119.249 59.9573 121.555 61.5162 123.084C62.4023 123.915 63.3728 124.65 64.412 125.28C65.4667 125.997 66.6407 126.522 67.8793 126.829C69.9128 127.263 72.3352 126.624 73.3839 124.685C73.6171 124.246 73.7573 123.764 73.7959 123.269C73.8579 122.595 72.803 122.599 72.7413 123.269C72.5635 125.2 70.298 126.104 68.6038 125.892C67.4602 125.703 66.3681 125.28 65.396 124.65C64.4579 124.104 63.569 123.478 62.7392 122.779C61.3022 121.538 59.9552 119.495 60.9203 117.559C61.7062 115.982 63.7547 115.998 65.0007 117.015C66.5096 118.246 66.2405 120.482 65.2453 121.944C64.1434 123.564 62.146 124.243 60.2629 124.316C58.2167 124.395 56.1876 123.927 54.232 123.372C51.802 122.684 49.3963 121.913 46.9547 121.265C45.5686 120.897 44.1718 120.565 42.7607 120.306C41.4263 120.013 40.0558 119.918 38.6937 120.025C36.4932 120.279 34.247 121.557 33.7052 123.847C33.5493 124.506 34.5659 124.787 34.722 124.127L34.722 124.127Z" fill="#3F3D56" />
      <path d="M142.463 183.632C142.524 190.54 143.031 197.436 143.981 204.279C144.014 204.519 144.05 204.76 144.084 205L208.905 204.638C209.095 204.4 209.283 204.158 209.464 203.914C210.069 203.105 210.636 202.273 211.157 201.413C213.66 197.267 214.805 192.413 213.657 189.064L213.637 189.012C213.389 188.279 212.998 187.602 212.488 187.02C209.87 184.079 204.577 184.569 199.774 186.943C204.082 182.236 207.512 176.298 207.773 170.997C208.029 165.827 205.32 162.133 202.381 158.994C202.285 158.89 202.188 158.789 202.092 158.689C202.045 158.637 201.996 158.588 201.949 158.536C199.657 156.144 196.984 153.786 193.116 154.071C188.873 154.384 184.169 157.931 180.871 162.064C177.573 166.193 175.381 170.885 173.16 175.464C170.935 180.04 168.529 184.732 164.959 188.643C169.794 182.541 173.692 175.597 175.131 168.844C176.571 162.09 175.304 155.604 170.995 152.424C169.687 151.488 168.174 150.879 166.581 150.646C166.395 150.615 166.206 150.59 166.015 150.568C162.026 150.115 157.264 151.492 153.212 154.783C148.75 158.407 145.656 163.778 144.116 168.878C142.577 173.977 142.413 178.886 142.463 183.632Z" fill="#F2F2F2" />
      <path d="M184.873 204.772L186.112 204.765C186.506 204.52 186.901 204.277 187.298 204.037C187.544 203.882 187.793 203.733 188.042 203.581C192.153 201.075 196.291 198.671 200.453 196.37C204.613 194.066 208.793 191.869 212.993 189.778C213.105 189.73 213.196 189.644 213.25 189.535C213.305 189.427 213.32 189.303 213.292 189.185C213.288 189.17 213.281 189.155 213.271 189.142C213.213 189.032 213.082 188.986 212.891 189.08C212.375 189.338 211.857 189.596 211.342 189.86C207.13 191.987 202.935 194.22 198.759 196.559C194.586 198.897 190.436 201.339 186.31 203.884C186.224 203.936 186.137 203.992 186.05 204.044C185.657 204.287 185.266 204.53 184.873 204.772Z" fill="white" />
      <path d="M168.891 204.862L169.657 204.857C169.83 204.616 170 204.375 170.173 204.133C174.234 198.449 178.295 192.765 182.355 187.08C189.004 177.775 195.65 168.469 202.295 159.163C202.334 159.113 202.363 159.055 202.381 158.994C202.438 158.788 202.286 158.667 202.092 158.689C202.01 158.7 201.931 158.729 201.862 158.774C201.792 158.818 201.732 158.877 201.687 158.946C197.959 164.165 194.233 169.382 190.507 174.596C183.893 183.856 177.28 193.114 170.668 202.372C170.247 202.959 169.827 203.55 169.406 204.137C169.233 204.379 169.061 204.62 168.891 204.862Z" fill="white" />
      <path d="M151.815 200.42C151.854 201.709 151.94 202.977 152.051 204.234C152.073 204.474 152.095 204.715 152.116 204.955L152.92 204.951C152.896 204.71 152.871 204.47 152.85 204.23C152.68 202.414 152.559 200.578 152.543 198.692C152.521 193.462 153.032 188.244 154.067 183.118C155.167 177.598 156.746 172.185 158.787 166.939C160.929 161.405 163.512 156.052 166.51 150.931C166.566 150.847 166.591 150.747 166.581 150.646C166.548 150.418 166.243 150.35 166.014 150.568C165.969 150.611 165.93 150.66 165.899 150.714C165.528 151.351 165.164 151.989 164.806 152.629C161.919 157.78 159.447 163.151 157.415 168.693C155.479 173.957 154.007 179.379 153.014 184.897C152.085 190.017 151.683 195.218 151.815 200.42Z" fill="white" />
      <path d="M73.4693 124.376C73.336 124.265 73.2385 124.118 73.1891 123.952C73.1398 123.786 73.1408 123.609 73.192 123.443C73.2432 123.278 73.3423 123.131 73.4769 123.022C73.6114 122.913 73.7754 122.846 73.9481 122.829C75.8654 122.644 77.8003 122.842 79.6402 123.412L98.3816 129.188C98.6242 129.263 98.8496 129.384 99.0451 129.546C99.2405 129.708 99.4021 129.906 99.5207 130.131C99.6393 130.355 99.7124 130.6 99.736 130.852C99.7597 131.104 99.7333 131.359 99.6584 131.601C99.5834 131.843 99.4615 132.068 99.2994 132.263C99.1374 132.458 98.9384 132.619 98.7139 132.738C98.4894 132.856 98.2437 132.929 97.9909 132.953C97.7381 132.976 97.4832 132.95 97.2406 132.875L78.4991 127.099C76.6581 126.533 74.9483 125.608 73.4693 124.376Z" fill="#3F3D56" />
      <path d="M150.293 148.372C149.784 148.159 149.328 147.837 148.957 147.429C148.586 147.021 148.309 146.536 148.146 146.01C147.983 145.484 147.937 144.928 148.013 144.382C148.088 143.836 148.282 143.314 148.582 142.851L145.37 126.085L153.557 125.448L154.051 141.847C154.797 142.401 155.313 143.209 155.502 144.117C155.691 145.025 155.539 145.971 155.076 146.775C154.613 147.58 153.87 148.186 152.988 148.481C152.107 148.775 151.148 148.736 150.293 148.372Z" fill="#FFB8B8" />
      <path d="M144.864 139.719L136.525 100.96C136.183 99.3677 136.488 97.7052 137.374 96.3378C138.26 94.9705 139.653 94.0103 141.248 93.6685C142.844 93.3268 144.509 93.6314 145.88 94.5154C147.25 95.3994 148.212 96.7904 148.554 98.3823L156.893 137.141C156.981 137.55 156.902 137.978 156.675 138.329C156.447 138.681 156.089 138.928 155.679 139.016L146.743 140.931C146.54 140.975 146.33 140.978 146.126 140.94C145.921 140.903 145.726 140.826 145.552 140.713C145.378 140.601 145.227 140.455 145.109 140.284C144.991 140.114 144.908 139.922 144.864 139.719Z" fill="#107887" />
      <path d="M131.177 200.69H126.867L124.817 184.101L131.178 184.101L131.177 200.69Z" fill="#FFB8B8" />
      <path d="M123.789 199.461H132.1V204.684H118.556C118.556 203.998 118.692 203.319 118.954 202.685C119.217 202.051 119.603 201.476 120.089 200.991C120.575 200.506 121.152 200.121 121.787 199.858C122.421 199.596 123.102 199.461 123.789 199.461Z" fill="#2F2E41" />
      <path d="M145.589 200.339H141.28L139.229 183.75L145.59 183.75L145.589 200.339Z" fill="#FFB8B8" />
      <path d="M138.201 199.11H146.513V204.333H132.968C132.968 203.647 133.104 202.968 133.367 202.334C133.63 201.7 134.015 201.125 134.501 200.64C134.987 200.155 135.564 199.77 136.199 199.508C136.834 199.245 137.514 199.11 138.201 199.11Z" fill="#2F2E41" />
      <path d="M126.013 197.595C125.852 197.463 125.718 197.3 125.621 197.115C125.523 196.931 125.464 196.729 125.445 196.521L120.435 141.52C120.416 141.307 120.44 141.091 120.507 140.888C120.573 140.684 120.681 140.496 120.823 140.335C120.965 140.174 121.138 140.043 121.332 139.951C121.526 139.859 121.737 139.808 121.951 139.8L147.078 138.859C147.295 138.851 147.512 138.888 147.715 138.967C147.917 139.047 148.101 139.167 148.255 139.32C148.409 139.474 148.53 139.657 148.609 139.859C148.689 140.061 148.726 140.278 148.718 140.495L146.709 195.284C146.695 195.668 146.541 196.033 146.276 196.311C146.012 196.59 145.655 196.763 145.272 196.799L140.205 197.258C139.995 197.277 139.784 197.254 139.583 197.191C139.382 197.127 139.195 197.023 139.035 196.887C138.875 196.75 138.743 196.583 138.649 196.394C138.554 196.206 138.498 196.001 138.484 195.791L136.159 160.984C136.157 160.917 136.142 160.851 136.115 160.79C136.087 160.729 136.048 160.674 135.999 160.629C135.95 160.583 135.893 160.547 135.83 160.524C135.767 160.501 135.7 160.49 135.633 160.493H135.631C135.564 160.49 135.497 160.501 135.434 160.525C135.371 160.548 135.313 160.584 135.264 160.63C135.215 160.677 135.176 160.732 135.149 160.793C135.122 160.855 135.107 160.921 135.106 160.988L133.026 196.03C133.005 196.405 132.85 196.76 132.589 197.031C132.328 197.302 131.979 197.471 131.604 197.507L127.178 197.949C126.97 197.97 126.76 197.95 126.56 197.889C126.36 197.829 126.174 197.729 126.013 197.595H126.013Z" fill="#2F2E41" />
      <path d="M135.679 85.4322C140.447 85.4322 144.312 81.5745 144.312 76.8158C144.312 72.0571 140.447 68.1993 135.679 68.1993C130.91 68.1993 127.045 72.0571 127.045 76.8158C127.045 81.5745 130.91 85.4322 135.679 85.4322Z" fill="#FFB8B8" />
      <path d="M119.053 141.893C118.899 141.718 118.786 141.512 118.722 141.289C118.658 141.066 118.644 140.831 118.683 140.602C120.277 130.933 119.378 116.721 118.344 106.5C118.074 103.789 118.524 101.055 119.65 98.5728C120.776 96.0907 122.537 93.9485 124.756 92.3617L129.265 88.5048C129.565 88.2513 129.947 88.1163 130.34 88.1252L141.541 88.4445C141.945 88.4555 142.329 88.6209 142.614 88.9066L144.345 92.7321L144.363 92.7451C146.426 94.2582 148.081 96.2578 149.18 98.5652C150.279 100.873 150.787 103.416 150.661 105.968L149.012 139.975C148.991 140.37 148.822 140.743 148.54 141.021C148.257 141.299 147.88 141.461 147.484 141.476L120.295 142.43C120.277 142.43 120.26 142.43 120.242 142.43C120.017 142.431 119.794 142.383 119.589 142.29C119.384 142.197 119.201 142.062 119.053 141.893Z" fill="#107887" />
      <path d="M86.4396 127.702C86.4261 127.151 86.5337 126.604 86.7549 126.099C86.9761 125.594 87.3055 125.144 87.72 124.78C88.1344 124.416 88.6238 124.147 89.1536 123.992C89.6834 123.838 90.2408 123.801 90.7865 123.884L104.8 114.097L108.733 121.291L93.9424 128.453C93.7413 129.359 93.2142 130.16 92.4611 130.703C91.708 131.247 90.7813 131.496 89.8565 131.403C88.9316 131.311 88.073 130.883 87.4432 130.2C86.8135 129.518 86.4564 128.629 86.4396 127.702L86.4396 127.702Z" fill="#FFB8B8" />
      <path d="M98.233 129.403C98.0276 129.372 97.8303 129.301 97.6526 129.193C97.4749 129.086 97.3203 128.944 97.1977 128.777L91.7897 121.424C91.542 121.086 91.4386 120.664 91.5022 120.25C91.5658 119.837 91.7911 119.465 92.1289 119.217L124.151 95.7599C125.466 94.7965 127.111 94.3939 128.723 94.6408C129.522 94.7631 130.288 95.0411 130.979 95.459C131.67 95.877 132.272 96.4266 132.75 97.0765C133.228 97.7265 133.573 98.464 133.765 99.2471C133.958 100.03 133.994 100.843 133.871 101.64C133.749 102.437 133.47 103.202 133.051 103.892C132.633 104.581 132.082 105.181 131.431 105.659L99.4089 129.115C99.1388 129.314 98.812 129.422 98.4763 129.422C98.3949 129.422 98.3136 129.415 98.233 129.403Z" fill="#107887" />
      <path d="M136.12 84.4863C137.265 83.0853 137.14 80.9512 136.221 79.3932C135.302 77.8351 133.742 76.7621 132.137 75.9239C130.532 75.0858 128.827 74.4267 127.316 73.4287C125.806 72.4308 125.871 68.206 125.469 66.4432L126.429 66.762C126.06 66.2913 125.942 64.9003 125.573 64.4296C126.657 64.5365 127.49 65.5636 128.574 65.6705L127.949 63.9949C131.666 63.7661 133.977 66.344 137.694 66.1153C139.536 66.0019 141.536 65.9302 143.05 66.9829C144.366 67.8976 145.032 69.4808 145.469 71.0205C146.407 74.3263 146.553 78.0294 144.932 81.0608C143.311 84.0922 139.576 86.1349 136.313 85.0403L136.12 84.4863Z" fill="#2F2E41" />
      <path d="M88.7192 204.877H222.648C222.742 204.877 222.831 204.84 222.897 204.774C222.963 204.709 223 204.619 223 204.526C223 204.433 222.963 204.344 222.897 204.278C222.831 204.213 222.742 204.176 222.648 204.176H88.7192C88.626 204.176 88.5366 204.213 88.4706 204.278C88.4047 204.344 88.3677 204.433 88.3677 204.526C88.3677 204.619 88.4047 204.709 88.4706 204.774C88.5366 204.84 88.626 204.877 88.7192 204.877Z" fill="#3F3D56" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="223" height="205" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default ExternalLink;
