import { Fab, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { RootState } from '../../../../../setup/reducer/reducers';
import {
  MEDIUM_GRAY_COLOR, WHITE_COLOR, INFO_COLOR, FONT_FAMILY_EXTRABOLD, EERIE_BLACK_COLOR,
} from '../../../../../theme';
import BatchPaper from '../../../organisms/batch-paper';
import { toggleInfoBatch } from '../../dou-page/store/actions';
import DouRegisterBatchStatus from '../../../../../domain/enum/dou/DouRegisterBatchStatus';
import CloseBatchModal from '../../../organisms/close-batch-modal';
import PublicationDouModal from '../../../organisms/publication-dou-modal';
import GetTemplateRequest from '../../../../../domain/interface/request/GetTemplateRequest';
import DouTemplateManager from '../../dou-list/DouTemplateManager';
import DocumentType from '../../../../../domain/enum/DocumentType';
import { showNotification, showSuccessNotification } from '../../notification/actions';
import { errorNotificationMessages, notificationIcons, successNotificationMessages } from '../../../../utils/constants';
import { CnpjMask } from '../../../../utils/stringMasks';
import DefaultTemplate from '../../../templates/default-template';
import DouBatchType from '../../../../../domain/enum/dou/DouBatchType';
import CustomizedTooltip from '../../../atoms/customized-tooltip';
import { Edit2, Eye } from 'react-feather';
import EditDouModal from '../../../organisms/edit-dou-modal';
import { setBatches, setLoadingBatches } from '../../dou-list/store/actions';
import BatchPaperManager from '../../../organisms/batch-paper/BatchPaperManager';

const PREFIX = 'DouInfo';

const classes = {
  root: `${PREFIX}-root`,
  fabIcon: `${PREFIX}-fabIcon`,
  fabButtonIcon: `${PREFIX}-fabButtonIcon`,
  fabGridRightButton: `${PREFIX}-fabGridRightButton`,
  fabGridLeftButton: `${PREFIX}-fabGridLeftButton`,
  errorColorFab: `${PREFIX}-errorColorFab`,
  successColorFab: `${PREFIX}-successColorFab`,
  primaryColorFab: `${PREFIX}-primaryColorFab`,
  fabGridRight: `${PREFIX}-fabGridRight`,
  infoColorFab: `${PREFIX}-infoColorFab`,
  extraBoldFont: `${PREFIX}-extraBoldFont`,
  infoColorFabTextDou: `${PREFIX}-infoColorFabTextDou`,
  textDouModal: `${PREFIX}-textDouModal`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    paddingBottom: theme.spacing(7),
  },

  [`& .${classes.fabIcon}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.errorColorFab}`]: {
    backgroundColor: theme.palette.error.main,
    height: '44px',
    '&:hover': {
      opacity: 0.9,
      backgroundColor: theme.palette.error.main,
    },
  },

  [`& .${classes.successColorFab}`]: {
    backgroundColor: theme.palette.success.main,
    height: '44px',
    '&:hover': {
      opacity: 0.9,
      backgroundColor: theme.palette.success.main,
    },
    '&:disabled': {
      backgroundColor: MEDIUM_GRAY_COLOR,
    },
  },

  [`& .${classes.primaryColorFab}`]: {
    backgroundColor: theme.palette.primary.main,
    height: '44px',
    '&:hover': {
      opacity: 0.9,
      backgroundColor: theme.palette.primary.main,
    },
  },

  [`& .${classes.fabGridRight}`]: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(4),
    maxWidth: '45%',
  },

  [`& .${classes.fabGridRightButton}`]: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(5),
    maxWidth: '0%',
  },

  [`& .${classes.fabGridLeftButton}`]: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(15),
    maxWidth: '0%',
  },

  [`& .${classes.infoColorFab}`]: {
    backgroundColor: INFO_COLOR,
    height: '2.75rem',
    width: '10rem',
    '&:hover': {
      opacity: 0.9,
      backgroundColor: INFO_COLOR,
    },
  },

  [`& .${classes.extraBoldFont}`]: {
    fontFamily: FONT_FAMILY_EXTRABOLD,
  },

  [`& .${classes.infoColorFabTextDou}`]: {
    backgroundColor: INFO_COLOR,
    height: '2.75rem',
    width: '15rem',
    '&:hover': {
      opacity: 0.9,
      backgroundColor: INFO_COLOR,
    },
  },

  [`& .${classes.fabButtonIcon}`]: {
    backgroundColor: INFO_COLOR,
    height: '60px',
    width: '60px',
    borderRadius: '50%',
    '&:hover': {
      opacity: 0.9,
      backgroundColor: INFO_COLOR,
    },
  },

  [`& .${classes.textDouModal}`]: {
    color: EERIE_BLACK_COLOR,
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
  }
}));

type CloseBatchButtonProps = {
  onClick: () => void;
  disabled?: boolean;
};

const CloseBatchButton = (props: CloseBatchButtonProps) => (
  <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={3} className={classes.fabGridRight}>
    <Grid item>
      <Fab variant="extended" size="large" component="button" disabled={props.disabled} className={classes.infoColorFab} onClick={props.onClick}>
        <CloseIcon className={classes.fabIcon} htmlColor={WHITE_COLOR} />
        <Typography variant="button" className={classes.extraBoldFont}>
          Fechar Lote
        </Typography>
      </Fab>
    </Grid>
  </Grid>
);

const TextPublicationDouButton = (props: { onClick: () => void }) => (
  <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={3} className={classes.fabGridLeftButton}>
    <Grid item>
      <CustomizedTooltip title="Ver Texto de Publicação" placement="top">
        <Fab variant="extended" size="large" component="button" className={classes.fabButtonIcon} onClick={props.onClick}>
          <Eye style={{ color: 'white' }} />
        </Fab>
      </CustomizedTooltip>
    </Grid>
  </Grid>
);

const TextEditPublicationDouButton = (props: { onClick: () => void }) => (
  <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={3} className={classes.fabGridRightButton}>
    <Grid item>
      <CustomizedTooltip title="Editar Data de Publicação e Número do DOU" placement="top">
        <Fab variant="extended" size="large" component="button" className={classes.fabButtonIcon} onClick={props.onClick}>
          <Edit2 style={{ color: 'white' }} />
        </Fab>
      </CustomizedTooltip>
    </Grid>
  </Grid>
);

export default function DouInfo() {

  const [openCloseBatchModal, setOpenCloseBatchModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const batch = useSelector((state: RootState) => state.douPage.batch);
  const selectedInstitution = useSelector((state: RootState) => state.header.institution);
  const [openModal, setOpenModal] = useState(false);
  const [loadingModalDou, setLoadingModalDou] = useState(false);
  const [textPublicationDou, setTextPublicationDou] = useState('');

  const dispatch = useDispatch();

  const toggleInfo = () => dispatch(toggleInfoBatch());
  const toggleEdit = async () => { updateDouTable() };

  const updateDouTable = async () => {
    if (selectedInstitution?.mecCode) {
      dispatch(setLoadingBatches(true));
      dispatch(setBatches(selectedInstitution));
      dispatch(toggleInfoBatch())
    } else {
      dispatch(showNotification(errorNotificationMessages.failedOnUpdateDouPage));
    }
  };

  const handleToggleCloseBatchModal = () => {
    setOpenCloseBatchModal(batch?.status === DouRegisterBatchStatus.Opened && !(openCloseBatchModal));
  };

  const batchIsOpen = (): boolean => batch?.status === DouRegisterBatchStatus.Opened;

  const getDouTemplate = async (requestTemplateDou: GetTemplateRequest) => {
    const response = await DouTemplateManager.getDouTemplate(requestTemplateDou);
    return response.template;
  };

  const toShortDate = (date: string) => moment(date).format('DD/MM/YYYY');
  const toLongDate = (date: string) => moment(date).format('DD [de] MMMM [de] YYYY');

  const getTemplateDocumentType = (batchType?: DouBatchType): DocumentType | undefined => {
    if (!batchType) {
      return undefined;
    }

    return batchType === DouBatchType.Register ? DocumentType.DouRegisterBatchTemplate : DocumentType.DouCancellationBatchTemplate;
  };

  const getTemplateEta = async () => {
    const institutionMecCode = batch?.institutionMecCode;
    const documentType = getTemplateDocumentType(batch?.type);
    const registerBookList = batch ? `${BatchPaperManager(batch).getDiplomasList().join('; ')}; ` : '';

    const data = {
      issuerInstitution: {
        name: selectedInstitution?.name,
        sponsor: {
          corporateName: selectedInstitution?.sponsor?.corporateName,
          cnpj: CnpjMask.apply(selectedInstitution?.sponsor?.cnpj),
        },
        validationUrl: selectedInstitution?.validationUrl,
        address: selectedInstitution?.address,
      },
      batch,
      registerBookList,
      toShortDate,
      toLongDate,
    };

    const requestTemplateDou = {
      institutionMecCode,
      documentType,
    } as GetTemplateRequest;

    const template = await getDouTemplate(requestTemplateDou);
    const response = await DouTemplateManager.getTemplateEta({ template, data });

    return response.template;
  };

  function closeWithErrorState(): void {
    setOpenModal(false);
    dispatch(showNotification(errorNotificationMessages.defaultError, notificationIcons.error));
  }

  const handleOpenModal = async () => {
    setOpenModal(true);
    setLoadingModalDou(true);

    try {
      const template = await getTemplateEta();

      if (template) {
        setTextPublicationDou(template);
        setLoadingModalDou(false);
      } else {
        closeWithErrorState();
      }
    } catch {
      closeWithErrorState();
    }
  };

  const handleOpenEditModal = async () => {
    setOpenEditModal(true);
  };

  const handleCopyButton = () => {
    navigator.clipboard.writeText(textPublicationDou);
    dispatch(showSuccessNotification(successNotificationMessages.successCopyToClipboard, notificationIcons.success));
  };

  return (
    <Root>
      <DefaultTemplate message="Informações do" highlightedMessage="Lote de Registro">
        <Grid container spacing={2} className={classes.root}>
          <Grid item md={12}>
            <BatchPaper onClickBack={toggleInfo} />
            {!batchIsOpen() && <TextPublicationDouButton onClick={handleOpenModal} />}
            {!batchIsOpen() && <TextEditPublicationDouButton onClick={handleOpenEditModal} />}
            {batchIsOpen() && <CloseBatchButton onClick={handleToggleCloseBatchModal} disabled={batch?.diplomas?.length === 0} />}
          </Grid>
        </Grid>
        {
          batch?.sk && (
            <CloseBatchModal
              openModal={openCloseBatchModal}
              batch={batch}
              onCloseModal={() => setOpenCloseBatchModal(false)}
              callbackAfterCloseBatch={toggleInfo}
            />
          )
        }
        <PublicationDouModal
          openModal={openModal}
          modalTitle="Texto de Publicação no DOU"
          textCopyButton="Copiar para Área de Transferência"
          handleCopyButton={handleCopyButton}
          onClose={() => setOpenModal(false)}
          loading={loadingModalDou}
          batchType={batch?.type || DouBatchType.Register}
        >
          <Typography className={classes.textDouModal}>
            {textPublicationDou}
          </Typography>
        </PublicationDouModal>
        <EditDouModal
          openEditModal={openEditModal}
          setOpenEditModal={setOpenEditModal}
          institutionMecCode={batch?.institutionMecCode}
          batchNumber={batch?.batchNumber}
          type={batch?.type}
          callbackAfterUpdateBatch={toggleEdit}
        />
      </DefaultTemplate>
    </Root>
  );
}
