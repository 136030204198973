import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import React from 'react';
import { cpf } from 'cpf-cnpj-validator';
import { DIPLOMA_COLOR, FONT_FAMILY_REGULAR, FONT_FAMILY_SEMIBOLD } from '../../../../theme';
import SelectAutocomplete from '../../molecules/select-autocomplete';
import { modalInformation, validation } from '../../../utils/constants';
import LabeledText from '../../molecules/labeled-text';
import { EmployeePaperProps } from './types';

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
  errorMessage: `${PREFIX}-errorMessage`,
  spaced: `${PREFIX}-spaced`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    padding: '0px 0px 40px 0px',
  },

  [`& .${classes.errorMessage}`]: {
    fontFamily: FONT_FAMILY_REGULAR,
    fontWeight: 50,
  },

  [`& .${classes.spaced}`]: {
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(4),
    color: DIPLOMA_COLOR,
    fontSize: '16px',
    fontFamily: FONT_FAMILY_SEMIBOLD,
    lineHeight: '20px',
  }
}));

export default function EmployeePaper({
  employees, setEmployees, selectedEmployee, error, loadingEmployee, order, defaultSigner
}: EmployeePaperProps): JSX.Element {

  if (employees && selectedEmployee === undefined) {
    selectedEmployee = employees.find((employee) => employee.login === defaultSigner?.login);
  }

  return (
    (<Root>
      <Grid
        container
        spacing={6}
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid item xs={12}>
          {order && (
            <Typography className={classes.spaced}>
              {order}
              ª Assinatura
            </Typography>
          )}
          <SelectAutocomplete
            defaultValue={selectedEmployee}
            compareName
            key={1}
            helperText={error ? validation.errorMessages.requiredField : ''}
            loading={loadingEmployee}
            listItems={employees}
            label="Responsável"
            placeholder="Nenhum responsável selecionado"
            setItem={setEmployees}
            error={error}
          />
        </Grid>
        <Grid
          item
          container
        >
          <Grid item xs={6}>
            <LabeledText
              label="CPF"
              classes={classes}
              placeholder={modalInformation.modalSendToSignature.signerPropertiesPlaceholder}
            >
              {selectedEmployee && (cpf.format(selectedEmployee.cpf))}
            </LabeledText>
          </Grid>
          <Grid item xs={6}>
            <LabeledText
              label="ID ou Matrícula"
              classes={classes}
              placeholder={modalInformation.modalSendToSignature.signerPropertiesPlaceholder}
            >
              {selectedEmployee?.registerNumber}
            </LabeledText>
          </Grid>
          <Grid item xs={12}>
            <LabeledText
              label="Cargo ou Função"
              classes={classes}
              placeholder={modalInformation.modalSendToSignature.signerPropertiesPlaceholder}
            >
              {selectedEmployee?.position}
            </LabeledText>
          </Grid>
        </Grid>
      </Grid>
    </Root>)
  );
}
