import {
  errorNotificationMessages,
  notificationIcons
} from '../../../../utils/constants';
import {
  DiplomaListActions,
  SET_SEARCH,
  SELECT_DIPLOMA_STATUS,
  SELECT_ISSUER_INSTITUTION,
  SET_DIPLOMAS,
  SET_ISSUER_INSTITUTIONS,
  SET_LOADING_DIPLOMAS,
  SET_SELECTED_INSTITUTION_PROFILE,
  SET_SELECTED_SYNC_DIPLOMAS
} from './types';
import Institution from '../../../../../domain/model/institution/Institution';
import { AppThunk } from '../../../../../setup/reducer';
import { SelectedDiplomaStatus } from '../types';
import { listDiplomas, listIssuerInstitutions } from '../DiplomaFilterManager';
import Diploma from '../../../../../domain/model/diploma/Diploma';
import { showNotification } from '../../notification/actions';
import InstitutionProfile from '../../../../../domain/enum/InstitutionProfile';

const internalSelectIssuerInstitution = (
  selectedIssuerInstitution?: Institution
): DiplomaListActions => ({
  type: SELECT_ISSUER_INSTITUTION,
  payload: {
    selectedIssuerInstitution
  }
});

export const selectIssuerInstitution =
  (selectedIssuerInstitution?: Institution): AppThunk =>
  async (dispatch) => {
    dispatch(internalSelectIssuerInstitution(selectedIssuerInstitution));
  };

const internalSetIssuerInstitutions = (
  issuerInstitutions: Institution[]
): DiplomaListActions => ({
  type: SET_ISSUER_INSTITUTIONS,
  payload: {
    issuerInstitutions
  }
});

export const setIssuerInstitutions =
  (selectedRegisterIes: Institution, setLoadingIes: any): AppThunk =>
  async (dispatch, getState) => {
    const { institutions } = getState().header;
    let issuerInstitutions: Institution[] = [];
    try {
      issuerInstitutions = await listIssuerInstitutions(
        selectedRegisterIes.mecCode,
        institutions
      );
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
    }
    dispatch(internalSetIssuerInstitutions(issuerInstitutions));
    setLoadingIes(false);
  };

const internalSelectDiplomaStatus = (
  selectedDiplomaStatus?: SelectedDiplomaStatus
): DiplomaListActions => ({
  type: SELECT_DIPLOMA_STATUS,
  payload: {
    selectedDiplomaStatus
  }
});

export const selectDiplomaStatus =
  (selectedDiplomaStatus?: SelectedDiplomaStatus): AppThunk =>
  async (dispatch) => {
    dispatch(internalSelectDiplomaStatus(selectedDiplomaStatus));
  };

const internalSetSearch = (search: string): DiplomaListActions => ({
  type: SET_SEARCH,
  payload: {
    search
  }
});

export const setSearch =
  (search: string): AppThunk =>
  async (dispatch) => {
    search = search.replace(/[*|[|+|(|)]/g, '');

    dispatch(internalSetSearch(search));
  };

const internalSetDiplomas = (diplomas: Diploma[]): DiplomaListActions => ({
  type: SET_DIPLOMAS,
  payload: {
    diplomas
  }
});

export const setDiplomasRows =
  (diplomas: Diploma[]): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetDiplomas(diplomas));
  };

export const setDiplomas =
  (
    setLoadingDiplomas: any,
    selectedDiplomaStatus?: SelectedDiplomaStatus,
    selectedRegisterIes?: Institution,
    selectedIssuerInstitution?: Institution,    
    selectedInstitutionProfile?: InstitutionProfile
  ): AppThunk =>
  async (dispatch) => {
    let diplomas: Diploma[] = [];

    if (!selectedDiplomaStatus) {
      setLoadingDiplomas(false);
      return;
    }

    try {
      diplomas = await listDiplomas(
        selectedDiplomaStatus,
        selectedRegisterIes,
        selectedIssuerInstitution,
        selectedInstitutionProfile
      );
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
    }
    dispatch(internalSetDiplomas(diplomas));
    setLoadingDiplomas(false);
  };

const internalSetLoadingDiplomas = (
  loadingDiplomas: boolean
): DiplomaListActions => ({
  type: SET_LOADING_DIPLOMAS,
  payload: {
    loadingDiplomas
  }
});

export const setLoadingDiplomas =
  (loadingDiplomas: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetLoadingDiplomas(loadingDiplomas));
  };

const internalSetSelectedInstitutionType = (
  selectedProfile: InstitutionProfile
): DiplomaListActions => ({
  type: SET_SELECTED_INSTITUTION_PROFILE,
  payload: {
    selectedProfile
  }
});

export const setSyncDiplomas =
  (syncDiplomas: Diploma[]): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetSyncDiplomas(syncDiplomas));
  };

const internalSetSyncDiplomas = (
  syncDiplomas: Diploma[]
): DiplomaListActions => ({
  type: SET_SELECTED_SYNC_DIPLOMAS,
  payload: {
    syncDiplomas
  }
});

export const setSelectedInstitutionProfile =
  (selectedProfile?: InstitutionProfile): AppThunk =>
  async (dispatch) => {
    if (!selectedProfile) {
      selectedProfile = InstitutionProfile.Issuer;
    }

    dispatch(internalSetSelectedInstitutionType(selectedProfile));
  };
