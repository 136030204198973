import React, { useEffect, useState } from 'react';
import ReplayIcon from '@mui/icons-material/Replay';
import { styled } from '@mui/material/styles';
import { Button, Grid } from '@mui/material';
import { Search } from 'react-feather';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import InstitutionProfile from '../../../../../domain/enum/InstitutionProfile';
import Institution from '../../../../../domain/model/institution/Institution';
import { RootState } from '../../../../../setup/reducer/reducers';
import CustomTextField from '../../../atoms/form/CustomTextField';
import SelectAutocomplete from '../../../molecules/select-autocomplete';
import usePrevious from '../../../../utils/usePrevious';
import { DIPLOMA_COLOR, GRAY_COLOR } from '../../../../../theme';
import {
  diplomaIssuerStatuses,
  diplomaRegisterStatuses
} from '../DiplomaFilterManager';
import * as Actions from '../store/actions';
import { DiplomaStatusFilter, SelectedDiplomaStatus } from '../types';
import BackdropLoading from '../../../molecules/backdrop-loading';
import { showNotification, showSuccessNotification } from '../../notification/actions';
import { errorNotificationMessages, notificationIcons, successNotificationMessages } from '../../../../utils/constants';
import ChangeType from '../../../../../domain/enum/diploma/ChangeType';
import DiplomaService from '../../../../../services/DiplomaService';
import ChangeDiplomaRequest from '../../../../../domain/interface/request/ChangeDiplomaRequest';
import { setSyncDiplomas } from '../store/actions';
import { AxiosResponse } from 'axios';

const PREFIX = 'DiplomaFilter';

const classes = {
  root: `${PREFIX}-root`,
  customTextField: `${PREFIX}-customTextField`
};

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {
    padding: '30px'
  },
  [`&.${classes.customTextField}`]: {
    marginTop: '200px'
  }
}));

const mapState = (state: RootState) => ({
  selectedIssuerInstitution: state.diplomaFilter.selectedIssuerInstitution,
  issuerInstitutions: state.diplomaFilter.issuerInstitutions,
  selectedDiplomaStatus: state.diplomaFilter.selectedDiplomaStatus,
  search: state.diplomaFilter.search,
  selectedInstitutionProfile: state.diplomaFilter.selectedInstitutionProfile,
  selectedInstitution: state.header.institution as Institution
});

const mapDispatch = {
  setIssuerInstitutions: (
    issuerInstitution: Institution,
    setLoadingInstitution: any
  ) => Actions.setIssuerInstitutions(issuerInstitution, setLoadingInstitution),
  setDiplomas: (
    setLoading: any,
    status?: SelectedDiplomaStatus,
    registerInstitution?: Institution,
    issuerInstitution?: Institution,
    selectedInstitutionType?: InstitutionProfile
  ) =>
    Actions.setDiplomas(
      setLoading,
      status,
      registerInstitution,
      issuerInstitution,
      selectedInstitutionType
    ),
  setSearch: (search: string) => Actions.setSearch(search),
  selectIssuerInstitution: (issuerInstitution?: Institution) =>
    Actions.selectIssuerInstitution(issuerInstitution),
  selectInstitutionProfile: (selectedInstitutionType: InstitutionProfile) =>
    Actions.setSelectedInstitutionProfile(selectedInstitutionType),
  selectDiplomaStatus: (status?: SelectedDiplomaStatus) =>
    Actions.selectDiplomaStatus(status),
  setLoadingDiplomas: (loading: boolean) => Actions.setLoadingDiplomas(loading)
};

const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const DiplomaFilter = (props: Props) => {
  const {
    selectedInstitution,
    selectedIssuerInstitution,
    selectedDiplomaStatus,
    search,
    setIssuerInstitutions,
    setDiplomas,
    setSearch,
    selectedInstitutionProfile,
    selectIssuerInstitution,
    selectDiplomaStatus,
    setLoadingDiplomas
  } = props;

  const previousValues = usePrevious({ selectedInstitution });
  const [statuses, setStatuses] = useState<DiplomaStatusFilter>([]);
  const syncDiplomaList = useSelector((state: RootState) => state.diplomaFilter.syncDiplomas);
  const [isLoading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    selectedDiplomaStatus === undefined
      ? selectDiplomaStatus({ name: 'Todos' })
      : selectDiplomaStatus(selectedDiplomaStatus);
    setStatuses(
      selectedInstitutionProfile === InstitutionProfile.Register
        ? diplomaRegisterStatuses
        : diplomaIssuerStatuses
    );
  }, [selectDiplomaStatus, selectedDiplomaStatus, selectedInstitutionProfile]);

  useEffect(() => {
    if (
      !previousValues ||
      (previousValues &&
        previousValues.selectedInstitution &&
        selectedInstitution &&
        previousValues.selectedInstitution.mecCode !==
        selectedInstitution.mecCode)
    ) {
      setIssuerInstitutions(selectedInstitution, () => { });
      selectIssuerInstitution(undefined);
    }
  }, [
    previousValues,
    selectedInstitution,
    selectedIssuerInstitution,
    setIssuerInstitutions,
    selectIssuerInstitution
  ]);

  useEffect(() => {
    setLoadingDiplomas(true);
    if (selectedInstitutionProfile === InstitutionProfile.Register) {
      setDiplomas(
        setLoadingDiplomas,
        selectedDiplomaStatus,
        selectedInstitution,
        selectedIssuerInstitution,
        selectedInstitutionProfile
      );
    } else {
      setDiplomas(
        setLoadingDiplomas,
        selectedDiplomaStatus,
        undefined,
        selectedInstitution,
        selectedInstitutionProfile
      );
    }
  }, [
    selectedDiplomaStatus,
    selectedInstitution,
    selectedIssuerInstitution,
    setDiplomas,
    setLoadingDiplomas,
    selectedInstitutionProfile
  ]);

  const changeRequest: ChangeDiplomaRequest = {
    type: ChangeType.IssuerInstitutionSynchronizesDiploma
  }

  async function handleSubmit(): Promise<void> {
    setLoading(true);
    try {
      let promises: Array<Promise<AxiosResponse<any>>> = [];
      syncDiplomaList.forEach(async (diploma) => {
        promises.push(DiplomaService.addChangeDiploma(changeRequest, diploma.id));
      });

      await Promise.all(promises);

      dispatch(setSyncDiplomas([]));

      dispatch(
        showSuccessNotification(
          successNotificationMessages.successSync,
          notificationIcons.success
        )
      );
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.failedToSyncDiploma,
          notificationIcons.error
        )
      );
    };

    setLoading(false);
    selectDiplomaStatus();
  }


  return (
    <StyledGrid className={classes.root} container spacing={3}>
      <Grid item xs={6} mt={2}>
        <CustomTextField
          fullWidth
          value={search}
          placeholder="Pesquise por aluno ou matrícula"
          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearch(e.target.value)
          }
          InputProps={{
            endAdornment: <Search color={search ? DIPLOMA_COLOR : GRAY_COLOR} />
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectAutocomplete
          defaultValue={selectedDiplomaStatus}
          compareName
          listItems={statuses}
          label="Status do Diploma"
          placeholder="Nenhuma situação selecionada"
          setItem={selectDiplomaStatus}
          data-testid="select-status"
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid container item xs={12} sx={{ justifyContent: 'flex-end' }}>
        <Button variant="contained"
          size="large"
          startIcon={<ReplayIcon />}
          disabled={syncDiplomaList.length === 0}
          onClick={handleSubmit}>
          Recarregar Dados
        </Button>
      </Grid>
      {isLoading && (
        <BackdropLoading
          size={50}
          description="Sincronizando Diplomas..."
          open={isLoading}
          classes={classes}
        />)}
    </StyledGrid>
  );
};

export default connector(DiplomaFilter);
