import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { pageRoutes } from '../../../../utils/constants';
import BackCardHeader from '../../../molecules/back-card-header';
import DefaultTemplate from '../../../templates/default-template';
import { InstitutionManagementInfo } from '../../../organisms/institution-management-info';

const PREFIX = 'CertificateInfo';

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  buttonUpload: `${PREFIX}-buttonUpload`,
  gridDropzone: `${PREFIX}-gridDropzone`,
  gridDropzoneDisabled: `${PREFIX}-gridDropzoneDisabled`,
  dropZoneBox: `${PREFIX}-dropZoneBox`,
  dropZoneBoxP: `${PREFIX}-dropZoneBoxP`,
  dropZoneBoxAdd: `${PREFIX}-dropZoneBoxAdd`,
  gridIcon: `${PREFIX}-gridIcon`,
  gridIconFile: `${PREFIX}-gridIconFile`,
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    paddingBottom: theme.spacing(7),
  }
}));

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main,
  }
}));


const InstitutionManagementInfoPage = () => {
  const history = useHistory();
  const navigateBackToMicrocertificatesOnClick = () => {
    history.push(pageRoutes.InstitutionManagement)
  }

  return (
    <Root>
      <DefaultTemplate message="Informações da " highlightedMessage="Instituição" >
        <StyledPaper>
          <BackCardHeader onClick={() => navigateBackToMicrocertificatesOnClick()} />
        </StyledPaper>
        <Grid item md={12} className={classes.root}>
          <InstitutionManagementInfo
          />
        </Grid>
      </DefaultTemplate>
    </Root >
  );
}

export default InstitutionManagementInfoPage; 
