import React from 'react';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { Paper, Divider } from '@mui/material';
import { BookOpen } from 'react-feather';
import CardTitle from '../../../molecules/card-title';
import EmptyTable from '../../../molecules/empty-table';
import { RootState } from '../../../../../setup/reducer/reducers';
import DouFilter from './DouFilter';
import DouTable from './DouTable';
import { theme } from '../../../../../theme';

const PREFIX = 'DouList';

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    marginBottom: '50px',
    maxHeight: '1000px',
  }
}));

export default function DouList(): JSX.Element {
  const selectedInstitution = useSelector((state: RootState) => state.header.institution);


  return (
    <Root>
      <Paper className={classes.root}>
        <Grid container >
          <CardTitle icon={<BookOpen color={theme.palette.primary.main} />}>
            Controle de Lotes para Publicação no Diário Oficial da União
          </CardTitle>
        </Grid>
        <Divider />
        {selectedInstitution ? (
          <>
            <DouFilter />
            <DouTable />
          </>
        )
          : <EmptyTable orientationMessage="Aguarde a criação de lotes de publicação de DOU na sua IES" />}
      </Paper>
    </Root>
  );
}
