import ChangeType from '../../enum/diploma/ChangeType';
import ReasonType from '../../enum/ReasonType';

export default abstract class ChangeDiplomaRequest {
  type?: ChangeType;

  reason?: string;

  reasonType?: ReasonType;
}
