enum DocumentType {
  RegisterTermOfResponsibility = 'Termo de responsabilidade de registro',
  Rvdd = 'Representação visual do diploma digital',
  AcademicDocumentationXml = 'Xml de documentação acadêmica',
  RegisterBookDiplomaPage = 'Registro de diploma no livro',
  ForwardingLetter = 'Ofício de encaminhamento',
  DispatchTerm = 'Termo de expedição',
  RegisterGraduateXml = 'Xml do diplomado da registradora',
  IssuerGraduateXml = 'Xml do diplomado da emissora',
  DouRegisterBatchTemplate = 'Template de lote de registro do DOU',
  DouCancellationBatchTemplate = 'Template de lote de cancelamento do DOU',
}

export default DocumentType;
