import InstitutionProfile from '../../../../domain/enum/InstitutionProfile';
import DiplomaStatus from '../../../../domain/enum/diploma/DiplomaStatus';
import ListDiplomasRequest from '../../../../domain/interface/request/ListDiplomasRequest';
import Diploma from '../../../../domain/model/diploma/Diploma';
import Institution from '../../../../domain/model/institution/Institution';
import SignerData from '../../../../domain/model/institution/SignerData';
import DiplomaService from '../../../../services/DiplomaService';
import { searchIgnoringCaseAndAccents } from '../../../utils/functions';
import { Data, DiplomaStatusFilter, SelectedDiplomaStatus } from './types';

export const diplomaRegisterStatuses: DiplomaStatusFilter = [
  {
    name: DiplomaStatus.RegisterConfirmationPending
  },
  {
    name: DiplomaStatus.AwaitingRegister
  },
  {
    name: DiplomaStatus.GeneratingRegisterDocuments
  },
  {
    name: DiplomaStatus.AwaitingRegisterSignature
  },
  {
    name: DiplomaStatus.RestartDiplomaFlow
  },
  {
    name: 'Todos'
  }
];

export const diplomaIssuerStatuses: DiplomaStatusFilter = [
  {
    name: DiplomaStatus.IssuerConfirmationPending
  },
  {
    name: DiplomaStatus.GeneratingIssuerDocuments
  },
  {
    name: DiplomaStatus.GeneratingAcademicDocumentation
  },

  {
    name: DiplomaStatus.AwaitingIssuerSignature
  },
  {
    name: DiplomaStatus.RegisterConfirmationPending
  },
  {
    name: DiplomaStatus.AwaitingRegister
  },
  {
    name: DiplomaStatus.GeneratingRegisterDocuments
  },
  {
    name: DiplomaStatus.AwaitingRegisterSignature
  },
  {
    name: DiplomaStatus.GeneratingVisualRepresentation
  },
  {
    name: DiplomaStatus.Completed
  },
  {
    name: DiplomaStatus.Sent
  },
  {
    name: DiplomaStatus.TemporarilyCancelled
  },
  {
    name: DiplomaStatus.Cancelled
  },
  {
    name: DiplomaStatus.RestartDiplomaFlow
  },
  {
    name: DiplomaStatus.InSync
  },
  {
    name: 'Todos'
  }
];

export const diplomaIssuerStatusList: DiplomaStatusFilter = [
  {
    name: DiplomaStatus.IssuerConfirmationPending
  },
  {
    name: DiplomaStatus.GeneratingIssuerDocuments
  },
  {
    name: DiplomaStatus.GeneratingAcademicDocumentation
  },

  {
    name: DiplomaStatus.AwaitingIssuerSignature
  },
  {
    name: DiplomaStatus.RegisterConfirmationPending
  },
  {
    name: DiplomaStatus.AwaitingRegister
  },
  {
    name: DiplomaStatus.GeneratingRegisterDocuments
  },
  {
    name: DiplomaStatus.AwaitingRegisterSignature
  },
  {
    name: DiplomaStatus.GeneratingVisualRepresentation
  },
  {
    name: DiplomaStatus.TemporarilyCancelled
  },
  {
    name: DiplomaStatus.Cancelled
  },
  {
    name: 'Todos'
  },
  {
    name: DiplomaStatus.RestartDiplomaFlow
  },
  {
    name: DiplomaStatus.InSync
  }
];

export const getRows = (diplomas: Diploma[], search: string): Data[] => {
  let rows: Data[] = [];
  if (!diplomas) {
    return rows;
  }
  diplomas.forEach((value) => {
    rows.push({
      originSystem: value.originSystem,
      checkbox: '',
      id: value?.id,
      status: value?.status,
      graduateName: value?.graduate?.name,
      graduateRegistrationNumber: value?.graduate?.registrationNumber,
      courseName: value?.course?.name,
      action: '',
      defaultSigners: value?.defaultSigners
        ? value?.defaultSigners
        : ([] as SignerData[]),
      updatedAt: value?.updatedAt
    });
  });
  if (search.length > 2) {
    search = search.toLowerCase();
    rows = rows.filter(
      (value) =>
        searchIgnoringCaseAndAccents(value?.graduateName, search) ||
        value.graduateRegistrationNumber?.toLowerCase().match(`.*${search}.*`)
    );
  }
  return rows;
};

export const listIssuerInstitutions = async (
  registerMecCode: number,
  registerInstitutions?: Institution[]
): Promise<Institution[]> => {
  if (!registerInstitutions) {
    return [];
  }
  const issuerInstitutions: Institution[] = [];
  const issuerInstitutionsMecCodes: number[] = (
    await DiplomaService.listIssuerInstitutions(registerMecCode)
  ).data;

  issuerInstitutionsMecCodes.forEach((issuerMecCode) => {
    registerInstitutions.forEach((institution) => {
      if (issuerMecCode === institution.mecCode) {
        issuerInstitutions.push(institution);
      }
    });
  });

  return issuerInstitutions;
};

function getAllStatusesOfInstitutionProfile(
  selectedInstitutionProfile: InstitutionProfile | undefined
): Array<DiplomaStatus> | undefined {
  let statuses: Array<DiplomaStatus> | undefined;

  if (selectedInstitutionProfile === InstitutionProfile.Register) {
    statuses = diplomaRegisterStatuses
      .filter((value) => value.name !== 'Todos')
      .map((value) => value.name as DiplomaStatus);
  } else if (selectedInstitutionProfile === InstitutionProfile.Issuer) {
    statuses = diplomaIssuerStatusList
      .filter((value) => value.name !== 'Todos')
      .map((value) => value.name as DiplomaStatus);
  }

  return statuses;
}

export const listDiplomas = async (
  status: SelectedDiplomaStatus,
  registerInstitution?: Institution,
  issuerInstitution?: Institution,
    selectedInstitutionProfile?: InstitutionProfile
): Promise<Diploma[]> => {
  if (!issuerInstitution) {
    return [];
  }

  const statuses =
    status.name === 'Todos'
       ? getAllStatusesOfInstitutionProfile(selectedInstitutionProfile)
      : [status.name as DiplomaStatus];

  let diplomas: Diploma[] = [];
  if(statuses) {
    for(const status of statuses) {
      const request: ListDiplomasRequest = {
        registerMecCode: registerInstitution?.mecCode,
        issuerMecCode: issuerInstitution.mecCode,
        status: status
      };
      const response = await DiplomaService.listDiplomas(request)
      diplomas = diplomas.concat(response);
    };
  } else {
    const request: ListDiplomasRequest = {
        registerMecCode: registerInstitution?.mecCode,
        issuerMecCode: issuerInstitution.mecCode,
        status: undefined
      };
      const response = await DiplomaService.listDiplomas(request)
      diplomas = diplomas.concat(response);
  }

  return diplomas;
};
