type JsonProperty = {
    [key: string]: JsonValue;
};

export type JsonValue = string | number | boolean | JsonProperty | JsonValue[] | null;

const isObject = (value: JsonValue): value is JsonProperty => {
    return typeof value === 'object';
};

export const getInnermostProperties = (obj: JsonProperty) : string[] => {
    let innermostProps: string[] = [];

    for (const key in obj) {
        const value = obj[key];

        if(value === null) {
            continue;
        }

        if (isObject(value)) {
            innermostProps = innermostProps.concat(getInnermostProperties(value));
        } else {
            innermostProps.push(value.toString());
        }
    }

    return innermostProps;
}
