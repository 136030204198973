import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Checkbox, FormHelperText, Icon, ListItem } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { SelectAutocompleteMultipleCheckboxesProps } from './types';
import { MEDIUM_GRAY_COLOR, OPAQUE_DIPLOMA_COLOR } from '../../../../theme';
import CustomTextField from '../../atoms/form/CustomTextField';

const PREFIX = 'SelectAutocompleteMultipleCheckbox';

const internalClasses = {
    activeIcon: `${PREFIX}-activeIcon`,
    popupIndicator: `${PREFIX}-popupIndicator`,
    popupIndicatorOpen: `${PREFIX}-root`,
    activeAutocomplete: `${PREFIX}-activeAutocomplete`,
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${internalClasses.activeIcon}`]: {
        fill: theme.palette.primary.main,
        width: 24,
        height: 24
    },
    [`& .${internalClasses.popupIndicator}`]: {
        marginRight: '5px'
    },
    [`& .${internalClasses.popupIndicatorOpen}`]: {
        color: theme.palette.primary.main
    },
    [`& .${internalClasses.activeAutocomplete}`]: {
        backgroundColor: OPAQUE_DIPLOMA_COLOR,
    }
}
));

const SelectAutocompleteMultipleCheckboxes = (props: SelectAutocompleteMultipleCheckboxesProps) => {
    const {
        listItems, placeholder, label, setItem, classes, selectedValues, displaySelectAll
    } = props;

    let items = listItems;

    if (displaySelectAll) {
        items = [{ value: 'Todos', name: 'Todos' }, ...items];
    }

    return <Root>
        {
            label && (
                <FormHelperText className={classes?.labelAutoComplete}>
                    {label}
                </FormHelperText>
            )
        }
        <Autocomplete
            multiple
            options={items}
            disableCloseOnSelect
            onChange={(_, newValue) => {
                if (selectedValues.find(sv => sv.value === 'Todos')) {
                    if (!newValue.find(nv => nv.value === 'Todos')) {
                        setItem([]);
                    } else {
                        setItem(newValue.filter((v) => v.value !== 'Todos'));
                    }
                } else {
                    if (!newValue.find(nv => nv.value === 'Todos') && newValue.length !== listItems.length) {
                        setItem(newValue);
                    } else {
                        setItem(items);
                    }
                }
            }}
            value={selectedValues}
            classes={{
                popupIndicator: internalClasses.popupIndicator,
                popupIndicatorOpen: internalClasses.popupIndicatorOpen,
                inputRoot: internalClasses.activeAutocomplete
            }}
            renderOption={(props, value, { selected }) => (
                <ListItem {...props} style={{ color: value.color ?? MEDIUM_GRAY_COLOR }}>
                    <Checkbox checked={!!selectedValues.find(sv => sv.value === 'Todos') || selected} value={value} />
                    {value.name}
                </ListItem>
            )}
            renderTags={() => !!selectedValues.find(sv => sv.value === 'Todos') ? `${listItems.length} selecionado(s)` : `${selectedValues.length} selecionado(s)`}
            renderInput={(params) => (
                <CustomTextField
                    {...params}
                    placeholder={selectedValues.length === 0 ? placeholder : ''}
                    hiddenLabel
                />
            )}
            popupIcon={(
                <Icon>
                    <KeyboardArrowDownIcon className={internalClasses.activeIcon} />
                </Icon>
            )}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.value === value.value}
        />
    </Root>;
};

SelectAutocompleteMultipleCheckboxes.defaultProps = {
    loading: false,
    classes: undefined,
    label: '',
    displaySelectAll: false
};

export default SelectAutocompleteMultipleCheckboxes;
