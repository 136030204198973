import { Grid } from '@mui/material';
import { Business } from '@mui/icons-material';
import React from 'react';
import AddressDto from '../../../../domain/interface/dto/AddressDto';
import RegulatoryActDto from '../../../../domain/interface/dto/RegulatoryActDto';
import { DIPLOMA_COLOR } from '../../../../theme';
import CardTitle from '../card-title';
import BasePublicDataWidget from '../base-public-data-widget';
import InlineLabeledText from '../inline-labeled-text';
import { PublicInstitutionDataWidgetProps } from './types';

function PublicInstitutionDataWidget({ institution, institutionProfile, isLoading }: PublicInstitutionDataWidgetProps): JSX.Element {
  return (
    <BasePublicDataWidget title={<CardTitle variant="h3" icon={<Business htmlColor={DIPLOMA_COLOR} />}>{`Dados da Instituição ${institutionProfile}`}</CardTitle>}>
      <>
        <Grid item container>
          <InlineLabeledText label="Nome" text={institution?.name} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Código MEC" text={institution?.mecCode.toString()} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Endereço" text={AddressDto.stringify(institution?.address)} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Credenciamento" text={RegulatoryActDto.stringify(institution?.licensing)} isLoading={isLoading} />
        </Grid>
        {institution?.relicensing ? (
          <Grid item container>
            <InlineLabeledText label="Recredenciamento" text={RegulatoryActDto.stringify(institution?.relicensing)} isLoading={isLoading} />
          </Grid>
        ) : null}
        {institution?.relicensingRenewal ? (
          <Grid item container>
            <InlineLabeledText label="Renovação de Recredenciamento" text={RegulatoryActDto.stringify(institution?.relicensingRenewal)} isLoading={isLoading} />
          </Grid>
        ) : null}
      </>
    </BasePublicDataWidget>
  );
}

PublicInstitutionDataWidget.defaultProps = {
  isLoading: false,
};

export default PublicInstitutionDataWidget;
