import Batch from '../../../../../domain/model/certificate/Batch';
import { AppThunk } from '../../../../../setup/reducer';
import {
  errorNotificationMessages,
  notificationIcons
} from '../../../../utils/constants';
import { showNotification } from '../../notification/actions';
import { listCertificateBatch } from '../CertificateTableManager';
import {
  CertificateActions,
  SET_CERTIFICATE_BATCHES,
  SET_LOADING_BATCHES,
  SET_SEARCH
} from './types';

const internalSetLoadingBatchesCertificates = (
  loadingBatches: boolean
): CertificateActions => ({
  type: SET_LOADING_BATCHES,
  payload: {
    loadingBatches
  }
});

const internalSetSearch = (search: string): CertificateActions => ({
  type: SET_SEARCH,
  payload: {
    search
  }
});

const internalSetCertificateBatches = (
  batches: Batch[]
): CertificateActions => ({
  type: SET_CERTIFICATE_BATCHES,
  payload: {
    batches
  }
});

export const setSearch =
  (search: string): AppThunk =>
  async (dispatch) => {
    search = search.replace(/[*|[|+|(|)]/g, '');
    dispatch(internalSetSearch(search));
  };

export const setLoadingBatchesCertificates =
  (loadingData: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetLoadingBatchesCertificates(loadingData));
  };

export const setBatches = (): AppThunk => async (dispatch) => {
  let batches: Batch[] = [];

  try {
    batches = await listCertificateBatch();
    if (batches.some((cert) => 'message' in cert)) {
      batches = [];
    }
  } catch (e) {
    dispatch(
      showNotification(
        errorNotificationMessages.defaultError,
        notificationIcons.error
      )
    );
  }

  dispatch(internalSetCertificateBatches(batches));
  dispatch(setLoadingBatchesCertificates(false));
};
