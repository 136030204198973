import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AffiliationPaper from '../../../organisms/affiliation-paper';
import { RootState } from '../../../../../setup/reducer/reducers';
import { toggleNextPage } from '../../diploma-page/store/actions';
import AcademicDocGraduatePaper from '../../../organisms/academic-doc-graduate-paper';
import CurriculumPaper from '../../../organisms/curriculum-paper';
import AcademicDocFilesPaper from '../../../organisms/academic-doc-files-paper';
import DefaultTemplate from '../../../templates/default-template';
import DiplomaValidationButton from '../../../molecules/diploma-validation-button';
import DiplomaStatus from '../../../../../domain/enum/diploma/DiplomaStatus';
import CancelDiploma from '../../diploma-modal-cancel/views/CancelDiploma';
import ActivateDiploma from '../../diploma-modal-activate/views/ActivateDiploma';

const PREFIX = 'AcademicDocumentationInformation';

const classes = {
  root: `${PREFIX}-root`,
  fabGridRight: `${PREFIX}-fabGridRight`,
  fabGridLeft: `${PREFIX}-fabGridLeft`
};

const StyledDefaultTemplate = styled(DefaultTemplate)(({ theme }) => ({
  [`& .${classes.root}`]: {
    paddingBottom: theme.spacing(7)
  },

  [`& .${classes.fabGridRight}`]: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(4),
    maxWidth: '45%'
  },

  [`& .${classes.fabGridLeft}`]: {
    position: 'fixed',
    bottom: theme.spacing(3),
    left: theme.spacing(4),
    maxWidth: '45%'
  }
}));

const AcademicDocumentationInformation = () => {
  const dispatch = useDispatch();
  const diploma = useSelector((state: RootState) => state.diplomaPage.diploma);
  const academicDocumentation = useSelector(
    (state: RootState) => state.diplomaPage.diploma?.academicDocumentation
  );

  const [openDiplomaCancellationModal, setOpenDiplomaCancellationModal] =
    useState(false);
  const [openDiplomaActivationModal, setOpenDiplomaActivationModal] =
    useState(false);

  const returnPage = () => {
    dispatch(toggleNextPage());
  };

  const handleSendDiplomaToCancellation = () => {
    setOpenDiplomaCancellationModal(true);
  };

  const handleSendDiplomaToCompleted = () => {
    setOpenDiplomaActivationModal(true);
  };

  return (
    <StyledDefaultTemplate
      message="Informação de"
      highlightedMessage="Documentação Acadêmica"
    >
      <Grid container spacing={2} className={classes.root}>
        <Grid item md={12}>
          <AffiliationPaper
            affiliation={academicDocumentation?.affiliation || undefined}
            onClickBack={returnPage}
          />
        </Grid>
        <Grid item md={12}>
          <AcademicDocGraduatePaper
            academicDocumentation={academicDocumentation || undefined}
          />
        </Grid>
        <Grid item md={12}>
          <CurriculumPaper
            transcript={academicDocumentation?.transcript || undefined}
          />
        </Grid>
        <Grid item md={12}>
          <AcademicDocFilesPaper />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={3}
        className={classes.fabGridRight}
      >
        {(diploma?.status === DiplomaStatus.Completed ||
          diploma?.status === DiplomaStatus.TemporarilyCancelled) && (
            <DiplomaValidationButton
              handleClick={handleSendDiplomaToCancellation}
              validation
              text="Anular Diploma"
              buttonType="warn"
            />
          )}
        {diploma?.status === DiplomaStatus.TemporarilyCancelled && (
          <DiplomaValidationButton
            handleClick={handleSendDiplomaToCompleted}
            validation
            text="Ativar Diploma"
            buttonType="success"
          />
        )}
        <CancelDiploma
          openModal={openDiplomaCancellationModal}
          setOpenModal={setOpenDiplomaCancellationModal}
          id={diploma?.id}
          status={diploma?.status}
        />
        <ActivateDiploma
          openModal={openDiplomaActivationModal}
          setOpenModal={setOpenDiplomaActivationModal}
          id={diploma?.id}
        />
      </Grid>
    </StyledDefaultTemplate>
  );
};

export default AcademicDocumentationInformation;
