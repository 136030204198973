import ReplayIcon from '@mui/icons-material/Replay';
import { Button, Divider, Grid, Paper } from "@mui/material";
import { styled } from '@mui/material/styles';
import { FileText } from 'react-feather';
import { theme } from "../../../../../theme";
import CardTitle from "../../../molecules/card-title";
import DigitalCurriculumFilter from './DigitalCurriculumFilter';
import DigitalCurriculumTable from './DigitalCurriculumTable';

const PREFIX = 'CurriculumList';

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  buttonAdd: `${PREFIX}-buttonAdd`,
  buttonCreateModel: `${PREFIX}-buttonCreateModel`,
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    marginBottom: '50px',
    maxHeight: '1000px',
  },
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main
  },
  [`& .${classes.buttonAdd}`]: {
    height: '2.5rem',
    margin: '0rem 2rem 2rem 0rem'
  },
  [`& .${classes.buttonCreateModel}`]: {
    height: '2.5rem',
    fontWeight: 'bold',
    marginRight: '2rem',
  },
}));

export default function CurriculumList(): JSX.Element {

  return (
    <Root>
      <Paper className={classes.root}>
        <Grid container item xs={12} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <CardTitle icon={<FileText color={theme.palette.primary.main} />}>
            Currículo Digital
          </CardTitle>
        </Grid>

        <Divider />
        <>
          <DigitalCurriculumFilter />
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button variant="contained" size="large" startIcon={<ReplayIcon />} className={classes.buttonAdd}>Recarregar Dados</Button>
            </Grid>
          </Grid>
          <DigitalCurriculumTable />
        </>
      </Paper>
    </Root>
  );
}
