import React from 'react';
import ReactLoading from 'react-loading';
import { DARK_CYAN_COLOR } from '../../../../theme';

type Props = {
  size?: number,
};

const LoadingIcon = (props: Props) => {
  const { size } = props;
  return (
    <ReactLoading
      type="spin"
      color={DARK_CYAN_COLOR}
      height={size}
      width={size}
    />
  );
};

LoadingIcon.defaultProps = {
  size: 24,
};

export default LoadingIcon;
