import Institution from '../../../../../domain/model/institution/Institution';

export const SET_INSTITUTION = 'app/institution-info/SET_INSTITUTION';
export const SET_LOADING_INSTITUTION =
  'app/institution-info/SET_LOADING_INSTITUTION';
export const SET_LOADING_SAVE_SETTINGS =
  'app/institution-info/SET_LOADING_SAVE_SETTINGS';

export interface InstitutionState {
  institution?: Institution;
  loadingInstitution: boolean;
  loadingSaveSettings: boolean;
}

export const INITIAL_STATE: InstitutionState = {
  institution: undefined,
  loadingInstitution: false,
  loadingSaveSettings: false
};

interface SetInstitutionInfo {
  type: typeof SET_INSTITUTION;
  payload: {
    institution?: Institution;
  };
}

interface SetLoadingInstitutionInfo {
  type: typeof SET_LOADING_INSTITUTION;
  payload: {
    loadingInstitution: boolean;
  };
}
interface SetLoadingSaveSettings {
  type: typeof SET_LOADING_SAVE_SETTINGS;
  payload: {
    loadingSaveSettings: boolean;
  };
}

export type DiplomaModalInstitutionInfoActions =
  | SetInstitutionInfo
  | SetLoadingInstitutionInfo
  | SetLoadingSaveSettings;
