import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../setup/reducer/reducers';
import DefaultTemplate from '../../../templates/default-template';
import DouList from '../../dou-list/views/DouList';
import DouInfo from '../../dou-info/views/DouInfo';

const DouPage = () => {
  const info = useSelector((state: RootState) => state.douPage.info);

  if (!info) {
    return (
      <DefaultTemplate>
        <DouList />
      </DefaultTemplate>
    );
  }
  return <DouInfo />;
};

export default DouPage;
