import { Grid, IconButton } from "@mui/material";
import { styled } from "@mui/styles";
import { Info } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GRAY_COLOR, DIPLOMA_COLOR } from "../../../../../theme";
import { pageRoutes } from '../../../../utils/constants';
import CustomizedTooltip from "../../../atoms/customized-tooltip";
import { setBatchCertificates, setCertificates, setSearchCertificates } from '../../certificate-batch-info/store/actions';
import { CertificateActionProps } from '../types';

const PREFIX = 'CertificateAction';

const classes = {
  icon: `${PREFIX}-icon`,
  activeIcon: `${PREFIX}-activeIcon`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.icon}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.25rem',
    height: '1.25rem',
    color: GRAY_COLOR,
    cursor: 'not-allowed',
  },

  [`& .${classes.activeIcon}`]: {
    cursor: 'pointer',
    color: DIPLOMA_COLOR,
  },
}));

export default function CertificateAction(props: CertificateActionProps) {
  const { id, disabledButton } = props
  const history = useHistory();
  const dispatch = useDispatch();

  const navigateToCertificateInfoOnClick = () => {
    const formatId = id.split('#')
    dispatch(setCertificates(formatId[1]))
    dispatch(setBatchCertificates(formatId[1]))
    dispatch(setSearchCertificates(''))
    history.push(pageRoutes.CertificateInfo)
  }

  return (
    <>
      <StyledGrid
        container
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item>
          <CustomizedTooltip title="Ver mais" placement="top">
            <span>
              <IconButton size="large" onClick={() => navigateToCertificateInfoOnClick()} disabled={disabledButton}>
                <Info className={`${classes.icon} ${!disabledButton && classes.activeIcon} `} />
              </IconButton>
            </span>
          </CustomizedTooltip>
        </Grid>
      </StyledGrid>
    </>
  )
}
