import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { Download } from 'react-feather';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../pages/notification/actions';
import { downloadBase64UrlFile } from '../../../utils/functions/downloadFile';
import CustomizedTooltip from '../../atoms/customized-tooltip';
import { PublicDiplomaActionButtonGroupProps } from './types';

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
  cursorNotAllowed: `${PREFIX}-cursorNotAllowed`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.cursorNotAllowed}`]: {
    cursor: 'not-allowed',
  }
}));

export default function PublicDiplomaActionButtonGroup({ diploma, isLoading }: PublicDiplomaActionButtonGroupProps): JSX.Element {

  const dispatch = useDispatch();
  const [downloadPdfButtonLoading, setDownloadPdfButtonLoading] = useState(false);
  const [downloadXmlButtonLoading, setDownloadXmlButtonLoading] = useState(false);

  const disableDownloadButtons = () => !diploma || isLoading;
  const disableXmlDownloadButton = () => disableDownloadButtons() || !diploma?.linkDownloadPublicXml || downloadXmlButtonLoading;
  const disablePdfDownloadButton = () => disableDownloadButtons() || !diploma?.linkDownloadRvdd || downloadPdfButtonLoading;

  const downloadFile = async (fileName: string, fileExtension: any, base64Url?: string) => {
    try {
      if (base64Url) {
        await downloadBase64UrlFile({
          fileName,
          base64Url,
          fileExtension,
        });
      } else {
        dispatch(showNotification());
      }
    } catch (err) {
      dispatch(showNotification());
    }
  };

  const handleDownloadPdfButtonClick = async () => {
    setDownloadPdfButtonLoading(true);
    await downloadFile(`diploma_${diploma?.processNumber || ''}`, 'application/pdf', diploma?.linkDownloadRvdd);
    setDownloadPdfButtonLoading(false);
  };

  const handleDownloadXmlButtonClick = async () => {
    setDownloadXmlButtonLoading(true);
    await downloadFile(`xml_${diploma?.processNumber || ''}`, 'application/xml', diploma?.linkDownloadPublicXml);
    setDownloadXmlButtonLoading(false);
  };

  return (
    <StyledGrid className={classes.root} container item direction="row" spacing={2} justifyContent="center" xs={12}>
      <Grid item xs={6}>
        <CustomizedTooltip title={diploma?.linkDownloadPublicXml ? '' : 'Documento indisponível'} placement="top">
          <Box className={disableXmlDownloadButton() ? classes.cursorNotAllowed : ''}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={handleDownloadXmlButtonClick}
              startIcon={downloadXmlButtonLoading ? <CircularProgress size={16} /> : <Download />}
              disabled={disableXmlDownloadButton()}
            >
              Baixar XML
            </Button>
          </Box>
        </CustomizedTooltip>
      </Grid>
      <Grid item xs={6}>
        <CustomizedTooltip title={diploma?.linkDownloadRvdd ? '' : 'Documento indisponível'} placement="top">
          <Box className={disablePdfDownloadButton() ? classes.cursorNotAllowed : ''}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={handleDownloadPdfButtonClick}
              startIcon={downloadPdfButtonLoading ? <CircularProgress size={16} /> : <Download />}
              disabled={disablePdfDownloadButton()}
            >
              Baixar PDF
            </Button>
          </Box>
        </CustomizedTooltip>
      </Grid>
    </StyledGrid>
  );
}
