import Employee from '../../../../../domain/model/employee/Employee';
import EmployeeService from '../../../../../services/EmployeeService';
import store, { AppThunk } from '../../../../../setup/reducer';
import {
  errorNotificationMessages,
  notificationIcons
} from '../../../../utils/constants';
import { showNotification } from '../../notification/actions';
import {
  AuthorizationActions,
  SET_SHOW_SIGN_DOCUMENTS,
  SET_EMPLOYEE
} from './types';

const internalShowSignDocuments = (
  showSignDocuments?: boolean
): AuthorizationActions => ({
  type: SET_SHOW_SIGN_DOCUMENTS,
  payload: {
    showSignDocuments
  }
});

export const setShowSignDocuments =
  (showSignDocuments?: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(internalShowSignDocuments(showSignDocuments));
  };

const internalSetEmployee = (employee?: Employee): AuthorizationActions => ({
  type: SET_EMPLOYEE,
  payload: {
    employee
  }
});

export const setEmployee =
  (employee?: Employee): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetEmployee(employee));
  };

export const setEmployees =
  (tenantId: string): AppThunk =>
  async (dispatch) => {
    let employees: Employee[];
    const user = store.getState().login.user;

    try {
      employees = (await EmployeeService.listEmployees()).data;
      if (employees) {
        let employee = employees.find(({ email }) => email === user?.email);
        dispatch(internalSetEmployee(employee));
      }
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
    }
  };
