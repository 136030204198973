import { Button, Grid, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { pageRoutes } from '../../../../utils/constants';
import BackCardHeader from '../../../molecules/back-card-header';
import DefaultTemplate from '../../../templates/default-template';
import { useSelector } from 'react-redux';
import InstitutionProfile from '../../../../../domain/enum/InstitutionProfile';
import { RootState } from '../../../../../setup/reducer/reducers';
import CurriculumCoursePaper from '../../../organisms/curriculum-course-paper';
import CurriculumIssuerInstitutionPaper from '../../../organisms/curriculum-institution-paper';
import { useState } from 'react';
import CurriculumDataPaper from '../../../organisms/curriculum-data-paper';
import CurriculumComplementaryActivityPaper from '../../../organisms/curriculum-complementary-activity-paper';
import CurriculumCurricularUnitiesPaper from '../../../organisms/curriculum-curricular-unities-paper';
import CurriculumCriteriaIntegralizationPaper from '../../../organisms/curriculum-criteria-integralization-paper';
import CurriculumSecurityInfoPaper from '../../../organisms/curriculum-security-info-paper';
import TabName from '../../../../../domain/enum/curriculum/TabName';
import { XCircle } from 'react-feather';
import CurriculumStatus from '../../../../../domain/enum/curriculum/CurriculumStatus';
import { GRAY_COLOR } from '../../../../../theme';
import DigitalCurriculumCanceledModal from '../../../organisms/digital-curriculum-cancel-modal/DigitalCurriculumCanceledModal';

const PREFIX = 'CurriculumInfoPage';

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  activeIconCancel: `${PREFIX}-activeIconCancel`,
  tabButtonActive: `${PREFIX}-tabButtonActive`,
  tabButtonInactive: `${PREFIX}-tabButtonInactive`,
  tabButtonContainer: `${PREFIX}-tabButtonContainer`,
  divTabButton: `${PREFIX}-divTabButton`,
  cancellationButton: `${PREFIX}-cancellationButton`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    paddingBottom: theme.spacing(2),
  },
  [`& .${classes.icon}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.25rem',
    height: '1.25rem',
    color: GRAY_COLOR,
    cursor: 'not-allowed',
  },
  [`& .${classes.activeIconCancel}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  [`& .${classes.tabButtonActive}`]: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '18px'
  },
  [`& .${classes.tabButtonInactive}`]: {
    color: theme.typography.body1.color,
    fontWeight: 600,
    fontSize: '18px'
  },
  [`& .${classes.tabButtonContainer}`]: {
    display: 'flex'
  },
  [`& .${classes.divTabButton}`]: {
    height: '2px',
    width: '20px',
    backgroundColor: theme.palette.primary.main,
    margin: 'auto'
  },
  [`& .${classes.cancellationButton}`]: {
    cursor: 'pointer',
    '& p': {
      fontSize: '14px',
      paddingLeft: '5px',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    }
  }
}));


const CurriculumInfoPage = (): JSX.Element => {
  const curriculum = useSelector((state: RootState) => state.curriculumInfoPage.curriculum);
  const loadingCurriculum = useSelector(
    (state: RootState) => state.curriculumInfoPage.isLoadingCurriculum
  );

  const [tabName, setTabName] = useState(TabName.iesData);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const enableCancel = curriculum?.root?.status === CurriculumStatus.AwaitingXmlSignature;

  const history = useHistory();
  const navigateBackToCurriculumPage = () => {
    history.push(pageRoutes.DigitalCurriculum)
  }

  return (
    <Root>
      <DefaultTemplate message="Informações do" highlightedMessage="Currículo" >
        <Grid container item xs={12} className={classes.root} direction={'row'} justifyContent={'space-between'}>
          <Grid item xs={6}>
            <BackCardHeader showDivider={false} onClick={() => navigateBackToCurriculumPage()} />
          </Grid>
          <Grid container item xs={6} direction='row' className={classes.root} justifyContent={'flex-end'}>
            <Grid item container xs={12} direction='row' alignItems="center" justifyContent={'flex-end'} className={classes.tabButtonContainer} >
              <div>
                <Button className={tabName === TabName.iesData ? classes.tabButtonActive : classes.tabButtonInactive} onClick={() => setTabName(TabName.iesData)}>Dados da IES e do Curso</Button>
                {tabName === TabName.iesData && <div className={classes.divTabButton} />}
              </div>
              <div>
                <Button className={tabName === TabName.curriculumData ? classes.tabButtonActive : classes.tabButtonInactive} onClick={() => setTabName(TabName.curriculumData)}>Dados do Currículo</Button>
                {tabName === TabName.curriculumData && <div className={classes.divTabButton} />}
              </div>
            </Grid>
          </Grid>
        </Grid>
        {tabName === TabName.iesData ? (
          <Grid container spacing={3}>
            <Grid item>
              <CurriculumCoursePaper course={curriculum?.course} isLoading={loadingCurriculum} />
            </Grid>
            <Grid item>
              <CurriculumIssuerInstitutionPaper institution={curriculum?.issuerInstitution} profile={InstitutionProfile.Issuer} isLoading={loadingCurriculum} />
            </Grid>
          </Grid>
        ) : (
          <Grid container direction='column' spacing={3}>
            <Grid item>
              <CurriculumDataPaper root={curriculum?.root} isLoading={loadingCurriculum} />
            </Grid>
            <Grid item>
              <CurriculumCurricularUnitiesPaper curriculum={curriculum} isLoading={loadingCurriculum} />
            </Grid>
            <Grid item>
              <CurriculumComplementaryActivityPaper complementaryActivityCategories={curriculum?.complementaryActivityCategories} isLoading={loadingCurriculum} />
            </Grid>
            <Grid item>
              <CurriculumCriteriaIntegralizationPaper criteriaIntegralization={curriculum?.criteriaIntegralization} isLoading={loadingCurriculum} />
            </Grid>
            <Grid item>
              <CurriculumSecurityInfoPaper curriculum={curriculum} isLoading={loadingCurriculum} />
            </Grid>
          </Grid>
        )}
        {enableCancel && (
          <Grid container style={{ width: '100%' }} justifyContent="flex-end">
            <IconButton size="large" disabled={!enableCancel} className={classes.cancellationButton} onClick={() => setIsCancelModalOpen(true)}>
              <XCircle className={`${classes.icon} ${!enableCancel ? '' : classes.activeIconCancel}`} />
              <p>Cancelar Fluxo</p>
            </IconButton>
            <DigitalCurriculumCanceledModal
              handleClose={(success) => {
                setIsCancelModalOpen(false);
                if (success) {
                  navigateBackToCurriculumPage();
                }
              }}
              isOpen={isCancelModalOpen}
              curriculumId={curriculum!.curriculumId} />
          </Grid>
        )}
      </DefaultTemplate>
    </Root>

  );
}


export default CurriculumInfoPage; 
