import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import { EmptyTableProps } from './types';
import NotFound from '../../../icons/NotFound';
import { GRAY_COLOR, MEDIUM_GRAY_COLOR } from '../../../../theme';

const PREFIX = 'EmptyTable';

const classes = {
  root: `${PREFIX}-root`,
  h3: `${PREFIX}-h3`,
  body2: `${PREFIX}-body2`,
  gridEmptyTable: `${PREFIX}-gridEmptyTable`
};

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {
    height: '528px',
  },

  [`& .${classes.h3}`]: {
    color: MEDIUM_GRAY_COLOR,
    marginBottom: '12px',
  },

  [`& .${classes.body2}`]: {
    color: GRAY_COLOR,
  },

  [`& .${classes.gridEmptyTable}`]: {
    maxHeight: '52%',
  }
}));

function EmptyTable({ emptyStateMessage, orientationMessage }: EmptyTableProps): JSX.Element {
  return (
    <StyledGrid
      className={classes.root}
      container
    >
      <Grid
        className={classes.gridEmptyTable}
        container
        item
        xs={12}
        alignContent="flex-end"
        justifyContent="center"
      >
        <NotFound />
      </Grid>
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        alignContent="center"
        direction="column"
      >
        <Typography className={classes.h3} variant="h3">{emptyStateMessage}</Typography>
        <Typography className={classes.body2} variant="body2">{orientationMessage}</Typography>
      </Grid>
    </StyledGrid>
  );
}

EmptyTable.defaultProps = {
  emptyStateMessage: 'Nenhum item encontrado',
};

export default EmptyTable;
