import React from 'react';
import useDotStepIconStyles from './styles';
import DotStepIconProps from './types';

function DotStepIcon({ active, activeColor }: DotStepIconProps) {
  const styles = useDotStepIconStyles({ activeColor });

  return (
    <div className={`${styles.dot} ${active && styles.activeDot}`} />
  );
}

export default DotStepIcon;
