import { AxiosResponse } from 'axios';
import getAxiosInstance from '../apis/axiosInstance';
import Root from '../domain/model/curriculum/Root';
import Curriculum from '../domain/model/curriculum/Curriculum';
import GetCurriculumRequest from '../domain/interface/request/GetCurriculumRequest';
import ChangeCurriculumRequest from '../domain/interface/request/ChangeCurriculumRequest';
import GetPublicCurriculumRequest from '../domain/interface/request/GetPublicCurriculumRequest';
import GetPublicCurriculumResponse from '../domain/interface/response/GetPublicCurriculumResponse';

let axiosInstance = getAxiosInstance(
  process.env.REACT_APP_CURRICULUM_API || ''
);

class CurriculumService {
  async getCurriculums(issuerMecCode: number): Promise<AxiosResponse<Root[]>> {
    return axiosInstance.get(
      `list-curriculumsRoot?issuerMecCode=${issuerMecCode}`
    );
  }

  async getCurriculum(
    request: GetCurriculumRequest
  ): Promise<AxiosResponse<Curriculum>> {
    return axiosInstance.get(`curriculum/${request.id}`);
  }
  
  async getPublicCurriculum(
    request: GetPublicCurriculumRequest
  ): Promise<GetPublicCurriculumResponse> {
    const response = await axiosInstance.get(
      `curriculum/public?validationCode=${request.validationCode}`
    );
    return response.data;
  }

  async validateCurriculum(
    curriculum: Curriculum
  ): Promise<AxiosResponse<any>> {
    const request = {
      curriculum: {
        ...curriculum
      }
    };
    return axiosInstance.post('curriculum/validate', request);
  }

  async addChangeCurriculum(
    request: ChangeCurriculumRequest,
    curriculumId: string | undefined
  ): Promise<AxiosResponse<any>> {
    return axiosInstance.post(`curriculum/${curriculumId}/change`, request);
  }
}

export default new CurriculumService();
