import { formatDate } from '../../shared/utils/functions';
import RegulatoryActPublicationType from '../enum/RegulatoryActPublicationType';
import EmecProcessInfo from './EmecProcessInfo';

export default class RegulatoryAct {
  publicationType?: RegulatoryActPublicationType;

  number?: string;

  date?: string;

  publicationVehicle?: string;

  publicationDate?: string;

  publicationSection?: number;

  publicationPage?: number;

  emecProcessInfo?: EmecProcessInfo;

  static stringify(
    regulatoryAct: RegulatoryAct | undefined
  ): string | undefined {
    if (!regulatoryAct) {
      return undefined;
    }

    if (regulatoryAct.emecProcessInfo) {
      const emecProcessInfoDescription: string[] = [];
      emecProcessInfoDescription.push(
        'Portaria MEC n° 23 de 21/12/2017, DOU n° 170, Seção 1, Página 44. '
      );
      emecProcessInfoDescription.push('Processo em trâmite');

      if (regulatoryAct.emecProcessInfo.emecProcessNumber) {
        emecProcessInfoDescription.push(
          `, sob o n° ${regulatoryAct.emecProcessInfo.emecProcessNumber}`
        );
      }

      if (regulatoryAct.emecProcessInfo.registerDate) {
        emecProcessInfoDescription.push(
          `, cadastrado em ${formatDate(
            regulatoryAct.emecProcessInfo.registerDate
          )}`
        );
      }
      if (regulatoryAct.emecProcessInfo.protocolDate) {
        emecProcessInfoDescription.push(
          ` e protocolado em ${formatDate(
            regulatoryAct.emecProcessInfo.protocolDate
          )}`
        );
      }

      return emecProcessInfoDescription.join('');
    }

    const initialDescription: string[] = [];
    if (regulatoryAct.publicationType) {
      initialDescription.push(regulatoryAct.publicationType);
    }
    if (regulatoryAct.number) {
      initialDescription.push(regulatoryAct.number);
    }
    if (regulatoryAct.date) {
      initialDescription.push(`de ${formatDate(regulatoryAct.date)}`);
    }
    const description: string[] = [];
    description.push(initialDescription.join(' '));
    if (regulatoryAct.publicationVehicle) {
      description.push(regulatoryAct.publicationVehicle);
    }
    if (regulatoryAct.publicationSection) {
      description.push(`Seção ${regulatoryAct.publicationSection}`);
    }
    if (regulatoryAct.publicationPage) {
      description.push(`Página ${regulatoryAct.publicationPage}`);
    }
    return description.join(', ');
  }
}
