import ChangeHistory from '../../../../domain/model/ChangeHistory';
import { getLongDateFormat } from '../../../utils/functions';
import InlineLabeledText from '../../molecules/inline-labeled-text';
import VerticalTimeline from '../vertical-timeline';
import ChangeHistoryManager from './HistoryManager';
import { ChangesTimelineProps } from './types';

const {
  isDiplomaIssuerRequester,
  isDiplomaTemporaryCancel,
  getChangeTypeColor,
  isCompleted,
  isDiplomaRegisterRequester,
  isAnyActivateDiplomaChangeType,
  getOnlyChangesAfterDiplomaConclusionSortted,
} = ChangeHistoryManager;

function ChangesTimeline({ history, onPublicPortal }: ChangesTimelineProps) {
  const changes = history ? getOnlyChangesAfterDiplomaConclusionSortted(history) : [];

  const hasChanges = () => changes.length > 0;

  const getPeriodLabel = (change: ChangeHistory) => {
    if (isCompleted(change) || isAnyActivateDiplomaChangeType(change)) {
      return onPublicPortal ? 'Período da ativação' : 'Período da conclusão';
    }

    if (isDiplomaTemporaryCancel(change)) {
      return 'Período da anulação temporária';
    }

    return onPublicPortal ? 'Período da inativação' : 'Período da anulação';
  };

  const buildLabel = (change: ChangeHistory, previousChange: ChangeHistory) => {
    const label = getPeriodLabel(change);

    const text = previousChange
      ? `${getLongDateFormat(change.date)} até ${getLongDateFormat(previousChange.date)}`
      : `${getLongDateFormat(change.date)}`;

    return (
      <InlineLabeledText
        label={label}
        text={text}
      />
    );
  };

  const getRequestedByLabel = (change: ChangeHistory) => {
    if (isCompleted(change) || isAnyActivateDiplomaChangeType(change)) {
      return onPublicPortal ? 'Ativado por' : 'Concluído por';
    }

    return onPublicPortal ? 'Inativado por' : 'Anulado por';
  };

  const getRequestedByText = (change: ChangeHistory) => {
    let suffix = '';

    const requestedByText = change.requestedBy;

    if (isDiplomaIssuerRequester(change)) {
      suffix = ' (em nome da IES Emissora)';
    }

    if (isDiplomaRegisterRequester(change)) {
      suffix = ' (em nome da IES Registradora)';
    }

    return `${requestedByText}${suffix}.`;
  };

  const buildContent = (change: ChangeHistory) => {
    const requestedByLabel = getRequestedByLabel(change);
    const requestedByText = getRequestedByText(change);

    return (
      <>
        {
          !onPublicPortal && change.requestedBy && (
            <InlineLabeledText
              label={requestedByLabel}
              text={requestedByText}
            />
          )
        }
        {
          change.reason && (
            <InlineLabeledText
              label="Motivo"
              text={change.reason}
            />
          )
        }
      </>
    );
  };

  const buildChangesSteps = () => changes.map((change, i) => ({
    label: buildLabel(change, changes[i - 1]),
    content: buildContent(change),
    activeColor: getChangeTypeColor(change),
  }));

  return (
    <>
      {
        hasChanges() && (
          <VerticalTimeline
            steps={buildChangesSteps()}
          />
        )
      }
    </>
  );
}

export default ChangesTimeline;
