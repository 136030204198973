import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Tooltip,
  Divider,
  Skeleton
} from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { CheckCircle, AlertTriangle } from 'react-feather';
import { useSelector } from 'react-redux';
import {
  CURRICULUM_COLOR,
  DARK_GRAY_COLOR,
  GRAY_COLOR,
  FONT_FAMILY_EXTRABOLD,
  FONT_FAMILY_BOLD
} from '../../../../theme';
import {
  EnhancedTableProps,
  CurriculumData,
  HeadCell,
  CurriculumTableProps,
  TableLoadingProps
} from './types';
import {
  getComparator,
  stableSort
} from '../../pages/diploma-list/DiplomaTableManager';
import PaperTooltip from '../../atoms/paper-tooltip';
import DiplomaTeacher from '../../../../domain/model/diploma/Teacher';
import DiplomaEnrolledCourse from '../../../../domain/model/diploma/EnrolledCourse';
import { RootState } from '../../../../setup/reducer/reducers';

const PREFIX = 'CurriculumTable';

const classes = {
  tooltip: `${PREFIX}-tooltip`,
  root: `${PREFIX}-root`,
  dropdown: `${PREFIX}-dropdown`,
  pagination: `${PREFIX}-pagination`,
  cellLabel: `${PREFIX}-cellLabel`,
  errorCellLabel: `${PREFIX}-errorCellLabel`,
  activeSortIcon: `${PREFIX}-activeSortIcon`,
  inactiveSortIcon: `${PREFIX}-inactiveSortIcon`,
  validationSuccess: `${PREFIX}-validationSuccess`,
  validationError: `${PREFIX}-validationError`,
  successIcon: `${PREFIX}-successIcon`,
  errorIcon: `${PREFIX}-errorIcon`,
  validationTableCell: `${PREFIX}-validationTableCell`,
  tooltipValidationError: `${PREFIX}-tooltipValidationError`,
  tooltipContainer: `${PREFIX}-tooltipContainer`,
  tooltipTypography: `${PREFIX}-tooltipTypography`,
  teachersIcon: `${PREFIX}-teachersIcon`,
  teachersTooltipIcon: `${PREFIX}-teachersTooltipIcon`,
  teachersTypography: `${PREFIX}-teachersTypography`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '100%',
    paddingLeft: '30px',
    paddingRight: '30px'
  },

  [`& .${classes.dropdown}`]: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },

  [`& .${classes.pagination}`]: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight
  },

  [`& .${classes.cellLabel}`]: {
    color: DARK_GRAY_COLOR,
    fontFamily: theme.typography.body2.fontFamily
  },

  [`& .${classes.errorCellLabel}`]: {
    color: theme.palette.error.main,
    fontFamily: theme.typography.body2.fontFamily
  },

  [`& .${classes.activeSortIcon}`]: {
    fill: theme.palette.primary.main,
    width: 24,
    height: 24
  },

  [`& .${classes.inactiveSortIcon}`]: {
    width: 24,
    height: 24
  },

  [`& .${classes.validationSuccess}`]: {
    color: theme.palette.success.main,
    fontFamily: FONT_FAMILY_EXTRABOLD,
    fontSize: 14,
    marginLeft: 8,
    display: 'inline-block'
  },

  [`& .${classes.validationError}`]: {
    color: theme.palette.error.main,
    fontFamily: FONT_FAMILY_EXTRABOLD,
    fontSize: 14,
    marginLeft: 8,
    display: 'inline-block'
  },

  [`& .${classes.successIcon}`]: {
    color: theme.palette.success.main
  },

  [`& .${classes.errorIcon}`]: {
    color: theme.palette.error.main
  },

  [`& .${classes.validationTableCell}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  [`& .${classes.tooltipValidationError}`]: {
    color: theme.palette.error.main,
    fontFamily: FONT_FAMILY_BOLD,
    fontSize: 16,
    marginLeft: 8,
    marginRight: 24,
    display: 'inline-block'
  },

  [`&.${classes.tooltipContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 16
  },

  [`& .${classes.tooltipTypography}`]: {
    margin: 8,
    marginLeft: 16
  },

  [`& .${classes.teachersIcon}`]: {
    color: GRAY_COLOR
  },

  [`& .${classes.teachersTooltipIcon}`]: {
    color: theme.palette.primary.main
  },

  [`& .${classes.teachersTypography}`]: {
    fontFamily: FONT_FAMILY_BOLD,
    color: theme.palette.primary.main,
    marginLeft: 8,
    marginRight: 24
  }
}));

const CustomizedTooltip = Tooltip;

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { headCells, classes, order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof CurriculumData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ width: headCell.size, whiteSpace: 'nowrap' }}
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                classes={{
                  icon:
                    orderBy === headCell.id
                      ? classes.activeSortIcon
                      : classes.inactiveSortIcon
                }}
                IconComponent={KeyboardArrowDown}
              >
                <Typography variant="caption" style={headCell.styles}>
                  {headCell.label}
                </Typography>
              </TableSortLabel>
            ) : (
              <Typography variant="caption" style={headCell.styles}>
                {headCell.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TableLoading = ({ size }: TableLoadingProps) => (
  <TableBody>
    {[...Array(10)].map((_e, i) => (
      <TableRow key={i++}>
        {[...Array(size)].map((_f, j) => (
          <TableCell key={i++ + j++} colSpan={1}>
            <Skeleton style={{ height: 32 }} animation="wave" />
          </TableCell>
        ))}
      </TableRow>
    ))}
  </TableBody>
);

const CurriculumTable = (props: CurriculumTableProps) => {
  const { loading, curriculum, validation } = props;
  const [rows, setRows] = useState<CurriculumData[]>([]);
  const isInfoMode = useSelector((state: RootState) => state.diplomaPage.info);

  useEffect(() => {
    if (curriculum) {
      const curriculumRows: CurriculumData[] = [];
      curriculum.forEach((v: DiplomaEnrolledCourse, i: number) => {
        const row = {
          id: i,
          code: v.code || '',
          period: v.period || '',
          courseName: v.name || '',
          situation: v.situation || '',
          grade:
            v?.evaluationGrade0to100?.toString() ||
            v.evaluationGrade0to10?.toString() ||
            v.evaluationConcept ||
            '',
          courseLoad: v.courseLoad,
          teachers: v.teachers || [],
          error:
            (validation &&
              validation[i] &&
              Object.keys(validation[i]).length) ||
            0,
          action: ''
        };

        curriculumRows.push(row);
      });
      setRows(curriculumRows);
    }
  }, [curriculum, validation]);

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof CurriculumData>('courseName');
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof CurriculumData
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const renderData = (
    data: string,
    error: boolean,
    alignment: 'center' | 'right' | 'left'
  ) => {
    if (data && data.length > 60) {
      return (
        <TableCell align={alignment}>
          <CustomizedTooltip
            title={data}
            placement="top-start"
            classes={{
              tooltip: classes.tooltip
            }}
          >
            <Typography
              className={(error && classes.errorCellLabel) || classes.cellLabel}
              variant="body1"
            >{`${data.slice(0, 60)}...`}</Typography>
          </CustomizedTooltip>
        </TableCell>
      );
    }
    return (
      <TableCell align={alignment}>
        <Typography
          className={(error && classes.errorCellLabel) || classes.cellLabel}
          variant="body1"
        >
          {data || 'N/A'}
        </Typography>
      </TableCell>
    );
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const getNumPages = () => Math.ceil((rows && rows.length) / rowsPerPage) - 1;

  let headCells: HeadCell[] = [
    {
      id: 'period',
      label: 'Período',
      size: '10%',
      align: 'right',
      sortable: true
    },
    {
      id: 'code',
      label: 'Código',
      size: '10%',
      sortable: true
    },
    {
      id: 'courseName',
      label: 'Disciplina',
      size: '25%',
      sortable: true
    },
    {
      id: 'situation',
      label: 'Situação',
      size: '10%',
      align: 'right',
      sortable: true
    },
    {
      id: 'grade',
      label: 'Resultado',
      size: '10%',
      align: 'right',
      sortable: true
    },
    {
      id: 'courseLoad',
      label: 'Carga Horária',
      size: '10%',
      align: 'right',
      sortable: true
    },
    {
      id: 'teachers',
      label: 'Docentes',
      size: '10%',
      align: 'center'
    },
    {
      id: 'error',
      label: 'Validação',
      size: '15%',
      align: 'center',
      sortable: true
    }
  ];

  if (isInfoMode) {
    headCells = headCells.filter((cell) => cell.label !== 'Validação');
  }

  const getTableErrorMessage = (errors: number) => {
    if (errors === 1) return '1 INCONSISTÊNCIA';
    if (errors > 1) return `${errors.toString()} INCONSISTÊNCIAS`;
    return '';
  };

  const getTooltipErrorMessage = (errors: number) => {
    if (errors === 1) return '1 Inconsistência encontrada';
    if (errors > 1) return `${errors.toString()} Inconsistências encontradas`;
    return '';
  };

  const getCurriculumErrors = (id: number, row: CurriculumData) => {
    const errors: React.ReactNode[] = [];
    if (validation && validation[id]?.period) {
      errors.push(
        <Typography
          key={`${id}-1`}
          className={classes.tooltipTypography}
          variant="h6"
        >{`Período: ${validation[id]?.period}`}</Typography>
      );
    }
    if (validation && validation[id]?.code) {
      errors.push(
        <Typography
          key={`${id}-2`}
          className={classes.tooltipTypography}
          variant="h6"
        >{`Código: ${validation[id]?.code}`}</Typography>
      );
    }
    if (validation && validation[id]?.name) {
      errors.push(
        <Typography
          key={`${id}-3`}
          className={classes.tooltipTypography}
          variant="h6"
        >{`Disciplina: ${validation[id]?.name}`}</Typography>
      );
    }
    if (validation && validation[id]?.evaluationGrade0to10) {
      errors.push(
        <Typography
          key={`${id}-4`}
          className={classes.tooltipTypography}
          variant="h6"
        >{`Nota: ${validation[id]?.evaluationGrade0to10}`}</Typography>
      );
    }
    if (validation && validation[id]?.evaluationGrade0to100) {
      errors.push(
        <Typography
          key={`${id}-4`}
          className={classes.tooltipTypography}
          variant="h6"
        >{`Nota (0 a 100): ${validation[id]?.evaluationGrade0to100}`}</Typography>
      );
    }
    if (validation && validation[id]?.evaluationConcept) {
      errors.push(
        <Typography
          key={`${id}-4`}
          className={classes.tooltipTypography}
          variant="h6"
        >{`Conceito: ${validation[id]?.evaluationConcept}`}</Typography>
      );
    }
    if (validation && validation[id]?.situation) {
      errors.push(
        <Typography
          key={`${id}-5`}
          className={classes.tooltipTypography}
          variant="h6"
        >{`Situação: ${validation[id]?.situation}`}</Typography>
      );
    }
    if (validation && validation[id]?.courseLoad) {
      errors.push(
        <Typography
          key={`${id}-6`}
          className={classes.tooltipTypography}
          variant="h6"
        >{`Carga Horária: ${validation[id]?.courseLoad}`}</Typography>
      );
    }
    if (validation && validation[id]?.teachers) {
      if (typeof validation[id]?.teachers === 'string') {
        errors.push(
          <Typography
            key={`${id}-7`}
            className={classes.tooltipTypography}
            variant="h6"
          >{`Docentes: ${validation[id]?.teachers}`}</Typography>
        );
      } else if (Object.keys(validation[id]?.teachers).length > 0) {
        validation[id]?.teachers.forEach((v: any, i: number) => {
          const newIndex = i
          errors.push(
            <Typography
              key={`${id}-7-${newIndex}`}
              className={classes.tooltipTypography}
              variant="h6"
            >{`${i + 1}° Docente: ${v?.name || row?.teachers[i]?.name || 'N/A'
              }, ${v?.title || row?.teachers[i]?.title || 'N/A'}`}</Typography>
          );
        });
      }
    }
    return errors;
  };

  const getTeachers = (teachers: Array<DiplomaTeacher>) => {
    const teachersRow: React.ReactNode[] = [];
    teachers.forEach((v: DiplomaTeacher, i: number) => {
      const newIndex = i
      teachersRow.push(
        <Typography
          key={newIndex}
          className={classes.tooltipTypography}
          variant="h6"
        >{`${v.name || 'N/A'} (${v.title || 'N/A'})`}</Typography>
      );
    });
    return teachersRow;
  };

  const getTeacherCell = (row: CurriculumData) => (
    <TableCell align="center">
      <SupervisorAccountIcon
        className={
          (validation && validation[row.id]?.teachers && classes.errorIcon) ||
          classes.teachersIcon
        }
      />
    </TableCell>
  );

  const buildCells = (row: CurriculumData) => (
    <TableRow hover key={row.id}>
      {renderData(
        row.period,
        validation && validation[row.id]?.period,
        'right'
      )}
      {renderData(row.code, validation && validation[row.id]?.code, 'left')}
      {renderData(
        row.courseName,
        validation && validation[row.id]?.name,
        'left'
      )}
      {renderData(
        row.situation,
        validation && validation[row.id]?.situation,
        'right'
      )}
      {renderData(
        row.grade,
        validation &&
        (validation[row.id]?.evaluationGrade0to100 ||
          validation[row.id]?.evaluationGrade0to10 ||
          validation[row.id]?.evaluationConcept),
        'right'
      )}
      {renderData(
        row.courseLoad >= 0 ? String(row.courseLoad) : 'N/A',
        validation && validation[row.id]?.courseLoad,
        'right'
      )}
      {row.teachers.length > 0 ? (
        <PaperTooltip
          key={row.id}
          placement="bottom-start"
          title={
            <>
              <Root className={classes.tooltipContainer}>
                <SupervisorAccountIcon
                  className={classes.teachersTooltipIcon}
                />
                <Typography
                  className={classes.teachersTypography}
                  variant="body2"
                >
                  Docentes
                </Typography>
              </Root>
              <Divider light style={{ marginBottom: 16 }} />
              <Root>{getTeachers(row.teachers)}</Root>
              <div style={{ paddingBottom: 8 }} />
            </>
          }
        >
          {getTeacherCell(row)}
        </PaperTooltip>
      ) : (
        getTeacherCell(row)
      )}
      {!isInfoMode && (
        <TableCell>
          <div className={classes.validationTableCell}>
            {row.error === 0 ? (
              <>
                <CheckCircle size={15} className={classes.successIcon} />
                <Typography
                  className={classes.validationSuccess}
                  variant="body1"
                >
                  OK
                </Typography>
              </>
            ) : (
              <>
                <AlertTriangle size={15} className={classes.errorIcon} />
                <Typography className={classes.validationError} variant="body1">
                  {getTableErrorMessage(row.error)}
                </Typography>
              </>
            )}
          </div>
        </TableCell>
      )}
    </TableRow>
  );

  const buildRow = (row: CurriculumData): React.ReactNode =>
    row.error !== 0 ? (
      <PaperTooltip
        key={row.id}
        placement="bottom-start"
        title={
          <>
            <Root className={classes.tooltipContainer}>
              <AlertTriangle size={15} className={classes.errorIcon} />
              <Typography
                className={classes.tooltipValidationError}
                variant="body1"
              >
                {getTooltipErrorMessage(row.error)}
              </Typography>
            </Root>
            <Divider light style={{ marginBottom: 16 }} />
            <Root>{getCurriculumErrors(row.id, row)}</Root>
            <div style={{ paddingBottom: 8 }} />
          </>
        }
      >
        {buildCells(row)}
      </PaperTooltip>
    ) : (
      buildCells(row)
    );

  return (
    <Root
      style={{
        width: '100%',
        paddingLeft: '30px',
        paddingRight: '30px'
      }}
    >
      <TableContainer>
        <Table>
          <EnhancedTableHead
            headCells={headCells}
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          {loading && <TableLoading size={headCells.length} />}
          {!loading && rows.length > 0 ? (
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: CurriculumData) => buildRow(row))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <></>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        className={classes.pagination}
        count={rows.length ? rows.length : 0}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        labelDisplayedRows={(obj: any) =>
          `${obj.from}-${obj.to} de ${obj.count}`
        }
        nextIconButtonProps={{
          style: {
            color: page === getNumPages() ? GRAY_COLOR : CURRICULUM_COLOR
          }
        }}
        backIconButtonProps={{
          style: {
            color: page === 0 ? GRAY_COLOR : CURRICULUM_COLOR
          }
        }}
        page={page}
        onPageChange={handleChangePage}
      />
    </Root>
  );
};

export default CurriculumTable;
