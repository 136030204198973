import { ExpandLess, ExpandMore } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Table,
  TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../../../../../setup/reducer/reducers';
import { DARK_GRAY_COLOR, DIPLOMA_COLOR, GRAY_COLOR } from "../../../../../theme";
import CustomizedTooltip from '../../../atoms/customized-tooltip';
import EmptyTable from '../../../molecules/empty-table';
import { getComparator, getRows, headCells, stableSort } from "../EditModelCertificateTableManager";
import { EditModelCertificateData, EnhancedTableProps, Order, RenderCellParams } from '../types';
import EditModelCertificateActions from './EditModelCertificateActions';


interface BrandsOpenState {
  [key: string]: boolean;
}

const PREFIX = 'EditModelCertificateTable';

const classes = {
  root: `${PREFIX}-root`,
  pagination: `${PREFIX}-pagination`,
  cellabel: `${PREFIX}-cellabel`,
  activeSortIcon: `${PREFIX}-activeSortIcon`,
  inactiveSortIcon: `${PREFIX}-inactiveSortIcon`,
  iconArrowBrands: `${PREFIX}-iconArrowBrands`,
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    paddingLeft: '30px',
    paddingRight: '30px',
  },

  [`& .${classes.pagination}`]: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
  },

  [`& .${classes.cellabel}`]: {
    color: DARK_GRAY_COLOR,
    fontFamily: theme.typography.body2.fontFamily,
  },
  [`& .${classes.iconArrowBrands}`]: {
    color: DIPLOMA_COLOR,

  },

  [`& .${classes.activeSortIcon}`]: {
    fill: theme.palette.primary.main,
    width: 24,
    height: 24,
  },

  [`& .${classes.inactiveSortIcon}`]: {
    width: 24,
    height: 24,
  },

}));

const mapState = (state: RootState) => ({
  search: state.editModelCertificateFilter.search,
  editModelsCertificate: state.editModelCertificatePage.editModelsCertificate,
  loadingEditModels: state.editModelCertificatePage.loadingEditModels
})

const connector = connect(mapState);

const TableLoading = () => (
  <TableBody>
    {
      [...Array(10)].map((_e, i) => (
        <TableRow key={i++}>
          {
            [...Array(headCells.length)].map((_f, j) => (
              <TableCell key={i++ + j++} colSpan={1}>
                <Skeleton style={{ height: 32 }} animation="wave" />
              </TableCell>
            ))
          }
        </TableRow>
      ))
    }
  </TableBody>
);

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const {
    classes, onRequestSort, order, orderBy,
  } = props;

  const createSortHandler = (property: keyof EditModelCertificateData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{
              width: headCell.size,
              whiteSpace: 'nowrap',
            }}
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id && order}
          >
            {
              headCell.sortable
                ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                    classes={{
                      icon: ((orderBy === headCell.id) ? classes.activeSortIcon : classes.inactiveSortIcon),
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <Typography variant="caption" style={headCell.styles}>
                      {headCell.label}
                    </Typography>
                  </TableSortLabel>
                )
                : (
                  <Typography variant="caption" style={headCell.styles}>
                    {headCell.label}
                  </Typography>
                )
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

type Props = ConnectedProps<typeof connector>;

const EditModelCertificateTable = (props: Props) => {
  const {
    search, editModelsCertificate, loadingEditModels
  } = props;

  const [rows, setRows] = React.useState<EditModelCertificateData[]>([]);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof EditModelCertificateData>('type');
  const [page, setPage] = React.useState(0);
  const [openShowBrands, setOpenShowBrands] = useState<BrandsOpenState>({});
  const rowsPerPage = 8;


  useEffect(() => {
    setRows(getRows(editModelsCertificate, search));
  }, [editModelsCertificate, search]);


  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof EditModelCertificateData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const renderActionsCell = (row: EditModelCertificateData) => (
    <TableCell align="center">
      <EditModelCertificateActions brands={row.brands} user={''} type={row.type} action={''} id={''} />
    </TableCell>
  );

  const handleShowBrandsClick = (id: string) => {
    setOpenShowBrands((prevState: any) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const renderBrandsCell = (row: string[], rowId: string) => (
    <TableCell align="center" style={{ padding: '0', width: '10%' }}>
      <ListItemButton onClick={() => handleShowBrandsClick(rowId)} sx={{ width: '80%' }}>
        <ListItemText primary={`${row.length} marcas selecionadas`} />
        {openShowBrands[rowId] ? <ExpandLess className={classes.iconArrowBrands} /> : <ExpandMore className={classes.iconArrowBrands} />}
      </ListItemButton>
      <Collapse in={openShowBrands[rowId]} timeout="auto" unmountOnExit >
        <List disablePadding>
          {row.map((item, index) => {
            let newIndex = index;
            return (
              <ListItem key={newIndex}>
                <ListItemText primary={item.toUpperCase()} />
              </ListItem>
            );
          })}
        </List>

      </Collapse>
    </TableCell >
  );

  const renderCell = ({
    label, cellSize = 25, textAlign = 'left',
  }: RenderCellParams) => {
    const customStyle = { width: cellSize };

    if (label) {
      if (label.length > 45) {
        return (
          <TableCell align={textAlign} style={customStyle}>
            <CustomizedTooltip title={label} placement="top">
              <Typography className={classes.cellabel} variant="body1">{`${(label).slice(0, 45)}...`}</Typography>
            </CustomizedTooltip>
          </TableCell>
        );
      }
      return (<TableCell align={textAlign} style={customStyle}><Typography className={classes.cellabel} variant="body1">{label}</Typography></TableCell>);
    }

    return <TableCell align={textAlign} style={customStyle}><Typography className={classes.cellabel} variant="body1">-</Typography></TableCell>;
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const getNumPages = () => Math.ceil((rows && rows.length) / rowsPerPage) - 1;

  const buildCells = (row: EditModelCertificateData): React.ReactNode => (
    <TableRow
      hover
      key={row.id}

    >
      {renderCell({
        label: row.type,
        cellSize: 10,
        splitPoint: 25,
      })}

      {renderBrandsCell(row.brands, row.id)}

      {renderCell({
        label: row.user,
        cellSize: 10,
        splitPoint: 25,
      })}

      {renderActionsCell(row)}
    </TableRow>
  );

  let tableContent;

  if (loadingEditModels) {
    tableContent = <TableLoading />;
  } else {
    if (rows.length > 0) {
      tableContent = (
        <TableBody>
          {stableSort(rows, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row: EditModelCertificateData) => buildCells(row))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 65 * emptyRows }}>
              <TableCell colSpan={headCells.length} />
            </TableRow>
          )}
        </TableBody>
      );
    } else {
      tableContent = (
        <TableBody>
          <TableRow style={{ height: 650 }}>
            <TableCell colSpan={headCells.length}>
              <EmptyTable emptyStateMessage="Nenhum resultado encontrado" orientationMessage="Tente informar dados diferentes para a busca!" />
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
  }

  return (
    <Root className={classes.root}>
      <TableContainer>
        <Table>
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          {tableContent}
        </Table>
      </TableContainer>
      {editModelsCertificate.length > 0 ? (
        <TablePagination
          component="div"
          className={classes.pagination}
          count={rows.length ? rows.length : 0}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={(obj: any) => (
            `${obj.from}-${obj.to} de ${obj.count}`
          )}
          nextIconButtonProps={{
            style: {
              color: page === getNumPages() ? GRAY_COLOR : DIPLOMA_COLOR,
            },
          }}
          backIconButtonProps={{
            style: {
              color: page === 0 ? GRAY_COLOR : DIPLOMA_COLOR,
            },
          }}
          page={page}
          onPageChange={handleChangePage}
        />
      ) : null}
    </Root>
  )
}

export default connector(EditModelCertificateTable);
