import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../setup/reducer/reducers';
import { DARK_CYAN_COLOR, DARK_GRAY_COLOR, DIPLOMA_COLOR, DIPLOMA_VALIDATION_ACTIVE_ACTION, GRAY_COLOR, INFO_COLOR, PINK_NEON_COLOR, PURPLE_VIOLET_COLOR, TOMATO_COLOR, CYAN_COLOR } from "../../../../../theme";
import CustomizedTooltip from '../../../atoms/customized-tooltip';
import EmptyTable from '../../../molecules/empty-table';
import { getComparator, headCells, stableSort } from "../CurriculumTableManager";
import { CurriculumData, EnhancedTableProps, Order, RenderCellParams } from "../types";
import CurriculumAction from './DigitalCurriculumAction';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import CurriculumStatus from '../../../../../domain/enum/curriculum/CurriculumStatus';
import XsdVersionBadge from '../../../atoms/xsd-version-badge';
import { setSyncCurriculums } from '../store/actions';
import { blue } from '@mui/material/colors';
import { getRows } from '../CurriculumFilterManager';
import RejectedDialogInfo from '../../../organisms/curriculum-rejected-modal';

const PREFIX = 'CurriculumTable';

const classes = {
  root: `${PREFIX}-root`,
  pagination: `${PREFIX}-pagination`,
  cellabel: `${PREFIX}-cellabel`,
  activeSortIcon: `${PREFIX}-activeSortIcon`,
  inactiveSortIcon: `${PREFIX}-inactiveSortIcon`,
  status: `${PREFIX}-status`,
  checkbox: `${PREFIX}-checkbox`,
  checkboxDisabled: `${PREFIX}-checkboxDisabled`,
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.pagination}`]: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
  },

  [`& .${classes.cellabel}`]: {
    color: DARK_GRAY_COLOR,
    fontFamily: theme.typography.body2.fontFamily,
  },
  [`& .${classes.status}`]: {
    fontFamily: theme.typography.body2.fontFamily,
    fontWeight: 800
  },

  [`& .${classes.activeSortIcon}`]: {
    fill: theme.palette.primary.main,
    width: 24,
    height: 24,
  },

  [`& .${classes.inactiveSortIcon}`]: {
    width: 24,
    height: 24,
  },
  [`& .${classes.checkbox}`]: {
    display: 'flex',
    alignItemns: 'center',
    width: '10px',
    color: blue,
  },
  [`& .${classes.checkboxDisabled}`]: {
    color: GRAY_COLOR
  },
}));

const mapState = (state: RootState) => ({
  curriculums: state.curriculumPage.curriculums,
  loadingCurriculums: state.curriculumPage.loadingCurriculums,
  filter: state.curriculumPage.filter,
})

const connector = connect(mapState);

const TableLoading = () => (
  <TableBody>
    {
      [...Array(10)].map((_e, i) => (
        <TableRow key={i++}>
          {
            [...Array(headCells.length)].map((_f, j) => (
              <TableCell key={i++ + j++} colSpan={1}>
                <Skeleton style={{ height: 32 }} animation="wave" />
              </TableCell>
            ))
          }
        </TableRow>
      ))
    }
  </TableBody>
);

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const {
    classes, onRequestSort, order, orderBy,
  } = props;

  const createSortHandler = (property: keyof CurriculumData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{
              width: headCell.size,
              whiteSpace: 'nowrap',
            }}
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id && order}
          >
            {
              headCell.sortable
                ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                    classes={{
                      icon: ((orderBy === headCell.id) ? classes.activeSortIcon : classes.inactiveSortIcon),
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <Typography variant="caption" style={headCell.styles}>
                      {headCell.label}
                    </Typography>
                  </TableSortLabel>
                )
                : (
                  <Typography variant="caption" style={headCell.styles}>
                    {headCell.label}
                  </Typography>
                )
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

type Props = ConnectedProps<typeof connector>;

const CurriculumTable = (props: Props) => {
  const {
    curriculums, loadingCurriculums, filter
  } = props;

  const [orderBy, setOrderBy] = React.useState<keyof CurriculumData>('updatedAt');
  const [order, setOrder] = React.useState<Order>('desc');
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState<CurriculumData[]>([]);
  const rowsPerPage = 8;
  const syncCurriculumList = useSelector((state: RootState) => state.curriculumPage.syncCurriculums);
  const dispatch = useDispatch();

  useEffect(() => {
    setRows(getRows(curriculums, filter));
  }, [curriculums, filter, filter.courses]);

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof CurriculumData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const renderActionsCell = (id: string, status: string, validationCode: string, issuerMecCode: number, curriculumCode: string) => (
    <TableCell align="center">
      <CurriculumAction
        id={id}
        status={status}
        validationCode={validationCode}
        issuerMecCode={issuerMecCode}
        curriculumCode={curriculumCode}
      />
    </TableCell>
  );

  const renderCell = ({
    label, splitPoint = 30, cellSize = 25, textAlign = 'left',
  }: RenderCellParams) => {
    const customStyle = splitPoint >= 15 ? { width: cellSize } : { width: cellSize, paddingLeft: '40px' };

    if (label) {
      if (label.length > 100) {
        return (
          <TableCell align={textAlign} style={customStyle}>
            <CustomizedTooltip title={label} placement="top">
              <Typography className={classes.cellabel} variant="body1">{`${(label).slice(0, 100)}...`}</Typography>
            </CustomizedTooltip>
          </TableCell>
        );
      }
      return (<TableCell align={textAlign} style={customStyle}><Typography className={classes.cellabel} variant="body1">{label}</Typography></TableCell>);
    }

    return <TableCell align={textAlign} style={customStyle}><Typography className={classes.cellabel} variant="body1">-</Typography></TableCell>;
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const getNumPages = () => Math.ceil((rows && rows?.length) / rowsPerPage) - 1;

  const getStatusStyle = (row: CurriculumData) => {
    switch (row.status) {
      case CurriculumStatus.AwaitingXmlSignature:
        return {
          value: row.status,
          color: DIPLOMA_VALIDATION_ACTIVE_ACTION,
          label: 'AGUARDANDO ASSINATURA DO XML',
        };
      case CurriculumStatus.Completed:
        return {
          value: row.status,
          color: DARK_CYAN_COLOR,
          label: 'CONCLUÍDO',
        };
      case CurriculumStatus.GeneratingCurriculumXml:
        return {
          value: row.status,
          color: PINK_NEON_COLOR,
          label: 'GERANDO XML DO CURRÍCULO DIGITAL',
        };
      case CurriculumStatus.GeneratingVisualRepresentation:
        return {
          value: row.status,
          color: PURPLE_VIOLET_COLOR,
          label: 'GERANDO REPRESENTAÇÃO VISUAL DO CURRÍCULO DIGITAL',
        };
      case CurriculumStatus.IssuerConfirmationPending:
        return {
          value: row.status,
          color: INFO_COLOR,
          label: 'PENDENTE DE EMISSÃO',
          transitoryStatus: true
        };
      case CurriculumStatus.Synchronizing:
        return {
          value: row.status,
          color: CYAN_COLOR,
          label: 'EM SINCRONIZAÇÃO',
          transitoryStatus: true
        };
      case CurriculumStatus.Rejected:
        return {
          value: row.status,
          color: TOMATO_COLOR,
          label: 'REPROVADO',
          transitoryStatus: true
        };
      case CurriculumStatus.RestartingFlow:
        return {
          value: row.status,
          color: DARK_GRAY_COLOR,
          label: 'REINICIANDO FLUXO',
          transitoryStatus: true
        };
      default:
        return {
          value: '',
          color: TOMATO_COLOR,
          label: 'INDEFINIDO',
        };
    }
  };

  const [openCurriculumrejectionModal, setOpenCurriculumrejectionModal] =
    useState(false);

  const [reasonType, setReasonType] =
    useState("");


  function rejectedCurriculumOnClick(rejectedType: string): void {
    setReasonType(rejectedType);
    setOpenCurriculumrejectionModal(true);
  };

  const handleClose = () => {
    setOpenCurriculumrejectionModal(false);
  };

  const buildCells = (row: CurriculumData): React.ReactNode => {
    const status = getStatusStyle(row)
    const isChecklistEnabled = row.status === CurriculumStatus.IssuerConfirmationPending && row.originSystem === 'SIAF';
    const isInputChecked = (id: string) => syncCurriculumList.some((curriculum) => curriculum.pk === id);

    function handleChange(event: any) {
      const { id, checked } = event.target
      if (checked) {
        const finderCurriculum = curriculums.find((curriculum) => curriculum.pk === id);
        if (finderCurriculum !== undefined) {
          dispatch(setSyncCurriculums([...syncCurriculumList, finderCurriculum]));
        }
      } else {
        const removeCurriculum = syncCurriculumList.filter((curriculum) => curriculum.pk !== id)
        dispatch(setSyncCurriculums(removeCurriculum));
      }
    }

    return (
      < TableRow
        hover
        key={row.id}
      >
        <TableCell >
          {isChecklistEnabled && (
            <Checkbox className={classes.checkbox} onChange={handleChange} id={row.id} checked={isInputChecked(row.id)} />
          )}
        </TableCell>

        <TableCell>
          <div>
            <Typography variant="body1">{row.courseName}</Typography>

            <Typography
              style={{
                color: status.color,
                textDecoration: row.status === CurriculumStatus.Rejected ? 'underline' : 'none',
                cursor: row.status === CurriculumStatus.Rejected ? 'pointer' : 'default',
                fontSize: '12px'
              }}
              className={classes.status}
              variant="body1"
              onClick={row.status === CurriculumStatus.Rejected ? () => { rejectedCurriculumOnClick(row.reasonType) } : undefined}>
              {status.label}
            </Typography>
          </div>
        </TableCell>

        {
          renderCell({
            label: row.originSystem,
            cellSize: 8,
            splitPoint: 25,
          })
        }

        {
          renderCell({
            label: row.campusEmecCode ? `${row.campusEmecCode}` : 'N/A',
            cellSize: 8,
            splitPoint: 25,
          })
        }
        {
          renderCell({
            label: row.campusName ? `${row.campusName}` : 'N/A',
            cellSize: 10,
            splitPoint: 25,
          })
        }
        <TableCell>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1"
            >{row.curriculumDescription}</Typography>
            <XsdVersionBadge
              xsdVersion={`v${row.xsdVersion}`} />
          </div>
        </TableCell>

        {
          renderCell({
            label: row.scheduleCode,
            cellSize: 10,
            splitPoint: 25,
          })
        }
        {
          renderCell({
            label: row.updatedAt ? moment(row.updatedAt).format('DD/MM/YYYY') : '',
            cellSize: 10,
            splitPoint: 25,
            textAlign: 'left',
          })
        }
        {renderActionsCell(row.id, row.status, row.validationCode, row.issuerMecCode, row.curriculumCode)}

      </TableRow >
    );
  }

  let tableContent;

  if (loadingCurriculums) {
    tableContent = <TableLoading />;
  } else {
    if (rows.length > 0) {
      tableContent = (
        <TableBody>
          {stableSort(rows, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row: CurriculumData) => buildCells(row))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 65 * emptyRows }}>
              <TableCell colSpan={headCells.length} />
            </TableRow>
          )}
          <RejectedDialogInfo
            selectedValue={reasonType}
            open={openCurriculumrejectionModal}
            onClose={handleClose}
          />
        </TableBody>
      );
    } else {
      tableContent = (
        <TableBody>
          <TableRow style={{ height: 650 }}>
            <TableCell colSpan={headCells.length}>
              <EmptyTable emptyStateMessage="Nenhum resultado encontrado" orientationMessage="Tente informar dados diferentes para a busca!" />
            </TableCell>
          </TableRow>

        </TableBody>
      );
    }
  }

  return (
    <Root className={classes.root}>
      <TableContainer>
        <Table>
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          {tableContent}
        </Table>
      </TableContainer>

      {curriculums.length > 0 ? (
        <TablePagination
          component="div"
          className={classes.pagination}
          count={rows.length ? rows.length : 0}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={(obj: any) => (
            `${obj.from}-${obj.to} de ${obj.count}`
          )}
          nextIconButtonProps={{
            style: {
              color: page === getNumPages() ? GRAY_COLOR : DIPLOMA_COLOR,
            },
          }}
          backIconButtonProps={{
            style: {
              color: page === 0 ? GRAY_COLOR : DIPLOMA_COLOR,
            },
          }}
          page={page}
          onPageChange={handleChangePage}
        />
      ) : null}
    </Root>
  )
}

export default connector(CurriculumTable);

