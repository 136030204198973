import DouBatchType from '../../../../domain/enum/dou/DouBatchType';
import { DiplomaBatch } from '../../../../domain/model/diploma/DiplomaBatch';
import Dou from '../../../../domain/model/dou/Dou';

export default function BatchPaperManager(batch: Dou) {
  const sortBySequentialNumber = (
    currentRegisterBook: DiplomaBatch,
    nextRegisterBook: DiplomaBatch
  ) => currentRegisterBook.sequential - nextRegisterBook.sequential;

  const getSortedRegistersNumbers = (registerBookNumber: string) => {
    const filteredDiplomas = batch?.diplomas?.filter(
      (registerBook) => registerBook.registerBook === registerBookNumber
    );
    const sortedRegistersBooks = filteredDiplomas?.sort(sortBySequentialNumber);
    return sortedRegistersBooks;
  };

  const getRegistersNumbersRange = (registerBookNumber: string): string[] => {
    let registersRange = [];

    const sortedRegistersBook = getSortedRegistersNumbers(registerBookNumber);

    if (sortedRegistersBook) {
      let currentDiploma = [sortedRegistersBook[0]];
      for (let i = 1; i < sortedRegistersBook.length; i++) {
        if (
          sortedRegistersBook[i].sequential ===
          sortedRegistersBook[i - 1].sequential + 1
        ) {
          currentDiploma.push(sortedRegistersBook[i]);
        } else {
          registersRange.push(currentDiploma);
          currentDiploma = [sortedRegistersBook[i]];
        }
      }

      registersRange.push(currentDiploma);
    }

    let diplomaRegisterNumber: string[] = [];

    registersRange.forEach((diplomas) => {
      if (diplomas.length === 1) {
        diplomaRegisterNumber.push(
          `Livro ${diplomas[0].registerBook} - Registro ${diplomas[0].registerNumber}`
        );
      } else {
        diplomaRegisterNumber.push(
          `Livro ${diplomas[0].registerBook} - Registros ${
            diplomas[0].registerNumber
          } a ${diplomas[diplomas.length - 1].registerNumber}`
        );
      }
    });

    return diplomaRegisterNumber.map((diplomaInBatch) => diplomaInBatch);
  };

  const getCancelledDiplomaList = (registerBookNumber: string): string[] => {
    const sortedDiplomaRegisterNumbers =
      getSortedRegistersNumbers(registerBookNumber);

    if (!sortedDiplomaRegisterNumbers) {
      return ['N/A'];
    }

    let diplomaRegisterNumber: string[] = [];
    sortedDiplomaRegisterNumbers.forEach((diplomas) => {
      diplomaRegisterNumber.push(
        `Livro ${diplomas.registerBook} - Registro ${diplomas.registerNumber}`
      );
    });

    return diplomaRegisterNumber.map((diplomaInBatch) => diplomaInBatch);
  };

  const getDiplomasList = (): string[] => {
    const registersBooks = batch?.diplomas?.map(
      (registerBook) => registerBook.registerBook
    );

    if (registersBooks?.length !== 0) {
      const uniqueRegistersBooks = [...new Set(registersBooks)].sort();

      if (batch?.type === DouBatchType.Cancellation) {
        return uniqueRegistersBooks
          ?.map(getCancelledDiplomaList)
          .reduce((accumulator, current) => accumulator.concat(current));
      }

      return uniqueRegistersBooks
        ?.map(getRegistersNumbersRange)
        .reduce((accumulator, current) => accumulator.concat(current));
    } else {
      return registersBooks;
    }
  };

  return {
    getDiplomasList
  };
}
