import PermissionEmployeeDto from '../../../../../domain/interface/dto/PermissionEmployeeDto';
import UpsertPermissionEmployeeRequest from '../../../../../domain/interface/request/UpsertPermissionEmployeeRequest';
import PermissionEmployee from '../../../../../domain/model/employee/PermissionEmployee';
import EmployeeService from '../../../../../services/EmployeeService';
import { AppThunk } from '../../../../../setup/reducer';
import {
  errorNotificationMessages,
  notificationIcons
} from '../../../../utils/constants';
import { showNotification } from '../../notification/actions';
import { UsersParamsDataProps } from '../../users-management/types';
import {
  SET_LOADING_UPSERT,
  SET_LOADING_USERS,
  SET_PERMISSIONS_USER,
  SET_USERS_SECURITY,
  UsersSecurityActions
} from './types';

const internalSetLoadingUsers = (
  loadingUsers: boolean
): UsersSecurityActions => ({
  type: SET_LOADING_USERS,
  payload: {
    loadingUsers
  }
});

const internalSetLoadingUpsert = (
  loadingUpsert: boolean
): UsersSecurityActions => ({
  type: SET_LOADING_UPSERT,
  payload: {
    loadingUpsert
  }
});

const internalGetUsersSecurity = (
  users: UsersParamsDataProps
): UsersSecurityActions => ({
  type: SET_USERS_SECURITY,
  payload: {
    users
  }
});

const internalGetPermissionUser = (
  permissions: PermissionEmployeeDto[]
): UsersSecurityActions => ({
  type: SET_PERMISSIONS_USER,
  payload: {
    permissions
  }
});

export const getUsersSecurity =
  (user: UsersParamsDataProps): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetLoadingUsers(true));
    let permissions: PermissionEmployee[];

    try {
      const result = await EmployeeService.getPermissionEmployee({
        id: user.id
      });

      permissions = result.data.permissions;

      dispatch(internalGetPermissionUser(permissions));
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
    }

    dispatch(internalGetUsersSecurity(user));
    dispatch(internalSetLoadingUsers(false));
  };

export const upsertPermissionEmployee =
  (permissions: UpsertPermissionEmployeeRequest[]): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(internalSetLoadingUpsert(true));
      await EmployeeService.upsertPermissionEmployee(permissions);
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
    } finally {
      dispatch(internalSetLoadingUpsert(false));
    }
  };
