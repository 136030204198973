import { DropzoneArea } from 'react-mui-dropzone';
import { DropEvent } from 'react-dropzone';
import { Grid, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { File } from 'react-feather';
import { SUCCESS_UPLOAD_COLOR } from '../../../../theme';
import { formatBytes } from '../../../utils/functions';

const useStyles = makeStyles((theme: Theme) => createStyles({
  gridCard: {
    position: 'relative',
    borderRadius: '10px',
    background: '#fafafa',
    borderStyle: 'dashed',
    borderColor: '#e8e8e8',
    border: '2px',
    height: '250px'
  },
  gridText: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    bottom: '35%'
  },
  gridIcon: {
    fontSize: '50px',
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    bottom: '50%'
  },
  dropZoneBox: {
    background: '#fafafa',
    borderColor: '#e8e8e8',
    borderSize: '10px',
    borderRadius: '10px',
    borderWidth: '2px',
    height: '200px',
    paddingTop: '14%'
  },
  dropZoneBoxP: {
    position: 'absolute',
    bottom: '25%',
    left: '0',
    width: '100%',
    textAlign: 'center'
  },
  dropZoneHide: {
    display: 'none'
  }
}));

const dropRejectMessage = (rejectedFile: File, acceptedFiles: string[], maxFileSize: number) => {
  let message: string = "Arquivo inválido.";

  if (rejectedFile.type !== acceptedFiles[0]) {
    message += 'Apenas o formato "' + acceptedFiles[0] + '" é permitido.';
  }
  if (rejectedFile.size > maxFileSize) {
    message += 'O tamanho do arquivo é maior que o tamanho máximo permitido de "' + formatBytes(maxFileSize) + '"';
  }

  return message;
}

const getDropAreaClasses = (props: DropzoneUploadProps, styles: any) => ({
  root: props.disabled ? styles.dropZoneHide : ''
});

export type DropzoneUploadProps = {
  onDrop?: (files: File[], event: DropEvent) => void;
  onDelete?: (file: File) => void;
  dropzoneParagraphClass?: string;
  dropzoneClass?: string;
  disabled?: boolean;
  textPreview?: string;
};

const DropZoneUpload = (props: DropzoneUploadProps) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.gridCard}>
      {props.disabled && <>
        <File className={classes.gridIcon} color={SUCCESS_UPLOAD_COLOR} size={'40'}></File>
        <Typography variant="body2" className={classes.gridText} color={'primary'}>{props.textPreview}</Typography>
      </>}
      <DropzoneArea
        onDrop={props.onDrop}
        onDelete={props.onDelete}
        dropzoneParagraphClass={props.dropzoneParagraphClass}
        dropzoneClass={props.dropzoneClass}
        showPreviewsInDropzone={false}
        showPreviews={false}
        showFileNamesInPreview={false}
        showAlerts={true}
        classes={getDropAreaClasses(props, classes)}
        getDropRejectMessage={dropRejectMessage}
        useChipsForPreview
        filesLimit={1}
        maxFileSize={4194304}
        dropzoneText="Arraste e Solte ou Clique aqui para inserir o arquivo"
        acceptedFiles={['application/pdf']} />
    </Grid>
  );
}

export default DropZoneUpload;
