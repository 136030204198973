import ReplayIcon from '@mui/icons-material/Replay';
import { Grid, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import {
  ConnectedProps,
  connect,
  useDispatch,
  useSelector
} from 'react-redux';
import SelectAutocomplete from '../../../molecules/select-autocomplete';
import { RootState } from '../../../../../setup/reducer/reducers';
import { CurriculumOriginSystemList, CurriculumStatusList, mapCoursesToOptions } from '../CurriculumFilterManager';
import { SelectedCourse, SelectedOriginSystem, SelectedStatus } from '../types';
import * as Actions from '../store/actions';
import Institution from '../../../../../domain/model/institution/Institution';
import SelectAutocompleteMultipleCheckboxes from '../../../molecules/select-autocomplete-multiple-checkboxes';
import { CurriculumsFilter } from '../store/types';
import ChangeCurriculumRequest from '../../../../../domain/interface/request/ChangeCurriculumRequest';
import ChangeType from '../../../../../domain/enum/curriculum/ChangeType';
import CurriculumService from '../../../../../services/CurriculumService';
import { showNotification, showSuccessNotification } from '../../notification/actions';
import { errorNotificationMessages, notificationIcons, successNotificationMessages } from '../../../../utils/constants';
import { AxiosResponse } from 'axios';
import BackdropLoading from '../../../molecules/backdrop-loading';

const PREFIX = 'CurriculumFilter';

const classes = {
  root: `${PREFIX}-root`,
  buttonAdd: `${PREFIX}-buttonAdd`
};

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {
    padding: '30px',
  },
  [`& .${classes.buttonAdd}`]: {
    height: '2.5rem',
    marginTop: '2rem'
  }
}));

const mapState = (state: RootState) => ({
  filter: state.curriculumPage.filter,
  curriculums: state.curriculumPage.curriculums,
  selectedInstitution: state.header.institution as Institution
});

const mapDispatch = {
  setCurriculumsFilter: (curriculumsFilter: CurriculumsFilter) => Actions.setCurriculumsFilter(curriculumsFilter),
};

const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

function CurriculumFilter(props: Props): JSX.Element {
  const { filter, selectedInstitution, curriculums, setCurriculumsFilter } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Actions.setLoadingCurriculums(true));
    dispatch(Actions.setCurriculums(selectedInstitution));
  }, [dispatch, selectedInstitution]);

  const syncCurriculumList = useSelector((state: RootState) => state.curriculumPage.syncCurriculums);
  const originSystemData = CurriculumOriginSystemList;

  const [isLoading, setLoading] = React.useState(false);

  async function handleSubmit(): Promise<void> {
    setLoading(true);
    try {
      let promises: Array<Promise<AxiosResponse<any>>> = [];
      syncCurriculumList.forEach(async (curriculum) => {
        const curriculumId = curriculum.pk.split("#")[1];

        const changeRequest: ChangeCurriculumRequest = {
          type: ChangeType.IssuerInstitutionSynchronizesCurriculum,
        }
        promises.push(CurriculumService.addChangeCurriculum(changeRequest, curriculumId));
      });

      await Promise.all(promises);

      dispatch(Actions.setSyncCurriculums([]));

      dispatch(
        showSuccessNotification(
          successNotificationMessages.successSync,
          notificationIcons.success
        )
      );
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.failedToSyncCurriculum,
          notificationIcons.error
        )
      );
    } finally {
      setLoading(false);
      dispatch(Actions.setLoadingCurriculums(true));
      dispatch(Actions.setCurriculums(selectedInstitution));
    }
  }


  return (
    <StyledGrid className={classes.root} container spacing={3}>
      <Grid item xs={3}>
        <SelectAutocomplete
          defaultValue={filter.originSystem}
          compareName
          listItems={originSystemData}
          label="Sistema de origem"
          placeholder="Nenhum tipo selecionado"
          setItem={(value: SelectedOriginSystem) => {
            setCurriculumsFilter({
              ...filter,
              originSystem: value
            })
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectAutocompleteMultipleCheckboxes
          listItems={mapCoursesToOptions(curriculums)}
          label="Curso"
          placeholder="Nenhum tipo selecionado"
          setItem={(values: SelectedCourse[]) => {
            setCurriculumsFilter({
              ...filter,
              courses: values
            })
          }}
          selectedValues={filter.courses}
          displaySelectAll
        />
      </Grid>
      <Grid item xs={3}>
        <SelectAutocompleteMultipleCheckboxes
          listItems={CurriculumStatusList}
          label="Status do Currículo"
          placeholder="Nenhum tipo selecionado"
          setItem={(values: SelectedStatus[]) => {
            setCurriculumsFilter({
              ...filter,
              statuses: values
            })
          }}
          selectedValues={filter.statuses}
          displaySelectAll
        />
      </Grid>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button
            variant="contained"
            size="large"
            startIcon={<ReplayIcon />}
            className={classes.buttonAdd}
            onClick={handleSubmit}
            disabled={syncCurriculumList.length === 0}
          >
            Recarregar Dados
          </Button>
        </Grid>
      </Grid>
      {isLoading && (
        <BackdropLoading
          size={50}
          description="Sincronizando Currículos..."
          open={isLoading}
          classes={classes}
        />)}
    </StyledGrid>
  );
}

export default connector(CurriculumFilter);
