import InstitutionsSigners from '../../../../../domain/model/institution/InstitutionsSigners';

export interface InstitutionManagementState {
  search: string;
  institutionsSigners: InstitutionsSigners[];
  loadingInstitutionsSigners: boolean;
  showNameInstitutionDropdow: boolean;
  page: number;
}

export const INITIAL_STATE: InstitutionManagementState = {
  search: '',
  institutionsSigners: [],
  loadingInstitutionsSigners: false,
  showNameInstitutionDropdow: true,
  page: 0
};

export const SET_SEARCH = 'app/institution-management/SET_SEARCH';
export const SET_LOADING_INSTITUTIONS_SIGNERS =
  'app/institution-management/SET_LOADING_INSTITUTION_SIGNERS';
export const SET_INSTITUTIONS_SIGNERS =
  'app/institution-management/SET_INSTITUTION_SIGNERS';
export const SET_SHOW_NAME_INSTITUTION_DROPDOWN =
  'app/institution-management/SET_SHOW_NAME_INSTITUTION_DROPDOWN';
export const SET_PAGE_INSTITUTION =
  'app/institution-management/SET_PAGE_INSTITUTION';

interface SetSearchAction {
  type: typeof SET_SEARCH;
  payload: {
    search: string;
  };
}

interface SetLoadingInstitutionsSignersAction {
  type: typeof SET_LOADING_INSTITUTIONS_SIGNERS;
  payload: {
    loadingInstitutionsSigners: boolean;
  };
}

interface SetInstitutionsSignersAction {
  type: typeof SET_INSTITUTIONS_SIGNERS;
  payload: {
    institutionsSigners: Array<InstitutionsSigners>;
  };
}

interface SetshowNameInstitutionDropdowAction {
  type: typeof SET_SHOW_NAME_INSTITUTION_DROPDOWN;
  payload: {
    showNameInstitutionDropdow: boolean;
  };
}

interface SetSetPageInstitution {
  type: typeof SET_PAGE_INSTITUTION;
  payload: {
    page: number;
  };
}

export type InstitutionManagementActions =
  | SetSearchAction
  | SetLoadingInstitutionsSignersAction
  | SetInstitutionsSignersAction
  | SetshowNameInstitutionDropdowAction
  | SetSetPageInstitution;
