import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import SchoolIcon from '@mui/icons-material/SchoolOutlined';
import Address from '../../../../domain/model/Address';
import RegulatoryAct from '../../../../domain/model/RegulatoryAct';
import CardTitle from '../../molecules/card-title';
import LabeledTooltip from '../../molecules/labeled-tooltip';
import { CoursePaperProps } from './types';

const PREFIX = 'index';

const classes = {
  icon: `${PREFIX}-icon`
};

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main,
  }
}));

export default function CoursePaper({ course, validation, isLoading, originSystem, processNumber }: CoursePaperProps) {

  return (
    <StyledPaper>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <CardTitle icon={<SchoolIcon className={classes.icon} />}>Curso</CardTitle>
        </Grid>
        <Grid item xs={8}>
          <LabeledTooltip label="Nome" error={validation?.name} errorMessage={validation?.name} isLoading={isLoading} data-testid="diploma-course-name"
            originSystem={originSystem} processNumber={processNumber}>
            {course?.name || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip label="Polo" error={validation?.campus} errorMessage={validation?.campus ? 'Formato inválido' : undefined} isLoading={isLoading} data-testid="diploma-course-campus"
            originSystem={originSystem} processNumber={processNumber}>
            {course?.campus?.name || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip label="Código do Curso EMEC" error={validation?.emecCode} errorMessage={validation?.emecCode} isLoading={isLoading} data-testid="diploma-course-emec-code"
            originSystem={originSystem} processNumber={processNumber}>
            {course?.emecCode ? course.emecCode.toString() : 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip label="Modalidade" error={validation?.modality} errorMessage={validation?.modality} isLoading={isLoading} data-testid="diploma-course-modality"
            originSystem={originSystem} processNumber={processNumber}>
            {(course && course.modality) ? course.modality : 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip label="Grau Conferido" error={validation?.conferredDegree} errorMessage={validation?.conferredDegree} isLoading={isLoading} data-testid="diploma-course-conferred-degree"
            originSystem={originSystem} processNumber={processNumber}>
            {(course && course.conferredDegree) ? course.conferredDegree : 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={8}>
          <LabeledTooltip label="Título Conferido" error={validation?.conferredTitle} errorMessage={validation?.conferredTitle} isLoading={isLoading} data-testid="diploma-course-conferred-title"
            originSystem={originSystem} processNumber={processNumber}>
            {(course && course.conferredTitle) ? course.conferredTitle : 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={4}>
          <LabeledTooltip label="Nome da Habilitação" error={validation?.qualificationName} errorMessage={validation?.qualificationName} isLoading={isLoading} data-testid="diploma-course-qualification-name"
            originSystem={originSystem} processNumber={processNumber}>
            {(course && course.qualificationName) ? course.qualificationName : 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={12}>
          <LabeledTooltip label="Endereço" error={validation?.address} errorMessage={validation?.address ? 'Endereço inválido' : undefined} isLoading={isLoading} data-testid="diploma-course-address"
            originSystem={originSystem} processNumber={processNumber}>
            {Address.stringify(course?.address) || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={12}>
          <LabeledTooltip label="Autorização" error={validation?.authorization} errorMessage={validation?.authorization ? 'Autorização inválida' : undefined} isLoading={isLoading} data-testid="diploma-course-authorization"
            originSystem={originSystem} processNumber={processNumber}>
            {RegulatoryAct.stringify(course?.authorization) || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={12}>
          <LabeledTooltip label="Reconhecimento" error={validation?.recognition} errorMessage={validation?.recognition ? 'Reconhecimento inválido' : undefined} isLoading={isLoading} data-testid="diploma-course-recognition"
            originSystem={originSystem} processNumber={processNumber}>
            {RegulatoryAct.stringify(course?.recognition) || 'N/A'}
          </LabeledTooltip>
        </Grid>
        <Grid item xs={12}>
          <LabeledTooltip label="Renovação de Reconhecimento" error={validation?.recognitionRenewal} errorMessage={validation?.recognitionRenewal ? 'Renovação de Reconhecimento inválido' : undefined} isLoading={isLoading} data-testid="diploma-course-recognition-renewal"
            originSystem={originSystem} processNumber={processNumber}>
            {RegulatoryAct.stringify(course?.recognitionRenewal) || 'N/A'}
          </LabeledTooltip>
        </Grid>
      </Grid>
    </StyledPaper>
  );
}
