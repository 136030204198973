import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Link } from '@mui/material';
import moment from 'moment';
import {
  Info,
} from 'react-feather';
import { useSelector } from 'react-redux';
import CardTitle from '../../molecules/card-title';
import { CnpjMask } from '../../../utils/stringMasks';
import { RootState } from '../../../../setup/reducer/reducers';
import GoBackPaper from '../../molecules/go-back-paper';
import LabeledText from '../../molecules/labeled-text';
import BatchPaperManager from './BatchPaperManager';

const PREFIX = 'index';

const classes = {
  icon: `${PREFIX}-icon`
};

const StyledGoBackPaper = styled(GoBackPaper)((
  {
    theme
  }
) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main,
  }
}));

export type BatchPaperProps = {
  onClickBack?: () => void,
};

export default function BatchPaper({ onClickBack }: BatchPaperProps): JSX.Element {
  const institution = useSelector((state: RootState) => state.header.institution);
  const batch = useSelector((state: RootState) => state.douPage.batch);



  const formattedDate = (date: string) => moment(date).format('DD/MM/YYYY');

  const renderBatchOpeningPeriod = () => {
    if (batch?.createdAt) {
      const closeBatchDate = (batch?.closedAt && formattedDate(batch.closedAt)) || 'o momento';
      return `${formattedDate(batch.createdAt)} até ${closeBatchDate}`;
    }
    return 'N/A';
  };

  const renderAssociatedDiplomas = () => {
    const associatedDiplomas = batch && BatchPaperManager(batch).getDiplomasList();

    if (associatedDiplomas?.length !== 0) {
      return associatedDiplomas?.map((associatedDiplomaText) => (
        <Grid key={associatedDiplomaText} item xs={12}>
          {associatedDiplomaText}
        </Grid>
      ));
    } else {
      return undefined
    }
  };

  const getPublicationDate = () => {
    if (batch?.status === 'Fechado') {
      return batch?.publicationDate ? moment(batch.publicationDate).format('DD/MM/YYYY') : 'N/A';
    }
    return '';
  };

  const getDouNumber = () => {
    if (batch?.status === 'Fechado') {
      return batch?.douNumber ? batch?.douNumber : 'N/A'
    }
    return '';
  }

  return (
    <StyledGoBackPaper onClickBack={onClickBack}>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <CardTitle icon={<Info className={classes.icon} />}>Informações</CardTitle>
        </Grid>
        <Grid item xs={4}>
          <LabeledText label="Status Lote">
            {batch?.status || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={4}>
          <LabeledText label="Número do Lote">
            {batch?.batchNumber || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={4}>
          <LabeledText label="Tipo do Lote">
            {batch?.type || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={4}>
          <LabeledText label="Data de Publicação">
            {getPublicationDate()}
          </LabeledText>
        </Grid>
        <Grid item xs={4}>
          <LabeledText label="IES">
            {institution?.name || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={4}>
          <LabeledText label="Mantenedora">

            {institution?.sponsor?.corporateName && (
              <Grid item xs={12}>
                {institution?.sponsor?.corporateName}
              </Grid>
            )}

            {institution?.sponsor?.cnpj && (
              <Grid item xs={12}>
                {`CNPJ ${CnpjMask.apply(institution?.sponsor?.cnpj)}`}
              </Grid>
            )}

            {!(institution?.sponsor?.corporateName || institution?.sponsor?.cnpj) && 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={4}>
          <LabeledText label="Número do DOU">
            {getDouNumber()}
          </LabeledText>
        </Grid>
        <Grid item xs={4}>
          <LabeledText label="Sítio Eletrônico para Consulta de Diplomas">
            {institution?.validationUrl
              ? (
                <Link href={institution.validationUrl} target="_blank" rel="noopener">
                  {institution.validationUrl}
                </Link>
              )
              : 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={4}>
          <LabeledText label="Número de Diplomas Registrados">
            {batch?.diplomas?.length || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={4}>
          <LabeledText label="Período de Abertura do Lote">
            {renderBatchOpeningPeriod()}
          </LabeledText>
        </Grid>
        <Grid item xs={12}>
          <LabeledText label="Diplomas Associados ao Lote">
            {renderAssociatedDiplomas() || 'N/A'}
          </LabeledText>
        </Grid>
      </Grid>
    </StyledGoBackPaper>
  );
}
