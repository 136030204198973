import Curriculum from '../../../../../domain/model/curriculum/Curriculum';

export const SET_CURRICULUM = 'app/curriculum-validate/SET_CURRICULUM';
export const TOGGLE_IS_LOADING_CURRICULUM =
  'app/curriculum-validate/IS_CURRICULUM';

export interface CurriculumValidatePage {
  loadingCurriculum: boolean;
  curriculum?: Curriculum;
  isLoadingCurriculum: boolean;
  id: string;
  validation?: any;
}

export const INITIAL_STATE: CurriculumValidatePage = {
  loadingCurriculum: false,
  isLoadingCurriculum: false,
  curriculum: undefined,
  validation: undefined,
  id: ''
};

export const SET_LOADING_CURRICULUM =
  'app/curriculum-page/SET_LOADING_CURRICULUM';

interface SetCurriculumInfoAction {
  type: typeof SET_CURRICULUM;
  payload: {
    curriculum: Curriculum;
    validation?: any;
  };
}

interface SetLoadingCurriculumAction {
  type: typeof SET_LOADING_CURRICULUM;
  payload: {
    loadingCurriculum: boolean;
  };
}

interface ToggleIsLoadingCurriculumAction {
  type: typeof TOGGLE_IS_LOADING_CURRICULUM;
}

export type CurriculumValidateActions =
  | SetCurriculumInfoAction
  | SetLoadingCurriculumAction
  | ToggleIsLoadingCurriculumAction;
