import RestartDiplomaFlowReason from '../../../../domain/enum/diploma/RestartDiplomaFlow';
import { RestartDiplomaFlowType } from './type';

export default class RestartDiplomaFlowManager {
  static RestartDiplomaFlow: Array<RestartDiplomaFlowType> = [
    {
      name: RestartDiplomaFlowReason.CourseData
    },
    {
      name: RestartDiplomaFlowReason.IESData
    },
    {
      name: RestartDiplomaFlowReason.PersonalData
    },
    {
      name: RestartDiplomaFlowReason.SubscriberData
    }
  ];
}
