import { AxiosResponse } from 'axios';
import getAxiosInstance from '../apis/axiosInstance';
import GetEmployeeRequest from '../domain/interface/request/GetEmployeeRequest';
import InsertEmployeeRequest from '../domain/interface/request/InsertEmployeeRequest';
import UpdateEmployeeRequest from '../domain/interface/request/UpdateEmployeeRequest';
import UpsertPermissionEmployeeRequest from '../domain/interface/request/UpsertPermissionEmployeeRequest';
import BaseResponse from '../domain/interface/response/BaseResponse';
import GetPermissionEmployeeResponse from '../domain/interface/response/GetPermissionEmployeeResponse';
import Employee from '../domain/model/employee/Employee';

const axiosInstance = getAxiosInstance(
  process.env.REACT_APP_DIPLOMA_EMPLOYEE_API || ''
);

class EmployeeService {
  async listEmployees(
    institutionMecCode?: number,
    canSignDocuments?: boolean
  ): Promise<AxiosResponse<Employee[]>> {
    return axiosInstance.get('employee', {
      params: {
        institutionMecCode,
        canSignDocuments
      }
    });
  }

  async updateEmployee(
    employee: UpdateEmployeeRequest
  ): Promise<AxiosResponse<Employee>> {
    return axiosInstance.put('employee/update', employee);
  }

  async insertEmployee(
    employee: InsertEmployeeRequest
  ): Promise<AxiosResponse<Employee>> {
    return axiosInstance.post('employee', employee);
  }

  async getPermissionEmployee(
    request: GetEmployeeRequest
  ): Promise<AxiosResponse<GetPermissionEmployeeResponse>> {
    return axiosInstance.get(`permission/${request.id}`);
  }

  async upsertPermissionEmployee(
    permissions: UpsertPermissionEmployeeRequest[]
  ): Promise<AxiosResponse<BaseResponse>> {
    return axiosInstance.put('upsertPermissions', permissions);
  }
}

export default new EmployeeService();
