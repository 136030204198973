import Brands from '../../../../../domain/enum/Brands';
import CertificateSettingsRequest from '../../../../../domain/interface/request/CertificateSettingsRequest';
import EditModelCertificate from '../../../../../domain/model/certificate/EditModelCertificate';
import DocumentPdfService from '../../../../../services/DocumentPdfService';
import DocumentService from '../../../../../services/DocumentService';
import { AppThunk } from '../../../../../setup/reducer';
import {
  errorNotificationMessages,
  notificationIcons
} from '../../../../utils/constants';
import { showNotification } from '../../notification/actions';

import {
  CertificateSettingsModelActions,
  SET_CERTIFICATE_SETTINGS,
  SET_CERTIFICATE_TYPES,
  SET_LIST_BRANDS,
  SET_LOADING_BRANDS,
  SET_LOADING_CERTIFICATE_TYPE
} from './types';

const internalSetCertificateTypes = (
  certificateTypes: string[]
): CertificateSettingsModelActions => ({
  type: SET_CERTIFICATE_TYPES,
  payload: {
    certificateTypes
  }
});

const internalSetListBrands = (
  brands: string[]
): CertificateSettingsModelActions => ({
  type: SET_LIST_BRANDS,
  payload: {
    brands
  }
});

const internalSetCertificateSettings = (
  certificateSetting: EditModelCertificate
): CertificateSettingsModelActions => ({
  type: SET_CERTIFICATE_SETTINGS,
  payload: {
    certificateSetting
  }
});

const internalSetLoadingCertificateType = (
  loadingCertificateType: boolean
): CertificateSettingsModelActions => ({
  type: SET_LOADING_CERTIFICATE_TYPE,
  payload: {
    loadingCertificateType
  }
});

const internalSetLoadingBrands = (
  loadingBrands: boolean
): CertificateSettingsModelActions => ({
  type: SET_LOADING_BRANDS,
  payload: {
    loadingBrands
  }
});

export const setCertificateTypes = (): AppThunk => async (dispatch) => {
  try {
    dispatch(internalSetLoadingCertificateType(true));
    const result = await DocumentPdfService.getCertificateTyppeAvailable();
    dispatch(internalSetCertificateTypes(result.data.certificateTypes));
  } catch (e) {
    dispatch(
      showNotification(
        errorNotificationMessages.defaultError,
        notificationIcons.error
      )
    );
  } finally {
    dispatch(internalSetLoadingCertificateType(false));
  }
};

export const setListBrands = (): AppThunk => async (dispatch) => {
  try {
    dispatch(internalSetLoadingBrands(true));
    await DocumentPdfService.getListBrands();
    const brandsEnum = Object.values(Brands);
    dispatch(internalSetListBrands(brandsEnum));
  } catch (e) {
    dispatch(
      showNotification(
        errorNotificationMessages.defaultError,
        notificationIcons.error
      )
    );
  } finally {
    dispatch(internalSetLoadingBrands(false));
  }
};

export const setEditModelCertificate =
  (type: string, editModelsCertificates: EditModelCertificate[]): AppThunk =>
  async (dispatch) => {
    try {
      const certificateSetting = editModelsCertificates.find(
        (editModelsCertificate) => editModelsCertificate.pk === type
      );
      if (certificateSetting) {
        dispatch(internalSetCertificateSettings(certificateSetting));
      }
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
    } finally {
      dispatch(internalSetLoadingBrands(false));
    }
  };

export const setInsertCertificateSettings =
  (request: CertificateSettingsRequest): AppThunk =>
  async (dispatch) => {
    try {
      await DocumentPdfService.insertCertificateSettings(request);
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
    }
  };

export const setUpdateCertificateSettings =
  (request: CertificateSettingsRequest): AppThunk =>
  async (dispatch) => {
    try {
      await DocumentPdfService.updateCertificateSettings(request);
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
    }
  };

export const setDeleteLogoFromBucket =
  (fileName: string): AppThunk =>
  async (dispatch) => {
    try {
      await DocumentService.deleteLogoFromBucket(fileName);
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
    }
  };
