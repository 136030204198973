import { styled } from '@mui/material/styles';
import { ClassNameMap } from '@mui/styles';
import { Grid, Typography, Skeleton } from '@mui/material';
import { AlertTriangle } from 'react-feather';
import { LabeledTextProps } from './types';
import { FONT_FAMILY_REGULAR } from '../../../../theme';

const PREFIX = 'index';

const defaultClasses = {
  root: `${PREFIX}-root`,
  errorMessage: `${PREFIX}-errorMessage`,
  errorIcon: `${PREFIX}-errorIcon`,
  mergedClasses: `${PREFIX}-mergedClasses`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${defaultClasses.root}`]: {
    padding: '0 32px 40px 32px',
  },

  [`& .${defaultClasses.errorMessage}`]: {
    fontFamily: FONT_FAMILY_REGULAR,
  },

  [`& .${defaultClasses.errorIcon}`]: {
    color: theme.palette.error.main,
  }
}));

export default function LabeledText({
  label, children, error, errorMessage, classes, placeholder, isLoading, 'data-testid': dataTestid
}: LabeledTextProps): JSX.Element {


  const mergedClasses: ClassNameMap<'root' | 'errorMessage'> = ({
    root: classes ? classes.root : defaultClasses.root,
    errorMessage: classes ? classes.errorMessage : defaultClasses.errorMessage,
  });

  const getErrorIcon = (): JSX.Element | null => {
    if (!error) {
      return null;
    }
    return <Root><Grid item><AlertTriangle size="16px" className={defaultClasses.errorIcon} /></Grid></Root>;
  };

  const getErrorMessage = (): JSX.Element | null => {
    if (!errorMessage) {
      return null;
    }

    return <Root><Grid item xs={12}><Typography variant="body1" color="error" className={mergedClasses.errorMessage}>{errorMessage}</Typography></Grid></Root >;
  };

  const getContent = (): JSX.Element => {
    if (isLoading) {
      return <Skeleton animation="wave" />;
    }

    return <Typography variant="body2" component="span" data-testid={dataTestid}>{children || placeholder}</Typography>;
  };

  return (
    <Root>
      <Grid item container xs={12} className={mergedClasses.root} >
        <Grid item container xs={12} spacing={1}>
          {getErrorIcon()}
          <Grid item>
            <Typography noWrap variant="body1" color={error ? 'error' : ''}>
              {label}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {getContent()}
        </Grid>
        {getErrorMessage()}
      </Grid>
    </Root>
  );
}
