import { styled } from '@mui/material/styles';
import { FormHelperText } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CustomTextField from './CustomTextField';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Calendar } from 'react-feather';

const PREFIX = 'CustomDatePicker';

const classes = {
  activeKeyboardButton: `${PREFIX}-activeKeyboardButton`,
  activeInput: `${PREFIX}-activeInput`,
  calendarIcon: `${PREFIX}-calendarIcon`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.activeKeyboardButton}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${classes.activeInput}`]: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  [`& .${classes.calendarIcon}`]: {
    color: '#999999 ',
  }
}));

type DatePickerProps = {
  inputLabel?: string;
  value?: string | null;
  maxDate?: any;
  minDate?: any;
  onChange?: any;
  inputError?: boolean;
};

const CustomDatePicker = (props: DatePickerProps) => {

  const [isOpen, setIsOpen] = useState(false);
  const [internalValue, setInternalValue] = useState<Date | null>(null);

  const {
    value,
    onChange,
    maxDate,
    minDate,
    inputLabel,
    inputError
  } = props;

  useEffect(() => {
    setInternalValue(moment(value).toDate());
  }, [value]);

  return (
    (<Root >
      {
        inputLabel && (
          <FormHelperText >
            {inputLabel}
          </FormHelperText>
        )
      }
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBrLocale}>
        <MobileDatePicker
          toolbarTitle=''
          {...props}
          showToolbar={false}
          maxDate={moment(maxDate).toDate()}
          minDate={minDate ? moment(minDate).toDate() : moment('1970-01-01').toDate()}
          className={value ? classes.activeInput : ''}
          closeOnSelect={false}
          value={internalValue || value}
          onChange={(newDate) => {
            setInternalValue(newDate)
          }}
          DialogProps={{
            disableRestoreFocus: true,
            onClose: () => {
              setIsOpen(false);
            }
          }}
          onClose={() => {
            setIsOpen(false)
          }}
          onAccept={() => {
            if (internalValue) {
              onChange(internalValue)
            }
            setIsOpen(false);
          }}
          OpenPickerButtonProps={{
            className: value?.toString() && classes.activeKeyboardButton,
            onFocus: () => {
              setIsOpen(true);
            },
          }}
          InputProps={{
            onFocus: () => {
              setIsOpen(true);
            },
            endAdornment: (
              <Calendar size={20} className={classes.calendarIcon} />
            ),
          }}
          open={isOpen}
          renderInput={(params) => <CustomTextField
            placeholder='Selecione uma data...'
            style={{ width: '100%' }}
            {...params}
            error={inputError ?? params.error}
          />}
        />
      </LocalizationProvider>
    </Root>)
  );
};

CustomDatePicker.defaultProps = {
  inputLabel: '',
};

export default CustomDatePicker;
