import { errorNotificationMessages, notificationIcons, successNotificationMessages } from '../../../utils/constants';
import { NotificationActionTypes, NOTIFY_ERROR, NOTIFY_SUCCESS } from './types';

export const showNotification = (message: string | null = errorNotificationMessages.defaultError,
  icon: string | null = notificationIcons.error): NotificationActionTypes => ({
  type: NOTIFY_ERROR,
  payload: { message, icon },
});

export const showSuccessNotification = (message: string | null = successNotificationMessages.defaultSuccess,
  icon: string | null = notificationIcons.success): NotificationActionTypes => ({
  type: NOTIFY_SUCCESS,
  payload: { message, icon },
});
