import {
  DiplomaModalDefaultSignersActions,
  INITIAL_STATE,
  SET_DEFAULT_SIGNER_MODAL_SETTINGS,
  SET_MODAL_SETTINGS,
  SET_SIGNERS,
  SignersState
} from './types';

export default function reducer(
  state: SignersState = INITIAL_STATE,
  action: DiplomaModalDefaultSignersActions
): SignersState {
  switch (action.type) {
    case SET_SIGNERS:
      return {
        ...state,
        defaultSigners: action.payload.defaultSigners,
        otherSigners: action.payload.otherSigners
      };
    case SET_MODAL_SETTINGS:
      return {
        ...state,
        openDefaultSignersModal: action.payload.openDefaultSignersModal,
        typeSubmit: action.payload.typeSubmit,
        mecCode: action.payload.mecCode,
        diplomaId: action.payload.diplomaId
      };
    case SET_DEFAULT_SIGNER_MODAL_SETTINGS:
      return {
        ...state,
        firstIssuerSigner: action.payload.firstIssuerSigner,
        secondIssuerSigner: action.payload.secondIssuerSigner,
        firstRegisterSigner: action.payload.firstRegisterSigner,
        secondRegisterSigner: action.payload.secondRegisterSigner
      };

    default:
      return state;
  }
}
