import { Grid } from '@mui/material';
import React from 'react';
import PublicDiplomaDto from '../../../../domain/interface/dto/PublicDiplomaDto';
import PublicInstitutionDto from '../../../../domain/interface/dto/PublicInstitutionDto';
import Diploma from '../../../icons/Diploma';
import { formatDate } from '../../../utils/functions';
import CardTitle from '../card-title';
import BasePublicDataWidget from '../base-public-data-widget';
import InlineLabeledText from '../inline-labeled-text';
import { PublicDiplomaDataWidgetProps } from './types';

function formatStatusText(diploma: PublicDiplomaDto | undefined, hasDisclaimer: boolean): string {
  if (hasDisclaimer) {
    return `${diploma?.status}*`;
  }

  return diploma?.status as string;
}

function getDiplomaRegistrationResponsibleName(registerInstitution: PublicInstitutionDto | undefined): string | undefined {
  if (!registerInstitution) {
    return undefined;
  }

  const responsible = registerInstitution.signers.find((signer) => signer.order === 2);
  return responsible?.name;
}

function PublicDiplomaDataWidget({ diploma, isLoading, hasDisclaimer }: PublicDiplomaDataWidgetProps): JSX.Element {
  return (
    <BasePublicDataWidget title={<CardTitle variant="h3" icon={<Diploma />}>Dados do Diploma</CardTitle>}>
      <>
        <Grid item container>
          <InlineLabeledText label="Estado" text={formatStatusText(diploma, hasDisclaimer)} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Livro de Registro" text={diploma?.registerBook?.registerBook} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Número da Folha" text={diploma?.registerBook?.pageNumber.toString()} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Número de Sequência" text={diploma?.registerBook?.sequential.toString()} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Processo" text={diploma?.processNumber} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Data de Colação de Grau" text={formatDate(diploma?.graduate?.graduationCeremonyDate)} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Data de Expedição" text={formatDate(diploma?.requestedOn)} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Data de Registro" text={formatDate(diploma?.registeredOn)} isLoading={isLoading} />
        </Grid>
        <Grid item container>
          <InlineLabeledText label="Responsável pelo Registro" text={getDiplomaRegistrationResponsibleName(diploma?.registerInstitution)} isLoading={isLoading} />
        </Grid>
      </>
    </BasePublicDataWidget>
  );
}

PublicDiplomaDataWidget.defaultProps = {
  isLoading: false,
};

export default PublicDiplomaDataWidget;
