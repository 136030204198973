import State from '../../enum/State';

export default class StateIdDto {
  number?: string;

  state?: State;

  issuedAt?: string;

  issuingAuthority?: string;

  static stringify(stateId: StateIdDto | undefined): string {
    return stateId ? `${stateId.state}-${stateId.number}` : '';
  }
}
