import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

import React from 'react';
import { Users } from 'react-feather';
import BackCardHeader from '../../molecules/back-card-header';
import CardTitle from '../../molecules/card-title';
import { stringify as stringifySex } from '../../../../domain/enum/Sex';
import { AffiliationPaperProps } from './types';
import LabeledTooltip from '../../molecules/labeled-tooltip';

const PREFIX = 'index';

const classes = {
  icon: `${PREFIX}-icon`
};

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main,
  }
}));


export default function AffiliationPaper(props: AffiliationPaperProps): JSX.Element {
  const { onClickBack, validation } = props;
  let { affiliation } = props;
  let { originSystem } = props;
  let { processNumber } = props;

  const rows: Array<JSX.Element> = [];

  if (!affiliation?.parents || affiliation.parents.length === 0) {
    affiliation = {
      parents: [{}],
    };
  }

  if (affiliation?.parents) {
    affiliation.parents.forEach((v, i) => {
      const newIndex = i
      rows.push(
        <React.Fragment key={newIndex}>
          <Grid item xs={4}>
            <LabeledTooltip label="Nome" error={validation?.parents[i]?.name} errorMessage={validation?.parents[i]?.name} originSystem={originSystem} processNumber={processNumber}>
              {v?.name || 'N/A'}
            </LabeledTooltip>
          </Grid>
          <Grid item xs={4}>
            <LabeledTooltip label="Nome Social" error={validation?.parents[i]?.socialName} errorMessage={validation?.parents[i]?.socialName} originSystem={originSystem} processNumber={processNumber}>
              {v?.socialName || 'N/A'}
            </LabeledTooltip>
          </Grid>
          <Grid item xs={4}>
            <LabeledTooltip label="Sexo" error={validation?.parents[i]?.sex} errorMessage={validation?.parents[i]?.sex} originSystem={originSystem} processNumber={processNumber}>
              {(v?.sex && stringifySex(v.sex)) || 'N/A'}
            </LabeledTooltip>
          </Grid>
        </React.Fragment>,
      );
    });
  }

  return (
    <StyledPaper>
      {onClickBack && <BackCardHeader onClick={onClickBack} />}
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <CardTitle icon={<Users className={classes.icon} />}>Genitores</CardTitle>
        </Grid>
        {rows}
      </Grid>
    </StyledPaper>
  );
}
