import {
  DiplomaModalSignersActions, INITIAL_STATE, SET_EMPLOYEES, SignersState,
} from './types';

export default function reducer(state: SignersState = INITIAL_STATE, action: DiplomaModalSignersActions): SignersState {
  if (action.type === SET_EMPLOYEES) {
    return {
      ...state,
      employees: action.payload.employees,
    };
  }
  return state;
}
