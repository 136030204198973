import React from 'react';
import { styled } from '@mui/material/styles';
import { LIGHT_GRAY_COLOR, theme } from '../../../../theme';

const StyledInfoBadge = styled('div')({
  backgroundColor: LIGHT_GRAY_COLOR,
  fontFamily: theme.typography.subtitle1.fontFamily,
  fontSize: '12px',
  color: 'black',
  padding: '1px 8px 1px 8px',
  borderRadius: '20px',
  display: 'inline-block',
  marginLeft: '10px',
});

const XsdVersionBadge: React.FC<{ xsdVersion: string }> = ({ xsdVersion }) => (
  <StyledInfoBadge>
    {xsdVersion}
  </StyledInfoBadge>
);

export default XsdVersionBadge;
