import { HeadCell, Order } from './types';

export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

export const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key
): ((
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

export const stableSort = <T>(
  array: T[],
  comparator: (a: T, b: T) => number
) => {
  if (array.length === 0) {
    return array;
  }
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

export const headCells: HeadCell[] = [
  {
    id: 'status',
    label: 'Status do Lote',
    size: '10%',
    align: 'center',
    sortable: true
  },
  {
    id: 'batchNumber',
    label: 'Número',
    size: '10%',
    align: 'left',
    styles: { marginLeft: '2.5rem' },
    sortable: true
  },
  {
    id: 'type',
    label: 'Tipo do Lote',
    size: '10%',
    align: 'left',
    styles: { marginLeft: '1.5rem' },
    sortable: true
  },
  {
    id: 'createdAt',
    label: 'Data de Criação',
    size: '10%',
    align: 'right',
    sortable: true
  },
  {
    id: 'publicationDate',
    label: 'Data de Publicação',
    size: '15%',
    align: 'right',
    sortable: true
  },
  {
    id: 'douNumber',
    label: 'N° do DOU',
    size: '10%',
    align: 'left',
    styles: { marginLeft: '2.5rem' },
    sortable: true
  },
  {
    id: 'action',
    label: 'Ações',
    size: '25%',
    align: 'right',
    styles: { marginRight: '5rem' }
  }
];
