import {
  DiplomaDashboardActions,
  DiplomaDashboardState,
  INITIAL_STATE,
  SELECT_DASHBOARD_END_DATE,
  SELECT_DASHBOARD_START_DATE,
  SET_DASHBOARD_DATA,
  SET_DASHBOARD_LOADING_DATA
} from './types';

export default function reducer(
  state: DiplomaDashboardState = INITIAL_STATE,
  action: DiplomaDashboardActions
): DiplomaDashboardState {
  switch (action.type) {
    case SELECT_DASHBOARD_START_DATE:
      return {
        ...state,
        startDate: action.payload.startDate
      };
    case SELECT_DASHBOARD_END_DATE:
      return {
        ...state,
        endDate: action.payload.endDate
      };
    case SET_DASHBOARD_DATA:
      return {
        ...state,
        data: action.payload.data
      };
    case SET_DASHBOARD_LOADING_DATA:
      return {
        ...state,
        loadingData: action.payload.loadingData
      };
    default:
      return state;
  }
}
