import { Grid, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Edit, Lock } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TypeSubmit from '../../../../../domain/enum/TypeSubmit';
import { RootState } from '../../../../../setup/reducer/reducers';
import { GRAY_COLOR, DIPLOMA_COLOR } from "../../../../../theme";
import { pageRoutes } from '../../../../utils/constants';
import CustomizedTooltip from "../../../atoms/customized-tooltip";
import { setDefaultUserModalAction } from '../../../organisms/users-modal/store/action';
import { getUsersSecurity } from '../../users-security-page/store/actions';
import { setUsersModalAction } from '../store/actions';
import { UsersManagementDataProps } from '../types';


const PREFIX = 'UsersManagementActions';

const classes = {
  icon: `${PREFIX}-icon`,
  activeIcon: `${PREFIX}-activeIcon`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.icon}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.25rem',
    height: '1.25rem',
    color: GRAY_COLOR,
    cursor: 'not-allowed',
  },

  [`& .${classes.activeIcon}`]: {
    cursor: 'pointer',
    color: DIPLOMA_COLOR,
  },
}));


export default function UsersManagementActions(props: UsersManagementDataProps) {
  const employees = useSelector((state: RootState) => state.usersManagementFilter.employees);
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = props

  function handleClickEditUsers() {
    const employee = employees?.find((data) => data.cpf === user.cpf);
    dispatch(setDefaultUserModalAction(employee))
    dispatch(setUsersModalAction(true, TypeSubmit.EditUserModal))
  }

  const handleClickSecurity = async () => {
    dispatch(getUsersSecurity(user));
    history.push(pageRoutes.UsersSecurity);
  };


  return (
    <>
      <StyledGrid
        container
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item>
          <CustomizedTooltip title="Editar Usuário" placement="top">
            <span>
              <IconButton size="large" onClick={handleClickEditUsers}>
                <Edit className={`${classes.icon} ${classes.activeIcon}`} color={DIPLOMA_COLOR} />
              </IconButton>
            </span>
          </CustomizedTooltip>
          <CustomizedTooltip title="Segurança" placement="top">
            <span>
              <IconButton size="large" onClick={handleClickSecurity}>
                <Lock className={`${classes.icon} ${classes.activeIcon}`} color={DIPLOMA_COLOR} />
              </IconButton>
            </span>
          </CustomizedTooltip>
        </Grid>
      </StyledGrid>
    </>
  )
}
