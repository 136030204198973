import InstitutionRegulatoryActType from '../../../../../domain/enum/InstitutionRegulatoryActType';
import ChangeCurriculumRequest from '../../../../../domain/interface/request/ChangeCurriculumRequest';
import Curriculum from '../../../../../domain/model/curriculum/Curriculum';
import Institution from '../../../../../domain/model/diploma/Institution';
import InstitutionRegulatoryAct from '../../../../../domain/model/institution/InstitutionRegulatoryAct';
import CurriculumService from '../../../../../services/CurriculumService';
import DiplomaInstitutionService from '../../../../../services/DiplomaInstitutionService';
import { AppThunk } from '../../../../../setup/reducer';
import { RootState } from '../../../../../setup/reducer/reducers';
import {
  errorNotificationMessages,
  notificationIcons,
} from '../../../../utils/constants';
import {
  showNotification,
  showSuccessNotification
} from '../../notification/actions';
import {
  CurriculumValidateActions,
  SET_CURRICULUM,
  TOGGLE_IS_LOADING_CURRICULUM
} from './type';

const internalSetCurriculum = (
  curriculum: Curriculum,
  validation?: any
): CurriculumValidateActions => ({
  type: SET_CURRICULUM,
  payload: {
    curriculum,
    validation
  }
});

const sortInstitutionRegulatoryActByDateDescending = (
  a: InstitutionRegulatoryAct,
  b: InstitutionRegulatoryAct
): number => {
  if ((!a || !a.date) && (!b || !b.date)) {
    return 0;
  }
  if (!a || !a.date) {
    return 1;
  }
  if (!b || !b.date) {
    return -1;
  }
  if (a.date > b.date) {
    return -1;
  }
  if (a.date < b.date) {
    return 1;
  }
  return 0;
};

const selectNewestIesRegulatoryActByType = (
  iesRegulatoryActs: InstitutionRegulatoryAct[],
  type: InstitutionRegulatoryActType
): InstitutionRegulatoryAct | undefined =>
  iesRegulatoryActs
    .filter((r) => r.type === type)
    .sort(sortInstitutionRegulatoryActByDateDescending)
    .shift();

export const toggleIsLoadingCurriculum = (): CurriculumValidateActions => ({
  type: TOGGLE_IS_LOADING_CURRICULUM
});

export const setCurriculum =
  (idCurriculum: string): AppThunk =>
  async (dispatch, getState: () => RootState) => {
    dispatch(toggleIsLoadingCurriculum());

    let curriculum: Curriculum = new Curriculum();

    const selectedInstitution: Institution | undefined =
      getState().header.institution;

    if (!selectedInstitution) {
      dispatch(
        showNotification(
          errorNotificationMessages.thereMustBeSelectedIesToProceed,
          notificationIcons.error
        )
      );
      return;
    }

    try {
      curriculum = (await CurriculumService.getCurriculum({ id: idCurriculum }))
        .data;
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
      dispatch(toggleIsLoadingCurriculum());
    }
    if (!curriculum.issuerInstitution) {
      let regulatoryActs: InstitutionRegulatoryAct[] | undefined;

      try {
        regulatoryActs = (
          await DiplomaInstitutionService.listRegulatoryAct(
            selectedInstitution.mecCode!
          )
        ).data;
      } catch {
        dispatch(
          showNotification(
            errorNotificationMessages.failedToRetrieveIesRegulatoryActs,
            notificationIcons.error
          )
        );
      }

      curriculum.issuerInstitution = JSON.parse(
        JSON.stringify(selectedInstitution)
      );

      if (regulatoryActs && curriculum.issuerInstitution) {
        curriculum.issuerInstitution.licensing =
          selectNewestIesRegulatoryActByType(
            regulatoryActs,
            InstitutionRegulatoryActType.Licensing
          );
        curriculum.issuerInstitution.relicensing =
          selectNewestIesRegulatoryActByType(
            regulatoryActs,
            InstitutionRegulatoryActType.Relicensing
          );
        curriculum.issuerInstitution.relicensingRenewal =
          selectNewestIesRegulatoryActByType(
            regulatoryActs,
            InstitutionRegulatoryActType.RelicensingRenewal
          );
      }
    }

    let validation: any;

    try {
      validation = (await CurriculumService.validateCurriculum(curriculum)).data
        .curriculum;
    } catch {
      dispatch(
        showNotification(
          errorNotificationMessages.failedToValidateDiploma,
          notificationIcons.error
        )
      );
      dispatch(internalSetCurriculum(curriculum));
      dispatch(toggleIsLoadingCurriculum());
      return;
    }
    dispatch(toggleIsLoadingCurriculum());
    dispatch(internalSetCurriculum(curriculum, validation));
  };

export const addChangeCurriculum =
  (
    request: ChangeCurriculumRequest,
    id?: string,
    successMessage?: string,
    errorMessage?: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      await CurriculumService.addChangeCurriculum(request, id);
      if(successMessage) {
        dispatch(
          showSuccessNotification(successMessage, notificationIcons.success)
        );
      }
    } catch (e) {
      if(errorMessage) {
        dispatch(showNotification(errorMessage, notificationIcons.error));
      }
    } 
  };
