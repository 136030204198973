import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Typography, CircularProgress, Backdrop, Grid,
} from '@mui/material';
import { BackdropLoadingProps } from './types';
import { A2S_COLOR, BACKGROUND_COLOR } from '../../../../theme';

const PREFIX = 'BackdropLoading';

const defaultClasses = {
  backdrop: `${PREFIX}-backdrop`,
  loading: `${PREFIX}-loading`,
  descriptionLoading: `${PREFIX}-descriptionLoading`,
  circularProgress: `${PREFIX}-circularProgress`
};

const Root = styled('div')({
  [`& .${defaultClasses.backdrop}`]: {
    zIndex: 2,
    backgroundColor: BACKGROUND_COLOR,
    opacity: '0.9 !important',
  },
  [`& .${defaultClasses.loading}`]: {
    alignItems: 'center',
    textAlign: 'center',
  },
  [`& .${defaultClasses.descriptionLoading}`]: {
    width: '19.938rem',
    color: A2S_COLOR,
  },
  [`& .${defaultClasses.circularProgress}`]: {
    color: A2S_COLOR,
  },
});

const BackdropLoading = ({
  size, description, open, classes,
}: BackdropLoadingProps) => {


  return (
    <Root>
      <Backdrop className={defaultClasses.backdrop} open={open}>
        <Grid
          className={`${defaultClasses.loading} ${classes?.grid}`}
          container
          direction="column"
          spacing={3}
        >
          <Grid item>
            <CircularProgress thickness={4} size={size} className={classes?.circularProgress || defaultClasses.circularProgress} />
          </Grid>
          <Grid item>
            <Typography variant="h2" className={classes?.descriptionLoading || defaultClasses.descriptionLoading}>{description}</Typography>
          </Grid>
        </Grid>
      </Backdrop>
    </Root>
  );
};

export default BackdropLoading;
