import { AxiosResponse } from 'axios';
import getAxiosInstance from '../apis/axiosInstance';
import GetTemplateRequest from '../domain/interface/request/GetTemplateRequest';
import CertificateSettingsRequest from '../domain/interface/request/CertificateSettingsRequest';
import GetCertificateTypesAvailableResponse from '../domain/interface/response/GetCertificateTypesAvailableResponse';
import GetListBrandsResponse from '../domain/interface/response/GetListBrandsResponse';
import GetListSettingsCertificateResponse from '../domain/interface/response/GetListSettingsCertificateResponse';
import GetTemplateResponse from '../domain/interface/response/GetTemplateResponse';

const axiosInstance = getAxiosInstance(
  process.env.REACT_APP_DIPLOMA_DOCUMENT_PDF_API || ''
);

class DocumentPdfService {
  async getTemplate(
    request: GetTemplateRequest
  ): Promise<AxiosResponse<GetTemplateResponse>> {
    return axiosInstance.get('template', {
      params: {
        ...request
      }
    });
  }
  async getListSettingsCertificate(): Promise<
    AxiosResponse<GetListSettingsCertificateResponse[]>
  > {
    return axiosInstance.get('listSettings');
  }

  async getCertificateTyppeAvailable(): Promise<
    AxiosResponse<GetCertificateTypesAvailableResponse>
  > {
    return axiosInstance.get('certificateTypeAvailable');
  }

  async getListBrands(): Promise<AxiosResponse<GetListBrandsResponse>> {
    return axiosInstance.get('listBrandsTemplate');
  }

  async insertCertificateSettings(
    request: CertificateSettingsRequest
  ): Promise<AxiosResponse> {
    return axiosInstance.post('insertCertificateSettings', request);
  }

  async updateCertificateSettings(
    request: CertificateSettingsRequest
  ): Promise<AxiosResponse> {
    return axiosInstance.put('updateCertificateSettings', request);
  }
}

export default new DocumentPdfService();
