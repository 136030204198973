import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, ClickAwayListener, Button } from '@mui/material';
import { Filter } from 'react-feather';
import { connect, ConnectedProps } from 'react-redux';
import { GRAY_COLOR, WHITE_COLOR, LIGHT_GRAY_COLOR } from '../../../../theme';
import SelectAutocomplete from '../select-autocomplete';
import * as Actions from './store/actions';
import Institution from '../../../../domain/model/institution/Institution';
import { RootState } from '../../../../setup/reducer/reducers';
import { sortListByName } from '../../../utils/functions';
import { setSelectedInstitutionProfile } from '../../pages/diploma-list/store/actions';
import InstitutionProfile from '../../../../domain/enum/InstitutionProfile';

const PREFIX = 'index';

const classes = {
  emptyState: `${PREFIX}-emptyState`,
  icon: `${PREFIX}-icon`,
  filter: `${PREFIX}-filter`,
  filterDisabled: `${PREFIX}-filterDisabled`,
  internalContainer: `${PREFIX}-internalContainer`,
  externalContainer: `${PREFIX}-externalContainer`,
  textApllyButton: `${PREFIX}-textApllyButton`,
  applyButton: `${PREFIX}-applyButton`,
  iesLabel: `${PREFIX}-iesLabel`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.emptyState}`]: {
    marginTop: '6px',
    position: 'relative',
    left: '10%'
  },

  [`& .${classes.icon}`]: {
    color: GRAY_COLOR
  },

  [`& .${classes.filter}`]: {
    color: theme.palette.primary.main
  },

  [`& .${classes.filterDisabled}`]: {
    color: WHITE_COLOR
  },

  [`& .${classes.internalContainer}`]: {
    position: 'absolute',
    zIndex: 1,
    backgroundColor: WHITE_COLOR,
    maxHeight: '20rem',
    width: '340px',
    boxShadow: '0 0 18px -1px #E8E8E8',
    borderRadius: '4px',
    padding: '20px'
  },

  [`& .${classes.externalContainer}`]: {
    position: 'relative',
    left: '-4.8rem',
    top: '1rem'
  },

  [`& .${classes.textApllyButton}`]: {
    fontSize: '14px',
    color: theme.palette.primary.main
  },

  [`& .${classes.applyButton}`]: {
    marginTop: '15px',
    backgroundColor: WHITE_COLOR,
    borderColor: theme.palette.primary.main,
    height: '48px',
    width: '100%',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: 'rgba(6,47,53,0.1)'
    },
    '&.Mui-disabled': {
      backgroundColor: LIGHT_GRAY_COLOR,
      borderColor: WHITE_COLOR,
      color: WHITE_COLOR
    }
  },

  [`& .${classes.iesLabel}`]: {
    marginTop: '8px'
  }
}));

const mapState = (state: RootState) => ({
  institutions: state.header.institutions,
  institution: state.header.institution
});

const mapDispatch = {
  setInstitution: (institution?: Institution) =>
    Actions.setInstitution(institution),
  resetSelectedInstitutionProfile: () =>
    setSelectedInstitutionProfile(InstitutionProfile.Issuer)
};

const connector = connect(mapState, mapDispatch);

interface InternalProps {
  showDropdown: boolean;
  setShowDropdown: any;
}

type Props = ConnectedProps<typeof connector> & InternalProps;

const isValidInstitution = (
  institution: Institution,
  institutions: Institution[]
): boolean => {
  for (const i of institutions) {
    if (i.mecCode === institution.mecCode) {
      return true;
    }
  }
  return false;
};

const DropdownIesCampus = (props: Props) => {
  const {
    setInstitution,
    resetSelectedInstitutionProfile,
    institution,
    institutions,
    showDropdown,
    setShowDropdown
  } = props;

  const [institutionLocal, setInstitutionLocal] = React.useState<
    Institution | undefined
  >(undefined);
  const [loadingInstitution] = React.useState<boolean>(false);

  React.useEffect(() => {
    setInstitutionLocal(institution);
  }, [institution]);

  React.useEffect(() => {
    resetSelectedInstitutionProfile();
  }, [institution, resetSelectedInstitutionProfile]);

  React.useEffect(() => {
    if (institutions.length > 0) {
      if (
        !institution ||
        (institution && !isValidInstitution(institution, institutions))
      ) {
        const [firstSortedInstitution] = [...institutions].sort(sortListByName);

        setInstitution(firstSortedInstitution);
      }
    }
  }, [institution, institutions, setInstitution]);

  const handleFilterOnClick = () => {
    setInstitution(institutionLocal);
    setShowDropdown(false);

    verifyInstitution();
  };

  let verifyInstitution = () => {
    localStorage.setItem(
      'institution_profile',
      JSON.stringify(InstitutionProfile.Issuer)
    );
    localStorage.setItem('institution_local', JSON.stringify(institutionLocal));
    document.location.reload();
  };

  const sortedInstitutions = [...institutions].sort(sortListByName);

  return (
    <>
      {showDropdown && (
        <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
          <StyledGrid container className={classes.externalContainer}>
            <Grid
              className={classes.internalContainer}
              style={{ left: '3rem' }}
            >
              <SelectAutocomplete
                compareName
                defaultValue={institutionLocal}
                loading={loadingInstitution}
                classes={{ labelAutoComplete: classes.iesLabel }}
                label="IES"
                placeholder="Nenhuma IES selecionada"
                listItems={sortedInstitutions}
                setItem={setInstitutionLocal}
                data-testid="select-ies"
              />
              <Button
                variant="outlined"
                color="primary"
                startIcon={
                  <Filter
                    size={18}
                    className={
                      !institutionLocal && !institution
                        ? classes.filterDisabled
                        : classes.filter
                    }
                  />
                }
                type="submit"
                fullWidth
                disableElevation
                className={classes.applyButton}
                disabled={!institutionLocal && !institution}
                onClick={handleFilterOnClick}
                data-testid="select-ies-campus-submit"
              >
                Aplicar
              </Button>
            </Grid>
          </StyledGrid>
        </ClickAwayListener>
      )}
    </>
  );
};

export default connector(DropdownIesCampus);
