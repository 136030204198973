import { Divider, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import React from 'react';
import PublicDiplomaStatus from '../../../../domain/enum/diploma/PublicDiplomaStatus';
import InstitutionProfile from '../../../../domain/enum/InstitutionProfile';
import PublicDiplomaDto from '../../../../domain/interface/dto/PublicDiplomaDto';
import PublicCourseDataWidget from '../../molecules/public-course-data-widget';
import PublicDiplomaActionButtonGroup from '../../molecules/public-diploma-action-button-group';
import PublicDiplomaDataWidget from '../../molecules/public-diploma-data-widget';
import PublicDiplomaHistoryWidget from '../../molecules/public-diploma-history-widget';
import PublicGraduateDataWidget from '../../molecules/public-graduate-data-widget';
import PublicInstitutionDataWidget from '../../molecules/public-institution-data-widget';
import { PublicDiplomaPaperProps } from './types';

const PREFIX = 'index';

const classes = {
  lastItem: `${PREFIX}-lastItem`,
  divider: `${PREFIX}-divider`,
  root: `${PREFIX}-root`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.lastItem}`]: {
    marginBottom: theme.spacing(4),
  },

  [`& .${classes.divider}`]: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },

  [`&.${classes.root}`]: {
    marginRight: theme.spacing(2),
  }
}));

function shouldShowHistoryWidget(diploma: PublicDiplomaDto | undefined): boolean {
  if (!diploma || !diploma.history) {
    return false;
  }

  if (diploma.status !== PublicDiplomaStatus.Completed) {
    return true;
  }

  return diploma.history.length > 1;
}

export default function PublicDiplomaInfo({ diploma, isLoading }: PublicDiplomaPaperProps): JSX.Element {


  const showAllData = diploma?.status === PublicDiplomaStatus.Completed || isLoading;
  const historyWidgetClassName = showAllData ? undefined : classes.lastItem;
  const showHistoryWidget = shouldShowHistoryWidget(diploma);
  const showHistoryDisclaimer = diploma?.status === PublicDiplomaStatus.Completed;

  return (
    <StyledGrid className={classes.root} container direction="column">
      {showAllData ? (
        <>
          <Grid item container>
            <PublicDiplomaActionButtonGroup diploma={diploma} isLoading={isLoading} />
          </Grid>
          <Grid className={classes.divider} item>
            <Divider />
          </Grid>
          <Grid item container>
            <PublicGraduateDataWidget graduate={diploma?.graduate} isLoading={isLoading} />
          </Grid>
          <Grid item container>
            <PublicDiplomaDataWidget diploma={diploma} isLoading={isLoading} hasDisclaimer={showHistoryWidget} />
          </Grid>
        </>
      ) : null}
      {showHistoryWidget ? (
        <Grid item container className={historyWidgetClassName}>
          <PublicDiplomaHistoryWidget diploma={diploma} hasDisclaimer={showHistoryDisclaimer} />
        </Grid>
      ) : null}
      {showAllData ? (
        <>
          <Grid item container>
            <PublicCourseDataWidget course={diploma?.course} isLoading={isLoading} />
          </Grid>
          <Grid item container>
            <PublicInstitutionDataWidget institution={diploma?.issuerInstitution} institutionProfile={InstitutionProfile.Issuer} isLoading={isLoading} />
          </Grid>
          <Grid className={classes.lastItem} item container>
            <PublicInstitutionDataWidget institution={diploma?.registerInstitution} institutionProfile={InstitutionProfile.Register} isLoading={isLoading} />
          </Grid>
        </>
      ) : null}
    </StyledGrid>
  );
}
