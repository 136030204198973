import { useEffect, useState } from 'react';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import {
  updateUser,
  updateUserManager
} from './shared/components/pages/login-microsoft/store';
import store from './setup/reducer/index';
import { BACKGROUND_COLOR, theme as appTheme, globalStyle } from './theme';
import {
  GetOidcConfig,
  GetUser,
  GetUserData
} from './shared/components/pages/login-microsoft/LoginManager';
import DiplomaBase from './shared/components/pages/diploma-base/views/DiplomaBase';
import { makeUserManager } from 'react-oidc';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './setup/reducer/reducers';
import {
  authorizations,
  errorNotificationMessages,
  notificationIcons,
  pageRoutes
} from './shared/utils/constants';
import { isAuthorized, sortListByName } from './shared/utils/functions';
import { setShowSignDocuments } from './shared/components/pages/authorization/store/actions';
import {
  setInstitution,
  setInstitutions
} from './shared/components/molecules/dropdown-ies-campus/store/actions';
import Institution from './domain/model/diploma/Institution';
import AuthenticationService from './services/AuthenticationService';
import { showNotification } from './shared/components/pages/notification/actions';
import { useHistory } from 'react-router-dom'
import { listSigners } from './shared/components/organisms/default-signers-modal/store/actions';
;

const projectTheme = createTheme(appTheme);

const PREFIX = 'App';

const classes = {
  content: `${PREFIX}-content`,
  global: `${PREFIX}-global`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.content}`]: {
    '@global': globalStyle,
    flexGrow: 1,
    backgroundColor: BACKGROUND_COLOR,
    minHeight: '100vh',
    paddingTop: theme.spacing(7),
    paddingRight: theme.spacing(4),
    paddingBottom: 0,
    paddingLeft: theme.spacing(12)
  },
  [`& .${classes.global}`]: {
    '@global': globalStyle
  }
}));

const App = () => {
  const [userData, setuserData] = useState(null);
  const user = useSelector((state: RootState) => state.login.user);
  const history = useHistory();

  useEffect(() => {
    if (!userData) {
      const userDataResult = GetUserData(GetUser());
      store.dispatch(updateUser(userDataResult));
      if (userDataResult?.token) {
        AuthenticationService.userPermission()
          .then((response) => {
            userDataResult.roles = response.data.permissions;
            store.dispatch(updateUser(userDataResult));
          })
          .catch(() => {
            store.dispatch(updateUser());
            store.dispatch(
              showNotification(
                errorNotificationMessages.failedToLogin,
                notificationIcons.error
              )
            );
            history.push(pageRoutes.LoginMicrosoft);
          });
      }
      store.dispatch(updateUserManager(makeUserManager(GetOidcConfig())));
      setuserData(userDataResult);
    }
  }, [history, userData]);

  const dispatch = useDispatch();
  const selectedInstitution = useSelector(
    (state: RootState) => state.header.institution
  );

  const roles = useSelector((state: RootState) => state.login.user?.roles);
  const institution = useSelector(
    (state: RootState) => state.header.institution
  );

  const institutions = useSelector(
    (state: RootState) => state.header.institutions
  );

  const isValidInstitution = (
    institution: Institution,
    institutions: Institution[]
  ): boolean => {
    for (const i of institutions) {
      if (i.mecCode === institution.mecCode) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (institutions.length > 0) {
      if (
        !institution ||
        (institution && !isValidInstitution(institution, institutions))
      ) {
        const [firstSortedInstitution] = [...institutions].sort(sortListByName);

        dispatch(setInstitution(firstSortedInstitution))
      }
    }
  }, [institution, institutions, dispatch]);

  useEffect(() => {
    if (roles && user?.token && institutions?.length === 0) {
      dispatch(setInstitutions());
    }
  }, [dispatch, institutions, user, roles]);

  useEffect(() => {
    if (user?.roles && selectedInstitution?.mecCode) {
      let permission = authorizations.showSignDocuments;
      permission = permission.replace(
        '{institutionMecCode}',
        selectedInstitution?.mecCode.toString() || '*'
      );
      permission = permission.replace('{campusEmecCode}', '*');

      const isAuthorizedValue = isAuthorized(permission, user.roles.split(' '));
      dispatch(setShowSignDocuments(isAuthorizedValue));
      dispatch(
        setShowSignDocuments(isAuthorized(permission, user.roles.split(' ')))
      );
      dispatch(listSigners(selectedInstitution?.mecCode));
    }
  }, [dispatch, user, selectedInstitution]);

  return (
    <ThemeProvider theme={projectTheme}>
      <CssBaseline />
      <Root>
        {' '}
        <DiplomaBase />
      </Root>
    </ThemeProvider>
  );
};

export default App;
