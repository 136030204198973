import { Grid, Paper, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CardTitle from '../../molecules/card-title';
import { CnpjMask } from '../../../utils/stringMasks';
import RegulatoryAct from '../../../../domain/model/RegulatoryAct';
import Address from '../../../../domain/model/Address';
import { FONT_FAMILY_EXTRABOLD } from '../../../../theme';
import { CurriculumIssuerInstitutionPaperProps } from './types';
import LabeledText from '../../molecules/labeled-text';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.primary.main
    },
    typography: {
      fontFamily: FONT_FAMILY_EXTRABOLD,
      color: theme.palette.primary.main
    }
  })
);

export default function CurriculumIssuerInstitutionPaper({
  institution,
  profile,
  isLoading,
  validation
}: CurriculumIssuerInstitutionPaperProps): JSX.Element {
  const classes = useStyles();

  return (
    <Paper>
      <Grid data-testid="curriculum-institution-paper" container item xs={12}>
        <Grid item xs={12}>
          <CardTitle
            icon={<BusinessIcon className={classes.icon} />}
          >{`IES ${profile}`}</CardTitle>
        </Grid>
        <Grid item xs={4}>
          <LabeledText
            label="Nome"
            isLoading={isLoading}
            error={validation?.name}
            errorMessage={validation?.name}
            data-testid="curriculum-institution-name"
          >
            {institution?.name || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={4}>
          <LabeledText
            label="CNPJ"
            isLoading={isLoading}
            error={validation?.cnpj}
            errorMessage={validation?.cnpj}
            data-testid="curriculum-institution-cnpj"
          >
            {institution?.cnpj ? CnpjMask.apply(institution.cnpj) : 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={2}>
          <LabeledText
            label="Código MEC"
            isLoading={isLoading}
            error={validation?.mecCode}
            errorMessage={validation?.mecCode}
            data-testid="curriculum-institution-mec-code"
          >
            {institution?.mecCode?.toString() || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={4}>
          <LabeledText
            label="Endereço"
            error={validation?.address}
            errorMessage={validation?.address}
            isLoading={isLoading}
            data-testid="curriculum-institution-address"
          >
            {Address.stringify(institution?.address) || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={6}>
          <LabeledText
            label="Marca"
            error={validation?.brand}
            errorMessage={validation?.brand ? 'Marca inválida' : undefined}
            isLoading={isLoading}
            data-testid="curriculum-institution-brand"
          >
            {institution?.brand?.toString() || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={4}>
          <LabeledText
            label="Credenciamento"
            error={validation?.licensing}
            errorMessage={validation?.licensing}
            isLoading={isLoading}
            data-testid="curriculum-institution-licensing"
          >
            {RegulatoryAct.stringify(institution?.licensing) || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={4}>
          <LabeledText
            label="Recredenciamento"
            error={validation?.relicensing}
            errorMessage={validation?.relicensing}
            isLoading={isLoading}
            data-testid="curriculum-institution-relicensing"
          >
            {RegulatoryAct.stringify(institution?.relicensing) || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={4}>
          <LabeledText
            label="Renovação de Recredenciamento"
            error={validation?.relicensingRenewal}
            errorMessage={validation?.relicensingRenewal}
            isLoading={isLoading}
            data-testid="curriculum-institution-relicensing-renewal"
          >
            {RegulatoryAct.stringify(institution?.relicensingRenewal) || 'N/A'}
          </LabeledText>
        </Grid>

        <Grid item xs={12}>
          <CardTitle
            variant="body1"
            classes={classes}
            icon={<AccountBalanceIcon className={classes.icon} />}
          >
            Mantenedora
          </CardTitle>
        </Grid>
        <Grid item xs={4}>
          <LabeledText
            label="CNPJ"
            error={validation?.sponsor?.cnpj}
            errorMessage={validation?.sponsor?.cnpj}
            isLoading={isLoading}
            data-testid="curriculum-institution-sponsor-cnpj"
          >
            {institution?.sponsor?.cnpj
              ? CnpjMask.apply(institution?.sponsor?.cnpj)
              : 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={8}>
          <LabeledText
            label="Razão Social"
            error={validation?.sponsor?.corporateName}
            errorMessage={validation?.sponsor?.corporateName}
            isLoading={isLoading}
            data-testid="curriculum-institution-sponsor-corporate-name"
          >
            {institution?.sponsor?.corporateName || 'N/A'}
          </LabeledText>
        </Grid>
        <Grid item xs={12}>
          <LabeledText
            label="Endereço"
            isLoading={isLoading}
            error={validation?.sponsor?.address}
            errorMessage={validation?.sponsor?.address}
            data-testid="curriculum-institution-sponsor-address"
          >
            {Address.stringify(institution?.sponsor?.address) || 'N/A'}
          </LabeledText>
        </Grid>

      </Grid>
    </Paper>
  );
}
