import { HeadCell, Order } from './types';

export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key
): ((
  a: {
    [key in Key]: any;
  },
  b: {
    [key in Key]: any;
  }
) => number) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

export const stableSort = <T>(
  array: T[],
  comparator: (a: T, b: T) => number
) => {
  if (array.length === 0) {
    return array;
  }

  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const headCells: HeadCell[] = [
  {
    id: 'checkbox',
    label: '',
    size: '2%',
    align: 'left'
  },

  {
    id: 'status',
    label: 'Situação do Diploma',
    size: '25%',
    align: 'center',
    sortable: true
  },
  {
    id: 'updatedAt',
    label: 'Última Atualização',
    size: '10%',
    align: 'right',
    sortable: true
  },
  {
    id: 'graduateRegistrationNumber',
    label: 'Matrícula do Aluno',
    size: '5%',
    align: 'right',
    sortable: true
  },
  {
    id: 'graduateName',
    label: 'Nome do Aluno',
    size: '5%',
    align: 'left',
    sortable: true
  },
  {
    id: 'courseName',
    label: 'Curso',
    size: '10%',
    align: 'left',
    sortable: true
  },
  {
    id: 'defaultSigners',
    label: 'Assinantes',
    size: '23%',
    align: 'left',
    sortable: false
  },
  {
    id: 'action',
    label: 'Ações',
    size: '20%',
    align: 'left',
    styles: { paddingLeft: '0.8rem' }
  }
];
