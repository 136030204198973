import { Grid, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileText, Info, X } from 'react-feather';
import DouRegisterBatchStatus from '../../../../../domain/enum/dou/DouRegisterBatchStatus';
import { RootState } from '../../../../../setup/reducer/reducers';
import { CnpjMask } from '../../../../utils/stringMasks';
import { selectBatch, toggleInfoBatch } from '../../dou-page/store/actions';
import DouTemplateManager from '../DouTemplateManager';
import { DouRegisterBatchProps } from '../types';
import GetTemplateRequest from '../../../../../domain/interface/request/GetTemplateRequest';
import { showNotification, showSuccessNotification } from '../../notification/actions';
import { errorNotificationMessages, notificationIcons, successNotificationMessages, warningNotificationMessages } from '../../../../utils/constants';
import { setBatches, setLoadingBatches } from '../store/actions';
import CustomizedTooltip from '../../../atoms/customized-tooltip';
import PublicationDouModal from '../../../organisms/publication-dou-modal';
import CloseBatchModal from '../../../organisms/close-batch-modal';
import { EERIE_BLACK_COLOR, GRAY_COLOR, DIPLOMA_COLOR } from '../../../../../theme';
import DocumentType from '../../../../../domain/enum/DocumentType';
import DouBatchType from '../../../../../domain/enum/dou/DouBatchType';
import DouService from '../../../../../services/DouService';
import Dou from '../../../../../domain/model/dou/Dou';
import BatchPaperManager from '../../../organisms/batch-paper/BatchPaperManager';

const PREFIX = 'DouActions';

const classes = {
  icon: `${PREFIX}-icon`,
  disabled: `${PREFIX}-disabled`,
  activeIcon: `${PREFIX}-activeIcon`,
  textDouModal: `${PREFIX}-textDouModal`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.icon}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.25rem',
    height: '1.25rem',
    color: GRAY_COLOR,
    cursor: 'not-allowed',
  },

  [`& .${classes.disabled}`]: {
    cursor: 'not-allowed',
  },

  [`& .${classes.activeIcon}`]: {
    cursor: 'pointer',
    color: DIPLOMA_COLOR,
  },

  [`& .${classes.textDouModal}`]: {
    color: EERIE_BLACK_COLOR,
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body2.lineHeight,
  }
}));

export default function DouActions(props: DouRegisterBatchProps) {
  const { status, sk } = props;
  const [openModal, setOpenModal] = useState(false);
  const [openCloseBatchModal, setOpenCloseBatchModal] = useState(false);
  const [loadingModalDou, setLoadingModalDou] = useState(false);
  const [textPublicationDou, setTextPublicationDou] = useState('');
  const dispatch = useDispatch();

  const batches = useSelector((state: RootState) => state.douFilter.batches);
  const [currentBatch, setCurrentBatch] = useState<Dou>({} as Dou);
  const selectedInstitution = useSelector((state: RootState) => state.header.institution);

  useEffect(() => {
    const selectedBatch = batches.filter((batch) => batch.sk === sk)[0];
    setCurrentBatch(selectedBatch);
  }, [sk, batches]);

  const handleClickInfo = async () => {
    let batch = batches.filter((f) => f.sk === sk)[0]
    const filterDiplomas = await DouService.getDiplomaInBatch(batch.institutionMecCode, batch.batchNumber, batch.type);
    const response = {
      ...batch,
      diplomas: filterDiplomas.data
    } as Dou
    dispatch(selectBatch(response));
    dispatch(toggleInfoBatch());
  };

  const getTemplateDou = async (requestTemplateDou: GetTemplateRequest) => {
    const responseTemplateDou = await DouTemplateManager.getDouTemplate(requestTemplateDou);

    return responseTemplateDou.template;
  };

  const toShortDate = (date: string) => moment(date).format('DD/MM/YYYY');
  const toLongDate = (date: string) => moment(date).format('DD [de] MMMM [de] YYYY');

  const getTemplateEta = async () => {
    const batchWithDiplomas = await DouService.getDiplomaInBatch(currentBatch.institutionMecCode, currentBatch.batchNumber, currentBatch.type);
    const institutionMecCode = currentBatch.institutionMecCode;
    const documentType = currentBatch.sk.split("#")[1] === DouBatchType.Register ? DocumentType.DouRegisterBatchTemplate : DocumentType.DouCancellationBatchTemplate;

    const batch = {
      ...currentBatch,
      diplomas: batchWithDiplomas.data
    } as Dou

    const registerBookList = `${BatchPaperManager(batch).getDiplomasList().join('; ')}; `;

    const data = {
      issuerInstitution: {
        name: selectedInstitution?.name,
        sponsor: {
          corporateName: selectedInstitution?.sponsor?.corporateName,
          cnpj: CnpjMask.apply(selectedInstitution?.sponsor?.cnpj),
        },
        validationUrl: selectedInstitution?.validationUrl,
        address: selectedInstitution?.address,
      },
      batch: batch,
      registerBookList,
      toShortDate,
      toLongDate,
    };

    const requestTemplateDou = {
      institutionMecCode,
      documentType,
    } as GetTemplateRequest;

    const template = await getTemplateDou(requestTemplateDou);
    const response = await DouTemplateManager.getTemplateEta({ template, data });

    return response.template;
  };

  function closeWithErrorState(): void {
    setOpenModal(false);
    dispatch(showNotification(errorNotificationMessages.failedOnRetrieveTextPublicationDouTemplate, notificationIcons.error));
  }

  const handleOpenModal = async () => {
    try {
      setOpenModal(true);
      setLoadingModalDou(true);
      const template = await getTemplateEta();

      if (template) {
        setTextPublicationDou(template);
        setLoadingModalDou(false);
      } else {
        closeWithErrorState();
      }
    } catch {
      closeWithErrorState();
    }
  };

  const handleCopyButton = () => {
    navigator.clipboard.writeText(textPublicationDou);
    dispatch(showSuccessNotification(successNotificationMessages.successCopyToClipboard, notificationIcons.success));
  };

  const handleToggleCloseBatchModal = async () => {
    let batch = batches.filter((f) => f.sk === sk)[0]
    const filterDiplomas = await DouService.getDiplomaInBatch(batch.institutionMecCode, batch.batchNumber, batch.type);
    const response = {
      ...batch,
      diplomas: filterDiplomas.data
    } as Dou
    setCurrentBatch(response);

    let hasDiplomas = response.diplomas?.length === 0 ? false : true;

    if (!hasDiplomas) {
      dispatch(showNotification(warningNotificationMessages.batchDoesNotHaveDiplomas, notificationIcons.error));
    }

    setOpenCloseBatchModal(status === DouRegisterBatchStatus.Opened && !(openCloseBatchModal) && hasDiplomas);
  };

  const updateDouTable = async () => {
    if (selectedInstitution?.mecCode) {
      dispatch(setLoadingBatches(true));
      dispatch(setBatches(selectedInstitution));
    } else {
      dispatch(showNotification(errorNotificationMessages.failedOnUpdateDouPage));
    }
  };

  return (
    <StyledGrid
      container
      justifyContent="flex-end"
      alignItems="center"
      spacing={3}
    >
      <Grid item>
        <CustomizedTooltip title="Informações" placement="top">
          <span>
            <IconButton onClick={handleClickInfo} size="large">
              <Info className={`${classes.icon} ${classes.activeIcon}`} color={DIPLOMA_COLOR} />
            </IconButton>
          </span>
        </CustomizedTooltip>
      </Grid>
      <Grid item>
        <CustomizedTooltip title="Ver Texto de Publicação" placement="top">
          <span className={status !== DouRegisterBatchStatus.Closed ? classes.disabled : ''}>
            <IconButton
              onClick={handleOpenModal}
              disabled={status !== DouRegisterBatchStatus.Closed}
              size="large">
              <FileText className={`${classes.icon} ${status === DouRegisterBatchStatus.Closed && classes.activeIcon}`} />
            </IconButton>
          </span>
        </CustomizedTooltip>
        <PublicationDouModal
          openModal={openModal}
          modalTitle="Texto de Publicação no DOU"
          textCopyButton="Copiar para Área de Transferência"
          handleCopyButton={handleCopyButton}
          onClose={() => setOpenModal(false)}
          loading={loadingModalDou}
          batchType={currentBatch.type}
        >
          <Typography className={classes.textDouModal}>
            {textPublicationDou}
          </Typography>
        </PublicationDouModal>
      </Grid>
      <Grid item>
        <CustomizedTooltip title="Fechar Lote" placement="top">
          <span className={status !== DouRegisterBatchStatus.Opened ? classes.disabled : ''}>
            <IconButton
              onClick={handleToggleCloseBatchModal}
              disabled={status !== DouRegisterBatchStatus.Opened}
              size="large">
              <X className={`${classes.icon} ${status === DouRegisterBatchStatus.Opened && classes.activeIcon}`} />
            </IconButton>
          </span>
        </CustomizedTooltip>
        {
          currentBatch?.sk && (
            <CloseBatchModal
              openModal={openCloseBatchModal}
              batch={currentBatch}
              onCloseModal={() => setOpenCloseBatchModal(false)}
              callbackAfterCloseBatch={updateDouTable}
            />
          )
        }
      </Grid>
    </StyledGrid>
  );
}
