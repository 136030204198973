import makeStyles from '@mui/styles/makeStyles';

import { FONT_FAMILY_BOLD, GRAY_COLOR, WHITE_COLOR } from '../../../../theme';
import { StylesProps } from './types';

export const useStagesTimelineStyles = makeStyles({
  label: (props: StylesProps) => ({
    fontFamily: FONT_FAMILY_BOLD,
    textAlign: 'center',
    fontSize: '0.825rem',
    lineHeight: '1rem',
    marginBottom: '0.25rem',
    color: props?.currentStepColor || GRAY_COLOR
  }),
  connector: {
    border: `0.03125rem solid ${GRAY_COLOR}`,
    background: GRAY_COLOR,
    width: '0.325rem',
    height: 0
  },
  dot: {
    width: '0.625rem',
    height: '0.625rem',
    border: `${GRAY_COLOR} 0.0625rem solid`,
    borderRadius: '50%',
    backgroundColor: WHITE_COLOR
  },
  activeDot: (props: StylesProps) => ({
    backgroundColor: props?.currentStepColor || GRAY_COLOR,
    border: `${props?.currentStepColor || GRAY_COLOR} 0.0625rem solid`
  })
});
