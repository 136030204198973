import {
  UsersManagementState,
  UsersManagementActions,
  INITIAL_STATE,
  SET_USERS_MANAGEMENT,
  SET_LOADING_USERS,
  SET_SEARCH,
  SET_MODAL_USERS
} from './types';

export default function reducer(
  state: UsersManagementState = INITIAL_STATE,
  action: UsersManagementActions
): UsersManagementState {
  switch (action.type) {
    case SET_USERS_MANAGEMENT:
      return {
        ...state,
        employees: action.payload.employees
      };
    case SET_LOADING_USERS:
      return {
        ...state,
        loadingEmployees: action.payload.loadingEmployees
      };
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload.search
      };
    case SET_MODAL_USERS:
      return {
        ...state,
        openUsersModal: action.payload.openUsersModal,
        typeSubmit: action.payload.typeSubmit
      };
    default:
      return state;
  }
}
