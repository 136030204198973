import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { ConnectedProps, connect, useDispatch } from 'react-redux';
import SendDiplomaToSignatureModal from '../../diploma-modal-signers/views/SendDiplomaToSignatureModal';
import AffiliationPaper from '../../../organisms/affiliation-paper';
import { RootState } from '../../../../../setup/reducer/reducers';
import { addChangeDiploma, updateComponentValidateActive } from '../../diploma-page/store/actions';
import AcademicDocGraduatePaper from '../../../organisms/academic-doc-graduate-paper';
import CurriculumPaper from '../../../organisms/curriculum-paper';
import AcademicDocFilesPaper from '../../../organisms/academic-doc-files-paper';
import DefaultTemplate from '../../../templates/default-template';
import DiplomaValidationButton from '../../../molecules/diploma-validation-button';
import { getIsCourtOrder } from '../../../../utils/functions';
import InstitutionProfile from '../../../../../domain/enum/InstitutionProfile';
import Signer from '../../../../../domain/model/diploma/Signer';
import IssuerInstitutionValidatesDiplomaRequest from '../../../../../domain/interface/request/IssuerInstitutionValidatesDiplomaRequest';
import ChangeType from '../../../../../domain/enum/diploma/ChangeType';
import BackdropLoading from '../../../molecules/backdrop-loading';
import Employee from '../../../../../domain/model/employee/Employee';
import SignerData from '../../../../../domain/model/institution/SignerData';

const PREFIX = 'AcademicDocumentation';

const classes = {
  root: `${PREFIX}-root`,
  fabGridRight: `${PREFIX}-fabGridRight`,
  fabGridLeft: `${PREFIX}-fabGridLeft`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    paddingBottom: theme.spacing(7)
  },

  [`& .${classes.fabGridRight}`]: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(4),
    maxWidth: '45%'
  },

  [`& .${classes.fabGridLeft}`]: {
    position: 'fixed',
    bottom: theme.spacing(3),
    left: theme.spacing(4),
    maxWidth: '45%'
  }
}));

const mapState = (state: RootState) => ({
  diploma: state.diplomaPage.diploma,
  academicDocumentation: state.diplomaPage.diploma?.academicDocumentation,
  validation: state.diplomaPage.validation,
  selectedInstitutionProfile: state.diplomaFilter.selectedInstitutionProfile,
  employees: state.selectDiplomaSigners.employees
});

const mapDispatch = {
  sendDiplomaToSignature: (
    request: IssuerInstitutionValidatesDiplomaRequest,
    diplomaId?: string,
    setLoading?: (isLoading: boolean) => void
  ) => addChangeDiploma(request, diplomaId, setLoading)
};

const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>


const AcademicDocumentation = (props: Props) => {
  const {
    diploma,
    academicDocumentation,
    validation,
    selectedInstitutionProfile,
    employees,
    sendDiplomaToSignature
  } = props;

  const dispatch = useDispatch();

  const [openSendDiplomaToSignatureModal, setOpenSendDiplomaToSignatureModal] =
    useState(false);

  const [isLoadingSignersAction, setLoadingSignersAction] = useState(false);

  let signers: Signer[];

  function returnRequest() {
    let issuerInstitutionValidatesDiplomaRequest = {
      issuerInstitution: {
        ...diploma?.issuerInstitution,
        signers
      },
      type: ChangeType.IssuerInstitutionValidatedDiploma
    } as IssuerInstitutionValidatesDiplomaRequest

    if (diploma?.isCourtOrder && diploma?.courtOrder) {
      issuerInstitutionValidatesDiplomaRequest.courtOrder = diploma.courtOrder
    }

    return issuerInstitutionValidatesDiplomaRequest;
  }

  const handleSendDiplomaToSignature = async () => {
    if (selectedInstitutionProfile === InstitutionProfile.Issuer) {
      let selectedFirstEmployee: Employee | undefined;
      let selectedSecondEmployee: Employee | undefined;
      let firstSigner: SignerData | undefined;
      let secondSigner: SignerData | undefined;

      firstSigner = hasDefaultSigners?.find((signer) => signer.signFor === 'issuer' && signer.signerPosition === 1);
      secondSigner = hasDefaultSigners?.find((signer) => signer.signFor === 'issuer' && signer.signerPosition === 2);

      selectedFirstEmployee = employees?.find((employee) => employee.login === firstSigner?.login);
      selectedSecondEmployee = employees?.find((employee) => employee.login === secondSigner?.login);

      signers = [
        {
          ...selectedFirstEmployee,
          order: 1
        } as Signer,
        {
          ...selectedSecondEmployee,
          order: 2
        } as Signer
      ];

      const request: IssuerInstitutionValidatesDiplomaRequest = returnRequest()
      sendDiplomaToSignature(request, diploma?.id, setLoadingSignersAction);
    } else {
      setOpenSendDiplomaToSignatureModal(true);
    }
  };

  const nextPage = () => {
    dispatch(updateComponentValidateActive("CourtOrder"));
  }

  const returnPage = () => {
    dispatch(updateComponentValidateActive("DiplomaValidate"));
  };

  const isCourtOrder = getIsCourtOrder(diploma);
  const hasDefaultSigners = diploma?.defaultSigners


  const getPageAction = (): JSX.Element => {
    if (isCourtOrder && selectedInstitutionProfile === InstitutionProfile.Issuer) {
      return (
        <DiplomaValidationButton
          handleClick={nextPage}
          text="Próximo"
          buttonType="primary"
        />
      );
    }
    if ((!hasDefaultSigners || hasDefaultSigners?.length < 4) && selectedInstitutionProfile === InstitutionProfile.Issuer) {
      return (
        <DiplomaValidationButton
          validation={"invalidDefaultSigners"}
          text="Enviar para assinatura"
          buttonType="success"
        />
      );
    }

    return (
      <>
        <DiplomaValidationButton
          handleClick={handleSendDiplomaToSignature}
          validation={validation}
          text="Enviar para assinatura"
          buttonType="success"
        />
        <SendDiplomaToSignatureModal
          openModal={openSendDiplomaToSignatureModal}
          setOpenModal={setOpenSendDiplomaToSignatureModal}
        />
      </>
    );
  };

  return (
    <Root>
      <DefaultTemplate
        message="Validação de"
        highlightedMessage="Documentação Acadêmica"
      >
        {isLoadingSignersAction && (
          <BackdropLoading
            size={50}
            description='Enviando Para Assinatura...'
            open={isLoadingSignersAction}
            classes={classes}
          />
        )}
        <Grid container spacing={2} className={classes.root}>
          <Grid item md={12}>
            <AffiliationPaper
              affiliation={academicDocumentation?.affiliation || undefined}
              onClickBack={returnPage}
              validation={
                validation?.academicDocumentation?.affiliation || undefined
              }
              processNumber={diploma?.processNumber}
              originSystem={diploma?.originSystem}
            />
          </Grid>
          <Grid item md={12}>
            <AcademicDocGraduatePaper
              academicDocumentation={academicDocumentation || undefined}
              validation={validation?.academicDocumentation || undefined}
              processNumber={diploma?.processNumber}
              originSystem={diploma?.originSystem}
            />
          </Grid>
          <Grid item md={12}>
            <CurriculumPaper
              transcript={academicDocumentation?.transcript || undefined}
              validation={
                validation?.academicDocumentation?.transcript || undefined
              }
              processNumber={diploma?.processNumber}
              originSystem={diploma?.originSystem}
            />
          </Grid>
          <Grid item md={12}>
            <AcademicDocFilesPaper />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={3}
          className={classes.fabGridRight}
        >
          {getPageAction()}
        </Grid>
        <SendDiplomaToSignatureModal
          openModal={openSendDiplomaToSignatureModal}
          setOpenModal={setOpenSendDiplomaToSignatureModal}
        />
      </DefaultTemplate>
    </Root>
  );
};

export default connector(AcademicDocumentation);
