import {
  NotificationActionTypes, NotificationState, NOTIFY_ERROR, NOTIFY_SUCCESS,
} from './types';

const INITIAL_STATE: NotificationState = {
  errorMessage: null,
  successMessage: null,
  successIcon: null,
  errorIcon: null,
};

export default function reducer(state: NotificationState = INITIAL_STATE, action: NotificationActionTypes): NotificationState {
  switch (action.type) {
    case NOTIFY_ERROR:
      return {
        ...state,
        errorMessage: action.payload.message,
        errorIcon: action.payload.icon,
      };

    case NOTIFY_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.message,
        successIcon: action.payload.icon,
      };

    default:
      return state;
  }
}
