import React from 'react';
import BaseModal from '../../../molecules/base-modal';
import BackdropLoading from '../../../molecules/backdrop-loading';
import { Button, IconButton, styled, Typography } from '@mui/material';
import CustomDatePicker from '../../../atoms/form/CustomDatePicker';
import moment, { Moment } from 'moment';
import useAdvancedFilterModalStyles from '../../../organisms/advanced-filter-modal/styles';
import SelectAutocomplete from '../../../molecules/select-autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import InspectionDocumentContext from '../../../../../domain/enum/inspectionDocument/InspectionDocumentContext';
import InspectionDocumentService from '../../../../../services/InspectionDocumentService';
import InspectionDocumentResponse from '../../../../../domain/interface/response/InspectionDocumentResponse';
import GenerateInspectionDocumentFeedback from '../../../organisms/generate-inspection-document-feedback/GenerateInspectionDocumentFeedback';

const PREFIX = 'GenerateInspectionDocumentModal';

const classes = {
    contentContainer: `${PREFIX}-contentContainer`,
    closeButton: `${PREFIX}-closeButton`,
    description: `${PREFIX}-description`,
    datesContainer: `${PREFIX}-datesContainer`,
    contextLabel: `${PREFIX}-contextLabel`,
    button: `${PREFIX}-button`
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.contentContainer}`]: {
        margin: '0px',
        width: '382px'
    },
    [`& .${classes.closeButton}`]: {
        position: 'absolute',
        right: 0,
        top: 0,
        color: theme.palette.grey[500],
    },
    [`& .${classes.description}`]: {
        marginTop: '16px',
        marginBottom: '32px'
    },
    [`& .${classes.datesContainer}`]: {
        display: 'flex',
        flexDirection: 'row',
        gap: '16px'
    },
    [`& .${classes.contextLabel}`]: {
        marginTop: '16px'
    },
    [`& .${classes.button}`]: {
        marginTop: '16px'
    }
}));

type GenerateInspectionDocumentModalProps = {
    isOpen: boolean;
    institutionId: number;
    onClose: () => void;
};

const GenerateInspectionDocumentModal = ({ isOpen, institutionId, onClose }: GenerateInspectionDocumentModalProps) => {
    const styles = useAdvancedFilterModalStyles();
    const [isLoading, setIsLoading] = React.useState(false);
    const [startDate, setStartDate] = React.useState<Moment | null>(null);
    const [endDate, setEndDate] = React.useState<Moment | null>(null);
    const [context, setContext] = React.useState<InspectionDocumentContext>();
    const [documents, setDocuments] = React.useState<InspectionDocumentResponse[]>([]);

    const service = new InspectionDocumentService();

    const clearState = () => {
        setIsLoading(false);
        setStartDate(null);
        setEndDate(null);
        setContext(undefined);
    }

    const clearStateAndClose = () => {
        clearState();
        setDocuments([]);
        onClose();
    }

    const onSubmit = () => {
        setIsLoading(true);
        service.createInspectionDocument(institutionId, startDate!, endDate!, context!)
            .then((response) => {
                setDocuments([response, ...documents]);
                clearState();
            })
            .finally(() => setIsLoading(false));
    }

    return (
        <BaseModal
            onClose={clearStateAndClose}
            openModal={isOpen}
            containerContentStyle={classes.contentContainer} >
            <Root>
                <div className={classes.contentContainer}>
                    <Typography variant="h3">
                        Gerar Arquivo de Fiscalização
                    </Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={clearStateAndClose}
                        size="large">
                        <CloseIcon />
                    </IconButton>
                    <Typography className={classes.description}>
                        Selecione o período e o contexto para a geração do arquivo.
                    </Typography>
                    <div className={classes.datesContainer}>
                        <CustomDatePicker
                            value={startDate ? startDate.toString() : null}
                            maxDate={endDate?.clone().subtract(1, 'days') ?? moment().subtract(1, 'days')}
                            minDate={moment("2022-01-01")}
                            inputLabel="Data Inicial"
                            inputError={false}
                            onChange={(date: Date) => setStartDate(moment(date))}
                        />
                        <CustomDatePicker
                            value={endDate ? endDate.toString() : null}
                            minDate={startDate?.clone().add(1, 'days')}
                            inputLabel="Data Final"
                            inputError={false}
                            onChange={(date: Date) => setEndDate(moment(date))}
                        />
                    </div>
                    <SelectAutocomplete
                        compareName
                        defaultValue={context}
                        classes={{ labelAutoComplete: classes.contextLabel }}
                        label="Contexto"
                        placeholder="Selecionar"
                        listItems={[
                            InspectionDocumentContext.Issuer,
                            InspectionDocumentContext.Register
                        ]}
                        setItem={setContext}
                        data-testid="select-ies-type"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        disabled={!startDate || !endDate || !context}
                        className={`${styles.button} ${classes.button}`}
                        onClick={onSubmit}
                    >
                        Gerar Arquivo de Fiscalização
                    </Button>
                    {
                        documents.map((document) => {
                            return (
                                <GenerateInspectionDocumentFeedback document={document} key={document.documentCode} />
                            );
                        })
                    }
                    {isLoading && (
                        <BackdropLoading
                            size={50}
                            description='Gerando arquivo...'
                            open={isLoading}
                            classes={classes}
                        />
                    )}
                </div>
            </Root>
        </BaseModal>
    );
}

export default GenerateInspectionDocumentModal;
