enum TypePermission {
  DiplomaTrackingRead = 'diplomas.ui.DiplomaTracking.read',
  DiplomaTrackingUpdate = 'diplomas.ui.DiplomaTracking.update',
  DiplomaTrackingCreate = 'diplomas.ui.DiplomaTracking.create',
  DiplomaTrackingDelete = 'diplomas.ui.DiplomaTracking.delete',
  BatchControlDouRead = 'diplomas.ui.BatchControlForPublicationInTheDOU.read',
  BatchControlDouUpdate = 'diplomas.ui.BatchControlForPublicationInTheDOU.update',
  BatchControlDouCreate = 'diplomas.ui.BatchControlForPublicationInTheDOU.create',
  BatchControlDouDelete = 'diplomas.ui.BatchControlForPublicationInTheDOU.delete',
  InstitutionManagementRead = 'diplomas.ui.InstitutionManagement.read',
  InstitutionManagementUpdate = 'diplomas.ui.InstitutionManagement.update',
  InstitutionManagementCreate = 'diplomas.ui.InstitutionManagement.create',
  InstitutionManagementDelete = 'diplomas.ui.InstitutionManagement.delete',
  UsersRegistrationRead = 'diplomas.ui.UserRegistration.read',
  UsersRegistrationUpdate = 'diplomas.ui.UserRegistration.update',
  UsersRegistrationCreate = 'diplomas.ui.UserRegistration.create',
  UsersRegistrationDelete = 'diplomas.ui.UserRegistration.delete',
  MicrocertificatesRead = 'diplomas.ui.Microcertificates.read',
  MicrocertificatesUpdate = 'diplomas.ui.Microcertificates.update',
  MicrocertificatesCreate = 'diplomas.ui.Microcertificates.create',
  MicrocertificatesDelete = 'diplomas.ui.Microcertificates.delete',
  SignDocumentsRead = 'diplomas.ui.SignDocuments.read',
  SignDocumentsUpdate = 'diplomas.ui.SignDocuments.update',
  SignDocumentsCreate = 'diplomas.ui.SignDocuments.create',
  SignDocumentsDelete = 'diplomas.ui.SignDocuments.delete',
  DigitalCurriculumRead = 'diplomas.ui.DigitalCurriculum.read',
  DigitalCurriculumUpdate = 'diplomas.ui.DigitalCurriculum.update',
  DigitalCurriculumCreate = 'diplomas.ui.DigitalCurriculum.create',
  DigitalCurriculumDelete = 'diplomas.ui.DigitalCurriculum.delete'
}

export default TypePermission;
