import { ThunkAction } from 'redux-thunk';
import { Action, Dispatch } from 'redux';
import DiplomaService from '../../../../../services/DiplomaService';
import {
  errorNotificationMessages,
  notificationIcons,
  successNotificationMessages
} from '../../../../utils/constants';
import {
  SELECT_DIPLOMA,
  DiplomaPageValidateActions,
  TOGGLE_NEXT_PAGE,
  TOGGLE_IS_LOADING_DIPLOMA,
  TOGGLE_INFO_DIPLOMA,
  UPDATE_COMPONENT_VALIDATE_ACTIVE
} from './types';
import Diploma from '../../../../../domain/model/diploma/Diploma';
import { RootState } from '../../../../../setup/reducer/reducers';
import {
  showNotification,
  showSuccessNotification
} from '../../notification/actions';
import Institution from '../../../../../domain/model/institution/Institution';
import DiplomaInstitutionService from '../../../../../services/DiplomaInstitutionService';
import InstitutionRegulatoryAct from '../../../../../domain/model/institution/InstitutionRegulatoryAct';
import InstitutionRegulatoryActType from '../../../../../domain/enum/InstitutionRegulatoryActType';
import { AppThunk } from '../../../../../setup/reducer';
import RegisterInstitutionRefusesDiplomaRequest from '../../../../../domain/interface/request/RegisterInstitutionRefusesDiplomaRequest';
import RegisterInstitutionValidatesDiplomaRequest from '../../../../../domain/interface/request/RegisterInstitutionValidatesDiplomaRequest';
import IssuerInstitutionValidatesDiplomaRequest from '../../../../../domain/interface/request/IssuerInstitutionValidatesDiplomaRequest';
import ChangeDiplomaRequest from '../../../../../domain/interface/request/ChangeDiplomaRequest';
import DiplomaStatus from '../../../../../domain/enum/diploma/DiplomaStatus';

const internalSelectDiploma = (
  diploma?: Diploma,
  validation?: any
): DiplomaPageValidateActions => ({
  type: SELECT_DIPLOMA,
  payload: {
    diploma,
    validation
  }
});

export const toggleInfoDiploma = (): DiplomaPageValidateActions => ({
  type: TOGGLE_INFO_DIPLOMA
});

export const toggleNextPage = (): DiplomaPageValidateActions => ({
  type: TOGGLE_NEXT_PAGE
});

export const updateComponentValidateActive = (
  componentValidateActive: string
): DiplomaPageValidateActions => ({
  type: UPDATE_COMPONENT_VALIDATE_ACTIVE,
  payload: {
    componentValidateActive
  }
});

const sortInstitutionRegulatoryActByDateDescending = (
  a: InstitutionRegulatoryAct,
  b: InstitutionRegulatoryAct
): number => {
  if ((!a || !a.date) && (!b || !b.date)) {
    return 0;
  }
  if (!a || !a.date) {
    return 1;
  }
  if (!b || !b.date) {
    return -1;
  }
  if (a.date > b.date) {
    return -1;
  }
  if (a.date < b.date) {
    return 1;
  }
  return 0;
};

const selectNewestIesRegulatoryActByType = (
  iesRegulatoryActs: InstitutionRegulatoryAct[],
  type: InstitutionRegulatoryActType
): InstitutionRegulatoryAct | undefined =>
  iesRegulatoryActs
    .filter((r) => r.type === type)
    .sort(sortInstitutionRegulatoryActByDateDescending)
    .shift();

export const toggleIsLoadingDiploma = (): DiplomaPageValidateActions => ({
  type: TOGGLE_IS_LOADING_DIPLOMA
});

export const selectDiploma =
  (diploma?: Diploma): ThunkAction<void, RootState, unknown, Action<Diploma>> =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    if (!diploma) {
      dispatch(internalSelectDiploma());
      return;
    }

    const selectedInstitution: Institution | undefined =
      getState().header.institution;

    if (!selectedInstitution) {
      dispatch(
        showNotification(
          errorNotificationMessages.thereMustBeSelectedIesToProceed,
          notificationIcons.error
        )
      );
      return;
    }

    const { info } = getState().diplomaPage;

    let selectedDiploma: Diploma;

    dispatch(toggleIsLoadingDiploma());

    try {
      selectedDiploma = (await DiplomaService.getDiploma({ id: diploma.id }))
        .data;
    } catch {
      dispatch(
        showNotification(
          errorNotificationMessages.failedToRetrieveDiploma,
          notificationIcons.error
        )
      );
      dispatch(toggleIsLoadingDiploma());
      return;
    }

    if (diploma.status !== DiplomaStatus.IssuerConfirmationPending) {
      let regulatoryActs: InstitutionRegulatoryAct[] | undefined;

      try {
        regulatoryActs = (
          await DiplomaInstitutionService.listRegulatoryAct(
            selectedInstitution.mecCode
          )
        ).data;
      } catch {
        dispatch(
          showNotification(
            errorNotificationMessages.failedToRetrieveIesRegulatoryActs,
            notificationIcons.error
          )
        );
      }

      selectedDiploma.registerInstitution = JSON.parse(
        JSON.stringify(selectedInstitution)
      );

      if (regulatoryActs && selectedDiploma.registerInstitution) {
        selectedDiploma.registerInstitution.licensing =
          selectNewestIesRegulatoryActByType(
            regulatoryActs,
            InstitutionRegulatoryActType.Licensing
          );
        selectedDiploma.registerInstitution.relicensing =
          selectNewestIesRegulatoryActByType(
            regulatoryActs,
            InstitutionRegulatoryActType.Relicensing
          );
        selectedDiploma.registerInstitution.relicensingRenewal =
          selectNewestIesRegulatoryActByType(
            regulatoryActs,
            InstitutionRegulatoryActType.RelicensingRenewal
          );
      }
    } else if (
      selectedDiploma.issuerInstitution?.mecCode !== selectedInstitution.mecCode
    ) {
      let regulatoryActs: InstitutionRegulatoryAct[] | undefined;

      try {
        regulatoryActs = (
          await DiplomaInstitutionService.listRegulatoryAct(
            selectedInstitution.mecCode
          )
        ).data;
      } catch {
        dispatch(
          showNotification(
            errorNotificationMessages.failedToRetrieveIesRegulatoryActs,
            notificationIcons.error
          )
        );
      }

      selectedDiploma.issuerInstitution = JSON.parse(
        JSON.stringify(selectedInstitution)
      );

      if (regulatoryActs && selectedDiploma.issuerInstitution) {
        selectedDiploma.issuerInstitution.licensing =
          selectNewestIesRegulatoryActByType(
            regulatoryActs,
            InstitutionRegulatoryActType.Licensing
          );
        selectedDiploma.issuerInstitution.relicensing =
          selectNewestIesRegulatoryActByType(
            regulatoryActs,
            InstitutionRegulatoryActType.Relicensing
          );
        selectedDiploma.issuerInstitution.relicensingRenewal =
          selectNewestIesRegulatoryActByType(
            regulatoryActs,
            InstitutionRegulatoryActType.RelicensingRenewal
          );
      }
    }

    let validation: any;

    if (!info) {
      try {
        validation = (await DiplomaService.validateDiploma(selectedDiploma))
          .data;
      } catch {
        dispatch(
          showNotification(
            errorNotificationMessages.failedToValidateDiploma,
            notificationIcons.error
          )
        );
        dispatch(internalSelectDiploma(selectedDiploma));
        dispatch(toggleIsLoadingDiploma());
        return;
      }
    }

    dispatch(internalSelectDiploma(selectedDiploma, validation));
    dispatch(toggleIsLoadingDiploma());
  };

export const updateDiploma =
  (diploma?: Diploma): ThunkAction<void, RootState, unknown, Action<Diploma>> =>
  async (dispatch: Dispatch) => {
    let validation: any;

    if (!diploma) {
      dispatch(internalSelectDiploma());
      return;
    }

    const selectedDiploma: Diploma = JSON.parse(
      JSON.stringify(diploma, (_key, value) => value || undefined)
    );
    dispatch(toggleIsLoadingDiploma());
    dispatch(internalSelectDiploma(selectedDiploma));

    try {
      validation = (await DiplomaService.validateDiploma(selectedDiploma)).data;
    } catch {
      dispatch(
        showNotification(
          errorNotificationMessages.failedToValidateDiploma,
          notificationIcons.error
        )
      );
      dispatch(internalSelectDiploma(selectedDiploma));
      return;
    }

    dispatch(internalSelectDiploma(selectedDiploma, validation));
    dispatch(toggleIsLoadingDiploma());
  };

export const addChangeDiploma =
  (
    request: ChangeDiplomaRequest,
    id?: string,
    setLoading?: (isLoading: boolean) => void
  ): AppThunk =>
  async (dispatch, getState: () => RootState) => {
    let successMessage: string;
    let errorMessage: string;

    const isNextPage: boolean = getState().diplomaPage.nextPage;

    switch (request.constructor) {
      case RegisterInstitutionRefusesDiplomaRequest:
        successMessage =
          successNotificationMessages.successToSendDiplomaToSignature;
        errorMessage = errorNotificationMessages.failedToSendDiplomaToSignature;
        break;
      case RegisterInstitutionValidatesDiplomaRequest:
        successMessage = successNotificationMessages.successToRefuseDiploma;
        errorMessage = errorNotificationMessages.failedToRefuseDiploma;
        break;
      case IssuerInstitutionValidatesDiplomaRequest:
        successMessage =
          successNotificationMessages.successToSendDiplomaToSignature;
        errorMessage = errorNotificationMessages.defaultError;
        break;
      default:
        successMessage = successNotificationMessages.defaultSuccess;
        errorMessage = errorNotificationMessages.defaultError;
        break;
    }

    try {
      if (setLoading) {
        setLoading(true);
      }

      await DiplomaService.addChangeDiploma(request, id);
      dispatch(
        showSuccessNotification(successMessage, notificationIcons.success)
      );
      dispatch(selectDiploma());

      if (isNextPage) {
        dispatch(toggleNextPage());
      }
    } catch (e) {
      dispatch(showNotification(errorMessage, notificationIcons.error));
    } finally {
      if (setLoading) {
        setLoading(false);
      }
    }
  };
