import ReasonType from '../../../../domain/enum/ReasonType';
import ChangeHistory from '../../../../domain/model/ChangeHistory';
import { getLongDateFormat } from '../../../utils/functions';
import CardCollapse from '../../molecules/card-collapse';
import TypographyEmphasis from '../../molecules/typography-emphasis';
import ChangesTimeline from '../changes-timeline';
import ChangeHistoryManager from '../changes-timeline/HistoryManager';
import { ChangesPaperProps } from './types';

const {
  isDiplomaTemporaryCancel,
  isCompleted,
  getChangeTypeColor,
  isAnyCancelChangeType,
  isAnyActivateDiplomaChangeType,
  getOnlyChangesAfterDiplomaConclusionSortted
} = ChangeHistoryManager;

function ChangesPaper({ history }: ChangesPaperProps) {
  const changes = history
    ? getOnlyChangesAfterDiplomaConclusionSortted(history)
    : [];
  const hasChanges = () => changes.length > 0;
  const hasManyChanges = () => changes.length > 1;

  const getChangeStatusLabel = (change: ChangeHistory) => {
    if (isCompleted(change) || isAnyActivateDiplomaChangeType(change)) {
      return 'concluído';
    }

    if (isDiplomaTemporaryCancel(change)) {
      return 'anulado temporariamente';
    }

    return 'anulado';
  };

  const getCancelReasonType = (change: ChangeHistory) => {
    if (isAnyCancelChangeType(change)) {
      if (change.reasonType === ReasonType.CraftError) {
        return 'erro de ofício';
      }

      if (change.reasonType === ReasonType.FactualError) {
        return 'erro de fato';
      }
    }

    return '';
  };

  const renderHeader = () => {
    const change = changes[0];
    const cancelReasonType = getCancelReasonType(change);
    const statusLabel = getChangeStatusLabel(change);
    const textColor = getChangeTypeColor(change);

    return (
      <TypographyEmphasis
        emphasisColor={textColor}
        variant="body2"
        data-testid="changes-paper-header"
      >
        {'Este diploma foi '}
        <strong>{statusLabel}</strong>
        {isDiplomaTemporaryCancel(change) && '. Anulado'}
        {` às ${getLongDateFormat(change.date)}`}
        {cancelReasonType && (
          <>
            {' devido a um '}
            <strong>{cancelReasonType}</strong>
          </>
        )}
        {'. '}
      </TypographyEmphasis>
    );
  };

  return (
    <>
      {hasChanges() && (
        <CardCollapse
          header={renderHeader()}
          showMoreLabel="Visualizar Histórico"
          showLessLabel="Visualizar Histórico"
        >
          {hasManyChanges() && <ChangesTimeline history={changes} />}
        </CardCollapse>
      )}
    </>
  );
}

ChangesPaper.defaultProps = {
  productType: undefined
};

export default ChangesPaper;
