import { Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import EnhancedTableHead from "../../organisms/enhanced-table-head";
import { HeadCell, Order } from "../../organisms/enhanced-table-head/types";
import { CurriculumTableProps } from "./types";
import { styled } from '@mui/material/styles';
import TypographyWithError from "../typography-with-error";
import { formatPrerequisites, handleRequestSort, orderList } from "../../../utils/functions/curriculum";
import { useState } from "react";

const PREFIX = 'CurricularUnitiesE2STable';

const classes = {
  e2sTableTopCell: `${PREFIX}-e2sTableTopCell`,
  e2sTableBottomCell: `${PREFIX}-e2sTableBottomCell`,
  activeSortIcon: `${PREFIX}-activeSortIcon`,
  inactiveSortIcon: `${PREFIX}-inactiveSortIcon`
};

const StyledDiv = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.e2sTableTopCell}`]: {
    borderBottom: 'none',
    paddingBottom: 0
  },
  [`& .${classes.e2sTableBottomCell}`]: {
    paddingTop: 0
  },
  [`& .${classes.activeSortIcon}`]: {
    fill: theme.palette.primary.main,
    width: 24,
    height: 24,
  },
  [`& .${classes.inactiveSortIcon}`]: {
    width: 24,
    height: 24,
  }
}));

const pageSize = 10;
const errorMessage = "Não deve ser nulo";

const E2sCurriculumTable = ({ curriculum, pageStartIndex, curricularUnityAndValidation }: CurriculumTableProps) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('name');

  const curriculumHasPrerequisites = !!curriculum?.curricularUnities.find(curriculum => !!curriculum.prerequisites);

  const headCells = [
    {
      id: 'type',
      label: 'Tipo',
      size: '15%',
      align: 'left',
      sortable: true,
      styles: {}
    },
    {
      id: 'code',
      label: 'Código',
      size: '15%',
      align: 'left',
      sortable: true,
      styles: {}
    },
    {
      id: 'name',
      label: 'Unidade Curricular',
      size: '35%',
      align: 'left',
      sortable: true,
      styles: {}
    },
    curriculumHasPrerequisites &&
    {
      id: 'prerequisites',
      label: 'Pré-requisitos',
      size: '20%',
      align: 'left',
      sortable: true,
      styles: {}
    },
    {
      id: 'load',
      label: 'CH relógio',
      size: '15%',
      align: 'right',
      sortable: true,
      styles: {}
    }
  ];

  const TableRows = () => {

    if (curricularUnityAndValidation.length > 0) {
      return orderList(curricularUnityAndValidation, order, orderBy).slice(pageStartIndex, pageStartIndex + pageSize).map((curricularUnity) => {
        return (
          <>
            <TableRow
              key={`${curricularUnity?.code}-top`}
            >
              <TableCell component='th' scope='row' className={classes.e2sTableTopCell}>
                <TypographyWithError
                  value={curricularUnity?.type || 'N/A'}
                  errors={curricularUnity?.validation?.type} />
              </TableCell>
              <TableCell className={classes.e2sTableTopCell}>
                <TypographyWithError
                  value={curricularUnity?.code || 'N/A'}
                  errors={curricularUnity?.validation?.code} />
              </TableCell>
              <TableCell className={classes.e2sTableTopCell} colSpan={curriculumHasPrerequisites ? 1 : 2}>
                <TypographyWithError
                  value={curricularUnity?.name || 'N/A'}
                  errors={curricularUnity?.validation?.name} />
              </TableCell>
              {curriculumHasPrerequisites && <TableCell className={classes.e2sTableTopCell}>{formatPrerequisites(curricularUnity)}</TableCell>}
              <TableCell align='right' className={classes.e2sTableTopCell}>
                <TypographyWithError
                  value={!!curricularUnity?.curricularUnitClockTime?.load ? `${curricularUnity.curricularUnitClockTime.load}h` : 'N/A'}
                  errors={curricularUnity?.validation?.curricularUnitClockTime?.load} />
              </TableCell>
            </TableRow>
            <TableRow
              key={`${curricularUnity.code}-bottom`}
            >
              <TableCell colSpan={2} className={classes.e2sTableBottomCell} />
              <TableCell colSpan={curriculumHasPrerequisites ? 4 : 3} className={classes.e2sTableBottomCell}>
                <Typography variant='body1'>Ementa</Typography>
                <TypographyWithError
                  value={!!curricularUnity?.program && curricularUnity?.program?.length ? curricularUnity?.program?.join(', ') : 'N/A'}
                  errors={curricularUnity?.validation?.program} />
              </TableCell>
            </TableRow>
          </>
        );
      });
    } else {
      return (
        <>
          <TableRow>
            <TableCell component='th' scope='row' className={classes.e2sTableTopCell}>
              <TypographyWithError
                value={'N/A'}
                errors={errorMessage} />
            </TableCell>
            <TableCell className={classes.e2sTableTopCell}>
              <TypographyWithError
                value={'N/A'}
                errors={errorMessage} />
            </TableCell>
            <TableCell className={classes.e2sTableTopCell}>
              <TypographyWithError
                value={'N/A'}
                errors={errorMessage} />
            </TableCell>
            <TableCell align='right' className={classes.e2sTableTopCell}>
              <TypographyWithError
                value={'N/A'}
                errors={errorMessage} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} className={classes.e2sTableBottomCell} />
            <TableCell colSpan={curriculumHasPrerequisites ? 3 : 2} className={classes.e2sTableBottomCell}>
              <Typography variant='body1'>Ementa</Typography>
              <TypographyWithError
                value={'N/A'}
                errors={errorMessage} />
            </TableCell>
          </TableRow>
        </>
      );
    }
  };

  return (
    <StyledDiv>
      <Table>
        <colgroup>
          <col style={{ width: '15%' }} />
          <col style={{ width: '5%' }} />
        </colgroup>
        <EnhancedTableHead
          classes={{ activeSortIcon: classes.activeSortIcon, inactiveSortIcon: classes.inactiveSortIcon }}
          onRequestSort={(_, property) => handleRequestSort(property, orderBy, order, setOrder, setOrderBy)}
          order={order}
          orderBy={orderBy}
          headCells={headCells as HeadCell[]}
        />
        <TableBody>
          {
            curriculum && TableRows()
          }
        </TableBody>
      </Table>
    </StyledDiv>
  );
}
export default E2sCurriculumTable;
