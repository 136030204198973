import { AxiosResponse } from 'axios';
import getAxiosInstance from '../apis/axiosInstance';
import CloseBatchRequest from '../domain/interface/request/CloseBatchRequest';
import { DiplomaBatch } from '../domain/model/diploma/DiplomaBatch';
import Batch from '../domain/model/dou/Batch';
import Dou from '../domain/model/dou/Dou';

const axiosInstance = getAxiosInstance(
  process.env.REACT_APP_DIPLOMA_DOU_API || ''
);

class DouService {
  async getBatch(batchId: string): Promise<AxiosResponse<Dou>> {
    return axiosInstance.get(`dou-batch/${batchId}/get`);
  }

  async getDiplomaInBatch(
    mecCode: number,
    batchNumber: number,
    batchType: string
  ): Promise<AxiosResponse<DiplomaBatch[]>> {
    return axiosInstance.get(
      `dou-batch/list-diploma?institutionMecCode=${mecCode}&batchNumber=${batchNumber}&batchType=${batchType}`
    );
  }

  async updateBatch(
    institutionMecCode: number,
    batchNumber: number,
    type: string,
    request: any
  ): Promise<AxiosResponse<Batch>> {
    return axiosInstance.put(
      `dou-newBatch/updateBatch?institutionMecCode=${institutionMecCode}&batchNumber=${batchNumber}&batchType=${type}`,
      request
    );
  }

  async listBatches(mecCode: number): Promise<AxiosResponse<Batch[]>> {
    return axiosInstance.get(`dou-batch?institutionMecCode=${mecCode}`);
  }

  async listNewBatches(mecCode: number): Promise<AxiosResponse<Dou[]>> {
    return axiosInstance.get(`dou-newBatch?institutionMecCode=${mecCode}`);
  }

  async closeBatch(
    institutionMecCode: number,
    request: CloseBatchRequest
  ): Promise<AxiosResponse<Batch[]>> {
    return axiosInstance.put(
      `dou-batch/${institutionMecCode}/newclose`,
      request
    );
  }
}

export default new DouService();
