import Institution from '../../../../../domain/model/institution/Institution';
import {
  HeaderActions,
  HeaderState,
  INITIAL_STATE,
  SET_INSTITUTION,
  SET_INSTITUTIONS
} from './types';

const verifyIntitutionLocal = (data?: Institution): Institution | undefined => {
  let institutionLocalStorage = JSON.parse(
    localStorage.getItem('institution_local') || '{}'
  );

  if (
    JSON.stringify(institutionLocalStorage) === null ||
    JSON.stringify(institutionLocalStorage) === '{}'
  ) {
    localStorage.setItem('institution_local', JSON.stringify(data));
    institutionLocalStorage = data;
  }
  return institutionLocalStorage;
};

export default function reducer(
  state: HeaderState = INITIAL_STATE,
  action: HeaderActions
): HeaderState {
  switch (action.type) {
    case SET_INSTITUTION:
      return {
        ...state,
        institution: verifyIntitutionLocal(action.payload.institution)
      };
    case SET_INSTITUTIONS:
      return {
        ...state,
        institutions: action.payload.institutions
      };
    default:
      return state;
  }
}
