import Root from '../../../../domain/model/curriculum/Root';
import CurriculumService from '../../../../services/CurriculumService';
import { HeadCell, Order } from './types';

export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

export const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key
): ((
  a: { [key in Key]: number | string | string[] },
  b: { [key in Key]: number | string | string[] }
) => number) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

export const stableSort = <T>(
  array: T[],
  comparator: (a: T, b: T) => number
) => {
  if (array.length === 0) {
    return array;
  }
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

export const headCells: HeadCell[] = [
  {
    id: 'checkbox',
    label: '',
    size: '1%',
    align: 'left'
  },
  {
    id: 'courseName',
    label: 'Curso',
    size: '21%',
    align: 'left',
    sortable: true
  },
  {
    id: 'originSystem',
    label: 'Origem',
    size: '10%',
    align: 'left',
    sortable: false
  },
  {
    id: 'campusEmecCode',
    label: 'Cód. EMEC',
    size: '2%',
    align: 'left',
    sortable: false
  },
  {
    id: 'campusName',
    label: 'Unidade',
    size: '15%',
    align: 'left',
    sortable: false
  },
  {
    id: 'curriculumDescription',
    label: 'Descrição da grade curricular',
    size: '2%',
    align: 'left',
    sortable: false
  },
  {
    id: 'scheduleCode',
    label: 'Grade Curricular',
    size: '17%',
    align: 'left',
    sortable: false
  },
  {
    id: 'updatedAt',
    label: 'Atualização',
    size: '2%',
    align: 'left',
    sortable: true
  },
  {
    id: 'action',
    label: 'Ações',
    size: '30%',
    align: 'center',
    sortable: false
  }
];

export const listCurriculums = async (
  issuerMecCode: number
): Promise<Root[]> => {
  if (!issuerMecCode) {
    return [];
  }
  const response = (await CurriculumService.getCurriculums(issuerMecCode)).data;
  return response;
};
