import {
  INITIAL_STATE,
  SET_LOADING_UPSERT,
  SET_LOADING_USERS,
  SET_PERMISSIONS_USER,
  SET_USERS_SECURITY,
  UsersSecurityActions,
  UsersSecurityState
} from './types';

export default function reducer(
  state: UsersSecurityState = INITIAL_STATE,
  action: UsersSecurityActions
): UsersSecurityState {
  switch (action.type) {
    case SET_USERS_SECURITY:
      return {
        ...state,
        users: action.payload.users
      };
    case SET_LOADING_USERS:
      return {
        ...state,
        loadingUsers: action.payload.loadingUsers
      };
    case SET_LOADING_UPSERT:
      return {
        ...state,
        loadingUpsert: action.payload.loadingUpsert
      };
    case SET_PERMISSIONS_USER:
      return {
        ...state,
        permissions: action.payload.permissions
      };

    default:
      return state;
  }
}
