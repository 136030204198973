import { Grid, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { styled } from '@mui/material/styles';
import DotStepIcon from '../../atoms/dot-step-icon';
import VerticalTimelineProps from './types';

const PREFIX = 'VerticalTimeline';

const classes = {
  stepperContainer: `${PREFIX}-stepperContainer`
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.stepperContainer}`]: {
    '& .MuiStepper-root': {
      padding: '0',
    },
    '& .MuiStepContent-root': {
      paddingLeft: '0.75rem',
      marginTop: '0',
    },
    '& .MuiStepConnector-vertical': {
      height: '1rem',
      padding: '0',
    },
  }
}));

const DotStepIconWithMargin = (isActive: boolean, activeColor?: string) => (
  <div style={{ marginLeft: '0.45rem' }}>
    <DotStepIcon active={isActive} activeColor={activeColor} />
  </div>
);
function VerticalTimeline({ steps, activeStep }: VerticalTimelineProps) {

  const isActiveStep = (i: number) => i === activeStep;


  return (
    <StyledGrid className={classes.stepperContainer} data-testid="vertical-timeline">
      <Stepper
        orientation="vertical"
        activeStep={0}
      >
        {
          steps.map(({ label, content, activeColor }, i) => {
            const newIndex = i;
            return (
              <Step key={`vertical-steps-${newIndex}`} data-testid="vertical-timeline-step" expanded>
                <StepLabel
                  StepIconComponent={() => DotStepIconWithMargin(isActiveStep(i), activeColor)}
                >
                  {label}
                </StepLabel>
                {content && <StepContent>{content}</StepContent>}
              </Step>
            );
          })
        }
      </Stepper>
    </StyledGrid >
  );
}

VerticalTimeline.defaultProps = {
  activeStep: 0,
};

export default VerticalTimeline;
