import { Button, FormGroup, Grid, InputLabel, Link, Paper, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FormikContextType, useFormik } from 'formik';
import {
  useState, useEffect,
} from 'react';
import * as yup from 'yup';
import PublicDiplomaDto from '../../../../../domain/interface/dto/PublicDiplomaDto';
import DiplomaService from '../../../../../services/DiplomaService';
import ErrorInputAdornment from '../../../atoms/error-input-adornment';
import EmptyTable from '../../../molecules/empty-table';
import PublicDiplomaInfo from '../../../organisms/public-diploma-info';
import { validation } from '../../../../utils/constants';
import { PublicPortalFormInputs } from '../types';
import ItiLogo from '../../../../img/iti_logo.svg';
import { BACKGROUND_COLOR, globalStyle, ITI_COLOR } from '../../../../../theme';
import BackToTopButton from '../../../atoms/back-to-top-button';
import useQuery from '../../../../hook/use-query-hook';
import CustomTextField from '../../../atoms/form/CustomTextField';

const useStyles = makeStyles((theme: Theme) => createStyles({
  '@global': globalStyle,
  content: {
    flexGrow: 1,
    backgroundColor: BACKGROUND_COLOR,
    minHeight: '100vh',
    maxWidth: '100%',
  },
  spacedTop: {
    marginTop: theme.spacing(4),
  },
  spacedBottom: {
    marginBottom: theme.spacing(4),
  },
  inputFormItem: {
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
  },
  disclaimer: {
    fontSize: '12px',
  },
  itiLink: {
    color: ITI_COLOR,
  },
  itiLogo: {
    height: '118px',
    width: '254px',
  },
  fixedWidthContainer: {
    width: '30.375rem',
  },
  pageTitle: {
    marginTop: theme.spacing(8),
  },
}));

async function getDiploma(validationCode: string): Promise<PublicDiplomaDto> {
  const response = await DiplomaService.getPublicDiploma(validationCode);
  return response.data;
}

const validationSchema = yup.object().shape({
  validationCode: yup
    .string()
    .matches(/\d+\.\d+\.[a-f0-9]{12,}/, validation.errorMessages.invalidDiplomaValidationCode)
    .required(validation.errorMessages.requiredField),
});

export default function PublicPortal(): JSX.Element {
  const classes = useStyles();

  const [diploma, setDiploma] = useState<PublicDiplomaDto | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchedOnce, setSearchedOnce] = useState<boolean>(false);

  const query = useQuery();
  const queryValidationCode = query.get('validationCode');

  const formik: FormikContextType<PublicPortalFormInputs> = useFormik({
    initialValues: {
      validationCode: queryValidationCode ?? '',
    },
    validateOnMount: !!queryValidationCode,
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema,
    onSubmit: async (values: PublicPortalFormInputs) => {
      if (!searchedOnce) {
        setSearchedOnce(true);
      }

      setIsLoading(true);

      try {
        const response = await getDiploma(values.validationCode);
        setDiploma(response);
      } catch {
        setDiploma(undefined);
      }

      setIsLoading(false);
    },
  });

  function searchDiplomaFromQueryString(): void {
    if (queryValidationCode) {
      formik.handleSubmit();
    }
  }

  useEffect(searchDiplomaFromQueryString, [formik, queryValidationCode]);

  const getSearchResult = (): JSX.Element | null => {
    if (!formik.errors.validationCode) {
      if (!isLoading && !diploma) {
        return <EmptyTable emptyStateMessage="Diploma não encontrado" orientationMessage="Experimente utilizar outro código de validação na sua busca" />;
      }

      return <PublicDiplomaInfo diploma={diploma} isLoading={isLoading} />;
    }

    return null;
  };

  return (
    <Grid className={classes.content} container alignItems="center" direction="column" spacing={4}>
      <Grid item>
        <Typography className={classes.pageTitle} variant="h1" noWrap>Consulta do Diploma Digital</Typography>
      </Grid>
      <Grid className={classes.fixedWidthContainer} item>
        <Paper>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <FormGroup className={`${classes.spacedTop} ${classes.inputFormItem} ${classes.spacedBottom}`}>
                  <InputLabel>
                    Código de Validação do Diploma
                  </InputLabel>
                  <CustomTextField
                    name="validationCode"
                    fullWidth
                    placeholder="Ex: 123.456.777777777"
                    value={formik.values.validationCode}
                    error={!!formik.errors.validationCode}
                    onChange={formik.handleChange}
                    helperText={formik.errors.validationCode}
                    InputProps={{
                      endAdornment: <ErrorInputAdornment show={!!formik.errors.validationCode} />,
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup className={`${classes.inputFormItem} ${classes.spacedBottom}`}>
                  <Button variant="contained" color="primary" type="submit" fullWidth>Consultar</Button>
                </FormGroup>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      {searchedOnce ? (
        <Grid item xs={12}>
          <Paper>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                {getSearchResult()}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ) : null}
      <Grid className={classes.fixedWidthContainer} item>
        <Typography className={classes.disclaimer}>
          Atendendo ao comando da Nota técnica No. 13/2019/DIFES/SESU/SESU, a Instituição de Ensino Superior deve implantar em seu site, em local acessível e visível, um ambiente que permita a informação ao público da veracidade do diploma digital. Para a consulta deverá ser utilizado o código de validação informado na Representação Visual do Diploma Digital - RVDD. Mais informações são disponibilizadas pelo MEC em
          {' '}
          <Link href="http://portal.mec.gov.br/diplomadigital" target="_blank" rel="noopener noreferrer">http://portal.mec.gov.br/diplomadigital</Link>
          .
        </Typography>
      </Grid>
      <Link href="https://verificador.iti.gov.br/" target="_blank" rel="noreferrer">
        <Grid container item justifyContent="center" alignItems="center" alignContent="center" direction="column" spacing={1}>
          <Grid item>
            <Typography className={classes.itiLink} variant="caption">
              Verifique a conformidade do diploma pelo ITI clicando aqui
            </Typography>
          </Grid>
          <Grid item>
            <img src={ItiLogo} className={classes.itiLogo} alt="Logomarca do Instituto Nacional de Tecnologia da Informação" />
          </Grid>
        </Grid>
      </Link>
      <BackToTopButton showBelow={160} />
    </Grid>
  );
}
