import {
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { Eye, FileText, Trash } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import InstitutionProfile from '../../../../domain/enum/InstitutionProfile';
import GetGraduateDocumentRequest from '../../../../domain/interface/request/GetGraduateDocumentRequest';
import GraduateDocument from '../../../../domain/model/diploma/GraduateDocument';
import GraduateDocumentFile from '../../../../domain/model/diploma/GraduateDocumentFile';
import DiplomaService from '../../../../services/DiplomaService';
import { RootState } from '../../../../setup/reducer/reducers';
import { DIPLOMA_COLOR, DANGER_COLOR } from '../../../../theme';
import { formatBytes, reduceTextIfNecessary } from '../../../utils/functions';
import CardTitle from '../../molecules/card-title';
import CustomizedTooltip from '../../atoms/customized-tooltip';
import AcademicDocFilesModal from '../academic-doc-files-modal';
import { Item } from '../academic-doc-files-modal/types';
import DropzoneUpload from '../../atoms/dropzone-upload';
import ConfirmationModal from '../confirmation-modal';
import GraduateDocumentType from '../../../../domain/enum/diploma/GraduateDocumentType';
import AddGraduateDocumentRequest from '../../../../domain/interface/request/AddGraduateDocumentRequest';
import { selectDiploma } from '../../pages/diploma-page/store/actions';
import Diploma from '../../../../domain/model/diploma/Diploma';
import { ErrorOutline } from '@mui/icons-material';
import DiplomaStatus from '../../../../domain/enum/diploma/DiplomaStatus';
import { showNotification } from '../../pages/notification/actions';
import {
  errorNotificationMessages,
  notificationIcons
} from '../../../utils/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.primary.main
    },
    iconDropZone: {
      fontSize: '24px !important'
    },
    primary: {
      fontSize: '14px'
    },
    container: {
      paddingLeft: '2rem',
      paddingBottom: '2.5rem'
    },
    listItemContainer: {
      paddingTop: '1.5rem'
    },
    listItem: {
      paddingRight: '2.5rem'
    },
    listItemIcon: {
      height: '1rem',
      width: 'auto'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(3),
      top: theme.spacing(3),
      color: theme.palette.grey[500]
    },
    dropZoneBox: {
      background: '#fafafa',
      borderColor: '#e8e8e8',
      borderSize: '10px',
      borderRadius: '10px',
      borderWidth: '2px',
      height: '200px',
      paddingTop: '14%'
    },
    dropZoneBoxP: {
      position: 'absolute',
      bottom: '25%',
      left: '0',
      width: '100%',
      textAlign: 'center'
    },
    warningMessage: {
      display: 'flex',
      alignContent: 'space-between',
      alignItems: 'center'
    },
    warningMessageP: {
      marginLeft: '10px'
    }
  })
);


function convertToItem(graduateDocument: GraduateDocument | undefined): Item {
  if (!graduateDocument) {
    return {
      id: '',
      name: ''
    };
  }

  return {
    id: graduateDocument.documentId,
    name: graduateDocument.name
  };
}

async function getGraduateDocument(
  diplomaId: string,
  documentId: string
): Promise<GraduateDocumentFile> {
  const getGraduateDocumentRequest: GetGraduateDocumentRequest = {
    diplomaId,
    documentId
  };

  const response = await DiplomaService.getGraduateDocument(
    getGraduateDocumentRequest
  );

  return response.data;
}

async function addGraduateDocument(
  request?: AddGraduateDocumentRequest,
  diplomaId?: string
): Promise<any> {
  const response = await DiplomaService.addGraduateDocument(request, diplomaId);
  return response.data;
}

async function deleteGraduateDocument(
  diplomaId?: string,
  documentId?: string
): Promise<any> {
  const response = await DiplomaService.deleteGraduateDocument(
    diplomaId,
    documentId
  );
  return response.data;
}

enum ActionConfirmationModalType {
  Upload = 'Upload',
  Delete = 'Delete'
}

const DocumentItem = ({
  document,
  handleOnClickViewButton,
  onClickDeleteButton,
  allowEditAcademicDocumentation,
  classes
}: {
  document: GraduateDocument;
  handleOnClickViewButton: (document: GraduateDocument) => void;
  onClickDeleteButton: (document: GraduateDocument) => void;
  allowEditAcademicDocumentation: () => boolean;
  classes: Record<string, string>;
}) => {
  const { documentId, name, size } = document;
  const label = reduceTextIfNecessary(`${name} (${formatBytes(size)})`);

  return (
    <ListItem key={documentId}>
      <CustomizedTooltip title={name} placement="top">
        <ListItemText primary={label} />
      </CustomizedTooltip>
      <ListItemSecondaryAction>
        <CustomizedTooltip title="Visualizar" placement="top">
          <IconButton
            edge="end"
            onClick={() => handleOnClickViewButton(document)}
            size="large"
          >
            <Eye className={classes.listItemIcon} color={DIPLOMA_COLOR} />
          </IconButton>
        </CustomizedTooltip>
        {allowEditAcademicDocumentation() && (
          <CustomizedTooltip title="Apagar documento" placement="top">
            <IconButton
              edge="end"
              onClick={() => onClickDeleteButton(document)}
              size="large"
            >
              <Trash className={classes.listItemIcon} color={DANGER_COLOR} />
            </IconButton>
          </CustomizedTooltip>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const GreyTextTypography = withStyles({
  root: {
    color: '#757575',
    fontSize: '17px',
    marginBottom: '5px'
  }
})(Typography);

interface UploadAreaProps {
  highSchoolCompletitionTestUpload: number;
  stateIdUpload: number;
  allowEditAcademicDocumentation: () => boolean;
  classes: Record<string, string>;
  openDialog: (files: File[], type: GraduateDocumentType) => void;
}

const UploadArea: React.FC<UploadAreaProps> = ({
  highSchoolCompletitionTestUpload,
  stateIdUpload,
  allowEditAcademicDocumentation,
  classes,
  openDialog,
}) => {
  return (
    <Grid container className={classes.listItemContainer}>
      <Grid item xs={4} md={4} className={classes.listItem}>
        <GreyTextTypography variant="body2">{`Prova de Conclusão do Ensino Médio`}</GreyTextTypography>
        {allowEditAcademicDocumentation() && (
          <DropzoneUpload
            disabled={highSchoolCompletitionTestUpload > 0}
            textPreview="Arquivo anexado"
            dropzoneClass={classes.dropZoneBox}
            dropzoneParagraphClass={classes.dropZoneBoxP}
            onDrop={(files) =>
              openDialog(files, GraduateDocumentType.HighSchoolCompletion)
            }
          />
        )}
      </Grid>
      <Grid item xs={4} md={4} className={classes.listItem}>
        <GreyTextTypography variant="body2">{`Documento de Identificação`}</GreyTextTypography>
        {allowEditAcademicDocumentation() && (
          <DropzoneUpload
            disabled={stateIdUpload > 0}
            textPreview="Arquivo anexado"
            dropzoneClass={classes.dropZoneBox}
            dropzoneParagraphClass={classes.dropZoneBoxP}
            onDrop={(files) =>
              openDialog(files, GraduateDocumentType.StateId)
            }
          />
        )}
      </Grid>
    </Grid>
  );
};

export default function AcademicDocFilesPaper(): JSX.Element {
  const classes = useStyles();
  const diploma = useSelector((state: RootState) => state.diplomaPage.diploma);

  const highSchoolCompletitionTestUpload =
    diploma?.graduateDocuments?.filter(
      (item) => item.type === GraduateDocumentType.HighSchoolCompletion
    ).length || 0;
  const stateIdUpload =
    diploma?.graduateDocuments?.filter(
      (item) => item.type === GraduateDocumentType.StateId
    ).length || 0;

  const validation = useSelector(
    (state: RootState) => state.diplomaPage.validation
  );
  const selectedInstitutionProfile = useSelector(
    (state: RootState) => state.diplomaFilter.selectedInstitutionProfile
  );
  const dispatch = useDispatch();
  const isInfoMode = useSelector((state: RootState) => state.diplomaPage.info);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentFile, setCurrentFile] = useState<string>('');
  const [currentDocument, setCurrentDocument] = useState<
    GraduateDocument | undefined
  >(undefined);

  const [openConfirmationModal, setConfirmationModal] =
    useState<boolean>(false);
  const [objectAddGraduateDocumentRequest, setAddGraduateDocumentRequest] =
    useState<AddGraduateDocumentRequest | undefined>(undefined);
  const [titleConfirmationModal, setTitleConfirmationModal] =
    useState<string>('');
  const [actionConfirmationModalType, setActionConfirmationModalType] =
    useState<ActionConfirmationModalType | undefined>(undefined);
  const [documentId, setDocumentId] = useState<string>('');

  const isRegisterInstitution = () =>
    selectedInstitutionProfile === InstitutionProfile.Register;
  const allowEditAcademicDocumentation = () =>
    !isInfoMode &&
    diploma?.status === DiplomaStatus.IssuerConfirmationPending &&
    !isRegisterInstitution();

  const openDialog = (files: File[], type: GraduateDocumentType): void => {
    setConfirmationModal(true);

    setActionConfirmationModalType(ActionConfirmationModalType.Upload);
    setTitleConfirmationModal('Confirma o upload deste documento?');

    const toBase64 = (file: Blob) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          resolve(reader.result);

          const addGraduateDocument: AddGraduateDocumentRequest = {
            name: files[0].name,
            type,
            file: reader.result?.toString().split(',')[1]
          };

          setAddGraduateDocumentRequest(addGraduateDocument);
        };
        reader.onerror = (error) => reject(error);
      });

    toBase64(files[0]);
  };

  const onClickDeleteButton = (document: GraduateDocument) => {
    setDocumentId(document.documentId);

    setAddGraduateDocumentRequest(document);
    setActionConfirmationModalType(ActionConfirmationModalType.Delete);
    setTitleConfirmationModal('Confirma a exclusão deste documento?');

    setConfirmationModal(true);
  };

  const successDialog = async (): Promise<void> => {
    setLoading(true);

    if (
      actionConfirmationModalType != null &&
      actionConfirmationModalType === ActionConfirmationModalType.Upload
    ) {
      try {
        let { documents } = await addGraduateDocument(
          objectAddGraduateDocumentRequest,
          diploma?.id
        );

        await dispatch(
          selectDiploma({
            ...diploma,
            graduateDocuments: documents as GraduateDocument[]
          } as Diploma)
        );
      } catch (ex) {
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        );
      }
    } else if (
      actionConfirmationModalType != null &&
      actionConfirmationModalType === ActionConfirmationModalType.Delete
    ) {
      try {
        await deleteGraduateDocument(diploma?.id, documentId);

        const graduateDocuments = diploma?.graduateDocuments?.filter(
          (item) => item.documentId !== documentId
        );

        await dispatch(
          selectDiploma({
            ...diploma,
            graduateDocuments: graduateDocuments
          } as Diploma)
        );
      } catch (ex) {
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        );
      }
    }

    setLoading(false);
    setConfirmationModal(false);
  };

  const closeDialog = (): void => {
    setConfirmationModal(false);
  };

  const setDocument = async (item: Item | undefined): Promise<void> => {
    if (diploma && item) {
      setLoading(true);

      setCurrentDocument({ name: item.name } as GraduateDocument);
      const document = await getGraduateDocument(diploma?.id, item.id);
      setCurrentFile(`data:application/pdf;base64,${document.file}`);

      setLoading(false);
    }
  };

  useEffect(() => {
    async function internalSetDocument(
      diplomaId: string,
      documentId: string
    ): Promise<void> {
      setLoading(true);
      const document = await getGraduateDocument(diplomaId, documentId);
      setCurrentFile(`data:application/pdf;base64,${document.file}`);
      setLoading(false);
    }

    if (diploma && currentDocument) {
      internalSetDocument(diploma.id, currentDocument.documentId);
    }
  }, [diploma, currentDocument]);

  const handleOnClickViewButton = (document: GraduateDocument) => {
    setOpenModal(true);
    setCurrentDocument(document);
  };

  const getSuffixMessageToRegisterInstitution = () =>
    isRegisterInstitution() ? ' enviados pela IES Emissora' : '';

  const documentsStateId = diploma?.graduateDocuments?.filter(
    (item) => item.type === GraduateDocumentType.StateId
  );
  const documentsHighSchoolCompletitionTest =
    diploma?.graduateDocuments?.filter(
      (item) =>
        item.type === GraduateDocumentType.HighSchoolCompletion ||
        item.type === GraduateDocumentType.Transcript
    );

  const OrangeTextTypography = withStyles({
    root: {
      color: 'orange',
      fontWeight: 'bold'
    }
  })(Typography);


  const renderDocumentItems = (documents: GraduateDocument[] | undefined) => {
    if (!documents) {
      return [];
    }

    return documents.map((document) => (
      <Grid
        item
        xs={12}
        key={document.documentId + '-' + document.name}
        className={classes.listItem}
      >
        <DocumentItem
          document={document}
          handleOnClickViewButton={handleOnClickViewButton}
          onClickDeleteButton={onClickDeleteButton}
          allowEditAcademicDocumentation={allowEditAcademicDocumentation}
          classes={classes}
        />
        <Divider />
      </Grid>
    ));
  };

  return (
    <>
      <Paper>
        <Grid container>
          <Grid item xs={6}>
            <CardTitle icon={<FileText className={classes.icon} />}>
              Arquivos da Documentação
            </CardTitle>
          </Grid>
          <Grid item xs={6} className={classes.warningMessage}>
            {validation?.graduateDocuments && (
              <>
                <ErrorOutline htmlColor="orange" fontSize="medium" />
                <OrangeTextTypography
                  variant="body2"
                  className={classes.warningMessageP}
                >
                  {validation?.graduateDocuments}
                </OrangeTextTypography>
              </>
            )}
          </Grid>
          <Grid item xs={12} className={classes.container}>
            <UploadArea
              highSchoolCompletitionTestUpload={highSchoolCompletitionTestUpload}
              stateIdUpload={stateIdUpload}
              allowEditAcademicDocumentation={allowEditAcademicDocumentation}
              classes={classes}
              openDialog={openDialog}
            />
            {diploma?.graduateDocuments &&
              diploma.graduateDocuments.length > 0 ? (
              <Grid container>
                <Grid item xs={4}>
                  <List dense component="nav">
                    <Grid container className={classes.listItemContainer}>
                      {renderDocumentItems(documentsHighSchoolCompletitionTest)}
                    </Grid>
                  </List>
                </Grid>
                <Grid item xs={4}>
                  <List dense component="nav">
                    <Grid
                      key="list-container"
                      container
                      className={classes.listItemContainer}
                    >
                      {renderDocumentItems(documentsStateId)}
                    </Grid>
                  </List>
                </Grid>
              </Grid>
            ) : (
              <Typography variant="body2" color="error">
                Não foi possível obter os documentos do aluno
                {getSuffixMessageToRegisterInstitution()}.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Paper>
      {diploma?.graduateDocuments && diploma.graduateDocuments.length > 0 && (
        <AcademicDocFilesModal
          listItems={diploma?.graduateDocuments?.map((document) => ({
            name: document.name,
            id: document.documentId
          }))}
          currentItem={convertToItem(currentDocument)}
          currentFileSrc={currentFile}
          openModal={openModal}
          loading={loading}
          setOpenModal={setOpenModal}
          setItem={setDocument}
        />
      )}
      {allowEditAcademicDocumentation() && (
        <ConfirmationModal
          openModal={openConfirmationModal}
          modalTitle={titleConfirmationModal}
          textContinueButton="Sim"
          textCancelButton="Cancelar"
          loading={loading}
          children=" "
          handleContinueButton={() => {
            successDialog();
          }}
          handleCancelButton={() => {
            closeDialog();
          }}
        />
      )}
    </>
  );
}
