/* eslint-disable react/jsx-props-no-spreading */
import { Typography } from '@mui/material';
import React from 'react';
import { useTypographyEmphasisStyles } from './styles';
import { TypographyWithEmphasisProps } from './types';

/**
 * - Emphasis every `<strong />` element inside `children` with `emphasisColor`
 */
function TypographyEmphasis(props: TypographyWithEmphasisProps) {
  const {
    children,
    baseColor,
    emphasisColor,
    isBold,
    ...otherProps
  } = props;

  const styles = useTypographyEmphasisStyles({ emphasisColor, baseColor, isBold });

  return (
    <Typography className={styles.emphasizedText} {...otherProps}>
      {children}
    </Typography>
  );
}

export default TypographyEmphasis;
