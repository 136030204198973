enum PermissionPage {
  Diploma = 'Permission#Page#DiplomaTracking',
  Dou = 'Permission#Page#BatchControlForPublicationInTheDOU',
  Institution = 'Permission#Page#InstitutionManagement',
  User = 'Permission#Page#UserRegistration',
  Microcertificate = 'Permission#Page#Microcertificates',
  Signer = 'Permission#Page#SignDocuments',
  DigitalCurriculum = 'Permission#Page#DigitalCurriculum'
}

export default PermissionPage;
