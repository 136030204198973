import DouBatchType from '../../../../domain/enum/dou/DouBatchType';
import DouBatchStatus from '../../../../domain/enum/dou/DouRegisterBatchStatus';
import CloseBatchRequest from '../../../../domain/interface/request/CloseBatchRequest';
import Dou from '../../../../domain/model/dou/Dou';
import DouService from '../../../../services/DouService';
import { BatchData, DouFilterBatchStatus, DouFilterBatchType } from './types';

export default class DouFilterManager {
  static DouBatchStatuses: Array<DouFilterBatchStatus> = [
    {
      name: DouBatchStatus.Opened
    },
    {
      name: DouBatchStatus.Closed
    },
    {
      name: 'Todos'
    }
  ];

  static DouBatchTypes: Array<DouFilterBatchType> = [
    {
      name: DouBatchType.Cancellation
    },
    {
      name: DouBatchType.Register
    },
    {
      name: 'Todos'
    }
  ];
}

export const listBatches = async (mecCode: number): Promise<Dou[]> => {
  const response = await DouService.listNewBatches(mecCode);
  return response.data;
};

export const listNewBatches = async (mecCode: number): Promise<Dou[]> => {
  const response = await DouService.listNewBatches(mecCode);
  return response.data;
};

export const closeBatch = async (
  batchId: number,
  request: CloseBatchRequest
): Promise<any> => {
  const response = await DouService.closeBatch(batchId, request);
  return response.data;
};

export const getRows = (
  batches: Dou[],
  search?: number,
  status?: DouFilterBatchStatus,
  type?: DouFilterBatchType
): BatchData[] => {
  let rows: BatchData[] = [];

  if (!batches) {
    return rows;
  }

  batches.forEach((value) => {
    rows.push({
      institutionMecCode: value.institutionMecCode,
      sk: value.sk,
      status: value.status,
      batchNumber: value.batchNumber,
      type: value.type,
      createdAt: value.createdAt,
      closedAt: value.closedAt ? value.closedAt : 'N/A',
      publicationDate: value.publicationDate ? value.publicationDate : 'N/A',
      douNumber: value.douNumber ? value.douNumber : 'N/A',
      action: ''
    });
  });

  if (search) {
    rows = rows.filter((value) =>
      value.batchNumber.toString().match(`.*${search}.*`)
    );
  }

  if (status && status.name !== 'Todos') {
    rows = rows.filter((value) =>
      value.status.toString().match(`.*${status.name}.*`)
    );
  }

  if (type && type.name !== 'Todos') {
    rows = rows.filter((value) =>
      value.type.toString().match(`.*${type.name}.*`)
    );
  }

  return rows;
};
