import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Filter } from 'react-feather';
import { useSelector } from 'react-redux';
import InstitutionProfile from '../../../../domain/enum/InstitutionProfile';
import Institution from '../../../../domain/model/institution/Institution';
import { RootState } from '../../../../setup/reducer/reducers';
import { WHITE_COLOR } from '../../../../theme';
import BaseModal from '../../molecules/base-modal';
import CustomDatePicker from '../../atoms/form/CustomDatePicker';
import SelectAutocomplete from '../../molecules/select-autocomplete';
import useAdvancedFilterModalStyles from './styles';
import AdvancedFilterModalProps, { AdvancedFilterResults } from './types';
import moment from 'moment';

const currentDate = new Date().toISOString();

export default function AdvancedFilterModal({ onClose, onFilter, openModal }: AdvancedFilterModalProps) {
  const styles = useAdvancedFilterModalStyles();
  const [endDate, setEndDate] = useState(currentDate);
  const [startDate, setStartDate] = useState(currentDate);
  const [selectedInstitution, setSelectedInstitution] = React.useState<Institution | undefined>(undefined);
  const issuerInstitutions = useSelector((state: RootState) => state.diplomaFilter.issuerInstitutions);
  const selectedInstitutionProfile = useSelector((state: RootState) => state.diplomaFilter.selectedInstitutionProfile);

  const isRegisterInstitution = () => selectedInstitutionProfile === InstitutionProfile.Register;

  const handleStartDateChange = (date: string) => {
    const newDate = moment(date).format('YYYY-MM-DD');
    if (newDate) {
      setStartDate(newDate);
    }
  };

  const handleEndDateChange = (date: string) => {
    const newDate = moment(date).format('YYYY-MM-DD');
    if (newDate) {
      setEndDate(newDate);
    }
  };

  const handleFilter = () => {
    const filterResults: AdvancedFilterResults = {
      institution: selectedInstitution,
      endDate,
      startDate,
    };

    onFilter(filterResults);
    onClose();
  };

  React.useEffect(() => {
    setSelectedInstitution(selectedInstitution);
  }, [selectedInstitution]);

  const handleClear = () => {
    const filterResults = {
      endDate: '',
      startDate: '',
      institution: undefined,
    };

    onFilter(filterResults);

    setStartDate(currentDate);
    setEndDate(currentDate);

    onClose();
  };

  return (
    <BaseModal
      openModal={openModal}
      modalTitle="Filtros Avançados"
      onClose={onClose}
      closeable
    >
      <Box className={styles.root}>
        <Grid container className={styles.filtersContainer} spacing={5}>
          {
            isRegisterInstitution() && (
              <Grid item xs={12}>
                <SelectAutocomplete
                  label="IES Emissora"
                  placeholder="Nenhuma IES selecionada"
                  listItems={issuerInstitutions}
                  setItem={setSelectedInstitution}
                  defaultValue={selectedInstitution}
                  data-testid="advanced-filter-modal-select-issuer"
                />
              </Grid>
            )
          }
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
          >
            <Grid item xs={12}>
              <Typography variant="body2" className={styles.datePickersDescription}>
                Última atualização entre os dias:
              </Typography>
            </Grid>
            <Grid item className={styles.datePicker}>
              <CustomDatePicker
                value={startDate}
                maxDate={moment(endDate).toDate()}
                inputLabel="Data Inicial"
                onChange={(newDate: Date) => { handleStartDateChange(newDate.toISOString()) }}
              />
            </Grid>
            <Grid item className={styles.datePicker}>
              <CustomDatePicker
                value={endDate}
                maxDate={moment(currentDate).toDate()}
                minDate={moment(startDate).toDate()}
                inputLabel="Data Final"
                onChange={(newDate: Date) => { handleEndDateChange(newDate.toISOString()) }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          direction="column"
        >
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              startIcon={<Filter size={18} color={WHITE_COLOR} />}
              className={styles.button}
              onClick={handleFilter}
            >
              Aplicar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClear}
              className={styles.button}
            >
              Limpar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </BaseModal>
  );
}
