import moment from 'moment';
import 'moment/locale/pt-br';
import { makeUserManager } from 'react-oidc';
import { UserManager } from 'oidc-client';
import { pageRoutes } from '../../../utils/constants';

export function GetUserManager() {
  return makeUserManager(GetOidcConfig());
}

export const LogoutComponent = (userManager: UserManager) => {
  const args = {
    extraQueryParams: {
      client_id: userManager.settings.client_id,
      logout_uri: userManager.settings.post_logout_redirect_uri
    }
  };

  userManager.removeUser();
  userManager.signoutRedirect(args);
};

export const GetUser = () => {
  const oidcConf = GetOidcConfig();
  return JSON.parse(sessionStorage.getItem(oidcConf.userStorageKey)!);
};

export const GetUserData = (user: any) => {
  if (!user) {
    return {};
  }
  return {
    ...user,
    clientId: user.profile.email,
    email: user.profile.email,
    clientTenant: 'anima',
    expirationDate: moment.unix(user.expires_at),
    login: user.profile.email,
    name: `${user.profile.given_name} ${user.profile.family_name}`,
    principalId: '',
    token: user.id_token
  };
};

export function GetOidcConfig() {
  return {
    post_logout_redirect_uri: `${process.env.REACT_APP_BASE_ROUTE}${pageRoutes.LoginMicrosoft}`,
    redirect_uri: `${process.env.REACT_APP_BASE_ROUTE}${pageRoutes.Login}`,
    client_id: process.env.REACT_APP_CLIENT_ID_COGNITO,
    authority: process.env.REACT_APP_AUTHORITY_COGNITO,
    scope: 'openid email profile',
    response_type: 'code',
    automaticSilentRenew: true,
    revokeAccessTokenOnSignout: true,
    metadataSeed: {
      end_session_endpoint: `${process.env.REACT_APP_BASE_ROUTE}${pageRoutes.LoginMicrosoft}`
    },
    userStorageKey: `oidc.user:${process.env.REACT_APP_AUTHORITY_COGNITO}:${process.env.REACT_APP_CLIENT_ID_COGNITO}`
  };
}
