import Certificate from '../../../../domain/model/certificate/Certificate';
import { searchIgnoringCaseAndAccents } from '../../../utils/functions';
import { CertificateInfoData, HeadCell, Order } from './types';

export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

export const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key
): ((
  a: { [key in Key]: number | string | string[] },
  b: { [key in Key]: number | string | string[] }
) => number) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

export const stableSort = <T>(
  array: T[],
  comparator: (a: T, b: T) => number
) => {
  if (array.length === 0) {
    return array;
  }
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

export const headCells: HeadCell[] = [
  {
    id: 'status',
    label: 'Status',
    size: '30%',
    align: 'left',
    sortable: true
  },
  {
    id: 'nameBrand',
    label: 'Nome da Marca',
    size: '3%',
    align: 'center',
    sortable: true
  },
  {
    id: 'type',
    label: 'Tipo de Microcertificação',
    size: '30%',
    align: 'left',
    sortable: true
  },
  {
    id: 'nameCourse',
    label: 'Nome do Curso',
    size: '10%',
    align: 'left',
    sortable: true
  },
  {
    id: 'workload',
    label: 'Carga Horária',
    size: '3%',
    align: 'left',
    sortable: false
  },
  {
    id: 'nameStudent',
    label: 'Nome do Estudante',
    size: '8%',
    align: 'left',
    sortable: true
  },

  {
    id: 'registrationNumber',
    label: 'RA',
    size: '3%',
    align: 'center',
    sortable: true
  }
];

export const getRows = (
  certificateBatchs: Certificate[],
  search: string
): CertificateInfoData[] => {
  let rows: CertificateInfoData[] = [];

  if (!certificateBatchs || certificateBatchs.length === 0) {
    return rows;
  }

  certificateBatchs.forEach((value) => {
    rows.push({
      id: value.id,
      status: value.root.status,
      nameBrand: value.institution.brand,
      type: value.root.type,
      nameCourse: value.course.name,
      nameStudent: value.student.name,
      registrationNumber: value.student.registrationNumber,
      workload: value.course.workload,
      validationErrors: value.root.validationErrors
    });
  });

  if (search.length >= 2) {
    search = search.toLowerCase();
    rows = rows.filter(
      (value) =>
        searchIgnoringCaseAndAccents(value?.nameStudent, search) ||
        value.nameStudent?.toLowerCase().match(`.*${search}.*`)
    );
  }

  return rows;
};
