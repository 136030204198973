import { Button, Checkbox, Grid, List, ListItem, Paper, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { BookOpen, FileText, Settings, User, Users } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import PermissionPage from '../../../../domain/enum/employee/PermissionPage';
import PermissionsEmployee from '../../../../domain/enum/employee/PermissionsEmployee';
import { default as PermissionPageDto } from '../../../../domain/interface/dto/PermissionEmployeeDto';
import { RootState } from '../../../../setup/reducer/reducers';
import { DIPLOMA_COLOR, LIGHT_GRAY_COLOR } from '../../../../theme';
import Certificate from '../../../icons/Certificate';
import Diploma from '../../../icons/Diploma';
import { pageRoutes } from "../../../utils/constants";
import BackCardHeader from "../../molecules/back-card-header";
import BackdropLoading from '../../molecules/backdrop-loading';
import CardTitle from '../../molecules/card-title';
import LabeledText from '../../molecules/labeled-text';
import DefaultTemplate from "../../templates/default-template";
import { upsertPermissionEmployee } from './store/actions';


const PREFIX = 'UsersSecurity';

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  grid: `${PREFIX}-grid`,
  cardList: `${PREFIX}-cardList`,
  listItem: `${PREFIX}-listItem`,
  check: `${PREFIX}-check`,
  titleFunc: `${PREFIX}-titleFunc`,
  button: `${PREFIX}-button`,
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    paddingBottom: theme.spacing(7),
  }
}));

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.icon}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${classes.grid}`]: {
    margin: '0 2rem'
  },
  [`& .${classes.cardList}`]: {
    margin: '8px 0'
  },
  [`& .${classes.listItem}`]: {
    justifyContent: 'space-between',
    borderBottom: `solid ${LIGHT_GRAY_COLOR} 2px`
  },
  [`& .${classes.check}`]: {
    marginRight: '1.5rem'
  },
  [`& .${classes.titleFunc}`]: {
    color: DIPLOMA_COLOR,
    fontSize: '1.6rem',
    marginBottom: '1.8rem'

  },
  [`& .${classes.button}`]: {
    height: '2.5rem',
    marginTop: '1rem',
    flexDirection: 'row',
    marginLeft: 'auto'

  },
}));

const ListLoading = () => (
  <List>
    {[...Array(Object.values(PermissionPage).length)].map((_, index) => {
      let newIndex = index;
      return (
        <ListItem key={newIndex} className={classes.listItem}>
          <Skeleton style={{ height: 40, width: '80%' }} animation="wave" />
          <Skeleton style={{ height: 40, width: '28px' }} />
        </ListItem>
      );
    })}
  </List>
);


const UsersSecurityPage = () => {
  const user = useSelector((state: RootState) => state.usersSecurity.users);
  const loadingUser = useSelector((state: RootState) => state.usersSecurity.loadingUsers);
  const isLoading = useSelector((state: RootState) => state.usersSecurity.loadingUpsert);
  const permissionsActives = useSelector((state: RootState) => state.usersSecurity.permissions);
  const history = useHistory();
  const dispatch = useDispatch();
  const navigateBackToMicrocertificatesOnClick = () => {
    setPermission([])
    history.push(pageRoutes.UsersManagement)
  }
  const [permission, setPermission] = useState<string[]>([]);

  useEffect(() => {
    const permissionsAdd: string[] = [];

    const permissionMap: any = {
      [PermissionPage.Diploma]: 'Diploma',
      [PermissionPage.Dou]: 'DOU',
      [PermissionPage.Institution]: 'Instituição',
      [PermissionPage.User]: 'Usuários',
      [PermissionPage.Microcertificate]: 'Microcertificado',
      [PermissionPage.Signer]: 'Signer',
      [PermissionPage.DigitalCurriculum]: 'Currículo',
    };

    if (permissionsActives !== undefined) {
      permissionsActives.forEach((value) => {
        if (value.sk !== undefined) {
          if (value.read && permissionMap[value.sk]) {
            permissionsAdd.push(permissionMap[value.sk]);
          }
        }
      });
    }
    setPermission(permissionsAdd);
  }, [permissionsActives])

  function handleChange(event: any) {
    const value = event.target.value;

    if (permission.includes(value)) {
      const permissionRemove = permission.filter(p => p !== value);
      setPermission(permissionRemove)
    } else {
      setPermission([...permission, value])
    }
  }

  function verifyPermission() {
    const typesPermission: string[] = Object.values(PermissionPage)
    const newPermission: string[] = []
    const cancelPermission: string[] = []

    if (permission.includes('Diploma')) {
      newPermission.push(PermissionPage.Diploma)
    }
    if (permission.includes('DOU')) {
      newPermission.push(PermissionPage.Dou)
    }
    if (permission.includes('Instituição')) {
      newPermission.push(PermissionPage.Institution)
    }
    if (permission.includes('Usuários')) {
      newPermission.push(PermissionPage.User)
    }
    if (permission.includes('Microcertificado')) {
      newPermission.push(PermissionPage.Microcertificate)
    }
    if (permission.includes('Signer')) {
      newPermission.push(PermissionPage.Signer)
    }
    if (permission.includes('Currículo')) {
      newPermission.push(PermissionPage.DigitalCurriculum)
    }

    typesPermission.forEach((value) => {
      if (!newPermission.includes(value)) {
        cancelPermission.push(value)
      }
    })

    return [newPermission, cancelPermission]
  }

  function createdRequest() {
    const [newPermission, cancelPermission] = verifyPermission()
    const request: PermissionsEmployee[] = []

    newPermission.forEach((page) => {
      const permissionAssigned = {
        pk: user.id,
        sk: page,
        read: true,
        update: true,
        create: true,
        delete: true
      } as PermissionPageDto
      request.push(permissionAssigned)
    })

    cancelPermission.forEach((page) => {
      const permissionDenied = {
        pk: user.id,
        sk: page,
        read: false,
        update: false,
        create: false,
        delete: false
      } as PermissionPageDto
      request.push(permissionDenied)
    })

    return request
  }

  async function handleSubmit() {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    await dispatch(upsertPermissionEmployee(createdRequest()))

    if (!isLoading) {
      history.push(pageRoutes.UsersManagement)
      setPermission([])
    }
  }

  return (
    <Root>
      <DefaultTemplate message="Informações de " highlightedMessage="Segurança" >
        <StyledPaper style={{ marginBottom: '100px', paddingBottom: '20px' }}>
          {isLoading && (
            <BackdropLoading
              size={50}
              description={'Atualizando permissões'}
              open={isLoading}
              classes={classes}
            />
          )}
          <BackCardHeader onClick={() => navigateBackToMicrocertificatesOnClick()} />
          <CardTitle
            icon={<User className={classes.icon} />}
          >Cadastro do Usuário</CardTitle>
          <Grid container item xs={12} style={{ gap: '10rem' }}>
            <LabeledText
              label="Nome"
              isLoading={loadingUser}
            >
              {user?.name}
            </LabeledText>

            <LabeledText
              label="Email"
              isLoading={loadingUser}
            >
              {user?.email}
            </LabeledText>
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            <Typography className={classes.titleFunc} variant="body1">Funcionalidades</Typography>
            {loadingUser ? <ListLoading /> : <List>
              <ListItem className={classes.listItem}>
                <CardTitle root={classes.cardList}
                  icon={<Diploma color={DIPLOMA_COLOR} />}
                >Acompanhamento de Diplomas</CardTitle>
                <Checkbox className={classes.check} checked={permission.includes('Diploma')} onChange={handleChange} value={'Diploma'} /></ListItem>

              <ListItem className={classes.listItem}>
                <CardTitle root={classes.cardList}
                  icon={<BookOpen className={classes.icon} />}
                >Controle de Lotes para Publicação no Diário Oficial da União</CardTitle>
                <Checkbox className={classes.check} checked={permission.includes('DOU')} onChange={handleChange} value={'DOU'} /></ListItem>

              <ListItem className={classes.listItem}>
                <CardTitle root={classes.cardList}
                  icon={<Settings className={classes.icon} />}
                >Gestão de Instituição</CardTitle>
                <Checkbox className={classes.check} checked={permission.includes('Instituição')} onChange={handleChange} value={'Instituição'} /></ListItem>

              <ListItem className={classes.listItem}>
                <CardTitle root={classes.cardList}
                  icon={<Users className={classes.icon} />}
                >Cadastro de Usuários</CardTitle>
                <Checkbox className={classes.check} checked={permission.includes('Usuários')} onChange={handleChange} value={'Usuários'} /></ListItem>

              <ListItem className={classes.listItem}>
                <CardTitle root={classes.cardList}
                  icon={<Certificate color={DIPLOMA_COLOR} />}
                >Microcertificados</CardTitle>
                <Checkbox className={classes.check} checked={permission.includes('Microcertificado')} onChange={handleChange} value={'Microcertificado'} /></ListItem>
              <ListItem className={classes.listItem}>
                <CardTitle root={classes.cardList}
                  icon={<FileText color={DIPLOMA_COLOR} />}
                >Currículo Digital</CardTitle>
                <Checkbox className={classes.check} checked={permission.includes('Currículo')} onChange={handleChange} value={'Currículo'} /></ListItem>
            </List>}
            <Grid container
              direction="row"
              justifyContent="flex-end"
              alignItems="center">
              <Button variant="contained" size="large" onClick={handleSubmit} className={classes.button} >Salvar Configurações</Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </DefaultTemplate>
    </Root >
  );
}

export default UsersSecurityPage; 
