import { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { styled } from '@mui/material/styles';
import { Check, XCircle } from 'react-feather';
import Curriculum from '../../../../../domain/model/curriculum/Curriculum';
import OriginSystem from '../../../../../domain/enum/curriculum/OriginSystem';
import CurriculumStatus from '../../../../../domain/enum/curriculum/CurriculumStatus';
import RejectedCurriculum from '../../curriculum-modal-reject/views/RejectCurriculum';
import TabName from '../../../../../domain/enum/curriculum/TabName';
import { useHistory } from 'react-router-dom';
import { errorNotificationMessages, pageRoutes, successNotificationMessages } from '../../../../utils/constants';
import ChangeCurriculumRequest from '../../../../../domain/interface/request/ChangeCurriculumRequest';
import { addChangeCurriculum } from '../store/actions';
import { setCurriculums, setLoadingCurriculums } from '../../digital-curriculum-page/store/actions';
import ChangeType from '../../../../../domain/enum/curriculum/ChangeType';
import IssuerInstitution from '../../../../../domain/model/curriculum/IssuerInstitution';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../setup/reducer/reducers';
import Institution from '../../../../../domain/model/institution/Institution';
import BackdropLoading from '../../../molecules/backdrop-loading';

const PREFIX = 'CurriculumValidateActions';

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  rejectButton: `${PREFIX}-rejectButton`,
  reloadButton: `${PREFIX}-reloadButton`,
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  [`& .${classes.icon}`]: {
    width: '16px',
    height: '16px'
  },
  [`& .${classes.rejectButton}`]: {
    color: theme.palette.error.main
  },
  [`& .${classes.reloadButton}`]: {
    color: theme.palette.primary.main,
  }
}));

interface Props {
  curriculum: Curriculum;
  error: boolean;
  currentTab: TabName;
  onPressContinue: Function;
}

const CurriculumValidateActions = ({ curriculum, error, currentTab, onPressContinue }: Props) => {
  const shouldShowReloadButton = curriculum?.root?.originSystem === OriginSystem.SIAF && curriculum.root.status === CurriculumStatus.IssuerConfirmationPending;

  const [openCurriculumRejectationModal, setOpenCurriculumRejectationModal] =
    useState(false);

  const [isLoading, setLoading] = useState(false);

  const [loadMessage, setLoadMessage] = useState<string>('');

  function rejectCurriculumOnClick(): void {
    setOpenCurriculumRejectationModal(true);
  };

  const history = useHistory();
  const dispatch = useDispatch();

  const selectedInstitution = useSelector((state: RootState) => state.header.institution) as Institution;

  async function handleChangeCurriculum(request: ChangeCurriculumRequest, successMessage: string, errorMessage: string) {
    setLoading(true);
    await dispatch(addChangeCurriculum(request, curriculum.curriculumId, successMessage, errorMessage));
    dispatch(setLoadingCurriculums(true));
    dispatch(setCurriculums(selectedInstitution));
    history.push(pageRoutes.DigitalCurriculum)
  }

  async function handleResynchChange() {
    const successMessage =
      successNotificationMessages.successSync;
    const errorMessage =
      errorNotificationMessages.failedToSyncDiploma;
    const request: ChangeCurriculumRequest = {
      type: ChangeType.IssuerInstitutionSynchronizesCurriculum
    }
    setLoadMessage("Ressincronizando Currículo...");
    await handleChangeCurriculum(request, successMessage, errorMessage);
  }

  async function handleIssuerInstitutionValidatedChange(type: ChangeType, issuerInstitution: IssuerInstitution) {
    const successMessage =
      successNotificationMessages.successToSendCurriculumToSignature;
    const errorMessage =
      errorNotificationMessages.failedToSendCurriculumToSignature;
    const request: ChangeCurriculumRequest = {
      type, issuerInstitution
    }
    setLoadMessage("Ativando currículo...");
    await handleChangeCurriculum(request, successMessage, errorMessage);
  }

  return (
    <Root>
      <Grid
        container
        justifyContent="center"
        alignItems="stretch"
      >
        <BackdropLoading
          size={30}
          description={loadMessage}
          open={isLoading}
          classes={classes}
        />
      </Grid>
      <Grid container item xs={12} direction='row' justifyContent='space-between' className={classes.root}>
        <Button
          onClick={rejectCurriculumOnClick}
          startIcon={(
            <XCircle className={`${classes.rejectButton} ${classes.icon}`} />
          )}>
          <Typography className={classes.rejectButton}>Reprovar Currículo</Typography>
        </Button>
        <Grid container item xs={6} direction='row' spacing={3} justifyContent={'flex-end'}>
          {shouldShowReloadButton && <Grid item>
            <Button
              onClick={handleResynchChange}
              startIcon={(
                <ReplayIcon className={`${classes.icon} ${classes.reloadButton}`} />
              )}>
              <Typography className={classes.reloadButton}>Recarregar dados</Typography>
            </Button>
          </Grid>}
          <Grid item>
            <Button
              variant='contained'
              startIcon={currentTab === TabName.curriculumData && <Check className={classes.icon} />}
              onClick={() => {
                const signCurriculum = onPressContinue();
                if (!signCurriculum) {
                  handleIssuerInstitutionValidatedChange(ChangeType.IssuerInstitutionValidatedCurriculum, curriculum.issuerInstitution);
                }
              }}
              disabled={currentTab !== TabName.iesData &&
                (curriculum?.root?.status !== CurriculumStatus.IssuerConfirmationPending || error)}>
              {currentTab === TabName.iesData ? 'Avançar' : 'Enviar para assinatura'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {!!curriculum &&
        <RejectedCurriculum
          openModal={openCurriculumRejectationModal}
          setOpenModal={setOpenCurriculumRejectationModal}
          id={curriculum.curriculumId}
        />}
    </Root>
  );
}

export default CurriculumValidateActions;
