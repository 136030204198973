import React from 'react';
import { useStagesTimelineStyles } from './styles';
import { StylesProps } from './types';

export default function StepConnector() {
  const styles = useStagesTimelineStyles({} as StylesProps);
  return (
    <div className={styles.connector} />
  );
}
