import {
  CurriculumActions,
  CurriculumState,
  INITIAL_STATE,
  SET_CURRICULUMS,
  SET_CURRICULUMS_FILTER,
  SET_LOADING_CURRICULUMS,
  SET_SELECTED_SYNC_CURRICULUMS
} from './types';

export default function reducer(
  state: CurriculumState = INITIAL_STATE,
  action: CurriculumActions
): CurriculumState {
  switch (action.type) {
    case SET_CURRICULUMS:
      return {
        ...state,
        curriculums: action.payload.curriculums
      };
    case SET_LOADING_CURRICULUMS:
      return {
        ...state,
        loadingCurriculums: action.payload.loadingCurriculums
      };
    case SET_SELECTED_SYNC_CURRICULUMS:
      return {
        ...state,
        syncCurriculums: action.payload.syncCurriculums
      };
    case SET_CURRICULUMS_FILTER:
      return {
        ...state,
        filter: action.payload.filter
      };
    default:
      return state;
  }
}
