import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Snackbar, Typography, Grid } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import {
  DARK_CYAN_COLOR,
} from '../../../../../theme';

const PREFIX = 'SuccessNotification';

const classes = {
  sucessoSnackBar: `${PREFIX}-sucessoSnackBar`,
  sucessoSnackBarText: `${PREFIX}-sucessoSnackBarText`,
  snackBar: `${PREFIX}-snackBar`,
  snackBarText: `${PREFIX}-snackBarText`
};

const StyledSnackbar = styled(Snackbar)(() => ({
  [`& .${classes.sucessoSnackBar}`]: {
    color: `${DARK_CYAN_COLOR} !important`,
  },

  [`& .${classes.sucessoSnackBarText}`]: {
    color: `${DARK_CYAN_COLOR} !important`,
  },

  [`& .${classes.snackBar}`]: {
    color: `${DARK_CYAN_COLOR} !important`,
  },

  [`& .${classes.snackBarText}`]: {
    color: `${DARK_CYAN_COLOR} !important`,
  }
}));

type Props = {
  successMessage: string | null,
  hideSuccessMessage: () => void,
  snackBarClass: string,
  snackBarTextClass: string,
  successIcon: JSX.Element,
};

const SuccessNotification = (props: Props) => {
  const {
    successMessage, hideSuccessMessage, snackBarClass, snackBarTextClass, successIcon: iconeSucesso,
  } = props;



  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        hideSuccessMessage();
      }, 5000);
    }
  }, [successMessage, hideSuccessMessage]);

  const openMessage: boolean = !!successMessage && successMessage.trim().length > 0;

  return (
    <StyledSnackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={openMessage}
      onClose={hideSuccessMessage}
      ClickAwayListenerProps={{
        mouseEvent: false,
      }}
    >
      <MuiAlert
        elevation={0}
        icon={iconeSucesso}
        severity="success"
        className={`${classes.snackBar} ${snackBarClass}`}
      >
        <Grid container direction="row" spacing={2} alignItems="center">
          <Grid item>
            <Typography className={`${classes.sucessoSnackBarText} ${classes.snackBarText} ${snackBarTextClass}`}>
              {successMessage}
            </Typography>
          </Grid>
        </Grid>
      </MuiAlert>
    </StyledSnackbar>
  );
};

export default SuccessNotification;
