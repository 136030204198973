import Certificate from '../../../../../domain/model/certificate/Certificate';
import CertificateService from '../../../../../services/CertificateService';
import { AppThunk } from '../../../../../setup/reducer';
import {
  errorNotificationMessages,
  notificationIcons
} from '../../../../utils/constants';
import { showNotification } from '../../notification/actions';
import {
  CertificateInfoActions,
  SET_BATCH_CERTIFICATES,
  SET_CERTIFICATES,
  SET_LOADING_CERTIFICATES,
  SET_SEARCH
} from './types';

const internalSetLoadingCertificates = (
  loadingCertificates: boolean
): CertificateInfoActions => ({
  type: SET_LOADING_CERTIFICATES,
  payload: {
    loadingCertificates
  }
});

const internalSetSearch = (search: string): CertificateInfoActions => ({
  type: SET_SEARCH,
  payload: {
    search
  }
});

const internalSetCertificates = (
  certificates: Certificate[]
): CertificateInfoActions => ({
  type: SET_CERTIFICATES,
  payload: {
    certificates
  }
});

const internalSetBatchCertificates = (id: string): CertificateInfoActions => ({
  type: SET_BATCH_CERTIFICATES,
  payload: {
    id
  }
});

export const setSearchCertificates =
  (search: string): AppThunk =>
  async (dispatch) => {
    search = search.replace(/[*|+()]/g, '');
    dispatch(internalSetSearch(search));
  };

export const setBatchCertificates =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetBatchCertificates(id));
  };
export const setLoadingCertificates =
  (loadingData: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(internalSetLoadingCertificates(loadingData));
  };

export const setCertificates =
  (idBatch: string): AppThunk =>
  async (dispatch) => {
    let certificates: Certificate[] = [];

    try {
      certificates = (await CertificateService.getCertificates({ id: idBatch }))
        .data;
      if (certificates.some((cert) => 'message' in cert)) {
        certificates = [];
      }
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
    }

    dispatch(internalSetCertificates(certificates));
    dispatch(setLoadingCertificates(false));
  };
