import { Button, styled } from "@mui/material";
import { ChevronLeft, ChevronRight } from "react-feather";
import { TablePaginationFooterProps } from "./types";

const PREFIX = 'TablePaginationFooter';

const classes = {
  paginationButton: `${PREFIX}-paginationButton`,
  disabledButton: `${PREFIX}-disabledButton`,
};

const StyledDiv = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.paginationButton}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: theme.palette.text.primary,
    fontSize: 14
  },
  [`& .${classes.disabledButton}`]: {
    color: theme.palette.grey[500]
  }
}));

const TablePaginationFooter = ({ numberOfItems: objectLength, pageStartIndex: page, pageSize, setPage }: TablePaginationFooterProps) => {
  return (
    <StyledDiv>
      <div className={classes.paginationButton}>
        {objectLength === 0 ? 0 : page + 1}
        -{page + pageSize > (objectLength ?? 0)
          ? objectLength : page + pageSize} de {objectLength}
        <Button
          onClick={() => setPage(page - pageSize)}
          disabled={page === 0} >
          <ChevronLeft
            className={page === 0 ? classes.disabledButton : ''} />
        </Button>
        <Button
          onClick={() => setPage(page + pageSize)}
          disabled={page + pageSize >= (objectLength ?? 0)}>
          <ChevronRight
            className={page + pageSize >= (objectLength ?? 0) ? classes.disabledButton : ''} />
        </Button>
      </div>
    </StyledDiv>
  );
}

export default TablePaginationFooter;

