import Employee from '../../../../../domain/model/employee/Employee';
import EmployeeService from '../../../../../services/EmployeeService';
import { AppThunk } from '../../../../../setup/reducer';
import {
  errorNotificationMessages,
  notificationIcons
} from '../../../../utils/constants';
import { showNotification } from '../../notification/actions';
import { SET_EMPLOYEES, DiplomaModalSignersActions } from './types';

const internalSetEmployees = (
  employees: Employee[]
): DiplomaModalSignersActions => ({
  type: SET_EMPLOYEES,
  payload: {
    employees
  }
});

export const setEmployees =
  (
    setLoadingEmployee: any,
    institutionMecCode?: number,
    canSignDocuments?: boolean
  ): AppThunk =>
  async (dispatch) => {
    let employees: Employee[] = [];
    try {
      employees = (
        await EmployeeService.listEmployees(
          institutionMecCode,
          canSignDocuments
        )
      ).data;
    } catch (e) {
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        )
      );
    }
    dispatch(internalSetEmployees(employees));
    setLoadingEmployee(false);
  };
