import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import { connect, ConnectedProps } from 'react-redux';
import {
  IconProps,
  Repeat, XCircle, CheckCircle,
} from 'react-feather';
import ErrorNotification from './ErrorNotification';
import SuccessNotification from './SuccessNotification';
import { notificationIcons } from '../../../../utils/constants';
import { FONT_FAMILY_SEMIBOLD, DARK_CYAN_COLOR, LIGHT_TOMATO_COLOR } from '../../../../../theme';
import { RootState } from '../../../../../setup/reducer/reducers';
import { showNotification, showSuccessNotification } from '../actions';

const PREFIX = 'Notification';

const classes = {
  snackBar: `${PREFIX}-snackBar`,
  snackbarText: `${PREFIX}-snackbarText`
};

const Root = styled('div')(() => ({
  [`& .${classes.snackBar}`]: {
    fontSize: '14px',
    fontWeight: 'bold',
    fill: 'solid',
    boxShadow: '0 2px 38px 0 rgba(0,0,0,0.07), 0 9px 15px 0 rgba(0,0,0,0.05)',
  },

  [`& .${classes.snackbarText}`]: {
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: FONT_FAMILY_SEMIBOLD,
  }
}));

const mapState = (state: RootState) => ({
  errorMessage: state.notification.errorMessage,
  errorIcon: state.notification.errorIcon,
  successMessage: state.notification.successMessage,
  successIcon: state.notification.successIcon,
});

const mapDispatch = {
  hideErrorMessage: () => showNotification(null, null),
  hideSuccessMessage: () => showSuccessNotification(null, null),
};

const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const Notification = (props: Props) => {
  const {
    errorMessage,
    successMessage,
    hideErrorMessage,
    hideSuccessMessage,
    successIcon,
    errorIcon,
  } = props;



  const icons: { [index: string]: FC<IconProps> } = {};
  icons[notificationIcons.error] = XCircle;
  icons[notificationIcons.tryAgain] = Repeat;
  icons[notificationIcons.success] = CheckCircle;

  const renderAlertIcon = (icon: string | null, color: string, defaultIcon?: string) => {
    let SelectedIcon: FC<IconProps> | null = null;
    if (icon) {
      SelectedIcon = icons[icon];
    }
    if (!SelectedIcon) {
      if (defaultIcon) {
        SelectedIcon = icons[defaultIcon];
      }
    }

    return SelectedIcon ? <SelectedIcon color={color} /> : <Root></Root>;
  };

  const successIconAlert = renderAlertIcon(successIcon, DARK_CYAN_COLOR);
  const errorIconAlert = renderAlertIcon(
    errorIcon,
    LIGHT_TOMATO_COLOR,
    notificationIcons.error,
  );

  return (
    <>
      <ErrorNotification
        errorMessage={errorMessage}
        hideErrorMessage={hideErrorMessage}
        snackBarClass={classes.snackBar}
        snackBarTextclass={classes.snackbarText}
        errorIcon={errorIconAlert}
      />
      <SuccessNotification
        successMessage={successMessage}
        hideSuccessMessage={hideSuccessMessage}
        snackBarClass={classes.snackBar}
        snackBarTextClass={classes.snackbarText}
        successIcon={successIconAlert}
      />
    </>
  );
};

export default connector(Notification);
