import makeStyles from '@mui/styles/makeStyles';

import { DIPLOMA_COLOR, EERIE_BLACK_COLOR } from '../../../../theme';

const useConfirmationModalStyles = makeStyles(() => ({
  backButton: {
    color: EERIE_BLACK_COLOR,
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '16px 26px'
  },
  continueButton: {
    color: DIPLOMA_COLOR,
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '16px 26px'
  }
}));

export default useConfirmationModalStyles;
