import State from '../../enum/State';

export default class StateId {
  number: string;

  state: State;

  issuingAuthority: string;

  issuedAt: string;

  static stringify(stateId: StateId | undefined): string | undefined {
    if (!stateId) {
      return undefined;
    }
    return `${stateId.state}-${stateId.number}`;
  }
}
