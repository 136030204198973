import React from 'react';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import {
  Grid, Typography, DialogContent, Dialog, DialogActions, DialogTitle, Box, IconButton,
} from '@mui/material';

import {
  WHITE_COLOR,
  BLACK_COLOR,
  FONT_FAMILY_REGULAR,
} from '../../../../theme';
import BackdropLoading from '../backdrop-loading';

const PREFIX = 'BaseModal';

const classes = {
  modal: `${PREFIX}-modal`,
  backgroundProps: `${PREFIX}-backgroundProps`,
  description: `${PREFIX}-description`,
  loadingContainer: `${PREFIX}-loadingContainer`,
  modalTitleWithChildren: `${PREFIX}-modalTitleWithChildren`,
  titleGrid: `${PREFIX}-titleGrid`,
  defaultContentGrid: `${PREFIX}-defaultContentGrid`,
  closeButton: `${PREFIX}-closeButton`
};

const StyledDialog = styled(Dialog)((
  {
    theme
  }
) => ({
  [`& .${classes.modal}`]: {
    maxWidth: '1200px',
    backgroundColor: WHITE_COLOR,
    boxShadow: '0 2px 38px 0 rgba(0,0,0,0.07), 0 9px 15px 0 rgba(0,0,0,0.05)',
    borderRadius: '8px',
    overflow: 'hidden',
    '& .MuiDialogContent-root': {
      overflow: 'hidden',
    },
  },

  [`& .${classes.backgroundProps}`]: {
    background: 'rgba(192,198,214,0.4)',
  },

  [`& .${classes.description}`]: {
    fontSize: '16px',
    color: BLACK_COLOR,
    fontFamily: FONT_FAMILY_REGULAR,
    marginBottom: '20px',
  },

  [`& .${classes.loadingContainer}`]: {
    minWidth: '40vw',
    minHeight: '20vh',
  },

  [`& .${classes.modalTitleWithChildren}`]: {
    marginLeft: theme.spacing(2),
  },

  [`& .${classes.titleGrid}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.defaultContentGrid}`]: {
    marginLeft: theme.spacing(2),
  },

  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  }
}));

type Props = {
  openModal: boolean;
  onClose: () => void;
  modalTitle?: string;
  modalSubTitle?: string;
  loading?: boolean;
  loadingMessage?: string;
  children?: any;
  containerContentStyle?: string;
  actions?: JSX.Element,
  closeable?: boolean,
};

const BaseModal = (props: Props) => {


  const {
    openModal,
    onClose,
    modalTitle,
    modalSubTitle,
    loading,
    loadingMessage,
    children,
    containerContentStyle,
    actions,
    closeable,
  } = props;



  return (
    <StyledDialog
      open={openModal}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          return false;
        }

        if (reason === "escapeKeyDown") {
          return false;
        }

        if (typeof onClose === "function") {
          onClose();
        }
      }
      }
      aria-labelledby="modal-titulo"
      aria-describedby="modal-descricao"
      scroll="body"
      classes={{ paper: classes.modal }}
      BackdropProps={{
        classes: {
          root: classes.backgroundProps,
        },
      }}
    >
      {
        modalTitle && (
          <DialogTitle>
            <Grid className={classes.titleGrid} item container justifyContent={children ? undefined : 'center'}>
              <Typography className={children ? classes.modalTitleWithChildren : undefined} variant="h3">{modalTitle}</Typography>
            </Grid>
            <Grid>
              {modalSubTitle && (
                <Typography className={children ? classes.modalTitleWithChildren : undefined} variant="h3">{modalSubTitle}</Typography>
              )}
            </Grid>
          </DialogTitle>
        )
      }
      <DialogContent>
        {
          closeable && (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
              size="large">
              <CloseIcon />
            </IconButton>
          )
        }
        {
          children && (
            <Box className={containerContentStyle || classes.defaultContentGrid}>
              <BackdropLoading
                size={50}
                open={!!loading}
                description={loadingMessage || ''}
              />
              {children}
            </Box>
          )
        }
      </DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </StyledDialog>
  );
};

BaseModal.defaultProps = {
  children: null,
  loading: false,
  loadingMessage: '',
  actions: null,
  containerContentStyle: undefined,
  closeable: false,
};

export default BaseModal;
