import Employee from '../../../../../domain/model/employee/Employee';

export const SET_EMPLOYEES = 'app/user/SET_EMPLOYEES';

export interface SignersState {
  employees?: Employee[],
}

export const INITIAL_STATE: SignersState = {
  employees: [],
};

interface SetEmployeesAction {
  type: typeof SET_EMPLOYEES,
  payload: {
    employees?: Employee[]
  }
}

export type DiplomaModalSignersActions = SetEmployeesAction;
