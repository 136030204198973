import { Button, Divider, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Filter } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import Diploma from '../../../../../domain/model/diploma/Diploma';
import { RootState } from '../../../../../setup/reducer/reducers';
import CardTitle from '../../../molecules/card-title';
import EmptyTable from '../../../molecules/empty-table';
import AdvancedFilterModal from '../../../organisms/advanced-filter-modal';
import { AdvancedFilterResults } from '../../../organisms/advanced-filter-modal/types';
import DiplomaIcon from '../../../../icons/Diploma';
import { filterListByDateProperty } from '../../../../utils/functions';
import { DARK_GRAY_COLOR, FONT_FAMILY_SEMIBOLD } from '../../../../../theme';
import { selectIssuerInstitution, setDiplomasRows } from '../store/actions';
import DiplomaFilter from './DiplomaFilter';
import DiplomaTable from './DiplomaTable';

const PREFIX = 'DiplomaList';

const classes = {
  root: `${PREFIX}-root`,
  advancedFilterButton: `${PREFIX}-advancedFilterButton`,
  advancedFilterButtonLabel: `${PREFIX}-advancedFilterButtonLabel`,
  filterMessageContainer: `${PREFIX}-filterMessageContainer`
};

const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {
    marginBottom: '50px',
    maxHeight: '1500px',
  },

  [`& .${classes.advancedFilterButton}`]: {
    marginRight: '2rem',
  },

  [`& .${classes.advancedFilterButtonLabel}`]: {
    fontFamily: FONT_FAMILY_SEMIBOLD,
    marginLeft: '0.5rem',
  },

  [`& .${classes.filterMessageContainer}`]: {
    paddingTop: '2rem',
    paddingLeft: '2rem',
    '& .MuiTypography-body1': {
      fontSize: '1rem',
      color: DARK_GRAY_COLOR,
    },
  }
}));

let clearAdvancedFilters: () => void;

const DiplomaList = () => {

  const dispatch = useDispatch();

  const selectedInstitution = useSelector((state: RootState) => state.header.institution);
  const diplomasList = useSelector((state: RootState) => state.diplomaFilter.diplomas);
  const selectedInstitutionProfile = useSelector((state: RootState) => state.diplomaFilter.selectedInstitutionProfile);

  const [cachedDiplomasList, setCachedDiplomasList] = useState<Diploma[]>([]);
  const [openAdvancedFilterModal, setOpenAdvancedFilterModal] = useState(false);
  const [filterMessage, setFilterMessage] = useState('');

  const toggleModal = () => {
    setOpenAdvancedFilterModal(!openAdvancedFilterModal);
  };

  const buildFilterMessage = (results: AdvancedFilterResults) => {
    const { institution, startDate, endDate } = results;
    let message = '';

    if (institution?.name) {
      message = `Exibindo diplomas da IES Emissora (${institution.name})`;
    }

    if (startDate && endDate) {
      const startDateFormatted = moment(startDate).format('DD/MM/YY');
      const endDateFormatted = moment(endDate).format('DD/MM/YY');
      const isSameDate = startDateFormatted === endDateFormatted;

      const rangeDateMessage = isSameDate ? `atualizados no dia ${startDateFormatted}` : `atualizados entre ${startDateFormatted} e ${endDateFormatted}`;

      if (message) {
        message += ` ${rangeDateMessage}`;
      } else {
        message = `Exibindo diplomas ${rangeDateMessage}`;
      }
    }

    const messageNotEndsWithDot = /[^.]$/;

    if (message && messageNotEndsWithDot.test(message)) {
      message += '.';
    }

    setFilterMessage(message);
  };


  const handleFilterModal = (results: AdvancedFilterResults) => {
    const { institution, startDate, endDate } = results;

    const hasCache = cachedDiplomasList.length > 0;

    if (institution?.name) {
      dispatch(selectIssuerInstitution(institution));
    }

    if (!hasCache) {
      setCachedDiplomasList(diplomasList);
    }

    const diplomas = hasCache ? cachedDiplomasList : diplomasList;

    const filteredDiplomas = diplomas.filter(filterListByDateProperty({ datePropertyName: 'updatedAt', startDate, endDate }));


    dispatch(setDiplomasRows(filteredDiplomas));

    buildFilterMessage(results);
  };

  clearAdvancedFilters = () => handleFilterModal({} as AdvancedFilterResults);

  useEffect(() => {
    clearAdvancedFilters();
    setCachedDiplomasList([]);
  }, [selectedInstitution, selectedInstitutionProfile]);

  return (
    (<Root>
      <Paper className={classes.root}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <CardTitle icon={<DiplomaIcon />}>
              Acompanhamento de Diplomas
            </CardTitle>
          </Grid>
          <Grid item>
            <Button onClick={toggleModal} className={classes.advancedFilterButton}>
              <Filter size={20} color={DARK_GRAY_COLOR} />
              <Typography variant="h5" className={classes.advancedFilterButtonLabel}>
                Filtros Avançados
              </Typography>
            </Button>
            <AdvancedFilterModal
              openModal={openAdvancedFilterModal}
              onClose={toggleModal}
              onFilter={handleFilterModal}
            />
          </Grid>
        </Grid>
        <Divider />
        {
          filterMessage && (
            <Grid item xs={12} className={classes.filterMessageContainer}>
              <Typography variant="body1">
                {filterMessage}
              </Typography>
            </Grid>
          )
        }
        {
          selectedInstitution
            ? (
              <>
                <DiplomaFilter />
                <DiplomaTable />
              </>
            )
            : (
              <EmptyTable
                emptyStateMessage="Nenhum Diploma Encontrado"
                orientationMessage="Aguarde o envio de algum diploma para registro na sua IES"
              />
            )
        }
      </Paper>
    </Root>)
  );
};

export default DiplomaList;
