export interface DiplomaEducationalState {
  showVisionDropdow: boolean;
}

export const INITIAL_STATE: DiplomaEducationalState = {
  showVisionDropdow: false
};

export const SET_SHOW_VISION_DROPDOWN =
  'app/diploma-educational-stage-label/SET_SHOW_VISION_DROPDOWN';

interface SetshowVisionDropdowAction {
  type: typeof SET_SHOW_VISION_DROPDOWN;
  payload: {
    showVisionDropdow: boolean;
  };
}

export type DiplomaEducationalActions = SetshowVisionDropdowAction;
