import {
  createStore,
  applyMiddleware,
  compose,
  Middleware,
  Action
} from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';
import reducers, { RootState } from './reducers';

const middlewares: Middleware[] = [thunk];

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);

export default store;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
